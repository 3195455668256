import {DirectionJson, DirectionModel} from "./direction";
import {ImageJson, ImageModel} from "./image";
import {EntityModel} from "./entityModel";
import {Observable, of} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectSchema} from "mongodb-realm";

export type RecipeDifficulty = 'easy' | 'medium' | 'hard'

export interface RecipeJson {
  cook_time_min?: number
  creator_id?: string
  difficulty?: RecipeDifficulty
  directions?: DirectionJson[]
  preparation_time_min?: number
  serving_yield?: number
  source_image?: ImageJson
  source_name?: string
  source_url?: string
}

export class RecipeModel implements EntityModel<RecipeModel> {
  public static schema: ObjectSchema = {
    name: 'RecipeEntity',
    embedded: true,
    properties: {
      cookTimeMin: 'int',
      creatorId: 'string?',
      difficulty: 'string?',
      directions: 'DirectionEntity[]',
      preparationTimeMin: 'int',
      servingYield: 'double',
      sourceImage: 'ImageEntity',
      sourceName: 'string?',
      sourceUrl: 'string?',
    },
  }

  public schema = RecipeModel.schema

  cookTimeMin: number = 0
  creatorId?: string
  difficulty?: RecipeDifficulty
  directions: DirectionModel[] = []
  preparationTimeMin: number = 0
  servingYield: number = 0
  sourceImage?: ImageModel
  sourceName?: string
  sourceUrl?: string

  constructor(entity?: RecipeModel | null) {
    if (!entity) return

    this.cookTimeMin = entity.cookTimeMin
    this.creatorId = entity.creatorId
    this.difficulty = entity.difficulty
    this.directions = entity.directions.map(i => new DirectionModel(i))
    this.preparationTimeMin = entity.preparationTimeMin
    this.servingYield = entity.servingYield
    this.sourceImage = new ImageModel(entity.sourceImage)
    this.sourceName = entity.sourceName
    this.sourceUrl = entity.sourceUrl

    return this
  }

  static fromJson(json?: RecipeJson): RecipeModel | undefined {
    if (!json) return

    const model = new RecipeModel()

    model.cookTimeMin = json.cook_time_min ?? 0
    model.creatorId = json.creator_id
    model.difficulty = json.difficulty
    model.directions = json.directions
      ?.map((d) => DirectionModel.fromJson(d))
      .flatMap(f => f ? [f] : []) ?? []
    model.preparationTimeMin = json.preparation_time_min ?? 0
    model.servingYield = json.serving_yield ?? 0
    model.sourceImage = ImageModel.fromJson(json.source_image)
    model.sourceName = json.source_name
    model.sourceUrl = json.source_url

    return model
  }

  toEntityObservable(realmService: BaseRealmService): Observable<RecipeModel> {
    return of(this)
  }
}