import {NutrientType} from "../../models/nutrient";
import React from "react";
import {useTranslation} from "next-i18next";
import {ProductDetailMacrosPieChart} from "./ProductDetailMacrosPieChart";
import {Body, Heading1} from "../text";
import Skeleton from "react-loading-skeleton";

export interface ProductDetailMacrosViewProps {
  nutrients: Map<NutrientType, number>
  isLoading: boolean
}

export const ProductDetailMacrosView: React.FC<ProductDetailMacrosViewProps> = ({ nutrients, isLoading }) => {
  const { t } = useTranslation()

  const protein = nutrients.get("PROTEIN") ?? 0
  const carbohydrate = nutrients.get("CARBOHYDRATE") ?? 0
  const fat = nutrients.get("FAT") ?? 0

  return (
    <div className="grid grid-cols-25 lg:grid-cols-17 gap-2 lg:gap-1 justify-center">
      <ProductDetailMacrosPieChart className="col-span-7 lg:col-span-4 h-24 lg:h-32" isLoading={isLoading} nutrients={nutrients} />

      <div className="hidden lg:block col-span-1" />

      <div className="col-span-6 lg:col-span-4 flex space-x-2 md:space-x-3 items-center justify-center lg:justify-start">
        {isLoading ? <Skeleton className="!h-11 lg:!h-14 !w-1 !rounded-full !flex-shrink-0" /> : <div className="h-11 lg:h-14 w-1 rounded-full bg-fg-purple-light flex-shrink-0"/>}

        <div className="flex flex-col space-y-0 justify-center">
          <Heading1>{isLoading ? <Skeleton className="!w-10" /> : protein + t('common:nutrient_unit_gram')}</Heading1>
          <Body>{isLoading ? <Skeleton className="!w-12" /> : t('common:nutrient_protein').toLocaleLowerCase()}</Body>
        </div>
      </div>

      <div className="col-span-6 lg:col-span-4 flex space-x-2 md:space-x-3 items-center justify-center lg:justify-start">
        {isLoading ? <Skeleton className="!h-11 lg:!h-14 !w-1 !rounded-full !flex-shrink-0" /> : <div className="h-11 lg:h-14 w-1 rounded-full bg-fg-sky-blue flex-shrink-0" />}

        <div className="flex flex-col space-y-0 justify-center">
          <Heading1>{isLoading ? <Skeleton className="!w-10" /> : carbohydrate + t('common:nutrient_unit_gram')}</Heading1>
          <Body>{isLoading ? <Skeleton className="!w-12" /> : t('common:nutrient_carbs').toLocaleLowerCase()}</Body>
        </div>
      </div>

      <div className="col-span-6 lg:col-span-4 flex space-x-2 md:space-x-3 items-center justify-center lg:justify-start">
        {isLoading ? <Skeleton className="!h-11 lg:!h-14 !w-1 !rounded-full !flex-shrink-0" /> : <div className="h-11 lg:h-14 w-1 rounded-full bg-fg-indigo flex-shrink-0" />}

        <div className="flex flex-col space-y-0 justify-center">
          <Heading1>{isLoading ? <Skeleton className="!w-10" /> : fat + t('common:nutrient_unit_gram')}</Heading1>
          <Body>{isLoading ? <Skeleton className="!w-12" /> : t('common:nutrient_fat').toLocaleLowerCase()}</Body>
        </div>
      </div>
    </div>
  )
}