import {BaseError} from "./baseError";
import {LocalizationInfo} from "react-i18next";

export type MacroSurveyValidationErrorCode =
  'MISSING_GENDER' |
  'MISSING_BODY_TYPE' |
  'INVALID_STARTING_WEIGHT' |
  'MISSING_STARTING_WEIGHT' |
  'INVALID_HEIGHT' |
  'MISSING_HEIGHT_FEET' |
  'MISSING_HEIGHT_INCHES' |
  'MISSING_HEIGHT_CENTIMETERS' |
  'MISSING_DIET_GOAL' |
  'MISSING_DEADLINE' |
  'INVALID_DEADLINE' |
  'MISSING_DIET_PREFERENCE' |
  'MISSING_ACTIVITY_LEVEL' |
  'MISSING_GOAL_WEIGHT'

export class MacroSurveyValidationError extends BaseError<MacroSurveyValidationErrorCode> {
  localizedMessage: LocalizationInfo | string
  localizedTitle: LocalizationInfo | string

  constructor(code: MacroSurveyValidationErrorCode) {
    super(code)

    switch (code) {
      case "MISSING_GENDER":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_gender"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_gender"}
        break
      case "MISSING_BODY_TYPE":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_body_type"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_body_type"}
        break
      case "INVALID_STARTING_WEIGHT":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_invalid_current_weight"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_invalid_current_weight"}
        break
      case "MISSING_STARTING_WEIGHT":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_current_weight"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_current_weight"}
        break
      case "INVALID_HEIGHT":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_invalid_height"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_invalid_height"}
        break
      case "MISSING_HEIGHT_FEET":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_height_feet"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_height_feet"}
        break
      case "MISSING_HEIGHT_INCHES":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_height_inches"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_height_inches"}
        break
      case "MISSING_HEIGHT_CENTIMETERS":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_height_centimeters"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_height_centimeters"}
        break
      case "MISSING_DIET_GOAL":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_diet_goal"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_diet_goal"}
        break
      case "MISSING_DEADLINE":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_deadline"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_deadline"}
        break
      case "INVALID_DEADLINE":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_invalid_deadline"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_invalid_deadline"}
        break
      case "MISSING_DIET_PREFERENCE":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_diet_preference"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_diet_preference"}
        break
      case "MISSING_ACTIVITY_LEVEL":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_activity_level"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_activity_level"}
        break
      case "MISSING_GOAL_WEIGHT":
        this.localizedMessage = { key: "macro_survey:macro_survey_error_message_missing_goal_weight"}
        this.localizedTitle = { key: "macro_survey:macro_survey_error_title_missing_goal_weight"}
        break
    }
  }
}