import React, {useEffect, useRef} from "react";
import {AlertDialog} from "../dialogs/AlertDialog";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  clearError, fetchAvailablePromoCodes, reset,
  selectError,
  selectIsLoading,
  selectIsSaving,
  selectPromoCodes, selectShouldClose,
  verifyPromoCode
} from "../../redux/promoCodeEntry/promoCodeEntrySlice";
import {Container} from "../containers/Container";
import {CloseIcon} from "../icons";
import {HeroHeading2} from "../text";
import {useTranslation} from "next-i18next";
import {BodyInput} from "../inputs";
import {Button} from "../buttons/Button";
import {PromoCodeEntryItemView} from "./PromoCodeEntryItemView";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export interface PromoCodeEntryViewProps {
  onClose: () => void
  storeId?: string
}

export const PromoCodeEntryView: React.FC<PromoCodeEntryViewProps> = ({ onClose, storeId }) => {
  const error = useAppSelector(selectError)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const promoCodes = useAppSelector(selectPromoCodes)
  const isLoading = useAppSelector(selectIsLoading)
  const isSaving = useAppSelector(selectIsSaving)
  const shouldClose = useAppSelector(selectShouldClose)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Promo Code Entry' }))
  }, [])

  useEffect(() => {
    dispatch(reset())

    storeId && dispatch(fetchAvailablePromoCodes(storeId))
  }, [dispatch, storeId])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  return <div className="max-h-screen flex flex-col w-full min-h-screen-3/4 md:min-h-screen-1/2 sticky">
    <AlertDialog
      isOpen={!!error}
      error={error}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            dispatch(clearError())
          },
          style: 'default'
        }
      ]}
    />

    <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
      <div className="w-full no-scrollbar flex-1 flex flex-col">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`mt-4`}>
          <HeroHeading2>{t('payment:payment_title_promo_code')}</HeroHeading2>
        </div>

        <div className="mt-6 flex flex-row space-x-3 items-center">
          <div className="flex-1">
            <BodyInput
              ref={inputRef}
              autoComplete="off"
              placeholder={ t('payment:payment_placeholder_enter_promo_code') }
              onChange={e => {

              }}
            />
          </div>

          <Button
            buttonStyle="primary"
            isLoading={isSaving}
            onClick={() => {
              const code = inputRef.current?.value
              if (code) {
                dispatch(verifyPromoCode({ code, store_id: storeId }))
              }
            }}
          >
            {t('payment:payment_title_apply')}
          </Button>
        </div>

        <ul className="mt-4">
          {promoCodes.map(promoCode => {
            return <li key={promoCode.id} className="border-b last:border-0 border-fg-gray-surface">
              <PromoCodeEntryItemView promoCode={promoCode} isLoading={isLoading} />
            </li>
          })}
        </ul>
      </div>
    </Container>
  </div>
}