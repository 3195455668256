import {catchError, filter, map, mergeMap, of} from "rxjs";
import {logErrorRx} from "../../utils/logError";
import {RootEpic} from "../../app/store";
import {fetchOrder, fetchOrderFailed, fetchOrderSucceeded} from "./orderDetailSlice";
import {PaymentService} from "../../services/paymentService";

export const fetchOrderEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchOrder.match),
    mergeMap(action => {
      return PaymentService.fetchSalesOrder(action.payload)
        .pipe(
          map(fetchOrderSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchOrderFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}