import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  clearError,
  createCampaign,
  fetchCampaign,
  reset,
  selectCampaign,
  selectConfig,
  selectError,
  selectIsDeleting,
  selectIsLoading,
  selectIsSubmitting,
  selectShouldClose,
  updateCampaign
} from "../../redux/campaign/campaignDetailSlice";
import {fetchCohorts, selectCohorts, selectIsGettingCohorts} from "../../redux/cohort/cohortSlice";
import {MessageCampaignModel} from "../../models/messageCampaign";
import {Container} from "../containers/Container";
import {CloseIcon, LoadingSpinnerIcon} from "../icons";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Heading4, HeroHeading2, TextStyles} from "../text";
import {MessageCampaignFilterModel, MessageCampaignNodeModel} from "../../models/messageCampaignNode";
import {BodyInput} from "../inputs";
import Moment from "moment";
import {CampaignDetailMessageNodeView} from "./CampaignDetailMessageNodeView";
import {CampaignDetailFilterNodeView} from "./CampaignDetailFilterNodeView";
import {Button} from "../buttons/Button";
import {ObjectId} from "bson";
import {CampaignTreeView} from "./CampaignTreeView";

export interface CampaignDetailViewProps {
  onClose: () => void
  campaignId?: string
}

export const CampaignDetailView: React.FC<CampaignDetailViewProps> = ({ onClose, campaignId }) => {
  const dispatch = useAppDispatch()

  const shouldClose = useAppSelector(selectShouldClose)
  const error = useAppSelector(selectError)
  const campaign = useAppSelector(selectCampaign)
  const config = useAppSelector(selectConfig)
  const isLoading = useAppSelector(selectIsLoading)
  const cohorts = useAppSelector(selectCohorts)
  const isDeleting = useAppSelector(selectIsDeleting)
  const isGettingCohorts = useAppSelector(selectIsGettingCohorts)
  const isSubmitting = useAppSelector(selectIsSubmitting)

  const [payload, setPayload] = useState<MessageCampaignModel>()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    if (campaignId) {
      dispatch(fetchCampaign(campaignId))
    } else {
      const defaultCampaign = new MessageCampaignModel()
      defaultCampaign.status = 'draft'

      const filterNode = new MessageCampaignNodeModel()
      filterNode.type = 'filter'
      filterNode.filter = new MessageCampaignFilterModel()
      filterNode.filter.type = 'in'
      defaultCampaign.nodes = [filterNode]
      setPayload(defaultCampaign)
    }

    dispatch(fetchCohorts())
  }, [campaignId, dispatch])

  useEffect(() => {
    campaign && setPayload(campaign)
  }, [campaign])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  if (isLoading || !payload || isGettingCohorts) {
    return <div
      className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    >
      <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
        <div className="w-full no-scrollbar flex-1 flex flex-col">
          <div className={`${isLoading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
            <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
          </div>
        </div>
      </Container>
    </div>
  }

  return <form
    className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    onSubmit={e => {
      e.preventDefault()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      isDismissable={false}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            dispatch(clearError())
          },
          style: 'default'
        }
      ]}
    />

    <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
      <div className="w-full no-scrollbar flex-1 flex flex-col">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`${isLoading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
          <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
        </div>

        <div className={`mt-4 mb-6 lg:mb-8`}>
          <HeroHeading2>{config === 'create' ? 'Create Campaign' : 'Update Campaign'}</HeroHeading2>
        </div>

        <div className="flex flex-col space-y-5">
          <div>
            <Heading4 className="mb-2">Campaign Name</Heading4>
            <BodyInput
              type="text"
              placeholder="Campaign Name"
              defaultValue={payload?.name}
              onChange={e => {
                if (payload) {
                  payload.name = e.target.value
                  setPayload(new MessageCampaignModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Starts On</Heading4>
            <BodyInput
              type="datetime-local"
              placeholder="Starts On"
              defaultValue={payload?.startOn && Moment(payload.startOn).format('YYYY-MM-DDTHH:mm')}
              onChange={e => {
                const date = Moment(e.target.value)
                if (payload && date.isValid()) {
                  payload.startOn = date.toDate()
                  setPayload(new MessageCampaignModel(payload))
                } else if (payload) {
                  payload.startOn = undefined
                  setPayload(new MessageCampaignModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Ends On</Heading4>
            <BodyInput
              type="datetime-local"
              placeholder="Ends On"
              defaultValue={payload?.endOn && Moment(payload.endOn).format('YYYY-MM-DDTHH:mm')}
              onChange={e => {
                const date = Moment(e.target.value)
                if (payload && date.isValid()) {
                  payload.endOn = date.toDate()
                  setPayload(new MessageCampaignModel(payload))
                } else if (payload) {
                  payload.endOn = undefined
                  setPayload(new MessageCampaignModel(payload))
                }
              }}
            />
          </div>

          {
            payload?.nodes.map((node, index) => {
              if (node.type === 'filter') {
                return <CampaignDetailFilterNodeView
                  node={node}
                  index={index}
                  onDelete={(deletedNode) => {
                    payload.nodes = payload.nodes.filter(n => n.id !== deletedNode.id)
                    setPayload(new MessageCampaignModel(payload))
                  }}
                  onChange={(updatedNode) => {
                    payload.nodes[index] = new MessageCampaignNodeModel(updatedNode)
                    setPayload(new MessageCampaignModel(payload))
                  }}
                />
              } else if (node.type === 'email') {
                return <CampaignDetailMessageNodeView
                  node={node}
                  index={index}
                  type="email"
                  onDelete={(deletedNode) => {
                    payload.nodes = payload.nodes.filter(n => n.id !== deletedNode.id)
                    setPayload(new MessageCampaignModel(payload))
                  }}
                  onChange={(updatedNode) => {
                    payload.nodes[index] = new MessageCampaignNodeModel(updatedNode)
                    setPayload(new MessageCampaignModel(payload))
                  }}
                />
              } else if (node.type === 'prompt') {
                return <CampaignDetailMessageNodeView
                  node={node}
                  index={index}
                  type="prompt"
                  onDelete={(deletedNode) => {
                    payload.nodes = payload.nodes.filter(n => n.id !== deletedNode.id)
                    setPayload(new MessageCampaignModel(payload))
                  }}
                  onChange={(updatedNode) => {
                    payload.nodes[index] = new MessageCampaignNodeModel(updatedNode)
                    setPayload(new MessageCampaignModel(payload))
                  }}
                />
              } else if (node.type === 'push') {
                return <CampaignDetailMessageNodeView
                  node={node}
                  index={index}
                  type="push"
                  onDelete={(deletedNode) => {
                    payload.nodes = payload.nodes.filter(n => n.id !== deletedNode.id)
                    setPayload(new MessageCampaignModel(payload))
                  }}
                  onChange={(updatedNode) => {
                    payload.nodes[index] = new MessageCampaignNodeModel(updatedNode)
                    setPayload(new MessageCampaignModel(payload))
                  }}
                />
              }
            })
          }

          <select
            id="add"
            name="add"
            value={'blank'}
            onClick={e => {
              e.stopPropagation()
            }}
            onChange={e => {
              if (!payload) return

              switch (e.target.value) {
                case 'add_filter': {
                  const filterNode = new MessageCampaignNodeModel()
                  const filter = new MessageCampaignFilterModel()
                  filter.type = 'in'
                  filterNode.id = new ObjectId().toHexString()
                  filterNode.type = 'filter'
                  filterNode.filter = filter

                  payload.nodes.push(filterNode)
                  setPayload(new MessageCampaignModel(payload))
                  break;
                }

                case 'add_email': {
                  const emailNode = new MessageCampaignNodeModel()
                  emailNode.type = 'email'
                  emailNode.id = new ObjectId().toHexString()

                  payload.nodes.push(emailNode)
                  setPayload(new MessageCampaignModel(payload))
                  break;
                }

                case 'add_prompt': {
                  const promptNode = new MessageCampaignNodeModel()
                  promptNode.type = 'prompt'
                  promptNode.id = new ObjectId().toHexString()

                  payload.nodes.push(promptNode)
                  setPayload(new MessageCampaignModel(payload))
                  break;
                }

                case 'add_push': {
                  const promptNode = new MessageCampaignNodeModel()
                  promptNode.type = 'push'
                  promptNode.id = new ObjectId().toHexString()

                  payload.nodes.push(promptNode)
                  setPayload(new MessageCampaignModel(payload))
                  break;
                }
              }
            }}
            className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
          >
            <option key={'blank'} value={'blank'}>Add Node</option>
            <option key={'add_filter'} value={'add_filter'}>Add Filter</option>
            <option key={'add_email'} value={'add_email'}>Add Email</option>
            <option key={'add_prompt'} value={'add_prompt'}>Add Prompt</option>
            <option key={'add_push'} value={'add_push'}>Add Push</option>
          </select>

          <div className={`${isLoading || error ? 'hidden' : ''} mt-4 w-full items-centered space-x-0 space-y-3 lg:flex-row lg:space-x-2 lg:space-y-5`}>
            <div>
              <Button
                isFullWidth={true}
                isLoading={isSubmitting}
                buttonStyle="primary"
                size="lg"
                onClick={() => {
                  if (config === 'create' && payload) {
                    dispatch(createCampaign({ campaign: payload }))
                  } else if (config === 'update' && campaignId && payload) {
                    dispatch(updateCampaign({ campaign: payload, id: campaignId }))
                  }
                }}
              >
                {config === 'create' ? 'Create Campaign' : 'Update Campaign'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </form>
}