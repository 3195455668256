import {MenuItemSizeType} from "./menuItemSizeType";
import {CurrencyCode} from "./currencyCode";
import {MealJson, MealModel} from "./meal";
import {ProductSkuOptionValueJson, ProductSkuOptionValueModel} from "./productSkuOptionValue";

export interface ProductSkuJson {
  _id?: string
  size?: MenuItemSizeType
  currency_code?: CurrencyCode
  price?: number
  quantity?: number
  delivered_meal?: MealJson
  menu_item_id?: string
  barcode?: string
  option_values?: ProductSkuOptionValueJson[]
  created_at?: Date
  updated_at?: Date
}

export class ProductSkuModel {
  createdAt?: Date
  currencyCode?: CurrencyCode
  deliveredMeal?: MealModel
  id?: string
  productId?: string
  optionValues: ProductSkuOptionValueModel[] = []
  quantity?: number
  size?: MenuItemSizeType
  price?: number
  updatedAt?: Date
  barcode?: string

  constructor(model?: ProductSkuModel | null) {
    if (!model) return

    this.createdAt = model.createdAt
    this.currencyCode = model.currencyCode
    this.deliveredMeal = model.deliveredMeal && new MealModel(model.deliveredMeal)
    this.id = model.id
    this.productId = model.productId
    this.optionValues = model.optionValues.map(i => new ProductSkuOptionValueModel(i))
    this.quantity = model.quantity
    this.size = model.size
    this.barcode = model.barcode
    this.price = model.price
    this.updatedAt = model.updatedAt
  }

  static fromJson(json?: ProductSkuJson): ProductSkuModel | undefined {
    if (!json) return

    return {
      createdAt: json.created_at,
      currencyCode: json.currency_code,
      deliveredMeal: MealModel.fromJson(json.delivered_meal),
      id: json._id,
      productId: json.menu_item_id,
      optionValues: json.option_values
        ?.map((i) => ProductSkuOptionValueModel.fromJson(i))
        .flatMap(i => i ? [i] : []) ?? [],
      quantity: json.quantity,
      size: json.size,
      barcode: json.barcode,
      price: json.price,
      updatedAt: json.updated_at
    }
  }
}