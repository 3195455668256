import {from, map, mergeMap, Observable, of} from "rxjs";
import {LocationModel} from "../models/location";
import {LocationApi} from "../apis/locationApi";
import {AutocompletePrediction, LatLng} from "googlemaps";
import {realmService} from "../realm/realmService";

export const LocationService = {
  fetchCurrentLatLng(): Observable<LatLng> {
    return LocationApi.getCurrentLatLng()
  },
  fetchApproxLatLng(): Observable<LatLng> {
    return LocationApi.getApproxLatLng()
  },
  fetchCurrentLocation(): Observable<LocationModel> {
    const latLng: Observable<LatLng> = LocationApi.getCurrentLatLng()
    return latLng.pipe(
      mergeMap(LocationApi.getLocationFromLatLong),
      map(location => {
        location.type = 'current'
        return location
      })
    )
  },
  fetchLocationPermissions(): Observable<boolean> {
    if (typeof window !== "undefined" && navigator && navigator.permissions) {
      return from(navigator.permissions.query({ name:'geolocation' })
        .then(result => result.state === 'granted'))
    } else {
      return of(false)
    }
  },
  fetchApproxLocation(): Observable<LocationModel> {
    const latLng: Observable<LatLng> = LocationApi.getApproxLatLng()
    return latLng.pipe(
      mergeMap(LocationApi.getLocationFromLatLong),
      map(location => {
        location.type = 'approximate'
        return location
      })
    )
  },
  fetchAutocompletePredictions(query: string, latLng: LatLng): Observable<AutocompletePrediction[]> {
    return LocationApi.getAutocompletePredictions(query, latLng)
  },
  fetchLocationFromPlaceId(placeId: string): Observable<LocationModel> {
    return LocationApi.getLocationFromPlaceId(placeId)
      .pipe(
        map(location => {
          location.type = 'delivery'
          return location
        })
      )
  },
  observeDeliveryLocations(): Observable<LocationModel[]> {
    return realmService.query<LocationModel>(LocationModel.schema.name)
      .where({ type: { $ne: 'pickup', $exists: true } })
      .observeAll()
      .pipe(map(locations => {
        return locations.sort((l1, l2) => (l2.updatedAt?.getTime() ?? 0) - (l1.updatedAt?.getTime() ?? 0))
      }))
      .pipe(map(entities => entities.map(entity => new LocationModel(entity))))
  },
  fetchDeliveryLocations(): Observable<LocationModel[]> {
    return realmService.query<LocationModel>(LocationModel.schema.name)
      .where({ type: { $ne: 'pickup', $exists: true } })
      .fetchAll()
      .pipe(map(locations => {
        return locations.sort((l1, l2) => (l2.updatedAt?.getTime() ?? 0) - (l1.updatedAt?.getTime() ?? 0))
      }))
      .pipe(map(entities => entities.map(entity => new LocationModel(entity))))
  },
  observePrimaryDeliveryLocation(): Observable<LocationModel | null> {
    return realmService.query<LocationModel>(LocationModel.schema.name)
      .where({ isPrimary: true, type: { $ne: 'pickup', $exists: true } })
      .observeOne()
      .pipe(map(entity => entity && new LocationModel(entity)))
  },
  fetchPrimaryDeliveryLocation(): Observable<LocationModel | null> {
    return realmService.query<LocationModel>(LocationModel.schema.name)
      .where({ isPrimary: true, type: { $ne: 'pickup', $exists: true } })
      .fetchOne()
      .pipe(map(entity => entity && new LocationModel(entity)))
  },
  deleteLocation(location: LocationModel): Observable<void> {
    return realmService.query<LocationModel>(LocationModel.schema.name)
      .where({ locationId: location.locationId })
      .delete()
  },
  setPrimaryDeliveryLocation(location: LocationModel): Observable<LocationModel> {
    const primaryLocation = new LocationModel(location)
    primaryLocation.isPrimary = true

    const createOrUpdatePrimaryLocation = realmService.query<LocationModel>(LocationModel.schema.name)
      .where({ latitude: primaryLocation.latitude, longitude: primaryLocation.longitude })
      .fetchOne()
      .pipe(
        map(l => new LocationModel(l)),
        map(matchingLocation => {
          if (matchingLocation) {
            primaryLocation._id = matchingLocation._id
            primaryLocation.locationId = matchingLocation.locationId

            return primaryLocation
          } else {
            return primaryLocation
          }
        }),
        mergeMap(l => realmService.createOrModifyEntity<LocationModel>(l)
          .pipe(map(l => new LocationModel(l))))
      )

    const updateNonPrimaryLocations = realmService.query<LocationModel>(LocationModel.schema.name)
      .where({ locationId: { $ne: location.locationId }, type: { $ne: 'pickup', $exists: true } })
      .fetchAll()
      .pipe(map(entities => entities.map(e => new LocationModel(e))))
      .pipe(
        mergeMap((locations) => {
          const nonPrimaryLocations = locations.map(l => {
            l.isPrimary = false
            return l
          })
          return realmService.createOrModifyEntities(nonPrimaryLocations)
        })
      )

    switch (location.type) {
      case "current":
        return updateNonPrimaryLocations.pipe(map(() => primaryLocation))
      case "approximate":
        return updateNonPrimaryLocations.pipe(map(() => primaryLocation))
      case "pickup":
        return updateNonPrimaryLocations.pipe(map(() => primaryLocation))
      default:
        return updateNonPrimaryLocations.pipe(mergeMap(() => createOrUpdatePrimaryLocation))
    }
  }
}