import {BaseError} from "./baseError";
import {LocalizationInfo} from "react-i18next";

export type AffiliateValidationErrorCode = 'INVALID_EMAIL'
  | 'EMAIL_IS_EMPTY'
  | 'FIRST_NAME_IS_EMPTY'
  | 'LAST_NAME_IS_EMPTY'
  | 'CODE_IS_EMPTY'
  | 'INVALID_CODE_LENGTH'
  | 'INVALID_PHONE_NUMBER'
  | 'PHONE_IS_EMPTY'

export class AffiliateValidationError extends BaseError<AffiliateValidationErrorCode> {
  localizedMessage: LocalizationInfo | string
  localizedTitle: LocalizationInfo | string

  constructor(code: AffiliateValidationErrorCode) {
    super(code)

    switch (code) {
      case "INVALID_EMAIL":
        this.localizedMessage = { key: "affiliate:affiliate_error_message_invalid_email"}
        this.localizedTitle = { key: "affiliate:affiliate_error_title_invalid_email"}
        break
      case "EMAIL_IS_EMPTY":
        this.localizedMessage = { key: "affiliate:affiliate_error_message_email_is_empty"}
        this.localizedTitle = { key: "affiliate:affiliate_error_title_email_is_empty"}
        break
      case "FIRST_NAME_IS_EMPTY":
        this.localizedMessage = { key: "affiliate:affiliate_error_message_first_name_is_empty"}
        this.localizedTitle = { key: "affiliate:affiliate_error_title_first_name_is_empty"}
        break
      case "LAST_NAME_IS_EMPTY":
        this.localizedMessage = { key: "affiliate:affiliate_error_message_last_name_is_empty"}
        this.localizedTitle = { key: "affiliate:affiliate_error_title_last_name_is_empty"}
        break
      case "CODE_IS_EMPTY":
        this.localizedMessage = { key: "affiliate:affiliate_error_message_code_is_empty"}
        this.localizedTitle = { key: "affiliate:affiliate_error_title_code_is_empty"}
        break
      case "INVALID_CODE_LENGTH":
        this.localizedMessage = { key: "affiliate:affiliate_error_message_invalid_code_length"}
        this.localizedTitle = { key: "affiliate:affiliate_error_title_invalid_code_length"}
        break
      case "INVALID_PHONE_NUMBER":
        this.localizedMessage = { key: "affiliate:affiliate_error_message_invalid_phone_number"}
        this.localizedTitle = { key: "affiliate:affiliate_error_title_invalid_phone_number"}
        break
      case "PHONE_IS_EMPTY":
        this.localizedMessage = { key: "affiliate:affiliate_error_message_phone_number_is_empty"}
        this.localizedTitle = { key: "affiliate:affiliate_error_title_phone_number_is_empty"}
        break
    }
  }
}