import {CurrencyCode} from "./currencyCode";
import {SalesOrderJson, SalesOrderModel} from "./salesOrder";

export type CommissionType = 'store_credit' | 'cash'
export type CommissionStatus = 'pending' | 'approved' | 'denied' | 'paid'
export type CommissionVendorChargeStatus = 'unpaid' | 'paid'

export interface CommissionJson {
  amount?: number
  type: CommissionType
  status: CommissionStatus
  order?: SalesOrderJson
  vendor_charge_status: CommissionVendorChargeStatus
  currency_code?: CurrencyCode
  createdAt?: Date
  updatedAt?: Date
}

export class CommissionModel {
  amount?: number
  type: CommissionType = 'store_credit'
  status: CommissionStatus = 'pending'
  order?: SalesOrderModel
  vendorChargeStatus: CommissionVendorChargeStatus = 'unpaid'
  currencyCode: CurrencyCode = 'USD'
  createdAt?: Date
  updatedAt?: Date

  static fromJson(json?: CommissionJson): CommissionModel | undefined {
    if (!json) return

    const model = new CommissionModel()
    model.amount = json.amount
    model.type = json.type
    model.status = json.status
    model.order = SalesOrderModel.fromJson(json.order)
    model.vendorChargeStatus = json.vendor_charge_status
    model.currencyCode = json.currency_code ?? 'USD'
    model.createdAt = json.createdAt
    model.updatedAt = json.updatedAt

    return model
  }
}