import {PushNotificationModel} from "../../models/pushNotification";
import {useTranslation} from "next-i18next";
import React, {useEffect, useRef, useState} from "react";
import {BaseError} from "../../errors/baseError";
import {MessageApi} from "../../apis/messageApi";
import {GenericError} from "../../errors/genericError";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchCohorts, selectCohorts, selectIsGettingCohorts} from "../../redux/cohort/cohortSlice";
import {Container} from "../containers/Container";
import {CloseIcon, MailFilledIcon} from "../icons";
import {Modal} from "../modals/Modal";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Heading4, HeroHeading2, TextStyles} from "../text";
import {BodyInput} from "../inputs";
import {Button} from "../buttons/Button";
import {showNotification} from "../../redux/navigation/navigationSlice";

export interface PushNotificationDetailSendModalProps {
  isOpen: boolean
  pushNotification: PushNotificationModel | string
  isTest: boolean
  onClose: () => any
}

export const PushNotificationDetailSendModal: React.FC<PushNotificationDetailSendModalProps> = ({ isOpen, isTest, onClose, pushNotification }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();

  const emailRef = useRef<HTMLInputElement>(null)

  const cohorts = useAppSelector(selectCohorts)
  const isGettingCohorts = useAppSelector(selectIsGettingCohorts)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedCohort, setSelectedCohort] = useState<string>()
  const [error, setError] = useState<BaseError<any>>()

  useEffect(() => {
    dispatch(fetchCohorts())
  }, [dispatch])

  const onSubmit = () => {
    const emailAddress = emailRef.current?.value
    if (!emailAddress && isTest) return

    let sendObservable;
    if (typeof pushNotification === "string") {
      sendObservable = MessageApi.sendPushNotification(
        emailAddress ? [emailAddress] : undefined,
        undefined,
        pushNotification,
        !isTest ? selectedCohort : undefined
      )
    } else {
      MessageApi.setTestEmail(emailAddress)
      sendObservable = MessageApi.sendPushNotification(
        emailAddress ? [emailAddress] : undefined,
        isTest ? pushNotification.toJson() : undefined,
        isTest ? undefined : pushNotification.id,
        !isTest ? selectedCohort : undefined
      )
    }

    setIsSubmitting(true)
    sendObservable.subscribe({
      next: () => {
        setIsSubmitting(false)
        onClose()

        dispatch(showNotification({ title: 'Success', message: 'Successfully sent pushNotification', status: 'success' }))
      },
      error: (e) => {
        setIsSubmitting(false)

        if (e instanceof BaseError) {
          setError(e)
        } else {
          const error = new GenericError('PushNotification_ERROR')
          error.localizedMessage = 'There was a problem sending this pushNotification'
          error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
          error.error = e
          setError(error)
        }
      },
    })
  }

  return <Modal
    className="md:max-w-lg"
    isOpen={isOpen}
    onClose={() =>{
      onClose()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      isDismissable={false}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            setError(undefined)
          },
          style: 'default'
        }
      ]}
    />
    <form
      className="max-h-screen md:max-h-screen-1/2 flex flex-col w-full sticky"
      onSubmit={e => {
        e.preventDefault()
      }}
    >
      <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
        <div className="w-full no-scrollbar flex-1 flex flex-col">
          <div className="-ml-3 -mt-3 flex items-center justify-between">
            <button
              type="button"
              className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
              onClick={() => {
                onClose()
              }}
            >
              <CloseIcon className="h-4 h-4" />
            </button>
          </div>

          <div className={`mt-4 mb-6 lg:mb-8`}>
            <HeroHeading2>{isTest ? 'Send Test PushNotification' : 'Send PushNotification'}</HeroHeading2>
          </div>

          <div className={`flex flex-col space-y-5`}>
            <div>
              <Heading4 className="mb-2">{t('auth:auth_placeholder_email')}</Heading4>
              <BodyInput
                type="email"
                autoComplete="email"
                ref={emailRef}
                defaultValue={MessageApi.getTestEmail() ?? undefined}
                leadingIcon={<MailFilledIcon />}
                placeholder={t('auth:auth_placeholder_email')}
              />
            </div>

            <div className={`${!cohorts.length && !isTest && !isGettingCohorts ? '' : 'hidden'}`}>
              <Heading4 className="mb-2">Mixpanel Cohort ID</Heading4>
              <BodyInput
                type="text"
                placeholder="Mixpanel Cohort ID"
                defaultValue={selectedCohort}
                onChange={e => {
                  setSelectedCohort(e.target.value)
                }}
              />
            </div>

            <div className={`${(!cohorts.length && !isGettingCohorts) || isTest ? 'hidden' : ''}`}>
              <Heading4 className="mb-2">Cohort</Heading4>
              <select
                id="cohort"
                name="cohort"
                onClick={e => {
                  e.stopPropagation()
                }}
                defaultValue={selectedCohort}
                onChange={e => {
                  setSelectedCohort(e.target.value)
                }}
                className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
              >
                <option key={'none'} value={''} />
                {
                  cohorts
                    .map(i => {
                      return <option key={i.id?.toString()} value={i.id?.toString()}>{i.name}</option>
                    })
                }
              </select>
            </div>

            <Button
              isFullWidth={true}
              size="lg"
              type="submit"
              isLoading={isSubmitting}
              buttonStyle="primary"
              onClick={onSubmit}
            >
              {isTest ? 'Send Test Push' : 'Send Push'}
            </Button>
          </div>
        </div>
      </Container>
    </form>
  </Modal>
}