import {LocalizationInfo} from 'react-i18next'

export abstract class BaseError<C extends string> extends Error {
  code: C
  localizedMessage?: LocalizationInfo | string
  localizedTitle?: LocalizationInfo | string
  error?: Error | null
  name: string
  message: string = typeof this.localizedMessage === "string" ? this.localizedMessage ?? '' : ''

  protected constructor(code: C, error?: Error | null) {
    super();
    this.code = code
    this.name = this.code
    this.error = error
  }
}