export type StoreSelectorBadgeType = 'normal' | 'free'

export interface StoreSelectorOptionBadgeJson {
  _id?: string
  title?: string
  type?: StoreSelectorBadgeType
}

export class StoreSelectorOptionBadgeModel {
  id?: string
  title?: string
  type?: StoreSelectorBadgeType

  static fromJson(json?: StoreSelectorOptionBadgeJson): StoreSelectorOptionBadgeModel | undefined {
    if (!json) return

    const model = new StoreSelectorOptionBadgeModel()

    model.id = json._id
    model.title = json.title
    model.type = json.type

    return model
  }
}