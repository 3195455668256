import {from, map, mergeMap, Observable, of} from "rxjs";
import {realmService} from "../realm/realmService";
import {UserModel} from "../models/user";
import {UserApi} from "../apis/userApi";
import {SetContactInfoPayload} from "../models/setContactInfoPayload";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import {FitnessProfileModel} from "../models/fitnessProfile";
import {OnboardingLinkResponseModel} from "../models/onboardingLinkResponse";

let deviceFingerprint: string;

export const UserService = {
  observeUser(): Observable<UserModel | null> {
    return UserApi.getUser()
      .pipe(
        mergeMap(user => realmService.createOrModifyEntity<UserModel>(user)),
        map(user => user!),
        mergeMap((user) => {
          return realmService.query<UserModel>(UserModel.schema.name)
            .where({ userId: user.userId })
            .observeOne()
            .pipe(map(entity => entity && new UserModel(entity)))
        })
      )
  },
  fetchUser(): Observable<UserModel | null> {
    return UserApi.getUser()
      .pipe(mergeMap(user => {
        return realmService.createOrModifyEntity<UserModel>(user)
          .pipe(map(entity => {
            if (entity) {
              entity.onesignalEmailAuthHash = user.onesignalEmailAuthHash
              entity.onesignalExternalIdAuthHash = user.onesignalExternalIdAuthHash
            }
            return entity && new UserModel(entity)
          }))
      }))
  },
  fetchOnboardingLink(): Observable<OnboardingLinkResponseModel> {
    return UserApi.getOnboardingLink()
  },
  updateAppReferrer(referrer: string): Observable<void> {
    return UserApi.updateAppReferrer(referrer)
  },
  updateContactInfo(payload: SetContactInfoPayload): Observable<UserModel | null> {
    return UserApi.setContactInfo(payload)
      .pipe(
        mergeMap(UserApi.getUser),
        mergeMap(user => realmService.createOrModifyEntity<UserModel>(user)),
        map(entity => entity && new UserModel(entity))
      )
  },
  updateFitnessProfile(fitnessProfile: FitnessProfileModel): Observable<void> {
    return this.fetchUser()
      .pipe(
        mergeMap(user => {
          if (user) {
            user.fitnessProfile = new FitnessProfileModel(fitnessProfile)
            return realmService.createOrModifyEntity(user)
              .pipe(map(() => {}))
          } else {
            return new Observable<void>(subscriber => {
              subscriber.next()
            })
          }
        })
      )
  },
  getDeviceFingerprint(): Observable<string> {
    const fpPromise = FingerprintJS.load({
      token: process.env.NEXT_PUBLIC_FINGERPRINT_JS_TOKEN!,
      endpoint: process.env.NEXT_PUBLIC_FINGERPRINT_JS_DOMAIN
    })

    if (deviceFingerprint) return of(deviceFingerprint)

    return from(fpPromise.then(fp => fp.get()).then(result => {
      deviceFingerprint = result.visitorId
      return result.visitorId
    }))
  }
}