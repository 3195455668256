import {BaseError} from "./baseError";
import {LocalizationInfo} from "react-i18next";

export type PaymentValidationErrorCode = 'INVALID_ADDRESS' | string

export class PaymentValidationError extends BaseError<PaymentValidationErrorCode> {
  localizedMessage: LocalizationInfo | string
  localizedTitle: LocalizationInfo | string

  constructor(code: PaymentValidationErrorCode) {
    super(code)

    switch (code) {
      case "INVALID_ADDRESS":
        this.localizedMessage = { key: "payment:payment_error_message_invalid_billing_address"}
        this.localizedTitle = { key: "payment:payment_error_title_invalid_billing_address"}
        break
      default:
        this.localizedMessage = ""
        this.localizedTitle = ""
        break
    }
  }
}