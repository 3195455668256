import {EntityModel} from "./entityModel";
import {ObjectSchema} from "mongodb-realm";
import {BaseRealmService} from "../realm/realmService";
import {map, Observable} from "rxjs";
import {ObjectId} from "bson";

export enum NutritionProtocolConfigType {
  daily = 'daily',
  weekly = 'weekly'
}

export class NutritionProtocolConfigModel implements EntityModel<NutritionProtocolConfigModel> {
  public static schema: ObjectSchema = {
    name: 'NutritionProtocolConfigEntity',
    properties: {
      _id: 'objectId',
      _partition: 'string',
      isEnabled: { type: 'bool', default: true },
      lastUpdatedAt: 'date?',
      numBreakfastMeals: { type: 'int', default: 1 },
      numDinnerMeals: { type: 'int', default: 2 },
      numLunchMeals: { type: 'int', default: 1 },
      numSnackMeals: { type: 'int', default: 2 },
      nutritionProtocolConfigId: 'string?',
      type: 'string?',
    },
    primaryKey: '_id',
  }

  schema = NutritionProtocolConfigModel.schema

  _id: ObjectId = new ObjectId()
  _partition: string = ''
  nutritionProtocolConfigId?: string
  isEnabled: boolean = true
  lastUpdatedAt?: Date
  numBreakfastMeals?: number = 1
  numDinnerMeals?: number = 2
  numLunchMeals?: number = 1
  numSnackMeals?: number = 2
  type: NutritionProtocolConfigType = NutritionProtocolConfigType.weekly

  constructor(entity?: NutritionProtocolConfigModel) {
    if (!entity) return

    this._id = entity._id
    this._partition = entity._partition
    this.nutritionProtocolConfigId = entity.nutritionProtocolConfigId
    this.isEnabled = entity.isEnabled
    this.lastUpdatedAt = entity.lastUpdatedAt
    this.numBreakfastMeals = entity.numBreakfastMeals
    this.numDinnerMeals = entity.numDinnerMeals
    this.numLunchMeals = entity.numLunchMeals
    this.numSnackMeals = entity.numSnackMeals
    this.type = entity.type

    return this
  }

  toEntityObservable(realmService: BaseRealmService): Observable<NutritionProtocolConfigModel> {
    return realmService.query<NutritionProtocolConfigModel>(NutritionProtocolConfigModel.schema.name)
      .where({ nutritionProtocolConfigId: this.nutritionProtocolConfigId })
      .fetchOne()
      .pipe(
        map(existingEntity => {
          if (existingEntity) {
            this._id = existingEntity._id
          }

          return this
        })
      )
  }
}