import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {useRouter} from "next/router";
import {Modal} from "../modals/Modal";
import {OrderDetailView} from "./OrderDetailView";

export const OrderDetailModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();
  const router = useRouter()
  const orderId = router.query.order_id as string

  return <Modal
    className="md:max-w-3xl"
    onClose={() => onClose()}
    isOpen={isOpen('order_detail')}>
    <OrderDetailView orderId={orderId} onClose={() => { onClose() }} />
  </Modal>
}