import React, {RefObject, useCallback, useEffect, useRef, useState} from "react";
import {Heading4, TextStyles} from "../text";
import {BodyInput} from "../inputs";
import {AsYouType, CountryCode, parsePhoneNumber} from "libphonenumber-js";
import {PhoneNumberUtils} from "../../utils/phoneNumberUtils";
import {Button} from "../buttons/Button";
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  selectError,
  selectIsSaving,
  selectShouldClose,
  selectValidationError
} from "../../redux/user/contactInfoEntrySlice";
import {selectPrimaryLocation, selectUser} from "../../redux/user/userSlice";
import {selectCustomerForAdmin} from "../../redux/checkout/checkoutSlice";
import {LocalizationInfo} from "react-i18next";

export interface ContactInfoEntryContainerViewProps {
  firstNameRef: RefObject<HTMLInputElement>
  lastNameRef: RefObject<HTMLInputElement>
  emailRef: RefObject<HTMLInputElement>
  phoneNumberRef: RefObject<HTMLInputElement>
  countryCodeRef: RefObject<HTMLSelectElement>
  config?: 'standard' | 'checkout'
}

export const ContactInfoEntryContainerView: React.FC<ContactInfoEntryContainerViewProps> = ({ firstNameRef, lastNameRef, countryCodeRef, emailRef, phoneNumberRef, config }) => {
  const { t } = useTranslation()
  const user = useAppSelector(selectUser)
  const customer = useAppSelector(selectCustomerForAdmin)
  const primaryLocation = useAppSelector(selectPrimaryLocation)
  const validationError = useAppSelector(selectValidationError)

  const isCheckingOut = config === 'checkout'

  const [emailValidation, setEmailValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() => () => true)
  const [firstNameValidation, setFirstNameValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() =>() => true)
  const [lastNameValidation, setLastNameValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() =>() => true)
  const [phoneNumberValidation, setPhoneNumberValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() =>() => true)

  const [phoneNumberInputValue, setPhoneNumberInputValue] = useState(user?.phoneNumber ?? "");
  const [countryCode, setCountryCode] = useState<CountryCode>( primaryLocation?.address?.countryCode as CountryCode ?? 'US');

  useEffect(() => {
    let parsedCountryCode: CountryCode | "" | undefined
    let formattedPhoneNumber: string = ""
    const fallbackCountryCode = primaryLocation?.address?.countryCode as CountryCode ?? 'US'

    const phoneNumber = isCheckingOut && user?.roles.includes('admin') ? customer?.phoneNumber ?? user?.phoneNumber : user?.phoneNumber

    try {
      const parsedNumber = phoneNumber ? parsePhoneNumber(phoneNumber, fallbackCountryCode) : undefined
      formattedPhoneNumber = parsedNumber ? parsedNumber.formatNational() : formattedPhoneNumber
      parsedCountryCode = phoneNumber && parsePhoneNumber(phoneNumber, fallbackCountryCode).country
      parsedCountryCode = parsedCountryCode === 'GG' ? 'GB' : parsedCountryCode
    } catch (e) {

    }

    setPhoneNumberInputValue(formattedPhoneNumber)
    if (countryCodeRef.current && parsedCountryCode) {
      countryCodeRef.current.value = parsedCountryCode
    }
    setCountryCode(parsedCountryCode ? parsedCountryCode : fallbackCountryCode)
  }, [])

  useEffect(() => {
    setFirstNameValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "FIRST_NAME_IS_EMPTY":
          return validationError?.localizedMessage
        default:
          break;
      }
    })

    setLastNameValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "LAST_NAME_IS_EMPTY":
          return validationError?.localizedMessage
        default:
          break;
      }
    })

    setPhoneNumberValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "PHONE_NUMBER_IS_EMPTY":
          return validationError?.localizedMessage
        case "INVALID_PHONE_NUMBER":
          return validationError?.localizedMessage
        default:
          break;
      }
    })

    setEmailValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "INVALID_EMAIL":
          return validationError?.localizedMessage
        case "EMAIL_IS_EMPTY":
          return validationError?.localizedMessage
        default:
          break;
      }
    })

  }, [validationError])

  return <div className="flex flex-col space-y-5">
    <div className="grid grid-cols-2 gap-3">
      <div>
        <Heading4 className="mb-2">{t('auth:auth_placeholder_first_name')}</Heading4>
        <BodyInput
          type="text"
          autoComplete="given-name"
          ref={firstNameRef}
          defaultValue={isCheckingOut && user?.roles?.includes('admin') ? customer?.firstName ?? user?.firstName : user?.firstName}
          validate={firstNameValidation}
          placeholder={ t('auth:auth_placeholder_first_name') }
        />
      </div>

      <div>
        <Heading4 className="mb-2">{t('auth:auth_placeholder_last_name')}</Heading4>
        <BodyInput
          type="text"
          autoComplete="family-name"
          ref={lastNameRef}
          defaultValue={isCheckingOut && user?.roles?.includes('admin') ? customer?.lastName ?? user?.lastName : user?.lastName}
          validate={lastNameValidation}
          placeholder={ t('auth:auth_placeholder_last_name') }
        />
      </div>
    </div>

    <div className={`${user?.roles.includes('anonymous') || user?.roles.includes('admin') && isCheckingOut ? '' : 'hidden'}`}>
      <Heading4 className="mb-2">{t('auth:auth_placeholder_email')}</Heading4>
      <BodyInput
        type="email"
        autoComplete="email"
        ref={emailRef}
        defaultValue={isCheckingOut && user?.roles?.includes('admin') ? customer?.email ?? user?.email : user?.email}
        placeholder={t('auth:auth_placeholder_email')}
        validate={emailValidation}
      />
    </div>

    <div className="grid grid-cols-10 gap-3">
      <div className="col-span-5 lg:col-span-3">
        <Heading4 className="mb-2">{t('auth:auth_placeholder_country')}</Heading4>

        <div>
          <select
            id="country_code"
            name="country_code"
            onClick={e => {
              e.stopPropagation()
            }}
            ref={countryCodeRef}
            defaultValue={countryCode}
            onChange={e => {
              setCountryCode(e.target.value as CountryCode)
            }}
            className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer text-center focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
          >
            {
              PhoneNumberUtils.countryCodes
                .sort((c1, c2) => (c1.isd) - (c2.isd))
                .map(i => {
                  return <option key={`${i.isd}_${i.code}`} value={i.code}>{`+${i.isd} (${i.code})`}</option>
                })
            }
          </select>
        </div>
      </div>

      <div className="col-span-5 lg:col-span-7">
        <Heading4 className="mb-2">{t('auth:auth_placeholder_phone_number')}</Heading4>
        <BodyInput
          type="tel"
          autoComplete="tel"
          ref={phoneNumberRef}
          placeholder={t('auth:auth_placeholder_phone_number')}
          validate={phoneNumberValidation}
          value={phoneNumberInputValue}
          onChange={useCallback(e => {
            const formatter = new AsYouType(countryCode)
            setPhoneNumberInputValue(formatter.input(e.target.value))
          }, [countryCode])}
        />
      </div>
    </div>
  </div>
}