import {UserJson, UserModel} from "./user";

export interface AffiliatesResponseJson {
  total_results?: number
  affiliates?: UserJson[]
}

export class AffiliatesResponseModel {
  totalResults: number = 0
  affiliates: UserModel[] = []

  static fromJson(json?: AffiliatesResponseJson): AffiliatesResponseModel | undefined {
    if (!json) return

    const model = new AffiliatesResponseModel()
    model.totalResults = json.total_results ?? 0
    model.affiliates = json.affiliates?.map(i => UserModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}