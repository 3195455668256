import {PromoCodeModel} from "../../models/promoCode";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";

export interface PromoCodeEntryState {
  isSaving: boolean
  isLoading: boolean
  shouldClose: boolean
  promoCodes: PromoCodeModel[]
  error?: BaseError<any> | null
}

export const promoCodeEntrySlice = createSlice({
  name: 'promo_code_entry',
  initialState: {
    isSaving: false,
    isLoading: false,
    shouldClose: false,
    promoCodes: []
  } as PromoCodeEntryState,
  reducers: {
    reset(state) {
      state.isSaving = false
      state.isLoading = false
      state.shouldClose = false
      state.error = null
      state.promoCodes = []
    },
    clearError(state) {
      state.error = null
    },
    fetchAvailablePromoCodes(state, action: PayloadAction<string>) {
      state.isLoading = true

      if (!state.promoCodes.length) {
        state.promoCodes = [
          {
            discounts: [],
            id: '1',
            vendors: []
          },
          {
            discounts: [],
            id: '2',
            vendors: []
          }
        ]
      }
    },
    fetchAvailablePromoCodesSucceeded(state, action: PayloadAction<PromoCodeModel[]>) {
      state.isLoading = false
      state.promoCodes = action.payload
    },
    verifyPromoCode(state, action: PayloadAction<{ code: string, store_id?: string }>) {
      state.isSaving = true
    },
    savePromoCode(state, action: PayloadAction<PromoCodeModel>) {
      state.isSaving = true
    },
    savePromoCodeSucceeded(state) {
      state.isSaving = true
      state.shouldClose = true
    },
    verifyPromoCodeSucceeded(state) {
      state.isSaving = true
      state.shouldClose = true
    },
    verifyPromoCodeFailed(state, action: PayloadAction<Error>) {
      state.isSaving = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PROMO_CODE_VERIFY_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'checkout:checkout_alert_message_error_verifying_code' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const promoCodeEntryReducer = promoCodeEntrySlice.reducer

export const {
  verifyPromoCode,
  verifyPromoCodeFailed,
  verifyPromoCodeSucceeded,
  savePromoCode,
  savePromoCodeSucceeded,
  fetchAvailablePromoCodes,
  fetchAvailablePromoCodesSucceeded,
  reset,
  clearError
} = promoCodeEntrySlice.actions

export const selectError = (state: RootState) => state.promoCodeEntry.error;
export const selectIsLoading = (state: RootState) => state.promoCodeEntry.isLoading;
export const selectIsSaving = (state: RootState) => state.promoCodeEntry.isSaving;
export const selectShouldClose = (state: RootState) => state.promoCodeEntry.shouldClose;
export const selectPromoCodes = (state: RootState) => state.promoCodeEntry.promoCodes;