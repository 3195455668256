export type ShipmentStatusType = 'label_printed' | 'label_purchased' | 'attempted_delivery' | 'ready_for_pickup' | 'confirmed' | 'delivered' | 'failure' | 'in_transit' | 'out_for_delivery'

export interface SalesOrderFulfillmentJson {
  id?: string
  shipment_status?: ShipmentStatusType
  shipment_status_updated_at?: Date
  estimated_delivery_at?: Date
  tracking_company?: string
  tracking_numbers?: string[]
  tracking_urls?: string[]
}

export class SalesOrderFulfillmentModel {
  id?: string
  shipmentStatus?: ShipmentStatusType
  shipmentStatusUpdatedAt?: Date
  estimatedDeliveryAt?: Date
  trackingCompany?: string
  trackingNumbers: string[] = []
  trackingUrls: string[] = []

  constructor(model?: SalesOrderFulfillmentModel) {
    if (!model) return

    this.id = model.id
    this.shipmentStatus = model.shipmentStatus
    this.estimatedDeliveryAt = model.estimatedDeliveryAt
    this.shipmentStatusUpdatedAt = model.shipmentStatusUpdatedAt
    this.trackingCompany = model.trackingCompany
    this.trackingNumbers = model.trackingNumbers
    this.trackingUrls = model.trackingUrls
  }

  static fromJson(json?: SalesOrderFulfillmentJson): SalesOrderFulfillmentModel | undefined {
    if (!json) return

    const model = new SalesOrderFulfillmentModel()

    model.id = json.id
    model.shipmentStatus = json.shipment_status
    model.estimatedDeliveryAt = json.estimated_delivery_at
    model.shipmentStatusUpdatedAt = json.shipment_status_updated_at
    model.trackingCompany = json.tracking_company
    model.trackingNumbers = json.tracking_numbers ?? []
    model.trackingUrls = json.tracking_urls ?? []

    return model
  }
}