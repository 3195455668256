import {BaseError} from "./baseError";
import {LocalizationInfo} from 'react-i18next'

export class GenericError extends BaseError<string> {
  localizedMessage?: LocalizationInfo | string
  localizedTitle?: LocalizationInfo | string
  error?: Error

  constructor(code: string, error?: Error) {
    super(code, error);
  }
}