import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ContactInfoValidationError} from "../../errors/contactInfoValidationError";
import {SetContactInfoPayload} from "../../models/setContactInfoPayload";
import {BaseError} from "../../errors/baseError";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";

interface ContactInfoEntryState {
  readonly isSaving: boolean
  readonly validationError?: ContactInfoValidationError | null
  readonly error?: BaseError<any> | null,
  readonly shouldClose: boolean
}

export const contactInfoEntrySlice = createSlice({
  name: "contact_info_entry",
  initialState: { isSaving: false, shouldClose: false } as ContactInfoEntryState,
  reducers: {
    reset(state) {
      state.isSaving = false
      state.error = null
      state.validationError = null
      state.shouldClose = false
    },
    clearError(state) {
      state.error = null
    },
    setContactInfo(state, action: PayloadAction<{ payload: SetContactInfoPayload, countryCode?: string }>) {
      state.isSaving = true
    },
    setContactInfoSucceeded(state) {
      state.isSaving = false
      state.shouldClose = true
    },
    setContactInfoFailed(state, action: PayloadAction<Error>) {
      state.isSaving = false

      if (action.payload instanceof ContactInfoValidationError) {
        state.validationError = action.payload as any
      } else if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('CONTACT_INFO_ENTRY_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'auth:auth_alert_message_error_updating_contact_info' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const contactInfoEntryReducer = contactInfoEntrySlice.reducer

export const {
  reset,
  clearError,
  setContactInfo,
  setContactInfoFailed,
  setContactInfoSucceeded
} = contactInfoEntrySlice.actions

export const selectError = (state: RootState) => state.contactInfoEntry.error
export const selectIsSaving = (state: RootState) => state.contactInfoEntry.isSaving
export const selectShouldClose = (state: RootState) => state.contactInfoEntry.shouldClose
export const selectValidationError = (state: RootState) => state.contactInfoEntry.validationError