import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled} from "rxjs";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";
import {
  deletePushNotification,
  deletePushNotificationFailed,
  deletePushNotificationSucceeded,
  fetchPushNotifications,
  fetchPushNotificationsFailed,
  fetchPushNotificationsSucceeded,
  reset, setCurrentPage
} from "./pushNotificationListSlice";

export const fetchPushNotificationsEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchPushNotifications.match),
    mergeMap(action => {
      return MessageService.fetchPushNotifications(action.payload)
        .pipe(
          map(fetchPushNotificationsSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchPushNotificationsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deletePushNotificationFromListEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(deletePushNotification.match),
    mergeMap(action => {
      return MessageService.deletePushNotification(action.payload)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.pushNotificationList.currentPage

            return scheduled([
              deletePushNotificationSucceeded(action.payload),
              reset(),
              pageNumber === 1 ? fetchPushNotifications(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: deletePushNotificationFailed.type,
            payload: { error, id: action.payload },
            error: true
          }))
        )
    })
  )
}