
type BranchSDK = typeof import('branch-sdk');

let branch: BranchSDK | null | undefined;

export const DeeplinkService = {
  setBranchSdk(branchSdk?: BranchSDK | null) {
    branch = branchSdk
  },
  getBranchSdk() {
    return branch;
  }
}