import {RootEpic} from "../../app/store";
import {catchError, filter, map, mergeMap, of} from "rxjs";
import {fetchOrders, fetchOrdersFailed, fetchOrdersSucceeded} from "./orderListSlice";
import {StoreService} from "../../services/storeService";
import {logErrorRx} from "../../utils/logError";

export const fetchOrdersEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchOrders.match),
    mergeMap(action => {
      return StoreService.fetchOrders(action.payload)
        .pipe(
          map(fetchOrdersSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchOrdersFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}