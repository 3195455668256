import {SalesOrderModel} from "../../models/salesOrder";
import React from "react";
import {ImageUtils} from "../../utils/imageUtils";
import Skeleton from "react-loading-skeleton";
import {Image} from "../images/Image";
import {Body, HeroHeading2, Paragraph} from "../text";
import moment from "moment";

interface OrderDetailHeaderProps {
  order: SalesOrderModel
  loading: boolean
}

export const OrderDetailHeader: React.FC<OrderDetailHeaderProps> = ({ order, loading }) => {
  const coverImageUrl = ImageUtils.createUrlFromImage(order.vendor?.coverImage, )
  const iconImageUrl = ImageUtils.createUrlFromImage(order.vendor?.iconImage, { heightPx: 200, widthPx: 200 })

  const createdAt = moment(order.createdAt).format('MMM D, YYYY h:mm A')
  const subtitle = `${order.distributionMethod} • ${createdAt}`
  return <div>
    {
      (() => {
        if (coverImageUrl && iconImageUrl) {
          return <div className="relative">
            {loading ? <Skeleton height="0px" className="!aspect-h-1 !aspect-w-5 !h-screen-1/8 !rounded-lg" /> : <Image
              src={coverImageUrl}
              alt={order?.vendor?.name + " Cover Photo"}
              layout={"fill"}
              objectFit={"cover"}
              sizeClass="aspect-h-1 aspect-w-5 h-screen-1/6"
              className="rounded-lg bg-fg-gray-surface"
            /> }
            <div className="absolute left-2 md:left-4 lg:left-6 bottom-0 -mb-5 h-10 w-10 md:-mb-6 md:h-12 md:w-12 rounded-full ring-2 lg:ring-4 ring-white shadow-xl">
              {loading ? <Skeleton className="!h-full !w-full !rounded-full !absolute" /> : <Image
                src={iconImageUrl}
                alt={order?.vendor?.name + " Logo"}
                layout={"fill"}
                objectFit={"contain"}
                sizeClass="aspect-h-1 aspect-w-1 h-full w-full"
                className="rounded-full bg-fg-gray-surface"
              /> }
            </div>
          </div>
        } else if (loading) {
          return <div className="relative">
            <Skeleton height="0px" className="!aspect-h-1 !aspect-w-5 !h-screen-1/6 !rounded-lg" />
            <div className="absolute left-2 md:left-4 lg:left-6 bottom-0 -mb-5 h-10 w-10 md:-mb-6 md:h-12 md:w-12 rounded-full ring-2 lg:ring-4 ring-white shadow-xl">
              <Skeleton className="!h-full !w-full !rounded-full !absolute" />
            </div>
          </div>
        } else {
          return null
        }
      })()
    }

    <div className="mt-10">
      <HeroHeading2>{loading ? <Skeleton width="30%" /> : order.vendor?.name}</HeroHeading2>
      <Paragraph className="mt-2 text-fg-gray-dark capitalize">{loading ? <Skeleton width="50%" /> : subtitle}</Paragraph>
    </div>

  </div>
}