import {CurrencyCode} from "../models/currencyCode";

export const CurrencyUtils = {
  stringFromPrice(price?: number, currencyCode?: CurrencyCode): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode ?? 'USD'
    });

    if (price != null) {
      return formatter.format(price)
    } else {
      return '---'
    }
  }
}