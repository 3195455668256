import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {Notification} from "../../components/notifications/NotificationToast";
import {ShoppingCartItemModel} from "../../models/shoppingCartItem";

// State
interface NavigationState {
  readonly sideNavIsOpen: boolean
  readonly cartMenuIsOpen: boolean
  readonly addedCartItem?: ShoppingCartItemModel
  readonly addedCartItemVisible: boolean
  readonly route?: string
  readonly notification?: { notification: Notification | null, show: boolean }
  readonly alert?: { alert?: Notification | null, show: boolean }
}

// Slice
export const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    sideNavIsOpen: false,
    cartMenuIsOpen: false,
    addedCartItemVisible: false
  } as NavigationState,
  reducers: {
    toggleSideNavOpen(state) {
      state.sideNavIsOpen = !state.sideNavIsOpen
    },
    setCartMenuOpen(state, action: PayloadAction<boolean>) {
      state.cartMenuIsOpen = action.payload
      state.addedCartItemVisible = false
    },
    setAddedCartItem(state, action: PayloadAction<ShoppingCartItemModel | undefined>) {
      state.addedCartItem = action.payload
    },
    setAddedCartItemVisible(state, action: PayloadAction<boolean>) {
      if (!state.cartMenuIsOpen) state.addedCartItemVisible = action.payload
    },
    showNotification(state, action: PayloadAction<Notification | null | undefined>) {
      return {
        ...state,
        notification: { notification: { ...action.payload }, show: true }
      }
    },
    hideNotification(state) {
      return {
        ...state,
        notification: { notification: { ...state.notification?.notification }, show: false }
      }
    },
    showAlert(state, action: PayloadAction<Notification | null | undefined>) {
      return {
        ...state,
        alert: { alert: { ...action.payload }, show: true }
      }
    },
    hideAlert(state) {
      return {
        ...state,
        alert: { alert: { ...state.alert?.alert }, show: false }
      }
    },
  },
})

// Reducer
export const navigationReducer = navigationSlice.reducer

// Actions
export const {
  toggleSideNavOpen,
  setCartMenuOpen,
  setAddedCartItem,
  setAddedCartItemVisible,
  showNotification,
  hideNotification,
  hideAlert,
  showAlert
} = navigationSlice.actions

// Selectors
export const selectSideNavIsOpen = (state: RootState) => state.navigation.sideNavIsOpen
export const selectCartMenuIsOpen = (state: RootState) => state.navigation.cartMenuIsOpen
export const selectAddedCartItem = (state: RootState) => state.navigation.addedCartItem
export const selectAddedCartItemVisible = (state: RootState) => state.navigation.addedCartItemVisible
export const selectNotification = (state: RootState) => state.navigation.notification?.notification
export const selectNotificationIsVisible = (state: RootState) => state.navigation.notification?.show == true
export const selectAlert = (state: RootState) => state.navigation.alert?.alert
export const selectAlertIsVisible = (state: RootState) => state.navigation.alert?.show == true
