import {UserModel} from "../../models/user";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AffiliatesResponseModel} from "../../models/affiliatesResponse";
import {GenericError} from "../../errors/genericError";
import {SetAffiliateStatusPayload} from "../../models/setAffiliateStatusPayload";
import {RootState} from "../../app/store";

interface AffiliateAdminState {
  readonly isLoadingAffiliates: boolean
  readonly isUpdatingAffiliateMap: { [key: string]: boolean }
  readonly affiliates: UserModel[]
  readonly totalAffiliates: number
  readonly error?: BaseError<any>
}

export const affiliateAdminSlice = createSlice({
  name: 'affiliate_admin',
  initialState: {
    isLoadingAffiliates: false,
    totalAffiliates: 0,
    affiliates: [],
    isUpdatingAffiliateMap: {}
  } as AffiliateAdminState,
  reducers: {
    reset(state) {
      state.isLoadingAffiliates = false
      state.affiliates = []
      state.isUpdatingAffiliateMap = {}
      state.error = undefined
    },
    fetchAffiliates(state, action: PayloadAction<number>) {
      if (!state.affiliates.length) {
        state.isLoadingAffiliates = true
      }
    },
    fetchAffiliatesSucceeded(state, action: PayloadAction<AffiliatesResponseModel>) {
      if (state.isLoadingAffiliates) {
        state.affiliates = action.payload.affiliates
      } else {
        state.affiliates = state.affiliates.concat(action.payload.affiliates)
      }

      state.totalAffiliates = action.payload.totalResults
      state.isLoadingAffiliates = false
    },
    fetchAffiliatesFailed(state, action: PayloadAction<Error>) {
      state.isLoadingAffiliates = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('AFFILIATE_ADMIN_ERROR')
        error.localizedMessage = { key: 'affiliate:affiliate_alert_message_error_getting_affiliates' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    updateAffiliateStatus(state, action: PayloadAction<SetAffiliateStatusPayload>) {
      state.isUpdatingAffiliateMap[action.payload.affiliate_user_id] = true
    },
    updateAffiliateStatusSucceeded(state, action: PayloadAction<SetAffiliateStatusPayload>) {
      state.isUpdatingAffiliateMap[action.payload.affiliate_user_id] = false

      state.affiliates = state.affiliates.map(affiliate => {
        if (affiliate.userId === action.payload.affiliate_user_id) {
          const updatedAffiliate = new UserModel(affiliate)
          if (updatedAffiliate.affiliateProgram) {
            updatedAffiliate.affiliateProgram.affiliateStatus = action.payload.status
          }
          return updatedAffiliate
        } else {
          return affiliate
        }
      })
    },
    updateAffiliateStatusFailed(state, action: PayloadAction<Error>) {
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('AFFILIATE_ADMIN_ERROR')
        error.localizedMessage = { key: 'affiliate:affiliate_alert_message_error_updating_affiliate_status' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const affiliateAdminReducer = affiliateAdminSlice.reducer

export const {
  reset,
  fetchAffiliates,
  fetchAffiliatesFailed,
  fetchAffiliatesSucceeded,
  updateAffiliateStatus,
  updateAffiliateStatusFailed,
  updateAffiliateStatusSucceeded
} = affiliateAdminSlice.actions

export const selectError = (state: RootState) => state.affiliateAdmin.error
export const selectAffiliates = (state: RootState) => state.affiliateAdmin.affiliates
export const selectIsLoadingAffiliates = (state: RootState) => state.affiliateAdmin.isLoadingAffiliates
export const selectIsUpdating = (state: RootState, userId?: string) => (userId ? state.affiliateAdmin.isUpdatingAffiliateMap[userId] : false)
export const selectTotalAffiliates = (state: RootState) => state.affiliateAdmin.totalAffiliates