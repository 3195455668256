import {ProductJson, ProductModel} from "./product";
import {ProductCategoryJson, ProductCategoryModel} from "./productCategory";
import {LocationJson, LocationModel} from "./location";

export type StoreSectionType = 'category' | 'menu_item' | 'location' | 'vendor'

export interface StoreSectionJson {
  _id?: string
  category_id?: string
  store_id?: string
  title?: string
  subtitle?: string
  sort_number?: number
  type?: StoreSectionType
  menu_items?: ProductJson[]
  categories?: ProductCategoryJson[]
  available_locations?: LocationJson[]
}

export class StoreSectionModel {
  availableLocations: LocationModel[] = []
  categories: ProductCategoryModel[] = []
  id?: string
  products: ProductModel[] = []
  sortNumber?: number
  subtitle?: string
  categoryId?: string
  storeId?: string
  title?: string
  type?: StoreSectionType

  static fromJson(json?: StoreSectionJson): StoreSectionModel | undefined {
    if (!json) return

    const model = new StoreSectionModel()

    model.availableLocations = json.available_locations
      ?.map((i) => LocationModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.categories = json.categories
      ?.map((i) => ProductCategoryModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.id = json._id
    model.products = json.menu_items
      ?.map((i) => ProductModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.sortNumber = json.sort_number
    model.subtitle = json.subtitle?.replace(/<[^>]+>/g, '')?.replace('&amp;', '&')
    model.title = json.title
    model.type = json.type
    model.categoryId = json.category_id
    model.storeId = json.store_id

    return model
  }
}