import {MessageCampaignNodeJson, MessageCampaignNodeModel} from "./messageCampaignNode";

export type MessageCampaignStatus = 'draft' | 'active' | 'inactive'

export interface MessageCampaignJson {
  _id?: string
  name?: string
  start_on?: Date
  end_on?: Date
  nodes?: MessageCampaignNodeJson[]
  status?: MessageCampaignStatus
  created_at?: Date
  updated_at?: Date
}

export class MessageCampaignModel {
  id?: string
  name?: string
  startOn?: Date
  endOn?: Date
  nodes: MessageCampaignNodeModel[] = []
  status?: MessageCampaignStatus
  createdAt?: Date
  updatedAt?: Date

  constructor(model?: MessageCampaignModel) {
    if (!model) return

    this.id = model.id
    this.name = model.name
    this.startOn = model.startOn
    this.endOn = model.endOn
    this.nodes = model.nodes.map(i => new MessageCampaignNodeModel(i))
    this.status = model.status
    this.createdAt = model.createdAt
    this.updatedAt = model.updatedAt

    return this
  }

  static fromJson(json?: MessageCampaignJson): MessageCampaignModel | undefined {
    if (!json) return

    const model = new MessageCampaignModel()

    model.id = json._id
    model.name = json.name
    model.startOn = json.start_on
    model.endOn = json.end_on
    model.nodes = json.nodes?.map(i => MessageCampaignNodeModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.status = json.status
    model.createdAt = json.created_at
    model.updatedAt = json.updated_at

    return model
  }

  toJson(): MessageCampaignJson {
    return {
      _id: this.id,
      name: this.name,
      start_on: this.startOn,
      end_on: this.endOn,
      nodes: this.nodes.map(i => i.toJson()),
      status: this.status,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    }
  }
}