import {from, Observable} from "rxjs";
import {AuthResponseModel} from "../models/authResponse";
import {LoginWithEmailPayload} from "../models/loginWithEmailPayload";
import {api, handleApiError, refreshApi} from "./api";
import {LoginWithFacebookPayload} from "../models/loginWithFacebookPayload";
import {SendPasswordResetRequestPayload} from "../models/sendPasswordResetRequestPayload";
import {SignUpPayload} from "../models/signupPayload";
import {LoginWithApplePayload} from "../models/loginWithApplePayload";
import {SignupAnonymouslyPayload} from "../models/signupAnonymouslyPayload";

export const AuthApi = {
  authenticateSession(): Observable<AuthResponseModel> {
    const request: Promise<AuthResponseModel> = refreshApi.get('/auth/refresh-token', {
      withCredentials: true
    })
      .then(response => {
        return AuthResponseModel.fromJson(response.data)!
      })
      .catch(handleApiError)

    return from(request)
  },
  signUp(payload: SignUpPayload): Observable<AuthResponseModel> {
    const request: Promise<AuthResponseModel> = api.post('/auth/signup', payload, {
      withCredentials: true,
      headers: { is_authenticated: true }
    })
      .then(response => {
        return AuthResponseModel.fromJson(response.data)!
      })
      .catch(handleApiError)

    return from(request)
  },
  signUpAnonymously(payload?: SignupAnonymouslyPayload): Observable<AuthResponseModel> {
    const request: Promise<AuthResponseModel> = api.post('/auth/signup-anonymously', payload, {
      withCredentials: true,
      headers: {
        is_authenticated: true,
        'x-fitgenie-anon-signup-key': process.env.NEXT_PUBLIC_ANON_SIGNUP_KEY
      }
    })
      .then(response => {
        return AuthResponseModel.fromJson(response.data)!
      })
      .catch(handleApiError)

    return from(request)
  },
  loginWithEmail(payload: LoginWithEmailPayload): Observable<AuthResponseModel> {
    const request: Promise<AuthResponseModel> = api.post('/auth/login', payload, {
      withCredentials: true,
      headers: { is_authenticated: true }
    })
      .then(response => {
        return AuthResponseModel.fromJson(response.data)!
      })
      .catch(handleApiError)

    return from(request)
  },
  loginWithFacebook(payload: LoginWithFacebookPayload): Observable<AuthResponseModel> {
    const request: Promise<AuthResponseModel> = api.post('auth/signin-with-facebook', payload, {
      withCredentials: true,
      headers: { is_authenticated: true }
    })
      .then(response => {
        return AuthResponseModel.fromJson(response.data)!
      })
      .catch(handleApiError)

    return from(request)
  },
  loginWithApple(payload: LoginWithApplePayload): Observable<AuthResponseModel> {
    const request: Promise<AuthResponseModel> = api.post('auth/signin-with-apple', payload, {
      withCredentials: true,
      headers: { is_authenticated: true }
    })
      .then(response => {
        return AuthResponseModel.fromJson(response.data)!
      })
      .catch(handleApiError)

    return from(request)
  },
  logout() {
    const request: Promise<void> = api.delete('/auth/logout', {
      withCredentials: true
    })
      .then(() => { })
      .catch(handleApiError)

    return from(request)
  },
  sendPasswordResetRequest(payload: SendPasswordResetRequestPayload): Observable<void> {
    const request: Promise<void> = api.post('auth/forgot-password', payload)
      .then(() => { })
      .catch(handleApiError)

    return from(request)
  }
}