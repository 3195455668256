import {RootEpic} from "../../app/store";
import {debounceTime, filter, map, mergeMap} from "rxjs/operators";
import {
  fetchAutocompletePredictions,
  fetchAutocompletePredictionsFailed,
  fetchAutocompletePredictionsSucceeded,
  fetchCurrentLocation,
  fetchCurrentLocationFailed,
  fetchCurrentLocationSucceeded,
  fetchLocationFromPlaceId,
  fetchLocationFromPlaceIdFailed,
  fetchLocationFromPlaceIdSucceeded,
} from "./locationSearchSlice";
import {LocationService} from "../../services/locationService";
import {asyncScheduler, catchError, from, Observable, of, scheduled} from "rxjs";
import {LatLng} from "googlemaps";
import {push} from "connected-next-router";
import {fetchLatLngSucceeded, setPrimaryLocation} from "../user/userSlice";
import {LocationModel} from "../../models/location";
import {CallRouterMethodPushPayload} from "connected-next-router/actions";
import {logErrorRx} from "../../utils/logError";

export const fetchLocationFromPlaceIdEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchLocationFromPlaceId.match),
    mergeMap(action => {
      return LocationService.fetchLocationFromPlaceId(action.payload.placeId)
        .pipe(
          mergeMap(location => {
            location.isPrimary = true
            if (action.payload.save) {
              return LocationService.setPrimaryDeliveryLocation(location)
                .pipe(catchError(() => of(location)))
            } else {
              return of(location)
            }
          }),
          mergeMap(location => {
            let actions: ({payload: LocationModel, type: string} | CallRouterMethodPushPayload)[] = [
              fetchLocationFromPlaceIdSucceeded(location)
            ]
            if (action.payload.save) actions = actions.concat([setPrimaryLocation(location)])
            if (action.payload.url) actions = actions.concat([push(action.payload.url)])
            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchLocationFromPlaceIdFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const fetchAutocompletePredictionsEpic: RootEpic = (action$, state$) => {
  const hasPermissionObservable = LocationService.fetchLocationPermissions()

  const locationObservable: Observable<LatLng> = hasPermissionObservable
    .pipe(mergeMap(hasPermission => {
      return hasPermission ? LocationService.fetchCurrentLatLng() : LocationService.fetchApproxLatLng()
    }))
    .pipe(catchError(() => LocationService.fetchApproxLatLng()))
    .pipe(catchError(() => of({ lat: 39.8283, lng: 98.5795 })))

  return action$.pipe(
    filter(fetchAutocompletePredictions.match),
    debounceTime(300),
    mergeMap(action => {
      if (action.payload.length < 2) {
        return of([])
          .pipe(map(fetchAutocompletePredictionsSucceeded))
      }

      const userLatLng = state$.value.user.userLatLng
      return (userLatLng ? of(userLatLng) : locationObservable)
        .pipe(
          mergeMap((latLng) => {
            return LocationService.fetchAutocompletePredictions(action.payload, latLng)
              .pipe(map(predictions => ({ predictions, latLng })))
          }),
          mergeMap(({predictions, latLng}) => scheduled(
            [
              fetchAutocompletePredictionsSucceeded(predictions),
              fetchLatLngSucceeded(latLng)
            ],
            asyncScheduler
          )),
          catchError(error => logErrorRx(error)),
          // map(fetchAutocompletePredictionsSucceeded),
          catchError(error => of({
            type: fetchAutocompletePredictionsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const fetchCurrentLocationEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(fetchCurrentLocation.match),
    mergeMap(action => {
      return LocationService.fetchCurrentLocation()
        .pipe(
          mergeMap(location => {
            if (action.payload.save) {
              return LocationService.setPrimaryDeliveryLocation(location)
                .pipe(catchError(() => of(location)))
            } else {
              return of(location)
            }
          }),
          mergeMap(location => {
            let actions: ({payload: LocationModel, type: string} | CallRouterMethodPushPayload)[] = [
              fetchCurrentLocationSucceeded(location)
            ]

            if (action.payload.save) actions = actions.concat([setPrimaryLocation(location)])
            if (action.payload.url) actions = actions.concat([push(action.payload.url)])
            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchCurrentLocationFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}