import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  clearError,
  createPrompt,
  deletePrompt,
  fetchPrompt,
  reset,
  selectConfig,
  selectError,
  selectIsDeleting,
  selectIsLoading,
  selectIsSubmitting,
  selectPrompt,
  selectShouldClose,
  updatePrompt
} from "../../redux/prompt/promptDetailSlice";
import {PromptAvailabilityType, PromptModel, PromptScreen} from "../../models/prompt";
import {Container} from "../containers/Container";
import {CloseIcon, LoadingSpinnerIcon} from "../icons";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Heading4, HeroHeading2, TextStyles} from "../text";
import {BodyInput} from "../inputs";
import {Button} from "../buttons/Button";
import Moment from 'moment';
import {PromptDetailSendTestModal} from "./PromptDetailSendTestModal";
import {fetchCohorts, selectCohorts, selectIsGettingCohorts} from "../../redux/cohort/cohortSlice";

export interface PromptDetailViewProps {
  onClose: () => void
  promptId?: string
}

export const PromptDetailView: React.FC<PromptDetailViewProps> = ({ onClose, promptId }) => {
  const dispatch = useAppDispatch();

  const shouldClose = useAppSelector(selectShouldClose)
  const error = useAppSelector(selectError)
  const prompt = useAppSelector(selectPrompt)
  const config = useAppSelector(selectConfig)
  const isLoading = useAppSelector(selectIsLoading)
  const cohorts = useAppSelector(selectCohorts)
  const isDeleting = useAppSelector(selectIsDeleting)
  const isGettingCohorts = useAppSelector(selectIsGettingCohorts)
  const isSubmitting = useAppSelector(selectIsSubmitting)

  const [payload, setPayload] = useState<PromptModel>()
  const [sendTestModalOpen, setSendTestModalOpen] = useState(false)
  const [promptIdToDelete, setPromptIdToDelete] = useState<string>()

  const priorities = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const availabilityTypes: PromptAvailabilityType[] = ['permanent', 'temporary', 'one_shot']
  const screens: PromptScreen[] = ['log', 'store_feed', 'profile', 'food_detail']

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    if (promptId) {
      dispatch(fetchPrompt(promptId))
    } else {
      const defaultPrompt = new PromptModel()
      defaultPrompt.availabilityType = 'temporary'
      defaultPrompt.backgroundColorDarkMode = '#454545'
      defaultPrompt.backgroundColorLightMode = '#EFEFEF'
      defaultPrompt.displayType = 'standard'
      defaultPrompt.isPremiumDisplayable = true
      defaultPrompt.isUserDismissable = false
      defaultPrompt.priority = 0
      defaultPrompt.minAndroidVersion = 63
      defaultPrompt.minIosVersion = '5.05'
      defaultPrompt.subtitleTextColorDarkMode = '#D1D1D1'
      defaultPrompt.subtitleTextColorLightMode = '#717871'
      defaultPrompt.titleTextColorDarkMode = '#FFFFFF'
      defaultPrompt.titleTextColorLightMode = '#0E1E12'
      defaultPrompt.utmMedium = 'in_app_prompt'
      defaultPrompt.utmSource = 'in_app_prompt'

      setPayload(defaultPrompt)
    }

    dispatch(fetchCohorts())
  }, [promptId, dispatch])

  useEffect(() => {
    prompt && setPayload(prompt)
  }, [prompt])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  if (isLoading || !payload || isGettingCohorts) {
    return <div
      className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    >
      <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
        <div className="w-full no-scrollbar flex-1 flex flex-col">
          <div className={`${isLoading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
            <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
          </div>
        </div>
      </Container>
    </div>
  }

  return <form
    className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    onSubmit={e => {
      e.preventDefault()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      isDismissable={false}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            dispatch(clearError())
          },
          style: 'default'
        }
      ]}
    />
    <AlertDialog
      isOpen={!!promptIdToDelete}
      message={'Are you sure you want to delete this prompt?'}
      title={'Delete Prompt?'}
      buttons={[
        {
          title: { key: 'common:common_title_delete' },
          onClick: () => {
            promptIdToDelete && dispatch(deletePrompt(promptIdToDelete))
            setPromptIdToDelete(undefined)
          },
          style: 'destructive'
        },
        {
          title: { key: 'common:common_title_cancel' },
          onClick: () => setPromptIdToDelete(undefined),
          style: 'cancel'
        }
      ]}
    />
    <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
      <div className="w-full no-scrollbar flex-1 flex flex-col">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`${isLoading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
          <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
        </div>

        <div className={`mt-4 mb-6 lg:mb-8`}>
          <HeroHeading2>{config === 'create' ? 'Create Prompt' : 'Update Prompt'}</HeroHeading2>
        </div>

        <div className="flex flex-col space-y-5">
          <div>
            <Heading4 className="mb-2">Name</Heading4>
            <BodyInput
              type="text"
              placeholder="Name"
              defaultValue={payload?.name}
              onChange={e => {
                if (payload) {
                  payload.name = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Title</Heading4>
            <BodyInput
              type="text"
              placeholder="Title"
              defaultValue={payload?.title}
              onChange={e => {
                if (payload) {
                  payload.title = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Subtitle</Heading4>
            <BodyInput
              type="text"
              placeholder="Subtitle"
              defaultValue={payload?.subtitle}
              onChange={e => {
                if (payload) {
                  payload.subtitle = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Icon Image URL</Heading4>
            <BodyInput
              type="text"
              placeholder="Icon Image URL"
              defaultValue={payload?.iconImageUrl}
              onChange={e => {
                if (payload) {
                  payload.iconImageUrl = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Background Image URL</Heading4>
            <BodyInput
              type="text"
              placeholder="Background Image URL"
              defaultValue={payload?.backgroundImageUrl}
              onChange={e => {
                if (payload) {
                  payload.backgroundImageUrl = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Light Mode Title Color</Heading4>
            <BodyInput
              type="text"
              placeholder="Light Mode Title Color"
              defaultValue={payload?.titleTextColorLightMode}
              onChange={e => {
                if (payload) {
                  payload.titleTextColorLightMode = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Light Mode Subtitle Color</Heading4>
            <BodyInput
              type="text"
              placeholder="Light Mode Subtitle Color"
              defaultValue={payload?.subtitleTextColorLightMode}
              onChange={e => {
                if (payload) {
                  payload.subtitleTextColorLightMode = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Dark Mode Title Color</Heading4>
            <BodyInput
              type="text"
              placeholder="Dark Mode Title Color"
              defaultValue={payload?.titleTextColorDarkMode}
              onChange={e => {
                if (payload) {
                  payload.titleTextColorDarkMode = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Dark Mode Subtitle Color</Heading4>
            <BodyInput
              type="text"
              placeholder="Dark Mode Subtitle Color"
              defaultValue={payload?.subtitleTextColorDarkMode}
              onChange={e => {
                if (payload) {
                  payload.subtitleTextColorDarkMode = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Light Mode Background Color</Heading4>
            <BodyInput
              type="text"
              placeholder="Light Mode Background Color"
              defaultValue={payload?.backgroundColorLightMode}
              onChange={e => {
                if (payload) {
                  payload.backgroundColorLightMode = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Dark Mode Background Color</Heading4>
            <BodyInput
              type="text"
              placeholder="Dark Mode Background Color"
              defaultValue={payload?.backgroundColorDarkMode}
              onChange={e => {
                if (payload) {
                  payload.backgroundColorDarkMode = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Availability Type</Heading4>
            <select
              id="availability_type"
              name="availability_type"
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payload?.availabilityType}
              onChange={e => {
                if (payload) {
                  payload.availabilityType = e.target.value as PromptAvailabilityType
                  setPayload(new PromptModel(payload))
                }
              }}
              className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              <option key={'none'} value={''} />
              {
                availabilityTypes
                  .map(i => {
                    return <option key={i} value={i}>{i}</option>
                  })
              }
            </select>
          </div>

          <div className={`${payload.availabilityType === 'temporary' ? '' : 'hidden'}`}>
            <Heading4 className="mb-2">Expires At</Heading4>
            <BodyInput
              type="date"
              placeholder="Expires At"
              defaultValue={payload?.expDate && Moment(payload.expDate).format('YYYY-MM-DD')}
              onChange={e => {
                const date = Moment(e.target.value)
                if (payload && date.isValid()) {
                  payload.expDate = date.toDate()
                  setPayload(new PromptModel(payload))
                } else if (payload) {
                  payload.expDate = undefined
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Link URL</Heading4>
            <BodyInput
              type="text"
              placeholder="Link URL"
              defaultValue={payload?.linkUrl}
              onChange={e => {
                if (payload) {
                  payload.linkUrl = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Min Android Version</Heading4>
            <BodyInput
              type="number"
              placeholder="Min Android Version"
              defaultValue={payload?.minAndroidVersion}
              onChange={e => {
                if (payload) {
                  payload.minAndroidVersion = parseInt(e.target.value)
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Min iOS Version</Heading4>
            <BodyInput
              type="text"
              placeholder="Min iOS Version"
              defaultValue={payload?.minIosVersion}
              onChange={e => {
                if (payload) {
                  payload.minIosVersion = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Priority</Heading4>
            <select
              id="priority"
              name="priority"
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payload?.priority}
              onChange={e => {
                if (payload) {
                  payload.priority = parseInt(e.target.value)
                  setPayload(new PromptModel(payload))
                }
              }}
              className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              {
                priorities
                  .map(i => {
                    return <option key={i} value={i}>{i}</option>
                  })
              }
            </select>
          </div>

          <div className={`${!cohorts.length && !isGettingCohorts ? '' : 'hidden'}`}>
            <Heading4 className="mb-2">Mixpanel Cohort ID</Heading4>
            <BodyInput
              type="text"
              placeholder="Mixpanel Cohort ID"
              defaultValue={payload?.mixpanelCohortId}
              onChange={e => {
                if (payload) {
                  payload.mixpanelCohortId = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div className={`${!cohorts.length && !isGettingCohorts ? 'hidden' : ''}`}>
            <Heading4 className="mb-2">Cohort</Heading4>
            <select
              id="cohort"
              name="cohort"
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payload?.mixpanelCohortId}
              onChange={e => {
                if (payload) {
                  payload.mixpanelCohortId = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
              className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              <option key={'none'} value={''} />
              {
                cohorts
                  .map(i => {
                    return <option key={i.id?.toString()} value={i.id?.toString()}>{i.name}</option>
                  })
              }
            </select>
          </div>

          <div>
            <Heading4 className="mb-2">Is User Dismissable</Heading4>
            <select
              id="is_user_dismissable"
              name="is_user_dismissable"
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payload?.isUserDismissable ? payload.isUserDismissable.toString() : false.toString()}
              onChange={e => {
                if (payload) {
                  payload.isUserDismissable = e.target.value === true.toString()
                  setPayload(new PromptModel(payload))
                }
              }}
              className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              <option key={'true'} value={true.toString()}>Yes</option>
              <option key={'false'} value={false.toString()}>No</option>
            </select>
          </div>

          <div>
            <Heading4 className="mb-2">Is Premium Displayable</Heading4>
            <select
              id="is_premium_displayable"
              name="is_premium_displayable"
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payload?.isPremiumDisplayable ? payload.isPremiumDisplayable.toString() : false.toString()}
              onChange={e => {
                if (payload) {
                  payload.isPremiumDisplayable = e.target.value === true.toString()
                  setPayload(new PromptModel(payload))
                }
              }}
              className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              <option key={'true'} value={true.toString()}>Yes</option>
              <option key={'false'} value={false.toString()}>No</option>
            </select>
          </div>

          <div>
            <Heading4 className="mb-2">Shows Badge</Heading4>
            <select
              id="shows_badge"
              name="shows_badge"
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payload?.showsBadge?.toString()}
              onChange={e => {
                if (payload) {
                  payload.showsBadge = e.target.value === true.toString()
                  setPayload(new PromptModel(payload))
                }
              }}
              className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              <option key={'true'} value={true.toString()}>Yes</option>
              <option key={'false'} value={false.toString()}>No</option>
            </select>
          </div>

          <div>
            <Heading4 className="mb-2">Screens</Heading4>
            <select
              id="screens"
              name="screens"
              multiple={true}
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payload?.screens}
              onChange={e => {
                const selectedScreens = [...e.target.options]
                  .filter((o) => o.selected)
                  .map((o) => o.value)
                if (payload) {
                  payload.screens = selectedScreens as PromptScreen[]
                  setPayload(new PromptModel(payload))
                }
              }}
              className={`bg-fg-gray-surface-light w-full h-full border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              {
                screens
                  .map(i => {
                    return <option key={i} value={i}>{i}</option>
                  })
              }
            </select>
          </div>

          <div>
            <Heading4 className="mb-2">UTM Campaign</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Campaign"
              defaultValue={payload?.utmCampaign}
              onChange={e => {
                if (payload) {
                  payload.utmCampaign = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">UTM Source</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Source"
              defaultValue={payload?.utmSource}
              onChange={e => {
                if (payload) {
                  payload.utmSource = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">UTM Medium</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Medium"
              defaultValue={payload?.utmMedium}
              onChange={e => {
                if (payload) {
                  payload.utmMedium = e.target.value
                  setPayload(new PromptModel(payload))
                }
              }}
            />
          </div>
        </div>

        <div className={`${isLoading || error ? 'hidden' : ''} mt-4 w-full items-centered space-x-0 space-y-3 lg:flex-row lg:space-x-2 lg:space-y-0`}>
          <div className="my-5">
            <Button
              isFullWidth={true}
              isLoading={isSubmitting}
              buttonStyle="primary"
              size="lg"
              onClick={() => {
                if (config === 'create' && payload) {
                  dispatch(createPrompt({ prompt: payload, cohortId: payload.mixpanelCohortId }))
                } else if (config === 'update' && promptId && payload) {
                  dispatch(updatePrompt({ prompt: payload, id: promptId, cohortId: payload.mixpanelCohortId }))
                }
              }}
            >
              {config === 'create' ? 'Create Prompt' : 'Update Prompt'}
            </Button>
          </div>

          <div className="mb-5">
            <Button
              buttonStyle="secondary"
              isFullWidth={true}
              size="lg"
              onClick={() =>{
                setSendTestModalOpen(true)
              }}
            >
              Send Test
            </Button>
          </div>

          {config === 'update' && <div className="flex-1">
              <Button
                  isFullWidth={true}
                  buttonStyle="text_destructive"
                  isLoading={isDeleting}
                  size="lg"
                  onClick={() => {
                    setPromptIdToDelete(promptId)
                  }}
              >
                  Delete
              </Button>
          </div>}
        </div>
      </div>
    </Container>

    <PromptDetailSendTestModal
      isOpen={sendTestModalOpen}
      prompt={payload}
      onClose={() => {
        setSendTestModalOpen(false)
      }}
    />
  </form>
}