import React, {useEffect, useRef, useState} from 'react'
import {InputProps} from "./index";
import {CustomTypeOptions, LocalizationInfo, Namespace, TFunction} from "react-i18next";
import { Transition } from '@headlessui/react'

export type AutocompleteItem<T> = { value: T, id: string }

export interface AutoCompleteProps<T> {
  t: TFunction<Namespace<keyof CustomTypeOptions['resources']>>
  items: AutocompleteItem<T>[]
  isOpen: boolean
  inputValue?: string | LocalizationInfo
  renderInput: (reqProps: InputProps) => React.ReactElement<InputProps>
  renderItem: (item: AutocompleteItem<T>, index: number) => React.ReactElement
  onItemSelected?: (item: AutocompleteItem<T>) => { shouldDismiss: boolean }
  onTextChanged?: (text: string) => void
  onInputRefChanged?: (ref: React.RefObject<HTMLInputElement> | null) => void
  onFocus?: () => void
  onBlur?: () => void
}

export const AutoCompleteInput = <T extends object>(props: AutoCompleteProps<T>) => {
  const [intrinsicIsOpen, setOpen] = useState(props.isOpen)
  const isOpen = props.isOpen && intrinsicIsOpen
  const [cursor, setCursor] = useState(-1)
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { t, inputValue } = props

  if (inputValue && inputRef.current) {
    inputRef.current.value = typeof inputValue !== "string" ? t(inputValue.key, inputValue.options) : inputValue
  }

  useEffect(() => {
    props.onInputRefChanged && props.onInputRefChanged(inputRef)
  }, [inputRef, props.onInputRefChanged])

  const select = (item: AutocompleteItem<T>) => {
    if (props.onItemSelected) {
      const { shouldDismiss } =  props.onItemSelected(item)

      if (shouldDismiss) setOpen(false)
    }
  }

  const handleChange = (text: string) => {
    props.onTextChanged && props.onTextChanged(text)
    setCursor(-1);
    if(!isOpen) {
      setOpen(true)
    }
  }

  const moveCursorDown = () => {
    if(cursor < props.items.length - 1) {
      setCursor(c => c + 1)
    }
  }

  const moveCursorUp = () => {
    if(cursor > 0) {
      setCursor(c => c - 1)
    }
  }

  const handleNav = (e: any) => {
    switch (e.key) {
      case "ArrowUp":
        moveCursorUp();
        break;
      case "ArrowDown":
        moveCursorDown();
        break;
      case "Enter":
        if(cursor >= 0 && cursor < props.items.length) {
          select(props.items[cursor]);
        }
        break;
    }
  }


  useEffect(() => {
    const listener = (e: any) => {
      if(!containerRef.current!.contains(e.target)) {
        setOpen(false)
        setCursor(-1)
      }
    };

    document.addEventListener('click', listener)
    document.addEventListener('focusin', listener)
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('focusin', listener);
    }
  },[]);

  return (
      <div ref={containerRef} className="relative w-full">
        {props.renderInput({
          ref: inputRef,
          defaultValue: inputValue && typeof inputValue !== "string" ? t(inputValue.key, inputValue.options) : inputValue,
          onChange: (e) => handleChange(e.target.value),
          onFocus: () => {
            setOpen(true)
            props.onFocus && props.onFocus()
          },
          onBlur: () => {
            props.onBlur && props.onBlur()
          },
          onKeyDown: handleNav
        })}
        <Transition
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <div className="absolute z-10 mt-2 w-full">
            <div className={`rounded-lg shadow-md w-full`}>
              <ul>
                {props.items.map((item, i, arr) => {
                  let className = "hover:bg-fg-gray-surface cursor-pointer "

                  if (i === 0 && arr.length === 1) {
                    className += "rounded-lg"
                  } else if (i === 0) {
                    className += "rounded-t-lg"
                  } else if (i === arr.length - 1) {
                    className += "rounded-b-lg"
                  } else {
                    className += ""
                  }

                  if (cursor === i) {
                    className += " bg-fg-gray-surface"
                  } else {
                    className += " bg-white"
                  }

                  return <li
                    key={item.id.toString()}
                    id={item.id.toString()}
                    className={className}
                    onClick={() => {
                      select(item)
                    }}
                  >{props.renderItem(item, i)}</li>
                })}
              </ul>
            </div>
            <div className="h-16" />
          </div>
        </Transition>
      </div>
  )
}