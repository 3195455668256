import {Dialog, Transition} from "@headlessui/react";
import {useTranslation} from "next-i18next";
import React, {Fragment, ReactElement} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectSideNavIsOpen, toggleSideNavOpen} from "../../redux/navigation/navigationSlice";
import {
  AdminSettingsIcon,
  AndroidFilledIcon, BellFilledIcon, CartFilledIcon,
  CloseIcon,
  DebitCardFilledIcon,
  IosFilledIcon,
  LifesaverFilledIcon, LineChartIcon,
  LogoutFilledIcon, MailFilledIcon, MegaphoneFilledIcon,
  ReceiptFilledIcon,
  StoreFilledIcon,
  UserFilledIcon
} from "../icons";
import {Button} from "../buttons/Button";
import {Body, Heading3} from "../text";
import {logout, selectLoginStatus} from "../../redux/auth/authSlice";
import {useRouter} from "next/router";
import {useModalRouting} from "../../utils/useModalRouting";
import {selectUser} from "../../redux/user/userSlice";

interface SideNavItemProps {
  title: string
  leadingIcon: React.ReactNode
  isLastItem: boolean
  externalHref?: string
  onClick?: () => void
}

const SideNavItem: React.FC<SideNavItemProps> = ({ title, leadingIcon, onClick, isLastItem, externalHref }) => {
  const leadingIconProps = leadingIcon && (leadingIcon as ReactElement)?.props
  const leadingIconClassName = `${leadingIconProps?.className ? leadingIconProps.className : ''} h-5 w-5 lg:h-6 lg:w-6`

  if (externalHref) {
    return <a target="_blank" rel="noreferrer" href={externalHref}>
      <div className={`px-3 py-5 flex flex-row items-center ${isLastItem ? '' : 'border-b border-fg-gray-surface'}`}>
        <div>
          {React.cloneElement(leadingIcon as React.ReactElement, { className: leadingIconClassName })}
        </div>
        <div className="flex-grow ml-4">
          <Heading3>{title}</Heading3>
        </div>
      </div>
    </a>
  } else {
    return <div onClick={() => { onClick && onClick() }} className={`px-3 py-5 flex flex-row items-center cursor-pointer ${isLastItem ? '' : 'border-b border-fg-gray-surface'}`}>
      <div>
        {React.cloneElement(leadingIcon as React.ReactElement, { className: leadingIconClassName })}
      </div>
      <div className="flex-grow ml-4">
        <Heading3>{title}</Heading3>
      </div>
    </div>
  }
}

const SideNavFooter: React.FC<any> = () => {
  const { t } = useTranslation()

  const isAndroid = navigator.userAgent.indexOf( "Android" ) !== -1
  const isIphone = navigator.userAgent.indexOf( "iPhone" ) !== -1

  return (
    <div>
      <div className="flex items-center space-x-3 mb-5">
        <div className="px-3 py-3 rounded-xl bg-white border border-fg-gray-lightest flex-shrink-0 flex items-center">
          <img src="../../images/common_logo_fitgenie_star.svg" className="h-8 w-8" alt="FitGenie Logo" />
        </div>
        <Body>{"Get meals, macros, and more in the app."}</Body>
      </div>
      <div className="flex flex-row space-x-2 justify-items-start">
        <a target="_blank" rel="noreferrer" href={isAndroid || isIphone ? process.env.NEXT_PUBLIC_APP_DOWNLOAD_URL! : process.env.NEXT_PUBLIC_FITGENIE_APP_STORE_URL}>
          <Button className={`${isAndroid ? 'hidden' : ''}`} buttonStyle="secondary" leadingicon={<IosFilledIcon />}>
            {t('common:nav_title_iphone')}
          </Button>
        </a>
        <a target="_blank" rel="noreferrer" href={isAndroid || isIphone ? process.env.NEXT_PUBLIC_APP_DOWNLOAD_URL! : process.env.NEXT_PUBLIC_FITGENIE_PLAY_STORE_URL}>
          <Button className={`${isIphone ? 'hidden' : ''}`} buttonStyle="secondary" leadingicon={<AndroidFilledIcon />}>
            {t('common:nav_title_android')}
          </Button>
        </a>
      </div>
    </div>
  )
}

const SideNavContentLoggedOut: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { createRoute } = useModalRouting()
  const router = useRouter()

  const user = useAppSelector(selectUser)
  const userInfo = user ? `----------------------------%0D%0AEmail: ${user.email}%0D%0AUser ID: ${user.userId}` : ''

  const navItems: SideNavItemProps[] = [
    {
      title: t('common:nav_title_become_a_vendor'),
      leadingIcon: <StoreFilledIcon className="h-6 w-6" />,
      isLastItem: false,
      externalHref: process.env.NEXT_PUBLIC_VENDOR_SIGNUP_URL
    },
    {
      title: t('common:nav_title_contact_support'),
      leadingIcon: <LifesaverFilledIcon className="h-6 w-6" />,
      isLastItem: true,
      externalHref: `mailto:${process.env.NEXT_PUBLIC_FITGENIE_SUPPORT_EMAIL}?subject=[SUPPORT] FitGenie - Support&body=Hi FitGenie Team,%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A${userInfo}`
    }
  ]

  return (
    <div className="flex flex-col space-y-3">
      <div className="grid grid-cols-2 gap-3">
        <Button
          size={"md"}
          isFullWidth={true}
          buttonStyle="secondary"
          className="flex-1 flex-grow"
          onClick={() => {
            dispatch(toggleSideNavOpen())
            let timeout: number;
            timeout = window.setTimeout(() => {
              router.push(
                createRoute({ name: 'login', query: { config: 'sign_in' } }),
                undefined,
                { shallow: true }
              );

              window.clearTimeout(timeout)
            }, 600)
          }}
        >
          { t('auth:auth_title_sign_in') }
        </Button>
        <Button
          size={"md"}
          isFullWidth={true}
          className="flex-1 flex-grow"
          onClick={() => {
            dispatch(toggleSideNavOpen())

            let timeout: number;
            timeout = window.setTimeout(() => {
              router.push(
                createRoute({ name: 'login', query: { config: 'sign_up' } }),
                undefined,
                { shallow: true }
              );

              window.clearTimeout(timeout)
            }, 600)
          }}
        >
          { t('auth:auth_title_sign_up') }
        </Button>
      </div>

      <div className="flex-grow-0 pt-6">
        <div>
          {navItems.map(item => {
            return <SideNavItem {...item} key={item.title} />
          })}
        </div>
      </div>
    </div>
  )
}

const SideNavContentLoggedIn: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { createRoute } = useModalRouting();

  const user = useAppSelector(selectUser)
  const userInfo = user ? `----------------------------%0D%0AEmail: ${user.email}%0D%0AUser ID: ${user.userId}` : ''

  const navItems: SideNavItemProps[] = [
    {
      title: t('common:nav_title_account'),
      leadingIcon: <UserFilledIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/account');

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    {
      title: t('common:nav_title_shop'),
      leadingIcon: <CartFilledIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/store-feed');

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    {
      title: t('common:nav_title_orders'),
      leadingIcon: <ReceiptFilledIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/orders')

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    {
      title: t('common:nav_title_payment'),
      leadingIcon: <DebitCardFilledIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push(
            createRoute({ name: 'payment_selector' }),
            undefined,
            {
              shallow: true,
            }
          );

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    user?.roles?.includes('affiliate') && {
      title: t('common:nav_title_affiliate_dashboard'),
      leadingIcon: <LineChartIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/affiliate')

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    {
      title: t('common:nav_title_contact_support'),
      leadingIcon: <LifesaverFilledIcon />,
      isLastItem: false,
      externalHref: `mailto:${process.env.NEXT_PUBLIC_FITGENIE_SUPPORT_EMAIL}?subject=[SUPPORT] FitGenie - Support&body=Hi FitGenie Team,%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A${userInfo}`
    },
    user?.roles?.includes('admin') && {
      title: t('common:nav_title_affiliate_admin'),
      leadingIcon: <AdminSettingsIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/admin/affiliates')

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    user?.roles?.includes('admin') && {
      title: 'Prompts',
      leadingIcon: <MailFilledIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/admin/prompts')

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    user?.roles?.includes('admin') && {
      title: 'Emails',
      leadingIcon: <MailFilledIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/admin/emails')

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    user?.roles?.includes('admin') && {
      title: 'Campaigns',
      leadingIcon: <MegaphoneFilledIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/admin/campaigns')

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    user?.roles?.includes('admin') && {
      title: 'Push Notifications',
      leadingIcon: <BellFilledIcon />,
      isLastItem: false,
      onClick: () => {
        dispatch(toggleSideNavOpen())

        let timeout: number;
        timeout = window.setTimeout(() => {
          router.push('/admin/push-notifications')

          window.clearTimeout(timeout)
        }, 600)
      }
    },
    {
      title: t('common:nav_title_become_a_vendor'),
      leadingIcon: <StoreFilledIcon className="h-6 w-6" />,
      isLastItem: false,
      externalHref: process.env.NEXT_PUBLIC_VENDOR_SIGNUP_URL
    },
    {
      title: t('common:nav_title_logout'),
      leadingIcon: <LogoutFilledIcon />,
      isLastItem: true,
      onClick: () => {
        dispatch(toggleSideNavOpen())
        let timeout: number;
        timeout = window.setTimeout(() => {
          dispatch(logout())

          window.clearTimeout(timeout)
        }, 600)
      }
    }
  ].filter(i => i).map(i => i as SideNavItemProps)

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex-grow-0">
        <div>
          {navItems.map(item => {
            return <SideNavItem {...item} key={item.title} />
          })}
        </div>
      </div>
    </div>
  )
}

export const SideNav: React.FC<any> = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectSideNavIsOpen);
  const loginStatus = useAppSelector(selectLoginStatus);

  return (
    <div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-hidden" onClose={() => dispatch(toggleSideNavOpen())}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 left-0 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom="-translate-x-96"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-96"
              >
                <div className="w-screen max-w-sm">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-2 sm:px-3 flex items-center justify-between">
                      <button
                        type="button"
                        className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
                        onClick={() => dispatch(toggleSideNavOpen())}
                      >
                        <CloseIcon className="h-4 h-4" />
                      </button>
                    </div>
                    <div className="mt-6 relative flex flex-col flex-1 px-4 sm:px-6">
                      <div className="flex-grow">
                        {loginStatus === 'logged_in' ? <SideNavContentLoggedIn /> : <SideNavContentLoggedOut />}
                      </div>
                      <div>
                        <SideNavFooter />
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}