import {catchError, mergeMap, Observable, of} from "rxjs";
import {PaymentApi} from "../apis/paymentApi";
import {SalesOrderModel} from "../models/salesOrder";
import {CreateSalesOrderPayload} from "../models/createSalesOrderPayload";
import {CardModel} from "../models/card";
import {PurchaseOrderModel} from "../models/purchaseOrder";
import {UserService} from "./userService";
import {CreatePurchaseOrderPayload} from "../models/createPurchaseOrderPayload";
import {UserModel} from "../models/user";
import {LocationModel} from "../models/location";
import {PickupLocationModel} from "../models/pickupLocation";
import {DistributionMethod} from "../models/distributionMethod";
import {ShoppingCartItemModel} from "../models/shoppingCartItem";
import {CreatePurchaseOrderLineItemPayload} from "../models/createPurchaseOrderLineItemPayload";
import {logErrorRx} from "../utils/logError";
import {PromoCodeModel} from "../models/promoCode";
import {CheckoutCustomer} from "../redux/checkout/checkoutSlice";

export const PaymentService = {
  fetchSalesOrder(orderId: string): Observable<SalesOrderModel> {
    return PaymentApi.getSalesOrder(orderId)
  },
  createSalesOrder(payload: CreateSalesOrderPayload): Observable<SalesOrderModel> {
    return PaymentApi.createSalesOrder(payload)
  },
  verifyPromoCode(promoCode: string, storeId?: string): Observable<PromoCodeModel> {
    return UserService.getDeviceFingerprint()
      .pipe(
        mergeMap(device_fingerprint => {
          return PaymentApi.verifyPromoCode({ promo_code: promoCode, device_fingerprint, store_id: storeId })
        })
      )
  },
  fetchPaymentSources(): Observable<CardModel[]> {
    return PaymentApi.getPaymentSources()
  },
  addPaymentSource(token: string, make_default: boolean = false): Observable<CardModel> {
    return PaymentApi.addPaymentSource({ token, make_default })
  },
  setDefaultPaymentSource(sourceId: string): Observable<void> {
    return PaymentApi.setDefaultPaymentSource({ source_id: sourceId })
  },
  deletePaymentSource(sourceId: string): Observable<void> {
    return PaymentApi.deletePaymentSource({ source_id: sourceId })
  },
  createPurchaseOrder(payload: {
    user?: UserModel | null,
    customer?: CheckoutCustomer | null,
    deliveryLocation?: LocationModel | null,
    pickupLocation?: PickupLocationModel | null,
    distributionMethod?: DistributionMethod | null,
    shippingOptionId?: string,
    lineItems: ShoppingCartItemModel[],
    promoCode?: string | null,
    storeId?: string | null,
    orderId?: string | null
  }): Observable<PurchaseOrderModel> {
    const deliveryLocationJson = payload.deliveryLocation?.toJson()
    const pickupLocationJson = payload.pickupLocation?.toJson()

    const lineItemJsons: CreatePurchaseOrderLineItemPayload[] = payload.lineItems.map(item => {
      return {
        currency_code: item.currencyCode,
        estimated_distribution_date: item.estimatedDistributionDate,
        _id: item.productId,
        sku_id: item.skuId,
        quantity: item.quantity,
        price: item.price,
        size: item.size
      }
    })

    const isAdmin = payload?.user?.roles?.includes('admin')

    const jsonPayload: CreatePurchaseOrderPayload = {
      customer_phone_number: isAdmin ? payload.customer?.phoneNumber ?? payload.user?.phoneNumber : payload.user?.phoneNumber,
      customer_last_name: isAdmin ? payload.customer?.lastName ?? payload.user?.lastName : payload.user?.lastName,
      customer_first_name: isAdmin ? payload.customer?.firstName ?? payload.user?.firstName : payload.user?.firstName,
      customer_email: isAdmin ? payload.customer?.email ?? payload.user?.email : payload.user?.email,
      delivery_location: deliveryLocationJson,
      distribution_method: payload.distributionMethod ?? undefined,
      shopify_shipping_option_id: payload.shippingOptionId,
      line_items: lineItemJsons,
      pickup_location: pickupLocationJson,
      promo_code: payload.promoCode ?? undefined,
      store_id: payload.storeId ?? undefined,
      order_id: payload.orderId ?? undefined
    }

    return UserService.getDeviceFingerprint()
      .pipe(
        catchError(e => logErrorRx(e)),
        catchError(e => of(undefined)),
        mergeMap(device_fingerprint => {
          jsonPayload.device_fingerprint = device_fingerprint
          return PaymentApi.createPurchaseOrder(jsonPayload)
        })
      )
  },
  fetchAvailablePromoCodesForStore(storeId: string): Observable<PromoCodeModel[]> {
    return PaymentApi.getActivePromoCodes(storeId)
  },
  setPromoCode(promoCode?: string) {
    if (!promoCode) {
      localStorage.removeItem('promo_code')
    } else {
      localStorage.setItem('promo_code', promoCode)
    }
  },
  getPromoCode(): string | undefined | null {
    return localStorage.getItem('promo_code')
  },
}