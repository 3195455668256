import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BaseError} from "../../errors/baseError";
import {CommissionModel} from "../../models/commission";
import {OnboardAffiliatePayload} from "../../models/onboardAffiliatePayload";
import {GenericError} from "../../errors/genericError";
import Moment from "moment"
import {AffiliateInfoResponseModel, AffiliateStatus} from "../../models/affiliateInfoResponse";
import {CommissionPayoutModel} from "../../models/commissionPayout";
import {PromoCodeModel} from "../../models/promoCode";
import {AffiliateInternalCodesResponseModel} from "../../models/affiliateInternalCodesResponse";
import {RootState} from "../../app/store";
import {AffiliateValidationError} from "../../errors/affiliateValidationError";

interface AffiliateState {
  readonly isOnboarding: boolean
  readonly isLoadingData: boolean
  readonly isLoadingInfo: boolean
  readonly isLoadingPromoCodes: boolean
  readonly shouldCloseOnboardModal: boolean
  readonly onboardError?: BaseError<any>
  readonly onboardValidationError?: AffiliateValidationError
  readonly error?: BaseError<any>
  readonly commissions: CommissionModel[]
  readonly cashPayouts: CommissionPayoutModel[]
  readonly storeCreditPayouts: CommissionPayoutModel[]
  readonly promoCodes: PromoCodeModel[]
  readonly totalPromoCodes: number
  readonly startDate: number
  readonly endDate: number
  readonly selectedTab: AffiliateDashboardTab
  readonly affiliateShareCode?: string
  readonly affiliateShopShareUrl?: string
  readonly affiliateAppShareUrl?: string
  readonly affiliateStatus?: AffiliateStatus
}

export type AffiliateDashboardTab = 'commissions' | 'cash_payouts' | 'store_credit_payouts' | 'perks'

export const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState: {
    isOnboarding: false,
    isLoadingData: false,
    isLoadingInfo: false,
    isLoadingPromoCodes: false,
    shouldCloseOnboardModal: false,
    totalPromoCodes: 0,
    commissions: [],
    cashPayouts: [],
    promoCodes: [],
    storeCreditPayouts: [],
    startDate: Moment().subtract(30, "days").toDate().getTime(),
    endDate: Moment().toDate().getTime(),
    selectedTab: 'commissions'
  } as AffiliateState,
  reducers: {
    reset(state) {
      state.error = undefined
      state.isLoadingData = false
      state.isLoadingInfo = false
      state.isLoadingPromoCodes = false
      state.commissions = []
      state.cashPayouts = []
      state.storeCreditPayouts = []
      state.startDate = Moment().subtract(30, "days").toDate().getTime()
      state.endDate = Moment().toDate().getTime()
      state.selectedTab = 'commissions'
    },
    resetOnboardModal(state) {
      state.isOnboarding = false
      state.shouldCloseOnboardModal = false
      state.onboardError = undefined
      state.onboardValidationError = undefined
    },
    setSelectedTab(state, action: PayloadAction<AffiliateDashboardTab>) {
      state.selectedTab = action.payload
    },
    setDateRange(state, action: PayloadAction<{ startDate: Date, endDate: Date }>) {
      state.startDate = action.payload.startDate.getTime()
      state.endDate = action.payload.endDate.getTime()
      state.isLoadingData = true
    },
    onboardAffiliate(state, action: PayloadAction<{ payload: OnboardAffiliatePayload, countryCode?: string }>) {
      state.isOnboarding = true
    },
    fetchAffiliateInfo(state) {
      state.isLoadingInfo = true
    },
    fetchAffiliateInfoFailed(state, action: PayloadAction<Error>) {
      state.isLoadingInfo = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('AFFILIATE_INFO_ERROR')
        error.localizedMessage = { key: 'affiliate:affiliate_alert_message_error_getting_commissions' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    fetchAffiliateInfoSucceeded(state, action: PayloadAction<AffiliateInfoResponseModel>) {
      state.affiliateStatus = action.payload.status
      state.affiliateShareCode = action.payload.code
      state.affiliateShopShareUrl = action.payload.shopShareUrl
      state.affiliateAppShareUrl = action.payload.appShareUrl
      state.isLoadingInfo = false
    },
    fetchAffiliatePromoCodes(state, action: PayloadAction<number>) {
      if (!state.promoCodes.length) {
        state.isLoadingPromoCodes = true
      }
    },
    fetchAffiliatePromoCodesSucceeded(state, action: PayloadAction<AffiliateInternalCodesResponseModel>) {
      if (state.isLoadingPromoCodes) {
        state.promoCodes = action.payload.promoCodes
      } else {
        state.promoCodes = state.promoCodes.concat(action.payload.promoCodes)
      }

      state.totalPromoCodes = action.payload.totalResults
      state.isLoadingPromoCodes = false
    },
    fetchAffiliatePromoCodesFailed(state, action: PayloadAction<Error>) {
      state.isLoadingData = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('AFFILIATE_PROMO_CODES_ERROR')
        error.localizedMessage = { key: 'affiliate:affiliate_alert_message_error_getting_perks' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    fetchAffiliateData(state) {
      state.isLoadingData = true
    },
    fetchAffiliateDataSucceeded(state, action: PayloadAction<{ commissions: CommissionModel[], cashPayouts: CommissionPayoutModel[], storeCreditPayouts: CommissionPayoutModel[] }>) {
      state.isLoadingData = false

      state.commissions = action.payload.commissions
      state.cashPayouts = action.payload.cashPayouts
      state.storeCreditPayouts = action.payload.storeCreditPayouts
    },
    fetchAffiliateDataFailed(state, action: PayloadAction<Error>) {
      state.isLoadingData = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('AFFILIATE_DATA_ERROR')
        error.localizedMessage = { key: 'affiliate:affiliate_alert_message_error_getting_commissions' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    onboardAffiliateSucceeded(state) {
      state.isOnboarding = false
      state.shouldCloseOnboardModal = true
    },
    onboardAffiliateFailed(state, action: PayloadAction<Error>) {
      state.isOnboarding = false
      if (action.payload instanceof AffiliateValidationError) {
        state.onboardValidationError = action.payload as any
      } else if (action.payload instanceof BaseError) {
        state.onboardError = action.payload as any
      } else {
        const error = new GenericError('AFFILIATE_SIGNUP_ERROR')
        error.localizedMessage = { key: 'affiliate:affiliate_alert_message_error_signing_up' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.onboardError = error as any
      }
    },
  }
})

export const affiliateReducer = affiliateSlice.reducer

export const {
  onboardAffiliate,
  onboardAffiliateSucceeded,
  onboardAffiliateFailed,
  reset,
  resetOnboardModal,
  fetchAffiliateData,
  fetchAffiliateDataFailed,
  fetchAffiliateDataSucceeded,
  fetchAffiliateInfo,
  fetchAffiliateInfoFailed,
  fetchAffiliateInfoSucceeded,
  setDateRange,
  setSelectedTab,
  fetchAffiliatePromoCodes,
  fetchAffiliatePromoCodesFailed,
  fetchAffiliatePromoCodesSucceeded
} = affiliateSlice.actions

export const selectError = (state: RootState) => state.affiliate.error;
export const selectOnboardError = (state: RootState) => state.affiliate.onboardError;
export const selectOnboardValidationError = (state: RootState) => state.affiliate.onboardValidationError;
export const selectIsOnboarding = (state: RootState) => state.affiliate.isOnboarding;
export const selectIsLoadingInfo = (state: RootState) => state.affiliate.isLoadingInfo;
export const selectIsLoadingData = (state: RootState) => state.affiliate.isLoadingData;
export const selectIsLoadingPromoCodes = (state: RootState) => state.affiliate.isLoadingPromoCodes;
export const selectStartDate = (state: RootState) => new Date(state.affiliate.startDate);
export const selectEndDate = (state: RootState) => new Date(state.affiliate.endDate);
export const selectAffiliateShareCode = (state: RootState) => state.affiliate.affiliateShareCode;
export const selectAffiliateAppShareUrl = (state: RootState) => state.affiliate.affiliateAppShareUrl;
export const selectAffiliateShopShareUrl = (state: RootState) => state.affiliate.affiliateShopShareUrl;
export const selectAffiliateStatus = (state: RootState) => state.affiliate.affiliateStatus;
export const selectSelectedTab = (state: RootState) => state.affiliate.selectedTab;
export const selectCommissions = (state: RootState) => state.affiliate.commissions;
export const selectCashPayouts = (state: RootState) => state.affiliate.cashPayouts;
export const selectStoreCreditPayouts = (state: RootState) => state.affiliate.storeCreditPayouts;
export const selectPromoCodes = (state: RootState) => state.affiliate.promoCodes;
export const selectShouldCloseOnboardModal = (state: RootState) => state.affiliate.shouldCloseOnboardModal;
export const selectTotalPromoCodeCount = (state: RootState) => state.affiliate.totalPromoCodes;
