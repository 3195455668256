import {CardModel} from "../../models/card";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";

export interface PaymentSelectorItem {
  readonly isLoading: boolean
  readonly isSaving: boolean
  readonly isDeleting: boolean
  readonly isSelected: boolean
  readonly card: CardModel
}

export interface PaymentSelectorState {
  readonly items: PaymentSelectorItem[]
  readonly defaultCard?: CardModel
  readonly error?: BaseError<any> | null
  readonly isLoading: boolean
}

export const paymentSelectorSlice = createSlice({
  name: 'payment_selector',
  initialState: { items: [], isLoading: false } as PaymentSelectorState,
  reducers: {
    reset(state) {
      state.items = []
      state.isLoading = false
    },
    fetchPaymentMethods(state) {
      state.isLoading = true
      state.error = null

      if (state.items.length === 0) {
        state.items = [
          {
            isLoading: true,
            isSaving: false,
            isDeleting: false,
            isSelected: false,
            card: CardModel.fromJson({ _id: "1" })!
          },
          {
            isLoading: true,
            isSaving: false,
            isDeleting: false,
            isSelected: false,
            card: CardModel.fromJson({ _id: "2" })!
          }
        ]
      } else {
        state.items = state.items.map(item => {
          item.isLoading = true
          return item
        })
      }
    },
    fetchPaymentMethodsSucceeded(state, action: PayloadAction<{ cards: CardModel[], defaultCard?: CardModel }>) {
      state.isLoading = false
      state.defaultCard = action.payload.defaultCard
      state.items = action.payload.cards.map(card => {
        return {
          card,
          isSaving: false,
          isDeleting: false,
          isSelected: card.id === action.payload.defaultCard?.id,
          isLoading: false
        }
      })
    },
    fetchPaymentMethodsFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('FETCH_PAYMENT_METHODS_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'payment:payment_message_error_getting_payment_methods' }
        error.error = action.payload
        state.error = error as any
      }
    },
    setDefaultPaymentMethod(state, action: PayloadAction<CardModel>) {
      state.items = state.items.map(item => {
        if (item.card.id === action.payload.id) item.isSaving = true
        return item
      })
    },
    setDefaultPaymentMethodSucceeded(state, action: PayloadAction<CardModel>) {
      state.items = state.items.map(item => {
        const isSelected = item.card.id === action.payload?.id

        return {
          card: item.card,
          isLoading: item.isLoading,
          isDeleting: false,
          isSaving: false,
          isSelected
        }
      })
    },
    setDefaultPaymentMethodFailed(state, action: PayloadAction<Error>) {
      state.items = state.items.map(item => {
        item.isSaving = false
        return item
      })

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('SET_DEFAULT_PAYMENT_METHOD_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'payment:payment_message_error_setting_default_payment_method' }
        error.error = action.payload
        state.error = error as any
      }
    },
    deletePaymentMethod(state, action: PayloadAction<CardModel>) {
      state.items = state.items.map(item => {
        if (item.card.id === action.payload.id) item.isDeleting = true
        return item
      })
    },
    deletePaymentMethodSucceeded(state, action: PayloadAction<CardModel>) {
      state.items = state.items.filter(i => i.card.id !== action.payload.id)
    },
    deletePaymentMethodFailed(state, action: PayloadAction<Error>) {
      state.items = state.items.map(item => {
        item.isDeleting = false
        return item
      })

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('LOCATION_ENTRY_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'location:location_message_error_deleting_address' }
        error.error = action.payload
        state.error = error as any
      }
    },
  }
})

export const paymentSelectorReducer = paymentSelectorSlice.reducer


export const {
  reset,
  fetchPaymentMethods,
  setDefaultPaymentMethod,
  setDefaultPaymentMethodFailed,
  setDefaultPaymentMethodSucceeded,
  deletePaymentMethod,
  deletePaymentMethodFailed,
  deletePaymentMethodSucceeded,
  fetchPaymentMethodsFailed,
  fetchPaymentMethodsSucceeded,
} = paymentSelectorSlice.actions

export const selectItems = (state: RootState) => state.paymentSelector.items;
export const selectError = (state: RootState) => state.paymentSelector.error;
export const selectIsLoading = (state: RootState) => state.paymentSelector.isLoading;