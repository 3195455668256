import {SubscriptionPriceModel} from "../../models/subscriptionPrice";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {CreateCheckoutResponseModel} from "../../models/createCheckoutSessionResponse";
import {InAppSubscriptionModel} from "../../models/inAppSubscription";

interface SubscriptionState {
  subscriptions: SubscriptionPriceModel[]
  isLoading: boolean
  isLoadingUserSubscription: boolean
  isCreatingCheckoutMap: { [id: string]: boolean }
  isCancelingSubscription: boolean
  fetchSubscriptionsError?: BaseError<any>
  userSubscription?: InAppSubscriptionModel
  cancelSubscriptionError?: BaseError<any>
  userSubscriptionError?: BaseError<any>
  createSessionError?: BaseError<any>
  checkoutSessionUrl?: string
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscriptions: [],
    isCreatingCheckoutMap: {},
    isCancelingSubscription: false,
    isLoadingUserSubscription: false,
    isLoading: false
  } as SubscriptionState,
  reducers: {
    reset(state) {
      state.fetchSubscriptionsError = undefined
      state.createSessionError = undefined
      state.cancelSubscriptionError = undefined
      state.checkoutSessionUrl = undefined
      state.subscriptions = []
      state.isCreatingCheckoutMap = {}
      state.isLoading = false
      state.userSubscription = undefined
      state.isLoadingUserSubscription = false
    },
    resetCheckoutSession(state) {
      state.createSessionError = undefined
      state.checkoutSessionUrl = undefined
    },
    clearErrors(state) {
      state.cancelSubscriptionError = undefined
      state.createSessionError = undefined
      state.fetchSubscriptionsError = undefined
      state.userSubscriptionError = undefined
    },
    fetchUserSubscription(state) {
      state.isLoadingUserSubscription = true
    },
    fetchUserSubscriptionSucceeded(state, action: PayloadAction<InAppSubscriptionModel>) {
      state.isLoadingUserSubscription = false
      state.userSubscription = action.payload
    },
    fetchUserSubscriptionFailed(state, action: PayloadAction<Error>) {
      state.isLoadingUserSubscription = false

      if (action.payload instanceof BaseError) {
        state.fetchSubscriptionsError = action.payload as any
      } else {
        const error = new GenericError('SUBSCRIPTION_FETCH_ERROR')
        error.localizedMessage = { key: 'subscription:subscription_error_message_getting_subscription' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.fetchSubscriptionsError = error as any
      }
    },
    fetchSubscriptions(state, action: PayloadAction<string | undefined>) {
      state.isLoading = true

      const dummySub1 = new SubscriptionPriceModel()
      dummySub1.id = "1"

      const dummySub2 = new SubscriptionPriceModel()
      dummySub2.id = "2"
      state.subscriptions = [dummySub1, dummySub2]
    },
    fetchSubscriptionsSucceeded(state, action: PayloadAction<SubscriptionPriceModel[]>) {
      state.isLoading = false
      state.subscriptions = action.payload
    },
    fetchSubscriptionsFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false
      state.subscriptions = []

      if (action.payload instanceof BaseError) {
        state.fetchSubscriptionsError = action.payload as any
      } else {
        const error = new GenericError('SUBSCRIPTION_FETCH_ERROR')
        error.localizedMessage = { key: 'subscription:subscription_error_message_getting_subscriptions' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.fetchSubscriptionsError = error as any
      }
    },
    setFreeTrialCollabExpDate(state, action: PayloadAction<number>) {

    },
    setFreeTrialCollabExpDateFailed(state) {

    },
    setFreeTrialCollabExpDateSucceeded(state) {

    },
    cancelSubscription(state) {
      state.isCancelingSubscription = true
    },
    cancelSubscriptionSucceeded(state) {
      state.isCancelingSubscription = false
    },
    cancelSubscriptionFailed(state, action: PayloadAction<Error>) {
      state.isCancelingSubscription = false

      if (action.payload instanceof BaseError) {
        state.cancelSubscriptionError = action.payload as any
      } else {
        const error = new GenericError('SUBSCRIPTION_CANCEL_ERROR')
        error.localizedMessage = { key: 'subscription:subscription_error_message_canceling_subscription' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.cancelSubscriptionError = error as any
      }
    },
    createCheckoutSession(state, action: PayloadAction<{ id: string, promoCode?: string }>) {
      state.isCreatingCheckoutMap[action.payload.id] = true
    },
    createCheckoutSessionSucceeded(state, action: PayloadAction<{ id: string, response: CreateCheckoutResponseModel }>) {
      state.isCreatingCheckoutMap[action.payload.id] = false
      state.checkoutSessionUrl = action.payload.response.url
    },
    createCheckoutSessionFailed(state, action: PayloadAction<{ error: Error, id: string }>) {
      state.isCreatingCheckoutMap[action.payload.id] = false

      if (action.payload.error instanceof BaseError) {
        state.createSessionError = action.payload.error as any
      } else {
        const error = new GenericError('UPGRADE_CHECKOUT_ERROR')
        error.localizedMessage = { key: 'subscription:subscription_error_message_preparing_checkout' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload.error
        state.createSessionError = error as any
      }
    }
  }
})

export const subscriptionReducer = subscriptionSlice.reducer

export const {
  reset,
  resetCheckoutSession,
  clearErrors,
  fetchSubscriptions,
  fetchSubscriptionsFailed,
  fetchSubscriptionsSucceeded,
  fetchUserSubscription,
  fetchUserSubscriptionFailed,
  fetchUserSubscriptionSucceeded,
  createCheckoutSession,
  createCheckoutSessionFailed,
  createCheckoutSessionSucceeded,
  cancelSubscription,
  cancelSubscriptionFailed,
  cancelSubscriptionSucceeded,
  setFreeTrialCollabExpDate,
  setFreeTrialCollabExpDateFailed,
  setFreeTrialCollabExpDateSucceeded
} = subscriptionSlice.actions

export const selectFetchSubscriptionsError = (state: RootState) => state.subscription.fetchSubscriptionsError
export const selectCreateSessionError = (state: RootState) => state.subscription.createSessionError
export const selectUserSubscriptionError = (state: RootState) => state.subscription.userSubscriptionError
export const selectCancelSubscriptionError = (state: RootState) => state.subscription.cancelSubscriptionError
export const selectIsLoading = (state: RootState) => state.subscription.isLoading
export const selectIsLoadingUserSubscription = (state: RootState) => state.subscription.isLoadingUserSubscription
export const selectIsCancelingSubscription = (state: RootState) => state.subscription.isCancelingSubscription
export const selectSubscriptions = (state: RootState) => state.subscription.subscriptions
export const selectUserSubscription = (state: RootState) => state.subscription.userSubscription
export const selectIsCreatingCheckout = (state: RootState, id?: string) => (id ? state.subscription.isCreatingCheckoutMap[id] : Object.values(state.subscription.isCreatingCheckoutMap).some((v) => v))
export const selectCheckoutSessionUrl = (state: RootState) => state.subscription.checkoutSessionUrl
