import React, {useEffect, useRef, useState} from "react";
import {CloseIcon, LoadingSpinnerIcon, MarkerFilledIcon} from "../icons";
import {Heading4, HeroHeading2} from "../text";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  deleteLocation,
  fetchAndSaveLocation,
  fetchSavedLocations,
  fetchSearchResults,
  reset,
  selectError,
  selectIsLoading, selectIsSaving,
  selectIsSearching,
  selectLocationEntryItems,
  selectLocationSearchItems,
  selectQuery, selectSelectedLocation,
  selectShouldClose,
  updatePrimaryLocation
} from "../../redux/location/locationEntrySlice";
import {BodyInput} from "../inputs";
import {LocationEntryOptionView} from "./LocationEntryOptionView";
import {LocationEntrySearchResultView} from "./LocationEntrySearchResultView";
import {AlertDialog} from "../dialogs/AlertDialog";
import {LocationModel} from "../../models/location";
import {Container} from "../containers/Container";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";
import {LocalizationInfo} from "react-i18next";
import {Button} from "../buttons/Button";

export interface LocationEntryViewProps {
  onClose: () => void
}

export const LocationEntryView: React.FC<LocationEntryViewProps> = ({ onClose }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch();
  const locationSearchItems = useAppSelector(selectLocationSearchItems)
  const locationEntryItems = useAppSelector(selectLocationEntryItems);
  const isSearching = useAppSelector(selectIsSearching);
  const isLoading = useAppSelector(selectIsLoading);
  const isSaving = useAppSelector(selectIsSaving);
  const selectedLocation = useAppSelector(selectSelectedLocation);
  const shouldClose = useAppSelector(selectShouldClose);
  const query = useAppSelector(selectQuery);
  const error = useAppSelector(selectError);

  const [location, setLocation] = useState<LocationModel | null>()

  const cityRef = useRef<HTMLInputElement>(null)
  const cityValidation = (text?: string | null): string | LocalizationInfo | null | undefined | boolean => {
    return (!text && !location?.address?.locality) ? { key: 'location:location_error_message_city_is_empty' } : true
  }

  const stateRef = useRef<HTMLInputElement>(null)
  const stateValidation = (text?: string | null): string | LocalizationInfo | null | undefined | boolean => {
    return (!text && !location?.address?.administrativeArea) ? { key: 'location:location_error_message_state_is_empty' } : true
  }

  const zipRef = useRef<HTMLInputElement>(null)
  const zipValidation = (text?: string | null): string | LocalizationInfo | null | undefined | boolean => {
    return (!text && !location?.address?.postalCode) ? { key: 'location:location_error_message_state_is_empty' } : true
  }

  const address2Ref = useRef<HTMLInputElement>(null)

  const inputRef = useRef<HTMLInputElement>(null)

  if (query === "" && inputRef.current) {
    inputRef.current.value = ""
  }

  const [locationToDelete, setLocationToDelete] = useState<LocationModel | null>(null)

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Location Selector' }))
  }, [])

  useEffect(() => {
    dispatch(reset())
    dispatch(fetchSavedLocations())
  }, [dispatch])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  useEffect(() => {
    setLocation(selectedLocation)

    const streetAddress = selectedLocation?.address?.getFullStreetAddress()
    if (streetAddress && inputRef.current) {
      inputRef.current.value = streetAddress
    }
  }, [selectedLocation])

  return (
    <Container className="h-full min-h-screen-3/4 md:min-h-screen-2/3 w-full overflow-y-scroll max-h-screen flex-1 sticky">
      <AlertDialog
        isOpen={!!error}
        error={error}
        buttons={[
          {
            title: { key: 'common:common_title_ok' },
            onClick: () => onClose(),
            style: 'default'
          }
        ]}
      />
      <AlertDialog
        isOpen={!!locationToDelete}
        title={{ key: 'location:location_alert_title_confirm_delete_address' }}
        message={{ key: 'location:location_alert_message_confirm_delete_address' }}
        buttons={[
          {
            title: { key: 'common:common_title_delete' },
            onClick: () => {
              locationToDelete && dispatch(deleteLocation(locationToDelete))
              setLocationToDelete(null)
            },
            style: 'destructive'
          },
          {
            title: { key: 'common:common_title_cancel' },
            onClick: () => setLocationToDelete(null),
            style: 'cancel'
          }
        ]}
      />
      <div className="-ml-3 -mt-3 flex items-center justify-between">
        <button
          type="button"
          className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
          onClick={() => onClose()}
        >
          <CloseIcon className="h-4 h-4" />
        </button>
      </div>
      <div className="mt-4">
        <HeroHeading2>{t('location:location_title_delivery_address')}</HeroHeading2>
      </div>
      <div className="mt-6">
        <BodyInput
          ref={inputRef}
          autoComplete="off"
          leadingIcon={isSearching ? <LoadingSpinnerIcon className="animate-spin text-fg-primary" /> : <MarkerFilledIcon />}
          placeholder={ t('location:location_placeholder_add_a_new_address') }
          onChange={e => {
            const text = e.target.value
            dispatch(fetchSearchResults(text))
          }}
        />
      </div>
      <ul className="mt-4">
        {
          (() => {
            if (locationSearchItems.length === 0 && !selectedLocation) {
              return locationEntryItems.map((item, index) => {
                return <li
                  key={item.location.locationId}
                  id={item.location.locationId}
                >
                  <LocationEntryOptionView
                    item={item}
                    onDeleteClick={() => {
                      setLocationToDelete(item.location)
                    }}
                    onClick={() => {
                      dispatch(updatePrimaryLocation(item.location))
                    }}
                    isLastItem={index === locationEntryItems.length - 1}
                  />
                </li>
              })
            } else if (!selectedLocation) {
              return locationSearchItems.map((item, index) => {
                return <li
                  key={item.id}
                  id={item.id}
                >
                  <LocationEntrySearchResultView
                    item={item}
                    isLastItem={index === locationSearchItems.length - 1}
                    key={item.id}
                    onClick={() => {
                      const placeId = item.value?.place_id
                      placeId && dispatch(fetchAndSaveLocation(placeId))
                    }}
                  />
                </li>
              })
            } else {
              return <div className="flex flex-col space-y-5">
                <div className={`grid grid-cols-12 gap-3`}>
                  <div className="col-span-12 lg:col-span-6">
                    <Heading4 className="mb-2">{t('location:location_title_city')}</Heading4>
                    <BodyInput
                      ref={cityRef}
                      defaultValue={selectedLocation?.address?.locality}
                      placeholder={t('location:location_title_city')}
                      onChange={(e) => {
                        const updatedLocation = new LocationModel(selectedLocation)
                        if (updatedLocation?.address) {
                          updatedLocation.address.locality = e.target.value
                          setLocation(updatedLocation)
                        }
                      }}
                      validate={cityValidation}
                    />
                  </div>

                  <div className="col-span-6 lg:col-span-3">
                    <Heading4 className="mb-2">{t('location:location_title_state')}</Heading4>
                    <BodyInput
                      ref={stateRef}
                      defaultValue={selectedLocation?.address?.administrativeArea}
                      onChange={(e) => {
                        const updatedLocation = new LocationModel(selectedLocation)
                        if (updatedLocation?.address) {
                          updatedLocation.address.administrativeArea = e.target.value
                          setLocation(updatedLocation)
                        }
                      }}
                      placeholder={t('location:location_title_state')}
                      validate={stateValidation}
                    />
                  </div>

                  <div className="col-span-6 lg:col-span-3">
                    <Heading4 className="mb-2">{t('location:location_title_zip')}</Heading4>
                    <BodyInput
                      ref={zipRef}
                      defaultValue={selectedLocation?.address?.postalCode}
                      onChange={(e) => {
                        const updatedLocation = new LocationModel(selectedLocation)
                        if (updatedLocation?.address) {
                          updatedLocation.address.postalCode = e.target.value
                          setLocation(updatedLocation)
                        }
                      }}
                      placeholder={t('location:location_title_zip')}
                      validate={zipValidation}
                    />
                  </div>
                </div>

                <div>
                  <Heading4 className="mb-2">{t('location:location_title_apt_num')}</Heading4>
                  <BodyInput
                    ref={address2Ref}
                    defaultValue={selectedLocation?.address?.subpremise}
                    onChange={(e) => {
                      const updatedLocation = new LocationModel(selectedLocation)
                      if (updatedLocation?.address) {
                        updatedLocation.address.subpremise = e.target.value
                        setLocation(updatedLocation)
                      }
                    }}
                    placeholder={t('location:location_title_apt_num')}
                  />
                </div>

                <div>
                  <Button
                    size="lg"
                    isFullWidth={true}
                    isDisabled={!location?.address?.locality || !location?.address?.administrativeArea || !location?.address?.postalCode}
                    isLoading={isSaving}
                    onClick={() => {
                      location && dispatch(updatePrimaryLocation(location))
                    }}
                  >
                    {t('common:common_title_save')}
                  </Button>
                </div>
              </div>
            }
          })()
        }
      </ul>
    </Container>
  )
}