import React, {Fragment, ReactElement, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {LocalizationInfo} from "react-i18next";
import {ApiError, ApiErrorActionStyle} from "../../errors/apiError";
import {Button, ButtonStyle} from "../buttons/Button";
import {LocalizedTextLabel, TextStyles} from "../text";
import {useTranslation} from "next-i18next";
import {BaseError} from "../../errors/baseError";
import {useDeeplinkRouter} from "../../utils/useDeeplinkRouter";

export interface AlertDialogButtonProps {
  title?: LocalizationInfo | string | null
  onClick?: () => void
  style: ApiErrorActionStyle
}

export interface AlertDialogProps {
  isOpen: boolean
  onClose?: () => void
  error?: BaseError<any> | null
  icon?: React.ReactNode
  title?: LocalizationInfo | string | null
  message?: LocalizationInfo | string | null
  buttons?: AlertDialogButtonProps[]
  isDismissable?: boolean
}

export const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  let { icon, title, message, buttons, error, isOpen, onClose, isDismissable } = props

  const deepLinkRouter = useDeeplinkRouter()

  if (error instanceof ApiError) {
    title = error.localizedTitle
    message = error.localizedMessage

    if (error.actions && error.actions.length > 0) {
      buttons = error.actions.map(a => {
        return {
          title: a.title,
          style: a.style ?? 'default',
          onClick: () => {
            if (!a.linkUrl) return
            const url = new URL(a.linkUrl)
            deepLinkRouter.handleDeeplinkUrl(url)
          }
        }
      })
    }
  } else if (error) {
    title = error.localizedTitle
    message = error.localizedMessage
  }
  const { t } = useTranslation()

  const iconProps = icon && (icon as ReactElement)?.props
  const iconClassName = `${iconProps ? iconProps.className : ''} h-6 w-6`

  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => { onClose && onClose() }}>
      <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" onClose={v => {
        isDismissable && setOpen(v)
      }}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className={`mx-auto mb-3 sm:mb-5 flex items-center justify-center ${icon ? '' : 'hidden'}`}>
                  {icon && React.cloneElement(icon as React.ReactElement, { className: iconClassName })}
                </div>
                <div className="text-center">
                  <Dialog.Title as="h3" className={`${TextStyles.Heading3}`}>
                    <LocalizedTextLabel t={t} text={title} />
                  </Dialog.Title>
                  <div className="mt-2">
                    <Dialog.Description as="p" className={`text-fg-gray-dark ${TextStyles.Paragraph}`}>
                      <LocalizedTextLabel t={t} text={message} />
                    </Dialog.Description>
                  </div>
                </div>
              </div>
              <div className={`${buttons!.length > 2 || buttons!.length === 1 ? 'grid grid-rows-1 gap-3' : 'grid gap-3 grid-rows-1 sm:grid-cols-2 sm:grid-flow-row-dense'} mx-auto mt-5 sm:mt-6 `}>
                {buttons!.map((button, index) => {
                  let buttonType: ButtonStyle;
                  switch (button.style) {
                    case "default":
                      buttonType = "primary"
                      break;
                    case "cancel":
                      buttonType = "secondary"
                      break;
                    case "destructive":
                      buttonType = "destructive"
                      break;

                  }
                  return <Button
                    key={index.toString()}
                    buttonStyle={buttonType}
                    isFullWidth={true}
                    onClick={() => {
                      setOpen(false)
                      button.onClick && button.onClick()
                    }}
                  >
                    <LocalizedTextLabel t={t} text={button.title} />
                  </Button>
                })}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

AlertDialog.defaultProps = {
  icon: null,
  buttons: [{ title: 'OK', style: 'default' }],
  isDismissable: true
}