import {BaseError} from "./baseError";
import {PlacesResponseStatus} from "googlemaps";
import {LocalizationInfo} from "react-i18next";

export class PlacesError extends BaseError<PlacesResponseStatus> {
  localizedMessage: LocalizationInfo | string
  localizedTitle: LocalizationInfo | string

  constructor(code: PlacesResponseStatus, error?: Error | null, query?: string) {
    super(code, error)

    switch (code) {
      case "OK":
        this.localizedMessage = ''
        this.localizedTitle = ''
        break
      case "ZERO_RESULTS":
        this.localizedMessage = { key: "common:places_error_message_no_results"}
        this.localizedTitle = { key: "common:places_error_title_no_results"}
        break
      case "NOT_FOUND":
        this.localizedMessage = { key: "common:places_error_message_not_found"}
        this.localizedTitle = { key: "common:places_error_title_not_found"}
        break
      case "INVALID_REQUEST":
        this.localizedMessage = { key: "common:common_error_title_something_went_wrong"}
        this.localizedTitle = { key: "common:places_error_message_generic"}
        break
      case "OVER_QUERY_LIMIT":
        this.localizedMessage = { key: "common:common_error_title_something_went_wrong"}
        this.localizedTitle = { key: "common:places_error_message_generic"}
        break
      case "REQUEST_DENIED":
        this.localizedMessage = { key: "common:common_error_title_something_went_wrong"}
        this.localizedTitle = { key: "common:places_error_message_generic"}
        break
      case "UNKNOWN_ERROR":
        this.localizedMessage = { key: "common:common_error_title_something_went_wrong"}
        this.localizedTitle = { key: "common:places_error_message_generic"}
        break
    }
  }
}