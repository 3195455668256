import {CohortModel} from "../../models/cohort";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";

interface CohortState {
  readonly isLoading: boolean
  readonly cohorts: CohortModel[]
  readonly error?: BaseError<any>
}

const cohortSlice = createSlice({
  name: 'cohort',
  initialState: {
    isLoading: false,
    cohorts: [] as CohortModel[]
  } as CohortState,
  reducers: {
    fetchCohorts(state) {
      state.isLoading = true
    },
    fetchCohortsSucceeded(state, action: PayloadAction<CohortModel[]>) {
      state.cohorts = action.payload
      state.isLoading = false
    },
    fetchCohortsFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('COHORT_ERROR')
        error.localizedMessage = 'There was a problem getting cohorts'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
      // state.cohorts = []
    },
  }
})

export const cohortReducer = cohortSlice.reducer

export const {
  fetchCohorts,
  fetchCohortsSucceeded,
  fetchCohortsFailed
} = cohortSlice.actions

export const selectError = (state: RootState) => state.cohort.error
export const selectIsGettingCohorts = (state: RootState) => state.cohort.isLoading
export const selectCohorts = (state: RootState) => state.cohort.cohorts
