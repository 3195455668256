import {
  AnalyticsEvent,
  EventPropertyName,
  EventProps,
  getEventPropValueFromActivityLevel,
  getEventPropValueFromBodyType,
  getEventPropValueFromDietaryPreference,
  getEventPropValueFromDietGoal,
  getEventPropValueFromHeightUnit, getEventPropValueFromWeightUnit,
  GlobalEventProperty
} from "./eventProperties";
import mixpanel from "mixpanel-browser";
import {UserModel} from "../models/user";
import OneSignal from "react-onesignal";
import {DeeplinkService} from "../services/deeplinkService";
import {LocationModel} from "../models/location";
import {StoreService} from "../services/storeService";
import {BehaviorSubject, catchError, zip} from "rxjs";
import {logError, logErrorRx} from "./logError";
import {ShoppingCartModel} from "../models/shoppingCart";
import {ImageUtils} from "./imageUtils";
import {filter, map} from "rxjs/operators";
import {StoreModel} from "../models/store";
import {NutritionProtocolModel} from "../models/nutritionProtocol";
import {NutritionProtocolConfigModel} from "../models/nutritionProtocolConfig";
import {FitnessProfileModel, HeightUnit, WeightUnit} from "../models/fitnessProfile";

type ReactPixelSDK = typeof import('react-facebook-pixel');

const reactPixelSubject: BehaviorSubject<ReactPixelSDK | null | undefined> = new BehaviorSubject<ReactPixelSDK | null | undefined>(null)

export const EventLogger = {
  setReactPixelSdk(reactPixelSdk?: ReactPixelSDK | null) {
    reactPixelSubject.next(reactPixelSdk)
  },
  getReactPixelSdk() {
    return reactPixelSubject?.pipe(filter(pixel => pixel != null));
  },
  alias(user: UserModel) {
    const email = user.email
    const userId = user.userId

    if (userId) {
      mixpanel.alias(userId, mixpanel.get_distinct_id())
      DeeplinkService.getBranchSdk()?.setIdentity(userId)
    }
  },
  logout() {
    DeeplinkService.getBranchSdk()?.logout()
    OneSignal.removeExternalUserId()
    OneSignal.logoutEmail()
  },
  setUserIdentifier(user?: UserModel | null) {
    const email = user?.email
    const userId = user?.userId
    const onesignalEmailAuthHash = user?.onesignalEmailAuthHash
    const onesignalUserIdAuthHash = user?.onesignalExternalIdAuthHash

    if (userId) {
      mixpanel.identify(userId)
      DeeplinkService.getBranchSdk()?.setIdentity(userId)
    } else {
      // For now, let's not identify users until they've logged in
      // mixpanel.identify()
    }

    if (email && onesignalEmailAuthHash) {
      OneSignal.setEmail(email, { emailAuthHash: onesignalEmailAuthHash })
        .catch((e) => {
          logError(e)
        })
    }
    if (userId && onesignalUserIdAuthHash) {
      OneSignal.setExternalUserId(userId, onesignalUserIdAuthHash)
        .catch((e) => {
          logError(e)
        })
    }
  },
  updateGlobalPropertiesWithCampaignUrl(url: URL) {
    let campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
    let kw = ''
    let params: any = {}
    let first_params: any = {}

    let index;
    for (index = 0; index < campaign_keywords.length; ++index) {
      kw = url.searchParams.get(campaign_keywords[index]) ?? '';
      if (kw.length) {
        params[campaign_keywords[index] + ' [last touch]'] = kw;
      }
    }
    for (index = 0; index < campaign_keywords.length; ++index) {
      kw = url.searchParams.get(campaign_keywords[index]) ?? '';
      if (kw.length) {
        first_params[campaign_keywords[index] + ' [first touch]'] = kw;
      }
    }
    mixpanel.people.set(params);
    mixpanel.people.set_once(first_params);
    mixpanel.register(params);
  },
  updateGlobalPropertiesWithLocation(deliveryLocation: LocationModel) {
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Delivery Location City', value: deliveryLocation.address?.locality }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Delivery Location Latitude', value: deliveryLocation.latitude }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Delivery Location Longitude', value: deliveryLocation.longitude }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Delivery Location State', value: deliveryLocation.address?.administrativeArea }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Delivery Location Type', value: deliveryLocation.type }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Delivery Location Zip', value: deliveryLocation.address?.postalCode }))
  },
  updateGlobalPropertiesWithStore(store: StoreModel) {
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Selected Vendor Name', value: store.header?.title }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Selected Vendor ID', value: store.vendorId ?? store.id }))
  },
  updateGlobalPropertyWithUser(user: UserModel) {
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: '$email', value: user.email }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: '$first_name', value: user.firstName }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: '$last_name', value: user.lastName }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: '$phone', value: user.phoneNumber }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'User ID', value: user.userId }))
  },
  updateGlobalPropertiesWithCart(cart?: ShoppingCartModel | null) {
    const lineItemNames: string[] = cart?.items
      ?.map((c) => c.name)
      .filter((c) => c)
      .map((c) => c as string) ?? []
    const lineItemIds: string[] = cart?.items
      ?.map((c) => c.skuId)
      .filter((c) => c)
      .map((c) => c as string) ?? []
    const lineItemImageUrls: string[] = cart?.items
      ?.map((c) => ImageUtils.createUrlFromImage(c.image))
      .filter((c) => c)
      .map((c) => c as string) ?? []

    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Cart Store ID', value: cart?.storeId }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Cart Line Item Names', value: lineItemNames }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Cart Line Item IDs', value: lineItemIds }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Cart Line Item Image URLs', value: lineItemImageUrls }))
  },
  updateGlobalPropertiesWithNutritionProtocol(protocol: NutritionProtocolModel, config: NutritionProtocolConfigModel) {
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Weekly Update Status', value: config.isEnabled ? 'Enabled' : 'Disabled' }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Lifetime Nutrition Plan Update Count', value: 1 }))
  },
  updateGlobalPropertiesWithFitnessProfile(fitnessProfile: FitnessProfileModel) {
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Activity Level', value: getEventPropValueFromActivityLevel(fitnessProfile.activityLevel) }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Body Type', value: getEventPropValueFromBodyType(fitnessProfile.bodyType) }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Diet Length (Weeks)', value: fitnessProfile.dietLengthWeeks }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Diet Goal', value: getEventPropValueFromDietGoal(fitnessProfile.goal) }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Dietary Preference', value: getEventPropValueFromDietaryPreference(fitnessProfile.dietaryPreference) }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Estimated Body Fat (%)', value: fitnessProfile.bodyFat * 100 }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Goal Weight (lbs)', value: fitnessProfile.goalWeight }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Starting Weight (lbs)', value: fitnessProfile.startingWeight }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Height (in)', value: fitnessProfile.height }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Height Units', value: getEventPropValueFromHeightUnit(fitnessProfile.heightUnits ?? HeightUnit.imperial) }))
    this.updateGlobalProperties(new GlobalEventProperty({ eventName: 'Weight Units', value: getEventPropValueFromWeightUnit(fitnessProfile.weightUnits ?? WeightUnit.imperial) }))
  },
  updateGlobalProperties(prop: GlobalEventProperty) {
    const eventName = prop.payload.eventName

    if (prop.getEventPropValue() == null) return

    switch (eventName) {
      case "Account Type":
        mixpanel.people.set(eventName, prop.getEventPropValue())
        mixpanel.register({ [eventName]: prop.getEventPropValue() })
        break;
      case "$email":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "Cart Store ID":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "Delivery Location City":
        mixpanel.people.set(eventName, prop.payload.value)
        mixpanel.register({ [eventName]: prop.payload.value })
        break;
      case "Delivery Location Latitude":
        mixpanel.people.set(eventName, prop.payload.value)
        mixpanel.register({ [eventName]: prop.payload.value })
        break;
      case "Delivery Location Longitude":
        mixpanel.people.set(eventName, prop.payload.value)
        mixpanel.register({ [eventName]: prop.payload.value })
        break;
      case "Delivery Location State":
        mixpanel.people.set(eventName, prop.payload.value)
        mixpanel.register({ [eventName]: prop.payload.value })
        break;
      case "Delivery Location Type":
        mixpanel.people.set(eventName, prop.payload.value)
        mixpanel.register({ [eventName]: prop.payload.value })
        break;
      case "Delivery Location Zip":
        mixpanel.people.set(eventName, prop.payload.value)
        mixpanel.register({ [eventName]: prop.payload.value })
        break;
      case "$first_name":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "Last Viewed Line Item Name":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "Last Viewed Line Item ID":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "$last_name":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "Lifetime Purchase Value":
        const props: EventProps = {
          'Purchase Type': prop.payload.purchaseType
        }
        mixpanel.people.track_charge(
          prop.payload.value,
          props
        )
        break;
      case "Cart Line Item Names":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "Cart Line Item IDs":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "Cart Line Item Image URLs":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "$phone":
        mixpanel.people.set(eventName, prop.payload.value)
        break;
      case "Selected Vendor Name":
        mixpanel.people.set(eventName, prop.payload.value)
        mixpanel.register({ [eventName]: prop.payload.value })
        break;
      case "Selected Vendor ID":
        mixpanel.people.set(eventName, prop.payload.value)
        mixpanel.register({ [eventName]: prop.payload.value })
        break;
    }
  },
  logEvent(event: AnalyticsEvent, additionalProperties?: EventProps) {
    event.getProps()
      .pipe(
        map(props => {
          if (additionalProperties) {
            Object.entries(additionalProperties).forEach(([key, value]) => {
              props[key as EventPropertyName] = value
            })
          }

          return props
        })
      )
      .subscribe(props => {
        if (event.destinations.includes('facebook') && process.env.NODE_ENV === 'production') {
          switch (event.payload.eventName) {
            case "Payment Info Added": {
              this.getReactPixelSdk()?.subscribe({
                next: pixel => {
                  pixel?.track('AddPaymentInfo')
                }
              })
              break;
            }
            case "Checkout Initiated": {
              const cart = event.payload.cart
              const content_ids = event.payload.cart.items.map((i) => i.skuId)

              const products = event.payload.cart.items.map(i => {
                return StoreService.fetchProduct(i.productId ?? '')
                  .pipe(map(product => {
                    const selectedSku = product.skus.filter(s => s.id === i.skuId)[0]
                    selectedSku ? selectedSku.quantity = i.quantity : {}
                    selectedSku && product.setSelectedSku(selectedSku)
                    return selectedSku
                  }))
              })

              zip(products)
                .pipe(catchError((e) => logErrorRx(e)))
                .subscribe({
                  next: (skus) => {
                    const contents = skus.filter(sku => sku)
                      .map(sku => ({ id: sku.barcode, quantity: sku.quantity }))

                    this.getReactPixelSdk()?.subscribe({
                      next: pixel => {
                        pixel?.track('InitiateCheckout', {
                          content_ids,
                          contents,
                          num_items: cart.items.length,
                          value: cart.totalPrice,
                          currency: cart.currencyCode
                        })
                      }
                    })
                  }
                })
              break;
            }
            case "Product Added To Cart": {
              const product = event.payload.product
              const sku = product.getSelectedSku()
              const value = (sku?.price ?? 0) * (event.payload.quantity ?? 1)
              const quantity = event.payload.quantity

              this.getReactPixelSdk()?.subscribe({
                next: pixel => {
                  pixel?.track('AddToCart', {
                    content_type: 'Product',
                    content_name: product.name,
                    content_ids: [sku?.id],
                    contents: [{id: sku?.barcode, quantity: quantity}],
                    currency: sku?.currencyCode,
                    product_catalog_id: sku?.id,
                    value
                  })
                }
              })
              break;
            }
            case "Product Viewed": {
              const product = event.payload.product
              const sku = product.getSelectedSku()
              const value = (sku?.price ?? 0) * (event.payload.quantity ?? 1)
              const quantity = event.payload.quantity

              this.getReactPixelSdk()?.subscribe({
                next: pixel => {
                  pixel?.track('ViewContent', {
                    content_type: 'Product',
                    content_name: product.name,
                    content_ids: [sku?.id],
                    contents: [{id: sku?.barcode, quantity: quantity}],
                    currency: sku?.currencyCode,
                    product_catalog_id: sku?.id,
                    value
                  })
                }
              })
              break;
            }
          }
        }

        if (event.destinations.includes('mixpanel')) {
          mixpanel.track(event.payload.eventName, props)
        }
      })
  }
}