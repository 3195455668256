import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  clearError,
  createEmail,
  deleteEmail,
  fetchEmail,
  reset,
  selectConfig,
  selectEmail,
  selectError,
  selectIsDeleting,
  selectIsLoading,
  selectIsSubmitting,
  selectShouldClose,
  updateEmail
} from "../../redux/email/emailDetailSlice";
import {fetchCohorts, selectCohorts, selectIsGettingCohorts} from "../../redux/cohort/cohortSlice";
import React, {useEffect, useState} from "react";
import {Container} from "../containers/Container";
import {CloseIcon, LoadingSpinnerIcon} from "../icons";
import {EmailModel, EmailTemplateDataType} from "../../models/email";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Heading4, HeroHeading2, TextStyles} from "../text";
import {BodyInput, BodyTextArea} from "../inputs";
import DOMPurify from 'dompurify';
import {Button} from "../buttons/Button";
import {EmailDetailSendModal} from "./EmailDetailSendModal";
import {PromptModel, PromptScreen} from "../../models/prompt";

export interface EmailDetailViewProps {
  onClose: () => void
  emailId?: string
}

export const EmailDetailView: React.FC<EmailDetailViewProps> = ({ onClose, emailId }) => {
  const dispatch = useAppDispatch();

  const shouldClose = useAppSelector(selectShouldClose)
  const error = useAppSelector(selectError)
  const email = useAppSelector(selectEmail)
  const config = useAppSelector(selectConfig)
  const isLoading = useAppSelector(selectIsLoading)
  const cohorts = useAppSelector(selectCohorts)
  const isDeleting = useAppSelector(selectIsDeleting)
  const isGettingCohorts = useAppSelector(selectIsGettingCohorts)
  const isSubmitting = useAppSelector(selectIsSubmitting)

  const templateDataTypes: EmailTemplateDataType[] = ['featured_products']

  const [payload, setPayload] = useState<EmailModel>()
  const [sendModalOpen, setSendModalOpen] = useState({ isOpen: false, isTest: true })
  const [emailIdToDelete, setEmailIdToDelete] = useState<string>()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    if (emailId) {
      dispatch(fetchEmail(emailId))
    } else {
      const defaultEmail = new EmailModel()
      defaultEmail.utmMedium = 'email'
      defaultEmail.utmSource = 'email'
      setPayload(defaultEmail)
    }

    dispatch(fetchCohorts())
  }, [emailId, dispatch])

  useEffect(() => {
    email && setPayload(email)
  }, [email])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  if (isLoading || !payload || isGettingCohorts) {
    return <div
      className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    >
      <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
        <div className="w-full no-scrollbar flex-1 flex flex-col">
          <div className={`${isLoading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
            <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
          </div>
        </div>
      </Container>
    </div>
  }

  return <form
    className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    onSubmit={e => {
      e.preventDefault()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      isDismissable={false}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            dispatch(clearError())
          },
          style: 'default'
        }
      ]}
    />
    <AlertDialog
      isOpen={!!emailIdToDelete}
      message={'Are you sure you want to delete this email?'}
      title={'Delete Email?'}
      buttons={[
        {
          title: { key: 'common:common_title_delete' },
          onClick: () => {
            emailIdToDelete && dispatch(deleteEmail(emailIdToDelete))
            setEmailIdToDelete(undefined)
          },
          style: 'destructive'
        },
        {
          title: { key: 'common:common_title_cancel' },
          onClick: () => setEmailIdToDelete(undefined),
          style: 'cancel'
        }
      ]}
    />

    <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
      <div className="w-full no-scrollbar flex-1 flex flex-col">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`${isLoading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
          <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
        </div>

        <div className={`mt-4 mb-6 lg:mb-8`}>
          <HeroHeading2>{config === 'create' ? 'Create Email' : 'Update Email'}</HeroHeading2>
        </div>

        <div className="flex flex-col space-y-5">
          <div>
            <Heading4 className="mb-2">Sender Name</Heading4>
            <BodyInput
              type="text"
              placeholder="Sender Name"
              defaultValue={payload?.senderName}
              onChange={e => {
                if (payload) {
                  payload.senderName = e.target.value
                  setPayload(new EmailModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Sender Email</Heading4>
            <BodyInput
              type="text"
              placeholder="Sender Email"
              defaultValue={payload?.senderEmail}
              onChange={e => {
                if (payload) {
                  payload.senderEmail = e.target.value
                  setPayload(new EmailModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Name</Heading4>
            <BodyInput
              type="text"
              placeholder="Name"
              defaultValue={payload?.name}
              onChange={e => {
                if (payload) {
                  payload.name = e.target.value
                  setPayload(new EmailModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">UTM Campaign</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Campaign"
              defaultValue={payload?.utmCampaign}
              onChange={e => {
                if (payload) {
                  payload.utmCampaign = e.target.value
                  setPayload(new EmailModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">UTM Source</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Source"
              defaultValue={payload?.utmSource}
              onChange={e => {
                if (payload) {
                  payload.utmSource = e.target.value
                  setPayload(new EmailModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">UTM Medium</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Medium"
              defaultValue={payload?.utmMedium}
              onChange={e => {
                if (payload) {
                  payload.utmMedium = e.target.value
                  setPayload(new EmailModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Insert Data</Heading4>
            <select
              id="template_data_types"
              name="template_data_types"
              multiple={true}
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payload?.templateDataTypes}
              onChange={e => {
                const dataTypes = [...e.target.options]
                  .filter((o) => o.selected)
                  .map((o) => o.value)
                if (payload) {
                  payload.templateDataTypes = dataTypes as EmailTemplateDataType[]
                  setPayload(new EmailModel(payload))
                }
              }}
              className={`bg-fg-gray-surface-light w-full h-full border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              {
                templateDataTypes
                  .map(i => {
                    return <option key={i} value={i}>{i}</option>
                  })
              }
            </select>
          </div>

          <div>
            <Heading4 className="mb-2">Subject</Heading4>
            <BodyInput
              type="text"
              placeholder="Subject"
              defaultValue={payload?.subject}
              onChange={e => {
                if (payload) {
                  payload.subject = e.target.value
                  setPayload(new EmailModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">HTML</Heading4>
            <BodyTextArea
              placeholder="Paste your email's HTML here"
              defaultValue={payload?.html}
              onChange={e => {
                if (payload) {
                  payload.html = e.target.value
                  setPayload(new EmailModel(payload))
                }
              }}
            />
          </div>

          <div className={`${payload?.html ? '' : 'hidden'}`}>
            <Heading4 className="mb-2">Email Preview</Heading4>
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(payload?.html ?? '<div></div>')}} />
          </div>

          <div className={`${isLoading || error ? 'hidden' : ''} mt-4 w-full items-centered space-x-0 space-y-3 lg:flex-row lg:space-x-2 lg:space-y-5`}>
            <div>
              <Button
                isFullWidth={true}
                isLoading={isSubmitting}
                buttonStyle="primary"
                size="lg"
                onClick={() => {
                  if (config === 'create' && payload) {
                    dispatch(createEmail({ email: payload }))
                  } else if (config === 'update' && emailId && payload) {
                    dispatch(updateEmail({ email: payload, id: emailId }))
                  }
                }}
              >
                {config === 'create' ? 'Create Email' : 'Update Email'}
              </Button>
            </div>

            <div>
              <Button
                buttonStyle="secondary"
                isFullWidth={true}
                size="lg"
                onClick={() =>{
                  setSendModalOpen({ isOpen: true, isTest: true })
                }}
              >
                Send Test
              </Button>
            </div>

            {config === 'update' && <div className="flex-1">
                <Button
                    isFullWidth={true}
                    buttonStyle="text_destructive"
                    isLoading={isDeleting}
                    size="lg"
                    onClick={() => {
                      setEmailIdToDelete(emailId)
                    }}
                >
                    Delete
                </Button>
            </div>}
          </div>
        </div>
      </div>
    </Container>

    <EmailDetailSendModal
      isOpen={sendModalOpen.isOpen}
      email={payload}
      isTest={sendModalOpen.isTest}
      onClose={() => {
        setSendModalOpen({ isOpen: false, isTest: true })
      }}
    />
  </form>
}