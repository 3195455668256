import {NutrientType} from "./nutrient";
import {NutritionProtocolModel} from "./nutritionProtocol";

export class NutritionTargetModel {
  endDate?: Date
  nutrients: Map<NutrientType, number> = new Map<NutrientType, number>()
  parentProtocol?: NutritionProtocolModel
  startDate?: Date

  constructor(payload: { parentProtocol?: NutritionProtocolModel, startDate?: Date, endDate?: Date }) {
    const { parentProtocol, startDate, endDate } = payload
    if (!parentProtocol) return

    const model = new NutritionTargetModel({})
    model.endDate = endDate
    model.nutrients = parentProtocol.getNutrients()
    model.startDate = startDate
    model.parentProtocol = parentProtocol

    return model
  }
}