import {BaseError} from "../../errors/baseError";
import {PushNotificationModel} from "../../models/pushNotification";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {PushNotificationsResponseModel} from "../../models/pushNotificationsResponse";
import {RootState} from "../../app/store";

interface PushNotificationListState {
  readonly isLoading: boolean
  readonly isLoadingMore: boolean
  readonly pushNotifications: PushNotificationModel[]
  readonly totalPushNotifications: number
  readonly currentPage: number
  readonly isUpdatingPushNotificationMap: { [key: string]: boolean }
  readonly error?: BaseError<any>
}

export const pushNotificationListSlice = createSlice({
  name: 'push_notification_list',
  initialState: {
    isLoading: false,
    isLoadingMore: false,
    pushNotifications: [],
    totalPushNotifications: 0,
    currentPage: 1,
    isUpdatingPushNotificationMap: {},
  } as PushNotificationListState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.isLoadingMore = false
      state.pushNotifications = []
      state.error = undefined
      state.isUpdatingPushNotificationMap = {}
      state.currentPage = 1
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    fetchPushNotifications(state, action: PayloadAction<number>) {
      state.isLoadingMore = true
      if (!state.pushNotifications.length) {
        state.isLoading = true
      }
    },
    deletePushNotification(state, action: PayloadAction<string>) {
      state.isUpdatingPushNotificationMap[action.payload] = true
    },
    deletePushNotificationSucceeded(state, action: PayloadAction<string>) {
      state.isUpdatingPushNotificationMap[action.payload] = false
    },
    deletePushNotificationFailed(state, action: PayloadAction<{ error: Error, id: string }>) {
      state.isUpdatingPushNotificationMap[action.payload.id] = false

      if (action.payload.error instanceof BaseError) {
        state.error = action.payload.error as any
      } else {
        const error = new GenericError('PUSH_NOTIFICATION_DELETE_ERROR')
        error.localizedMessage = 'There was a problem deleting this push notification'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload.error
        state.error = error as any
      }
    },
    fetchPushNotificationsSucceeded(state, action: PayloadAction<PushNotificationsResponseModel>) {
      if (state.isLoading) {
        state.pushNotifications = action.payload.pushNotifications
      } else {
        state.pushNotifications = state.pushNotifications.concat(action.payload.pushNotifications)
      }

      state.totalPushNotifications = action.payload.totalResults
      state.isLoading = false
      state.isLoadingMore = false
    },
    fetchPushNotificationsFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false
      state.isLoadingMore = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PUSH_NOTIFICATION_ERROR')
        error.localizedMessage = 'There was a problem getting push notifications'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
  }
})

export const pushNotificationListReducer = pushNotificationListSlice.reducer

export const {
  reset,
  fetchPushNotifications,
  fetchPushNotificationsFailed,
  fetchPushNotificationsSucceeded,
  setCurrentPage,
  deletePushNotification,
  deletePushNotificationFailed,
  deletePushNotificationSucceeded
} = pushNotificationListSlice.actions

export const selectError = (state: RootState) => state.pushNotificationList.error
export const selectPushNotifications = (state: RootState) => state.pushNotificationList.pushNotifications
export const selectIsLoading = (state: RootState) => state.pushNotificationList.isLoading
export const selectIsLoadingMore = (state: RootState) => state.pushNotificationList.isLoadingMore
export const selectTotalPushNotifications = (state: RootState) => state.pushNotificationList.totalPushNotifications
export const selectCurrentPage = (state: RootState) => state.pushNotificationList.currentPage
export const selectIsUpdating = (state: RootState, pushNotificationId?: string) => (pushNotificationId ? state.pushNotificationList.isUpdatingPushNotificationMap[pushNotificationId] : false)
