export interface ProductOptionJson {
  _id?: string
  menu_item_id?: string
  name?: string
  sort_number?: number
  values?: string[]
}

export class ProductOptionModel {
  id?: string
  productId?: string
  name?: string
  sortNumber?: number
  values: string[] = []

  constructor(model?: ProductOptionModel | null) {
    if (!model) return

    this.id = model.id
    this.productId = model.productId
    this.name = model.name
    this.sortNumber = model.sortNumber
    this.values = model.values
  }

  static fromJson(json?: ProductOptionJson): ProductOptionModel | undefined {
    if (!json) return

    return {
      id: json._id,
      productId: json.menu_item_id,
      name: json.name,
      sortNumber: json.sort_number,
      values: json.values ?? []
    }
  }
}