import React, {useRef, useState} from "react";
import {Container} from "../containers/Container";
import {CloseIcon, MailFilledIcon} from "../icons";
import {Heading4, HeroHeading2} from "../text";
import {Modal} from "../modals/Modal";
import {BodyInput} from "../inputs";
import {useTranslation} from "next-i18next";
import {PromptModel} from "../../models/prompt";
import {Button} from "../buttons/Button";
import {MessageApi} from "../../apis/messageApi";
import {BaseError} from "../../errors/baseError";
import {GenericError} from "../../errors/genericError";
import {AlertDialog} from "../dialogs/AlertDialog";

export interface PromptDetailSendTestModalProps {
  isOpen: boolean
  prompt: PromptModel | string
  onClose: () => any
}

export const PromptDetailSendTestModal: React.FC<PromptDetailSendTestModalProps> = ({ isOpen, onClose, prompt }) => {
  const { t } = useTranslation()
  const emailRef = useRef<HTMLInputElement>(null)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<BaseError<any>>()

  const onSubmit = () => {
    const email = emailRef.current?.value
    if (!email) return

    let sendObservable;
    if (typeof prompt === "string") {
      sendObservable = MessageApi.sendPrompt([email], undefined, prompt)
    } else {
      MessageApi.setTestEmail(email)
      sendObservable = MessageApi.sendPrompt([email], prompt.toJson())
    }

    setIsSubmitting(true)
    sendObservable.subscribe({
      next: () => {
        setIsSubmitting(false)
        onClose()
      },
      error: (e) => {
        setIsSubmitting(false)

        if (e instanceof BaseError) {
          setError(e)
        } else {
          const error = new GenericError('PROMPT_ERROR')
          error.localizedMessage = 'There was a problem getting this prompt'
          error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
          error.error = e
          setError(error)
        }
      },
    })
  }

  return <Modal
    className="md:max-w-lg"
    isOpen={isOpen}
    onClose={() =>{
      onClose()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      isDismissable={false}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            setError(undefined)
          },
          style: 'default'
        }
      ]}
    />
    <form
      className="max-h-screen md:max-h-screen-1/2 flex flex-col w-full sticky"
      onSubmit={e => {
        e.preventDefault()
      }}
    >
      <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
        <div className="w-full no-scrollbar flex-1 flex flex-col">
          <div className="-ml-3 -mt-3 flex items-center justify-between">
            <button
              type="button"
              className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
              onClick={() => {
                onClose()
              }}
            >
              <CloseIcon className="h-4 h-4" />
            </button>
          </div>

          <div className={`mt-4 mb-6 lg:mb-8`}>
            <HeroHeading2>Send Test Prompt</HeroHeading2>
          </div>

          <div className="flex flex-col space-y-5">
            <div>
              <Heading4 className="mb-2">{t('auth:auth_placeholder_email')}</Heading4>
              <BodyInput
                type="email"
                autoComplete="email"
                ref={emailRef}
                defaultValue={MessageApi.getTestEmail() ?? undefined}
                leadingIcon={<MailFilledIcon />}
                placeholder={t('auth:auth_placeholder_email')}
              />
            </div>

            <Button
              isFullWidth={true}
              size="lg"
              type="submit"
              isLoading={isSubmitting}
              buttonStyle="primary"
              onClick={onSubmit}
            >
              Send Test Prompt
            </Button>
          </div>
        </div>
      </Container>
    </form>
  </Modal>
}