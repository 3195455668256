import React, {useEffect, useState} from "react";
import {
  fetchAutocompletePredictions,
  fetchCurrentLocation,
  fetchLocationFromPlaceId,
  locationSearchFocused,
  LocationSearchItem,
  selectInputAddress,
  selectIsSearching,
  selectItems,
  selectListIsOpen,
  setListOpen
} from "../../redux/location/locationSearchSlice";
import {LoadingSpinnerIcon, MarkerFilledIcon, NavigationFilledIcon, SearchIcon, WarningFilledIcon} from "../icons";
import {Body, LocalizedTextLabel, Paragraph} from "../text";
import {BodyInput} from "../inputs";
import {AutocompletePrediction} from "googlemaps";
import {AutoCompleteInput, AutocompleteItem} from "../inputs/AutoCompleteInput";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectBillingAddress, selectValidationError} from "../../redux/payment/paymentEntrySlice";

export const PaymentBillingAddressInputView: React.FC<any> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const items = useAppSelector(selectItems)
  const billingAddress = useAppSelector(selectBillingAddress)
  const isSearching = useAppSelector(selectIsSearching)
  const isOpen = useAppSelector(selectListIsOpen)
  const validationError = useAppSelector(selectValidationError)
  const [ref, setRef] = useState<React.RefObject<HTMLInputElement> | null>(null)

  const autoCompleteItems: AutocompleteItem<LocationSearchItem>[] = items.map(item => {
    return {
      id: item.id,
      value: item
    }
  })

  useEffect(() => {
    if (!billingAddress && ref?.current) {
      ref.current.value = ""
    }
  }, [billingAddress, ref])

  return <AutoCompleteInput
    t={t}
    isOpen={isOpen}
    inputValue={billingAddress?.getFormattedAddress() ?? undefined}
    items={autoCompleteItems}
    onFocus={() => {
      dispatch(locationSearchFocused())
    }}
    onInputRefChanged={ref => {
      setRef(ref)
    }}
    onBlur={() => {
      dispatch(setListOpen(false))
    }}
    renderItem={ (item, index) => {
      return (
        <div className={`px-4 py-3 flex items-center ${index !== autoCompleteItems.length - 1 ? 'border-b border-fg-gray-surface' : ''}`}>
          <div>
            {
              (() => {
                if (item.value.isLoading) {
                  return <LoadingSpinnerIcon className="h-6 h-6 mr-4 animate-spin text-fg-primary" />
                } else if (item.value.type === 'error') {
                  return <WarningFilledIcon className="h-6 w-6 mr-4" />
                } else if (item.value.type === 'current_location') {
                  return <NavigationFilledIcon className="text-fg-primary h-6 w-6 mr-4" />
                } else if (item.value.type === 'no_results') {
                  return <SearchIcon className="h-6 w-6 mr-4" />
                } else {
                  return <MarkerFilledIcon className="h-6 w-6 mr-4" />
                }
              })()
            }
          </div>
          <div className="flex-grow">
            <Body className={`${item.value.subtitle ? 'mb-1' : ''}`}>
              <LocalizedTextLabel t={t} text={item.value.title} />
            </Body>
            <Paragraph className={`${item.value.subtitle ? 'block' : 'hidden'}`}>
              <LocalizedTextLabel t={t} text={item.value.subtitle} />
            </Paragraph>
          </div>
        </div>
      )
    }}
    renderInput={reqProps => {
      // @ts-ignore
      return <BodyInput
        autoComplete="off"
        validate={() => (validationError?.code === "INVALID_ADDRESS" ? validationError.localizedMessage : undefined)}
        trailingIcon={isSearching ? <LoadingSpinnerIcon className="animate-spin text-fg-primary" /> : undefined}
        placeholder={ t('payment:payment_placeholder_billing_address') }
        {...reqProps}
      />
    }}
    onItemSelected={item => {
      if (item.value.type === 'prediction') {
        const prediction = item.value.value as AutocompletePrediction
        dispatch(fetchLocationFromPlaceId({ placeId: prediction.place_id, save: false }))
      } else if (item.value.type === 'current_location') {
        dispatch(fetchCurrentLocation({ save: false }))
      }

      return { shouldDismiss: false }
    }}
    onTextChanged={(text) => {
      dispatch(fetchAutocompletePredictions(text))
    }}
  />
}