import React, {Fragment} from "react";
import {useAppSelector} from "../../app/hooks";
import {selectAddedCartItem, selectAddedCartItemVisible} from "../../redux/navigation/navigationSlice";
import {Popover, Transition} from '@headlessui/react'
import {NavBarNegativeInset} from "../navBars/NavBarMain";
import {Body} from "../text";
import {Image} from "../images/Image";
import {ImageUtils} from "../../utils/imageUtils";
import {CurrencyUtils} from "../../utils/currencyUtils";


export const AddedCartMenuView: React.FC<any> = () => {
  const item = useAppSelector(selectAddedCartItem)
  const addedCartItemVisible = useAppSelector(selectAddedCartItemVisible)
  const imageUrl = ImageUtils.createUrlFromImage(item?.image)
  const unitPrice = (item?.price ?? 0) * (item?.quantity ?? 0)
  const totalPrice = CurrencyUtils.stringFromPrice(unitPrice, item?.currencyCode)

  return (
    <div>
      <Popover>
        {({ open }) => {
          open = addedCartItemVisible

          return <>
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`fixed z-30 right-8 transform mt-3 w-screen h-0 max-w-lg ${NavBarNegativeInset}`} static>
                <div className="transform rounded-lg shadow-lg ring-1 bg-white ring-fg-gray-surface">
                  <div className="p-5 flex flex-row items-center space-x-6">
                    <Body>{item?.quantity}</Body>
                    {imageUrl && <Image src={imageUrl} sizeClass="h-12 w-12" objectFit="cover" className="rounded-lg" />}
                    <Body className="flex-grow">{item?.name}</Body>
                    <Body>{totalPrice}</Body>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        }}
      </Popover>
    </div>
  )
}