import {MessageCampaignFilterType, MessageCampaignNodeModel} from "../../models/messageCampaignNode";
import React, {useEffect} from "react";
import {Heading2, Heading4, TextStyles} from "../text";
import {BodyInput} from "../inputs";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fetchCohorts, selectCohorts, selectIsGettingCohorts} from "../../redux/cohort/cohortSlice";
import {CloseIcon} from "../icons";

interface CampaignDetailFilterNodeViewProps {
  node: MessageCampaignNodeModel,
  index: number,
  onChange: (node: MessageCampaignNodeModel) => void
  onDelete: (node: MessageCampaignNodeModel) => void
}

export const CampaignDetailFilterNodeView: React.FC<CampaignDetailFilterNodeViewProps> = ({ node, index, onChange, onDelete }) => {
  const dispatch = useAppDispatch()

  const cohorts = useAppSelector(selectCohorts)
  const isGettingCohorts = useAppSelector(selectIsGettingCohorts)

  useEffect(() => {
    dispatch(fetchCohorts())
  }, [dispatch])

  return <div className="flex flex-col space-y-5">
    <div className={`flex space-x-4`}>
      <Heading2 className={`flex-grow`}>{`Node #${index + 1}`}</Heading2>

      <div className={`flex-shrink-0 ${index === 0 ? 'hidden' : ''}`}>
        <button
          type="button"
          className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
          onClick={() => {
            onDelete(node)
          }}
        >
          <CloseIcon className="h-4 h-4" />
        </button>
      </div>
    </div>

    <div>
      <Heading4 className="mb-2">{`Name`}</Heading4>
      <BodyInput
        type="text"
        placeholder="Node Name"
        defaultValue={node.name}
        onChange={e => {
          node.name = e.target.value
          onChange(new MessageCampaignNodeModel(node))
        }}
      />
    </div>

    <div>
      <Heading4 className="mb-2">{`Filter Type`}</Heading4>
      <select
        id="filter_type"
        name="filter_type"
        onClick={e => {
          e.stopPropagation()
        }}
        defaultValue={node.filter?.type}
        onChange={e => {
          if (!node.filter) return

          node.filter.type = e.target.value as MessageCampaignFilterType
          onChange(new MessageCampaignNodeModel(node))
        }}
        className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
      >
        <option key={'in'} value={'in'}>In</option>
        <option key={'out'} value={'out'}>Out</option>
      </select>
    </div>

    {/*<div className={`${!cohorts.length && !isGettingCohorts ? '' : 'hidden'}`}>*/}
    {/*  <Heading4 className="mb-2">Mixpanel Cohort ID</Heading4>*/}
    {/*  <BodyInput*/}
    {/*    type="text"*/}
    {/*    placeholder="Mixpanel Cohort ID"*/}
    {/*    defaultValue={node.filter?.cohortId}*/}
    {/*    onChange={e => {*/}
    {/*      if (!node.filter) return*/}

    {/*      node.filter.cohortId = e.target.value*/}
    {/*      onChange(new MessageCampaignNodeModel(node))*/}
    {/*    }}*/}
    {/*  />*/}
    {/*</div>*/}

    <div className={`${!cohorts.length && !isGettingCohorts ? 'hidden' : ''}`}>
      <Heading4 className="mb-2">Cohort</Heading4>
      <select
        id="cohort"
        name="cohort"
        multiple={true}
        onClick={e => {
          e.stopPropagation()
        }}
        defaultValue={node.filter?.cohortIds}
        onChange={e => {
          if (!node.filter) return

          const selectedCohorts = [...e.target.options]
            .filter((o) => o.selected)
            .map((o) => o.value)

          node.filter.cohortIds = selectedCohorts
          onChange(new MessageCampaignNodeModel(node))
        }}
        className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
      >
        {
          cohorts
            .map(i => {
              return <option key={i.id?.toString()} value={i.id?.toString()}>{i.name}</option>
            })
        }
      </select>
    </div>

    <div>
      <Heading4 className="mb-2">{`Execute After (Hours)`}</Heading4>
      <BodyInput
        type="number"
        placeholder="Execute After (Hours)"
        defaultValue={node.executeAfter ? node.executeAfter / 3600 : node.executeAfter}
        onChange={e => {
          node.executeAfter = parseInt(e.target.value) * 3600
          onChange(new MessageCampaignNodeModel(node))
        }}
      />
    </div>
  </div>
}