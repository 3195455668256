import React, {useEffect, useState} from "react";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {
  addPaymentSource,
  clearError,
  reset,
  selectError,
  selectIsSaving,
  selectShouldClose
} from "../../redux/payment/paymentEntrySlice";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Container} from "../containers/Container";
import {CloseIcon} from "../icons";
import {Heading4, HeroHeading2} from "../text";
import {reset as resetLocationSearch} from "../../redux/location/locationSearchSlice";
import {CardBrand} from "../../models/card";
import {PaymentBillingAddressInputView} from "./PaymentBillingAddressInputView";
import {PaymentCardNumberInputView} from "./PaymentCardNumberInputView";
import {PaymentExpDateInputView} from "./PaymentExpDateInputView";
import {PaymentCvcInputView} from "./PaymentCvcInputView";
import {Button} from "../buttons/Button";
import { isSafari, isMobile } from 'react-device-detect';
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export interface PaymentEntryViewProps {
  onClose: () => void
}

export const PaymentEntryView: React.FC<PaymentEntryViewProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const error = useAppSelector(selectError)
  const isSaving = useAppSelector(selectIsSaving)
  const shouldClose = useAppSelector(selectShouldClose)
  const dispatch = useAppDispatch()
  const [cardBrand, setCardBrand] = useState<CardBrand>('unknown')

  useEffect(() => {
    dispatch(resetLocationSearch())
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = () => {
    const cardNumberElement = elements?.getElement(CardNumberElement)

    if (!cardNumberElement) return
    if (!stripe) return

    dispatch(addPaymentSource({ stripe, element: cardNumberElement }))
  }

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Payment Method Entry' }))
  }, [])

  return <form
    className="max-h-screen flex flex-col w-full min-h-screen md:min-h-screen-2/3 sticky"
    onSubmit={e => {
      e.preventDefault()
      handleSubmit()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            dispatch(clearError())
          },
          style: 'default'
        }
      ]}
    />
    <Container className="overflow-y-scroll max-h-screen flex-1 w-full">
      <div className="w-full no-scrollbar">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`mt-4 mb-6 lg:mb-8`}>
          <HeroHeading2>{t('payment:payment_title_add_payment_method')}</HeroHeading2>
        </div>

        <div className="flex flex-col space-y-5">
          <div className={`${isMobile || isSafari ? 'hidden' : ''}`}>
            <Heading4 className="mb-2">{t('payment:payment_placeholder_billing_address')}</Heading4>
            <PaymentBillingAddressInputView />
          </div>

          <div>
            <Heading4 className="mb-2">{t('payment:payment_label_card_number')}</Heading4>
            <PaymentCardNumberInputView onChange={e => setCardBrand(e.brand)} />
          </div>

          <div className="grid grid-cols-2 gap-3">
            <div>
              <Heading4 className="mb-2">{t('payment:payment_label_card_exp')}</Heading4>
              <PaymentExpDateInputView />
            </div>

            <div>
              <Heading4 className="mb-2">{t('payment:payment_label_card_cvc')}</Heading4>
              <PaymentCvcInputView cardBrand={cardBrand} />
            </div>
          </div>

          <div className={`${isMobile || isSafari ? '' : 'hidden'}`}>
            <Heading4 className="mb-2">{t('payment:payment_placeholder_billing_address')}</Heading4>
            <PaymentBillingAddressInputView />
          </div>

          <div>
            <Button
              size="lg"
              isFullWidth={true}
              isLoading={isSaving}
              onClick={() => {
                handleSubmit()
              }}
            >
              {t('payment:payment_title_add_card')}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  </form>
}