import {BaseRealmService} from "../realm/realmService";
import {Observable, of } from "rxjs";
import {EntityModel} from "./entityModel";
import {ObjectSchema} from "mongodb-realm";

export enum ActivityLevel {
  low = 1,
  moderate = 2,
  high = 3,
  veryHigh = 4
}

export enum BodyType {
  lean = 1,
  moderatelyLean = 2,
  average = 3,
  moderatelyOverweight = 4,
  overweight = 5,
  obese = 6,
}

export enum DietaryPreference {
  lowFat = 1,
  lowCarb = 2,
  vegan = 3,
  vegetarian = 4,
  paleo = 5,
  everything = 6,
}

export enum Gender {
  male = 1,
  female = 2
}

export enum DietGoal {
  bulk = 1,
  cut = 2,
  maintenance = 3
}

export enum HeightUnit {
  imperial = "in",
  metric = "cm"
}

export enum WeightUnit {
  imperial = "lbs",
  metric = "kg"
}

export interface FitnessProfileJson {
  activityLevel?: ActivityLevel
  bodyFat?: number
  bodyType?: BodyType
  timeFrame?: number
  dietaryPreference?: DietaryPreference
  gender?: Gender
  goal?: DietGoal
  goalWeight?: number
  height?: number
  heightUnits?: HeightUnit
  _id?: string
  weight?: number
  weightUnits?: WeightUnit
}

export class FitnessProfileModel implements EntityModel<FitnessProfileModel> {
  public static schema: ObjectSchema = {
    name: 'FitnessProfileEntity',
    embedded: true,
    properties: {
      activityLevel: 'int',
      bodyFat: 'double',
      bodyType: 'int',
      dietLengthWeeks: 'int',
      dietaryPreference: 'int',
      gender: 'int',
      goal: 'int',
      goalWeight: 'double',
      height: 'double',
      heightUnits: 'string?',
      startingWeight: 'double',
      weightUnits: 'string?',
    },
  }

  schema = FitnessProfileModel.schema

  activityLevel: ActivityLevel = 2
  bodyFat: number = 0.2
  bodyType: BodyType = BodyType.average
  dietLengthWeeks: number = 4
  dietaryPreference: DietaryPreference = DietaryPreference.everything
  gender: Gender = Gender.female
  goal: DietGoal = DietGoal.cut
  goalWeight: number = 0
  height: number = 0
  heightUnits?: HeightUnit
  startingWeight: number = 0
  weightUnits?: WeightUnit

  constructor(entity?: FitnessProfileModel | null) {
    if (!entity) return

    this.activityLevel = entity.activityLevel
    this.bodyFat = entity.bodyFat
    this.bodyType = entity.bodyType
    this.dietLengthWeeks = entity.dietLengthWeeks
    this.dietaryPreference = entity.dietaryPreference
    this.gender = entity.gender
    this.goal = entity.goal
    this.goalWeight = entity.goalWeight
    this.height = entity.height
    this.heightUnits = entity.heightUnits
    this.startingWeight = entity.startingWeight
    this.weightUnits = entity.weightUnits

    return this
  }

  static fromJson(json?: FitnessProfileJson): FitnessProfileModel | undefined {
    if (!json) return

    const model = new FitnessProfileModel()

    model.activityLevel = json.activityLevel ?? model.activityLevel
    model.bodyFat = json.bodyFat ?? model.bodyFat
    model.dietLengthWeeks = json.timeFrame ?? model.dietLengthWeeks
    model.dietaryPreference = json.dietaryPreference ?? model.dietaryPreference
    model.gender = json.gender ?? model.gender
    model.goal = json.goal ?? model.goal
    model.goalWeight = json.goalWeight ?? model.goalWeight
    model.height = json.height ?? model.height
    model.heightUnits = json.heightUnits
    model.startingWeight = json.weight ?? model.startingWeight
    model.weightUnits = json.weightUnits

    return model
  }

  toEntityObservable(realmService: BaseRealmService): Observable<FitnessProfileModel> {
    return of(this)
  }
}