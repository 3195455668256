import {Button, ButtonProps} from "./Button";
import {Heading4, LocalizedTextLabel} from "../text";
import {LocalizationInfo} from "react-i18next";
import {useTranslation} from "next-i18next";

export interface CartButtonProps extends ButtonProps {
  total: string
  buttonTitle?: string | LocalizationInfo,
  quantity?: string
}

export const CartButton: React.FC<CartButtonProps> = (props) => {
  const {
    total,
    buttonTitle,
    quantity,
    isLoading
  } = props

  const { t } = useTranslation()

  const buttonProps = {...props}
  delete buttonProps.buttonTitle

  return (
    <Button {...buttonProps} isFullWidth>
      <div className="grid grid-cols-5 gap-2 items-center justify-items-center">
        <div className={`center justify-self-start col-span-1 rounded-full h-8 w-8 flex items-center ${quantity ? '' : 'hidden lg:block lg:invisible'} ${isLoading ? 'bg-transparent' : 'bg-fg-primary-darker'}`}>
          <Heading4 className="m-auto">{quantity}</Heading4>
        </div>
        <Heading4 className={`text-center capitalize ${quantity ? 'col-span-3' : 'col-span-4 lg:col-span-3'}`}>
          <LocalizedTextLabel t={t} text={buttonTitle} />
        </Heading4>
        <Heading4 className="text-left col-span-1 justify-self-end hover:bg-fg-primary-dark">{total}</Heading4>
      </div>
    </Button>
  )
}