import {RootEpic} from "../../app/store";
import {filter} from "rxjs/operators";
import {
  deletePaymentMethod,
  deletePaymentMethodFailed,
  deletePaymentMethodSucceeded,
  fetchPaymentMethods,
  fetchPaymentMethodsFailed,
  fetchPaymentMethodsSucceeded,
  setDefaultPaymentMethod,
  setDefaultPaymentMethodFailed,
  setDefaultPaymentMethodSucceeded
} from "./paymentSelectorSlice";
import {catchError, map, mergeMap, of} from "rxjs";
import {PaymentService} from "../../services/paymentService";
import {logErrorRx} from "../../utils/logError";

export const fetchPaymentMethodsEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchPaymentMethods.match),
    mergeMap(() => {
      return PaymentService.fetchPaymentSources()
        .pipe(
          map(cards => {
            const defaultCard = cards.filter(c => c.isDefault)[0]
            return { cards, defaultCard }
          }),
          map(fetchPaymentMethodsSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchPaymentMethodsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const setDefaultPaymentMethodEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(setDefaultPaymentMethod.match),
    mergeMap(action => {
      const sourceId = action.payload.id!
      return PaymentService.setDefaultPaymentSource(sourceId)
        .pipe(
          map(() => setDefaultPaymentMethodSucceeded(action.payload)),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: setDefaultPaymentMethodFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deletePaymentMethodEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(deletePaymentMethod.match),
    mergeMap(action => {
      const sourceId = action.payload.id!
      return PaymentService.deletePaymentSource(sourceId)
        .pipe(
          map(() => deletePaymentMethodSucceeded(action.payload)),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: deletePaymentMethodFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}