import {StoreSelectorModel} from "../../models/storeSelector";
import {DistributionMethod} from "../../models/distributionMethod";
import {ImageModel} from "../../models/image";
import {ProductModel} from "../../models/product";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BaseError} from "../../errors/baseError";
import {GenericError} from "../../errors/genericError";
import {StoreSelectorOptionModel} from "../../models/storeSelectorOption";
import {WritableDraft} from "immer/dist/types/types-external";
import {RootState} from "../../app/store";

type StoreFeedConfig = 'add_location' | 'standard' | 'no_stores_available' | 'no_pickup_available' | 'no_delivery_available'

export interface StoreFeedItem {
  readonly id: string
  readonly title?: string
  readonly subtitle?: string
  readonly iconImage?: ImageModel
  readonly products: ProductModel[]
  readonly totalItemCount?: number
  readonly storeAttributes?: { text: string, id: string }[]
}

// State
interface StoreFeedState {
  readonly isLoading: boolean
  readonly storeSelector?: StoreSelectorModel
  readonly config: StoreFeedConfig
  readonly distributionMethod: DistributionMethod
  readonly items: StoreFeedItem[]
  readonly error?: BaseError<any>
}

const createStoreFeedItems = (stores: StoreSelectorOptionModel[] | WritableDraft<StoreSelectorOptionModel[]>, distributionMethod: DistributionMethod): StoreFeedItem[] => {
  return stores.filter(s => s.distributionOptions.includes(distributionMethod)).map((store) => {
    let subtitle: string | undefined;
    if (store.categories.length > 0) {
      subtitle = store.categories.map(c => c.name).join(' • ')
    } else {
      subtitle = store.subtitle
    }

    const attributes: { text: string, id: string }[] = store.badges
      .map(badge => badge.title && badge.id ? ({ text: badge.title, id: badge.id }) : undefined)
      .flatMap(i => i ? [i] : []) ?? []

    return {
      id: store.storeId ?? '',
      title: store.title,
      subtitle,
      iconImage: store.iconImage,
      products: store.featuredProducts,
      totalItemCount: store.totalItemCount,
      storeAttributes: attributes,
    } as StoreFeedItem
  })
}

const createStoreFeedConfig = (storeSelector: StoreSelectorModel | WritableDraft<StoreSelectorModel>, distributionMethod: DistributionMethod): StoreFeedConfig => {
  const stores = storeSelector.stores.filter(s => s.distributionOptions.includes(distributionMethod))

  let config: StoreFeedConfig;
  if (storeSelector.state === 'standard') {
    if (distributionMethod === 'delivery' && stores.length === 0) {
      config = 'no_delivery_available'
    } else if (distributionMethod === 'pickup' && stores.length === 0) {
      config = 'no_pickup_available'
    } else {
      config = 'standard'
    }
  } else if (storeSelector.state === 'no_stores_available') {
    config = "no_stores_available"
  } else {
    config = "no_stores_available"
  }

  return config
}

export const storeFeedSlice = createSlice({
  name: "store_feed",
  initialState: {
    isLoading: false,
    config: "standard",
    items: [],
    distributionMethod: "delivery"
  } as StoreFeedState,
  reducers: {
    updateDistributionMethod(state, action: PayloadAction<DistributionMethod>) {
      state.distributionMethod = action.payload

      const storeSelector = state.storeSelector
      if (!storeSelector) return

      const stores: WritableDraft<StoreSelectorOptionModel[]> = storeSelector.stores ?? []

      const items: StoreFeedItem[] = createStoreFeedItems(stores, action.payload)
      const config: StoreFeedConfig = createStoreFeedConfig(storeSelector, action.payload)

      state.items = items
      state.config = config
    },
    /// shouldAutoRoute determines if this action should automatically push a store page from the store feed
    /// when there is only one store in store feed
    fetchStoreFeed(state, action: PayloadAction<{ shouldAutoRoute: boolean }>) {
      state.isLoading = true
      state.error = undefined
    },
    fetchStoreFeedSucceeded(state, action: PayloadAction<StoreSelectorModel>) {
      const distributionMethod = state.distributionMethod
      const storeSelector = action.payload
      const {
        stores
      } = storeSelector

      const items: StoreFeedItem[] = createStoreFeedItems(stores, distributionMethod)
      const config: StoreFeedConfig = createStoreFeedConfig(storeSelector, distributionMethod)

      state.isLoading = false
      state.items = items
      state.config = config
      state.storeSelector = storeSelector
    },
    fetchStoreFeedFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('STORE_FEED_ERROR', action.payload)
        error.localizedMessage = { key: 'store:store_error_message_error_getting_options' }
        error.localizedTitle = { key: 'store:store_error_title_error_getting_options' }
        error.error = action.payload
        state.error = error as any
      }
    },
  },
})

export const storeFeedReducer = storeFeedSlice.reducer

export const {
  updateDistributionMethod,
  fetchStoreFeed,
  fetchStoreFeedFailed,
  fetchStoreFeedSucceeded
} = storeFeedSlice.actions

export const selectItems = (state: RootState) => state.storeFeed.items
export const selectIsLoading = (state: RootState) => state.storeFeed.isLoading
export const selectConfig = (state: RootState) => state.storeFeed.config
export const selectError = (state: RootState) => state.storeFeed.error
export const selectDistributionMethod = (state: RootState) => state.storeFeed.distributionMethod