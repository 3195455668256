import {PromoCodeModel} from "../../models/promoCode";
import React from "react";
import {savePromoCode} from "../../redux/promoCodeEntry/promoCodeEntrySlice";
import {useAppDispatch} from "../../app/hooks";
import {Body, Paragraph} from "../text";
import Skeleton from "react-loading-skeleton";
import {ArrowNextIcon} from "../icons";

export interface PromoCodeEntryItemViewProps {
  promoCode: PromoCodeModel
  isLoading: boolean
}

export const PromoCodeEntryItemView: React.FC<PromoCodeEntryItemViewProps> = ({ promoCode, isLoading }) => {
  const dispatch = useAppDispatch()

  return <div
    className={`flex flex-row items-center space-x-4 lg:space-x-6 py-5 cursor-pointer border-b last:border-0 border-fg-gray-surface`}
    onClick={() => {
      dispatch(savePromoCode(promoCode))
    }}
  >
    <div className="flex-grow">
      <Body className={`${promoCode.description ? 'mb-1' : ''}`}>
        {isLoading ? <Skeleton className="!w-3/4"/> : promoCode.title}
      </Body>
      <Paragraph className={`${promoCode.description ? 'block' : 'hidden'} text-fg-gray-dark`}>
        {isLoading ? <Skeleton className="!w-1/3"/> : promoCode.description}
      </Paragraph>
    </div>
    <div className={`flex-shrink-0 ${isLoading ? 'hidden' : ''}`}>
      <ArrowNextIcon className="h-3 w-3"/>
    </div>
  </div>
}