import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {Modal} from "../modals/Modal";
import {LocationEntryView} from "./LocationEntryView";

export const LocationEntryModal: React.FC<any> = () => {
  const { isOpen, onClose } = useModalRouting()

  return <Modal
    className="md:max-w-2xl"
    isOpen={isOpen('location_entry')}
    onClose={() => onClose()}
  >
    <LocationEntryView onClose={() => onClose()} />
  </Modal>
}