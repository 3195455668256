import {SubscriptionApi} from "../apis/subscriptionApi";
import {Observable} from "rxjs";
import {SubscriptionPriceModel} from "../models/subscriptionPrice";
import {CreateCheckoutResponseModel} from "../models/createCheckoutSessionResponse";
import {InAppSubscriptionModel} from "../models/inAppSubscription";

export const SubscriptionService = {
  fetchSubscriptionPrices(code?: string): Observable<SubscriptionPriceModel[]> {
    return SubscriptionApi.getSubscriptionPrices(code)
  },
  createCheckoutSession(id: string, promoCode?: string): Observable<CreateCheckoutResponseModel> {
    return SubscriptionApi.createCheckoutSession(id, promoCode)
  },
  cancelSubscription(): Observable<void> {
    return SubscriptionApi.cancelSubscription()
  },
  fetchCurrentSubscription(): Observable<InAppSubscriptionModel> {
    return SubscriptionApi.getCurrentSubscription()
  },
  setFreeTrialExpDate(date: Date): Observable<void> {
    return SubscriptionApi.setFreeTrialExpDate(date)
  }
}