import {ProductOptionItem} from "../../redux/product/productSlice";
import React from "react";
import {CurrencyUtils} from "../../utils/currencyUtils";
import {useTranslation} from "next-i18next";
import {RadioEmptyIcon, RadioFilledIcon} from "../icons";
import {Body} from "../text";

export interface ProductDetailSkuOptionViewProps {
  item: ProductOptionItem
  isLastItem: boolean
  onClick: () => void
}

export const ProductDetailSkuOptionView: React.FC<ProductDetailSkuOptionViewProps> = ({ item, isLastItem, onClick }) => {
  const { t } = useTranslation()

  const { value, minPrice, sku, isSelected } = item

  const priceDiff = Math.abs((sku.price ?? 0) - (minPrice ?? 0))
  const priceDiffText = CurrencyUtils.stringFromPrice(priceDiff, sku.currencyCode)

  return (
    <div onClick={() => onClick()}>
      <div className={`flex items-center ${!isLastItem ? 'border-b border-fg-gray-surface' : ''}`}>
        <div className="flex py-4 flex-grow items-center cursor-pointer">
          <div>
            {
              (() => {
                if (isSelected) {
                  return <RadioFilledIcon className="text-fg-primary h-6 w-6 mr-4" />
                } else {
                  return <RadioEmptyIcon className="text-fg-gray-light h-6 w-6 mr-4" />
                }
              })()
            }
          </div>
          <div className="flex-grow">
            <Body>{value}</Body>
          </div>
          <div className={`${priceDiff === 0 ? 'hidden' : ''}`}>
            <Body className="text-fg-gray-dark text-right">{`(+${priceDiffText})`}</Body>
          </div>
        </div>
      </div>
    </div>
  )
}