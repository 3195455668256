import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  clearError,
  createPushNotification,
  deletePushNotification,
  fetchPushNotification,
  reset,
  selectConfig,
  selectError,
  selectIsDeleting,
  selectIsLoading,
  selectIsSubmitting,
  selectPushNotification,
  selectShouldClose,
  updatePushNotification
} from "../../redux/pushNotification/pushNotificationDetailSlice";
import {selectIsGettingCohorts} from "../../redux/cohort/cohortSlice";
import React, {useEffect, useState} from "react";
import {Container} from "../containers/Container";
import {CloseIcon, LoadingSpinnerIcon} from "../icons";
import {PushNotificationModel} from "../../models/pushNotification";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Heading4, HeroHeading2} from "../text";
import {BodyInput} from "../inputs";
import {Button} from "../buttons/Button";
import {PushNotificationDetailSendModal} from "./PushNotificationDetailSendModal";
import Moment from "moment";

export interface PushNotificationDetailViewProps {
  onClose: () => void
  pushNotificationId?: string
}

export const PushNotificationDetailView: React.FC<PushNotificationDetailViewProps> = ({ onClose, pushNotificationId }) => {
  const dispatch = useAppDispatch();

  const shouldClose = useAppSelector(selectShouldClose)
  const error = useAppSelector(selectError)
  const pushNotification = useAppSelector(selectPushNotification)
  const config = useAppSelector(selectConfig)
  const isLoading = useAppSelector(selectIsLoading)
  const isDeleting = useAppSelector(selectIsDeleting)
  const isGettingCohorts = useAppSelector(selectIsGettingCohorts)
  const isSubmitting = useAppSelector(selectIsSubmitting)

  const [payload, setPayload] = useState<PushNotificationModel>()
  const [sendModalOpen, setSendModalOpen] = useState({ isOpen: false, isTest: true })
  const [pushNotificationIdToDelete, setPushNotificationIdToDelete] = useState<string>()

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    if (pushNotificationId) {
      dispatch(fetchPushNotification(pushNotificationId))
    } else {
      const defaultPushNotification = new PushNotificationModel()
      defaultPushNotification.utmMedium = 'push'
      defaultPushNotification.utmSource = 'push'
      setPayload(defaultPushNotification)
    }
  }, [pushNotificationId, dispatch])

  useEffect(() => {
    pushNotification && setPayload(pushNotification)
  }, [pushNotification])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  if (isLoading || !payload || isGettingCohorts) {
    return <div
      className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    >
      <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
        <div className="w-full no-scrollbar flex-1 flex flex-col">
          <div className={`${isLoading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
            <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
          </div>
        </div>
      </Container>
    </div>
  }

  return <form
    className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    onSubmit={e => {
      e.preventDefault()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      isDismissable={false}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            dispatch(clearError())
          },
          style: 'default'
        }
      ]}
    />
    <AlertDialog
      isOpen={!!pushNotificationIdToDelete}
      message={'Are you sure you want to delete this pushNotification?'}
      title={'Delete PushNotification?'}
      buttons={[
        {
          title: { key: 'common:common_title_delete' },
          onClick: () => {
            pushNotificationIdToDelete && dispatch(deletePushNotification(pushNotificationIdToDelete))
            setPushNotificationIdToDelete(undefined)
          },
          style: 'destructive'
        },
        {
          title: { key: 'common:common_title_cancel' },
          onClick: () => setPushNotificationIdToDelete(undefined),
          style: 'cancel'
        }
      ]}
    />

    <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
      <div className="w-full no-scrollbar flex-1 flex flex-col">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`${isLoading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
          <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
        </div>

        <div className={`mt-4 mb-6 lg:mb-8`}>
          <HeroHeading2>{config === 'create' ? 'Create Push Notification' : 'Update Push Notification'}</HeroHeading2>
        </div>

        <div className="flex flex-col space-y-5">
          <div>
            <Heading4 className="mb-2">Name</Heading4>
            <BodyInput
              type="text"
              placeholder="Name"
              defaultValue={payload?.name}
              onChange={e => {
                if (payload) {
                  payload.name = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Title</Heading4>
            <BodyInput
              type="text"
              placeholder="Title"
              defaultValue={payload?.title}
              onChange={e => {
                if (payload) {
                  payload.title = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Subtitle</Heading4>
            <BodyInput
              type="text"
              placeholder="Subtitle"
              defaultValue={payload?.subtitle}
              onChange={e => {
                if (payload) {
                  payload.subtitle = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Message Content</Heading4>
            <BodyInput
              type="text"
              placeholder="Message Content"
              defaultValue={payload?.message}
              onChange={e => {
                if (payload) {
                  payload.message = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">URL</Heading4>
            <BodyInput
              type="text"
              placeholder="URL"
              defaultValue={payload?.url}
              onChange={e => {
                if (payload) {
                  payload.url = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Image URL</Heading4>
            <BodyInput
              type="text"
              placeholder="Image URL"
              defaultValue={payload?.imageUrl}
              onChange={e => {
                if (payload) {
                  payload.imageUrl = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Send After</Heading4>
            <BodyInput
              type="datetime-local"
              placeholder="Send After"
              defaultValue={payload?.sendAfter && Moment(payload.sendAfter).format('YYYY-MM-DDTHH:mm')}
              onChange={e => {
                const date = Moment(e.target.value)
                if (payload && date.isValid()) {
                  payload.sendAfter = date.toDate()
                  setPayload(new PushNotificationModel(payload))
                } else if (payload) {
                  payload.sendAfter = undefined
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">Delivery Time of Day</Heading4>
            <BodyInput
              type="time"
              placeholder="Delivery Time of Day"
              defaultValue={payload?.deliveryTimeOfDay}
              onChange={e => {
                if (e.target.value) {
                  payload.deliveryTimeOfDay = e.target.value
                  setPayload(new PushNotificationModel(payload))
                } else if (payload) {
                  payload.deliveryTimeOfDay = undefined
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">UTM Campaign</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Campaign"
              defaultValue={payload?.utmCampaign}
              onChange={e => {
                if (payload) {
                  payload.utmCampaign = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">UTM Source</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Source"
              defaultValue={payload?.utmSource}
              onChange={e => {
                if (payload) {
                  payload.utmSource = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div>
            <Heading4 className="mb-2">UTM Medium</Heading4>
            <BodyInput
              type="text"
              placeholder="UTM Medium"
              defaultValue={payload?.utmMedium}
              onChange={e => {
                if (payload) {
                  payload.utmMedium = e.target.value
                  setPayload(new PushNotificationModel(payload))
                }
              }}
            />
          </div>

          <div className={`${isLoading || error ? 'hidden' : ''} mt-4 w-full items-centered space-x-0 space-y-3 lg:flex-row lg:space-x-2 lg:space-y-5`}>
            <div>
              <Button
                isFullWidth={true}
                isLoading={isSubmitting}
                buttonStyle="primary"
                size="lg"
                onClick={() => {
                  if (config === 'create' && payload) {
                    dispatch(createPushNotification({ pushNotification: payload }))
                  } else if (config === 'update' && pushNotificationId && payload) {
                    dispatch(updatePushNotification({ pushNotification: payload, id: pushNotificationId }))
                  }
                }}
              >
                {config === 'create' ? 'Create Push Notification' : 'Update Push Notification'}
              </Button>
            </div>

            <div>
              <Button
                buttonStyle="secondary"
                isFullWidth={true}
                size="lg"
                onClick={() =>{
                  setSendModalOpen({ isOpen: true, isTest: true })
                }}
              >
                Send Test
              </Button>
            </div>

            {config === 'update' && <div className="flex-1">
                <Button
                    isFullWidth={true}
                    buttonStyle="text_destructive"
                    isLoading={isDeleting}
                    size="lg"
                    onClick={() => {
                      setPushNotificationIdToDelete(pushNotificationId)
                    }}
                >
                    Delete
                </Button>
            </div>}
          </div>
        </div>
      </div>
    </Container>

    <PushNotificationDetailSendModal
      isOpen={sendModalOpen.isOpen}
      pushNotification={payload}
      isTest={sendModalOpen.isTest}
      onClose={() => {
        setSendModalOpen({ isOpen: false, isTest: true })
      }}
    />
  </form>
}