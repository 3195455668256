import React from "react";
import {useTranslation} from "next-i18next";
import {LoadingSpinnerIcon, NavigationFilledIcon, RadioEmptyIcon, RadioFilledIcon} from "../icons";
import {Body, LocalizedTextLabel, Paragraph} from "../text";
import {Button} from "../buttons/Button";
import Skeleton from "react-loading-skeleton";
import {LocationEntryItem} from "../../redux/location/locationEntrySlice";

export interface LocationEntryOptionViewProps {
  item: LocationEntryItem
  isLastItem: boolean
  onDeleteClick?: () => void
  onClick?: () => void
}

export const LocationEntryOptionView: React.FC<LocationEntryOptionViewProps> = ({ item, isLastItem, onDeleteClick, onClick }) => {
  const { location, isSaving, isLoading, isDeleting, isSelected } = item
  const { t } = useTranslation()

  const canDelete = location.type !== 'current' && location.type !== 'approximate' && !isLoading && !isSelected

  let title: string | null;
  let subtitle: string | null;

  switch (location.type) {
    case "explore":
      title = t('location:location_title_explore')
      subtitle = location.getDisplayDescription()
      break;
    case "approximate":
      title = t('location:location_title_near_you')
      subtitle = location.getDisplayDescription()
      break;
    case "current":
      title = t('location:location_title_your_location')
      subtitle = location.getDisplayDescription()
      break;
    default:
      title = location.getDisplayDescription()
      subtitle = `${location.address?.locality}, ${location.address?.administrativeArea} ${location.address?.postalCode ? location.address?.postalCode : ''}`.trim()
      break;
  }

  return <div className={`px-5`}>
    <div className={`flex items-center ${!isLastItem ? 'border-b border-fg-gray-surface' : ''}`}>
      <div className="flex py-4 flex-grow items-center cursor-pointer" onClick={() => onClick && onClick()}>
        <div>
          {
            (() => {
              if (isLoading) {
                return <Skeleton className="!rounded-full !h-7 !w-7 !mr-4" />
              } else if (isSaving) {
                return <LoadingSpinnerIcon className="h-6 w-6 mr-4 animate-spin text-fg-primary" />
              } else if (location.type === 'current' || location.type === 'approximate') {
                return <NavigationFilledIcon className={`${isSelected ? 'text-fg-primary' : 'text-fg-black'} h-6 w-6 mr-4`} />
              } else if (isSelected) {
                return <RadioFilledIcon className="text-fg-primary h-6 w-6 mr-4" />
              } else {
                return <RadioEmptyIcon className="text-fg-gray-light h-6 w-6 mr-4" />
              }
            })()
          }
        </div>
        <div className="flex-grow">
          <Body className={`${subtitle ? 'mb-1' : ''}`}>
            {isLoading ? <Skeleton className="!w-24"/>  : <LocalizedTextLabel t={t} text={title}/>}
          </Body>
          <Paragraph className={`${subtitle ? 'block' : 'hidden'} text-fg-gray-dark`}>
            {isLoading ? <Skeleton className="!w-64"/> : <LocalizedTextLabel t={t} text={subtitle}/>}
          </Paragraph>
        </div>
      </div>
      <div className={`${!canDelete ? 'hidden' : ''}`}>
        <Button buttonStyle="secondary" isLoading={isDeleting} onClick={() => onDeleteClick && onDeleteClick()}>
          <span className="text-fg-red">{t('common:common_title_delete')}</span>
        </Button>
      </div>
    </div>
  </div>
}