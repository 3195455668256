import {LoadingSpinnerIcon} from "../icons";
import React, {DetailedHTMLProps, ReactElement} from "react";
import {TextStyles} from "../text";
import Skeleton from "react-loading-skeleton";
import { isMobile } from 'react-device-detect';

export type ButtonStyle = 'primary' | 'secondary' | 'destructive' | 'text_primary' | 'text_secondary' | 'text_destructive'

export interface ButtonProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string
  isLoading?: boolean
  isSkeletonLoading?: boolean
  skeletonClassName?: string
  href?: string
  isDisabled?: boolean
  isFullWidth?: boolean
  leadingicon?: React.ReactNode
  leadingIconSizeClass?: string
  trailingicon?: React.ReactNode
  trailingIconSizeClass?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  buttonStyle?: ButtonStyle
}

const ButtonContent: React.FC<ButtonProps & { styleClass?: string }> = (props) => {
  const {
    children,
    onClick,
    isFullWidth,
    isLoading,
    isDisabled,
    trailingIconSizeClass,
    leadingIconSizeClass,
    styleClass,
    leadingicon: leadingIcon,
    trailingicon: trailingIcon
  } = props

  const size = props.size ?? 'sm'

  let sizeClass: string;
  switch (size) {
    case "sm":
      sizeClass = `px-4 py-2 md:px-4 md:py-2 ${TextStyles.Heading4}`
      break
    case "md":
      sizeClass = `px-4 py-2 md:px-6 md:py-3 ${TextStyles.Heading3}`
      break
    case "lg":
      sizeClass = `px-6 py-3 md:px-8 md:py-4 ${TextStyles.Heading3}`
      break
    case "xl":
      sizeClass = `px-8 py-4 md:px-10 md:py-5 ${TextStyles.Heading2}`
      break
  }

  const leadingIconProps = leadingIcon && (leadingIcon as ReactElement)?.props
  const leadingIconClassName = `${leadingIconSizeClass ? leadingIconSizeClass : 'h-5 w-5'} ${leadingIconProps ? leadingIconProps.className : ''}`

  const trailingIconProps = trailingIcon && (trailingIcon as ReactElement)?.props
  const trailingIconClassName = `${trailingIconSizeClass ? trailingIconSizeClass : 'h-5 w-5'} ${trailingIconProps ? trailingIconProps.className : ''}`

  const buttonProps = { ...props }
  delete buttonProps.isLoading
  delete buttonProps.isSkeletonLoading
  delete buttonProps.skeletonClassName
  delete buttonProps.isDisabled
  delete buttonProps.isFullWidth
  delete buttonProps.size
  delete buttonProps.href
  delete buttonProps.styleClass
  delete buttonProps.trailingIconSizeClass
  delete buttonProps.leadingIconSizeClass
  delete buttonProps.buttonStyle
  delete buttonProps.leadingicon
  delete buttonProps.trailingicon

  return <button
    type="button"
    {...buttonProps}
    className={`inline-flex ${ leadingIcon || trailingIcon ? 'space-x-2' : 'space-x-0'} items-center border border-transparent font-medium rounded-full ${isDisabled ? `${isMobile ? 'bg-opacity-50' : 'opacity-50'} cursor-not-allowed` : ''} ${styleClass} ${sizeClass} ${isFullWidth ? 'w-full' : ''} ${isLoading ? 'relative' : ''} ${props.className ? props.className : ''}`}
    disabled={isDisabled || isLoading}
    onClick={onClick}
  >
    <div className={`${leadingIcon ? '' : 'hidden'} ${isLoading ? 'text-transparent' : ''} flex items-center pointer-events-none`}>
      {leadingIcon && React.cloneElement(leadingIcon as React.ReactElement, { className: leadingIconClassName })}
    </div>
    <div className={`w-full m-auto ${isLoading ? 'text-transparent' : ''}`}>{children}</div>
    <div className={`${trailingIcon ? '' : 'hidden'} ${isLoading ? 'text-transparent' : ''} flex items-center pointer-events-none`}>
      {trailingIcon && React.cloneElement(trailingIcon as React.ReactElement, { className: trailingIconClassName })}
    </div>
  </button>
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    isLoading,
    isSkeletonLoading,
    skeletonClassName,
    isDisabled,
    href
  } = props

  const style = props.buttonStyle ?? 'primary'

  let styleClass: string;
  let textColor: string;
  switch (style) {
    case "primary":
      textColor = 'text-white'
      styleClass = `bg-fg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fg-primary-dark ${!isLoading && !isDisabled ? 'hover:bg-fg-primary-dark' : ''}`
      break
    case "destructive":
      textColor = 'text-white'
      styleClass = `bg-fg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fg-red-dark ${!isLoading && !isDisabled ? 'hover:bg-fg-red-dark' : ''}`
      break
    case "secondary":
      textColor = 'text-fg-black'
      styleClass = `bg-fg-gray-lightest focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fg-gray-lighter ${!isLoading && !isDisabled ? 'hover:bg-fg-gray-lighter' : ''}`
      break
    case "text_primary":
      textColor = 'text-fg-primary'
      styleClass = `bg-transparent ${!isLoading && !isDisabled ? 'hover:bg-fg-gray-lightest' : ''}`
      break
    case "text_secondary":
      textColor = 'text-fg-black'
      styleClass = `bg-transparent ${!isLoading && !isDisabled ? 'hover:bg-fg-gray-lightest' : ''}`
      break
    case "text_destructive":
      textColor = 'text-fg-red'
      styleClass = `bg-transparent ${!isLoading && !isDisabled ? 'hover:bg-fg-gray-lightest' : ''}`
      break
  }

  const buttonProps = { ...props }
  delete buttonProps.isLoading
  delete buttonProps.isSkeletonLoading
  delete buttonProps.skeletonClassName
  delete buttonProps.isDisabled
  delete buttonProps.isFullWidth
  delete buttonProps.size
  delete buttonProps.href
  delete buttonProps.trailingIconSizeClass
  delete buttonProps.leadingIconSizeClass
  delete buttonProps.buttonStyle
  delete buttonProps.leadingicon
  delete buttonProps.trailingicon

  return (
    <div className={`relative ${textColor}`}>
      {isLoading && <LoadingSpinnerIcon className="absolute animate-spin h-5 w-5 inset-y-0 left-1/2 z-10 top-1/2 -mt-2.5 -ml-2.5" />}
      {isSkeletonLoading && <div className="absolute h-full w-full z-10 top-1/2 transform -translate-y-1/2">
          <Skeleton className={`!h-full !w-full !absolute !rounded-full ${skeletonClassName ? skeletonClassName : ''}`}/>
      </div>}

      {href ?

        <a href={href} target={`${href ? '_blank' : undefined}`} rel={`${href ? 'noreferrer' : undefined}`}>
          <ButtonContent {...props} styleClass={styleClass} />
        </a>

        :

        <ButtonContent {...props} styleClass={styleClass} />
      }
    </div>
  )
}

Button.defaultProps = {
  isFullWidth: false,
  isDisabled: false,
  isLoading: false,
  size: "sm",
}