import {RootEpic, RootState} from "../../app/store";
import {filter} from "rxjs/operators";
import {
  CheckoutData, createSalesOrder, createSalesOrderFailed, createSalesOrderSucceeded,
  fetchCheckoutData,
  fetchCheckoutDataFailed,
  fetchCheckoutDataSucceeded,
  optionItemSelected,
  removePromoCode, setCustomer
} from "./checkoutSlice";
import {asyncScheduler, catchError, map, mergeMap, tap, Observable, of, scheduled, zip} from "rxjs";
import {StoreService} from "../../services/storeService";
import {StateObservable} from "redux-observable";
import {LocationService} from "../../services/locationService";
import {LocationModel} from "../../models/location";
import {UserService} from "../../services/userService";
import {PaymentService} from "../../services/paymentService";
import {logErrorRx} from "../../utils/logError";
import {savePrimaryLocationSucceeded} from "../location/locationEntrySlice";
import {setContactInfoSucceeded} from "../user/contactInfoEntrySlice";
import {addPaymentSourceSucceeded} from "../payment/paymentEntrySlice";
import {setDefaultPaymentMethodSucceeded} from "../payment/paymentSelectorSlice";
import {updateItemInCartSucceeded} from "../product/productSlice";
import {savePromoCodeSucceeded} from "../promoCodeEntry/promoCodeEntrySlice";
import {AuthService} from "../../services/authService";
import {configureLoggedInUser} from "../auth/authEpics";
import {authenticateSessionSucceeded} from "../auth/authSlice";
import {fetchCartSucceeded} from "../cart/cartSlice";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export const updateCheckoutDataEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      return savePrimaryLocationSucceeded.match(action)
        || setContactInfoSucceeded.match(action)
        || addPaymentSourceSucceeded.match(action)
        || setDefaultPaymentMethodSucceeded.match(action)
        || savePromoCodeSucceeded.match(action)
        || updateItemInCartSucceeded.match(action)
    }),
    mergeMap(() => {
      const storeId = state$.value.checkout.storeId

      return scheduled(storeId ? [fetchCheckoutData({ storeId, reload: true })] : [], asyncScheduler)
    })
  )
}

export const createSalesOrderEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(createSalesOrder.match),
    mergeMap(() => {
      const order_id = state$.value.checkout.orderId!
      const source_id = state$.value.checkout.defaultCard?.id!

      return PaymentService.createSalesOrder({ order_id, source_id })
        .pipe(
          mergeMap(order => {
            const store_id = state$.value.checkout.storeId!

            PaymentService.setPromoCode(undefined)
            return StoreService.clearCart(store_id)
              .pipe(
                mergeMap(cart => scheduled([fetchCartSucceeded(cart)], asyncScheduler)),
                map(() => order)
              )
          }),
          mergeMap(order => {
            EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Purchase Completed', order }))
            return scheduled([
              createSalesOrderSucceeded(order),
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: createSalesOrderFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const fetchCheckoutDataEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchCheckoutData.match),
    mergeMap(action => {
      const loginStatus = state$.value.auth.loginStatus
      let anonSignupIfNeeded: Observable<void> = of(undefined)
      if (loginStatus === 'not_logged_in') {
        anonSignupIfNeeded = AuthService.signUpAnonymously(state$.value)
          .pipe(
            mergeMap(authResponse => {
              return configureLoggedInUser(authResponse)
                .pipe(map(result => ({ user: result.user, location: result.location, authResponse })))
            }),
            mergeMap(({ user, authResponse }) => scheduled(
              [
                authenticateSessionSucceeded({ auth: authResponse, user })
              ],
              asyncScheduler
            )),
            map(() => {}),
            catchError(error => logErrorRx(error)),
            catchError(() => of(undefined)),
          )
      }

      return anonSignupIfNeeded.pipe(map(() => action))
    }),
    mergeMap(action => {
      const purchaseOrder = state$.value.checkout.purchaseOrder

      const storeId = action.payload.storeId
      const shippingOptionId = state$.value.checkout.selectedShippingOptionId
      const orderId = state$.value.checkout.orderId
      const customer = state$.value.checkout.customer
      const promoCode = PaymentService.getPromoCode()
      const distributionMethod = purchaseOrder?.shippingOptions.filter(o => o.id === shippingOptionId)[0]?.distributionMethod
        ?? StoreService.getDistributionMethod()
      const user = state$.value.user.user
      const isAdmin = user?.roles?.includes('admin')
      const userObservable = user ? of(user) : UserService.fetchUser()
      const locationObservable = fetchLocation(state$)

      const localCart = state$.value.cart.cartMap[storeId]
      const cartObservable = StoreService.fetchShoppingCart(storeId, localCart)
        .pipe(
          map(cart => {
            return { cart, didUpdate: false }
          }),
          tap({
            next: ({ cart }) => {
              if (!action.payload.reload && cart) {
                EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Checkout Initiated', cart }))
              }
            }
          })
        )
      const pickupLocationObservable = StoreService.fetchPrimaryPickupLocation(storeId)

      const checkoutDataObservable: Observable<CheckoutData> = zip(
        [
          locationObservable,
          userObservable,
          cartObservable,
          pickupLocationObservable
        ]
      ).pipe(mergeMap(([location, user, cart, pickupLocation]) => {
        return PaymentService.createPurchaseOrder({
          user,
          deliveryLocation: location,
          pickupLocation,
          customer: isAdmin ? customer : undefined,
          distributionMethod,
          shippingOptionId,
          lineItems: cart.cart?.items ?? [],
          promoCode,
          storeId,
          orderId
        }).pipe(map(purchaseOrder => ({
          purchaseOrder,
          user,
          defaultCard: purchaseOrder.defaultCard,
          cart: cart.cart ?? undefined,
          didUpdateItems: cart.didUpdate
        })))
      }))

      return checkoutDataObservable
        .pipe(
          mergeMap(data => {
            return scheduled([fetchCheckoutDataSucceeded(data)], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchCheckoutDataFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const removePromoCodeEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(removePromoCode.match),
    mergeMap(() => {
      PaymentService.setPromoCode(undefined)
      const storeId = state$.value.checkout.storeId
      return scheduled(storeId ? [fetchCheckoutData({ storeId })] : [], asyncScheduler)
    })
  )
}

export const optionItemSelectedEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(optionItemSelected.match),
    mergeMap(action => {
      const storeId = state$.value.checkout.storeId
      const isUpdating = state$.value.checkout.isUpdating

      return scheduled(storeId && isUpdating ? [fetchCheckoutData({ storeId })] : [], asyncScheduler)
    }),
  )
}

const fetchLocation = (state$: StateObservable<RootState>): Observable<LocationModel | null> => {
  const deliveryLocationObservable = LocationService.fetchPrimaryDeliveryLocation()
    .pipe(map(location => {
      const isValidLocation = location?.type !== "approximate" &&
        location?.type !== "explore" &&
        location?.type !== "pickup"

      return isValidLocation ? location : null
    }))

  return deliveryLocationObservable.pipe(mergeMap(location => {
    if (!location) {
      const primaryLocation = state$.value.user.primaryLocation

      if (primaryLocation && primaryLocation.type === 'current') {
        return of(primaryLocation)
      } else {
        const hasPermissionObservable = LocationService.fetchLocationPermissions()
        return hasPermissionObservable
          .pipe(mergeMap(hasPermission => hasPermission ? LocationService.fetchCurrentLocation() : of(null)))
      }
    } else {
      return of(location)
    }
  }))
}