import {RootEpic} from "../../app/store";
import {
  fetchAffiliateData,
  fetchAffiliateDataFailed,
  fetchAffiliateDataSucceeded,
  fetchAffiliateInfo,
  fetchAffiliateInfoFailed,
  fetchAffiliateInfoSucceeded,
  fetchAffiliatePromoCodes,
  fetchAffiliatePromoCodesFailed,
  fetchAffiliatePromoCodesSucceeded,
  onboardAffiliate,
  onboardAffiliateFailed,
  onboardAffiliateSucceeded, reset,
  setDateRange
} from "./affiliateSlice";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled, throwError, zip} from "rxjs";
import {AffiliateService} from "../../services/affiliateService";
import {logErrorRx} from "../../utils/logError";
import {isValidEmail} from "../../utils/isValidEmail";
import {AffiliateValidationError} from "../../errors/affiliateValidationError";
import {OnboardAffiliatePayload} from "../../models/onboardAffiliatePayload";
import {CountryCode, isValidPhoneNumber} from "libphonenumber-js";

export const fetchAffiliateInfoEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchAffiliateInfo.match),
    mergeMap(action => {
      return AffiliateService.fetchAffiliateInfo()
        .pipe(
          map(fetchAffiliateInfoSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchAffiliateInfoFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const fetchAffiliateDataEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchAffiliateData.match),
    mergeMap(action => {
      const startDate = new Date(state$.value.affiliate.startDate)
      const endDate = new Date(state$.value.affiliate.endDate)
      const commissionsSingle = AffiliateService.fetchCommissions(startDate, endDate)
      const cashPayoutsSingle = AffiliateService.fetchPayouts(startDate, endDate, 'cash')
      const storeCreditPayoutsSingle = AffiliateService.fetchPayouts(startDate, endDate, 'store_credit')

      return zip(
        [
          commissionsSingle,
          cashPayoutsSingle,
          storeCreditPayoutsSingle
        ]
      ).pipe(
        map(([commissionsResponse, cashPayoutsResponse, storeCreditPayoutsResponse]) => {
          return {
            commissions: commissionsResponse.commissions,
            cashPayouts: cashPayoutsResponse.payouts,
            storeCreditPayouts: storeCreditPayoutsResponse.payouts
          }
        }),
        map(fetchAffiliateDataSucceeded),
        catchError(error => logErrorRx(error)),
        catchError(error => of({
          type: fetchAffiliateDataFailed.type,
          payload: error,
          error: true
        }))
      )
    })
  )
}

export const setDateRangeEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(setDateRange.match),
    mergeMap(action => {
      const startDate = action.payload.startDate
      const endDate = action.payload.endDate
      const commissionsSingle = AffiliateService.fetchCommissions(startDate, endDate)
      const cashPayoutsSingle = AffiliateService.fetchPayouts(startDate, endDate, 'cash')
      const storeCreditPayoutsSingle = AffiliateService.fetchPayouts(startDate, endDate, 'store_credit')

      return zip(
        [
          commissionsSingle,
          cashPayoutsSingle,
          storeCreditPayoutsSingle
        ]
      ).pipe(
        map(([commissionsResponse, cashPayoutsResponse, storeCreditPayoutsResponse]) => {
          return {
            commissions: commissionsResponse.commissions,
            cashPayouts: cashPayoutsResponse.payouts,
            storeCreditPayouts: storeCreditPayoutsResponse.payouts
          }
        }),
        map(fetchAffiliateDataSucceeded),
        catchError(error => logErrorRx(error)),
        catchError(error => of({
          type: fetchAffiliateDataFailed.type,
          payload: error,
          error: true
        }))
      )
    })
  )
}

export const fetchAffiliatePromoCodesEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchAffiliatePromoCodes.match),
    mergeMap(action => {
      return AffiliateService.fetchInternalPromoCodes(action.payload)
        .pipe(
          map(fetchAffiliatePromoCodesSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchAffiliatePromoCodesFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const onboardAffiliateEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(onboardAffiliate.match),
    mergeMap(action => {
      return validate(action.payload.payload, action.payload.countryCode)
        .pipe(
          mergeMap(payload => {
            return AffiliateService.onboardAffiliate(payload)
          }),
          mergeMap(() => {
            return scheduled([
              onboardAffiliateSucceeded(),
              reset(),
              fetchAffiliateData(),
              fetchAffiliateInfo(),
              fetchAffiliatePromoCodes(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: onboardAffiliateFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

const validate = (payload: OnboardAffiliatePayload, countryCode?: string) => {
  if (!payload.payout_email && payload.payout_method === 'paypal') {
    return throwError(() => new AffiliateValidationError("EMAIL_IS_EMPTY"))
  } else if (!payload.payout_phone_number && payload.payout_method === 'venmo') {
    return throwError(() => new AffiliateValidationError("PHONE_IS_EMPTY"))
  } else if (payload.payout_email && !isValidEmail(payload.payout_email) && payload.payout_method === 'paypal') {
    return throwError(() => new AffiliateValidationError("INVALID_EMAIL"))
  } else if (payload.payout_phone_number && !isValidPhoneNumber(payload.payout_phone_number, countryCode as CountryCode) && payload.payout_method === 'venmo') {
    return throwError(() => new AffiliateValidationError("INVALID_PHONE_NUMBER"))
  } else if (!payload.code) {
    return throwError(() => new AffiliateValidationError("CODE_IS_EMPTY"))
  } else if (payload.code.length < 3) {
    return throwError(() => new AffiliateValidationError("INVALID_CODE_LENGTH"))
  } else if (!payload.first_name) {
    return throwError(() => new AffiliateValidationError("FIRST_NAME_IS_EMPTY"))
  } else if (!payload.last_name) {
    return throwError(() => new AffiliateValidationError("LAST_NAME_IS_EMPTY"))
  } else {
    return of(payload)
  }
}