import {ImageModel} from "../models/image";

export const ImageUtils = {
  createUrlFromImage(image?: ImageModel, size?: { widthPx: number, heightPx: number }): string | undefined {
    const publicId = image?.publicId
    if (publicId && size) {
      return process.env.NEXT_PUBLIC_CLOUDINARY_BASE_URL
        + '/'
        + process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME
        + `/image/upload/h_${size.heightPx},w_${size.widthPx},q_auto/`
        + publicId
    } else if (publicId) {
      return process.env.NEXT_PUBLIC_CLOUDINARY_BASE_URL
        + '/'
        + process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME
        + `/image/upload/q_auto/`
        + publicId
    } else {
      return image?.url
    }
  }
}