import {SalesOrderJson, SalesOrderModel} from "./salesOrder";

export interface SalesOrderResponseJson {
  total_results?: number
  orders?: SalesOrderJson[]
}

export class SalesOrderResponseModel {
  totalResults: number = 0
  orders: SalesOrderModel[] = []

  static fromJson(json?: SalesOrderResponseJson): SalesOrderResponseModel | undefined {
    if (!json) return

    const model = new SalesOrderResponseModel()
    model.totalResults = json.total_results ?? 0
    model.orders = json.orders?.map(i => SalesOrderModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}