import {SalesOrderModel} from "../../models/salesOrder";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SalesOrderResponseModel} from "../../models/salesOrderResponse";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {ObjectId} from "bson";
import {SalesOrderLineItemModel} from "../../models/salesOrderLineItem";

export interface OrderListState {
  readonly isLoading: boolean
  readonly error?: BaseError<any>
  readonly orders: SalesOrderModel[]
  readonly totalResults: number
}

export const orderListSlice = createSlice({
  name: 'order_list',
  initialState: {
    isLoading: false,
    orders: [],
    totalResults: 0
  } as OrderListState,
  reducers: {
    reset(state) {
      state.orders = []
      state.error = undefined
      state.totalResults = 0
    },
    fetchOrders(state, action: PayloadAction<number>) {
      if (!state.orders.length) {
        state.orders = [0, 1, 2, 3, 4, 5].map(i => {
          const order = new SalesOrderModel()
          order.id = new ObjectId().toHexString()
          order.lineItems = [0, 1, 2].map(i => {
            const lineItem = new SalesOrderLineItemModel()
            lineItem.id = new ObjectId().toHexString()

            return lineItem
          })

          return order
        })

        state.isLoading = true
      }
    },
    fetchOrdersSucceeded(state, action: PayloadAction<SalesOrderResponseModel>) {
      state.totalResults = action.payload.totalResults

      if (state.isLoading) {
        state.orders = action.payload.orders
      } else {
        state.orders = state.orders.concat(action.payload.orders)
      }

      state.isLoading = false
    },
    fetchOrdersFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('ORDER_FETCH_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'store:store_error_message_error_getting_orders' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const orderListReducer = orderListSlice.reducer

export const {
  reset,
  fetchOrders,
  fetchOrdersFailed,
  fetchOrdersSucceeded
} = orderListSlice.actions

export const selectError = (state: RootState) => state.orderList.error;
export const selectIsLoading = (state: RootState) => state.orderList.isLoading;
export const selectOrders = (state: RootState) => state.orderList.orders;
export const selectTotalResults = (state: RootState) => state.orderList.totalResults;