import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {useRouter} from "next/router";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Modal} from "../modals/Modal";
import {reset} from "../../redux/pushNotification/pushNotificationDetailSlice";
import {
  fetchPushNotifications,
  reset as resetPushNotificationList,
  selectCurrentPage,
  setCurrentPage
} from "../../redux/pushNotification/pushNotificationListSlice";
import {PushNotificationDetailView} from "./PushNotificationDetailView";

export const PushNotificationDetailModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();
  const router = useRouter()
  const pushNotificationId = router.query.push_notification_id as string | undefined
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector(selectCurrentPage)

  return <Modal
    className="md:max-w-screen-7/8"
    isOpen={isOpen('push_notification_detail')}
    onClose={() => {
      dispatch(reset())
      onClose()

      dispatch(resetPushNotificationList())
      if (pageNumber === 1) {
        dispatch(fetchPushNotifications(pageNumber))
      } else {
        dispatch(setCurrentPage(1))
      }
    }}
  >
    <PushNotificationDetailView onClose={onClose} pushNotificationId={pushNotificationId} />
  </Modal>
}