import {EntityModel} from "./entityModel";
import {ShoppingCartItemModel} from "./shoppingCartItem";
import {map, mergeMap, Observable, of, zip} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectId} from "bson";
import {CurrencyCode} from "./currencyCode";
import {ObjectSchema} from "mongodb-realm";
import moment from "moment";

export class ShoppingCartModel implements EntityModel<ShoppingCartModel> {
  public static schema: ObjectSchema = {
    name: 'ShoppingCartEntity',
    properties: {
      _id: 'objectId',
      _partition: 'string',
      createdAt: 'date?',
      currencyCode: 'string?',
      items: 'ShoppingCartItemEntity[]',
      shoppingCartId: 'string?',
      storeId: 'string?',
      totalDiscount: 'double',
      totalPrice: 'double',
      updatedAt: 'date?',
    },
    primaryKey: '_id',
  }

  schema = ShoppingCartModel.schema

  _id: ObjectId = new ObjectId()
  _partition: string = ''
  createdAt?: Date
  currencyCode?: CurrencyCode
  items: ShoppingCartItemModel[] = []
  shoppingCartId?: string
  storeId?: string
  totalDiscount: number = 0
  totalPrice: number = 0
  updatedAt?: Date

  constructor(entity?: ShoppingCartModel | null) {
    if (!entity) return

    this._id = entity._id
    this._partition = entity._partition
    this.createdAt = entity.createdAt ? moment(entity.createdAt).toDate() : entity.createdAt
    this.currencyCode = entity.currencyCode
    this.items = entity.items.map(i => new ShoppingCartItemModel(i))
    this.shoppingCartId = entity.shoppingCartId
    this.storeId = entity.storeId
    this.totalDiscount = entity.totalDiscount
    this.totalPrice = entity.totalPrice
    this.updatedAt = entity.updatedAt ? moment(entity.updatedAt).toDate() : entity.updatedAt

    return this
  }

  toEntityObservable(realmService: BaseRealmService): Observable<ShoppingCartModel> {
    const itemsObservable: Observable<ShoppingCartItemModel[]> = this.items.length === 0 ? of([]) : zip(this.items.map(i => i.toEntityObservable(realmService)))

    return itemsObservable
      .pipe(
        mergeMap(items => {
          this.items = items

          return realmService.query<ShoppingCartModel>(ShoppingCartModel.schema.name)
            .where({ storeId: this.storeId })
            .fetchOne()
            .pipe(
              map(existingEntity => {
                if (existingEntity) {
                  this._id = existingEntity._id
                }

                return this
              })
            )
        })
      )
  }
}