import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  fetchEmails,
  reset as resetEmails,
  selectCurrentPage as selectCurrentEmailPage,
  selectEmails,
  selectError as selectEmailError,
  selectIsLoading as selectEmailIsLoading,
  selectIsLoadingMore as selectEmailIsLoadingMore,
  setCurrentPage as setCurrentEmailPage
} from "../../redux/email/emailListSlice";
import {
  fetchPrompts,
  reset as resetPrompts,
  selectCurrentPage as selectCurrentPromptPage,
  selectPrompts,
  selectError as selectPromptError,
  selectIsLoading as selectPromptIsLoading,
  setCurrentPage as setCurrentPromptPage
} from "../../redux/prompt/promptListSlice";
import {
  fetchPushNotifications,
  reset as resetPushNotifications,
  selectCurrentPage as selectCurrentPushNotificationPage,
  selectPushNotifications,
  selectError as selectPushNotificationError,
  selectIsLoading as selectPushNotificationIsLoading,
  setCurrentPage as setCurrentPushNotificationPage
} from "../../redux/pushNotification/pushNotificationListSlice";
import {ColumnProps} from "rc-table/lib/sugar/Column";
import {EmailModel} from "../../models/email";
import {TableHeader} from "../table/TableHeader";
import {Caption, Heading4, Paragraph} from "../text";
import {TableCell} from "../table/TableCell";
import Moment from "moment";
import Table from "rc-table";
import {Button} from "../buttons/Button";
import {useTranslation} from "next-i18next";
import {ApiError} from "../../errors/apiError";
import {RealmServiceError} from "../../errors/realmServiceError";
import {StoreEmptyStateView} from "../storeFeed/StoreEmptyStateView";
import {WarningColorIcon} from "../icons";
import {PromptModel} from "../../models/prompt";
import {BaseError} from "../../errors/baseError";
import {PushNotificationModel} from "../../models/pushNotification";

export type CampaignMessageType = EmailModel | PromptModel | PushNotificationModel

interface CampaignMessagesTableProps {
  messages: CampaignMessageType[]
  onLoadMoreClicked: () => void
  onMessageSelected: (message: CampaignMessageType) => void
}

export const CampaignDetailMessageSelectorTableView: React.FC<CampaignMessagesTableProps> = ({ messages, onMessageSelected, onLoadMoreClicked }) => {
  const isLoadingMore = useAppSelector(selectEmailIsLoadingMore)

  const columns: ColumnProps<CampaignMessageType>[] = [
    {
      title: <TableHeader isFirst={true}>
        <Heading4 className="text-fg-gray-dark">Title</Heading4>
      </TableHeader>,
      dataIndex: 'name',
      render: (text, record) => (
        <TableCell>
          <div>
            <Paragraph>{`${record.name}`}</Paragraph>
            <Caption className="text-fg-gray-dark">
              Title: {`${record instanceof EmailModel
              ? record.formattedSubject
              : record instanceof PushNotificationModel
                ? record.title
                : record.title}`}
            </Caption>
          </div>
        </TableCell>
      )
    },
    {
      title: <TableHeader>
        <Heading4 className="text-fg-gray-dark">Create On</Heading4>
      </TableHeader>,
      dataIndex: 'created_at',
      render: (text, record) => (
        <TableCell>
          <Paragraph>{Moment(record.createdAt).format('MMM D, YYYY')}</Paragraph>
        </TableCell>
      )
    },
    {
      title: <TableHeader isLast={true}>

      </TableHeader>,
      dataIndex: 'action',
      render: (text, record) => (
        <TableCell>
          <Button
            onClick={() => {
              onMessageSelected(record)
            }}
          >
            Select
          </Button>
        </TableCell>
      )
    }
  ]

  return <Table
    className="rounded-lg border border-fg-gray-surface fg-table"
    scroll={{x: true}}
    data={messages}
    columns={columns}
    emptyText={() => {
      return <div className="w-full h-16 flex items-center justify-center">
        <Paragraph>{'No Messages'}</Paragraph>
      </div>
    }}
    footer={() => {
      return <div className="w-full flex items-center justify-center py-8 border-t border-fg-gray-surface">
        <Button
          buttonStyle="secondary"
          isLoading={isLoadingMore}
          onClick={() => {
            onLoadMoreClicked()
          }}
        >
          Load More
        </Button>
      </div>
    }}
  />
}

export interface CampaignDetailMessageSelectorViewProps {
  onMessageSelected: (message: CampaignMessageType) => void
  type: 'email' | 'prompt' | 'push'
}

export const CampaignDetailMessageSelectorView: React.FC<CampaignDetailMessageSelectorViewProps> = ({ onMessageSelected, type }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [error, setError] = useState<BaseError<any>>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(1)

  useEffect(() => {
    dispatch(resetPrompts())
    dispatch(resetEmails())
    dispatch(resetPushNotifications())
  }, [dispatch])

  const emailPageNumber = useAppSelector(selectCurrentEmailPage)
  const emails = useAppSelector(selectEmails)
  const emailError = useAppSelector(selectEmailError)
  const emailIsLoading = useAppSelector(selectEmailIsLoading)

  useEffect(() => {
    setIsLoading(emailIsLoading)
  }, [emailIsLoading])

  useEffect(() => {
    setError(emailError)
  }, [emailError])

  useEffect(() => {
    setPageNumber(emailPageNumber)
  }, [emailPageNumber])

  const promptPageNumber = useAppSelector(selectCurrentPromptPage)
  const prompts = useAppSelector(selectPrompts)
  const promptError = useAppSelector(selectPromptError)
  const promptIsLoading = useAppSelector(selectPromptIsLoading)

  useEffect(() => {
    setIsLoading(promptIsLoading)
  }, [promptIsLoading])

  useEffect(() => {
    setError(promptError)
  }, [promptError])

  useEffect(() => {
    setPageNumber(promptPageNumber)
  }, [promptPageNumber])

  const pushNotificationPageNumber = useAppSelector(selectCurrentPushNotificationPage)
  const pushNotifications = useAppSelector(selectPushNotifications)
  const pushNotificationError = useAppSelector(selectPushNotificationError)
  const pushNotificationIsLoading = useAppSelector(selectPushNotificationIsLoading)

  useEffect(() => {
    setIsLoading(pushNotificationIsLoading)
  }, [pushNotificationIsLoading])

  useEffect(() => {
    setError(pushNotificationError)
  }, [pushNotificationError])

  useEffect(() => {
    setPageNumber(pushNotificationPageNumber)
  }, [pushNotificationPageNumber])

  useEffect(() => {
    if (type === 'email') {
      dispatch(fetchEmails(pageNumber))
    } else if (type === 'prompt') {
      dispatch(fetchPrompts(pageNumber))
    } else if (type === 'push') {
      dispatch(fetchPushNotifications(pageNumber))
    }
  }, [dispatch, pageNumber])

  const emptyStateButtonTitle = error instanceof ApiError && error.code === 'UNAUTHORIZED' || error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN'
    ? t('store:store_empty_state_button_go_back')
    : t('store:store_empty_state_button_try_again')

  return <div>
    <div className={`${error ? '' : 'hidden'} flex-1 flex flex-col justify-center items-center`}>
      <StoreEmptyStateView
        onClick={() => {
          if (error instanceof ApiError && error.code === 'UNAUTHORIZED' || error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {

          } else {
            if (type === 'email') {
              dispatch(resetEmails())
              if (pageNumber === 1) {
                dispatch(fetchEmails(pageNumber))
              } else {
                dispatch(setCurrentEmailPage(1))
              }
            } else if (type === 'prompt') {
              dispatch(resetPrompts())
              if (pageNumber === 1) {
                dispatch(fetchPrompts(pageNumber))
              } else {
                dispatch(setCurrentPromptPage(1))
              }
            } else if (type === 'push') {
              dispatch(resetPushNotifications())
              if (pageNumber === 1) {
                dispatch(fetchPushNotifications(pageNumber))
              } else {
                dispatch(setCurrentPushNotificationPage(1))
              }
            }
          }
        }}
        buttonTitle={emptyStateButtonTitle}
        error={error}
        icon={<WarningColorIcon />}
        isLoading={isLoading}
      />
    </div>

    <div className={`mb-8 ${error ? 'hidden' : ''}`}>
      <CampaignDetailMessageSelectorTableView
        onMessageSelected={onMessageSelected}
        messages={type === 'email' ? emails : type === 'push' ? pushNotifications : prompts}
        onLoadMoreClicked={() => {
          if (type === 'email') {
            dispatch(setCurrentEmailPage(pageNumber + 1))
          } else if (type === 'prompt') {
            dispatch(setCurrentPromptPage(pageNumber + 1))
          } else if (type === 'push') {
            dispatch(setCurrentPushNotificationPage(pageNumber + 1))
          }
        }}
      />
    </div>
  </div>
}