import {NutrientType} from "./nutrient";
import {EntityModel} from "./entityModel";
import {Observable, of} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectSchema} from "mongodb-realm";

export interface MealItemJson {
  _id?: string
  brand_name?: string
  broad_subcategory?: string
  calcium?: number
  calories?: number
  carbohydrate?: number
  cholesterol?: number
  default_measurement_description?: string
  default_metric_serving_amount?: number
  default_serving_id?: string
  fat?: number
  fiber?: number
  food_id?: string
  food_name?:string
  iron?: number
  isFitGenieUnitMetricServing?: boolean
  measurement_description?: string
  metric_serving_amount?: number
  serving_description?: string
  serving_id?: string
  monounsaturated_fat?: number
  number_of_units?: number
  polyunsaturated_fat?: number
  potassium?: number
  protein?: number
  quantity?: number
  saturated_fat?: number
  sodium?: number
  sugar?: number
  trans_fat?: number
  vitamin_c?: number
  vitamin_a?: number
}

export class MealItemModel implements EntityModel<MealItemModel> {
  public static schema: ObjectSchema = {
    name: 'MealItemEntity',
    embedded: true,
    properties: {
      brandName: 'string?',
      broadSubcategory: 'string?',
      calcium: 'double?',
      calories: 'double?',
      carbohydrate: 'double?',
      cholesterol: 'double?',
      fat: 'double?',
      fiber: 'double?',
      foodId: 'string?',
      foodName: 'string?',
      iron: 'double?',
      isFitGenieUnitMetricServing: 'bool',
      mealItemId: 'string?',
      measurementDescription: 'string?',
      metricServingAmount: 'double?',
      monounsaturatedFat: 'double?',
      numberOfUnits: 'double',
      polyunsaturatedFat: 'double?',
      potassium: 'double?',
      protein: 'double?',
      quantity: 'double',
      saturatedFat: 'double?',
      servingDescription: 'string?',
      servingId: 'string?',
      sodium: 'double?',
      sugar: 'double?',
      transFat: 'double?',
      vitaminA: 'double?',
      vitaminC: 'double?',
    },
  }

  public schema = MealItemModel.schema

  brandName?: string
  broadSubcategory?: string
  calcium?: number
  calories?: number
  carbohydrate?: number
  cholesterol?: number
  fat?: number
  fiber?: number
  foodId?: string
  foodName?: string
  iron?: number
  isFitGenieUnitMetricServing: boolean = false
  mealItemId?: string
  measurementDescription?: string
  metricServingAmount?: number
  monounsaturatedFat?: number
  numberOfUnits: number = 0
  polyunsaturatedFat?: number
  potassium?: number
  protein?: number
  quantity: number = 0
  saturatedFat?: number
  servingDescription?: string
  servingId?: string
  sodium?: number
  sugar?: number
  transFat?: number
  vitaminA?: number
  vitaminC?: number

  constructor(entity?: MealItemModel | null) {
    if (!entity) return

    this.brandName = entity.brandName
    this.broadSubcategory = entity.broadSubcategory
    this.calcium = entity.calcium
    this.calories = entity.calories
    this.carbohydrate = entity.carbohydrate
    this.cholesterol = entity.cholesterol
    this.fat = entity.fat
    this.fiber = entity.fiber
    this.foodId = entity.foodId
    this.foodName = entity.foodName
    this.iron = entity.iron
    this.isFitGenieUnitMetricServing = entity.isFitGenieUnitMetricServing
    this.mealItemId = entity.mealItemId
    this.measurementDescription = entity.measurementDescription
    this.metricServingAmount = entity.metricServingAmount
    this.monounsaturatedFat = entity.monounsaturatedFat
    this.numberOfUnits = entity.numberOfUnits
    this.polyunsaturatedFat = entity.polyunsaturatedFat
    this.potassium = entity.potassium
    this.protein = entity.protein
    this.quantity = entity.quantity
    this.saturatedFat = entity.saturatedFat
    this.servingDescription = entity.servingDescription
    this.servingId = entity.servingId
    this.sodium = entity.sodium
    this.sugar = entity.sugar
    this.transFat = entity.transFat
    this.vitaminA = entity.vitaminA
    this.vitaminC = entity.vitaminC

    return this
  }

  static fromJson(json?: MealItemJson): MealItemModel | undefined {
    if (!json) return undefined

    const model = new MealItemModel()

    model.brandName = json.brand_name
    model.broadSubcategory = json.broad_subcategory
    model.calcium = json.calcium
    model.calories = json.calories
    model.carbohydrate = json.carbohydrate
    model.cholesterol = json.cholesterol
    model.fat = json.fat
    model.fiber = json.fiber
    model.foodId = json.food_id
    model.foodName = json.food_name
    model.iron = json.iron
    model.isFitGenieUnitMetricServing = json.isFitGenieUnitMetricServing ?? false
    model.mealItemId = json._id
    model.measurementDescription = json.measurement_description
    model.metricServingAmount = json.metric_serving_amount
    model.monounsaturatedFat = json.monounsaturated_fat
    model.numberOfUnits = json.number_of_units ?? 0
    model.polyunsaturatedFat = json.polyunsaturated_fat
    model.potassium = json.potassium
    model.protein = json.protein
    model.quantity = json.quantity ?? 0
    model.saturatedFat = json.saturated_fat
    model.servingDescription = json.serving_description
    model.servingId = json.serving_id
    model.sodium = json.sodium
    model.sugar = json.sugar
    model.transFat = json.trans_fat
    model.vitaminA = json.vitamin_a
    model.vitaminC = json.vitamin_c

    return model
  }

  toJson(): MealItemJson {
    return {
      _id: this.mealItemId,
      brand_name: this.brandName,
      broad_subcategory: this.broadSubcategory,
      calcium: this.calcium,
      calories: this.calories,
      carbohydrate: this.carbohydrate,
      cholesterol: this.cholesterol,
      fat: this.fat,
      fiber: this.fiber,
      food_id: this.foodId,
      food_name: this.foodName,
      iron: this.iron,
      isFitGenieUnitMetricServing: this.isFitGenieUnitMetricServing,
      measurement_description: this.measurementDescription,
      metric_serving_amount: this.metricServingAmount,
      serving_description: this.servingDescription,
      serving_id: this.servingId,
      monounsaturated_fat: this.monounsaturatedFat,
      number_of_units: this.numberOfUnits,
      polyunsaturated_fat: this.polyunsaturatedFat,
      potassium: this.potassium,
      protein: this.protein,
      quantity: this.quantity,
      saturated_fat: this.saturatedFat,
      sodium: this.sodium,
      sugar: this.sugar,
      trans_fat: this.transFat,
      vitamin_c: this.vitaminC,
      vitamin_a: this.vitaminA
    }
  }

  toEntityObservable(realmService: BaseRealmService): Observable<MealItemModel> {
    return of(this)
  }

  getNutrients(): Map<NutrientType, number> {
    const map = new Map<NutrientType, number>()

    this.calcium && map.set("CALCIUM", this.calcium)
    this.calories && map.set("CALORIES", this.calories)
    this.carbohydrate && map.set("CARBOHYDRATE", this.carbohydrate)
    this.cholesterol && map.set("CHOLESTEROL", this.cholesterol)
    this.fat && map.set("FAT", this.fat)
    this.fiber && map.set("FIBER", this.fiber)
    this.iron && map.set("IRON", this.iron)
    this.monounsaturatedFat && map.set("MONOUNSATURATED_FAT", this.monounsaturatedFat)
    this.polyunsaturatedFat && map.set("POLYUNSATURATED_FAT", this.polyunsaturatedFat)
    this.potassium && map.set("POTASSIUM", this.potassium)
    this.protein && map.set("PROTEIN", this.protein)
    this.saturatedFat && map.set("SATURATED_FAT", this.saturatedFat)
    this.sodium && map.set("SODIUM", this.sodium)
    this.sugar && map.set("SUGAR", this.sugar)
    this.transFat && map.set("TRANS_FAT", this.transFat)
    this.vitaminA && map.set("VITAMIN_A", this.vitaminA)
    this.vitaminC && map.set("VITAMIN_C", this.vitaminC)

    return map
  }
}