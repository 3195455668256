import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  selectError,
  selectOrder,
  reset,
  fetchOrder,
  selectIsLoading, selectSections
} from "../../redux/orderDetail/orderDetailSlice";
import {StoreEmptyStateView} from "../storeFeed/StoreEmptyStateView";
import {ApiError} from "../../errors/apiError";
import {CloseIcon, LoadingSpinnerIcon, WarningColorIcon} from "../icons";
import {useRouter} from "next/router";
import {useModalRouting} from "../../utils/useModalRouting";
import {useTranslation} from "next-i18next";
import {Container} from "../containers/Container";
import {OrderDetailHeader} from "./OrderDetailHeader";
import {Body, Heading2, Heading3, LocalizedTextLabel, Paragraph} from "../text";
import Skeleton from "react-loading-skeleton";
import {OrderDetailItemView} from "./OrderDetailItemView";
import {CurrencyUtils} from "../../utils/currencyUtils";
import {Button} from "../buttons/Button";
import {RealmServiceError} from "../../errors/realmServiceError";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export interface OrderDetailViewProps {
  orderId: string
  onClose: () => void
}

export const OrderDetailView: React.FC<OrderDetailViewProps> = ({ orderId, onClose }) => {
  const { t } = useTranslation()

  const order = useAppSelector(selectOrder)
  const error = useAppSelector(selectError)
  const sections = useAppSelector(selectSections)
  const isLoading = useAppSelector(selectIsLoading)
  const router = useRouter()
  const { createRoute } = useModalRouting()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(isLoading)

  const userInfo = `----------------------------%0D%0AEmail: ${order?.customer?.email}%0D%0AUser ID: ${order?.customer?.id}`

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Order Detail' }))
  }, [])

  useEffect(() => {
    dispatch(reset())

    if (orderId) {
      dispatch(fetchOrder(orderId))
    }
  }, [orderId, dispatch])

  useEffect(() => {
    setLoading(router.isFallback || isLoading || !orderId)
  }, [router.isFallback, isLoading, orderId])

  const emptyStateButtonTitle = error instanceof ApiError && error.code === 'UNAUTHORIZED' || error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN'
    ? t('auth:auth_title_sign_in')
    : t('store:store_empty_state_button_try_again')

  const trackingUrl = order?.fulfillments[0]?.trackingUrls[0]

  return <div className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky">
    <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
      <div className="w-full no-scrollbar flex-1 flex flex-col">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`${loading ? '' : 'hidden'} h-full w-full flex-1 flex items-center justify-center`}>
          <LoadingSpinnerIcon className="h-8 w-8 lg:h-10 lg:w-10 animate-spin text-fg-primary" />
        </div>

        <div className={`${error && !loading ? '' : 'hidden'} flex-1 flex flex-col justify-center items-center`}>
          <StoreEmptyStateView
            onClick={() => {
              if (error instanceof ApiError && error.code === 'UNAUTHORIZED' || error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
                router.push(
                  createRoute({ name: 'login', query: { config: 'sign_in', order_id: orderId }, returnTo: `/order-detail` }),
                  undefined,
                  { shallow: true }
                );
              } else {
                dispatch(reset())
                dispatch(fetchOrder(orderId))
              }
            }}
            buttonTitle={emptyStateButtonTitle}
            error={error}
            icon={<WarningColorIcon />}
            isLoading={isLoading}
          />
        </div>


        {order && <div className={`mt-4 ${loading || error ? 'hidden' : ''}`}>
            <OrderDetailHeader order={order} loading={isLoading}/>
        </div>}

        {sections.map((section, index) => {
          return <div key={section.id} className={`${loading || error ? 'hidden' : ''}`}>
            <Heading2 className="mb-2 mt-4 lg:mt-6">{isLoading ? <Skeleton className="!w-1/3" /> : <LocalizedTextLabel t={t} text={section.title} />}</Heading2>

            {section.items.map(item => {
              return <OrderDetailItemView key={item.id} item={item} />
            })}
          </div>
        })}

        <div className={`${loading || error ? 'hidden' : ''}`}>
          <div className="my-6 border-b-2 border-fg-gray-surface-dark" />

          <div className="flex">
            <Body className="text-left">{isLoading ? <Skeleton className="!w-16" /> : t('checkout:checkout_row_title_subtotal')}</Body>
            <Body className="text-right flex-grow">{isLoading ? <Skeleton className="!w-16" /> : CurrencyUtils.stringFromPrice(order?.paymentSummary?.subtotal, order?.paymentSummary?.currencyCode)}</Body>
          </div>

          <div className="flex mt-4">
            <Body className="text-left">{isLoading ? <Skeleton className="!w-16" /> : t('checkout:checkout_row_title_shipping')}</Body>
            <Body className="text-right flex-grow">{isLoading ? <Skeleton className="!w-16" /> : CurrencyUtils.stringFromPrice(order?.paymentSummary?.shipping, order?.paymentSummary?.currencyCode)}</Body>
          </div>

          <div className={`flex mt-4 ${order?.paymentSummary?.discount ? '' : 'hidden'}`}>
            <Body className="text-left text-fg-primary">{isLoading ? <Skeleton className="!w-16" /> : t('checkout:checkout_row_title_discount')}</Body>
            <Body className="text-right text-fg-primary flex-grow">-{isLoading ? <Skeleton className="!w-16" /> : CurrencyUtils.stringFromPrice(order?.paymentSummary?.discount, order?.paymentSummary?.currencyCode)}</Body>
          </div>

          <div className="flex mt-4">
            <Body className="text-left">{isLoading ? <Skeleton className="!w-16" /> : t('checkout:checkout_row_title_tax')}</Body>
            <Body className="text-right flex-grow">{isLoading ? <Skeleton className="!w-16" /> : CurrencyUtils.stringFromPrice(order?.paymentSummary?.tax, order?.paymentSummary?.currencyCode)}</Body>
          </div>

          <div className="my-6 border-b-2 border-fg-gray-surface-dark" />

          <div className="flex mt-4">
            <Heading3 className="text-left">{isLoading ? <Skeleton className="!w-16" /> : t('checkout:checkout_row_title_total')}</Heading3>
            <Heading3 className="text-right flex-grow">{isLoading ? <Skeleton className="!w-16" /> : CurrencyUtils.stringFromPrice(order?.paymentSummary?.getTotal(), order?.paymentSummary?.currencyCode)}</Heading3>
          </div>
        </div>

        <div className={`${loading || error ? 'hidden' : ''} mt-4 w-full items-centered flex flex-col space-x-0 space-y-3 lg:flex-row lg:space-x-2 lg:space-y-0`}>
          {trackingUrl && <div className="flex-1">
            <Button
              isFullWidth={true}
              buttonStyle="primary"
              size="lg"
              href={trackingUrl}
            >
              {t('order:order_title_track_this_order')}
            </Button>
          </div>}

          <div className="flex-1">
            <Button
              isFullWidth={true}
              buttonStyle="secondary"
              size="lg"
              href={`mailto:${order?.vendor?.supportEmail}?subject=${`Order Help - Order ID: ${order?.id}`}&body=Hi ${order?.vendor?.name},%0D%0A%0D%0A%0D%0A%0D%0A${userInfo}&cc=${process.env.NEXT_PUBLIC_FITGENIE_SUPPORT_EMAIL}`}
              onClick={() => {

              }}
            >
              <a>{t('order:order_title_get_help')}</a>
            </Button>
          </div>
        </div>
      </div>
    </Container>
  </div>
}