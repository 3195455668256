import {CurrencyCode} from "./currencyCode";
import {DistributionMethod} from "./distributionMethod";

export interface ShippingOptionJson {
  _id?: string
  price?: number
  title?: string
  distribution_method?: DistributionMethod
  currency_code?: CurrencyCode
  delivery_range?: Date[]
}

export class ShippingOptionModel {
  id?: string
  price?: number
  title?: string
  distributionMethod?: DistributionMethod
  currencyCode?: CurrencyCode
  deliveryRange: Date[] = []

  constructor(model?: ShippingOptionModel) {
    if (!model) return

    this.id = model.id
    this.price = model.price
    this.title = model.title
    this.distributionMethod = model.distributionMethod
    this.currencyCode = model.currencyCode
    this.deliveryRange = model.deliveryRange
  }

  static fromJson(json?: ShippingOptionJson): ShippingOptionModel | undefined {
    if (!json) return

    const model = new ShippingOptionModel()

    model.id = json._id
    model.price = json.price
    model.title = json.title
    model.distributionMethod = json.distribution_method
    model.currencyCode = json.currency_code
    model.deliveryRange = json.delivery_range ?? []

    return model
  }
}