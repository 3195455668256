import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {StoreSectionModel} from "../../models/storeSection";
import {BaseError} from "../../errors/baseError";
import {WritableDraft} from "immer/dist/types/types-external";
import {ShoppingCartModel} from "../../models/shoppingCart";
import {fetchCartSucceeded} from "../cart/cartSlice";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {ProductSkuModel} from "../../models/productSku";

interface StoreSectionDetailState {
  readonly isLoading: boolean
  readonly sectionId?: string
  readonly categoryId?: string
  readonly sectionType: 'category' | 'section'
  readonly storeSection?: StoreSectionModel
  readonly error?: BaseError<any>
  readonly cart?: ShoppingCartModel | null
}

const updateStoreSectionWithCart = (section: StoreSectionModel | WritableDraft<StoreSectionModel>, cart?: ShoppingCartModel | null): (StoreSectionModel | WritableDraft<StoreSectionModel>)  => {
  section.products = section.products.map(p => {
    const items = cart?.items
      .filter(i => i.productId === p?.id)
      .sort((i1, i2) => (i2.updatedAt?.getTime() ?? 0) - (i1.updatedAt?.getTime() ?? 0)) ?? []

    const cartItem = items[0]
    const cartSku = p?.skus.filter(s => s.id === cartItem?.skuId)[0]

    if (cartSku && cartItem) {
      cartSku.quantity = cartItem.quantity
      p.setSelectedSku(cartSku as ProductSkuModel)
    } else {
      const lineItem = cart?.items.filter(i => i.skuId === p.getSelectedSku()?.id)[0]
      const quantity = lineItem?.quantity

      p.updateWithQuantity(quantity)
    }

    return p
  })
  return section
}

export const storeSectionDetailSlice = createSlice({
  name: "store_section_detail",
  initialState: {} as StoreSectionDetailState,
  reducers: {
    reset(state) {
      state.isLoading = true
      state.sectionId = undefined
      state.categoryId = undefined
      state.storeSection = undefined
      state.error = undefined
    },
    fetchStoreSectionForCategoryId(state, action: PayloadAction<string>) {
      state.isLoading = state.storeSection?.categoryId !== action.payload
      state.categoryId = action.payload
    },
    fetchStoreSectionForId(state, action: PayloadAction<string>) {
      state.isLoading = state.storeSection?.id !== action.payload
      state.sectionId = action.payload
    },
    fetchStoreSectionSucceeded(state, action: PayloadAction<StoreSectionModel>) {
      state.isLoading = false
      state.storeSection = action.payload

      const cart = state.cart
      state.storeSection = updateStoreSectionWithCart(action.payload, cart)
    },
    fetchStoreSectionFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('STORE_ERROR', action.payload)
        error.localizedMessage = { key: 'store:store_error_message_error_getting_store' }
        error.localizedTitle = { key: 'store:store_error_title_error_getting_store' }
        error.error = action.payload
        state.error = error as any
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchCartSucceeded,(state, action) => {
      state.cart = new ShoppingCartModel(action.payload)

      const storeSection = state.storeSection
      if (storeSection) {
        state.storeSection = updateStoreSectionWithCart(storeSection, state.cart)
      }
    })
  },
})

export const storeSectionDetailReducer = storeSectionDetailSlice.reducer

export const {
  fetchStoreSectionSucceeded,
  fetchStoreSectionForCategoryId,
  fetchStoreSectionForId,
  fetchStoreSectionFailed
} = storeSectionDetailSlice.actions

export const selectStoreSection = (state: RootState) => state.storeSectionDetail.storeSection
export const selectIsLoading = (state: RootState) => state.storeSectionDetail.isLoading
export const selectError = (state: RootState) => state.storeSectionDetail.error