import {ProductCategoryJson, ProductCategoryModel} from "./productCategory";
import {ImageJson, ImageModel} from "./image";
import {MealType} from "./mealType";
import {ProductOptionJson, ProductOptionModel} from "./productOption";
import {MenuItemSizeType} from "./menuItemSizeType";
import {ProductSkuJson, ProductSkuModel} from "./productSku";
import {ShoppingCartItemModel} from "./shoppingCartItem";
import {ProductSkuOptionValueModel} from "./productSkuOptionValue";

export interface ProductJson {
  _id?: string
  name?: string
  categories?: ProductCategoryJson[]
  description?: string
  image?: ImageJson
  availability_start_date?: Date
  availability_end_date?: Date
  estimated_distribution_date?: Date
  meal_type?: MealType
  options?: ProductOptionJson[]
  selected_size?: MenuItemSizeType
  skus?: ProductSkuJson[]
  section_id?: string
  store_id?: string
  vendor_id?: string
  vendor_name?: string
  sort_number?: number
  created_at?: Date
  updated_at?: Date
}

export class ProductModel {
  availabilityEndDate?: Date
  availabilityStartDate?: Date
  categories: ProductCategoryModel[] = []
  createdAt?: Date
  description?: string
  estimatedDistributionDate?: Date
  id?: string
  image?: ImageModel
  mealType?: MealType
  name?: string
  options: ProductOptionModel[] = []
  sectionId?: string
  selectedSize?: MenuItemSizeType
  selectedOptionValues: Map<String, String> = new Map<String, String>()
  skus: ProductSkuModel[] = []
  sortNumber?: number
  storeId?: string
  updatedAt?: Date
  vendorId?: string
  vendorName?: string

  constructor(model?: ProductModel | null) {
    if (!model) return

    this.availabilityEndDate = model.availabilityEndDate
    this.availabilityStartDate = model.availabilityStartDate
    this.categories = model.categories.map(i => new ProductCategoryModel(i))
    this.createdAt = model.createdAt
    this.description = model.description
    this.estimatedDistributionDate = model.estimatedDistributionDate
    this.id = model.id
    this.image = model.image && new ImageModel(model.image)
    this.mealType = model.mealType
    this.name = model.name
    this.options = model.options
    this.sectionId = model.sectionId
    this.selectedSize = model.selectedSize
    this.selectedOptionValues = new Map(model.selectedOptionValues)
    this.skus = model.skus.map(i => new ProductSkuModel(i))
    this.sortNumber = model.sortNumber
    this.storeId = model.storeId
    this.updatedAt = model.updatedAt
    this.vendorId = model.vendorId
  }

  static fromJson(json?: ProductJson): ProductModel | undefined {
    if (!json) return

    const model = new ProductModel()

    model.availabilityEndDate = json.availability_end_date
    model.availabilityStartDate = json.availability_start_date
    model.categories = json.categories
      ?.map((i) => ProductCategoryModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.createdAt = json.created_at
    model.description = json.description?.replace(/<[^>]+>/g, '')?.replace('&amp;', '&')
    model.estimatedDistributionDate = json.estimated_distribution_date,
    model.id = json._id
    model.image = ImageModel.fromJson(json.image)
    model.mealType = json.meal_type
    model.name = json.name
    model.options = json.options
      ?.map((i) => ProductOptionModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []

    model.selectedOptionValues.clear()
    model.options.forEach(option => {
      const optionId = option.id
      const value = option.values[0]
      if (optionId && value) {
        model.selectedOptionValues.set(optionId, value)
      }
    })

    model.sectionId = json.section_id
    model.selectedSize = json.selected_size
    model.skus = json.skus
      ?.map((i) => ProductSkuModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.sortNumber = json.sort_number
    model.storeId = json.store_id
    model.updatedAt = json.updated_at
    model.vendorId = json.vendor_id
    model.vendorName = json.vendor_name

    return model
  }

  getIsAvailable(): boolean {
    const start = this.availabilityStartDate
    const end = this.availabilityEndDate

    if (!start) return false
    if (!end) return false

    return (new Date()) > start && (new Date()) < end
  }

  getSelectedSku(): ProductSkuModel | undefined {
    const values = Array.from(this.selectedOptionValues.values())
    if (values.length > 0) {
      const sku = this.skus.filter((sku) => {
        const optionValues = sku.optionValues
          .map((o) => o.value)
          .flatMap(i => i ? [i] : [])

        return optionValues.every((value) => values.includes(value))
      })[0]

      return sku ?? this.skus[0]
    } else {
      return this.skus[0]
    }
  }

  setSelectedSku(sku: ProductSkuModel) {
    this.selectedOptionValues.clear()
    this.options.forEach(option => {
      const optionId = option.id
      const value = option.values.filter(v => sku.optionValues.map(o => o.value).includes(v))[0]
      if (optionId && value) {
        this.selectedOptionValues.set(optionId, value)
      }
    })

    this.updateWithQuantity(sku.quantity)
  }

  updateWithQuantity(newQuantity?: number) {
    const selectedSku = this.getSelectedSku()
    if (!selectedSku) return

    selectedSku.quantity = newQuantity
    const index = this.skus.findIndex(s => s.id === selectedSku.id)
    this.skus[index] = selectedSku
  }

  toLineItem(): ShoppingCartItemModel | undefined {
    const selectedSku = this.getSelectedSku()
    if (!selectedSku) return

    const model = new ShoppingCartItemModel()

    model.availabilityEndDate = this.availabilityEndDate,
    model.availabilityStartDate = this.availabilityStartDate
    model.createdAt = new Date()
    model.currencyCode = selectedSku?.currencyCode
    model.itemDescription = this.description
    model.estimatedDistributionDate = this.estimatedDistributionDate
    model.skuId = selectedSku.id
    model.optionValues = selectedSku.optionValues.map(i => new ProductSkuOptionValueModel(i))
    model.image = new ImageModel(this.image)
    model.productId = selectedSku.productId
    model.name = this.name
    model.quantity = selectedSku.quantity ?? 0
    model.price = selectedSku.price ?? 0
    model.size = selectedSku.size
    model.storeId = this.storeId
    model.updatedAt = new Date()
    model.vendorId = this.vendorId

    return model
  }
}