import {from, Observable} from "rxjs";
import {api, handleApiError} from "./api";
import {OnboardAffiliatePayload} from "../models/onboardAffiliatePayload";
import {SetAffiliateStatusPayload} from "../models/setAffiliateStatusPayload";
import {AffiliatesResponseModel} from "../models/affiliatesResponse";
import {CommissionsResponseModel} from "../models/commissionsResponse";
import {CommissionType} from "../models/commission";
import {CommissionPayoutsResponseModel} from "../models/commissionPayoutsResponse";
import {AffiliateInfoResponseModel} from "../models/affiliateInfoResponse";
import {AffiliateInternalCodesResponseModel} from "../models/affiliateInternalCodesResponse";

export const AffiliateApi = {
  getAffiliateInfo(): Observable<AffiliateInfoResponseModel> {
    const request: Promise<AffiliateInfoResponseModel> = api.get('/affiliate/get-info', {
      headers: { is_authenticated: true }
    })
      .then(response => AffiliateInfoResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  onboardAffiliate: (payload: OnboardAffiliatePayload): Observable<void> => {
    const request: Promise<void> = api.post('/affiliate/onboard', payload, {
      headers: { is_authenticated: true }
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  getAllAffiliates(pageNumber: number): Observable<AffiliatesResponseModel> {
    const request: Promise<AffiliatesResponseModel> = api.get(`/affiliate/get-all`, {
      headers: { is_authenticated: true },
      params: { page_number: pageNumber, max_results: 30 }
    })
      .then(response => AffiliatesResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getCommissions(start_date: Date, end_date: Date): Observable<CommissionsResponseModel> {
    const request: Promise<CommissionsResponseModel> = api.get(`/affiliate/get-commissions`, {
      headers: { is_authenticated: true },
      params: { start_date, end_date }
    })
      .then(response => CommissionsResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getInternalPromoCodes(pageNumber: number): Observable<AffiliateInternalCodesResponseModel> {
    const request: Promise<AffiliateInternalCodesResponseModel> = api.get(`/affiliate/get-internal-codes`, {
      headers: { is_authenticated: true },
      params: { page_number: pageNumber, max_results: 20 }
    })
      .then(response => AffiliateInternalCodesResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getPayouts(start_date: Date, end_date: Date, type: CommissionType): Observable<CommissionPayoutsResponseModel> {
    const request: Promise<CommissionPayoutsResponseModel> = api.get(`/affiliate/get-payouts`, {
      headers: { is_authenticated: true },
      params: { start_date, end_date, type }
    })
      .then(response => CommissionPayoutsResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  setAffiliateStatus(payload: SetAffiliateStatusPayload): Observable<void> {
    const request: Promise<void> = api.post('/affiliate/set-status', payload, {
      headers: { is_authenticated: true }
    })
      .then(() => { })
      .catch(handleApiError)

    return from(request)
  }
}