import {HeightUnit, WeightUnit} from "../models/fitnessProfile";

export const UnitsUtils = {
  convertWeight(value: number | undefined, currentUnit: WeightUnit, desiredUnit: WeightUnit): number | undefined {
    if (!value) return
    if (currentUnit === WeightUnit.imperial && desiredUnit === WeightUnit.metric) {
      return value / 2.20462
    } else if (currentUnit === WeightUnit.metric && desiredUnit === WeightUnit.imperial) {
      return value * 2.20462
    } else {
      return value
    }
  },
  convertHeight(value: number | undefined, currentUnit: HeightUnit, desiredUnit: HeightUnit): number | undefined {
    if (!value) return
    if (currentUnit === HeightUnit.imperial && desiredUnit === HeightUnit.metric) {
      return value / 0.393701
    } else if (currentUnit === HeightUnit.metric && desiredUnit === HeightUnit.imperial) {
      return value * 0.393701
    } else {
      return value
    }
  }
}