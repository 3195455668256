export interface CreateCheckoutResponseJson {
  url?: string
  id?: string
}

export class CreateCheckoutResponseModel {
  url?: string
  id?: string

  constructor(model?: CreateCheckoutResponseModel | null) {
    if (!model) return

    this.url = model.url
    this.id = model.id

    return this
  }

  static fromJson(json?: CreateCheckoutResponseJson): CreateCheckoutResponseModel | undefined {
    if (!json) return

    const model = new CreateCheckoutResponseModel()
    model.url = json.url
    model.id = json.id

    return model
  }
}