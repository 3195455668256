import {BehaviorSubject, from, mergeMap, Observable, of, zip} from "rxjs";
import {AuthResponseModel} from "../models/authResponse";
import {AuthApi} from "../apis/authApi";
import {SignUpPayload} from "../models/signupPayload";
import {LoginWithEmailPayload} from "../models/loginWithEmailPayload";
import {LoginWithFacebookPayload} from "../models/loginWithFacebookPayload";
import {SendPasswordResetRequestPayload} from "../models/sendPasswordResetRequestPayload";
import {realmService} from "../realm/realmService";
import {map} from "rxjs/operators";
import moment from "moment";
import {GenericError} from "../errors/genericError";
import {LoginWithApplePayload} from "../models/loginWithApplePayload";
import {SignupAnonymouslyPayload} from "../models/signupAnonymouslyPayload";
import {UserService} from "./userService";
import {RootState} from "../app/store";
import {ShoppingCartModel} from "../models/shoppingCart";
import {LocationModel} from "../models/location";
import {LocationService} from "./locationService";
import {DeeplinkService} from "./deeplinkService";

const accessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)

export const AuthService = {
  authenticateSession(): Observable<AuthResponseModel> {
    return AuthApi.authenticateSession()
  },
  signUp(payload: SignUpPayload): Observable<AuthResponseModel> {
    return AuthApi.signUp(payload)
  },
  signUpAnonymously(state: RootState): Observable<AuthResponseModel> {
    const carts = Object.values(state.cart.cartMap).map(cart => new ShoppingCartModel(cart))
    const primaryLocation = state.user.primaryLocation && new LocationModel(state.user.primaryLocation)

    return UserService.getDeviceFingerprint()
      .pipe(
        mergeMap(device_fingerprint => {
          return AuthApi.signUpAnonymously({ device_fingerprint })
        }),
        mergeMap(response => {
          return realmService.login(response.realmToken!)
            .pipe(map(() => response))
        }),
        mergeMap(response => {
          return zip([
            realmService.createOrModifyEntities(carts),
            primaryLocation ? LocationService.setPrimaryDeliveryLocation(primaryLocation) : of(undefined)
          ]).pipe(map(() => response))
        }),
      )
  },
  loginWithEmail(payload: LoginWithEmailPayload): Observable<AuthResponseModel> {
    return AuthApi.loginWithEmail(payload)
  },
  loginWithFacebook(): Observable<AuthResponseModel> {
    const payloadObservable = new Observable<LoginWithFacebookPayload>(subscriber => {
      // @ts-ignore
      window.FB.login((response: any) => {
        if (response.authResponse && response.status === 'connected') {
          const graphData: LoginWithFacebookPayload = {
            token: response.authResponse.accessToken,
            id: response.authResponse.userID
          }

          // @ts-ignore
          window.FB.api('/me', {fields: 'email,id,first_name,last_name'}, (response: any) => {
            graphData.email = response.email
            graphData.first_name = response.first_name
            graphData.last_name = response.last_name

            subscriber.next(graphData)
          });
        } else {
          const error = new GenericError('FACEBOOK_LOGIN_ERROR')
          error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
          error.localizedMessage = { key: 'auth:auth_alert_message_error_facebook_login' }
          return subscriber.error(error)
        }
      }, {scope: 'public_profile,email'});
    })

    return payloadObservable.pipe(mergeMap(AuthApi.loginWithFacebook))
  },
  loginWithApple(): Observable<AuthResponseModel> {
    // @ts-ignore
    const payloadObservable: Observable<LoginWithApplePayload> = from(window.AppleID.auth.signIn())
      .pipe(
        map((result: any) => {
          const response: LoginWithApplePayload = {
            token: result.authorization.id_token,
            email: result.user?.email,
            first_name: result.user?.name?.firstName,
            last_name: result.user?.name?.lastName,
          }

          return response
        })
      )

    return payloadObservable.pipe(mergeMap(AuthApi.loginWithApple))
  },
  loginToRealmIfNeeded(token: string): Observable<void> {
    if (realmService.userIsLoggedIn()) {
      return realmService.login(token)
        .pipe(map(() => {}))
    } else {
      return of(undefined)
    }
  },
  loginToRealmAnonymouslyIfNeeded(): Observable<void> {
    if (realmService.userIsLoggedIn()) {
      return realmService.loginAnonymously()
        .pipe(map(() => {}))
    } else {
      return of(undefined)
    }
  },
  logout(): Observable<void> {
    return AuthApi.logout()
      .pipe(
        mergeMap(() => {
          DeeplinkService.getBranchSdk()?.logout()
          return realmService.logout()
        }),
        map(() => {
          localStorage.removeItem('refresh_token_exp')
        })
      )
  },
  sendPasswordResetRequest(payload: SendPasswordResetRequestPayload): Observable<void> {
    return AuthApi.sendPasswordResetRequest(payload)
  },
  setAccessToken(auth?: AuthResponseModel) {
    if (!auth) {
      accessTokenSubject.next("")
      localStorage.removeItem('refresh_token_exp')
      return
    }
    const { accessToken: token, refreshTokenExpDate } = auth;
    if (!token) return;
    if (!refreshTokenExpDate) return;

    accessTokenSubject.next(token)
    localStorage.setItem('refresh_token_exp', refreshTokenExpDate.toISOString())
  },
  getAccessToken(): BehaviorSubject<string | null> {
    return accessTokenSubject
  },
  isRefreshTokenValid(): boolean {
    const expDateString = localStorage.getItem('refresh_token_exp')
    if (!expDateString) return false
    const expDate = moment(expDateString).toDate()
    return new Date() < expDate
  }
}