import {EmailJson, EmailModel} from "./email";

export interface EmailsResponseJson {
  total_results?: number
  emails?: EmailJson[]
}

export class EmailsResponseModel {
  totalResults: number = 0
  emails: EmailModel[] = []

  static fromJson(json?: EmailsResponseJson): EmailsResponseModel | undefined {
    if (!json) return

    const model = new EmailsResponseModel()
    model.totalResults = json.total_results ?? 0
    model.emails = json.emails?.map(i => EmailModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}