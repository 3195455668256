import {AddressModel} from "../../models/address";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BaseError} from "../../errors/baseError";
import {fetchLocationFromPlaceIdSucceeded} from "../location/locationSearchSlice";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {Stripe, StripeCardNumberElement} from '@stripe/stripe-js'
import {PaymentValidationError} from "../../errors/paymentValidationError";
import {StripeError} from "../../utils/useStripeFormatter";

export interface PaymentEntryState {
  isSaving: boolean
  billingAddress?: AddressModel
  error?: BaseError<any> | null
  validationError?: PaymentValidationError | null
  shouldClose: boolean
}

export const paymentEntrySlice = createSlice({
  name: 'payment_entry',
  initialState: { isSaving: false, shouldClose: false } as PaymentEntryState,
  reducers: {
    reset(state) {
      state.isSaving = false
      state.billingAddress = undefined
      state.error = null
      state.validationError = null
      state.shouldClose = false
    },
    clearError(state) {
      state.error = null
    },
    addPaymentSource(state, action: PayloadAction<{ stripe: Stripe, element: StripeCardNumberElement }>) {
      state.isSaving = true
    },
    addPaymentSourceSucceeded(state) {
      state.isSaving = false
      state.shouldClose = true
    },
    addPaymentSourceFailed(state, action: PayloadAction<Error | StripeError>) {
      state.isSaving = false

      if (action.payload instanceof PaymentValidationError) {
        state.validationError = action.payload as any
      } else if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else if (action.payload instanceof Error) {
        const error = new GenericError('PAYMENT_ENTRY_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'payment:payment_message_error_adding_payment_method' }
        error.error = action.payload
        state.error = error as any
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchLocationFromPlaceIdSucceeded, (state, action) => {
      state.validationError = null
      state.billingAddress = action.payload.address
    })
  }
})

export const paymentEntryReducer = paymentEntrySlice.reducer

export const {
  reset,
  clearError,
  addPaymentSource,
  addPaymentSourceFailed,
  addPaymentSourceSucceeded
} = paymentEntrySlice.actions

export const selectError = (state: RootState) => state.paymentEntry.error
export const selectBillingAddress = (state: RootState) => state.paymentEntry.billingAddress
export const selectIsSaving = (state: RootState) => state.paymentEntry.isSaving
export const selectShouldClose = (state: RootState) => state.paymentEntry.shouldClose
export const selectValidationError = (state: RootState) => state.paymentEntry.validationError