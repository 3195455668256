import {from, Observable} from "rxjs";
import {api, handleApiError} from "./api";
import {IpLocationResponseJson} from "../models/ipLocationResponse";
import {AutocompletePrediction, AutoCompletePredictionResponse, LatLng, PlacesResponseStatus} from "googlemaps";
import {LocationJson, LocationModel} from "../models/location";
import {PlacesError} from "../errors/placesError";
import {AuthService} from "../services/authService";

export const LocationApi = {
  getLocationFromLatLong(latLong: LatLng): Observable<LocationModel> {
    const params = {
      lat: latLong.lat,
      long: latLong.lng
    }
    const request = api.get('/location/get-location', { params })
      .then(response => {
        const json: { location?: LocationJson, status: PlacesResponseStatus } = response.data
        if (json.status !== "OK") {
          throw new PlacesError(json.status ?? 'UNKNOWN_ERROR')
        } else if (!json.location) {
          throw new PlacesError('UNKNOWN_ERROR')
        }

        return LocationModel.fromJson(json.location)!
      })
      .catch(handleApiError)

    return from(request);
  },
  getApproxLatLng(): Observable<LatLng> {
    const request: Promise<LatLng> = api.get(`/location/get-approx-lat-long`, {
      headers: { is_authenticated: true }
    })
      .then(response => {
        return response.data as LatLng
      })

    return from(request)
  },
  getCurrentLatLng(): Observable<LatLng> {
    return new Observable<LatLng>(subscriber => {
      navigator.geolocation.getCurrentPosition(
        position => {
          subscriber.next({ lat: position.coords.latitude, lng: position.coords.longitude })
        },
        error => {
          subscriber.error(error)
        }
      )
    })
  },
  getLocationFromPlaceId(placeId: string): Observable<LocationModel> {
    const params = {
      place_id: placeId
    }

    const request = api.get(`/location/get-location-from-place-id`, { params, headers: { is_authenticated: true } })
      .then(response => {
        const json: { location?: LocationJson, status: PlacesResponseStatus } = response.data
        if (json.status !== "OK") {
          throw new PlacesError(json.status ?? 'UNKNOWN_ERROR')
        } else if (!json.location) {
          throw new PlacesError('UNKNOWN_ERROR')
        }

        return LocationModel.fromJson(json.location)!
      })
      .catch(handleApiError)

    return from(request);
  },
  getAutocompletePredictions(query: string, currentLatLng: LatLng): Observable<AutocompletePrediction[]> {
    const params = {
      query,
      lat: currentLatLng.lat,
      long: currentLatLng.lng
    }

    const request = api.get(`/location/get-autocomplete-predictions`, { params, headers: { is_authenticated: true } })
      .then(apiResponse => {
        const response = apiResponse.data as AutoCompletePredictionResponse
        if (response.status !== "OK") {
          throw new PlacesError(response.status ?? 'UNKNOWN_ERROR', null, query)
        }

        return response.predictions
      })
      .catch(handleApiError)

    return from(request);
  }
}