import {RootEpic} from "../../app/store";
import {catchError, filter, map, mergeMap, Observable, of} from "rxjs";
import {fetchCohorts, fetchCohortsFailed, fetchCohortsSucceeded} from "./cohortSlice";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";
import {CohortModel} from "../../models/cohort";

export const fetchCohortsEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchCohorts.match),
    mergeMap(action => {
      let cohortObservable: Observable<CohortModel[]>
      if (state$.value.cohort.cohorts.length) {
        cohortObservable = new Observable<CohortModel[]>(subscriber => {
          subscriber.next(state$.value.cohort.cohorts)
        })
      } else {
        cohortObservable = MessageService.fetchCohorts()
      }

      return cohortObservable
        .pipe(
          map(fetchCohortsSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchCohortsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}