import {HTMLAttributes} from "react";
import {CustomTypeOptions, LocalizationInfo, Namespace, TFunction} from "react-i18next";
import {BaseError} from "../../errors/baseError";

export const TextStyles = {
  Hero: 'font-semibold text-3xl md:text-3xl lg:text-5xl xl:text-6xl',
  HeroHeading1: 'font-semibold text-2xl md:text-2xl lg:text-4xl xl:text-4xl',
  HeroHeading2: 'font-semibold text-xl md:text-xl lg:text-3xl xl:text-3xl',
  HeroBody: 'font-normal text-base md:text-base lg:text-lg xl:text-xl',
  HeroParagraph: 'font-normal text-sm md:text-sm lg:text-base xl:text-lg',
  Heading1: 'font-semibold text-xl md:text-xl lg:text-2xl xl:text-2xl',
  Heading2: 'font-semibold text-lg md:text-lg lg:text-xl xl:text-xl',
  Heading3: 'font-semibold text-base md:text-base lg:text-lg xl:text-lg',
  Heading4: 'font-semibold text-sm md:text-sm lg:text-base xl:text-base',
  Body: 'font-normal text-base md:text-base lg:text-lg xl:text-lg',
  Paragraph: 'font-normal text-sm md:text-sm lg:text-base xl:text-base',
  Caption: 'font-normal text-xs md:text-xs lg:text-sm xl:text-sm',
}

const Hero: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <h1 {...props} className={`${TextStyles.Hero} ${props.className ?? ''}`}>
      {props.children}
    </h1>
  )
}

const HeroHeading1: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <h1 {...props} className={`${TextStyles.HeroHeading1} ${props.className ?? ''}`}>
      {props.children}
    </h1>
  )
}

const HeroHeading2: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <h2 {...props} className={`${TextStyles.HeroHeading2} ${props.className ?? ''}`}>
      {props.children}
    </h2>
  )
}

const HeroBody: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <p {...props} className={`${TextStyles.HeroBody} ${props.className ?? ''}`}>
      {props.children}
    </p>
  )
}

const HeroParagraph: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <p {...props} className={`${TextStyles.HeroParagraph} ${props.className ?? ''}`}>
      {props.children}
    </p>
  )
}

const Paragraph: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <p {...props} className={`${TextStyles.Paragraph} ${props.className ?? ''}`}>
      {props.children}
    </p>
  )
}

const Caption: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <p {...props} className={`${TextStyles.Caption} ${props.className ?? ''}`}>
      {props.children}
    </p>
  )
}

const Body: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <p {...props} className={`${TextStyles.Body} ${props.className ?? ''}`}>
      {props.children}
    </p>
  )
}

const Heading1: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <h1 {...props} className={`${TextStyles.Heading1} ${props.className ?? ''}`}>
      {props.children}
    </h1>
  )
}

const Heading2: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <h2 {...props} className={`${TextStyles.Heading2} ${props.className}`}>
      {props.children}
    </h2>
  )
}

const Heading3: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <h3 {...props} className={`${TextStyles.Heading3} ${props.className}`}>
      {props.children}
    </h3>
  )
}

const Heading4: React.FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  return(
    <h4 {...props} className={`${TextStyles.Heading4} ${props.className}`}>
      {props.children}
    </h4>
  )
}

interface LocalizedTextLabelProps extends HTMLAttributes<HTMLDivElement> {
  t: TFunction<Namespace<keyof CustomTypeOptions['resources']>>
  text?: LocalizationInfo | string | null
}

const LocalizedTextLabel: React.FC<LocalizedTextLabelProps> = (props) => {
  const { t, text } = props
  return (
    <span>{ typeof text !== "string" && text ? t(text.key, text.options) : text }</span>
  )
}

export {
  LocalizedTextLabel,
  Hero,
  HeroHeading1,
  HeroHeading2,
  HeroBody,
  HeroParagraph,
  Paragraph,
  Caption,
  Body,
  Heading1,
  Heading2,
  Heading3,
  Heading4
}