import {setCartMenuOpen, toggleSideNavOpen} from "../../redux/navigation/navigationSlice";
import {CartFilledIcon, FitGenieLogoIcon, FitGenieStarIcon, MarkerFilledIcon, MenuIcon} from "../icons";
import {Button} from "../buttons/Button";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {SegmentedControl, SegmentedControlItem} from "../segmentedControls/SegmentedControl";
import {DistributionMethod} from "../../models/distributionMethod";
import {Heading4} from "../text";
import {selectPrimaryLocation} from "../../redux/user/userSlice";
import Link from "next/link";
import {selectDistributionMethod, updateDistributionMethod} from "../../redux/storeFeed/storeFeedSlice";
import {useRouter} from "next/router";
import {selectCart} from "../../redux/cart/cartSlice";
import {selectCart as selectStoreCart} from "../../redux/store/storeSlice";
import {useModalRouting} from "../../utils/useModalRouting";
import {LocationEntryModal} from "../location/locationEntryModal";
import {selectLoginStatus} from "../../redux/auth/authSlice";

export const NavBarNegativeHeight = "-mt-16 md:-mt-20"
export const NavBarNegativeInset = "inset-y-16 md:inset-y-20"
export const NavBarHeight = "h-16 md:h-20"
export const NavBarPadding = "pt-16 md:pt-20"
export const ScreenHeightFullWithNavBar = "h-[calc(100vh-4rem)] md:h-[calc(100vh-5rem)]"
export const ScreenMinHeightFullWithNavBar = "min-h-[calc(100vh-4rem)] md:min-h-[calc(100vh-5rem)]"

export const NavBarMain: React.FC<any> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { route } = router
  const { createRoute } = useModalRouting()
  const loginStatus = useAppSelector(selectLoginStatus)
  const subdomain = process.env.NEXT_PUBLIC_SUBDOMAIN || window.location.host.split('.')[0]

  const location = useAppSelector(selectPrimaryLocation)
  const cart = useAppSelector(state => {
    if (router.route === '/store/[id]') {
      return selectStoreCart(state)
    } else if (router.route === '/store-section/[id]') {
      return selectStoreCart(state)
    } else if (router.route === '/store-category/[id]') {
      return selectStoreCart(state)
    }  else if (router.route === '/cart/[id]') {
      return selectStoreCart(state)
    } else {
      return selectCart(state, router.query.cart_id as string)
    }
  })

  const cartQuantity = cart?.items.reduce((sum, item) => {
    return sum + (item.quantity ?? 0)
  }, 0).toString() ?? "0"

  const cartIsEmpty = !cart || cart.items.length <= 0

  const distributionMethod = useAppSelector(selectDistributionMethod);

  const segmentedControlItems: SegmentedControlItem<DistributionMethod>[] = [
    { value: 'delivery', id: 'delivery' },
    { value: 'pickup', id: 'pickup' },
  ]

  return (
    <>
      <div className="w-full mx-auto px-2 sm:px-8 md:px-10 bg-white border-b border-fg-gray-surface sticky top-0 z-20">
        <div className={`relative flex items-center justify-between ${NavBarHeight}`}>
          <div className="flex items-center justify-start space-x-2 md:space-x-6 lg:space-x-8">
            <button
              className="p-3 transition-all hover:bg-fg-gray-surface rounded-full flex items-center justify-center"
              onClick={() => dispatch(toggleSideNavOpen())}
            >
              <MenuIcon className="block h-5 w-5" />
            </button>

            <div className="flex-shrink-0 flex items-center">
              <Link href={subdomain?.includes('shop') ? '/store-feed' : '/'} passHref>
                <a>
                  <FitGenieStarIcon className="block md:hidden h-7 w-auto" />
                </a>
              </Link>
              <Link href={subdomain?.includes('shop') ? '/store-feed' : '/'} passHref>
                <a>
                  <FitGenieLogoIcon className="hidden md:block h-7 w-auto" />
                </a>
              </Link>
            </div>

            <SegmentedControl
              className={`${route === '/store-feed' ? 'hidden md:block' : 'hidden'}`}
              selectedItemId={distributionMethod}
              items={segmentedControlItems}
              renderItem={(item) => {
                return <Heading4 className="text-center capitalize">{item.value.toLowerCase()}</Heading4>
              }}
              onItemClick={(item) => dispatch(updateDistributionMethod(item.value))}
            />

            <LocationEntryModal />

            <Link
              href={createRoute({ name: 'location_entry' })}
              shallow={true}
              passHref
            >
              <a>
                <Button
                  className={`${route === '/store-feed' ? 'hidden lg:flex' : 'hidden'}`}
                  leadingicon={<MarkerFilledIcon />}
                  buttonStyle="secondary"
                >
                  {location?.getDisplayDescription() ?? t('location:location_title_add_address')}
                </Button>
              </a>
            </Link>
          </div>

          <Link
            href={createRoute({ name: 'location_entry' })}
            shallow={true}
            passHref
          >
            <a>
              <Button
                className={`${route === '/store-feed' ? 'block lg:hidden' : 'hidden'}`}
                leadingicon={<MarkerFilledIcon />}
                buttonStyle="secondary"
              >
                {location?.getDisplayDescription() ?? t('location:location_title_add_address')}
              </Button>
            </a>
          </Link>

          {!router.isFallback && <div className={`hidden ${router.route === '/checkout' ? 'lg:hidden' : 'lg:flex'} inset-y-0 right-0 items-center space-x-2 sm:static sm:inset-auto`}>
            <Button
              buttonStyle={`${cartIsEmpty ? 'secondary' : 'primary'}`}
              leadingicon={<CartFilledIcon/>}
              onClick={() => {
                if (loginStatus === 'not_logged_in') {
                  router.push(
                    createRoute({ name: 'login', query: { config: 'get_started' }}),
                    undefined,
                    { shallow: true }
                  );
                } else {
                  dispatch(setCartMenuOpen(true))
                }
              }}
            >
              {t('store:store_title_cart')} • {cartQuantity}
            </Button>
          </div>}
        </div>
      </div>
    </>
  )
}