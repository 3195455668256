import {RootEpic} from "../../app/store";
import {fetchPrompts, fetchPromptsFailed, fetchPromptsSucceeded} from "./promptListSlice";
import {catchError, filter, map, mergeMap, of} from "rxjs";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";

export const fetchPromptsEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchPrompts.match),
    mergeMap(action => {
      return MessageService.fetchPrompts(action.payload)
        .pipe(
          map(fetchPromptsSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchPromptsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}