import {ShoppingCartModel} from "../../models/shoppingCart";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ShoppingCartItemModel} from "../../models/shoppingCartItem";
import {BaseError} from "../../errors/baseError";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {logoutSucceeded} from "../auth/authSlice";
import {ProductModel} from "../../models/product";

export interface CartState {
  readonly cartMap: { [storeId: string]: ShoppingCartModel }
  readonly isUpdatingMap: { [storeId: string]: boolean }
  readonly isLoadingMap: { [storeId: string]: boolean }
  readonly productUpdatingMap: { [productId: string]: boolean }
  readonly didUpdateCartMap: { [storeId: string]: boolean }
  readonly errorMap: { [storeId: string]: BaseError<any> | null | undefined }
}

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartMap: {},
    isUpdatingMap: {},
    isLoadingMap: {},
    didUpdateCartMap: {},
    productUpdatingMap: {},
    errorMap: {}
  } as CartState,
  reducers: {
    fetchCart(state, action: PayloadAction<string>) {
      state.isLoadingMap[action.payload] = true
    },
    fetchRecentCart(state, action: PayloadAction) {

    },
    fetchCartSucceeded(state, action: PayloadAction<ShoppingCartModel | null>) {
      const cart = action.payload
      const storeId = cart?.storeId
      if (storeId && cart) {
        state.cartMap[storeId] = new ShoppingCartModel(cart)
      }
    },
    resetCartUpdateStatus(state, action: PayloadAction<string>) {
      state.didUpdateCartMap[action.payload] = false
    },
    fetchAndUpdateCartIfNeeded(state, action: PayloadAction<string>) {
      const cart = state.cartMap[action.payload]
      const items = cart?.items ?? []

      state.isLoadingMap[action.payload] = items.length === 0

      state.isUpdatingMap[action.payload] = true
      state.errorMap[action.payload] = null
    },
    fetchAndUpdateCartIfNeededSucceeded(state, action: PayloadAction<{ cart: ShoppingCartModel | null, didUpdate: boolean }>) {
      const cart = action.payload.cart
      const storeId = cart?.storeId
      if (storeId && cart) {
        state.cartMap[storeId] = new ShoppingCartModel(cart)
        state.isUpdatingMap[storeId] = false
        state.isLoadingMap[storeId] = false
        state.didUpdateCartMap[storeId] = action.payload.didUpdate
      }
    },
    fetchAndUpdateCartIfNeededFailed(state, action: PayloadAction<{ error: Error, storeId: string }>) {
      state.isUpdatingMap[action.payload.storeId] = false
      state.isLoadingMap[action.payload.storeId] = false

      if (action.payload.error instanceof BaseError) {
        state.errorMap[action.payload.storeId] = action.payload.error as any
      } else {
        const error = new GenericError('CART_ERROR', action.payload.error)
        error.localizedMessage = { key: 'store:store_error_message_error_updating_cart' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload.error
        state.errorMap[action.payload.storeId] = error as any
      }
    },
    fetchCartFailed(state, action: PayloadAction<{ error: Error, storeId: string }>) {
      state.isLoadingMap[action.payload.storeId] = false
    },
    addItemToCart(state, action: PayloadAction<{ item: ShoppingCartItemModel, product: ProductModel, quantity: number, storeId: string }>) {
      const productId = action.payload.item.productId
      if (productId) {
        state.productUpdatingMap[productId] = true
      }
    },
    incrementItemInCart(state, action: PayloadAction<{ item: ShoppingCartItemModel, product: ProductModel, storeId: string }>) {
      const productId = action.payload.item.productId
      if (productId) {
        state.productUpdatingMap[productId] = true
      }
    },
    decrementItemInCart(state, action: PayloadAction<{ item: ShoppingCartItemModel, product: ProductModel, storeId: string }>) {
      const productId = action.payload.item.productId
      if (productId) {
        state.productUpdatingMap[productId] = true
      }
    },
    updateItemInCart(state, action: PayloadAction<{ item: ShoppingCartItemModel, product?: ProductModel, quantity: number, storeId: string }>) {
      const productId = action.payload.item.productId
      if (productId) {
        state.productUpdatingMap[productId] = true
      }
    },
    updateCartItemSucceeded(state, action: PayloadAction<ShoppingCartItemModel>) {
      const productId = action.payload.productId
      if (productId) {
        state.productUpdatingMap[productId] = false
      }
    },
    updateCartItemFailed(state, action: PayloadAction<{ error: Error, item: ShoppingCartItemModel }>) {
      const productId = action.payload.item.productId
      if (productId) {
        state.productUpdatingMap[productId] = false
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(logoutSucceeded, (state, action) => {
      state.cartMap = {}
    })
  }
})

export const cartReducer = cartSlice.reducer

export const {
  fetchCart,
  fetchRecentCart,
  resetCartUpdateStatus,
  fetchCartSucceeded,
  fetchCartFailed,
  fetchAndUpdateCartIfNeeded,
  fetchAndUpdateCartIfNeededFailed,
  fetchAndUpdateCartIfNeededSucceeded,
  addItemToCart,
  incrementItemInCart,
  decrementItemInCart,
  updateItemInCart,
  updateCartItemSucceeded,
  updateCartItemFailed
} = cartSlice.actions

export const selectIsUpdating = (state: RootState, storeId?: string): boolean => (storeId ? state.cart.isUpdatingMap[storeId] : false)
export const selectError = (state: RootState, storeId?: string) => (storeId ? state.cart.errorMap[storeId] : undefined)
export const selectIsUpdatingProduct = (state: RootState, productId?: string) => (productId ? state.cart.productUpdatingMap[productId] : false)
export const selectIsLoading = (state: RootState, storeId?: string) => (storeId ? state.cart.isLoadingMap[storeId] : false)
export const selectCart = (state: RootState, storeId?: string) => {
  if (storeId) {
    return state.cart.cartMap[storeId]
  } else {
    return Object.values(state.cart.cartMap)
      .filter(c => c.items.length > 0)
      .sort((c1, c2) => (c2.updatedAt?.getTime() ?? 0) - (c1.updatedAt?.getTime() ?? 0))[0]
  }
}
export const selectDidUpdate = (state: RootState, storeId?: string) => (storeId ? state.cart.didUpdateCartMap[storeId] : false)
