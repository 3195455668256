export type UserAffiliateStatus = 'active' | 'inactive'
export type UserAffiliatePayoutMethodType = 'paypal' | 'venmo'

export interface UserAffiliateProgramPayoutMethodJson {
  type?: UserAffiliatePayoutMethodType
  venmo_phone_number?: string
  paypal_email?: string
}

export interface UserAffiliateProgramJson {
  affiliate_code?: string
  affiliate_status?: UserAffiliateStatus
  payout_method?: UserAffiliateProgramPayoutMethodJson,
  tier?: number
}

export class UserAffiliateProgramPayoutMethodModel {
  type?: UserAffiliatePayoutMethodType
  venmoPhoneNumber?: string
  paypalEmail?: string

  constructor(model?: UserAffiliateProgramPayoutMethodModel) {
    if (!model) return

    this.type = model.type
    this.venmoPhoneNumber = model.venmoPhoneNumber
    this.paypalEmail = model.paypalEmail
  }

  static fromJson(json?: UserAffiliateProgramPayoutMethodJson): UserAffiliateProgramPayoutMethodModel | undefined {
    if (!json) return

    const model = new UserAffiliateProgramPayoutMethodModel()

    model.type = json.type
    model.venmoPhoneNumber = json.venmo_phone_number
    model.paypalEmail = json.paypal_email

    return model
  }
}

export class UserAffiliateProgramModel {
  affiliateCode?: string
  affiliateStatus?: UserAffiliateStatus
  payoutMethod?: UserAffiliateProgramPayoutMethodModel
  tier: number = 0

  constructor(model?: UserAffiliateProgramModel) {
    if (!model) return

    this.affiliateCode = model.affiliateCode
    this.affiliateStatus = model.affiliateStatus
    this.payoutMethod = new UserAffiliateProgramPayoutMethodModel(model.payoutMethod)
    this.tier = model.tier
  }

  static fromJson(json?: UserAffiliateProgramJson): UserAffiliateProgramModel | undefined {
    if (!json) return

    const model = new UserAffiliateProgramModel()

    model.affiliateCode = json.affiliate_code
    model.affiliateStatus = json.affiliate_status
    model.payoutMethod = UserAffiliateProgramPayoutMethodModel.fromJson(json.payout_method)
    model.tier = json.tier ?? model.tier

    return model
  }
}