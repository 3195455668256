import {BaseError} from "../../errors/baseError";
import {EmailModel} from "../../models/email";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";

interface EmailDetailState {
  readonly isLoading: boolean
  readonly isSubmitting: boolean
  readonly isDeleting: boolean
  readonly isSending: boolean
  readonly emailId?: string
  readonly config: 'create' | 'update'
  readonly email?: EmailModel
  readonly error?: BaseError<any>
  readonly shouldClose: boolean
}

const emailDetailSlice = createSlice({
  name: 'email_detail',
  initialState: {
    isLoading: false,
    isSubmitting: false,
    isDeleting: false,
    isSending: false,
    config: 'create',
  } as EmailDetailState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.isSubmitting = false
      state.isDeleting = false
      state.isSending = false
      state.email = undefined
      state.emailId = undefined
      state.error = undefined
      state.shouldClose = false
      state.config = 'create'
    },
    clearError(state) {
      state.error = undefined
    },
    fetchEmail(state, action: PayloadAction<string>) {
      state.config = 'update'
      state.isLoading = true
    },
    createEmail(state, action: PayloadAction<{ email: EmailModel }>) {
      state.isSubmitting = true
    },
    updateEmail(state, action: PayloadAction<{ email: EmailModel, id: string }>) {
      state.isSubmitting = true
    },
    deleteEmail(state, action: PayloadAction<string>) {
      state.isDeleting = true
    },
    fetchEmailSucceeded(state, action: PayloadAction<EmailModel>) {
      state.isLoading = false
      state.email = action.payload
      state.emailId = action.payload.id
    },
    createEmailSucceeded(state) {
      state.isSubmitting = false
      state.shouldClose = true
    },
    updateEmailSucceeded(state) {
      state.isSubmitting = false
      state.shouldClose = true
    },
    deleteEmailSucceeded(state) {
      state.isDeleting = false
      state.shouldClose = true
    },
    fetchEmailFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('EMAIL_ERROR')
        error.localizedMessage = 'There was a problem getting this email'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    createEmailFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('EMAIL_CREATE_ERROR')
        error.localizedMessage = 'There was a problem creating this email'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    updateEmailFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('EMAIL_UPDATE_ERROR')
        error.localizedMessage = 'There was a problem updating this email'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    deleteEmailFailed(state, action: PayloadAction<Error>) {
      state.isDeleting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('EMAIL_DELETE_ERROR')
        error.localizedMessage = 'There was a problem deleting this email'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const emailDetailReducer = emailDetailSlice.reducer

export const {
  reset,
  fetchEmail,
  fetchEmailFailed,
  fetchEmailSucceeded,
  createEmail,
  createEmailFailed,
  createEmailSucceeded,
  updateEmail,
  updateEmailFailed,
  updateEmailSucceeded,
  deleteEmail,
  deleteEmailFailed,
  deleteEmailSucceeded,
  clearError
} = emailDetailSlice.actions

export const selectError = (state: RootState) => state.emailDetail.error
export const selectEmail = (state: RootState) => state.emailDetail.email
export const selectConfig = (state: RootState) => state.emailDetail.config
export const selectIsLoading = (state: RootState) => state.emailDetail.isLoading
export const selectIsSubmitting = (state: RootState) => state.emailDetail.isSubmitting
export const selectIsDeleting = (state: RootState) => state.emailDetail.isDeleting
export const selectShouldClose = (state: RootState) => state.emailDetail.shouldClose