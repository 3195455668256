import {EmailJson, EmailModel} from "./email";
import {PromptJson, PromptModel} from "./prompt";
import {PushNotificationJson, PushNotificationModel} from "./pushNotification";

export type MessageCampaignFilterType = 'in' | 'out'
export type MessageCampaignNodeType = 'email' | 'filter' | 'push' | 'prompt'

export interface MessageCampaignFilterJson {
  mixpanel_cohorts?: string[]
  type?: MessageCampaignFilterType
}

export interface MessageCampaignNodeJson {
  _id?: string
  name?: string
  path?: string
  parent?: string
  execute_after?: number
  type?: MessageCampaignNodeType
  email?: EmailJson
  push?: PushNotificationJson
  prompt?: PromptJson
  filter?: MessageCampaignFilterJson
  created_at?: Date
  updated_at?: Date
}

export class MessageCampaignFilterModel {
  cohortIds: string[] = []
  type?: MessageCampaignFilterType

  constructor(model?: MessageCampaignFilterModel) {
    if (!model) return

    this.cohortIds = model.cohortIds
    this.type = model.type

    return this
  }

  static fromJson(json?: MessageCampaignFilterJson): MessageCampaignFilterModel | undefined {
    if (!json) return

    const model = new MessageCampaignFilterModel()

    model.cohortIds = json.mixpanel_cohorts ?? []
    model.type = json.type

    return model
  }

  toJson(): MessageCampaignFilterJson {
    return  {
      mixpanel_cohorts: this.cohortIds ?? [],
      type: this.type
    }
  }
}

export class MessageCampaignNodeModel {
  id?: string
  name?: string
  path?: string
  parent?: string
  executeAfter?: number
  type?: MessageCampaignNodeType
  email?: EmailModel
  push?: PushNotificationModel
  prompt?: PromptModel
  filter?: MessageCampaignFilterModel
  createdAt?: Date
  updatedAt?: Date

  constructor(model?: MessageCampaignNodeModel) {
    if (!model) return

    this.id = model.id
    this.name = model.name
    this.path = model.path
    this.parent = model.parent
    this.executeAfter = model.executeAfter
    this.type = model.type
    this.email = model.email && new EmailModel(model.email)
    this.push = model.push && new PushNotificationModel(model.push)
    this.prompt = model.prompt && new PromptModel(model.prompt)
    this.filter = model.filter && new MessageCampaignFilterModel(model.filter)
    this.createdAt = model.createdAt
    this.updatedAt = model.updatedAt

    return this
  }

  static fromJson(json?: MessageCampaignNodeJson): MessageCampaignNodeModel | undefined {
    if (!json) return

    const model = new MessageCampaignNodeModel()

    model.id = json._id
    model.name = json.name
    model.path = json.path
    model.parent = json.parent
    model.executeAfter = json.execute_after
    model.type = json.type
    model.email = json.email && EmailModel.fromJson(json.email)
    model.push = json.push && PushNotificationModel.fromJson(json.push)
    model.prompt = json.prompt && PromptModel.fromJson(json.prompt)
    model.filter = json.filter && MessageCampaignFilterModel.fromJson(json.filter)
    model.createdAt = json.created_at
    model.updatedAt = json.updated_at

    return model
  }

  toJson(): MessageCampaignNodeJson {
    return {
      _id: this.id,
      name: this.name,
      path: this.path,
      parent: this.parent,
      execute_after: this.executeAfter,
      type: this.type,
      email: this.email?.toJson(),
      push: this.push?.toJson(),
      prompt: this.prompt?.toJson(),
      filter: this.filter?.toJson(),
      created_at: this.createdAt,
      updated_at: this.updatedAt,
    }
  }
}