export interface AuthResponseJson {
  access_token?: string
  access_token_exp_date?: Date
  realm_token?: string
  refresh_token?: string
  refresh_token_exp_date?: Date
  is_new_user?: boolean
}

export class AuthResponseModel {
  accessToken?: string
  accessTokenExpDate?: Date
  realmToken?: string
  refreshToken?: string
  refreshTokenExpDate?: Date
  isNewUser?: boolean

  static fromJson(json?: AuthResponseJson): AuthResponseModel | undefined {
    if (!json) return

    const model = new AuthResponseModel()

    model.accessToken = json.access_token
    model.accessTokenExpDate = json.access_token_exp_date
    model.realmToken = json.realm_token
    model.refreshToken = json.refresh_token
    model.refreshTokenExpDate = json.refresh_token_exp_date
    model.isNewUser = json.is_new_user

    return model
  }
}