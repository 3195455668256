import React, {Fragment} from "react";
import {Popover, Transition} from '@headlessui/react'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectCartMenuIsOpen, setCartMenuOpen} from "../../redux/navigation/navigationSlice";
import {NavBarNegativeInset} from "../navBars/NavBarMain";
import {CartView} from "./CartView";

export const CartMenu: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectCartMenuIsOpen)

  return (
    <div>
      <Popover>
        {({ open }) => {
          open = isOpen

          return <>
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`fixed z-30 right-8 transform mt-3 w-screen h-0 max-w-lg ${NavBarNegativeInset}`} static>
                <div className="transform rounded-lg shadow-lg ring-1 bg-white ring-fg-gray-surface">
                  <CartView isMenu={true} onClose={() => dispatch(setCartMenuOpen(false))} />
                </div>
              </Popover.Panel>
            </Transition>
          </>
        }}
      </Popover>
    </div>
  )
}