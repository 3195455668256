import React, {useState, useEffect, useMemo} from 'react'
import {BehaviorSubject, debounceTime} from "rxjs";

export default function useIsWindowFocused(): boolean {
  const [windowIsActive, setWindowIsActive] = useState(true)
  const windowActiveSubject = useMemo(() => new BehaviorSubject(false), [])

  windowActiveSubject.pipe(debounceTime(1000))
    .subscribe(isActive => {
      setWindowIsActive(isActive)
    })

  const handleActivity = React.useCallback((e: { type: string }) => {
    if (e?.type == 'focus') {
      windowActiveSubject.next(true)
    }
    if (e?.type == 'blur') {
      windowActiveSubject.next(false)
    }
    if (e?.type == 'visibilitychange') {
      if (document.hidden) {
        windowActiveSubject.next(false)
      } else {
        windowActiveSubject.next(true)
      }
    }
  }, [windowActiveSubject])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleActivity)
    document.addEventListener('blur', handleActivity)
    window.addEventListener('blur', handleActivity)
    window.addEventListener('focus', handleActivity)
    document.addEventListener('focus', handleActivity)

    return () => {
      window.removeEventListener('blur', handleActivity)
      document.removeEventListener('blur', handleActivity)
      window.removeEventListener('focus', handleActivity)
      document.removeEventListener('focus', handleActivity)
      document.removeEventListener('visibilitychange', handleActivity)
    }
  }, [handleActivity])

  return windowIsActive
}