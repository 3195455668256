import {BodyType, DietGoal, Gender, HeightUnit} from "../models/fitnessProfile";
import {UnitsUtils} from "./unitsUtils";

export const MacroSurveyUtils = {
    getMaxWeightLossRate(bodyType: BodyType): number {
        switch (bodyType) {
            case BodyType.lean:
                return -0.006
            case BodyType.moderatelyLean:
                return -0.008
            case BodyType.average:
                return -0.010
            case BodyType.moderatelyOverweight:
                return -0.012
            case BodyType.overweight:
                return -0.014
            case BodyType.obese:
                return -0.016
        }
    },

    getMinWeightLossRate(deadlineMonths: number, bodyType: BodyType): number {
        const maxWeightLossRate = this.getMaxWeightLossRate(bodyType)

        switch (deadlineMonths) {
            case 1:
                return maxWeightLossRate * 0.65
            case 2:
                return maxWeightLossRate * 0.45
            case 3:
                return maxWeightLossRate * 0.35
            case 4:
                return maxWeightLossRate * 0.30
            default:
                return maxWeightLossRate * 0.27
        }
    },

    getFfmi(
      height: number,
      currentWeight: number,
      bodyType: BodyType,
      gender: Gender
    ): number {
        let bodyFat = 0.1 + (bodyType - 1) * 0.04

        if (gender == Gender.female) {
            bodyFat += 0.07
        }

        return (currentWeight * (1 - bodyFat) / 2.2) / (height * 0.0254 * height * 0.0254)
    },

    getIdealGoalWeightInPounds(
      height: number,
      currentWeight: number,
      deadlineMonths: number,
      bodyType: BodyType,
      dietGoal: DietGoal,
      gender: Gender
    ): number {
        const maxWeightLossRate = this.getMaxWeightLossRate(bodyType)
        const minWeightLossRate = this.getMinWeightLossRate(deadlineMonths, bodyType)

        const maxWeightGainRate = this.getMaxWeightGainRate(height, currentWeight, bodyType, gender)
        const minWeightGainRate = this.getMinWeightGainRate(height, currentWeight, bodyType, gender)

        switch (dietGoal) {
            case DietGoal.cut:
                return Math.round(currentWeight + (deadlineMonths * 4 * currentWeight * ((maxWeightLossRate + minWeightLossRate) / 2)))
            case DietGoal.maintenance:
                return currentWeight
            default:
                return Math.round(currentWeight + (deadlineMonths * 4 * currentWeight * ((maxWeightGainRate + minWeightGainRate) / 2)))
        }
    },

    getGoalWeightsInPounds(
      currentWeight: number,
      deadlineMonths: number,
      bodyType: BodyType,
      dietGoal: DietGoal,
      height: number,
      gender: Gender
    ): number[] {
        const goalWeights: number[] = []
        const idealGoalWeight = this.getIdealGoalWeightInPounds(
          height,
          currentWeight,
          deadlineMonths,
          bodyType,
          dietGoal,
          gender
        )

        const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

        switch (dietGoal) {
            case DietGoal.cut: {
                range(Math.floor(idealGoalWeight), Math.round(currentWeight), 1).forEach(weight => {
                    goalWeights.push(weight)
                })
                if (!goalWeights.length) {
                    return [Math.round(currentWeight)]
                } else {
                    return goalWeights.reverse()
                }
            }
            case DietGoal.maintenance:
                return [currentWeight]
            default: {
                const maxWeight = idealGoalWeight + 2 * (idealGoalWeight - currentWeight) + 15
                range(Math.floor(idealGoalWeight), Math.round(maxWeight), 1).forEach(weight => {
                    goalWeights.push(weight)
                })
                if (!goalWeights.length) {
                    return [Math.round(currentWeight)]
                } else {
                    return goalWeights
                }
            }
        }
    },

    getHeightInInchesFromImperial(heightFeetString?: string, heightInchesString?: string): number | undefined {
        if (!heightFeetString || !heightInchesString) return
        return (parseFloat(heightFeetString) * 12.0 + parseFloat(heightInchesString))
    },

    getHeightInInchesFromMetric(heightCentimetersString?: string): number | undefined {
        if (!heightCentimetersString) return

        const centimeters = parseFloat(heightCentimetersString)
        return UnitsUtils.convertHeight(centimeters, HeightUnit.metric, HeightUnit.imperial)
    },

    getMinWeightGainRate(
      height: number,
      currentWeight: number,
      bodyType: BodyType,
      gender: Gender
    ): number {
        const ffmi = this.getFfmi(height, currentWeight, bodyType, gender)

        if (ffmi < 19.5) {
            return 0.0100 / 4.0
        } else if (ffmi >= 19.5 && ffmi <= 22.0) {
            return 0.0050 / 4.0
        } else {
            return 0.0025 / 4.0
        }
    },

    getMaxWeightGainRate(
      height: number,
      currentWeight: number,
      bodyType: BodyType,
      gender: Gender
    ): number {
        const ffmi = this.getFfmi(height, currentWeight, bodyType, gender)

        if (ffmi < 19.5) {
            return (0.015 * 1.25) / 4.0
        } else if (ffmi >= 19.5 && ffmi <= 22.0) {
            return (0.010 * 1.25) / 4
        } else {
            return (0.005 * 1.25) / 4
        }
    }
}