import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  decrementItem,
  incrementItem,
  selectConfig, selectIsLoading, selectIsUpdatingItem, selectLineItem, selectProduct,
  selectQuantity, selectTotalPrice,
  setQuantity, updateCartWithItem
} from "../../redux/product/productSlice";
import {useTranslation} from "next-i18next";
import {MinusIcon, PlusIcon, TrashFilledIcon} from "../icons";
import {BodyInput} from "../inputs";
import {CurrencyUtils} from "../../utils/currencyUtils";
import {CartButton} from "../buttons/CartButton";
import {Button} from "../buttons/Button";
import {useRouter} from "next/router";
import {selectLoginStatus} from "../../redux/auth/authSlice";
import {useModalRouting} from "../../utils/useModalRouting";

export const ProductDetailToolbarView: React.FC<{ productId: string }> = ({ productId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const quantity = useAppSelector(selectQuantity)
  const lineItem = useAppSelector(selectLineItem)
  const config = useAppSelector(selectConfig)
  const isUpdating = useAppSelector(selectIsUpdatingItem)
  const isLoading = useAppSelector(selectIsLoading)
  const totalPrice = (lineItem?.quantity ?? 0) * (lineItem?.price ?? 0)

  const loginStatus = useAppSelector(selectLoginStatus)

  const router = useRouter()
  const { createRoute } = useModalRouting();

  const [quantityText, setQuantityText] = useState(quantity.toString())

  let buttonTitle: string;
  if (config === 'adding') {
    buttonTitle = t('store:store_button_add_to_cart')
  } else if (quantity > 0) {
    buttonTitle = t('store:store_button_update_order')
  } else {
    buttonTitle = t('store:store_button_remove_from_cart')
  }
  const priceText = CurrencyUtils.stringFromPrice(totalPrice, lineItem?.currencyCode)

  useEffect(() => {
    setQuantityText(quantity.toString())
  }, [quantity])


  return <div>
    <div className="grid grid-cols-17 lg:grid-cols-3 gap-2 lg:gap-8 items-center justify-center">
      <div className="flex col-span-7 lg:col-span-1 flex-row space-x-1 lg:space-x-2 items-center">
        <button onClick={e => {
          e.stopPropagation()
          dispatch(decrementItem())
        }} className="flex items-center justify-center hover:bg-fg-gray-surface-dark bg-fg-gray-surface h-8 w-8 lg:h-10 lg:w-10 ring-inset flex-none rounded-full center">
          <MinusIcon className="h-3 w-3 lg:h-4 lg:w-4"/>
        </button>
        <div className="flex-grow">
          <BodyInput
            value={quantityText}
            className="text-center"
            type="number"
            onChange={e => {
              const value = e.target.value.replace(/\D/g, "")
              const number = parseInt(value)

              if (config === "adding" && value.length > 0) {
                if (number <= 0) {
                  setQuantityText("1")
                  dispatch(setQuantity(1))
                } else {
                  setQuantityText(value)
                  dispatch(setQuantity(number))
                }
              } else if (value.length > 0) {
                if (number < 0) {
                  setQuantityText("0")
                  dispatch(setQuantity(0))
                } else {
                  setQuantityText(value)
                  dispatch(setQuantity(number))
                }
              } else {
                setQuantityText(value)
                dispatch(setQuantity(config === 'adding' ? 1 : 0))
              }
            }}
          />
        </div>
        <button onClick={e => {
          e.stopPropagation()
          dispatch(incrementItem())
        }} className="flex items-center justify-center hover:bg-fg-gray-surface-dark bg-fg-gray-surface h-8 w-8 lg:h-10 lg:w-10 ring-inset flex-none rounded-full center">
          <PlusIcon className="h-3 w-3 lg:h-4 lg:w-4"/>
        </button>
      </div>
      <div className="col-span-10 lg:col-span-2">
        <div className="capitalize hidden lg:block">
          <CartButton
            onClick={() => {
              if (loginStatus === 'not_logged_in') {
                router.push(
                  createRoute({ name: 'login', query: { config: 'get_started' }, returnTo: { name: 'product_detail', query: { product_id: productId }, as: `/product/${productId}` }}),
                  undefined,
                  { shallow: true }
                );
              } else {
                dispatch(updateCartWithItem())
              }
            }}
            buttonStyle={quantity ? 'primary' : 'destructive'}
            isLoading={isUpdating || isLoading || router.isFallback}
            total={priceText}
            buttonTitle={buttonTitle} />
        </div>
        <div className="lg:hidden">
          <Button
            isFullWidth={true}
            buttonStyle={quantity ? 'primary' : 'destructive'}
            isLoading={isUpdating || isLoading || router.isFallback}
            onClick={() => {
              if (loginStatus === 'not_logged_in') {
                router.push(
                  createRoute({ name: 'login', query: { config: 'get_started' }, returnTo: { name: 'product_detail', query: { product_id: productId }, as: `/product/${productId}` }}),
                  undefined,
                  { shallow: true }
                );
              } else {
                dispatch(updateCartWithItem())
              }
            }}
          >
            <span className="capitalize">{quantity ? `${buttonTitle} – ${priceText}` : buttonTitle}</span>
          </Button>
        </div>
      </div>
    </div>
  </div>
}