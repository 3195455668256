import {from, Observable} from "rxjs";
import {UserModel} from "../models/user";
import {api, handleApiError} from "./api";
import {SetContactInfoPayload} from "../models/setContactInfoPayload";
import {OnboardingLinkResponseModel} from "../models/onboardingLinkResponse";

export const UserApi = {
  getUser (): Observable<UserModel> {
    const request: Promise<UserModel> = api.get('/user/me', {
      headers: { is_authenticated: true }
    })
      .then(response => {
        return UserModel.fromJson(response.data)!
      })
      .catch(handleApiError)

    return from(request)
  },
  getOnboardingLink(): Observable<OnboardingLinkResponseModel> {
    const request: Promise<OnboardingLinkResponseModel> = api.get('/user/get-download-link', {
      headers: { is_authenticated: true }
    })
      .then(response => {
        return OnboardingLinkResponseModel.fromJson(response.data)!
      })
      .catch(handleApiError)

    return from(request)
  },
  setContactInfo(payload: SetContactInfoPayload): Observable<void> {
    const request: Promise<void> = api.post('/user/set-contact-info', payload, {
      headers: { is_authenticated: true }
    })
      .then(() => { })
      .catch(handleApiError)

    return from(request)
  },
  updateAppReferrer(referrer: string): Observable<void> {
    const request: Promise<void> = api.post('/user/set-referrer', { referrer }, {
      headers: { is_authenticated: true }
    })
      .then(() => { })
      .catch(handleApiError)

    return from(request)
  }
}