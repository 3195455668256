import {NutritionSurveyPayload} from "../models/nutritionSurveyPayload";
import {from, Observable} from "rxjs";
import {NutritionProtocolModel} from "../models/nutritionProtocol";
import {api, handleApiError} from "./api";

export const NutritionProtocolApi = {
  saveNutritionSurvey(payload: NutritionSurveyPayload):Observable<NutritionProtocolModel> {
    const request: Promise<NutritionProtocolModel> = api.post('/user/save-survey', payload, {
      headers: { is_authenticated: true }
    }).then(response => NutritionProtocolModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  }
}