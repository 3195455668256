import {from, Observable} from "rxjs";
import {api, handleApiError} from "./api";
import {ProductJson, ProductModel} from "../models/product";
import {SalesOrderResponseModel} from "../models/salesOrderResponse";

export const OrderApi = {
  getOrderedProducts: (query?: string): Observable<ProductModel[]> => {
    const params = { query }
    const request: Promise<ProductModel[]> = api.get(`/orders/get-ordered-menu-items`, { params, headers: { is_authenticated: true } })
      .then(response => {
        const jsons: ProductModel[] = response.data.map((p: ProductJson) => ProductModel.fromJson(p)!)
        return jsons
      })
      .catch(handleApiError)

    return from(request)
  },
  getOrders: (pageNumber: number): Observable<SalesOrderResponseModel> => {
    const request: Promise<SalesOrderResponseModel> = api.get(`/v2/orders/get-orders-for-customer`, {
      headers: { is_authenticated: true },
      params: { page_number: pageNumber, max_results: 10 }
    })
      .then(response => SalesOrderResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  }
}