export type SegmentedControlItem<T> = { value: T, id: string }

export interface SegmentedControlProps<T> {
  selectedItemId: string
  className?: string
  items: SegmentedControlItem<T>[]
  renderItem: (item: SegmentedControlItem<T>, index: number) => React.ReactElement
  onItemClick: (item: SegmentedControlItem<T>) => void
}

export const SegmentedControl = <T extends any>(props: SegmentedControlProps<T>) => {
  const {
    selectedItemId,
    items,
    renderItem,
    onItemClick
  } = props

  let width; let gridCols;
  if (items.length === 0) {
    return <div />
  } else if (items.length === 1) {
    width = 'w-full'
    gridCols = 'grid-cols-1'
  } else if (items.length === 2) {
    width = 'w-1/2'
    gridCols = 'grid-cols-2'
  } else if (items.length === 3) {
    width = 'w-1/3'
    gridCols = 'grid-cols-3'
  } else if (items.length === 4) {
    width = 'w-1/4'
    gridCols = 'grid-cols-4'
  }

  let translation;
  if (selectedItemId === items[0]?.id) {
    translation = 'translate-x-0'
  } else if (selectedItemId === items[1]?.id) {
    translation = 'translate-x-full'
  } else if (selectedItemId === items[2]?.id) {
    translation = 'translate-x-full-2x'
  } else if (selectedItemId === items[3]?.id) {
    translation = 'translate-x-full-3x'
  }

  return (
    <div className={`bg-fg-gray-surface border-2 border-fg-gray-surface rounded-full ${props.className}`}>
      <div className={`relative grid gap-0 items-center ${gridCols}`}>

        <div className={`absolute inset-y-0 flex rounded-full bg-fg-black transition-all ease-in-out duration-200 transform shadow ${width} ${translation}`} />

        {items.map((item, index) => {
          return (
            <div
              key={item.id}
              onClick={() => onItemClick(item)}
              className={`transition-all duration-200 relative flex-1 items-center align-middle justify-center cursor-pointer ${item.id === selectedItemId ? 'text-white' : 'text-fg-black'}`}
            >
              <div className="py-2 px-4">{renderItem(item, index)}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}