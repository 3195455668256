import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BaseError} from "../../errors/baseError";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {SignUpPayload} from "../../models/signupPayload";
import {LoginWithEmailPayload} from "../../models/loginWithEmailPayload";
import {AuthResponseModel} from "../../models/authResponse";
import {SendPasswordResetRequestPayload} from "../../models/sendPasswordResetRequestPayload";
import {ApiError} from "../../errors/apiError";
import {UserModel} from "../../models/user";
import {fetchUserFailed, fetchUserSucceeded} from "../user/userSlice";
import {submitFailed} from "../survey/macroSurveySlice";

// State
interface AuthState {
  loginStatus?: 'logged_in' | 'logged_in_anon' | 'not_logged_in'
  isAuthenticatingSession: boolean
  isSigningUpWithEmail: boolean
  isLoggingInWithEmail: boolean
  isLoggingInWithFacebook: boolean
  isLoggingInWithApple: boolean
  isResettingPassword: boolean
  isLoggingOut: boolean
  shouldCloseLoginModal: boolean
  error?: BaseError<any>
  authResponse?: AuthResponseModel
}

// Slice
export const authSlice = createSlice({
  name: "auth",
  initialState: { isAuthenticatingSession: true, isLoggingOut: false, shouldCloseLoginModal: false } as AuthState,
  reducers: {
    reset(state) {
      state.error = undefined
      state.isSigningUpWithEmail = false
      state.isLoggingInWithEmail = false
      state.isLoggingInWithFacebook = false
      state.isResettingPassword = false
      state.shouldCloseLoginModal = false
    },
    authenticateSession(state) {
      state.isAuthenticatingSession = true
    },
    sendPasswordReset(state, action: PayloadAction<SendPasswordResetRequestPayload>) {
      state.isResettingPassword = true
    },
    signUpWithEmail(state, action: PayloadAction<SignUpPayload>) {
      state.isSigningUpWithEmail = true
    },
    signUpWithEmailAndSubmitSurvey(state, action: PayloadAction<SignUpPayload>) {
      state.isSigningUpWithEmail = true
    },
    signUpWithEmailSucceeded(state, action: PayloadAction<{ auth: AuthResponseModel, user?: UserModel | null }>) {
      state.isSigningUpWithEmail = false

      state.loginStatus = action.payload.user?.roles.includes('anonymous') ? 'logged_in_anon' : 'logged_in'
      state.authResponse = action.payload.auth
      state.shouldCloseLoginModal = true
    },
    signUpWithEmailFailed(state, action: PayloadAction<Error>) {
      state.isSigningUpWithEmail = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('EMAIL_SIGNUP_ERROR')
        error.localizedMessage = { key: 'auth:auth_alert_message_error_email_sign_up' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    loginWithEmail(state, action: PayloadAction<LoginWithEmailPayload>) {
      state.isLoggingInWithEmail = true
    },
    loginWithEmailAndSubmitSurvey(state, action: PayloadAction<LoginWithEmailPayload>) {
      state.isLoggingInWithEmail = true
    },
    loginWithEmailSucceeded(state, action: PayloadAction<{ auth: AuthResponseModel, user?: UserModel | null }>) {
      state.isLoggingInWithEmail = false

      state.loginStatus = action.payload.user?.roles.includes('anonymous') ? 'logged_in_anon' : 'logged_in'
      state.authResponse = action.payload.auth
      state.shouldCloseLoginModal = true
    },
    loginWithEmailFailed(state, action: PayloadAction<Error>) {
      state.isLoggingInWithEmail = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('EMAIL_SIGN_IN_ERROR')
        error.localizedMessage = { key: 'auth:auth_alert_message_error_email_sign_in' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    loginWithFacebook(state) {
      state.isLoggingInWithFacebook = true
    },
    loginWithFacebookAndSubmitSurvey(state) {
      state.isLoggingInWithFacebook = true
    },
    loginWithFacebookSucceeded: (state, action: PayloadAction<{ auth: AuthResponseModel, user?: UserModel | null }>) => {
      state.isLoggingInWithFacebook = false

      state.loginStatus = action.payload.user?.roles.includes('anonymous') ? 'logged_in_anon' : 'logged_in'
      state.authResponse = action.payload.auth
      state.shouldCloseLoginModal = true
    },
    loginWithFacebookFailed(state, action: PayloadAction<Error>) {
      state.isLoggingInWithFacebook = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('FACEBOOK_LOGIN_ERROR')
        error.localizedMessage = { key: 'auth:auth_alert_message_error_facebook_login' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    loginWithApple(state) {
      state.isLoggingInWithApple = true
    },
    loginWithAppleAndSubmitSurvey(state) {
      state.isLoggingInWithApple = true
    },
    loginWithAppleSucceeded: (state, action: PayloadAction<{ auth: AuthResponseModel, user?: UserModel | null }>) => {
      state.isLoggingInWithApple = false

      state.loginStatus = action.payload.user?.roles.includes('anonymous') ? 'logged_in_anon' : 'logged_in'
      state.authResponse = action.payload.auth
      state.shouldCloseLoginModal = true
    },
    loginWithAppleFailed(state, action: PayloadAction<Error>) {
      state.isLoggingInWithApple = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('APPLE_LOGIN_ERROR')
        error.localizedMessage = { key: 'auth:auth_alert_message_error_apple_login' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    logout(state) {
      state.isLoggingOut = true
    },
    logoutSucceeded(state) {
      state.isLoggingOut = false
      state.loginStatus = 'not_logged_in'
    },
    logoutFailed(state, action: PayloadAction<Error>) {
      state.isLoggingOut = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('LOGOUT_ERROR')
        error.localizedMessage = { key: 'auth:auth_alert_message_error_logging_out' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    sendPasswordResetSucceeded(state) {
      state.isResettingPassword = false
      state.shouldCloseLoginModal = true
    },
    sendPasswordResetFailed(state, action: PayloadAction<Error>) {
      state.isResettingPassword = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PASSWORD_RESET_ERROR')
        error.localizedMessage = { key: 'auth:auth_alert_message_error_resetting_password' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    authenticateSessionSucceeded(state, action: PayloadAction<{ auth: AuthResponseModel, user?: UserModel | null }>) {
      state.isAuthenticatingSession = false
      state.loginStatus = action.payload.user?.roles.includes('anonymous') ? 'logged_in_anon' : 'logged_in'
      state.authResponse = action.payload.auth
    },
    authenticateSessionFailed(state, action: PayloadAction<Error>) {
      state.isAuthenticatingSession = false

      if (action.payload instanceof ApiError && action.payload.code === 'UNAUTHORIZED') {
        state.loginStatus = 'not_logged_in'
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserFailed, (state, action) => {
      if (action.payload instanceof ApiError && action.payload.code === 'UNAUTHORIZED') {
        state.loginStatus = 'not_logged_in'
      }
    }).addCase(fetchUserSucceeded, (state, action) => {
      state.loginStatus = action.payload?.roles.includes('anonymous') ? 'logged_in_anon' : 'logged_in'
    }).addCase(submitFailed, (state, action) => {
      state.isLoggingInWithEmail = false
      state.isLoggingInWithFacebook = false
      state.isLoggingInWithApple = false
      state.isSigningUpWithEmail = false
    })
  }
});

// Reducer
export const authReducer = authSlice.reducer

// Actions
export const {
  reset,
  authenticateSession,
  signUpWithEmailAndSubmitSurvey,
  signUpWithEmail,
  signUpWithEmailSucceeded,
  signUpWithEmailFailed,
  loginWithEmailAndSubmitSurvey,
  loginWithEmail,
  loginWithEmailSucceeded,
  loginWithEmailFailed,
  loginWithFacebookAndSubmitSurvey,
  loginWithFacebook,
  loginWithFacebookSucceeded,
  loginWithFacebookFailed,
  loginWithAppleAndSubmitSurvey,
  loginWithApple,
  loginWithAppleFailed,
  loginWithAppleSucceeded,
  logout,
  logoutSucceeded,
  logoutFailed,
  authenticateSessionSucceeded,
  authenticateSessionFailed,
  sendPasswordReset,
  sendPasswordResetSucceeded,
  sendPasswordResetFailed,
} = authSlice.actions;

// Selectors
export const selectIsSigningUpWithEmail = (state: RootState) => state.auth.isSigningUpWithEmail;
export const selectIsLoggingInWithEmail = (state: RootState) => state.auth.isLoggingInWithEmail;
export const selectIsLoggingInWithApple = (state: RootState) => state.auth.isLoggingInWithApple;
export const selectIsLoggingInWithFacebook = (state: RootState) => state.auth.isLoggingInWithFacebook;
export const selectIsResettingPassword = (state: RootState) => state.auth.isResettingPassword;
export const selectIsAuthenticatingSession = (state: RootState) => state.auth.isAuthenticatingSession;
export const selectError = (state: RootState) => state.auth.error;
export const selectShouldClose = (state: RootState) => state.auth.shouldCloseLoginModal;
export const selectLoginStatus = (state: RootState) => state.auth.loginStatus;