import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {Modal} from "../modals/Modal";
import {ContactInfoEntryView} from "./ContactInfoEntryView";

export const ContactInfoEntryModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();

  return <Modal
    className="md:max-w-xl"
    isOpen={isOpen('contact_info_entry')}
    onClose={async () => {
      await onClose()
    }}
  >
    <ContactInfoEntryView onClose={onClose} />
  </Modal>
}