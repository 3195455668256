import {BaseError} from "../../errors/baseError";
import {MessageCampaignModel} from "../../models/messageCampaign";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {MessageCampaignsResponseModel} from "../../models/messageCampaignsResponse";
import {RootState} from "../../app/store";

interface CampaignListState {
  readonly isLoading: boolean
  readonly isLoadingMore: boolean
  readonly campaigns: MessageCampaignModel[]
  readonly totalCampaigns: number
  readonly currentPage: number
  readonly isUpdatingCampaignMap: { [key: string]: boolean }
  readonly error?: BaseError<any>
}

export const campaignListSlice = createSlice({
  name: 'campaign_list',
  initialState: {
    isLoading: false,
    isLoadingMore: false,
    campaigns: [],
    totalCampaigns: 0,
    currentPage: 1,
    isUpdatingCampaignMap: {},
  } as CampaignListState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.isLoadingMore = false
      state.campaigns = []
      state.error = undefined
      state.isUpdatingCampaignMap = {}
      state.currentPage = 1
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    fetchCampaigns(state, action: PayloadAction<number>) {
      state.isLoadingMore = true
      if (!state.campaigns.length) {
        state.isLoading = true
      }
    },
    activateCampaign(state, action: PayloadAction<string>) {
      state.isUpdatingCampaignMap[action.payload] = true
    },
    activateCampaignSucceeded(state, action: PayloadAction<string>) {
      state.isUpdatingCampaignMap[action.payload] = false
    },
    activateCampaignFailed(state, action: PayloadAction<{ error: Error, id: string }>) {
      state.isUpdatingCampaignMap[action.payload.id] = false

      if (action.payload.error instanceof BaseError) {
        state.error = action.payload.error as any
      } else {
        const error = new GenericError('CAMPAIGN_ACTIVATE_ERROR')
        error.localizedMessage = 'There was a problem activating this campaign'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload.error
        state.error = error as any
      }
    },
    deleteCampaign(state, action: PayloadAction<string>) {
      state.isUpdatingCampaignMap[action.payload] = true
    },
    deleteCampaignSucceeded(state, action: PayloadAction<string>) {
      state.isUpdatingCampaignMap[action.payload] = false
    },
    deleteCampaignFailed(state, action: PayloadAction<{ error: Error, id: string }>) {
      state.isUpdatingCampaignMap[action.payload.id] = false

      if (action.payload.error instanceof BaseError) {
        state.error = action.payload.error as any
      } else {
        const error = new GenericError('CAMPAIGN_DELETE_ERROR')
        error.localizedMessage = 'There was a problem deleting this campaign'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload.error
        state.error = error as any
      }
    },
    fetchCampaignsSucceeded(state, action: PayloadAction<MessageCampaignsResponseModel>) {
      if (state.isLoading) {
        state.campaigns = action.payload.campaigns
      } else {
        state.campaigns = state.campaigns.concat(action.payload.campaigns)
      }

      state.totalCampaigns = action.payload.totalResults
      state.isLoading = false
      state.isLoadingMore = false
    },
    fetchCampaignsFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false
      state.isLoadingMore = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('CAMPAIGN_ERROR')
        error.localizedMessage = 'There was a problem getting campaigns'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
  }
})

export const campaignListReducer = campaignListSlice.reducer

export const {
  reset,
  fetchCampaigns,
  fetchCampaignsFailed,
  fetchCampaignsSucceeded,
  setCurrentPage,
  deleteCampaign,
  deleteCampaignFailed,
  deleteCampaignSucceeded,
  activateCampaign,
  activateCampaignFailed,
  activateCampaignSucceeded
} = campaignListSlice.actions

export const selectError = (state: RootState) => state.campaignList.error
export const selectCampaigns = (state: RootState) => state.campaignList.campaigns
export const selectIsLoading = (state: RootState) => state.campaignList.isLoading
export const selectIsLoadingMore = (state: RootState) => state.campaignList.isLoadingMore
export const selectTotalCampaigns = (state: RootState) => state.campaignList.totalCampaigns
export const selectCurrentPage = (state: RootState) => state.campaignList.currentPage
export const selectIsUpdating = (state: RootState, id?: string) => (id ? state.campaignList.isUpdatingCampaignMap[id] : false)
