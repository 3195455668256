import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {NutritionTargetModel} from "../../models/nutritionTarget";

interface MacroPlanState {
  error?: BaseError<any>
  isLoading: boolean
  nutritionTarget?: NutritionTargetModel | null
}

export const macroPlanSlice = createSlice({
  name: 'macro_plan',
  initialState: {
    isLoading: false
  } as MacroPlanState,
  reducers: {
    reset(state) {
      state.error = undefined
      state.nutritionTarget = undefined
      state.isLoading = false
    },
    fetchNutritionTarget(state) {
      state.isLoading = true
    },
    fetchNutritionTargetSucceeded(state, action: PayloadAction<NutritionTargetModel | null | undefined>) {
      state.isLoading = false
      state.nutritionTarget = action.payload
    },
    fetchNutritionTargetFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('MACRO_PLAN_ERROR')
        error.localizedMessage = { key: 'macro_plan:macro_plan_error_message_getting_plan' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const macroPlanReducer = macroPlanSlice.reducer

export const {
  reset,
  fetchNutritionTarget,
  fetchNutritionTargetFailed,
  fetchNutritionTargetSucceeded
} = macroPlanSlice.actions

export const selectError = (state: RootState) => state.macroPlan.error
export const selectIsLoading = (state: RootState) => state.macroPlan.isLoading
export const selectNutritionTarget = (state: RootState) => state.macroPlan.nutritionTarget
