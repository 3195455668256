import {RootEpic} from "../../app/store";
import {catchError, filter, map, mergeMap, of} from "rxjs";
import {AffiliateService} from "../../services/affiliateService";
import {logErrorRx} from "../../utils/logError";
import {
  fetchAffiliates,
  fetchAffiliatesFailed,
  fetchAffiliatesSucceeded,
  updateAffiliateStatus,
  updateAffiliateStatusFailed,
  updateAffiliateStatusSucceeded
} from "./affiliateAdminSlice";

export const fetchAffiliatesEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchAffiliates.match),
    mergeMap(action => {
      return AffiliateService.fetchAllAffiliates(action.payload)
        .pipe(
          map(fetchAffiliatesSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchAffiliatesFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const updateAffiliateStatusEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(updateAffiliateStatus.match),
    mergeMap(action => {
      return AffiliateService.setAffiliateStatus(action.payload)
        .pipe(
          map(() => updateAffiliateStatusSucceeded(action.payload)),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: updateAffiliateStatusFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}