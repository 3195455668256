import {BaseError} from "../../errors/baseError";
import {PushNotificationModel} from "../../models/pushNotification";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";

interface PushNotificationDetailState {
  readonly isLoading: boolean
  readonly isSubmitting: boolean
  readonly isDeleting: boolean
  readonly isSending: boolean
  readonly pushNotificationId?: string
  readonly config: 'create' | 'update'
  readonly pushNotification?: PushNotificationModel
  readonly error?: BaseError<any>
  readonly shouldClose: boolean
}

const pushNotificationDetailSlice = createSlice({
  name: 'push_notification_detail',
  initialState: {
    isLoading: false,
    isSubmitting: false,
    isDeleting: false,
    isSending: false,
    config: 'create',
  } as PushNotificationDetailState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.isSubmitting = false
      state.isDeleting = false
      state.isSending = false
      state.pushNotification = undefined
      state.pushNotificationId = undefined
      state.error = undefined
      state.shouldClose = false
      state.config = 'create'
    },
    clearError(state) {
      state.error = undefined
    },
    fetchPushNotification(state, action: PayloadAction<string>) {
      state.config = 'update'
      state.isLoading = true
    },
    createPushNotification(state, action: PayloadAction<{ pushNotification: PushNotificationModel }>) {
      state.isSubmitting = true
    },
    updatePushNotification(state, action: PayloadAction<{ pushNotification: PushNotificationModel, id: string }>) {
      state.isSubmitting = true
    },
    deletePushNotification(state, action: PayloadAction<string>) {
      state.isDeleting = true
    },
    fetchPushNotificationSucceeded(state, action: PayloadAction<PushNotificationModel>) {
      state.isLoading = false
      state.pushNotification = action.payload
      state.pushNotificationId = action.payload.id
    },
    createPushNotificationSucceeded(state) {
      state.isSubmitting = false
      state.shouldClose = true
    },
    updatePushNotificationSucceeded(state) {
      state.isSubmitting = false
      state.shouldClose = true
    },
    deletePushNotificationSucceeded(state) {
      state.isDeleting = false
      state.shouldClose = true
    },
    fetchPushNotificationFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PUSH_NOTIFICATION_ERROR')
        error.localizedMessage = 'There was a problem getting this push notification'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    createPushNotificationFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PUSH_NOTIFICATION_CREATE_ERROR')
        error.localizedMessage = 'There was a problem creating this push notification'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    updatePushNotificationFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PUSH_NOTIFICATION_UPDATE_ERROR')
        error.localizedMessage = 'There was a problem updating this push notification'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    deletePushNotificationFailed(state, action: PayloadAction<Error>) {
      state.isDeleting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PUSH_NOTIFICATION_DELETE_ERROR')
        error.localizedMessage = 'There was a problem deleting this push notification'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const pushNotificationDetailReducer = pushNotificationDetailSlice.reducer

export const {
  reset,
  fetchPushNotification,
  fetchPushNotificationFailed,
  fetchPushNotificationSucceeded,
  createPushNotification,
  createPushNotificationFailed,
  createPushNotificationSucceeded,
  updatePushNotification,
  updatePushNotificationFailed,
  updatePushNotificationSucceeded,
  deletePushNotification,
  deletePushNotificationFailed,
  deletePushNotificationSucceeded,
  clearError
} = pushNotificationDetailSlice.actions

export const selectError = (state: RootState) => state.pushNotificationDetail.error
export const selectPushNotification = (state: RootState) => state.pushNotificationDetail.pushNotification
export const selectConfig = (state: RootState) => state.pushNotificationDetail.config
export const selectIsLoading = (state: RootState) => state.pushNotificationDetail.isLoading
export const selectIsSubmitting = (state: RootState) => state.pushNotificationDetail.isSubmitting
export const selectIsDeleting = (state: RootState) => state.pushNotificationDetail.isDeleting
export const selectShouldClose = (state: RootState) => state.pushNotificationDetail.shouldClose