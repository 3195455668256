import {CurrencyCode} from "./currencyCode";

export interface CustomerJson {
  first_name?: string
  last_name?: string
  phone_number?: string
  email?: string
  _id?: string
  currency_code?: CurrencyCode
}

export class CustomerModel {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  email?: string
  id?: string
  currencyCode?: CurrencyCode

  constructor(model?: CustomerModel) {
    if (!model) return

    this.firstName = model.firstName
    this.lastName = model.lastName
    this.phoneNumber = model.phoneNumber
    this.email = model.email
    this.id = model.id
    this.currencyCode = model.currencyCode
  }

  static fromJson(json?: CustomerJson): CustomerModel | undefined {
    if (!json) return

    const model = new CustomerModel()

    model.firstName = json.first_name
    model.lastName = json.last_name
    model.phoneNumber = json.phone_number
    model.email = json.email
    model.id = json._id
    model.currencyCode = json.currency_code

    return model
  }
}