import {PromptJson, PromptModel} from "./prompt";

export interface PromptsResponseJson {
  total_results?: number
  prompts?: PromptJson[]
}

export class PromptsResponseModel {
  totalResults: number = 0
  prompts: PromptModel[] = []

  static fromJson(json?: PromptsResponseJson): PromptsResponseModel | undefined {
    if (!json) return

    const model = new PromptsResponseModel()
    model.totalResults = json.total_results ?? 0
    model.prompts = json.prompts?.map(i => PromptModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}