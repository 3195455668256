import {
  ActivityLevel,
  BodyType,
  DietaryPreference,
  DietGoal,
  FitnessProfileModel,
  Gender,
  HeightUnit,
  WeightUnit
} from "../models/fitnessProfile";
import {map, mergeMap, Observable, of} from "rxjs";
import {NutritionSurveyPayload} from "../models/nutritionSurveyPayload";
import {NutritionProtocolApi} from "../apis/nutritionProtocolApi";
import {NutritionProtocolModel} from "../models/nutritionProtocol";
import Moment from "moment";
import {Frequency, RRule, RRuleSet, Weekday} from "rrule";
import {ObjectId} from "bson";
import {realmService} from "../realm/realmService";
import {NutritionProtocolConfigModel, NutritionProtocolConfigType} from "../models/nutritionProtocolConfig";
import {NutritionTargetModel} from "../models/nutritionTarget";
import {NutritionProtocolUtils} from "../utils/nutritionProtocolUtils";
import {UserService} from "./userService";
import {LocationModel} from "../models/location";
import {RruleUtils} from "../utils/RruleUtils";

export const NutritionProtocolService = {
  saveNutritionSurvey(fitnessProfile: FitnessProfileModel): Observable<void> {
    const payload: NutritionSurveyPayload = {
      activityLevel: fitnessProfile.activityLevel ?? ActivityLevel.moderate,
      bodyType: fitnessProfile.bodyType ?? BodyType.average,
      currentWeight: Math.round(fitnessProfile.startingWeight ?? 150),
      dietHabit: fitnessProfile.dietaryPreference ?? DietaryPreference.everything,
      gender: fitnessProfile.gender ?? Gender.male,
      goal: fitnessProfile.goal ?? DietGoal.cut,
      goalWeight: fitnessProfile.goalWeight ?? 150,
      height: Math.round(fitnessProfile.height ?? 67),
      heightUnits: fitnessProfile.heightUnits ?? HeightUnit.imperial,
      timeFrame: (fitnessProfile.dietLengthWeeks ?? 12) / 4,
      weightUnits: fitnessProfile.weightUnits ?? WeightUnit.imperial
    }

    return NutritionProtocolApi.saveNutritionSurvey(payload)
      .pipe(
        mergeMap(protocol => {
          return UserService.updateFitnessProfile(fitnessProfile)
            .pipe(
              mergeMap(() => this.saveUpdatedNutritionProtocol(protocol)),
              map(() => {})
            )
        })
      )
  },

  saveUpdatedNutritionProtocol(updatedProtocol: NutritionProtocolModel): Observable<NutritionProtocolModel> {
    const startDate = Moment().startOf('day').toDate()

    const rrule = new RRule({
      freq: Frequency.DAILY,
      dtstart: startDate,
      until: null,
      wkst: Weekday.fromStr('MO'),
      interval: 1
    })

    const newProtocol = new NutritionProtocolModel()

    newProtocol.avgCalories = updatedProtocol.avgCalories
    newProtocol.avgWeight = updatedProtocol.avgWeight
    newProtocol.calcium = updatedProtocol.getNutrients().get("CALCIUM")
    newProtocol.calories = updatedProtocol.getNutrients().get("CALORIES")
    newProtocol.carbohydrate = updatedProtocol.getNutrients().get("CARBOHYDRATE")
    newProtocol.cholesterol = updatedProtocol.getNutrients().get("CHOLESTEROL")
    newProtocol.createdAt = new Date()
    newProtocol.endDate = undefined
    newProtocol.fat = updatedProtocol.getNutrients().get("FAT")
    newProtocol.fiber = updatedProtocol.getNutrients().get("FIBER")
    newProtocol.iron = updatedProtocol.getNutrients().get("IRON")
    newProtocol.potassium = updatedProtocol.getNutrients().get("POTASSIUM")
    newProtocol.protein = updatedProtocol.getNutrients().get("PROTEIN")
    newProtocol.recommendationFeedback = updatedProtocol.recommendationFeedback
    newProtocol.rrule = RruleUtils.createStringFromRrule(rrule)
    newProtocol.sodium = updatedProtocol.getNutrients().get("SODIUM")
    newProtocol.startDate = startDate
    newProtocol.sugar = updatedProtocol.getNutrients().get("SUGAR")
    newProtocol.updatedAt = new Date()
    newProtocol.nutritionProtocolId = new ObjectId().toHexString()
    newProtocol.vitaminA = updatedProtocol.getNutrients().get("VITAMIN_A")
    newProtocol.vitaminC = updatedProtocol.getNutrients().get("VITAMIN_C")
    newProtocol.weightChangeRate = updatedProtocol.weightChangeRate

    const saveProtocol = realmService.createOrModifyEntity(newProtocol)
      .pipe(
        map(() => newProtocol)
      )

    const updateOldProtocols = realmService.query<NutritionProtocolModel>(NutritionProtocolModel.schema.name)
      .fetchAll()
      .pipe(
        map(entities => entities.map(entity => new NutritionProtocolModel(entity))),
        mergeMap(nutritionProtocols => {
          const protocols = nutritionProtocols.filter(p => !p.endDate || !p.getRrule().options.until)
          const newEndDate = Moment().subtract(1, 'day').endOf('day').toDate()

          protocols.forEach((p, i) => {
            const currentStartDate = protocols[i].startDate ?? protocols[i].getRrule().options.dtstart

            const updatedRrule = new RRule({
              freq: Frequency.DAILY,
              dtstart: currentStartDate,
              until: newEndDate,
              wkst: Weekday.fromStr('MO'),
              interval: 1
            })

            protocols[i].rrule = RruleUtils.createStringFromRrule(updatedRrule)
            protocols[i].endDate = newEndDate
          })

          return realmService.createOrModifyEntities(protocols)
        })
      )

    const deleteOldProtocols = realmService.query<NutritionProtocolModel>(NutritionProtocolModel.schema.name)
      .where({ endDate: { $exists: true }})
      .fetchAll()
      .pipe(
        map(entities => entities.map(entity => new NutritionProtocolModel(entity))),
        map(protocols => protocols.filter(p => (p.startDate?.getTime() ?? 0) >= (p.endDate?.getTime() ?? 0))),
        map(protocols => protocols.map(p => p.nutritionProtocolId).flatMap(i => i ? [i] : [])),
        mergeMap(protocolIds => {
          return realmService.query<NutritionProtocolModel>(NutritionProtocolModel.schema.name)
            .where({ nutritionProtocolId: { $in: protocolIds } })
            .delete()
        })
      )

    const updateConfig = this.fetchConfig()
      .pipe(
        mergeMap(config => {
          if (config) {
            config.lastUpdatedAt = updatedProtocol.createdAt ?? new Date()
            config.type = NutritionProtocolConfigType.weekly

            return realmService.createOrModifyEntity(config)
          } else {
            return of(config)
          }
        })
      )

    return updateOldProtocols
      .pipe(
        mergeMap(() => deleteOldProtocols),
        mergeMap(() => updateConfig),
        mergeMap(() => saveProtocol)
      )
  },

  fetchNutritionTargetForDate(date: Date): Observable<NutritionTargetModel | null> {
    return realmService.query<NutritionProtocolModel>(NutritionProtocolModel.schema.name)
      .fetchAll({ sort: { startDate: 1 }, limit: 14 })
      .pipe(
        map(entities => entities.map(entity => new NutritionProtocolModel(entity))),
        map(protocols => {
          return this.createDailyTargetFromNutritionProtocol(protocols, date)
        })
      )
  },

  createDailyTargetFromNutritionProtocol(
    nutritionProtocols: NutritionProtocolModel[],
    date: Date
  ): NutritionTargetModel | null {
    const targets = NutritionProtocolUtils.createTargetsFromProtocols(nutritionProtocols, date)

    const matchingTarget = targets.filter(t => {
      const start = t.startDate ?? new Date()
      const end = t.endDate ?? new Date()

      return date.getTime() > start.getTime() && date.getTime() < end.getTime()
    })[0]

    if (matchingTarget) {
      return matchingTarget
    }

    const minProtocol: NutritionProtocolModel | undefined = nutritionProtocols.sort((p1, p2) => {
      const date1 = p1.startDate ?? p1.createdAt ?? new Date()
      const date2 = p2.startDate ?? p2.createdAt ?? new Date()

      return date1.getTime() - date2.getTime()
    })[0]

    const minDate = minProtocol?.startDate ?? minProtocol?.createdAt

    if (minDate) {
      const firstProtocol = nutritionProtocols[0]
      const lastProtocol = nutritionProtocols[nutritionProtocols.length - 1]
      if (firstProtocol) {
        return new NutritionTargetModel({
          parentProtocol: firstProtocol,
          startDate: Moment(minDate).startOf('day').toDate(),
          endDate: Moment(minDate).endOf('day').toDate()
        })
      } else if (lastProtocol) {
        return new NutritionTargetModel({
          parentProtocol: lastProtocol,
          startDate: Moment(minDate).startOf('day').toDate(),
          endDate: Moment(minDate).endOf('day').toDate()
        })
      }
    }

    return null
  },
  fetchConfig(): Observable<NutritionProtocolConfigModel | null> {
    return realmService.query<NutritionProtocolConfigModel>(NutritionProtocolConfigModel.schema.name)
      .fetchOne()
      .pipe(
        map(entity => entity ? new NutritionProtocolConfigModel(entity) : null),
        mergeMap(config => {
          if (!config?.lastUpdatedAt) {
            return this.fetchNutritionTargetForDate(new Date())
              .pipe(
                map(c => c?.parentProtocol?.createdAt ?? new Date()),
                map(d => {
                  if (config) config.lastUpdatedAt = d
                  return config
                })
              )
          } else {
            return of(config)
          }
        })
      )
  }
}