import {LocationJson, LocationModel} from "./location";
import {OpeningTimePeriodJson, OpeningTimePeriodModel} from "./openingTimePeriod";
import {EntityModel} from "./entityModel";
import {map, mergeMap, Observable, of, zip} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectId} from "bson";
import {ProductOptionModel} from "./productOption";
import {ObjectSchema} from "mongodb-realm";

export interface PickupLocationJson {
  created_at?: Date
  _id?: string
  location?: LocationJson
  description?: string
  is_primary?: boolean
  name?: string
  opening_hours?: OpeningTimePeriodJson[]
  phone_number?: string
  store_id?: string
  updated_at?: Date
  vendor_id?: string
}

export class PickupLocationModel implements EntityModel<PickupLocationModel> {
  public static schema: ObjectSchema = {
    name: 'PickupLocationEntity',
    properties: {
      _id: 'objectId',
      _partition: 'string',
      createdAt: 'date?',
      isPrimary: 'bool',
      location: 'LocationEntity',
      locationDescription: 'string?',
      name: 'string?',
      openingHours: 'OpeningTimePeriodEntity[]',
      phoneNumber: 'string?',
      pickupLocationId: 'string?',
      storeId: 'string?',
      updatedAt: 'date?',
      vendorId: 'string?',
    },
    primaryKey: '_id',
  }

  schema = PickupLocationModel.schema

  _id: ObjectId = new ObjectId()
  _partition: string = ''
  createdAt?: Date
  description?: string
  pickupLocationId?: string
  isPrimary?: boolean
  location?: LocationModel
  name?: string
  openingHours: OpeningTimePeriodModel[] = []
  phoneNumber?: string
  storeId?: string
  updatedAt?: Date
  vendorId?: string

  constructor(entity?: PickupLocationModel | null) {
    if (!entity) return

    this._id = entity._id
    this._partition = entity._partition
    this.createdAt = entity.createdAt
    this.description = entity.description
    this.pickupLocationId = entity.pickupLocationId
    this.isPrimary = entity.isPrimary
    this.location = new LocationModel(entity.location)
    this.name = entity.name
    this.openingHours = entity.openingHours.map(i => new OpeningTimePeriodModel(i))
    this.phoneNumber = entity.phoneNumber
    this.storeId = entity.storeId
    this.updatedAt = entity.updatedAt
    this.vendorId = entity.vendorId

    return this
  }

  static fromJson(json?: PickupLocationJson): PickupLocationModel | undefined {
    if (!json) return

    const model = new PickupLocationModel()

    model.createdAt = json.created_at
    model.description = json.description
    model.pickupLocationId = json._id
    model.isPrimary = json.is_primary
    model.location = LocationModel.fromJson(json.location)
    model.name = json.name
    model.openingHours = json.opening_hours
      ?.map((i) => OpeningTimePeriodModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.phoneNumber = json.phone_number
    model.storeId = json.store_id
    model.updatedAt = json.updated_at
    model.vendorId = json.vendor_id

    return model
  }

  toJson(): PickupLocationJson {
    return {
      created_at: this.createdAt,
      description: this.description,
      _id: this.pickupLocationId,
      is_primary: this.isPrimary,
      location: this.location?.toJson(),
      name: this.name,
      opening_hours: this.openingHours.map(o => o.toJson()),
      phone_number: this.phoneNumber,
      store_id: this.storeId,
      updated_at: this.updatedAt,
      vendor_id: this.vendorId
    }
  }

  toEntityObservable(realmService: BaseRealmService): Observable<PickupLocationModel> {
    const openPeriodsObservable: Observable<OpeningTimePeriodModel[]> = this.openingHours.length === 0 ? of([]) : zip(this.openingHours.map(i => i.toEntityObservable(realmService)))

    return openPeriodsObservable
      .pipe(
        mergeMap(openHours => {
          this.openingHours = openHours

          return realmService.query<PickupLocationModel>(PickupLocationModel.schema.name)
            .where({ pickupLocationId: this.pickupLocationId })
            .fetchOne()
            .pipe(
              map(existingEntity => {
                if (existingEntity) {
                  this._id = existingEntity._id
                }

                return this
              })
            )
        })
      )

  }
}