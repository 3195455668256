import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled} from "rxjs";
import {
  activateCampaign, activateCampaignFailed, activateCampaignSucceeded,
  deleteCampaign, deleteCampaignFailed,
  deleteCampaignSucceeded,
  fetchCampaigns,
  fetchCampaignsFailed,
  fetchCampaignsSucceeded, reset, setCurrentPage
} from "./campaignListSlice";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";

export const fetchCampaignsEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchCampaigns.match),
    mergeMap(action => {
      return MessageService.fetchCampaigns(action.payload)
        .pipe(
          map(fetchCampaignsSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchCampaignsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deleteCampaignFromListEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(deleteCampaign.match),
    mergeMap(action => {
      return MessageService.deleteCampaign(action.payload)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.campaignList.currentPage

            return scheduled([
              deleteCampaignSucceeded(action.payload),
              reset(),
              pageNumber === 1 ? fetchCampaigns(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: deleteCampaignFailed.type,
            payload: { error, id: action.payload },
            error: true
          }))
        )
    })
  )
}

export const activateCampaignFromListEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(activateCampaign.match),
    mergeMap(action => {
      return MessageService.activateCampaign(action.payload)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.campaignList.currentPage

            return scheduled([
              activateCampaignSucceeded(action.payload),
              reset(),
              pageNumber === 1 ? fetchCampaigns(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: activateCampaignFailed.type,
            payload: { error, id: action.payload },
            error: true
          }))
        )
    })
  )
}