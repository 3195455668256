import React, {useEffect, useRef} from "react";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  clearError,
  reset,
  selectError,
  selectIsSaving,
  selectShouldClose,
  setContactInfo
} from "../../redux/user/contactInfoEntrySlice";
import {fetchUser, selectUser} from "../../redux/user/userSlice";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Container} from "../containers/Container";
import {CloseIcon} from "../icons";
import {HeroHeading2} from "../text";
import {CountryCode, parsePhoneNumber} from "libphonenumber-js";
import {Button} from "../buttons/Button";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";
import {useRouter} from "next/router";
import {setCustomer} from "../../redux/checkout/checkoutSlice";
import {ContactInfoEntryContainerView} from "./ContactInfoEntryContainerView";

export interface ContactInfoEntryViewProps {
  onClose: () => void
}

export const ContactInfoEntryView: React.FC<ContactInfoEntryViewProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const error = useAppSelector(selectError)
  const isSaving = useAppSelector(selectIsSaving)
  const user = useAppSelector(selectUser)
  const shouldClose = useAppSelector(selectShouldClose)

  const isCheckingOut = router.query.checkout === 'true'

  const dispatch = useAppDispatch()

  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const phoneNumberRef = useRef<HTMLInputElement>(null)
  const countryCodeRef = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Contact Entry' }))
  }, [])

  useEffect(() => {
    dispatch(reset())
    dispatch(fetchUser())
  }, [dispatch])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  const handleSubmit = () => {
    const firstName = firstNameRef.current?.value
    const lastName = lastNameRef.current?.value
    let phoneNumber: string | undefined
    const email = emailRef.current?.value
    const countryCode: CountryCode | undefined = countryCodeRef.current?.value as CountryCode

    if (phoneNumberRef.current?.value && countryCode) {
      const parsedNumber = parsePhoneNumber(phoneNumberRef.current.value, countryCode)
      phoneNumber =  parsedNumber.number.toString()
    }

    if (isCheckingOut && user?.roles?.includes('admin')) {
      dispatch(setCustomer({
          firstName,
          lastName,
          phoneNumber,
          email,
          countryCode
        }
      ))
    } else {
      dispatch(setContactInfo(
        {
          payload: {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            email
          },
          countryCode
        }
      ))
    }
  }

  return <form
    className="max-h-screen flex flex-col w-full min-h-screen md:min-h-screen-2/3 sticky"
    onSubmit={e => {
      e.preventDefault()
      handleSubmit()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            dispatch(clearError())
          },
          style: 'default'
        }
      ]}
    />
    <Container className="overflow-y-scroll max-h-screen flex-1 w-full">
      <div className="w-full no-scrollbar">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`mt-4 mb-6 lg:mb-8`}>
          <HeroHeading2>{t('auth:auth_title_contact_info')}</HeroHeading2>
        </div>

        <div className="flex flex-col space-y-5">
          <ContactInfoEntryContainerView
            firstNameRef={firstNameRef}
            lastNameRef={lastNameRef}
            emailRef={emailRef}
            countryCodeRef={countryCodeRef}
            config={router.query.checkout === 'true' ? 'checkout' : 'standard'}
            phoneNumberRef={phoneNumberRef} />

          <div>
            <Button
              size="lg"
              isFullWidth={true}
              isLoading={isSaving}
              onClick={() => {
                handleSubmit()
              }}
            >
              {t('common:common_title_update')}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  </form>
}