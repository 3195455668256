import {RootEpic} from "../../app/store";
import {filter} from "rxjs/operators";
import {asyncScheduler, catchError, tap, map, mergeMap, Observable, of, scheduled, zip} from "rxjs";
import {
  fetchProduct,
  fetchProductFailed,
  fetchProductSucceeded,
  updateCartWithItem,
  updateItemInCartFailed,
  updateItemInCartSucceeded
} from "./productSlice";
import {StoreService} from "../../services/storeService";
import {ProductModel} from "../../models/product";
import {ShoppingCartModel} from "../../models/shoppingCart";
import {fetchCartSucceeded} from "../cart/cartSlice";
import {setAddedCartItem} from "../navigation/navigationSlice";
import {logErrorRx} from "../../utils/logError";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export const fetchProductEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchProduct.match),
    mergeMap(action => {
      const { productId, skuId, product } = action.payload
      const productObservable = product ? of(product) : StoreService.fetchProduct(productId)

      let result: Observable<ProductModel>
      if (skuId) {
        const localCarts = Object.values(state$.value.cart.cartMap)
        const localCart = localCarts.filter(c => c.items.filter(i => i.skuId === skuId).length > 0)[0]

        const lineItemObservable = StoreService.fetchCartItem(skuId, localCart)

        result = zip(productObservable, lineItemObservable)
          .pipe(
            map(([product, lineItem]) => {
              if (lineItem) {
                product.selectedSize = lineItem.size ?? product.selectedSize
                const selectedOptionValues: Map<String, String> = new Map<String, String>()
                product.options.forEach(option => {
                  const optionId = option.id
                  const value = lineItem.optionValues.filter(v => v.value && option.values.includes(v.value))[0]?.value
                  if (value && optionId) {
                    selectedOptionValues.set(optionId, value)
                  }
                })

                product.selectedOptionValues = selectedOptionValues

                const selectedSku = product.getSelectedSku()
                if (!selectedSku) return product

                selectedSku.quantity = lineItem.quantity ?? 1
                const index = product.skus.findIndex(s => s.id === selectedSku?.id)
                product.skus[index] = selectedSku
              }

              return product
            })
          )
      } else {
        result = productObservable
          .pipe(
            map(product => {
              const selectedSku = product.getSelectedSku()
              if (!selectedSku) return product

              selectedSku.quantity = selectedSku?.quantity ?? 1
              const index = product.skus.findIndex(s => s.id === selectedSku?.id)
              product.skus[index] = selectedSku

              return product
            })
          )
      }

      return result.pipe(
        mergeMap(product => {
          EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Product Viewed', product }))
          return scheduled([fetchProductSucceeded(product)], asyncScheduler)
        }),
        catchError(error => logErrorRx(error)),
        catchError(error => of({
          type: fetchProductFailed.type,
          payload: error,
          error: true
        }))
      )
    })
  )
}

export const updateCartWithItemEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(updateCartWithItem.match),
    mergeMap(action => {
      const lineItem = state$.value.product.lineItem
      const product = state$.value.product.product
      const storeId = lineItem?.storeId
      const skuId = lineItem?.skuId

      if (!lineItem || !storeId || !skuId) {
        return scheduled([updateItemInCartSucceeded()], asyncScheduler)
      }

      const localCart = state$.value.cart.cartMap[storeId]

      const config = state$.value.product.config

      let updatedCart: Observable<ShoppingCartModel | null>

      if (config === 'adding') {
        updatedCart = StoreService.addItemToCart(lineItem, storeId, localCart)
          .pipe(
            tap({
              next: () => {
                const quantity = lineItem.quantity
                product && EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Product Added To Cart', quantity, product }))
              }
            })
          )
      } else if (lineItem.quantity > 0) {
        updatedCart = StoreService.updateItemInCart(lineItem, storeId, localCart)
      } else {
        updatedCart = StoreService.removeItemFromCart(skuId, storeId, localCart)
          .pipe(
            tap({
              next: () => {
                const productId = lineItem.productId
                productId && EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Product Removed From Cart', product, productId }))
              }
            })
          )
      }

      return updatedCart.pipe(
        mergeMap(cart => {
          let actions: { payload: any, type: string }[]
          if (config === 'adding') {
            actions = [
              fetchCartSucceeded(cart),
              updateItemInCartSucceeded(),
              setAddedCartItem(lineItem)
            ]
          } else {
            actions = [
              fetchCartSucceeded(cart),
              updateItemInCartSucceeded()
            ]
          }
          return scheduled(actions, asyncScheduler)
        }),
        catchError(error => logErrorRx(error)),
        catchError(error => of({
          type: updateItemInCartFailed.type,
          payload: error,
          error: true
        }))
      )
    })
  )
}