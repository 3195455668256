import {RootEpic} from "../../app/store";
import {fetchStoreFeed, fetchStoreFeedFailed, fetchStoreFeedSucceeded} from "./storeFeedSlice";
import {filter, map} from "rxjs/operators";
import {asyncScheduler, catchError, distinctUntilChanged, mergeMap, Observable, of, scheduled, startWith} from "rxjs";
import {LocationService} from "../../services/locationService";
import {StoreService} from "../../services/storeService";
import {setPrimaryLocation} from "../user/userSlice";
import {LocationModel} from "../../models/location";
import {logErrorRx} from "../../utils/logError";
import {push} from "connected-next-router";

export const fetchStoreFeedEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(fetchStoreFeed.match),
    mergeMap(({ payload }) => {
      const primaryLocation = state$.value.user.primaryLocation

      const hasPermissionObservable = LocationService.fetchLocationPermissions()

      const currentLocationObservable = hasPermissionObservable.pipe(
        mergeMap(hasPermission => hasPermission ? LocationService.fetchCurrentLocation() : LocationService.fetchApproxLocation())
      )

      let locationObservable: Observable<LocationModel> = LocationService.fetchPrimaryDeliveryLocation()
        .pipe(
          mergeMap(location => {
            if (location) {
              return of(location)
            } else {
              return currentLocationObservable
            }
          }),
          mergeMap(location => {
            return scheduled([setPrimaryLocation(location)], asyncScheduler)
              .pipe(map(() => location))
          }),
          catchError(() => {
            return primaryLocation ? of(primaryLocation) : currentLocationObservable
          })
        )

      if (primaryLocation) {
        locationObservable = locationObservable.pipe(startWith(primaryLocation))
      }

      return locationObservable
        .pipe(
          distinctUntilChanged((l1, l2) => {
            return l1.latitude === l2.latitude
              && l1.longitude === l2.longitude
          }),
          mergeMap(location => {
            return StoreService.fetchStoreSelectorForLocation(location)
              .pipe(map(storeSelector => ({ storeSelector, location })))
          }),
          mergeMap(({ storeSelector, location }) => {
            const actions: {payload: any, type: string}[] = [setPrimaryLocation(location)]
            if (storeSelector.state === 'standard' && storeSelector.stores.length === 1 && payload.shouldAutoRoute) {
              actions.push(push(`/store/${storeSelector.stores[0].storeId}`))
            } else {
              actions.push(fetchStoreFeedSucceeded(storeSelector))
            }
            return scheduled(
              actions,
              asyncScheduler
            )
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchStoreFeedFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
})