import {CommissionJson, CommissionModel} from "./commission";

export interface CommissionsResponseJson {
  total_results?: number
  commissions?: CommissionJson[]
}

export class CommissionsResponseModel {
  totalResults: number = 0
  commissions: CommissionModel[] = []

  static fromJson(json?: CommissionsResponseJson): CommissionsResponseModel | undefined {
    if (!json) return

    const model = new CommissionsResponseModel()
    model.totalResults = json.total_results ?? 0
    model.commissions = json.commissions?.map(i => CommissionModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}