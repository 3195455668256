export type CardBrand =
  'visa' |
  'Visa' |
  'amex' |
  'American Express' |
  'diners' |
  'Diners Club' |
  'discover' |
  'Discover' |
  'jcb' |
  'JCB' |
  'mastercard' |
  'MasterCard' |
  'union' |
  'Union Pay' |
  'UnionPay' |
  'unknown' |
  string

export interface CardJson {
  _id?: string;
  address_city?: string;
  address_country?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_state?: string;
  address_zip?: string;
  brand?: string;
  is_default?: boolean
  last_four_card_num_digits?: string;
  exp_month?: number;
  exp_year?: number;
  name?: string;
}

export class CardModel {
  id?: string
  addressCity?: string
  addressCountry?: string
  addressLine1?: string
  addressLine2?: string
  addressState?: string
  addressZip?: string
  brand?: CardBrand
  isDefault?: boolean
  lastFourCardNumDigits?: string
  expMonth?: number
  expYear?: number
  name?: string

  constructor(model?: CardModel) {
    if (!model) return

    this.id = model.id
    this.addressCity = model.addressCity
    this.addressCountry = model.addressCountry
    this.addressLine1 = model.addressLine1
    this.addressLine2 = model.addressLine2
    this.addressState = model.addressState
    this.addressZip = model.addressZip
    this.brand = model.brand
    this.isDefault = model.isDefault
    this.lastFourCardNumDigits = model.lastFourCardNumDigits
    this.expMonth = model.expMonth
    this.expYear = model.expYear
    this.name = model.name
  }

  static fromJson(json?: CardJson): CardModel | undefined {
    if (!json) return undefined

    const model = new CardModel()

    model.id = json._id
    model.addressCity = json.address_city
    model.addressCountry = json.address_country
    model.addressLine1 = json.address_line_1
    model.addressLine2 = json.address_line_2
    model.addressState = json.address_state
    model.addressZip = json.address_zip
    model.brand = json.brand
    model.isDefault = json.is_default
    model.lastFourCardNumDigits = json.last_four_card_num_digits
    model.expMonth = json.exp_month
    model.expYear = json.exp_year
    model.name = json.name

    return model
  }

  toJson(): CardJson {
    return {
      _id: this.id,
      address_city: this.addressCity,
      address_country: this.addressCountry,
      address_line_1: this.addressLine1,
      address_line_2: this.addressLine2,
      address_state: this.addressState,
      address_zip: this.addressZip,
      brand: this.brand,
      is_default: this.isDefault,
      last_four_card_num_digits: this.lastFourCardNumDigits,
      exp_month: this.expMonth,
      exp_year: this.expYear,
      name: this.name
    }
  }
}