import {PushNotificationJson, PushNotificationModel} from "./pushNotification";

export interface PushNotificationsResponseJson {
  total_results?: number
  push_notifications?: PushNotificationJson[]
}

export class PushNotificationsResponseModel {
  totalResults: number = 0
  pushNotifications: PushNotificationModel[] = []

  static fromJson(json?: PushNotificationsResponseJson): PushNotificationsResponseModel | undefined {
    if (!json) return

    const model = new PushNotificationsResponseModel()
    model.totalResults = json.total_results ?? 0
    model.pushNotifications = json.push_notifications?.map(i => PushNotificationModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}