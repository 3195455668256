module.exports = {
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    colors: {
      'fg-primary-gradient-start': '#3bca7c',
      'fg-primary-lightest': '#f2fff7',
      'fg-primary': '#1ead5e',
      'fg-primary-dark': '#1c9c55',
      'fg-primary-darker': '#1c864a',

      'fg-gold': '#ffc800',
      'fg-purple-light': '#8a89c0',
      'fg-sky-blue': '#54c6eb',
      'fg-indigo': '#003d55',
      'fg-lime-green': '#06d6a0',
      'fg-footer-border': '#3a5440',

      'fg-black': '#0e1e12',

      'black': '#000',

      'fg-gray-dark': '#414b42',
      'fg-gray': '#717871',
      'fg-gray-light': '#a0a5a1',
      'fg-gray-lighter': '#e7e8e7',
      'fg-gray-lightest': '#efefef',

      'fg-gray-surface-dark': '#e7e8e7',
      'fg-gray-surface': '#efefef',
      'fg-gray-surface-light': '#f7f7f7',

      'fg-off-white': '#fbfbfa',

      'fg-red': '#d93f22',
      'fg-red-dark': '#B0341C',

      'facebook-blue': '#3951a2',

      'white': '#ffffff',
      'transparent': 'rgba(255,255,255,0)'
    },
    extend: {
      fontFamily: {
        sans: ['Gilroy', 'sans-serif'],
      },
      gridTemplateColumns: {
        '17': 'repeat(17, minmax(0, 1fr))',
        '25': 'repeat(25, minmax(0, 1fr))',
      },
      transitionProperty: {
        'display': 'hidden, block, flex, inline',
        'width': 'width',
        'height': 'height',
        'margin': 'margin',
        'visibility': 'visible, hidden'
      },
      translate: {
        'full-2x': '200%',
        'full-3x': '300%'
      },
      height: {
        'screen-1/10': '10vh',
        'screen-1/8': '12vh',
        'screen-1/6': '17vh',
        'screen-1/5': '20vh',
        'screen-1/4': '25vh',
        'screen-1/3': '33vh',
        'screen-1/2': '50vh',
        'screen-2/3': '66vh',
        'screen-3/4': '75vh',
      },
      minHeight: {
        'screen-1/10': '10vh',
        'screen-1/8': '12vh',
        'screen-1/6': '17vh',
        'screen-1/5': '20vh',
        'screen-1/4': '25vh',
        'screen-1/3': '33vh',
        'screen-1/2': '50vh',
        'screen-2/3': '66vh',
        'screen-3/4': '75vh',
        'screen-4/5': '80vh',
        'screen-5/6': '83vh',
        'screen-6/7': '85vh',
        'screen-7/8': '88vh',
        '48': '12rem',
      },
      maxHeight: {
        'screen-1/10': '10vh',
        'screen-1/8': '12vh',
        'screen-1/6': '17vh',
        'screen-1/5': '20vh',
        'screen-1/4': '25vh',
        'screen-1/3': '33vh',
        'screen-1/2': '50vh',
        'screen-2/3': '66vh',
        'screen-3/4': '75vh',
        'screen-4/5': '80vh',
        'screen-5/6': '83vh',
        'screen-6/7': '85vh',
        'screen-7/8': '88vh',
      },
      minWidth: {
        'xs': '20rem',
        'sm': '24rem',
        'md': '28rem',
        'lg': '32rem',
        'xl': '36rem',
        '2xl': '42rem',
        '3xl': '48rem',
        '4xl': '56rem',
        '5xl': '64rem',
        '6xl': '72rem',
        '7xl': '80rem',
        'screen-1/10': '10vw',
        'screen-1/8': '12vw',
        'screen-1/6': '17vw',
        'screen-1/5': '20vw',
        'screen-1/4': '25vw',
        'screen-1/3': '33vw',
        'screen-1/2': '50vw',
        'screen-2/3': '66vw',
        'screen-3/4': '75vw',
        'screen-4/5': '80vw',
        'screen-5/6': '83vw',
        'screen-6/7': '85vw',
        'screen-7/8': '88vw',
        'screen': '100vw'
      },
      maxWidth: {
        'screen-1/10': '10vw',
        'screen-1/8': '12vw',
        'screen-1/6': '17vw',
        'screen-1/5': '20vw',
        'screen-1/4': '25vw',
        'screen-1/3': '33vw',
        'screen-1/2': '50vw',
        'screen-2/3': '66vw',
        'screen-3/4': '75vw',
        'screen-4/5': '80vw',
        'screen-5/6': '83vw',
        'screen-6/7': '85vw',
        'screen-7/8': '88vw',
        'screen': '100vw'
      },
      width: {
        'screen-1/10': '10vh',
        'screen-1/8': '12vh',
        'screen-1/6': '17vh',
        'screen-1/5': '20vh',
        'screen-1/4': '25vh',
        'screen-1/3': '33vh',
        'screen-1/2': '50vh',
        'screen-2/3': '66vh',
        'screen-3/4': '75vh',
        '17': '4.25rem',
        'xs': '20rem',
        'sm': '24rem',
        'md': '28rem',
        'lg': '32rem',
        'xl': '36rem',
        '2xl': '42rem',
        '3xl': '48rem',
        '4xl': '56rem',
        '5xl': '64rem',
        '6xl': '72rem',
        '7xl': '80rem'
      },
      inset: {
        '1/20': '5%',
        '1/14': '7%',
        '1/12': '8%',
        '1/10': '10%',
        '1/8': '12%',
        '1/6': '17%',
        '1/5': '20%',
        '3/10': '30%',
        '2/5': '40%',
        '9/20': '45%',
        '3/5': '60%',
        '4/5': '80%',
        '5/6': '83%',
        '6/7': '85%',
        '7/8': '88%',
        '9/10': '90%',
        '13/14': '93%',
        '19/20': '95%',
      },
      borderWidth: {
        DEFAULT: '1px',
        '0': '0',
        '2': '2px',
        '3': '3px',
        '4': '4px',
        '6': '6px',
        '8': '8px',
      },
    },
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      display: ['group-hover'],
      visibility: ['group-hover'],
      borderColor: ['group-hover'],
      borderWidth: ['group-hover', 'last'],
      padding: ['group-hover'],
      ringWidth: ['group-hover', 'active'],
      ringColor: ['group-hover', 'active'],
      'width': ['group-hover'],
      'height': ['group-hover'],
      'margin': ['group-hover'],
      transitionProperty: ['group-hover', 'responsive'],
      scale: ['group-hover'],
      textColor: ['group-focus'],
    }
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/forms'),
  ],
}
