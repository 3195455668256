import {LocationType} from "../models/location";
import {ProductModel} from "../models/product";
import {ProductCategoryModel} from "../models/productCategory";
import {StoreSectionModel, StoreSectionType} from "../models/storeSection";
import {
  ActivityLevel,
  BodyType,
  DietaryPreference,
  DietGoal,
  Gender,
  HeightUnit,
  WeightUnit
} from "../models/fitnessProfile";
import {DietaryCategory} from "../models/dietaryCategory";
import {Observable, mergeMap, zip} from "rxjs";
import {StoreApi} from "../apis/storeApi";
import {map} from "rxjs/operators";
import {MealType} from "../models/mealType";
import {StoreService} from "../services/storeService";
import {ShoppingCartModel} from "../models/shoppingCart";
import {SalesOrderModel} from "../models/salesOrder";
import mixpanel from "mixpanel-browser";
import {NutritionProtocolModel} from "../models/nutritionProtocol";
import {NutritionProtocolConfigModel} from "../models/nutritionProtocolConfig";

export type ScreenName = 'Cart'
  | 'Checkout'
  | 'Contact Entry'
  | 'Landing'
  | 'Location Entry'
  | 'Location Selector'
  | 'Menu Item Detail'
  | 'Order Detail'
  | 'Order List'
  | 'Payment Method Entry'
  | 'Payment Method Selector'
  | 'Pickup Location Detail'
  | 'Pickup Location Selector'
  | 'Promo Code Entry'
  | 'Profile'
  | 'Sign In'
  | 'Sign Up'
  | 'Forgot Password'
  | 'Store'
  | 'Store Detail'
  | 'Store Feed'
  | 'Affiliate Dashboard'
  | 'Nutrition Survey'
  | 'Nutrition Plan'

export type EventPropertyName = 'Login Method'
  | 'Dietary Category'
  | 'Dietary Categories'
  | 'Meal Type'
  | 'Product Categories'
  | 'Product Category ID'
  | 'Product Category Name'
  | 'Product ID'
  | 'Product Name'
  | 'Product Total Price (USD)'
  | 'Product Unit Price (USD)'
  | 'Product Quantity'
  | 'Product Size'
  | 'Product Type'
  | 'Product Variant ID'
  | 'Store Section ID'
  | 'Store Section Item Position'
  | 'Store Section Position'
  | 'Store Section Title'
  | 'Store Section Subtitle'
  | 'Store Section Type'
  | 'Purchase Type'
  | 'Estimated Activity Level'
  | 'Estimated Body Type'
  | 'Diet Length (Weeks)'
  | 'Diet Goal'
  | 'Dietary Preference'
  | 'Estimated Body Fat (%)'
  | 'Goal Weight (lbs)'
  | 'Height (in)'
  | 'Height Units'
  | 'Sex'
  | 'Starting Weight (lbs)'
  | 'Weight Units'
  | 'Vendor Name'
  | 'Screen Name'
  | 'Nutrition Plan Schedule'
  | 'Calories'
  | 'Fat'
  | 'Saturated Fat'
  | 'Polyunsaturated Fat'
  | 'Monounsaturated Fat'
  | 'Trans Fat'
  | 'Carbohydrate'
  | 'Fiber'
  | 'Sugar'
  | 'Protein'
  | 'Cholesterol'
  | 'Sodium'
  | 'Potassium'
  | 'Vitamin A'
  | 'Vitamin C'
  | 'Calcium'
  | 'Iron'
  | 'Survey Question'

export type EventProps = {
  [key in EventPropertyName]?: string | number | string[] | undefined | null;
};

export type EventPayload = { eventName: 'Account Created' }
  | { eventName: 'Product Added To Cart', product: ProductModel, quantity?: number }
  | { eventName: 'Product Removed From Cart', product?: ProductModel, productId: string, quantity?: number }
  | { eventName: 'Product Viewed', product: ProductModel, quantity?: number }
  | { eventName: 'Product Category Viewed', category: ProductCategoryModel }
  | { eventName: 'Store Section Viewed', storeSection: StoreSectionModel }
  | { eventName: 'Checkout Initiated', cart: ShoppingCartModel }
  | { eventName: 'Purchase Completed', order: SalesOrderModel }
  | { eventName: 'Nutrition Plan Created', protocol: NutritionProtocolModel, config: NutritionProtocolConfigModel }
  | { eventName: 'Nutrition Plan Updated', protocol: NutritionProtocolModel, config: NutritionProtocolConfigModel }
  | { eventName: 'Payment Info Added' }
  | { eventName: 'Screen Visited', screenName: ScreenName }
  | { eventName: 'Login', method: 'Email' | 'Facebook' | 'Apple' }

export class AnalyticsEvent {
  payload: EventPayload
  destinations: ('facebook' | 'mixpanel')[] = ['facebook', 'mixpanel']
  additionalProps: EventProps = {}

  constructor(payload: EventPayload, additionalProps?: EventProps) {
    this.payload = payload
    this.additionalProps = additionalProps ?? this.additionalProps
    switch (this.payload.eventName) {
      case "Checkout Initiated":
        this.destinations = ['facebook']
        break;
      case "Purchase Completed":
        this.destinations = ['facebook']
        break;
      case "Payment Info Added":
        this.destinations = ['facebook']
        break;
      default:
        this.destinations = ['facebook', 'mixpanel']
        break;
    }
  }

  getProps(): Observable<EventProps> {
    switch (this.payload.eventName) {
      case "Account Created":
        return new Observable(subscriber => {
          subscriber.next({ ...this.additionalProps })
        })
      case "Checkout Initiated":
        return new Observable(subscriber => {
          subscriber.next({ ...this.additionalProps })
        })
      case "Purchase Completed":
        return new Observable(subscriber => {
          subscriber.next({ ...this.additionalProps })
        })
      case "Payment Info Added":
        return new Observable(subscriber => {
          subscriber.next({ ...this.additionalProps })
        })
      case "Nutrition Plan Created":
        return createEventPropsForNutritionProtocol(this.payload.protocol, this.payload.config)
          .pipe(
            map(props => {
              return {
                ...props,
                ...this.additionalProps
              }
            })
          )
      case "Nutrition Plan Updated":
        return createEventPropsForNutritionProtocol(this.payload.protocol, this.payload.config)
          .pipe(
            map(props => {
              return {
                ...props,
                ...this.additionalProps
              }
            })
          )
      case "Product Added To Cart":
        return createMenuItemEventProps(this.payload.product.id!, this.payload.product, this.payload.quantity)
          .pipe(
            map(props => {
              return {
                ...props,
                ...this.additionalProps
              }
            })
          )
      case "Product Removed From Cart":
        return createMenuItemEventProps(this.payload.productId, this.payload.product, this.payload.quantity)
          .pipe(
            map(props => {
              return {
                ...props,
                ...this.additionalProps
              }
            })
          )
      case 'Product Viewed':
        return createMenuItemEventProps(this.payload.product.id!, this.payload.product, this.payload.quantity)
          .pipe(
            map(props => {
              return {
                ...props,
                ...this.additionalProps
              }
            })
          )
      case 'Product Category Viewed':
        return createEventPropsForCategory(this.payload.category)
          .pipe(
            map(props => {
              return {
                ...props,
                ...this.additionalProps
              }
            })
          )
      case 'Store Section Viewed':
        return createEventPropsForStoreSection(this.payload.storeSection)
          .pipe(
            map(props => {
              return {
                ...props,
                ...this.additionalProps
              }
            })
          )
      case 'Login':
        const method = this.payload.method

        return new Observable(subscriber => {
          subscriber.next({
            'Login Method': method,
            ...this.additionalProps
          })
        })
      case 'Screen Visited':
        const screenName = this.payload.screenName
        return new Observable(subscriber => {
          subscriber.next({
            'Screen Name': screenName,
            ...this.additionalProps
          })
        })
    }
  }
}

export type GlobalEventPropertyPayload = { eventName: 'Account Type', value: 'Free' | 'Premium' }
  | { eventName: '$email', value?: string }
  | { eventName: 'Cart Store ID', value?: string }
  | { eventName: 'Delivery Location City', value?: string }
  | { eventName: 'Delivery Location Latitude', value?: number }
  | { eventName: 'Delivery Location Longitude', value?: number }
  | { eventName: 'Delivery Location State', value?: string }
  | { eventName: 'Delivery Location Type', value?: LocationType }
  | { eventName: 'Delivery Location Zip', value?: string }
  | { eventName: '$first_name', value?: string }
  | { eventName: 'Last Viewed Line Item Name', value?: string }
  | { eventName: 'Last Viewed Line Item ID', value?: string }
  | { eventName: '$last_name', value?: string }
  | { eventName: 'Lifetime Purchase Value', purchaseType: 'In App' | 'Menu Item', value: number }
  | { eventName: 'Cart Line Item Names', value: string[] }
  | { eventName: 'Cart Line Item IDs', value: string[] }
  | { eventName: 'Cart Line Item Image URLs', value: string[] }
  | { eventName: '$phone', value?: string }
  | { eventName: 'Selected Vendor Name', value?: string }
  | { eventName: 'Selected Vendor ID', value?: string }
  | { eventName: 'User ID', value?: string }
  | { eventName: 'Activity Level', value?: 'Low' | 'Moderate' | 'High' | 'Very High' }
  | { eventName: 'Body Type', value?: 'Very Lean' | 'Lean' | 'Average' | 'Overweight' | 'Very Overweight' | 'Obese' }
  | { eventName: 'Diet Length (Weeks)', value?: number }
  | { eventName: 'Diet Goal', value?: 'Gain Muscle' | 'Lose Fat' | 'Maintenance' }
  | { eventName: 'Dietary Preference', value?: 'Low Fat' | 'Low Carb' | 'Vegan' | 'Vegetarian' | 'Paleo' | 'Everything' }
  | { eventName: 'Estimated Body Fat (%)', value?: number }
  | { eventName: 'Goal Weight (lbs)', value?: number }
  | { eventName: 'Height (in)', value?: number }
  | { eventName: 'Height Units', value?: 'in' | 'cm' }
  | { eventName: 'Sex', value?: 'Male' | 'Female' }
  | { eventName: 'Starting Weight (lbs)', value?: number }
  | { eventName: 'Weight Units', value?: 'lbs' | 'kg' }
  | { eventName: 'Weekly Update Status', value?: 'Enabled' | 'Disabled' }
  | { eventName: 'Last Macros Update Date', value?: Date }
  | { eventName: 'First Macros Update Date', value?: Date }
  | { eventName: 'Lifetime Nutrition Plan Update Count', value: number }

export class GlobalEventProperty {
  payload: GlobalEventPropertyPayload

  constructor(payload: GlobalEventPropertyPayload) {
    this.payload = payload
  }

  getEventPropValue() {
    switch (this.payload.eventName) {
      case 'Account Type':
        return this.payload.value
      case '$email':
        return this.payload.value
      case 'Cart Store ID':
        return this.payload.value
      case 'Delivery Location City':
        return this.payload.value
      case 'Delivery Location Latitude':
        return this.payload.value
      case 'Delivery Location Longitude':
        return this.payload.value
      case 'Delivery Location State':
        return this.payload.value
      case 'Delivery Location Type':
        return this.payload.value
      case 'Delivery Location Zip':
        return this.payload.value
      case '$first_name':
        return this.payload.value
      case 'Last Viewed Line Item Name':
        return this.payload.value
      case 'Last Viewed Line Item ID':
        return this.payload.value
      case '$last_name':
        return this.payload.value
      case 'Lifetime Purchase Value':
        return this.payload.value
      case 'Cart Line Item Names':
        return this.payload.value
      case 'Cart Line Item IDs':
        return this.payload.value
      case 'Cart Line Item Image URLs':
        return this.payload.value
      case '$phone':
        return this.payload.value
      case 'Selected Vendor Name':
        return this.payload.value
      case 'Selected Vendor ID':
        return this.payload.value
    }
  }
}

const getEventPropValueFromDietaryCategory = (dietaryCategory: DietaryCategory) => {
  switch (dietaryCategory) {
    case 'everything':
      return 'Balanced';
    case 'low_carb':
      return 'Low Carb';
    case 'gluten_free':
      return 'Gluten Free';
    case 'paleo':
      return 'Paleo';
    case 'vegan':
      return 'Vegan';
    case 'vegetarian':
      return 'Vegetarian';
    case 'keto':
      return 'Keto'
    default:
      return dietaryCategory
  }
}

const getEventPropValueFromMealType = (mealType: MealType) => {
  switch (mealType) {
    case 'breakfast':
      return 'Breakfast';
    case 'lunch':
      return 'Lunch';
    case 'dinner':
      return 'Dinner';
    case 'other':
      return 'Snack';
    default:
      return mealType
  }
}

const getEventPropValueFromStoreSectionType = (sectionType: StoreSectionType) => {
  switch (sectionType) {
    case 'category':
      return 'Category';
    case 'menu_item':
      return 'Menu Item';
    case 'location':
      return 'Location';
    case 'vendor':
      return 'Vendor';
  }
}


export const getEventPropValueFromDietaryPreference = (dietaryPreference: DietaryPreference) => {
  switch (dietaryPreference) {
    case DietaryPreference.everything:
      return 'Everything';
    case DietaryPreference.lowCarb:
      return 'Low Carb';
    case DietaryPreference.lowFat:
      return 'Low Fat';
    case DietaryPreference.paleo:
      return 'Paleo';
    case DietaryPreference.vegan:
      return 'Vegan';
    case DietaryPreference.vegetarian:
      return 'Vegetarian';
  }
}

export const getEventPropValueFromActivityLevel = (activityLevel: ActivityLevel) => {
  switch (activityLevel) {
    case ActivityLevel.low:
      return 'Low'
    case ActivityLevel.moderate:
      return 'Moderate'
    case ActivityLevel.high:
      return 'High'
    case ActivityLevel.veryHigh:
      return 'Very High'
  }
}

export const getEventPropValueFromBodyType = (bodyType: BodyType) => {
  switch (bodyType) {
    case BodyType.lean:
      return 'Very Lean'
    case BodyType.moderatelyLean:
      return 'Lean'
    case BodyType.average:
      return 'Average'
    case BodyType.moderatelyOverweight:
      return 'Overweight'
    case BodyType.overweight:
      return 'Very Overweight'
    case BodyType.obese:
      return 'Obese'
  }
}

export const getEventPropValueFromDietGoal = (dietGoal: DietGoal) => {
  switch (dietGoal) {
    case DietGoal.bulk:
      return 'Gain Muscle'
    case DietGoal.cut:
      return 'Lose Fat'
    case DietGoal.maintenance:
      return 'Maintenance'
  }
}

export const getEventPropValueFromHeightUnit = (heightUnit: HeightUnit) => {
  switch (heightUnit) {
    case HeightUnit.imperial:
      return 'in'
    case HeightUnit.metric:
      return 'cm'
  }
}

export const getEventPropValueFromWeightUnit = (weightUnit: WeightUnit) => {
  switch (weightUnit) {
    case WeightUnit.imperial:
      return 'lbs'
    case WeightUnit.metric:
      return 'kg'
  }
}

export const getEventPropValueFromGender = (gender: Gender) => {
  switch (gender) {
    case Gender.male:
      return 'Male'
    case Gender.female:
      return 'Female'
  }
}

const createMenuItemEventProps = (productId: string, product?: ProductModel, quantity?: number): Observable<EventProps> => {
  let productObservable: Observable<ProductModel>
  if (product) {
    productObservable = new Observable(subscriber => {
      subscriber.next(product)
    })
  } else {
    productObservable = StoreService.fetchProduct(productId)
  }

  return productObservable
    .pipe(
      mergeMap(product => {
        const sku = product.getSelectedSku()

        let unitPriceObservable: Observable<number | undefined>
        if (sku?.currencyCode && sku.currencyCode !== 'USD' && sku.price) {
          unitPriceObservable = StoreApi.convertCurrency(sku.currencyCode, 'USD', sku.price)
        } else {
          unitPriceObservable = new Observable(subscriber => {
            subscriber.next(product.getSelectedSku()?.price)
          })
        }

        return unitPriceObservable.pipe(map(unitPrice => {
          return { unitPrice, product }
        }))
      }),
      map(({ unitPrice, product }) => {
        const sku = product.getSelectedSku()

        const dietaryCategories = product.categories
          .map((c) => c.dietaryCategory && getEventPropValueFromDietaryCategory(c.dietaryCategory))
          .filter((c) => c)
          .map((c) => c as string)
        const productCategories = product.categories
          .map((c) => c.name)
          .filter((c) => c)
          .map((c) => c as string)

        const props: EventProps = {
          'Product Categories': productCategories,
          'Dietary Categories': dietaryCategories,
          'Product ID': product.id,
          'Meal Type': product.mealType && getEventPropValueFromMealType(product.mealType),
          'Product Name': product.name,
          'Product Quantity': quantity,
          'Product Unit Price (USD)': unitPrice,
          'Product Total Price (USD)': quantity && unitPrice && (unitPrice * quantity),
          'Product Size': sku?.size,
          'Product Type': 'Menu Item',
          'Product Variant ID': sku?.id,
          'Vendor Name': product.vendorName
        }

        return props
      })
    )
}

const createEventPropsForCategory = (category: ProductCategoryModel): Observable<EventProps> => {
  return new Observable(subscriber => {
    const props: EventProps = {
      'Product Category Name': category.name,
      'Product Category ID': category.id,
      'Vendor Name': category.vendorName,
      'Meal Type': category.mealType && getEventPropValueFromMealType(category.mealType),
      "Dietary Category": category.dietaryCategory && getEventPropValueFromDietaryCategory(category.dietaryCategory),
    }

    subscriber.next(props)
  })
}

const createEventPropsForStoreSection = (storeSection: StoreSectionModel): Observable<EventProps> => {
  return new Observable(subscriber => {
    const props: EventProps = {
      "Store Section ID": storeSection.id,
      "Store Section Subtitle": storeSection.subtitle,
      "Store Section Title": storeSection.title,
      "Store Section Type": storeSection.type && getEventPropValueFromStoreSectionType(storeSection.type)
    }

    subscriber.next(props)
  })
}

const createEventPropsForNutritionProtocol = (protocol: NutritionProtocolModel, config: NutritionProtocolConfigModel): Observable<EventProps> => {
  return new Observable(subscriber => {
    const props: EventProps = {
      "Nutrition Plan Schedule": config.type === 'weekly' ? 'Weekly' : 'Daily'
    }

    protocol.getNutrients().forEach((value, key) => {
      switch (key) {
        case "CALORIES":
          props['Calories'] = value
          break;
        case "FAT":
          props['Fat'] = value
          break;
        case "SATURATED_FAT":
          props['Saturated Fat'] = value
          break;
        case "POLYUNSATURATED_FAT":
          props['Polyunsaturated Fat'] = value
          break;
        case "MONOUNSATURATED_FAT":
          props['Monounsaturated Fat'] = value
          break;
        case "TRANS_FAT":
          props['Trans Fat'] = value
          break;
        case "CARBOHYDRATE":
          props['Carbohydrate'] = value
          break;
        case "FIBER":
          props['Fiber'] = value
          break;
        case "SUGAR":
          props['Sugar'] = value
          break;
        case "PROTEIN":
          props['Protein'] = value
          break;
        case "CHOLESTEROL":
          props['Cholesterol'] = value
          break;
        case "SODIUM":
          props['Sodium'] = value
          break;
        case "POTASSIUM":
          props['Potassium'] = value
          break;
        case "VITAMIN_A":
          props['Vitamin A'] = value
          break;
        case "VITAMIN_C":
          props['Vitamin C'] = value
          break;
        case "CALCIUM":
          props['Calcium'] = value
          break;
        case "IRON":
          props['Iron'] = value
          break;
      }
    })

    subscriber.next(props)
  })
}