import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {Modal} from "../modals/Modal";
import {LoginView, LoginViewConfig} from "./LoginView";
import {useRouter} from "next/router";

export const LoginModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();
  const router = useRouter()
  let config: LoginViewConfig

  if (router.query.config === 'sign_up') {
    config = 'sign_up'
  } else if (router.query.config === 'sign_in') {
    config = 'sign_in'
  } else if (router.query.config === 'forgot_password') {
    config = 'forgot_password'
  } else if (router.query.config === 'post_purchase') {
    config = 'post_purchase'
  } else if (router.query.config === 'get_started') {
    config = 'get_started'
  } else {
    config = 'sign_up'
  }

  return <Modal
    className="md:max-w-lg"
    isOpen={isOpen('login')}
    onClose={() => onClose()}
  >
    <LoginView config={config} onClose={onClose} />
  </Modal>
}