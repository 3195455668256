import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements} from "@stripe/react-stripe-js";
import useWindowDimensions from "./useWindowDimensions";
import {TextInputStyles} from "../components/inputs";
import {TextStyles} from "../components/text";
import {useEffect, useState} from "react";
import {StripeElementStyleVariant} from "@stripe/stripe-js";
import tailwindConfig from "../tailwind.config";

export type StripeError = {
  type: 'validation_error'
  code: string;
  message: string;
}

export const useStripeFormatter = (elementType: 'cardNumber' | 'cardExpiry' | 'cardCvc') => {
  const elements = useElements()

  const dimensions = useWindowDimensions()

  const baseStripeInputClass = `px-3 lg:px-4 py-2.5 lg:py-3 rounded-lg ${TextInputStyles.Base} ${TextStyles.Body}`
  const [baseStyle, setBaseStyle] = useState<StripeElementStyleVariant>({})
  const [baseClass, setBaseClass] = useState<string>(baseStripeInputClass)
  const [focused, setFocused] = useState<boolean>(false)
  const [error, setError] = useState<StripeError | undefined>()

  useEffect(() => {
    setBaseClass(baseStripeInputClass + ` ${error ? 'ring-2 ring-fg-red' : focused ? 'ring-2 ring-fg-black' : ''}`)
  }, [error, focused, baseStripeInputClass])

  useEffect(() => {
    switch (elementType) {
      case "cardNumber":
        elements?.getElement(CardNumberElement)?.update({
          classes: {
            base: baseClass
          }
        })
        break;
      case "cardExpiry":
        elements?.getElement(CardExpiryElement)?.update({
          classes: {
            base: baseClass
          }
        })
        break;
      case "cardCvc":
        elements?.getElement(CardCvcElement)?.update({
          classes: {
            base: baseClass
          }
        })
        break;
    }

  }, [baseClass, elements, elementType])

  useEffect(() => {
    let fontSize: string;
    let lineHeight: string;
    let paddingY: string;
    let paddingX: string;

    if (dimensions.width <= 640) {
      fontSize = '16px'
      lineHeight = '24px'
      paddingY = '10px'
      paddingX = '12px'
    } else if (dimensions.width <= 768) {
      fontSize = '16px'
      lineHeight = '24px'
      paddingY = '10px'
      paddingX = '12px'
    } else if (dimensions.width <= 1024) {
      fontSize = '18px'
      lineHeight = '28px'
      paddingY = '12px'
      paddingX = '16px'
    } else {
      fontSize = '18px'
      lineHeight = '28px'
      paddingY = '12px'
      paddingX = '16px'
    }

    setBaseStyle({
      fontFamily: tailwindConfig.theme.extend.fontFamily.sans[0],
      fontWeight: '400',
      fontSize,
      lineHeight,
      color: tailwindConfig.theme.colors['fg-black'],
      padding: `${paddingY} ${paddingX} ${paddingY} ${paddingX}`,
      backgroundColor: tailwindConfig.theme.colors['fg-gray-surface-light'],
    })
  }, [dimensions.width])

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)
  }

  const onError = (e: StripeError | undefined) => {
    setError(e)
  }

  return { baseClass, baseStyle, onFocus, onError, onBlur }
}