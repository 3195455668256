import React, {useEffect, useState} from "react";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {reset, selectError, selectShouldClose} from "../../redux/auth/authSlice";
import {AuthValidationError} from "../../errors/authValidationError";
import {BaseError} from "../../errors/baseError";
import {AlertDialog} from "../dialogs/AlertDialog";
import {CloseIcon} from "../icons";
import {HeroHeading2} from "../text";
import {LoginFormView} from "./LoginFormView";

export type LoginViewConfig = 'sign_up'
  | 'sign_in'
  | 'forgot_password'
  | 'post_purchase'
  | 'get_started'
  | 'get_macro_plan'

export interface LoginViewProps {
  config: LoginViewConfig
  onClose: () => void
}

export const LoginView: React.FC<LoginViewProps> = (props) => {
  const { t } = useTranslation()
  const { onClose } = props

  const dispatch = useAppDispatch();
  const shouldClose = useAppSelector(selectShouldClose)
  const [selectedConfig, setSelectedConfig] = useState<LoginViewConfig>(props.config)

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(reset())
    }
  }, [shouldClose, onClose, dispatch])

  const error = useAppSelector(selectError);

  const [authError, setAuthError] = useState<BaseError<any> | null>()

  useEffect(() => {
    setAuthError(!(error instanceof AuthValidationError) ? error ?? null : null)
  },  [error])

  let title: string;
  switch (selectedConfig) {
    case "sign_up":
      title = t('auth:auth_title_sign_up')
      break;
    case "post_purchase":
      title = t('auth:auth_title_order_received_create_account')
      break;
    case "get_started":
      title = t('auth:auth_title_get_started')
      break;
    case "get_macro_plan":
      title = t('auth:auth_title_get_started')
      break;
    case "sign_in":
      title = t('auth:auth_title_sign_in')
      break;
    case "forgot_password":
      title = t('auth:auth_title_forgot_password')
      break;
  }

  return <div className="w-full min-h-screen-3/4 max-h-screen-7/8 md:min-h-0 px-6 py-6 lg:px-10 lg:py-10 overflow-y-scroll">
    <AlertDialog
      isOpen={!!authError}
      error={authError}
      onClose={() => {
        dispatch(reset())
      }}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {},
          style: 'default'
        }
      ]}
    />
    <div className="-ml-3 -mt-3 flex items-center justify-between">
      <button
        type="button"
        className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
        onClick={() => onClose()}
      >
        <CloseIcon className="h-4 h-4" />
      </button>
    </div>
    <div className="mt-4 mb-10 lg:mb-16">
      <HeroHeading2 className="whitespace-pre-line">{title}</HeroHeading2>
    </div>
    <LoginFormView config={props.config} onSelectedConfigChange={setSelectedConfig} />
  </div>
}