import {CurrencyCode} from "./currencyCode";
import {ImageJson, ImageModel} from "./image";
import {MenuItemSizeType} from "./menuItemSizeType";
import {ProductSkuOptionValueJson, ProductSkuOptionValueModel} from "./productSkuOptionValue";

export type SalesOrderLineItemType = 'menu_item'

export interface SalesOrderLineItemJson {
  created_at?: Date
  currency_code?: CurrencyCode
  description?: string
  estimated_distribution_date?: Date
  option_values?: ProductSkuOptionValueJson[]
  image?: ImageJson
  name?: string
  quantity?: number
  price?: number
  _id?: string
  size?: MenuItemSizeType
  type?: SalesOrderLineItemType
  updated_at?: Date
}

export class SalesOrderLineItemModel {
  createdAt?: Date
  currencyCode?: CurrencyCode
  description?: string
  estimatedDistributionDate?: Date
  optionValues: ProductSkuOptionValueModel[] = []
  id?: string
  image?: ImageModel
  name?: string
  quantity?: number
  price?: number
  size?: MenuItemSizeType
  type?: SalesOrderLineItemType
  updatedAt?: Date

  static fromJson(json?: SalesOrderLineItemJson): SalesOrderLineItemModel | undefined {
    if (!json) return

    const model = new SalesOrderLineItemModel()

    model.createdAt = json.created_at
    model.currencyCode = json.currency_code
    model.description = json.description
    model.estimatedDistributionDate = json.estimated_distribution_date
    model.id = json._id
    model.image = ImageModel.fromJson(json.image)
    model.optionValues = json.option_values?.map(i => ProductSkuOptionValueModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.name = json.name
    model.quantity = json.quantity
    model.price = json.price
    model.size = json.size
    model.type = json.type
    model.updatedAt = json.updated_at

    return model
  }
}