import {ImageJson, ImageModel} from "./image";

export interface StoreHeaderJson {
  cover_image?: ImageJson
  icon_image?: ImageJson
  _id?: string
  title?: string
  subtitle?: string
}

export class StoreHeaderModel {
  coverImage?: ImageModel
  iconImage?: ImageModel
  id?: string
  title?: string
  subtitle?: string

  static fromJson(json?: StoreHeaderJson): StoreHeaderModel | undefined {
    if (!json) return

    const model = new StoreHeaderModel()

    model.coverImage = ImageModel.fromJson(json.cover_image)
    model.iconImage = ImageModel.fromJson(json.icon_image)
    model.id = json._id
    model.title = json.title
    model.subtitle = json.subtitle?.replace(/<[^>]+>/g, '')?.replace('&amp;', '&')

    return model
  }
}