import {BaseRealmService} from "../realm/realmService";
import {Observable, of} from "rxjs";
import {EntityModel} from "./entityModel";
import {ObjectSchema} from "mongodb-realm";

export interface AddressJson {
  administrative_area?: string;
  country?: string;
  country_code?: string;
  locality?: string;
  postal_code?: string;
  thoroughfare?: string;
  sub_administrative_area?: string;
  subpremise?: string;
  sub_thoroughfare?: string;
}

export class AddressModel implements EntityModel<AddressModel> {
  public static schema: ObjectSchema = {
    name: 'AddressEntity',
    embedded: true,
    properties: {
      administrativeArea: 'string?',
      country: 'string?',
      countryCode: 'string?',
      locality: 'string?',
      postalCode: 'string?',
      subAdministrativeArea: 'string?',
      subThoroughfare: 'string?',
      subpremise: 'string?',
      thoroughfare: 'string?',
    },
  };

  schema = AddressModel.schema

  administrativeArea?: string
  country?: string
  countryCode?: string
  locality?: string
  postalCode?: string
  subAdministrativeArea?: string
  subThoroughfare?: string
  subpremise?: string
  thoroughfare?: string

  constructor(entity?: AddressModel | null) {
    if (!entity) return

    this.administrativeArea = entity.administrativeArea
    this.country = entity.country
    this.countryCode = entity.countryCode
    this.locality = entity.locality
    this.postalCode = entity.postalCode
    this.subAdministrativeArea = entity.subAdministrativeArea
    this.subThoroughfare = entity.subThoroughfare
    this.subpremise = entity.subpremise
    this.thoroughfare = entity.thoroughfare

    return this
  }

  static fromJson(json?: AddressJson): AddressModel | undefined {
    if (!json) return undefined

    const model = new AddressModel()

    model.thoroughfare = json.thoroughfare
    model.subThoroughfare = json.sub_thoroughfare
    model.subpremise = json.subpremise
    model.locality = json.locality
    model.country = json.country
    model.countryCode = json.country_code
    model.subAdministrativeArea = json.sub_administrative_area
    model.administrativeArea = json.administrative_area
    model.postalCode = json.postal_code

    return model
  }

  toJson(): AddressJson {
    return {
      thoroughfare: this.thoroughfare,
      sub_thoroughfare: this.subThoroughfare,
      subpremise: this.subpremise,
      locality: this.locality,
      country: this.country,
      country_code: this.countryCode,
      sub_administrative_area: this.subAdministrativeArea,
      administrative_area: this.administrativeArea,
      postal_code: this.postalCode
    }
  }

  getFullStreetAddress(): string | null {
    if (
      this.subThoroughfare &&
      this.thoroughfare &&
      this.subpremise
    ) {
      return `${this.subThoroughfare} ${this.thoroughfare}, ${this.subpremise}`
    } else if (
      this.subThoroughfare &&
      this.thoroughfare
    ) {
      return `${this.subThoroughfare} ${this.thoroughfare}`
    } else if (
      this.thoroughfare &&
      this.thoroughfare.length > 0
    ) {
      return this.thoroughfare
    } else {
      return null
    }
  }

  getCityStateZip(): string | null {
    if (
      this.locality &&
      this.administrativeArea &&
      this.postalCode
    ) {
      return `${this.locality}, ${this.administrativeArea} ${this.postalCode}`
    } else if (
      this.locality &&
      this.administrativeArea
    ) {
      return `${this.locality}, ${this.administrativeArea}`
    } else if (this.postalCode) {
      return this.postalCode
    } else {
      return null
    }
  }

  getFormattedAddress(): string | null {
    if (
      this.subThoroughfare &&
      this.thoroughfare &&
      this.locality &&
      this.administrativeArea &&
      this.postalCode &&
      this.subpremise
    ) {
      return `${this.subThoroughfare} ${this.thoroughfare}, ${this.subpremise}, ${this.locality}, ${this.administrativeArea} ${this.postalCode}`
    } else if (
      this.subThoroughfare &&
      this.thoroughfare &&
      this.locality &&
      this.administrativeArea &&
      this.postalCode
    ) {
      return `${this.subThoroughfare} ${this.thoroughfare}, ${this.locality}, ${this.administrativeArea} ${this.postalCode}`
    } else if (
      this.subThoroughfare &&
      this.thoroughfare
    ) {
      return `${this.subThoroughfare} ${this.thoroughfare}`
    } else if (
      this.thoroughfare
    ) {
      return this.thoroughfare
    } else {
      return null
    }
  }

  toEntityObservable(realmService: BaseRealmService): Observable<AddressModel> {
    return of(this)
  }
};