import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AutocompletePrediction} from "googlemaps";
import {BaseError} from "../../errors/baseError";
import {RootState} from "../../app/store";
import {LocationModel} from "../../models/location";
import {GenericError} from "../../errors/genericError";
import {LocalizationInfo} from "react-i18next";
import {PlacesError} from "../../errors/placesError";
import {UrlObject} from "url";

export interface LocationSearchItem {
  readonly title: LocalizationInfo | string
  readonly subtitle?: LocalizationInfo | string
  readonly id: string
  readonly isLoading: boolean
  readonly value: AutocompletePrediction | null
  readonly type: 'prediction' | 'current_location' | 'error' | 'no_results'
}

// State
export interface LocationSearchState {
  readonly query?: string
  readonly isSearching: boolean
  readonly predictions: AutocompletePrediction[]
  readonly inputAddress?: string
  readonly listIsOpen: boolean
  readonly items: LocationSearchItem[]
  readonly error?: BaseError<any> | null
}

export const locationSearchSlice = createSlice({
  name: "location_search",
  initialState: {
    isSearching: false,
    listIsOpen: false,
    predictions: [],
    items: [
      {
        title: { key: "location:location_title_use_current" },
        isLoading: false,
        value: null,
        id: 'current_location',
        type: 'current_location'
      }
    ]
  } as LocationSearchState,
  reducers: {
    reset(state) {
      state.inputAddress = undefined
      state.listIsOpen = false
      state.items = [
        {
          title: { key: "location:location_title_use_current" },
          isLoading: false,
          value: null,
          id: 'current_location',
          type: 'current_location'
        }
      ]
      state.predictions = []
      state.isSearching = false
    },
    locationSearchFocused(state) {
      state.inputAddress = undefined
      state.listIsOpen = true
    },
    setListOpen(state, action: PayloadAction<boolean>) {
      state.listIsOpen = action.payload
    },
    fetchAutocompletePredictions(state, action: PayloadAction<string>) {
      state.isSearching = action.payload.length > 2
      state.error = null
    },
    fetchAutocompletePredictionsSucceeded(state, action: PayloadAction<AutocompletePrediction[]>) {
      const currentLocationItem: LocationSearchItem = {
        title: { key: "location:location_title_use_current" },
        isLoading: false,
        value: null,
        id: 'current_location',
        type: 'current_location'
      }

      const items: LocationSearchItem[] = action.payload.map(prediction => {
        return {
          title: prediction.structured_formatting.main_text,
          subtitle: prediction.structured_formatting.secondary_text,
          isLoading: false,
          value: prediction,
          id: prediction.place_id,
          type: 'prediction'
        }
      })

      return {
        ...state,
        isSearching: false,
        items: [currentLocationItem].concat(items)
      }
    },
    fetchAutocompletePredictionsFailed(state, action: PayloadAction<Error>) {
      state.isSearching = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('LOCATION_SEARCH_ERROR')
        error.localizedMessage = { key: 'location:location_message_error_searching_address' }
        error.localizedTitle = { key: 'common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }

      state.items = [
        {
          title: { key: "location:location_title_use_current" },
          isLoading: false,
          value: null,
          id: 'current_location',
          type: 'current_location'
        },
        {
          title: state.error?.localizedMessage ?? { key: 'location:location_message_error_searching_address' },
          isLoading: false,
          value: null,
          id: 'error',
          type: (state.error as PlacesError)?.code === 'ZERO_RESULTS' ? 'no_results' : 'error'
        }
      ]
    },
    fetchLocationFromPlaceId(state, action: PayloadAction<{ placeId: string, save: boolean, url?: UrlObject | string }>) {
      state.error = null
      state.items = state.items.map(item => {
        if ((item.value as AutocompletePrediction)?.place_id === action.payload.placeId) {
          item.isLoading = true
          return item
        } else {
          return item
        }
      })
    },
    fetchLocationFromPlaceIdSucceeded(state, action: PayloadAction<LocationModel>) {
      state.inputAddress = action.payload.formattedAddress
      state.listIsOpen = false
      state.items = state.items.map(item => {
        item.isLoading = false
        return item
      })
    },
    fetchLocationFromPlaceIdFailed(state, action: PayloadAction<Error>) {
      state.items = state.items.map(item => {
        item.isLoading = false
        return item
      })

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('LOCATION_FETCH_ERROR')
        error.localizedMessage = { key: 'location:location_message_error_getting_address' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }

      state.items = [
        {
          title: { key: "location:location_title_use_current" },
          isLoading: false,
          value: null,
          id: 'current_location',
          type: 'current_location'
        },
        {
          title: state.error?.localizedMessage ?? { key: 'location:location_message_error_getting_address' },
          isLoading: false,
          value: null,
          id: 'error',
          type: 'error'
        }
      ]
    },
    fetchCurrentLocation(state, action: PayloadAction<{ save: boolean, url?: UrlObject | string }>) {
      state.error = null
      state.items = state.items.map(item => {
        if (item.type === 'current_location') {
          item.isLoading = true
          return item
        } else {
          return item
        }
      })
    },
    fetchCurrentLocationSucceeded(state, action: PayloadAction<LocationModel>) {
      state.inputAddress = action.payload.formattedAddress
      state.listIsOpen = false
      state.items = state.items.map(item => {
        if (item.type === 'current_location') {
          item.isLoading = false
          return item
        } else {
          return item
        }
      })
    },
    fetchCurrentLocationFailed(state, action: PayloadAction<Error>) {
      state.items = state.items.map(item => {
        if (item.type === 'current_location') {
          item.isLoading = false
          return item
        } else {
          return item
        }
      })

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('LOCATION_FETCH_ERROR')
        error.localizedMessage = { key: 'location:location_message_error_getting_address' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }

      state.items = [
        {
          title: { key: "location:location_title_use_current" },
          isLoading: false,
          value: null,
          type: 'current_location',
          id: 'current_location'
        },
        {
          title: state.error?.localizedMessage ?? { key: 'location:location_message_error_getting_address' },
          isLoading: false,
          value: null,
          type: 'error',
          id: 'error',
        }
      ]
    },
  },
})

// Reducer
export const locationSearchReducer = locationSearchSlice.reducer

// Actions
export const {
  reset,
  fetchCurrentLocation,
  fetchLocationFromPlaceId,
  fetchAutocompletePredictions,
  fetchAutocompletePredictionsSucceeded,
  fetchAutocompletePredictionsFailed,
  fetchLocationFromPlaceIdSucceeded,
  fetchLocationFromPlaceIdFailed,
  fetchCurrentLocationSucceeded,
  fetchCurrentLocationFailed,
  locationSearchFocused,
  setListOpen
} = locationSearchSlice.actions

// Selectors
export const selectItems = (state: RootState) => state.locationSearch.items;
export const selectInputAddress = (state: RootState) => state.locationSearch.inputAddress;
export const selectIsSearching = (state: RootState) => state.locationSearch.isSearching;
export const selectPredictions = (state: RootState) => state.locationSearch.predictions;
export const selectListIsOpen = (state: RootState) => state.locationSearch.listIsOpen;
export const selectError = (state: RootState) => state.locationSearch.error;
export const selectQuery = (state: RootState) => state.locationSearch.error;
