export const PhoneNumberUtils = {
  countryCodes: [
    {
      "name": "Australia",
      "isd": 61,
      "code": "AU"
    },
    {
      "name": "Canada",
      "isd": 1,
      "code": "CA"
    },
    {
      "name": "Puerto Rico",
      "isd": 1787,
      "code": "PR"
    },
    {
      "name": "Puerto Rico",
      "isd": 1939,
      "code": "PR"
    },
    {
      "name": "United Kingdom",
      "isd": 44,
      "code": "GB"
    },
    {
      "name": "United States",
      "isd": 1,
      "code": "US"
    },
    {
      "name": "U.S. Virgin Islands",
      "isd": 1340,
      "code": "VI"
    },
  ]
}