import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {authenticateSession, selectIsAuthenticatingSession} from "../../redux/auth/authSlice";
import {useEffect, useRef} from "react";
import {
  hideAlert,
  hideNotification,
  selectAddedCartItem,
  selectAlert,
  selectAlertIsVisible,
  selectNotification,
  selectNotificationIsVisible,
  setAddedCartItemVisible,
  showNotification
} from "../../redux/navigation/navigationSlice";
import {NotificationToast} from "../notifications/NotificationToast";
import {CartMenu} from "../cart/CartMenu";
import {selectCart} from "../../redux/store/storeSlice";
import {useRouter} from "next/router";
import {AddedCartMenuView} from "../cart/AddedCartItemMenuView";
import {CartModal} from "../cart/CartModal";
import {ProductDetailModal} from "../product/ProductDetailModal";
import useIsWindowFocused from "../../utils/useWindowIsFocused";
import {PaymentSelectorModal} from "../payment/PaymentSelectorModal";
import {PaymentEntryModal} from "../payment/PaymentEntryModal";
import {LoginModal} from "../login/LoginModal";
import {ContactInfoEntryModal} from "../contactInfoEntry/ContactInfoEntryModal";
import {PromoCodeEntryModal} from "../promoCodeEntry/PromoCodeEntryModal";
import {OrderDetailModal} from "../orderDetail/OrderDetailModal";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Footer} from "../footer/Footer";
import {ScreenMinHeightFullWithNavBar} from "../navBars/NavBarMain";
import {AffiliateOnboardModal} from "../affiliate/AffiliateOnboardModal";
import {fetchUser} from "../../redux/user/userSlice";
import {PromptDetailModal} from "../prompt/PromptDetailModal";
import {PushNotificationDetailModal} from "../pushNotification/PushNotificationDetailModal";
import {CampaignDetailModal} from "../campaign/CampaignDetailModal";
import {EmailDetailModal} from "../email/EmailDetailModal";

export interface AppProviderProps {
  children?: React.ReactNode
}

export const AppProvider: React.FC<AppProviderProps> = (props) => {
  const isAuthenticatingSession = useAppSelector(selectIsAuthenticatingSession)
  const dispatch = useAppDispatch();
  const router = useRouter();
  const notification = useAppSelector(selectNotification)
  const notificationIsVisible = useAppSelector(selectNotificationIsVisible)
  const alert = useAppSelector(selectAlert)
  const alertIsVisible = useAppSelector(selectAlertIsVisible)

  const cart = useAppSelector(selectCart)
  const addedCartItem = useAppSelector(selectAddedCartItem)

  const isFocused = useIsWindowFocused()

  const timeout = useRef<number>();

  const showFooter = router.route === '/store/[id]'
    || router.route === '/store-section/[id]'
    || router.route === '/store-feed'
    || router.route === '/'
    || router.route === '/upgrade'
    || router.route === '/orders'
    || router.route === '/order-detail'
    || router.route === '/store-category/[id]'

  useEffect(() => {
    const isStoreRoute = router.route === '/store/[id]'
      || router.route === '/store-section/[id]'
      || router.route === '/store-category/[id]'

    if (isStoreRoute && cart && addedCartItem) {
      dispatch(setAddedCartItemVisible(true))

      window.clearTimeout(timeout.current)

      timeout.current = window.setTimeout(() => {
        dispatch(setAddedCartItemVisible(false))

        window.clearTimeout(timeout.current)
      }, 4000)
    }

    return () => {
      window.clearTimeout(timeout.current)
    }
  }, [addedCartItem])

  useEffect(() => {
    if (isFocused) {
      dispatch(authenticateSession())
    }
  }, [isFocused, dispatch])

  useEffect(() => {
    window.addEventListener("offline", (event) => {
      dispatch(showNotification({
        title: { key: 'common:common_error_title_connection_unavailable' },
        message: { key: 'common:common_error_message_connection_unavailable' },
        status: "failure"
      }))
    });

    window.addEventListener("online", (event) => {
      dispatch(hideNotification())
    });

    return () => {
      window.removeEventListener('online', () => {})
      window.removeEventListener('offline', () => {})
    }
  }, []);

  return <div>
    <NotificationToast
      isVisible={notificationIsVisible}
      status={notification?.status}
      title={notification?.title}
      subtitle={notification?.message}
      onClose={() => {
        dispatch(hideNotification())
      }}
    />

    <AlertDialog
      isOpen={alertIsVisible}
      title={alert?.title}
      message={alert?.message}
      onClose={() => dispatch(hideAlert())}
    />

    <div
      className={`hidden lg:block`}>
      <CartMenu />
      <AddedCartMenuView />
    </div>

    <div className={`lg:hidden`}>
      <CartModal />
    </div>

    <LoginModal />
    <AffiliateOnboardModal />
    <ProductDetailModal />
    <PaymentSelectorModal />
    <PaymentEntryModal />
    <PromoCodeEntryModal />
    <ContactInfoEntryModal />
    <OrderDetailModal />
    <PromptDetailModal />
    <EmailDetailModal />
    <PushNotificationDetailModal />
    <CampaignDetailModal />

    <div className={ScreenMinHeightFullWithNavBar}>
      {props.children}
    </div>

    {showFooter && <Footer/>}
  </div>
}