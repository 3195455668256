import {BaseError} from "./baseError";
import {LocalizationInfo} from "react-i18next";

export type RealmServiceErrorCode = 'NOT_LOGGED_IN'

export class RealmServiceError extends BaseError<RealmServiceErrorCode> {
  localizedMessage: LocalizationInfo | string
  localizedTitle: LocalizationInfo | string

  constructor(code: RealmServiceErrorCode) {
    super(code)

    switch (code) {
      case "NOT_LOGGED_IN":
        this.localizedMessage = { key: "common:realm_service_error_message_logged_out"}
        this.localizedTitle = { key: "common:realm_service_error_title_login"}
        break
      default:
        this.localizedMessage = { key: "common:realm_service_error_message_problem_with_data"}
        this.localizedTitle = { key: "common:common_error_title_something_went_wrong"}
        break
    }
  }
}