import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, mergeMap, of, scheduled} from "rxjs";
import {fetchOnboardingData, fetchOnboardingDataFailed, fetchOnboardingDataSucceeded} from "./onboardingSlice";
import {UserService} from "../../services/userService";
import {logErrorRx} from "../../utils/logError";

export const fetchOnboardingDataEpics: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchOnboardingData.match),
    mergeMap(action => {
      return UserService.fetchOnboardingLink()
        .pipe(
          mergeMap(data => {
            return scheduled([fetchOnboardingDataSucceeded(data)], asyncScheduler)
          })
        )
    }),
    catchError(error => logErrorRx(error)),
    catchError(error => of({
      type: fetchOnboardingDataFailed.type,
      payload: error,
      error: true
    }))
  )
}