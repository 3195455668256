function uniq(arr: any[]) {
  return arr.filter((el: any, index: number, self) => self.indexOf(el) === index);
}

function normalizeLocales(arr: any[]) {
  return arr.map((el) => {
    if (!el || el.indexOf('-') === -1 || el.toLowerCase() !== el) {
      return el;
    }

    const splitEl = el.split('-');
    return `${splitEl[0]}-${splitEl[1].toUpperCase()}`;
  });
}

function getUserLocalesInternal() {
  let languageList: string[] = [];

  if (typeof window !== "undefined" && navigator) {
    if (navigator.languages) {
      languageList = languageList.concat(navigator.languages);
    }
    if (navigator.language) {
      languageList.push(navigator.language);
    }
  }

  return normalizeLocales(uniq(languageList));
}

let locales: string[];

export const LocaleUtils = {
  getUserLocales() {
    if (locales) {
      return locales;
    } else {
      locales = getUserLocalesInternal()
      return locales;
    }
  },
  getUserLocale(): string | undefined {
    return this.getUserLocales()[0]
  }
}