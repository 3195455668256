import {RootEpic} from "../../app/store";
import {filter, tap} from "rxjs/operators";
import {fetchStore, fetchStoreFailed, fetchStoreSucceeded} from "./storeSlice";
import {catchError, map, mergeMap, of} from "rxjs";
import {StoreService} from "../../services/storeService";
import {logErrorRx} from "../../utils/logError";
import {EventLogger} from "../../utils/eventLogger";

export const fetchStoreEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(fetchStore.match),
    mergeMap(action => {
      return StoreService.fetchStore(action.payload)
        .pipe(
          tap({
            next: (store) => {
              store && EventLogger.updateGlobalPropertiesWithStore(store)
            }
          }),
          map(fetchStoreSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchStoreFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
})