import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled} from "rxjs";
import {
  createCampaign, createCampaignFailed,
  createCampaignSucceeded, deleteCampaign, deleteCampaignFailed, deleteCampaignSucceeded,
  fetchCampaign,
  fetchCampaignFailed,
  fetchCampaignSucceeded, reset, updateCampaign, updateCampaignFailed, updateCampaignSucceeded
} from "./campaignDetailSlice";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";
import {fetchCampaigns, reset as resetCampaignList, setCurrentPage} from "../campaign/campaignListSlice";

export const fetchCampaignEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchCampaign.match),
    mergeMap(action => {
      return MessageService.fetchCampaign(action.payload)
        .pipe(
          map(fetchCampaignSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchCampaignFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const createCampaignEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(createCampaign.match),
    mergeMap(action => {
      return MessageService.createCampaign(action.payload.campaign)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.campaignList.currentPage

            return scheduled([
              createCampaignSucceeded(),
              reset(),
              resetCampaignList(),
              pageNumber === 1 ? fetchCampaigns(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: createCampaignFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const updateCampaignEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(updateCampaign.match),
    mergeMap(action => {
      return MessageService.updateCampaign(action.payload.campaign, action.payload.id)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.campaignList.currentPage

            return scheduled([
              updateCampaignSucceeded(),
              reset(),
              resetCampaignList(),
              pageNumber === 1 ? fetchCampaigns(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: updateCampaignFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deleteCampaignEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(deleteCampaign.match),
    mergeMap(action => {
      return MessageService.deleteCampaign(action.payload)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.campaignList.currentPage

            return scheduled([
              deleteCampaignSucceeded(),
              reset(),
              resetCampaignList(),
              pageNumber === 1 ? fetchCampaigns(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: deleteCampaignFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}