import {CardJson, CardModel} from "./card";
import {LocationJson, LocationModel} from "./location";
import {DistributionMethod} from "./distributionMethod";
import {SalesOrderLineItemJson, SalesOrderLineItemModel} from "./salesOrderLineItem";
import {PaymentSummaryJson, PaymentSummaryModel} from "./paymentSummary";
import {VendorJson, VendorModel} from "./vendor";
import {PickupLocationJson, PickupLocationModel} from "./pickupLocation";
import {SalesOrderFulfillmentJson, SalesOrderFulfillmentModel} from "./salesOrderFulfilment";
import {CustomerJson, CustomerModel} from "./customer";
import {PromoCodeJson, PromoCodeModel} from "./promoCode";

export type SalesOrderStatus = 'canceled' | 'created' | 'fulfilled' | 'paid' | 'refunded'

export interface SalesOrderJson {
  _id?: string
  card?: CardJson
  created_at?: Date
  updated_at?: Date
  status?: SalesOrderStatus
  delivery_location?: LocationJson
  pickup_location?: PickupLocationJson
  distribution_method?: DistributionMethod
  line_items?: SalesOrderLineItemJson[]
  shopify_fulfillments?: SalesOrderFulfillmentJson[]
  payment_summary?: PaymentSummaryJson
  customer?: CustomerJson
  promo_code?: PromoCodeJson
  store_id?: string
  vendor?: VendorJson
}

export class SalesOrderModel {
  createdAt?: Date
  card?: CardModel
  promoCode?: PromoCodeModel
  deliveryLocation?: LocationModel
  distributionMethod?: DistributionMethod
  id?: string
  lineItems: SalesOrderLineItemModel[] = []
  fulfillments: SalesOrderFulfillmentModel[] = []
  paymentSummary?: PaymentSummaryModel
  pickupLocation?: PickupLocationModel
  customer?: CustomerModel
  status?: SalesOrderStatus
  storeId?: string
  updatedAt?: Date
  vendor?: VendorModel

  static fromJson(json?: SalesOrderJson): SalesOrderModel | undefined {
    if (!json) return

    const model = new SalesOrderModel()

    model.createdAt = json.created_at
    model.card = CardModel.fromJson(json.card)
    model.promoCode = PromoCodeModel.fromJson(json.promo_code)
    model.deliveryLocation = LocationModel.fromJson(json.delivery_location)
    model.distributionMethod = json.distribution_method
    model.id = json._id
    model.lineItems = json.line_items
      ?.map((i) => SalesOrderLineItemModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.fulfillments = json.shopify_fulfillments
      ?.map((i) => SalesOrderFulfillmentModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.paymentSummary = PaymentSummaryModel.fromJson(json.payment_summary)
    model.pickupLocation = PickupLocationModel.fromJson(json.pickup_location)
    model.customer = CustomerModel.fromJson(json.customer)
    model.status = json.status
    model.storeId = json.store_id
    model.updatedAt = json.updated_at
    model.vendor = VendorModel.fromJson(json.vendor)

    return model
  }
}