export type EmailTemplateDataType = 'user' | 'featured_products'

export interface EmailStatsJson {
  blocks?: number
  bounce_drops?: number
  bounces?: number
  deferred?: number
  delivered?: number
  invalid_emails?: number
  processed?: number
  requests?: number
  spam_report_drops?: number
  spam_reports?: number
  unsubscribe_drops?: number
  unsubscribes?: number
  clicks?: number
  unique_clicks?: number
  opens?: number
  unique_opens?: number
}

export interface EmailJson {
  _id?: string
  created_at?: Date
  updated_at?: Date
  sender_name?: string
  sender_email?: string
  subject?: string
  formatted_subject?: string
  utm_campaign?: string
  utm_source?: string
  utm_medium?: string
  template_data_types?: EmailTemplateDataType[]
  html?: string
  send_at?: Date
  name?: string
  stats?: EmailStatsJson
}

export class EmailStatsModel {
  blocks: number = 0
  bounceDrops: number = 0
  bounces: number = 0
  deferred: number = 0
  delivered: number = 0
  invalidEmails: number = 0
  processed: number = 0
  requests: number = 0
  spamReportDrops: number = 0
  spamReports: number = 0
  unsubscribeDrops: number = 0
  unsubscribes: number = 0
  clicks: number = 0
  uniqueClicks: number = 0
  opens: number = 0
  uniqueOpens: number = 0

  constructor(model?: EmailStatsModel) {
    if (!model) return

    this.blocks = model.blocks
    this.bounceDrops = model.bounceDrops
    this.bounces = model.bounces
    this.deferred = model.deferred
    this.delivered = model.delivered
    this.invalidEmails = model.invalidEmails
    this.processed = model.processed
    this.requests = model.requests
    this.spamReportDrops = model.spamReportDrops
    this.spamReports = model.spamReports
    this.unsubscribeDrops = model.unsubscribeDrops
    this.unsubscribes = model.unsubscribes
    this.clicks = model.clicks
    this.uniqueClicks = model.uniqueClicks
    this.opens = model.opens
    this.uniqueOpens = model.uniqueOpens

    return this
  }

  static fromJson(json?: EmailStatsJson): EmailStatsModel | undefined {
    if (!json) return

    const model = new EmailStatsModel()

    model.blocks = json.blocks ?? model.blocks
    model.bounceDrops = json.bounce_drops ?? model.bounceDrops
    model.bounces = json.bounces ?? model.bounces
    model.deferred = json.deferred ?? model.deferred
    model.delivered = json.delivered ?? model.delivered
    model.invalidEmails = json.invalid_emails ?? model.invalidEmails
    model.processed = json.processed ?? model.processed
    model.requests = json.requests ?? model.requests
    model.spamReportDrops = json.spam_report_drops ?? model.spamReportDrops
    model.spamReports = json.spam_reports ?? model.spamReports
    model.unsubscribeDrops = json.unsubscribe_drops ?? model.unsubscribeDrops
    model.unsubscribes = json.unsubscribes ?? model.unsubscribes
    model.clicks = json.clicks ?? model.clicks
    model.uniqueClicks = json.unique_clicks ?? model.uniqueClicks
    model.opens = json.opens ?? model.opens
    model.uniqueOpens = json.unique_opens ?? model.uniqueOpens

    return model
  }

  toJson(): EmailStatsJson {
    return {
      blocks: this.blocks,
      bounce_drops: this.bounceDrops,
      bounces: this.bounces,
      deferred: this.deferred,
      delivered: this.delivered,
      invalid_emails: this.invalidEmails,
      processed: this.processed,
      requests: this.requests,
      spam_report_drops: this.spamReportDrops,
      spam_reports: this.spamReports,
      unsubscribe_drops: this.unsubscribeDrops,
      unsubscribes: this.unsubscribes,
      clicks: this.clicks,
      unique_clicks: this.uniqueClicks,
      opens: this.opens,
      unique_opens: this.uniqueOpens
    }
  }
}

export class EmailModel {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  senderName?: string
  senderEmail?: string
  subject?: string
  formattedSubject?: string
  html?: string
  sendAt?: Date
  utmCampaign?: string
  utmSource?: string
  utmMedium?: string
  name?: string
  templateDataTypes: EmailTemplateDataType[] = []
  stats?: EmailStatsModel

  constructor(model?: EmailModel) {
    if (!model) return

    this.id = model.id
    this.createdAt = model.createdAt
    this.updatedAt = model.updatedAt
    this.senderName = model.senderName
    this.senderEmail = model.senderEmail
    this.subject = model.subject
    this.formattedSubject = model.formattedSubject
    this.html = model.html
    this.sendAt = model.sendAt
    this.utmCampaign = model.utmCampaign
    this.utmSource = model.utmSource
    this.utmMedium = model.utmMedium
    this.name = model.name
    this.templateDataTypes = model.templateDataTypes
    this.stats = new EmailStatsModel(model.stats)

    return this
  }

  static fromJson(json?: EmailJson): EmailModel | undefined {
    if (!json) return

    const model = new EmailModel()

    model.id = json._id
    model.createdAt = json.created_at
    model.updatedAt = json.updated_at
    model.senderName = json.sender_name
    model.senderEmail = json.sender_email
    model.subject = json.subject
    model.formattedSubject = json.formatted_subject
    model.html = json.html
    model.sendAt = json.send_at
    model.utmCampaign = json.utm_campaign
    model.utmSource = json.utm_source
    model.utmMedium = json.utm_medium
    model.name = json.name
    model.templateDataTypes = json.template_data_types ?? []
    model.stats = EmailStatsModel.fromJson(json.stats)

    return model
  }

  toJson(): EmailJson {
    return {
      _id: this.id,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      sender_name: this.senderName,
      sender_email: this.senderEmail,
      subject: this.subject,
      html: this.html,
      send_at: this.sendAt,
      utm_campaign: this.utmCampaign,
      utm_source: this.utmSource,
      utm_medium: this.utmMedium,
      name: this.name,
      template_data_types: this.templateDataTypes,
      stats: this.stats?.toJson(),
    }
  }
}