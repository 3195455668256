import {MessageCampaignModel} from "../../models/messageCampaign";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";

interface CampaignDetailState {
  readonly isLoading: boolean
  readonly isSubmitting: boolean
  readonly isDeleting: boolean
  readonly campaignId?: string
  readonly config: 'create' | 'update'
  readonly campaign?: MessageCampaignModel
  readonly error?: BaseError<any>
  readonly shouldClose: boolean
}

const campaignDetailSlice = createSlice({
  name: 'campaign_detail',
  initialState: {
    isLoading: false,
    isSubmitting: false,
    isDeleting: false,
    config: 'create',
  } as CampaignDetailState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.isSubmitting = false
      state.isDeleting = false
      state.campaign = undefined
      state.campaignId = undefined
      state.error = undefined
      state.shouldClose = false
      state.config = 'create'
    },
    clearError(state) {
      state.error = undefined
    },
    fetchCampaign(state, action: PayloadAction<string>) {
      state.config = 'update'
      state.isLoading = true
    },
    createCampaign(state, action: PayloadAction<{ campaign: MessageCampaignModel }>) {
      state.isSubmitting = true
    },
    updateCampaign(state, action: PayloadAction<{ campaign: MessageCampaignModel, id: string }>) {
      state.isSubmitting = true
    },
    deleteCampaign(state, action: PayloadAction<string>) {
      state.isDeleting = true
    },
    fetchCampaignSucceeded(state, action: PayloadAction<MessageCampaignModel>) {
      state.isLoading = false
      state.campaign = action.payload
      state.campaignId = action.payload.id
    },
    createCampaignSucceeded(state) {
      state.isSubmitting = false
      state.shouldClose = true
    },
    updateCampaignSucceeded(state) {
      state.isSubmitting = false
      state.shouldClose = true
    },
    deleteCampaignSucceeded(state) {
      state.isDeleting = false
      state.shouldClose = true
    },
    fetchCampaignFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('CAMPAIGN_ERROR')
        error.localizedMessage = 'There was a problem getting this campaign'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    createCampaignFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('CAMPAIGN_CREATE_ERROR')
        error.localizedMessage = 'There was a problem creating this campaign'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    updateCampaignFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('CAMPAIGN_UPDATE_ERROR')
        error.localizedMessage = 'There was a problem updating this campaign'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    deleteCampaignFailed(state, action: PayloadAction<Error>) {
      state.isDeleting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('CAMPAIGN_DELETE_ERROR')
        error.localizedMessage = 'There was a problem deleting this campaign'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const campaignDetailReducer = campaignDetailSlice.reducer

export const {
  reset,
  fetchCampaign,
  fetchCampaignFailed,
  fetchCampaignSucceeded,
  createCampaign,
  createCampaignFailed,
  createCampaignSucceeded,
  updateCampaign,
  updateCampaignFailed,
  updateCampaignSucceeded,
  deleteCampaign,
  deleteCampaignFailed,
  deleteCampaignSucceeded,
  clearError
} = campaignDetailSlice.actions

export const selectError = (state: RootState) => state.campaignDetail.error
export const selectCampaign = (state: RootState) => state.campaignDetail.campaign
export const selectConfig = (state: RootState) => state.campaignDetail.config
export const selectIsLoading = (state: RootState) => state.campaignDetail.isLoading
export const selectIsSubmitting = (state: RootState) => state.campaignDetail.isSubmitting
export const selectIsDeleting = (state: RootState) => state.campaignDetail.isDeleting
export const selectShouldClose = (state: RootState) => state.campaignDetail.shouldClose