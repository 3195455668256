import {RootEpic} from "../../app/store";
import {filter, tap} from "rxjs/operators";
import {
  fetchStoreSectionFailed,
  fetchStoreSectionForCategoryId,
  fetchStoreSectionForId,
  fetchStoreSectionSucceeded
} from "./storeSectionDetailSlice";
import {catchError, map, mergeMap, of} from "rxjs";
import {StoreService} from "../../services/storeService";
import {logErrorRx} from "../../utils/logError";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export const fetchStoreSectionForCategoryEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(fetchStoreSectionForCategoryId.match),
    mergeMap(action => {
      return StoreService.fetchStoreSectionForCategory(action.payload)
        .pipe(
          tap({
            next: (storeSection) => {
              const category = storeSection.categories.filter(((c) => c.id === action.payload))[0]
              category && EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Product Category Viewed', category }))
            }
          }),
          map(fetchStoreSectionSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchStoreSectionFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
})

export const fetchStoreSectionForIdEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(fetchStoreSectionForId.match),
    mergeMap(action => {
      return StoreService.fetchStoreSection(action.payload)
        .pipe(
          tap({
            next: (storeSection) => {
              EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Store Section Viewed', storeSection }))
            }
          }),
          map(fetchStoreSectionSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchStoreSectionFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
})