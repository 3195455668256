import React, {useState} from "react";
import NextImage, {ImageProps as NextImageProps} from "next/image";

export interface ImageProps extends NextImageProps {
  sizeClass?: string
}

export const Image: React.FC<ImageProps> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return <div className={`transition-opacity duration-300 ${props.sizeClass} ${isLoaded ? 'opacity-100' : 'opacity-0'} relative`}>
    <NextImage
      src={props.src}
      alt={props.alt}
      layout="fill"
      onLoad={() => {
        setIsLoaded(true)
      }}
      objectFit={props.objectFit}
      className={props.className}
    />
  </div>
}