import {ProductCategoryJson, ProductCategoryModel} from "./productCategory";
import {CurrencyCode} from "./currencyCode";
import {DistributionMethod} from "./distributionMethod";
import {ProductJson, ProductModel} from "./product";
import {StoreSelectorOptionBadgeJson, StoreSelectorOptionBadgeModel} from "./storeSelectorOptionBadge";
import {ImageJson, ImageModel} from "./image";

export interface StoreSelectorOptionJson {
  categories?: ProductCategoryJson[]
  currency_code?: CurrencyCode
  delivery_fee?: number
  pickup_fee?: number
  distribution_options?: DistributionMethod[]
  featured_menu_items?: ProductJson[]
  badges?: StoreSelectorOptionBadgeJson[]
  icon_image?: ImageJson
  is_primary?: boolean
  min_order_total?: number
  min_order_item_count?: number
  total_item_count?: number
  store_id?: string
  subtitle?: string
  title?: string
  vendor_id?: string
}

export class StoreSelectorOptionModel {
  categories: ProductCategoryModel[] = []
  currencyCode?: CurrencyCode
  deliveryFee?: number
  distributionOptions: DistributionMethod[] = []
  featuredProducts: ProductModel[] = []
  badges: StoreSelectorOptionBadgeModel[] = []
  iconImage?: ImageModel
  isPrimary?: boolean
  minOrderTotal?: number
  minOrderItemCount?: number
  totalItemCount?: number
  pickupFee?: number
  storeId?: string
  subtitle?: string
  title?: string
  vendorId?: string

  static fromJson(json?: StoreSelectorOptionJson) : StoreSelectorOptionModel | undefined {
    if (!json) return

    const model = new StoreSelectorOptionModel()

    model.categories = json.categories
      ?.map((i) => ProductCategoryModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.currencyCode = json.currency_code
    model.deliveryFee = json.delivery_fee
    model.distributionOptions = json.distribution_options ?? []
    model.featuredProducts = json.featured_menu_items
      ?.map((i) => ProductModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.badges = json.badges
      ?.map((i) => StoreSelectorOptionBadgeModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.iconImage = ImageModel.fromJson(json.icon_image)
    model.isPrimary = json.is_primary
    model.minOrderTotal = json.min_order_total
    model.minOrderItemCount = json.min_order_item_count
    model.totalItemCount = json.total_item_count
    model.pickupFee = json.pickup_fee
    model.storeId = json.store_id
    model.subtitle = json.subtitle
    model.title = json.title
    model.vendorId = json.vendor_id

    return model
  }
}