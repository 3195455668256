import {PromptModel} from "../../models/prompt";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";

interface PromptDetailState {
  readonly isLoading: boolean
  readonly isSubmitting: boolean
  readonly isDeleting: boolean
  readonly promptId?: string
  readonly config: 'create' | 'update'
  readonly prompt?: PromptModel
  readonly error?: BaseError<any>
  readonly shouldClose: boolean
}

const promptDetailSlice = createSlice({
  name: 'prompt_detail',
  initialState: {
    isLoading: false,
    isSubmitting: false,
    isDeleting: false,
    config: 'create',
  } as PromptDetailState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.isSubmitting = false
      state.isDeleting = false
      state.prompt = undefined
      state.promptId = undefined
      state.error = undefined
      state.shouldClose = false
      state.config = 'create'
    },
    clearError(state) {
      state.error = undefined
    },
    fetchPrompt(state, action: PayloadAction<string>) {
      state.config = 'update'
      state.isLoading = true
    },
    createPrompt(state, action: PayloadAction<{ prompt: PromptModel, cohortId?: string }>) {
      state.isSubmitting = true
    },
    updatePrompt(state, action: PayloadAction<{ prompt: PromptModel, id: string, cohortId?: string }>) {
      state.isSubmitting = true
    },
    deletePrompt(state, action: PayloadAction<string>) {
      state.isDeleting = true
    },
    fetchPromptSucceeded(state, action: PayloadAction<PromptModel>) {
      state.isLoading = false
      state.prompt = action.payload
      state.promptId = action.payload.promptId
    },
    createPromptSucceeded(state) {
      state.isSubmitting = false
      state.shouldClose = true
    },
    updatePromptSucceeded(state) {
      state.isSubmitting = false
      state.shouldClose = true
    },
    deletePromptSucceeded(state) {
      state.isDeleting = false
      state.shouldClose = true
    },
    fetchPromptFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PROMPT_ERROR')
        error.localizedMessage = 'There was a problem getting this prompt'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    createPromptFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PROMPT_CREATE_ERROR')
        error.localizedMessage = 'There was a problem creating this prompt'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    updatePromptFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PROMPT_UPDATE_ERROR')
        error.localizedMessage = 'There was a problem updating this prompt'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    deletePromptFailed(state, action: PayloadAction<Error>) {
      state.isDeleting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PROMPT_DELETE_ERROR')
        error.localizedMessage = 'There was a problem deleting this prompt'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const promptDetailReducer = promptDetailSlice.reducer

export const {
  reset,
  fetchPrompt,
  fetchPromptFailed,
  fetchPromptSucceeded,
  createPrompt,
  createPromptFailed,
  createPromptSucceeded,
  updatePrompt,
  updatePromptFailed,
  updatePromptSucceeded,
  deletePrompt,
  deletePromptFailed,
  deletePromptSucceeded,
  clearError
} = promptDetailSlice.actions

export const selectError = (state: RootState) => state.promptDetail.error
export const selectPrompt = (state: RootState) => state.promptDetail.prompt
export const selectConfig = (state: RootState) => state.promptDetail.config
export const selectIsLoading = (state: RootState) => state.promptDetail.isLoading
export const selectIsSubmitting = (state: RootState) => state.promptDetail.isSubmitting
export const selectIsDeleting = (state: RootState) => state.promptDetail.isDeleting
export const selectShouldClose = (state: RootState) => state.promptDetail.shouldClose