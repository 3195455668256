import {ProductJson, ProductModel} from "./product";
import {VendorJson, VendorModel} from "./vendor";

export type DiscountType = 'shipping' | 'product'

export interface DiscountJson {
  amount_off?: number
  percent_off?: number
  products?: ProductJson[]
  type?: DiscountType
}

export interface PromoCodeJson {
  _id?: string
  code?: string
  title?: string
  description?: string
  discounts?: DiscountJson[]
  vendors?: VendorJson[]
  expires_on?: Date
  redemption_limit?: number
  min_order_total?: number
  min_order_item_count?: number
  active_on?: Date
}

export class DiscountModel {
  amountOff?: number
  percentOff?: number
  products: ProductModel[] = []
  type?: DiscountType

  constructor(model?: DiscountModel) {
    if (!model) return

    this.amountOff = model.amountOff
    this.percentOff = model.percentOff
    this.products = model.products.map(i => new ProductModel(i))
    this.type = model.type
  }

  static fromJson(json?: DiscountJson): DiscountModel | undefined {
    if (!json) return

    return {
      amountOff: json.amount_off,
      percentOff: json.percent_off,
      products: json.products?.map(i => ProductModel.fromJson(i))
        .flatMap(i => i ? [i] : []) ?? [],
      type: json.type
    }
  }
}

export class PromoCodeModel {
  id?: string
  code?: string
  title?: string
  description?: string
  discounts: DiscountModel[] = []
  vendors: VendorModel[] = []
  expiresOn?: Date
  redemptionLimit?: number
  minOrderTotal?: number
  minOrderItemCount?: number
  activeOn?: Date

  constructor(model?: PromoCodeModel) {
    if (!model) return

    this.id = model.id
    this.code = model.code
    this.title = model.title
    this.description = model.description
    this.discounts = model.discounts.map(i => new DiscountModel(i))
    this.vendors = model.vendors.map(i => new VendorModel(i))
    this.expiresOn = model.expiresOn
    this.redemptionLimit = model.redemptionLimit
    this.minOrderTotal = model.minOrderTotal
    this.minOrderItemCount = model.minOrderItemCount
    this.activeOn = model.activeOn
  }

  static fromJson(json?: PromoCodeJson): PromoCodeModel | undefined {
    if (!json) return

    return {
      id: json._id,
      code: json.code,
      title: json.title,
      description: json.description,
      discounts: json.discounts?.map(i => DiscountModel.fromJson(i))
        .flatMap(i => i ? [i] : []) ?? [],
      vendors: json.vendors?.map(i => VendorModel.fromJson(i))
        .flatMap(i => i ? [i] : []) ?? [],
      expiresOn: json.expires_on,
      activeOn: json.active_on,
      redemptionLimit: json.redemption_limit,
      minOrderItemCount: json.min_order_item_count,
      minOrderTotal: json.min_order_total
    }
  }
}