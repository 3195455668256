import {ShoppingCartItemModel} from "../../models/shoppingCartItem";
import React from "react";
import {Button} from "../buttons/Button";
import {ArrowDownIcon} from "../icons";
import {Body, Heading3, Paragraph, TextStyles} from "../text";
import {CurrencyUtils} from "../../utils/currencyUtils";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectIsUpdatingProduct, updateItemInCart} from "../../redux/cart/cartSlice";
import {useRouter} from "next/router";
import {ImageUtils} from "../../utils/imageUtils";
import {Image} from "../images/Image";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "next-i18next";
import {useModalRouting} from "../../utils/useModalRouting";
import useWindowDimensions from "../../utils/useWindowDimensions";

export interface ShoppingCartItemProps {
  item: ShoppingCartItemModel
  isLastItem: boolean
  isLoading: boolean
}

export const CartItemView: React.FC<ShoppingCartItemProps> = ({ item, isLastItem, isLoading }) => {
  const unitPrice = item.price * item.quantity
  const totalPrice = CurrencyUtils.stringFromPrice(unitPrice, item.currencyCode)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { t } = useTranslation()
  const { createRoute } = useModalRouting();
  const isUpdating = useAppSelector(state => selectIsUpdatingProduct(state, item.productId))
  const dimensions = useWindowDimensions()

  const imageUrl = ImageUtils.createUrlFromImage(item.image)

  return (
    <div onClick={e => {
      const productId = item.productId
      if (!productId) return

      e.stopPropagation()

      router.push(
        createRoute({ name: 'product_detail', query: { product_id: productId }, returnTo: dimensions.width < 1024 ? { name: 'cart', as: `/cart/${item.storeId}` } : undefined }),
        `/product/${productId}`,
        { shallow: true }
      );
    }}>
      <div className={`flex flex-row items-center space-x-2.5 lg:space-x-3 py-5 cursor-pointer ${!isLastItem ? 'border-b border-fg-gray-surface' : ''}`}>
        {
          (() => {
            if (isLoading) {
              return <Skeleton className="!h-10 !w-17 !rounded-full" />
            } else {
              return <div className="relative">
                <div className="absolute top-0 left-0">
                  <Button
                    isLoading={isUpdating}
                    buttonStyle="secondary"
                    trailingIconSizeClass="h-3 w-3"
                    trailingicon={<ArrowDownIcon />}>
                    {item.quantity}
                  </Button>
                </div>

                <select
                  id="quantity"
                  name="quantity"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  defaultValue={item.quantity}
                  onChange={e => {
                    const storeId = item.storeId
                    if (!storeId) return
                    dispatch(updateItemInCart({ item, quantity: parseInt(e.target.value), storeId }))
                  }}
                  className={`appearance-none opacity-0 transition-colors bg-fg-gray-surface hover:bg-fg-gray-surface-dark border-0 cursor-pointer text-center focus:ring-0 rounded-full px-1 lg:px-0.5 py-2 flex flex-row items-center ${TextStyles.Body}`}
                >
                  <option value={0}>{t('common:common_title_remove')}</option>
                  {Array.from({ length: 40 }, (_, i) => i + 1).map(i => {
                    return <option key={i.toString()} value={i}>{i}</option>
                  })}
                </select>
              </div>
            }
          })()
        }

        {
          (() => {
            if (isLoading) {
              return <Skeleton className="!h-12 !w-12 !rounded-lg" />
            } else if (imageUrl) {
              return <div className="flex-shrink-0">
                <Image
                  src={imageUrl}
                  alt={item.name}
                  layout="fill"
                  objectFit="cover"
                  sizeClass="h-12 w-12"
                  className="rounded-lg bg-fg-gray-surface"
                />
              </div>
            }
          })()
        }

        <div className="flex-grow flex flex-col space-y-1">
          <Body className="text-left">
            {isLoading ?
              <span>
                <Skeleton className="!w-full" />
                <Skeleton className="!w-1/2" />
              </span> :
              item.name
            }
          </Body>
          {item.optionValues.filter(o => o.value !== 'Default Title').map(o => {
            return <Paragraph key={o.optionValueId} className="text-fg-gray-dark">
              {o.value}
            </Paragraph>
          })}
        </div>
        <Heading3 className="text-right">{isLoading ? <Skeleton className="!w-12" /> : totalPrice}</Heading3>
      </div>
    </div>
  )
}