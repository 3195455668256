import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SalesOrderModel} from "../../models/salesOrder";
import {BaseError} from "../../errors/baseError";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {LocationModel} from "../../models/location";
import {PickupLocationModel} from "../../models/pickupLocation";
import {PromoCodeModel} from "../../models/promoCode";
import {CardModel} from "../../models/card";
import {LocalizationInfo} from "react-i18next";
import {SalesOrderFulfillmentModel} from "../../models/salesOrderFulfilment";
import {CountryCode, formatNumber, ParsedNumber, parseNumber} from "libphonenumber-js";
import {CustomerModel} from "../../models/customer";
import {CurrencyUtils} from "../../utils/currencyUtils";
import {SalesOrderLineItemModel} from "../../models/salesOrderLineItem";
import moment from "moment";

interface OrderDetailState {
  readonly isLoading: boolean
  readonly orderId?: string
  readonly order?: SalesOrderModel
  readonly sections: OrderDetailSection[]
  readonly error?: BaseError<any>
}

type OrderDetailSection = { title: string | LocalizationInfo, id: 'shipping_details' | 'order_summary' | 'order_status' | 'payment' | string, items: OrderDetailItem[] }
type OrderDetailIcon = 'refunded' | 'box' | 'marker' | 'in_transit' | 'frying_pan' | 'delivered' | 'warning'

export interface OrderDetailItem {
  type: 'delivery_location' | 'shipment_status' | 'tracking_number' | 'pickup_location' | 'promo_code' | 'contact' | 'card' | 'cart_item'
  title?: string | LocalizationInfo | null
  id: string
  subtitle?: string | LocalizationInfo | null
  icon?: OrderDetailIcon | null
  value?: string | CustomerModel | LocationModel | SalesOrderFulfillmentModel | PickupLocationModel | PromoCodeModel | CardModel | SalesOrderLineItemModel
}

const createShippingDetailsSection = (order: SalesOrderModel): OrderDetailSection => {
  const { customer, deliveryLocation, pickupLocation, distributionMethod } = order

  let locationItem: OrderDetailItem;
  if (distributionMethod === 'pickup') {
    locationItem = {
      type: 'pickup_location',
      id: 'pickup_location',
      title: pickupLocation?.name ?? pickupLocation?.location?.address?.getFormattedAddress(),
      subtitle: pickupLocation?.location?.address?.getFormattedAddress(),
      value: pickupLocation,
    }
  } else {
    locationItem = {
      type: 'delivery_location',
      id: 'delivery_location',
      title: deliveryLocation?.address?.getFullStreetAddress(),
      subtitle: deliveryLocation?.address?.getCityStateZip(),
      value: deliveryLocation,
    }
  }

  const countryCode = deliveryLocation?.address?.countryCode ?? pickupLocation?.location?.address?.countryCode ?? 'US'
  const phoneNumber = customer?.phoneNumber && (parseNumber(customer.phoneNumber, countryCode as CountryCode) as ParsedNumber)?.phone
  const formattedNumber = phoneNumber && formatNumber({ phone: phoneNumber, country: countryCode as CountryCode}, 'National')
  const firstName = customer?.firstName
  const lastName = customer?.lastName

  const contactItem: OrderDetailItem = {
    title: (firstName && lastName) ? `${firstName} ${lastName}` : firstName,
    subtitle: formattedNumber,
    type: 'contact',
    id: 'contact',
    value: customer
  }

  return {
    title: { key: 'order:order_section_header_title_shipping_details' },
    items: [locationItem, contactItem],
    id: 'shipping_details'
  }
}

const createOrderStatusItem = (order: SalesOrderModel, fulfillment?: SalesOrderFulfillmentModel): OrderDetailItem => {
  let title: string | LocalizationInfo;
  let subtitle: string | LocalizationInfo | undefined;
  let icon: OrderDetailIcon | null | undefined;
  let estimatedDeliverySubtitle: string | LocalizationInfo | undefined;

  if (fulfillment?.estimatedDeliveryAt) {
    const date = moment(fulfillment.estimatedDeliveryAt).format('MMM D')
    const time = moment(fulfillment.estimatedDeliveryAt).format('h:mm A')

    estimatedDeliverySubtitle = { key: order.distributionMethod === 'pickup' ? 'order:order_subtitle_estimated_pickup_date' : 'order:order_subtitle_estimated_delivery_date_time', options: { date, time } }
  } else if (order.paymentSummary?.shipDates[0]) {
    const date = moment(order.paymentSummary?.shipDates[0]).format('MMM D')
    estimatedDeliverySubtitle = { key: order.distributionMethod === 'pickup' ? 'order:order_subtitle_estimated_pickup_date' : 'order:order_subtitle_estimated_delivery_date', options: { date } }
  }

  if (order.status === 'refunded') {
    title = { key: 'order:order_title_refunded' }
    icon = 'refunded'
  } else {
    switch (fulfillment?.shipmentStatus) {
      case 'attempted_delivery':
        icon = 'warning'
        title = { key: 'order:order_title_delivery_unsuccessful' }
        subtitle = { key: 'order:order_subtitle_contact_delivery_service', options: { delivery_service: fulfillment.trackingCompany ?? order.vendor?.name } }
        break;
      case 'ready_for_pickup':
        icon = 'box'
        title = { key: 'order:order_title_ready_for_pickup' }
        break;
      case 'delivered':
        icon = 'delivered'
        title = { key: 'order:order_title_delivered' }
        if (fulfillment.shipmentStatusUpdatedAt) {
          const date = moment(fulfillment.shipmentStatusUpdatedAt).format('MMM D')
          const time = moment(fulfillment.shipmentStatusUpdatedAt).format('h:mm A')

          subtitle = { key: 'order:order_subtitle_delivered_on', options: { date, time } }
        }
        break;
      case 'failure':
        icon = 'warning'
        title = { key: 'order:order_title_order_status_unknown' }
        subtitle = { key: 'order:order_subtitle_contact_delivery_service', options: { delivery_service: fulfillment.trackingCompany ?? order.vendor?.name } }
        break;
      case 'in_transit':
        icon = 'in_transit'
        title = { key: 'order:order_title_in_transit' }
        subtitle = estimatedDeliverySubtitle
        break;
      case 'out_for_delivery':
        icon = 'in_transit'
        title = { key: 'order:order_title_out_for_delivery' }
        subtitle = estimatedDeliverySubtitle
        break;
      default:
        switch (order.status) {
          case 'canceled':
            icon = 'warning'
            title = { key: 'order:order_title_order_canceled' }
            break
          case 'created':
            icon = 'frying_pan'
            title = { key: 'order:order_title_preparing_your_order' }
            subtitle = estimatedDeliverySubtitle
            break
          case 'fulfilled':
            icon = 'box'
            title = { key: 'order:order_title_prepared_for_delivery' }
            subtitle = estimatedDeliverySubtitle
            break
          case 'paid':
            icon = 'frying_pan'
            title = { key: 'order:order_title_preparing_your_order' }
            subtitle = estimatedDeliverySubtitle
            break
          default:
            icon = 'warning'
            title = { key: 'order:order_title_order_status_unknown' }
            subtitle = { key: 'order:order_subtitle_contact_delivery_service', options: { delivery_service: fulfillment?.trackingCompany ?? order.vendor?.name } }
            break
        }
        break;
    }
  }

  return {
    title: title,
    subtitle: subtitle,
    type: 'shipment_status',
    id: `${fulfillment?.id ?? ''}_order_status`,
    value: fulfillment,
    icon
  }
}

const createOrderStatusSections = (order: SalesOrderModel): OrderDetailSection[] => {
  if (order.fulfillments.length) {
    return order.fulfillments.map((fulfillment, index) => {
      const statusItem = createOrderStatusItem(order, fulfillment)

      const trackingItems: OrderDetailItem[] = fulfillment.trackingNumbers.map((trackingNumber, index) => {
        const url = fulfillment.trackingUrls[index]

        return {
          title: { key: 'order:order_title_tracking_number', options: { delivery_service: fulfillment.trackingCompany ?? '' } },
          subtitle: trackingNumber,
          type: 'tracking_number',
          id: url ?? `tracking_number_${index}`,
          value: url
        }
      })

      const items = [statusItem, ...trackingItems]
      const title: LocalizationInfo = order.fulfillments.length > 1 ? { key: 'order:order_section_header_title_order_status_for_package', options: { number: index + 1 } } : { key: 'order:order_section_header_title_order_status' }

      return { title, items, id: `${fulfillment.id}_order_status_section` }
    })
  } else {
    const statusItem = createOrderStatusItem(order)
    const items = [statusItem]
    const title: LocalizationInfo = { key: 'order:order_section_header_title_order_status' }
    return [{ title, items, id: `order_status_section` }]
  }
}

const createPaymentSection = (order: SalesOrderModel): OrderDetailSection => {
  const { card, promoCode } = order

  const last4 = card?.lastFourCardNumDigits
  const cardItem: OrderDetailItem | undefined = card && {
    type: 'card',
    id: 'card',
    title: { key: 'order:order_title_last4', options: { last4 } },
    value: card,
  }

  const promoCodeItem: OrderDetailItem | undefined = promoCode && {
    type: 'promo_code',
    id: 'promo_code',
    title: promoCode?.code,
    subtitle: promoCode?.title,
    value: promoCode,
  }

  return {
    title: { key: 'checkout:checkout_section_header_title_payment' },
    items: [cardItem, promoCodeItem].flatMap(i => i ? [i] : []),
    id: 'payment'
  }
}

const createCartItemsSection = (order: SalesOrderModel): OrderDetailSection => {
  const items: OrderDetailItem[] = order.lineItems.map(item => {
    const unitPrice = (item.price ?? 0) * (item.quantity ?? 0)
    const totalPrice = CurrencyUtils.stringFromPrice(unitPrice, item.currencyCode)

    return {
      type: 'cart_item',
      title: item.name,
      subtitle: totalPrice,
      id: item.id ?? '',
      value: item
    }
  })

  return {
    title: { key: 'order:order_section_header_title_order_summary' },
    items,
    id: 'order_summary'
  }
}

const createSections = (order: SalesOrderModel): OrderDetailSection[] => {
  const shippingDetailsSection = createShippingDetailsSection(order)
  const orderStatusSections = createOrderStatusSections(order)
  const paymentSection = createPaymentSection(order)
  const orderSummarySection = createCartItemsSection(order)

  return [shippingDetailsSection, ...orderStatusSections, paymentSection, orderSummarySection]
    .filter(s => s.items.length)
}

export const orderDetailSlice = createSlice({
  name: 'order_detail',
  initialState: { sections: [], isLoading: false } as OrderDetailState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.sections = []
      state.order = undefined
      state.error = undefined
    },
    fetchOrder(state, action: PayloadAction<string>) {
      state.isLoading = true
    },
    fetchOrderSucceeded(state, action: PayloadAction<SalesOrderModel>) {
      const sections = createSections(action.payload)

      return {
        ...state,
        sections,
        order: action.payload,
        orderId: action.payload.id,
        isLoading: false,
      }
    },
    fetchOrderFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('STORE_ERROR', action.payload)
        error.localizedMessage = { key: 'store:store_error_message_error_getting_order' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    }
  }
})

export const orderDetailReducer = orderDetailSlice.reducer

export const {
  fetchOrder,
  fetchOrderFailed,
  fetchOrderSucceeded,
  reset
} = orderDetailSlice.actions

export const selectError = (state: RootState) => state.orderDetail.error
export const selectOrder = (state: RootState) => state.orderDetail.order
export const selectSections = (state: RootState) => state.orderDetail.sections
export const selectIsLoading = (state: RootState) => state.orderDetail.isLoading