export interface OnboardingLinkResponseJson {
  url?: string
}

export class OnboardingLinkResponseModel {
  url?: string

  constructor(model?: OnboardingLinkResponseModel | null) {
    if (!model) return

    this.url = model.url

    return this
  }

  static fromJson(json?: OnboardingLinkResponseJson): OnboardingLinkResponseModel | undefined {
    if (!json) return

    const model = new OnboardingLinkResponseModel()
    model.url = json.url

    return model
  }
}