import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled} from "rxjs";
import {
  createEmail, createEmailFailed,
  createEmailSucceeded, deleteEmail, deleteEmailFailed, deleteEmailSucceeded,
  fetchEmail,
  fetchEmailFailed,
  fetchEmailSucceeded, reset, updateEmail, updateEmailFailed, updateEmailSucceeded
} from "./emailDetailSlice";
import {
  fetchEmails,
  reset as resetEmailList,
  selectCurrentPage,
  setCurrentPage
} from "../../redux/email/emailListSlice";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";

export const fetchEmailEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchEmail.match),
    mergeMap(action => {
      return MessageService.fetchEmail(action.payload)
        .pipe(
          map(fetchEmailSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchEmailFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const createEmailEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(createEmail.match),
    mergeMap(action => {
      return MessageService.createEmail(action.payload.email)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.emailList.currentPage

            return scheduled([
              createEmailSucceeded(),
              reset(),
              resetEmailList(),
              pageNumber === 1 ? fetchEmails(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: createEmailFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const updateEmailEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(updateEmail.match),
    mergeMap(action => {
      return MessageService.updateEmail(action.payload.email, action.payload.id)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.emailList.currentPage

            return scheduled([
              updateEmailSucceeded(),
              reset(),
              resetEmailList(),
              pageNumber === 1 ? fetchEmails(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: updateEmailFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deleteEmailEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(deleteEmail.match),
    mergeMap(action => {
      return MessageService.deleteEmail(action.payload)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.emailList.currentPage

            return scheduled([
              deleteEmailSucceeded(),
              reset(),
              resetEmailList(),
              pageNumber === 1 ? fetchEmails(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: deleteEmailFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}