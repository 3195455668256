import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BaseError} from "../../errors/baseError";
import {RootState} from "../../app/store";
import {OnboardingLinkResponseJson, OnboardingLinkResponseModel} from "../../models/onboardingLinkResponse";

interface OnboardingState {
  onboardingData?: OnboardingLinkResponseModel
  isLoading: boolean
  error?: BaseError<any>
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    isLoading: false
  } as OnboardingState,
  reducers: {
    reset(state) {
      state.error = undefined
      state.onboardingData = undefined
      state.isLoading = false
    },
    fetchOnboardingData(state) {
      state.isLoading = true
    },
    fetchOnboardingDataSucceeded(state, action: PayloadAction<OnboardingLinkResponseModel>) {
      state.isLoading = false
      state.onboardingData = action.payload
    },
    fetchOnboardingDataFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false
      state.onboardingData = new OnboardingLinkResponseModel({ url: process.env.NEXT_PUBLIC_APP_DOWNLOAD_URL })
    }
  }
})

export const onboardingReducer = onboardingSlice.reducer

export const {
  fetchOnboardingData,
  fetchOnboardingDataFailed,
  fetchOnboardingDataSucceeded,
  reset
} = onboardingSlice.actions

export const selectError = (state: RootState) => state.onboarding.error
export const selectIsLoading = (state: RootState) => state.onboarding.isLoading
export const selectOnboardingData = (state: RootState) => state.onboarding.onboardingData
