import {MessageCampaignFilterType, MessageCampaignNodeModel} from "../../models/messageCampaignNode";
import React, {useState} from "react";
import {Body, Caption, Heading1, Heading2, Heading4, HeroHeading2, Paragraph, TextStyles} from "../text";
import {BodyInput} from "../inputs";
import {CampaignDetailMessageSelectorModal} from "./CampaignDetailMessageSelectorModal";
import {EmailModel} from "../../models/email";
import {PromptModel} from "../../models/prompt";
import Link from "next/link";
import {Button} from "../buttons/Button";
import {CloseIcon} from "../icons";
import {PushNotificationModel} from "../../models/pushNotification";

interface CampaignDetailMessageNodeViewProps {
  node: MessageCampaignNodeModel,
  index: number,
  type: 'email' | 'prompt' | 'push',
  onChange: (node: MessageCampaignNodeModel) => void
  onDelete: (node: MessageCampaignNodeModel) => void
}

export const CampaignDetailMessageNodeView: React.FC<CampaignDetailMessageNodeViewProps> = ({ node, type, index, onChange, onDelete }) => {
  const [selectorModalOpen, setSelectorModalOpen] = useState(false)

  return <div className="flex flex-col space-y-5">
    <div className={`flex space-x-4`}>
      <Heading2 className={`flex-grow`}>{`Node #${index + 1}`}</Heading2>

      <div className={`flex-shrink-0 ${index === 0 ? 'hidden' : ''}`}>
        <button
          type="button"
          className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
          onClick={() => {
            onDelete(node)
          }}
        >
          <CloseIcon className="h-4 h-4" />
        </button>
      </div>
    </div>

    <div>
      <Heading4 className="mb-2">{`Name`}</Heading4>
      <BodyInput
        type="text"
        placeholder="Node Name"
        defaultValue={node.name}
        onChange={e => {
          node.name = e.target.value
          onChange(new MessageCampaignNodeModel(node))
        }}
      />
    </div>

    <div>
      <Heading4 className="mb-2 capitalize">{type}</Heading4>
      <div
        onClick={e => {
          setSelectorModalOpen(true)
        }}
        className={`py-2.5 lg:py-3 px-3 lg:px-4 bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
      >
        <div>
          <Body>
            {
              node.type === 'email' ? node.email?.name ?? 'Select an email'
                : node.type === 'prompt' ? node.prompt?.name ?? 'Select a prompt'
                  : node.push?.name ?? 'Select a notification'
            }
          </Body>
          <Paragraph className="text-fg-gray-dark">
            {
              node.type === 'email' ? `Subject: ${node.email?.formattedSubject}`
                : node.type === 'prompt' ? `Title: ${node.prompt?.title}`
                  : `Title: ${node.push?.title}`
            }
          </Paragraph>
        </div>
      </div>
    </div>

    <div>
      <Heading4 className="mb-2">{`Execute After (Hours)`}</Heading4>
      <BodyInput
        type="number"
        placeholder="Execute After (Hours)"
        defaultValue={node.executeAfter ? node.executeAfter / 3600 : node.executeAfter}
        onChange={e => {
          node.executeAfter = parseFloat(e.target.value) * 3600
          onChange(new MessageCampaignNodeModel(node))
        }}
      />
    </div>

    <CampaignDetailMessageSelectorModal
      isOpen={selectorModalOpen}
      type={type}
      onClose={(message) => {
        if (message instanceof EmailModel) {
          node.email = message
          node.type = 'email'
        } else if (message instanceof PromptModel) {
          node.prompt = message
          node.type = 'prompt'
        } else if (message instanceof PushNotificationModel) {
          node.push = message
          node.type = 'push'
        }
        onChange(new MessageCampaignNodeModel(node))

        setSelectorModalOpen(false)
      }}
    />
  </div>
}