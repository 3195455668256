import {from, Observable} from "rxjs";
import {api, handleApiError} from "./api";
import {SalesOrderModel} from "../models/salesOrder";
import {AddPaymentSourcePayload} from "../models/addPaymentSourcePayload";
import {PaymentSourcePayload} from "../models/paymentSourcePayload";
import {CardJson, CardModel} from "../models/card";
import {CreatePurchaseOrderPayload} from "../models/createPurchaseOrderPayload";
import {PurchaseOrderModel} from "../models/purchaseOrder";
import {CreateSalesOrderPayload} from "../models/createSalesOrderPayload";
import {VerifyPromoCodePayload} from "../models/verifyPromoCodePayload";
import {PromoCodeJson, PromoCodeModel} from "../models/promoCode";

export const PaymentApi = {
  deletePaymentSource(payload: PaymentSourcePayload): Observable<void> {
    const request: Promise<void> = api.delete(`/payment/delete-payment-source`, {
      headers: { is_authenticated: true },
      data: payload
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  setDefaultPaymentSource(payload: PaymentSourcePayload): Observable<void> {
    const request: Promise<void> = api.post(`/payment/set-default-payment-source`, payload, {
      headers: { is_authenticated: true }
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  addPaymentSource(payload: AddPaymentSourcePayload): Observable<CardModel> {
    const request: Promise<CardModel> = api.post(`/payment/add-payment-source`, payload, {
      headers: { is_authenticated: true }
    })
      .then(response => CardModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getPaymentSources(): Observable<CardModel[]> {
    const request: Promise<CardModel[]> = api.get(`/payment/get-payment-sources`, {
      headers: { is_authenticated: true }
    })
      .then(response => {
        const jsons: CardModel[] = response.data.map((p: CardJson) => CardModel.fromJson(p)!)
        return jsons
      })
      .catch(handleApiError)

    return from(request)
  },
  createPurchaseOrder(payload: CreatePurchaseOrderPayload): Observable<PurchaseOrderModel> {
    const request: Promise<PurchaseOrderModel> = api.post(`/v2/payment/create-purchase-order`, payload, {
      headers: { is_authenticated: true }
    })
      .then(response => PurchaseOrderModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  createSalesOrder(payload: CreateSalesOrderPayload): Observable<SalesOrderModel> {
    const request: Promise<SalesOrderModel> = api.post(`/payment/create-sales-order`, payload, {
      headers: { is_authenticated: true }
    })
      .then(response => SalesOrderModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getSalesOrder(id: string): Observable<SalesOrderModel> {
    const request: Promise<SalesOrderModel> = api.get(`/payment/get-sales-order/${id}`, {
      headers: { is_authenticated: true }
    })
      .then(response => SalesOrderModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getActivePromoCodes(storeId: string): Observable<PromoCodeModel[]> {
    const request: Promise<PromoCodeModel[]> = api.get(`/discount/get-active-codes-for-store`, {
      params: { store_id: storeId },
      headers: { is_authenticated: true }
    })
      .then(response => {
        const jsons: PromoCodeModel[] = response.data.map((p: PromoCodeJson) => PromoCodeModel.fromJson(p)!)
        return jsons
      })
      .catch(handleApiError)

    return from(request)
  },
  verifyPromoCode(payload: VerifyPromoCodePayload): Observable<PromoCodeModel> {
    const request: Promise<PromoCodeModel> = api.post(`/payment/verify-promo-code`, payload, {
      headers: { is_authenticated: true }
    })
      .then(response => PromoCodeModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  }
}