import {CurrencyCode} from "./currencyCode";
import {StoreHeaderJson, StoreHeaderModel} from "./storeHeader";
import {StoreSectionJson, StoreSectionModel} from "./storeSection";
import {StoreViewState} from "./storeViewState";
import {ProductCategoryModel} from "./productCategory";
import {DistributionMethod} from "./distributionMethod";

export interface StoreJson {
  currency_code?: CurrencyCode
  _id?: string
  distribution_methods?: DistributionMethod[]
  header?: StoreHeaderJson
  min_order_item_count?: number
  min_order_total?: number
  required_order_item_multiple?: number
  sections?: StoreSectionJson[]
  state?: StoreViewState
  vendor_id?: string
  stripe_id?: string
}

export class StoreModel {
  currencyCode?: CurrencyCode
  header?: StoreHeaderModel
  id?: string
  minOrderItemCount?: number
  minOrderTotal?: number
  requiredOrderItemMultiple?: number
  sections: StoreSectionModel[] = []
  state?: StoreViewState
  stripeId?: string
  vendorId?: string

  static fromJson(json?: StoreJson): StoreModel | undefined {
    if (!json) return

    const model = new StoreModel()

    model.currencyCode = json.currency_code
    model.header = StoreHeaderModel.fromJson(json.header)
    model.id = json._id
    model.minOrderItemCount = json.min_order_item_count
    model.minOrderTotal = json.min_order_total
    model.requiredOrderItemMultiple = json.required_order_item_multiple
    model.sections = json.sections
      ?.map((i) => StoreSectionModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.state = json.state
    model.stripeId = json.stripe_id
    model.vendorId = json.vendor_id

    return model
  }
}