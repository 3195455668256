import React, {useEffect, useState} from "react";
import {CartItemView} from "./CartItemView";
import {CloseIcon, GroceryColorIcon, WarningColorIcon} from "../icons";
import {Body, Heading1, Heading3, HeroHeading2, Paragraph} from "../text";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {CurrencyUtils} from "../../utils/currencyUtils";
import {selectCart as selectStoreCart, selectStore} from "../../redux/store/storeSlice";
import {useTranslation} from "next-i18next";
import {CartButton} from "../buttons/CartButton";
import {Button} from "../buttons/Button";
import {
  fetchAndUpdateCartIfNeeded,
  resetCartUpdateStatus, selectCart,
  selectDidUpdate,
  selectError, selectIsLoading,
  selectIsUpdating
} from "../../redux/cart/cartSlice";
import {useRouter} from "next/router";
import {ShoppingCartItemModel} from "../../models/shoppingCartItem";
import Skeleton from "react-loading-skeleton";
import {LocalizationInfo} from "react-i18next";
import {Double} from "bson";
import {AlertDialog} from "../dialogs/AlertDialog";
import Head from "next/head";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export interface CartViewProps {
  onClose: () => void
  storeId?: string
  isMenu: boolean
}

export const CartView: React.FC<CartViewProps> = ({ onClose, storeId, isMenu }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const cart = useAppSelector(state => {
    if (router.route === '/store/[id]') {
      return selectStoreCart(state)
    } else if (router.route === '/store-section/[id]') {
      return selectStoreCart(state)
    } else if (router.route === '/store-category/[id]') {
      return selectStoreCart(state)
    }  else if (router.route === '/cart/[id]') {
      return selectStoreCart(state)
    } else {
      return selectCart(state, storeId)
    }
  })

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Cart' }))
  }, [])

  const isLoading = useAppSelector(state => selectIsLoading(state, storeId))
  let items = cart?.items ?? []
  const cartIsEmpty = !cart || cart.items.length <= 0
  const cartTotal = CurrencyUtils.stringFromPrice(cart?.totalPrice, cart?.currencyCode)
  const cartTitle = t('store:store_button_go_to_checkout')
  const cartQuantity = items.reduce((sum, item) => {
    return sum + (item.quantity ?? 0)
  }, 0).toString() ?? "0"

  const [loading, setLoading] = useState(router.isFallback)
  const [alert, setAlert] = useState<{ title: string | LocalizationInfo, message: string | LocalizationInfo } | undefined>()

  if (loading && items.length <= 0) {
    items = [1, 2, 3].map(i => {
      const item = new ShoppingCartItemModel()
      item.skuId = i.toString()

      return item
    })
  }

  useEffect(() => {
    if (cart?.storeId) {
      dispatch(fetchAndUpdateCartIfNeeded(cart?.storeId))
    }

  }, [cart?.storeId, dispatch])

  useEffect(() => {
    setLoading(router.isFallback || isLoading)
  }, [router.isFallback, isLoading])

  const store = useAppSelector(selectStore)
  const isUpdating = useAppSelector(state => selectIsUpdating(state, cart?.storeId))
  const error = useAppSelector(state => selectError(state, cart?.storeId))
  const didUpdate = useAppSelector(state => selectDidUpdate(state, cart?.storeId))

  const onButtonClick = () => {
    const minTotal = store?.minOrderTotal
    const minItemCount = store?.minOrderItemCount
    const orderItemMultiple = store?.requiredOrderItemMultiple
    const totalPrice = cart?.totalPrice

    const itemCount = items.reduce((sum, item) => {
      return sum + (item.quantity ?? 0)
    }, 0)

    if (totalPrice && minTotal && totalPrice < minTotal) {
      const minTotalString = CurrencyUtils.stringFromPrice(minTotal, store.currencyCode)

      setAlert({
        title: { key: 'store:store_alert_title_error_min_order_total', options: { total: minTotalString } },
        message: { key: 'store:store_alert_message_error_min_order_total', options: { total: minTotalString } }
      })
    } else if (minItemCount && itemCount < minItemCount) {
      setAlert({
        title: { key: 'store:store_alert_title_error_min_order_item_count', options: { count: minItemCount } },
        message: { key: 'store:store_alert_message_error_min_order_item_count', options: { count: minItemCount } }
      })
    } else if (orderItemMultiple && itemCount % orderItemMultiple != 0) {
      const k = 1 / orderItemMultiple
      const nearestMultiple = Math.trunc(Math.round(itemCount * k) / k)

      let message: string | LocalizationInfo
      let title: string | LocalizationInfo
      if (itemCount < nearestMultiple) {
        const diff = nearestMultiple - itemCount
        title = diff > 1
          ? { key: 'store:store_alert_title_error_required_order_item_multiple_other', options: { count: diff } }
          : { key: 'store:store_alert_title_error_required_order_item_multiple_one', options: { count: diff } }
        message = diff > 1
          ? { key: 'store:store_alert_message_error_required_order_item_multiple_other', options: { count: diff, multiple: orderItemMultiple } }
          : { key: 'store:store_alert_message_error_required_order_item_multiple_one', options: { count: diff, multiple: orderItemMultiple } }
      } else {
        const diff = (nearestMultiple + orderItemMultiple) - itemCount
        title = diff > 1
          ? { key: 'store:store_alert_title_error_required_order_item_multiple_other', options: { count: diff } }
          : { key: 'store:store_alert_title_error_required_order_item_multiple_one', options: { count: diff } }
        message = diff > 1
          ? { key: 'store:store_alert_message_error_required_order_item_multiple_other', options: { count: diff, multiple: orderItemMultiple } }
          : { key: 'store:store_alert_message_error_required_order_item_multiple_one', options: { count: diff, multiple: orderItemMultiple } }
      }

      setAlert({
        title,
        message
      })
    } else {
      router.push({ pathname: '/checkout', query: { store_id: cart?.storeId } })
      if (isMenu) onClose()
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={!!alert}
        title={alert?.title}
        message={alert?.message}
        buttons={[
          {
            title: { key: 'common:common_title_ok' },
            style: 'default',
            onClick: () => {
              setAlert(undefined)
            }
          }
        ]}
      />
      <div className={`transform w-full no-scrollbar lg:overflow-y-scroll lg:max-h-screen-3/4 lg:p-6`}>
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`lg:overflow-hidden no-scrollbar max-h-screen`}>
          <div className={`p-4 mx-1 mt-2 rounded-lg bg-fg-gray-surface-light ring-1 ring-fg-gray-surface-dark ${didUpdate ? '' : 'hidden'}`}>
            <div className="flex flex-row space-x-2 items-start">
              <Heading3 className="flex-grow">{t('store:store_title_removed_items')}</Heading3>
              <div className="flex items-center justify-center">
                <button
                  type="button"
                  className="text-fg-black px-0.5 py-0.5 rounded-full flex items-center focus:ring-0"
                  onClick={() => cart?.storeId && dispatch(resetCartUpdateStatus(cart.storeId))}
                >
                  <CloseIcon className="h-3 h-3" />
                </button>
              </div>
            </div>

            <Body className="text-fg-gray-dark mt-3">{t('store:store_message_removed_items')}</Body>
          </div>

          <div className={`flex flex-col space-y-6 mt-6 items-center ${cartIsEmpty && !error && !loading ? '' : 'hidden'}`}>
            <GroceryColorIcon className="h-20 w-20" />
            <Heading1 className="text-center">
              {t('store:store_empty_state_title_cart_is_empty')}
            </Heading1>
            <Paragraph className="text-fg-gray-dark text-center">
              {t('store:store_empty_state_message_add_items_to_cart')}
            </Paragraph>
            <Button size="lg" buttonStyle="secondary" onClick={() => onClose()}>
              {t('store:store_empty_state_button_shop_now')}
            </Button>
          </div>

          <div className={`flex flex-col space-y-6 mt-6 items-center ${error ? '' : 'hidden'}`}>
            <WarningColorIcon className="h-20 w-20" />
            <Heading1 className="text-center">
              {error?.localizedTitle}
            </Heading1>
            <Paragraph className="text-fg-gray-dark text-center">
              {error?.localizedMessage}
            </Paragraph>
            <Button isLoading={isUpdating || loading} size="lg" buttonStyle="secondary" onClick={() => {
              if (cart?.storeId) dispatch(fetchAndUpdateCartIfNeeded(cart.storeId))
            }}>
              {t('store:store_empty_state_button_try_again')}
            </Button>
          </div>

          <div className={`mt-6 mb-6 ${(cartIsEmpty && !loading) || error ? 'hidden' : ''}`}>
            <HeroHeading2>{loading ? <Skeleton className="!w-1/3" /> : store?.header?.title ?? t('store:store_title_your_items')}</HeroHeading2>
          </div>

          <div className={`${(cartIsEmpty && !loading) || error ? 'hidden' : ''}`}>
            {items.map((item, index) => {
              const isLastItem = index === items.length - 1
              return <CartItemView key={item.skuId} isLoading={loading} item={item} isLastItem={isLastItem} />
            })}
          </div>

          <div className={`${(cartIsEmpty && !loading) || error ? 'hidden' : 'pb-32'}`} />
        </div>
      </div>

      <div className={`${(cartIsEmpty && !loading) || error ? 'hidden' : 'fixed'} left-0 bottom-0 w-full rounded-full`}>
        <div className="mx-4 my-4 lg:mx-6 lg:mx-6 rounded-full shadow-lg lg:shadow-none">
          <CartButton
            isLoading={isUpdating || loading}
            total={cartTotal}
            buttonTitle={cartTitle}
            quantity={cartQuantity}
            onClick={onButtonClick}
          />
        </div>
      </div>
    </>
  )
}