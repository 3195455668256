import {Options, RRule, RRuleSet, rrulestr, Weekday} from "rrule";
import Moment from "moment";

export const RruleUtils = {
  createStringFromRrule(rrule: RRule | RRuleSet): string {
    const options: Partial<Options> = { ...rrule.origOptions }
    options.dtstart = null

    const builder = new RRule(options)
    const dtstart = Moment(rrule.options.dtstart).format('YYYYMMDDTHHmmss') + 'Z'

    const rruleString = builder.toString()

    return rruleString + ';DTSTART=' + dtstart
  },
  createRruleFromString(rruleString: string): RRule | RRuleSet {
    if (rruleString.includes('\n')) {
      return rrulestr(rruleString)
    }

    const token = rruleString.includes(';DTSTART:') ? ';DTSTART:' : ';DTSTART='
    const [rule, dtstartValue] = rruleString.split(token)

    if (dtstartValue) {
      return rrulestr('DTSTART:' + dtstartValue + ';\n' + rule)
    } else {
      return rrulestr(rruleString)
    }
  }
}