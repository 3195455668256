import {EntityModel} from "./entityModel";
import {Observable, of} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectSchema} from "mongodb-realm";

export interface OpeningTimePeriodJson {
  open_time?: string
  close_time?: string
  weekday?: number
}

export class OpeningTimePeriodModel implements EntityModel<OpeningTimePeriodModel> {
  public static schema: ObjectSchema = {
    name: 'OpeningTimePeriodEntity',
    embedded: true,
    properties: {
      closeTime: 'string?',
      openTime: 'string?',
      weekday: 'int?',
    },
  }

  schema = OpeningTimePeriodModel.schema

  openTime?: string
  closeTime?: string
  weekday?: number

  constructor(entity?: OpeningTimePeriodModel | null) {
    if (!entity) return

    this.openTime = entity.openTime
    this.closeTime = entity.closeTime
    this.weekday = entity.weekday

    return this
  }

  static fromJson(json?: OpeningTimePeriodJson): OpeningTimePeriodModel | undefined {
    if (!json) return

    const model = new OpeningTimePeriodModel()

    model.openTime = json.open_time
    model.closeTime = json.close_time
    model.weekday = json.weekday

    return model
  }

  toJson(): OpeningTimePeriodJson {
    return {
      open_time: this.openTime,
      close_time: this.closeTime,
      weekday: this.weekday
    }
  }

  toEntityObservable(realmService: BaseRealmService): Observable<OpeningTimePeriodModel> {
    return of(this)
  }
}