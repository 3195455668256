import {ObjectId} from "bson";
import {AddressJson, AddressModel} from "./address";
import {EntityModel} from "./entityModel";
import {map, mergeMap, Observable, of} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectSchema} from "mongodb-realm";
import moment from "moment";

export type LocationType = 'home' | 'work' | 'approximate' | 'current' | 'explore' | 'pickup' | string

export interface LocationJson {
  _id?: string;
  created_at?: Date;
  updated_at?: Date;
  address?: AddressJson;
  formatted_address?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  type?: string;
  place_id?: string;
  is_primary?: boolean;
}

export class LocationModel implements EntityModel<LocationModel> {
  public static schema: ObjectSchema = {
    name: 'LocationEntity',
    properties: {
      _id: 'objectId',
      _partition: 'string',
      address: 'AddressEntity',
      createdAt: 'date?',
      formattedAddress: 'string?',
      isPrimary: 'bool',
      latitude: 'double',
      locationId: 'string?',
      longitude: 'double',
      name: 'string?',
      type: 'string?',
      updatedAt: 'date?',
    },
    primaryKey: '_id',
  }

  schema = LocationModel.schema

  _id: ObjectId = new ObjectId()
  _partition: string = ''
  address?: AddressModel
  createdAt?: Date
  formattedAddress?: string
  isPrimary: boolean = false
  latitude: number = 0
  locationId?: string
  longitude: number = 0
  name?: string
  type?: LocationType
  updatedAt?: Date
  placeId?: string

  constructor(entity?: LocationModel | null) {
    if (!entity) return

    this._id = entity._id
    this._partition = entity._partition
    this.address = new AddressModel(entity.address)
    this.createdAt = entity.createdAt ? moment(entity.createdAt).toDate() : entity.createdAt
    this.formattedAddress = entity.formattedAddress
    this.isPrimary = entity.isPrimary
    this.latitude = entity.latitude
    this.locationId = entity.locationId
    this.longitude = entity.longitude
    this.name = entity.name
    this.type = entity.type
    this.updatedAt = entity.updatedAt ? moment(entity.updatedAt).toDate() : entity.updatedAt
    this.placeId = entity.placeId

    return this
  }

  static fromJson(json?: LocationJson): LocationModel | undefined {
    if (!json) return

    const model = new LocationModel()

    model.address = AddressModel.fromJson(json.address)
    model.createdAt = json.created_at ? moment(json.created_at).toDate() : json.created_at
    model.formattedAddress = json.formatted_address
    model.isPrimary = json.is_primary ?? model.isPrimary
    model.latitude = json.latitude ?? model.latitude
    model.locationId = json._id
    model.longitude = json.longitude ?? model.longitude
    model.name = json.name
    model.type = json.type
    model.updatedAt = json.updated_at ? moment(json.updated_at).toDate() : json.updated_at
    model.placeId = json.place_id

    return model
  }

  toJson(): LocationJson {
    return {
      _id: this.locationId,
      address: this.address?.toJson(),
      created_at: this.createdAt,
      formatted_address: this.formattedAddress,
      is_primary: this.isPrimary,
      latitude: this.latitude,
      longitude: this.longitude,
      name: this.name,
      type: this.type,
      updated_at: this.updatedAt,
      place_id: this.placeId
    }
  }

  getShippingDescription(): string | null {
    if (this.type) {
      switch (this.type) {
        case "approximate":
          return `${this.address?.locality}, ${this.address?.administrativeArea}`
        case "explore":
          return `${this.address?.locality}, ${this.address?.administrativeArea}`
        case "current":
          break
        case "pickup":
          break
        default:
          break
      }
    }

    if (this.name) return this.name
    const fullStreetAddress = this.address?.getFullStreetAddress()
    return fullStreetAddress ?? null
  }

  getDisplayDescription(): string | null {
    if (this.type) {
      switch (this.type) {
        case "approximate":
          return `${this.address?.locality}, ${this.address?.administrativeArea}`
        case "explore":
          return `${this.address?.locality}, ${this.address?.administrativeArea}`
        case "current":
          break
        case "pickup":
          break
        default:
          break
      }
    }

    const fullStreetAddress = this.address?.getFullStreetAddress()
    return fullStreetAddress ?? this.name ?? this.address?.locality ?? null
  }

  toEntityObservable(realmService: BaseRealmService): Observable<LocationModel> {
    const addressObservable: Observable<AddressModel | undefined> = this.address?.toEntityObservable(realmService)
      ?? of<AddressModel | undefined>(undefined)

    return addressObservable.pipe(
      mergeMap((address) => {
        this.address = address

        return realmService.query<LocationModel>(LocationModel.schema.name)
          .where({ locationId: this.locationId })
          .fetchOne()
          .pipe(
            map((existingEntity) => {
              if (existingEntity) {
                this._id = existingEntity._id
              }

              return this
            })
          )
      })
    )
  }
}
