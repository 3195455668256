import {Observable} from "rxjs";
import {PromptsResponseModel} from "../models/promtsResponse";
import {PromptModel} from "../models/prompt";
import {MessageApi} from "../apis/messageApi";
import {CohortModel} from "../models/cohort";
import {EmailsResponseModel} from "../models/emailsResponse";
import {EmailModel} from "../models/email";
import {MessageCampaignsResponseModel} from "../models/messageCampaignsResponse";
import {MessageCampaignModel} from "../models/messageCampaign";
import {PushNotificationModel} from "../models/pushNotification";
import {PushNotificationsResponseModel} from "../models/pushNotificationsResponse";

export const MessageService = {
  fetchCohorts(): Observable<CohortModel[]> {
    return MessageApi.getCohorts()
  },
  fetchPrompts(pageNumber: number): Observable<PromptsResponseModel> {
    return MessageApi.getPrompts(pageNumber)
  },
  fetchPrompt(id: string): Observable<PromptModel> {
    return MessageApi.getPrompt(id)
  },
  createPrompt(prompt: PromptModel, cohortId?: string): Observable<PromptModel> {
    return MessageApi.createPrompt(prompt.toJson(), cohortId)
  },
  updatePrompt(prompt: PromptModel, id: string, cohortId?: string): Observable<PromptModel> {
    return MessageApi.updatePrompt(prompt.toJson(), id, cohortId)
  },
  deletePrompt(id: string): Observable<void> {
    return MessageApi.deletePrompt(id)
  },
  fetchCampaigns(pageNumber: number): Observable<MessageCampaignsResponseModel> {
    return MessageApi.getCampaigns(pageNumber)
  },
  fetchCampaign(id: string): Observable<MessageCampaignModel> {
    return MessageApi.getCampaign(id)
  },
  createCampaign(campaign: MessageCampaignModel): Observable<MessageCampaignModel> {
    return MessageApi.createCampaign(campaign.toJson())
  },
  updateCampaign(campaign: MessageCampaignModel, id: string): Observable<MessageCampaignModel> {
    return MessageApi.updateCampaign(campaign.toJson(), id)
  },
  deleteCampaign(id: string): Observable<void> {
    return MessageApi.deleteCampaign(id)
  },
  activateCampaign(id: string): Observable<void> {
    return MessageApi.activateCampaign(id)
  },
  fetchEmails(pageNumber: number): Observable<EmailsResponseModel> {
    return MessageApi.getEmails(pageNumber)
  },
  fetchEmail(id: string): Observable<EmailModel> {
    return MessageApi.getEmail(id)
  },
  createEmail(email: EmailModel): Observable<EmailModel> {
    return MessageApi.createEmail(email.toJson())
  },
  updateEmail(email: EmailModel, id: string): Observable<EmailModel> {
    return MessageApi.updateEmail(email.toJson(), id)
  },
  deleteEmail(id: string): Observable<void> {
    return MessageApi.deleteEmail(id)
  },
  sendEmail(to: string[], testEmail?: EmailModel, emailId?: string, cohortId?: string): Observable<void> {
    return MessageApi.sendEmail(to, testEmail?.toJson(), emailId, cohortId);
  },
  fetchPushNotifications(pageNumber: number): Observable<PushNotificationsResponseModel> {
    return MessageApi.getPushNotifications(pageNumber)
  },
  fetchPushNotification(id: string): Observable<PushNotificationModel> {
    return MessageApi.getPushNotification(id)
  },
  createPushNotification(push: PushNotificationModel): Observable<PushNotificationModel> {
    return MessageApi.createPushNotification(push.toJson())
  },
  updatePushNotification(push: PushNotificationModel, id: string): Observable<PushNotificationModel> {
    return MessageApi.updatePushNotification(push.toJson(), id)
  },
  deletePushNotification(id: string): Observable<void> {
    return MessageApi.deletePushNotification(id)
  },
  sendPushNotification(to: string[], testPush?: PushNotificationModel, pushId?: string, cohortId?: string): Observable<void> {
    return MessageApi.sendPushNotification(to, testPush?.toJson(), pushId, cohortId);
  }
}