import {api, handleApiError} from "./api";
import {from, Observable} from "rxjs";
import {SubscriptionPriceJson, SubscriptionPriceModel} from "../models/subscriptionPrice";
import {CreateCheckoutResponseModel} from "../models/createCheckoutSessionResponse";
import {InAppSubscriptionModel} from "../models/inAppSubscription";

export const SubscriptionApi = {
  getSubscriptionPrices(code?: string): Observable<SubscriptionPriceModel[]> {
    const request: Promise<SubscriptionPriceModel[]> = api.post(`/subscription/get-prices`, { code },{
    })
      .then(response => {
        const jsons: SubscriptionPriceModel[] = response.data.map((p: SubscriptionPriceJson) => SubscriptionPriceModel.fromJson(p)!)
        return jsons
      })
      .catch(handleApiError)

    return from(request)
  },
  createCheckoutSession(priceId?: string, promoCode?: string): Observable<CreateCheckoutResponseModel> {
    const request: Promise<CreateCheckoutResponseModel> = api.post(`/subscription/create-checkout-session`, { promo_code: promoCode, price_id: priceId },{
    })
      .then(response => CreateCheckoutResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  cancelSubscription(): Observable<void> {
    const request: Promise<void> = api.post(`/subscription/cancel`, {  },{
      headers: { is_authenticated: true }
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  getCurrentSubscription(): Observable<InAppSubscriptionModel> {
    const request: Promise<InAppSubscriptionModel> = api.get(`/subscription/current`, {
      headers: { is_authenticated: true }
    })
      .then(response => InAppSubscriptionModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  setFreeTrialExpDate(date: Date): Observable<void> {
    const request: Promise<void> = api.post('/user/set-free-trial-exp-date', { free_trial_exp_date: date }, {
      headers: { is_authenticated: true }
    })
      .then(() => { })
      .catch(handleApiError)

    return from(request)
  }
}