import {from, Observable} from "rxjs";
import {UserModel} from "../models/user";
import {api, handleApiError} from "./api";
import {SetContactInfoPayload} from "../models/setContactInfoPayload";
import {PickupLocationJson, PickupLocationModel} from "../models/pickupLocation";

export const DistributionApi = {
  getPickupLocations: (storeId: string): Observable<PickupLocationModel[]> => {
    const request: Promise<PickupLocationModel[]> = api.get(`/distribution/get-pickup-locations/${storeId}`, { headers: { is_authenticated: true } })
      .then(response => {
        const jsons: PickupLocationModel[] = response.data.map((p: PickupLocationJson) => PickupLocationModel.fromJson(p)!)
        return jsons
      })
      .catch(handleApiError)

    return from(request)
  }
}