import {AccountConfigJson, AccountConfigModel} from "./accountConfig";
import {FitnessProfileJson, FitnessProfileModel} from "./fitnessProfile";
import {ObjectId} from "bson";
import {BaseRealmService} from "../realm/realmService";
import {map, mergeMap, Observable, of, zip} from "rxjs";
import {EntityModel} from "./entityModel";
import {ObjectSchema} from "mongodb-realm";
import {UserAffiliateProgramJson, UserAffiliateProgramModel} from "./userAffiliateProgram";

export type UserRole =
  'user' |
  'affiliate' |
  'coach' |
  'vendor' |
  'admin' |
  'anonymous'

export interface UserJson {
  account_config?: AccountConfigJson
  created_at?: Date
  did_migrate_to_mongo_db_realm?: boolean
  email?: string
  first_name?: string
  fitness_profile?: FitnessProfileJson
  _id?: string
  last_name?: string
  phone_number?: string
  roles?: UserRole[]
  stripe_id?: string
  updated_at?: Date
  affiliate_program?: UserAffiliateProgramJson
  onesignal_email_auth_hash?: string
  onesignal_external_id_auth_hash?: string
}

export class UserModel implements EntityModel<UserModel> {
  public static schema: ObjectSchema = {
    name: 'UserEntity',
    properties: {
      _id: 'objectId',
      _partition: 'string',
      accountConfig: 'AccountConfigEntity',
      createdAt: 'date?',
      email: 'string?',
      firstName: 'string?',
      fitnessProfile: 'FitnessProfileEntity',
      lastName: 'string?',
      phoneNumber: 'string?',
      roles: 'string[]',
      stripeId: 'string?',
      updatedAt: 'date?',
      userId: 'string?',
    },
    primaryKey: '_id',
  }

  schema = UserModel.schema

  _id: ObjectId = new ObjectId()
  _partition: string = ''
  accountConfig?: AccountConfigModel
  createdAt?: Date
  email?: string
  firstName?: string
  fitnessProfile?: FitnessProfileModel
  lastName?: string
  phoneNumber?: string
  roles: UserRole[] = []
  stripeId?: string
  updatedAt?: Date
  userId?: string
  affiliateProgram?: UserAffiliateProgramModel
  onesignalEmailAuthHash?: string
  onesignalExternalIdAuthHash?: string

  constructor(entity?: UserModel | null) {
    if (!entity) return

    this._id = entity._id
    this._partition = entity._partition
    this.accountConfig = new AccountConfigModel(entity.accountConfig)
    this.createdAt = entity.createdAt
    this.email = entity.email
    this.firstName = entity.firstName
    this.fitnessProfile = new FitnessProfileModel(entity.fitnessProfile)
    this.lastName = entity.lastName
    this.phoneNumber = entity.phoneNumber
    this.roles = entity.roles
    this.stripeId = entity.stripeId
    this.updatedAt = entity.updatedAt
    this.userId = entity.userId
    this.affiliateProgram = new UserAffiliateProgramModel(entity.affiliateProgram)
    this.onesignalEmailAuthHash = entity.onesignalEmailAuthHash
    this.onesignalExternalIdAuthHash = entity.onesignalExternalIdAuthHash

    return this
  }

  static fromJson(json?: UserJson): UserModel | undefined {
    if (!json) return

    const model = new UserModel()

    model.accountConfig = AccountConfigModel.fromJson(json.account_config)
    model.createdAt = json.created_at
    model.email = json.email
    model.firstName = json.first_name
    model.fitnessProfile = FitnessProfileModel.fromJson(json.fitness_profile)
    model.lastName = json.last_name
    model.phoneNumber = json.phone_number
    model.roles = json.roles ?? model.roles
    model.stripeId = json.stripe_id
    model.updatedAt = json.updated_at
    model.userId = json._id
    model.affiliateProgram = UserAffiliateProgramModel.fromJson(json.affiliate_program)
    model.onesignalEmailAuthHash = json.onesignal_email_auth_hash
    model.onesignalExternalIdAuthHash = json.onesignal_external_id_auth_hash

    return model
  }

  toEntityObservable(realmService: BaseRealmService): Observable<UserModel> {
    const accountConfigObservable: Observable<AccountConfigModel | undefined> = this.accountConfig?.toEntityObservable(realmService)
      ?? of<AccountConfigModel | undefined>(undefined)
    const fitnessProfileObservable: Observable<FitnessProfileModel | undefined> = this.fitnessProfile?.toEntityObservable(realmService)
      ?? of<FitnessProfileModel | undefined>(undefined)

    return zip(accountConfigObservable, fitnessProfileObservable)
      .pipe(
        mergeMap(([accountConfig, fitnessProfile]) => {
          this.fitnessProfile = fitnessProfile
          this.accountConfig = accountConfig

          return realmService.query<UserModel>(UserModel.schema.name)
            .where({ userId: this.userId })
            .fetchOne()
            .pipe(
              map((existingEntity) => {
                if (existingEntity) {
                  this._id = existingEntity._id
                }

                return this
              })
            )
        })
      )
  }
}