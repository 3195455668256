import {StoreModel} from "../../models/store";
import {BaseError} from "../../errors/baseError";
import {ShoppingCartModel} from "../../models/shoppingCart";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {fetchAndUpdateCartIfNeededSucceeded, fetchCartSucceeded} from "../cart/cartSlice";
import {StoreSectionModel} from "../../models/storeSection";
import {ProductCategoryModel} from "../../models/productCategory";
import {WritableDraft} from "immer/dist/types/types-external";
import {GenericError} from "../../errors/genericError";
import {ProductSkuModel} from "../../models/productSku";

interface StoreState {
  readonly isLoading: boolean
  readonly storeId?: string
  readonly store?: StoreModel
  readonly productSections: StoreSectionModel[]
  readonly storeCategories: ProductCategoryModel[][]
  readonly cart?: ShoppingCartModel | null
  readonly error?: BaseError<any>
}

const updateStoreWithCart = (store: StoreModel | WritableDraft<StoreModel>, cart?: ShoppingCartModel | null) => {
  const storeCategories = store.sections.map(section => {
    if (section.type === 'category') {
      return section.categories
    } else {
      return undefined
    }
  }).flatMap(i => i ? [i] : [])

  const productSections = store.sections.map(section => {
    if (section.type === 'menu_item' && section.products.length > 0) {
      return createProductSection(section, cart)
    } else {
      return undefined
    }
  }).flatMap(i => i ? [i] : [])

  return { productSections, storeCategories }
}

const createProductSection = (section: StoreSectionModel | WritableDraft<StoreSectionModel>, cart?: ShoppingCartModel | null): (StoreSectionModel | WritableDraft<StoreSectionModel>) => {
  section.products = section.products.map(p => {
    const items = cart?.items
      .filter(i => i.productId === p?.id)
      .sort((i1, i2) => (i2.updatedAt?.getTime() ?? 0) - (i1.updatedAt?.getTime() ?? 0)) ?? []

    const cartItem = items[0]
    const cartSku = p?.skus.filter(s => s.id === cartItem?.skuId)[0]

    if (cartSku && cartItem) {
      cartSku.quantity = cartItem.quantity
      p.setSelectedSku(cartSku as ProductSkuModel)
    } else {
      const lineItem = cart?.items.filter(i => i.skuId === p.getSelectedSku()?.id)[0]
      const quantity = lineItem?.quantity

      p.updateWithQuantity(quantity)
    }

    return p
  })
  return section
}

export const storeSlice = createSlice({
  name: "store",
  initialState: {
    isLoading: true,
    productSections: [],
    storeCategories: [],
  } as StoreState,
  reducers: {
    reset(state) {
      state.isLoading = true
      state.storeId = undefined
      state.store = undefined
      state.productSections = []
      state.storeCategories = []
      state.error = undefined
    },
    fetchStore(state, action: PayloadAction<string>) {
      state.isLoading = state.store?.id !== action.payload
      state.storeId = action.payload
    },
    fetchStoreSucceeded(state, action: PayloadAction<StoreModel>) {
      state.isLoading = false
      state.store = action.payload

      const cart = state.cart
      const { productSections, storeCategories } = updateStoreWithCart(action.payload, cart)
      state.productSections = productSections
      state.storeCategories = storeCategories
    },
    fetchStoreFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('STORE_ERROR', action.payload)
        error.localizedMessage = { key: 'store:store_error_message_error_getting_store' }
        error.localizedTitle = { key: 'store:store_error_title_error_getting_store' }
        error.error = action.payload
        state.error = error as any
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchCartSucceeded,(state, action) => {
      state.cart = new ShoppingCartModel(action.payload)

      const store = state.store
      if (store) {
        const { productSections, storeCategories } = updateStoreWithCart(store, state.cart)
        state.productSections = productSections
        state.storeCategories = storeCategories
      }
    }).addCase(fetchAndUpdateCartIfNeededSucceeded,(state, action) => {
      state.cart = new ShoppingCartModel(action.payload.cart)

      const store = state.store
      if (store) {
        const { productSections, storeCategories } = updateStoreWithCart(store, state.cart)
        state.productSections = productSections
        state.storeCategories = storeCategories
      }
    })
  },
})

export const storeReducer = storeSlice.reducer

export const {
  fetchStore,
  fetchStoreSucceeded,
  fetchStoreFailed,
  reset
} = storeSlice.actions

export const selectStore = (state: RootState) => state.store.store
export const selectIsLoading = (state: RootState) => state.store.isLoading
export const selectStoreCategories = (state: RootState) => state.store.storeCategories
export const selectProductSections = (state: RootState) => state.store.productSections
export const selectCart = (state: RootState) => state.store.cart
export const selectError = (state: RootState) => state.store.error