export interface PurchaseOrderLineItemJson {
  _id?: string
  quantity?: number
}

export class PurchaseOrderItemModel {
  id?: string
  quantity?: number

  constructor(model?: PurchaseOrderItemModel) {
    if (!model) return

    this.id = model.id
    this.quantity = model.quantity
  }

  static fromJson(json?: PurchaseOrderLineItemJson): PurchaseOrderItemModel | undefined {
    if (!json) return

    const model = new PurchaseOrderItemModel()

    model.id = json._id
    model.quantity = json.quantity

    return model
  }
}