import {EntityModel} from "./entityModel";
import {Observable, of} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ImageModel} from "./image";
import {ProductSkuOptionValueModel} from "./productSkuOptionValue";
import {CurrencyCode} from "./currencyCode";
import {MenuItemSizeType} from "./menuItemSizeType";
import {ObjectSchema} from "mongodb-realm";
import moment from "moment";

export class ShoppingCartItemModel implements EntityModel<ShoppingCartItemModel> {
  public static schema: ObjectSchema = {
    name: 'ShoppingCartItemEntity',
    embedded: true,
    properties: {
      availabilityEndDate: 'date?',
      availabilityStartDate: 'date?',
      createdAt: 'date?',
      currencyCode: 'string?',
      estimatedDistributionDate: 'date?',
      image: 'ImageEntity',
      itemDescription: 'string?',
      name: 'string?',
      optionValues: 'ProductSkuOptionValueEntity[]',
      price: 'double',
      productId: 'string?',
      quantity: 'double',
      size: 'string?',
      skuId: 'string?',
      storeId: 'string?',
      updatedAt: 'date?',
      vendorId: 'string?',
    },
  }

  schema = ShoppingCartItemModel.schema

  availabilityEndDate?: Date
  availabilityStartDate?: Date
  createdAt?: Date
  currencyCode?: CurrencyCode
  estimatedDistributionDate?: Date
  image?: ImageModel
  itemDescription?: string
  name?: string
  optionValues: ProductSkuOptionValueModel[] = []
  price: number = 0
  productId?: string
  quantity: number = 0
  size?: MenuItemSizeType
  skuId?: string
  storeId?: string
  updatedAt?: Date
  vendorId?: string

  constructor(entity?: ShoppingCartItemModel | null) {
    if (!entity) return

    this.availabilityEndDate = entity.availabilityEndDate ? moment(entity.availabilityEndDate).toDate() : entity.availabilityEndDate
    this.availabilityStartDate = entity.availabilityStartDate ? moment(entity.availabilityStartDate).toDate() : entity.availabilityStartDate
    this.createdAt = entity.createdAt ? moment(entity.createdAt).toDate() : entity.createdAt
    this.currencyCode = entity.currencyCode
    this.estimatedDistributionDate = entity.estimatedDistributionDate
    this.image = new ImageModel(entity.image)
    this.itemDescription = entity.itemDescription
    this.name = entity.name
    this.optionValues = entity.optionValues.map(i => new ProductSkuOptionValueModel(i))
    this.price = entity.price
    this.productId = entity.productId
    this.quantity = entity.quantity
    this.size = entity.size
    this.skuId = entity.skuId
    this.storeId = entity.storeId
    this.updatedAt = entity.updatedAt ? moment(entity.updatedAt).toDate() : entity.updatedAt
    this.vendorId = entity.vendorId

    return this
  }

  toEntityObservable(realmService: BaseRealmService): Observable<ShoppingCartItemModel> {
    return of(this)
  }

  getIsAvailable(): boolean {
    const start = this.availabilityStartDate
    const end = this.availabilityEndDate

    if (!start) return false
    if (!end) return false

    return (new Date()) > start && (new Date()) < end
  }
}