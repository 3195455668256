import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {Modal} from "../modals/Modal";
import {useRouter} from "next/router";
import {PromptDetailView} from "./PromptDetailView";
import {reset} from "../../redux/prompt/promptDetailSlice";
import {
  fetchPrompts,
  reset as resetPromptList,
  selectCurrentPage,
  setCurrentPage
} from "../../redux/prompt/promptListSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

export const PromptDetailModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();
  const router = useRouter()
  const promptId = router.query.prompt_id as string | undefined
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector(selectCurrentPage)

  return <Modal
    className="md:max-w-lg"
    isOpen={isOpen('prompt_detail')}
    onClose={() => {
      dispatch(reset())
      onClose()

      dispatch(resetPromptList())
      if (pageNumber === 1) {
        dispatch(fetchPrompts(pageNumber))
      } else {
        dispatch(setCurrentPage(1))
      }
    }}
  >
    <PromptDetailView onClose={onClose} promptId={promptId} />
  </Modal>
}