import {EntityModel} from "./entityModel";
import {Observable, of} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectSchema} from "mongodb-realm";

export interface ProductSkuOptionValueJson {
  shopify_product_option_id?: string
  value?: string
  name?: string
}

export class ProductSkuOptionValueModel implements EntityModel<ProductSkuOptionValueModel> {
  public static schema: ObjectSchema = {
    name: 'ProductSkuOptionValueEntity',
    embedded: true,
    properties: {
      name: 'string?',
      optionValueId: 'string?',
      value: 'string?',
    },
  }

  schema = ProductSkuOptionValueModel.schema

  optionValueId?: string
  value?: string
  name?: string

  constructor(entity?: ProductSkuOptionValueModel | null) {
    if (!entity) return

    this.optionValueId = entity.optionValueId
    this.value = entity.value
    this.name = entity.name

    return this
  }

  static fromJson(json?: ProductSkuOptionValueJson): ProductSkuOptionValueModel | undefined {
    if (!json) return

    const model = new ProductSkuOptionValueModel()

    model.optionValueId = json.shopify_product_option_id?.toString()
    model.value = json.value
    model.name = json.name

    return model
  }

  toEntityObservable(realmService: BaseRealmService): Observable<ProductSkuOptionValueModel> {
    return of(this)
  }
}