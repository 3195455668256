import {BaseError} from "./baseError";
import {LocalizationInfo} from "react-i18next";

export type ApiErrorCode = 'INTERNAL_SERVER_ERROR'
  | 'INVALID_DELIVERY_LOCATION'
  | 'INVALID_PARAMETER'
  | 'INVALID_PASSWORD'
  | 'INVALID_PROMO_CODE'
  | 'INVALID_ORDER_ITEM_MULTIPLE'
  | 'NOT_FOUND'
  | 'STRIPE_ERROR'
  | 'UNAUTHORIZED'
  | string

export type ApiErrorActionStyle = 'default' | 'cancel' | 'destructive'

export interface ApiErrorActionJson {
  title?: string
  link_url?: string
  style?: ApiErrorActionStyle
}

export class ApiError extends BaseError<ApiErrorCode> {
  code: ApiErrorCode = ''
  actions?: ApiErrorAction[]
  localizedMessage?: LocalizationInfo | string
  localizedTitle?: LocalizationInfo | string
  message: string = ''
  name: string = ''
  debugDescription?: string

  static fromJson(json?: ApiErrorJson | null): ApiError | undefined {
    if (!json) return

    const model = new ApiError(json.code ?? 'UNKNOWN')

    model.code = json.code ?? 'UNKNOWN'
    model.name = json.code ?? 'ApiError'

    model.actions = json.actions?.map((action) => new ApiErrorAction(action))
    model.localizedTitle = json.title
    model.localizedMessage = json.message
    model.message = json.message ?? ''
    model.debugDescription = json.debug_description

    return model
  }
}

export interface ApiErrorJson {
  code?: ApiErrorCode
  actions?: ApiErrorActionJson[]
  title?: string
  message?: string
  debug_description?: string
}

export class ApiErrorAction {
  title?: string
  linkUrl?: string
  style?: ApiErrorActionStyle

  constructor(json?: ApiErrorActionJson) {
    if (!json) return

    this.title = json.title
    this.linkUrl = json.link_url
    this.style = json.style
  }
}