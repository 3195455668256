import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, mergeMap, of, scheduled, throwError} from "rxjs"
import {setContactInfo, setContactInfoFailed, setContactInfoSucceeded} from "./contactInfoEntrySlice";
import {SetContactInfoPayload} from "../../models/setContactInfoPayload";
import {UserModel} from "../../models/user";
import {ContactInfoValidationError} from "../../errors/contactInfoValidationError";
import {isValidEmail} from "../../utils/isValidEmail";
import {CountryCode, isValidPhoneNumber} from "libphonenumber-js";
import {UserService} from "../../services/userService";
import {fetchUserSucceeded} from "./userSlice";
import {showNotification} from "../navigation/navigationSlice";
import {logErrorRx} from "../../utils/logError";
import {setCustomer} from "../checkout/checkoutSlice";

export const setContactInfoEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(setContactInfo.match),
    mergeMap(action => {
      return validate(action.payload.payload, state$.value.user.user, action.payload.countryCode)
        .pipe(
          mergeMap(UserService.updateContactInfo),
          mergeMap(user => scheduled(
            [
              setContactInfoSucceeded(),
              fetchUserSucceeded(user),
              showNotification({
                title: { key: 'auth:auth_alert_title_update_succeeded' },
                message: { key: 'auth:auth_alert_message_your_account_has_been_updated' }
              })
            ],
            asyncScheduler
          )),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: setContactInfoFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const setCustomerEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(setCustomer.match),
    mergeMap(action => {
      const payload: SetContactInfoPayload = {
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
        email: action.payload.email,
        phone_number: action.payload.phoneNumber
      }
      return validate(payload, state$.value.user.user, action.payload.countryCode)
        .pipe(
          mergeMap(() => scheduled(
            [
              setContactInfoSucceeded(),
              showNotification({
                title: { key: 'auth:auth_alert_title_update_succeeded' },
                message: 'The customer for this order has been updated'
              })
            ],
            asyncScheduler
          )),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: setContactInfoFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

const validate = (payload: SetContactInfoPayload, user?: UserModel | null, countryCode?: string) => {
  if (user && user.roles.includes('anonymous')) {
    if (!payload.email) {
      return throwError(() => new ContactInfoValidationError("EMAIL_IS_EMPTY"))
    } else if (!isValidEmail(payload.email)) {
      return throwError(() => new ContactInfoValidationError("INVALID_EMAIL"))
    }
  }

  if (!payload.first_name) {
    return throwError(() => new ContactInfoValidationError("FIRST_NAME_IS_EMPTY"))
  } else if (!payload.last_name) {
    return throwError(() => new ContactInfoValidationError("LAST_NAME_IS_EMPTY"))
  } else if (!payload.phone_number) {
    return throwError(() => new ContactInfoValidationError("PHONE_NUMBER_IS_EMPTY"))
  } else if (!isValidPhoneNumber(payload.phone_number, countryCode as CountryCode)) {
    return throwError(() => new ContactInfoValidationError("INVALID_PHONE_NUMBER"))
  } else {
    return of(payload)
  }
}