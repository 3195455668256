import {Observable} from "rxjs";
import {AffiliateInfoResponseModel} from "../models/affiliateInfoResponse";
import {AffiliateApi} from "../apis/affiliateApi";
import {OnboardAffiliatePayload} from "../models/onboardAffiliatePayload";
import {AffiliatesResponseModel} from "../models/affiliatesResponse";
import {CommissionsResponseModel} from "../models/commissionsResponse";
import {AffiliateInternalCodesResponseModel} from "../models/affiliateInternalCodesResponse";
import {CommissionType} from "../models/commission";
import {CommissionPayoutsResponseModel} from "../models/commissionPayoutsResponse";
import {SetAffiliateStatusPayload} from "../models/setAffiliateStatusPayload";

export const AffiliateService = {
  fetchAffiliateInfo(): Observable<AffiliateInfoResponseModel> {
    return AffiliateApi.getAffiliateInfo()
  },
  onboardAffiliate(payload: OnboardAffiliatePayload): Observable<void> {
    return AffiliateApi.onboardAffiliate(payload)
  },
  fetchAllAffiliates(pageNumber: number): Observable<AffiliatesResponseModel> {
    return AffiliateApi.getAllAffiliates(pageNumber)
  },
  fetchCommissions(startDate: Date, endDate: Date): Observable<CommissionsResponseModel> {
    return AffiliateApi.getCommissions(startDate, endDate)
  },
  fetchInternalPromoCodes(pageNumber: number): Observable<AffiliateInternalCodesResponseModel> {
    return AffiliateApi.getInternalPromoCodes(pageNumber)
  },
  fetchPayouts(startDate: Date, endDate: Date, type: CommissionType): Observable<CommissionPayoutsResponseModel> {
    return AffiliateApi.getPayouts(startDate, endDate, type)
  },
  setAffiliateStatus(payload: SetAffiliateStatusPayload): Observable<void> {
    return AffiliateApi.setAffiliateStatus(payload)
  },
  getAllAffiliates(pageNumber: number): Observable<AffiliatesResponseModel> {
    return AffiliateApi.getAllAffiliates(pageNumber)
  }
}