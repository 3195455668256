import {CardModel} from "../../models/card";
import {ShippingOptionModel} from "../../models/shippingOption";
import {DistributionMethod} from "../../models/distributionMethod";
import {PurchaseOrderModel} from "../../models/purchaseOrder";
import {ShoppingCartItemModel} from "../../models/shoppingCartItem";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserModel} from "../../models/user";
import {BaseError} from "../../errors/baseError";
import {GenericError} from "../../errors/genericError";
import {LocationModel} from "../../models/location";
import {PickupLocationModel} from "../../models/pickupLocation";
import {PromoCodeModel} from "../../models/promoCode";
import {LocalizationInfo} from "react-i18next";
import Moment from "moment";
import {CurrencyUtils} from "../../utils/currencyUtils";
import {CountryCode, formatNumber} from "libphonenumber-js";
import {RootState} from "../../app/store";
import {ShoppingCartModel} from "../../models/shoppingCart";
import {SalesOrderModel} from "../../models/salesOrder";

export interface CheckoutCustomer {
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  countryCode?: string
}

export interface CheckoutState {
  readonly isLoading: boolean
  readonly isPurchasing: boolean
  readonly isUpdating: boolean
  readonly showCartUpdateAlert: boolean
  readonly defaultCard?: CardModel
  readonly selectedShippingOptionId?: string
  readonly orderId?: string
  readonly storeId?: string
  readonly user?: UserModel | null
  readonly customer?: CheckoutCustomer
  readonly buttonTitle?: string | LocalizationInfo
  readonly buttonDestination?: CheckoutDestination
  readonly shippingOptions: ShippingOptionModel[]
  readonly purchaseOrder?: PurchaseOrderModel
  readonly salesOrder?: SalesOrderModel
  readonly cart?: ShoppingCartModel
  readonly sections: CheckoutSection[]
  readonly error?: BaseError<any> | null
  readonly purchaseError?: BaseError<any> | null
}

type CheckoutSection = { title: string | LocalizationInfo, id: 'shipping_details' | 'cart_items' | 'delivery_options' | 'payment' | string, items: (CheckoutItem | CheckoutOptionItem)[] }
type CheckoutDestination = 'location_entry' | 'contact_info_entry' | 'payment_entry' | 'pickup_location_selector'

export interface CheckoutData {
  cart?: ShoppingCartModel
  purchaseOrder?: PurchaseOrderModel
  user?: UserModel | null
  defaultCard?: CardModel
  didUpdateItems: boolean
}

export class CheckoutItem {
  type: 'delivery_location' | 'pickup_location' | 'promo_code' | 'contact' | 'card' | 'cart_item' = 'delivery_location'
  title: string | LocalizationInfo = ''
  id: string = ''
  subtitle?: string | LocalizationInfo
  isCompleted: boolean = false
  buttonTitle?: string | LocalizationInfo
  value?: UserModel | LocationModel | PickupLocationModel | PromoCodeModel | CardModel | ShoppingCartItemModel
}

export class CheckoutOptionItem {
  type: 'shipping_option' = 'shipping_option'
  title: string | LocalizationInfo = ''
  id: string = ''
  subtitle?: string | LocalizationInfo
  isSelected: boolean = false
  value?: ShippingOptionModel
}

const createShippingOptionsSection = (data: { shippingOptions: ShippingOptionModel[], selectedShippingOptionId?: string }): CheckoutSection => {
  const { shippingOptions, selectedShippingOptionId } = data
  const options: CheckoutOptionItem[] = shippingOptions.map(option => {
    const isSelected = option.id === selectedShippingOptionId
    const deliveryRange = option.deliveryRange

    let title: string | LocalizationInfo;
    if (deliveryRange.length) {
      const start = Math.ceil(Moment(deliveryRange[0]).diff(Moment(), 'seconds') / 86400)
      const end = Math.ceil(Moment(deliveryRange[deliveryRange.length - 1]).diff(Moment(), 'seconds') / 86400)

      let distributionMethodText: string | LocalizationInfo;
      switch (option.distributionMethod ?? 'delivery') {
        case 'delivery':
          distributionMethodText = 'Delivered'
          break
        case 'pickup':
          distributionMethodText = 'Pickup'
          break
      }

      if (start != null && end != null && start >= 0 && end >= 0 && start !== end) {
        title = { key: 'checkout:checkout_row_title_distribution_range', options: { distributionMethod: distributionMethodText, start: start.toString(), end: end.toString() } }
      } else if (start != null && start >= 0) {
        if (start === 0) {
          title = { key: 'checkout:checkout_row_title_distribution_today', options: { distributionMethod: distributionMethodText } }
        } else if (start === 1) {
          title = { key: 'checkout:checkout_row_title_distribution_tomorrow', options: { distributionMethod: distributionMethodText } }
        } else {
          title = { key: 'checkout:checkout_row_title_distribution_days', options: { distributionMethod: distributionMethodText, days: start.toString() } }
        }
      } else {
        if (option.distributionMethod === 'pickup') {
          title = { key: 'checkout:checkout_row_title_pickup' }
        } else {
          title = { key: 'checkout:checkout_row_title_delivery' }
        }
      }
    } else {
      if (option.distributionMethod === 'pickup') {
        title = { key: 'checkout:checkout_row_title_pickup' }
      } else {
        title = { key: 'checkout:checkout_row_title_delivery' }
      }
    }

    const price = option.price
    const shippingOptionTitle = option.title

    let subtitle: string | LocalizationInfo | undefined = undefined;
    if (price && shippingOptionTitle != null) {
      const priceText = CurrencyUtils.stringFromPrice(price, option.currencyCode)
      subtitle = { key: 'checkout:checkout_row_subtitle_shipping_price', options: { price: priceText, title: shippingOptionTitle } }
    } else if (shippingOptionTitle) {
      subtitle = { key: 'checkout:checkout_row_subtitle_shipping_free', options: { title: shippingOptionTitle } }
    }

    const item = new CheckoutOptionItem()
    item.type = 'shipping_option'
    item.title = title
    item.subtitle = subtitle
    item.isSelected = isSelected
    item.id = option.id ?? ''
    item.value = option

    return item
  })

  return {
    title: { key: 'checkout:checkout_section_header_title_delivery_options' },
    items: options,
    id: 'delivery_options'
  }
}

const createShippingDetailsSection = (data: { user?: UserModel, customer?: CheckoutCustomer, deliveryLocation?: LocationModel, pickupLocation?: PickupLocationModel, selectedMethod: DistributionMethod }): CheckoutSection => {
  const { user, customer, deliveryLocation, pickupLocation, selectedMethod } = data

  const locationItem = new CheckoutItem();
  if (selectedMethod === 'pickup') {
    locationItem.type = 'pickup_location'
    locationItem.id = 'pickup_location'
    locationItem.title = pickupLocation?.name ?? { key: 'checkout:checkout_row_title_add_pickup_location' }
    locationItem.subtitle = pickupLocation?.location?.address?.getFormattedAddress() ?? undefined
    locationItem.isCompleted = !!pickupLocation
    locationItem.buttonTitle = !!pickupLocation ? { key: 'common:common_title_edit' } : { key: 'common:common_title_add' }
    locationItem.value = pickupLocation
  } else {
    locationItem.type = 'delivery_location'
    locationItem.id = 'delivery_location'
    locationItem.title = deliveryLocation?.address?.getFullStreetAddress() ?? { key: 'checkout:checkout_row_title_add_address' }
    locationItem.subtitle = deliveryLocation?.address?.getCityStateZip() ?? undefined
    locationItem.isCompleted = !!deliveryLocation
    locationItem.buttonTitle = !!deliveryLocation ? { key: 'common:common_title_edit' } : { key: 'common:common_title_add' }
    locationItem.value = deliveryLocation
  }

  const isAdmin = user?.roles?.includes('admin')

  let countryCode = deliveryLocation?.address?.countryCode ?? pickupLocation?.location?.address?.countryCode ?? 'US'
  countryCode = isAdmin ? customer?.countryCode ?? countryCode : countryCode
  const phoneNumber = isAdmin ? customer?.phoneNumber ?? user?.phoneNumber : user?.phoneNumber
  const formattedNumber = phoneNumber && formatNumber({ phone: phoneNumber, country: countryCode as CountryCode}, 'National')
  const firstName = isAdmin ? customer?.firstName ?? user?.firstName : user?.firstName
  const lastName = isAdmin ? customer?.lastName ?? user?.lastName : user?.lastName

  const contactItem = new CheckoutItem()
  contactItem.title = (() => {
    let text: string | LocalizationInfo;
    if (firstName && lastName) {
      text = `${firstName} ${lastName}`
    } else if (firstName) {
      text = firstName
    } else if (formattedNumber) {
      text = { key: 'checkout:checkout_row_title_add_contact_name' }
    } else {
      text = { key: 'checkout:checkout_row_title_add_contact_info' }
    }

    return text
  })()
  contactItem.subtitle = (() => {
    let text: string | LocalizationInfo | undefined;
    if (formattedNumber) {
      text = formattedNumber
    } else if (!formattedNumber && firstName) {
      text = { key: 'checkout:checkout_row_title_add_contact_number' }
    } else {
      text = undefined
    }

    return text
  })()
  contactItem.type = "contact"
  contactItem.id = "contact"
  contactItem.isCompleted = !!(firstName && formattedNumber)
  contactItem.buttonTitle = !!(firstName && formattedNumber) ? { key: 'common:common_title_edit' } : { key: 'common:common_title_add' }
  contactItem.value = user

  return {
    title: { key: 'checkout:checkout_section_header_title_shipping' },
    items: [locationItem, contactItem],
    id: 'shipping_details'
  }
}

const createCartItemsSection = (cartItems: ShoppingCartItemModel[]): CheckoutSection => {
  const items: CheckoutItem[] = cartItems.map(cartItem => {
    const unitPrice = cartItem.price * cartItem.quantity
    const totalPrice = CurrencyUtils.stringFromPrice(unitPrice, cartItem.currencyCode)

    const item = new CheckoutItem()
    item.type = 'cart_item'
    item.title = cartItem.name ?? ''
    item.subtitle = totalPrice
    item.isCompleted = true
    item.value = cartItem
    item.id = cartItem.skuId ?? ''
    item.buttonTitle = { key: 'common:common_title_edit' }

    return item
  })

  return {
    title: { key: 'checkout:checkout_section_header_title_your_items' },
    items,
    id: 'cart_items'
  }
}

const createPaymentSection = (data: { card?: CardModel, promoCode?: PromoCodeModel }): CheckoutSection => {
  const { card, promoCode } = data

  const last4 = card?.lastFourCardNumDigits
  const cardItem = new CheckoutItem()
  cardItem.type = 'card'
  cardItem.id = 'card'
  cardItem.title = last4 ? { key: 'checkout:checkout_row_title_last4', options: { last4 } } : { key: 'checkout:checkout_button_title_add_payment_method' }
  cardItem.isCompleted = !!card
  cardItem.buttonTitle = !!card ? { key: 'common:common_title_edit' } : { key: 'common:common_title_add' }
  cardItem.value = card

  const promoCodeItem = new CheckoutItem()
  promoCodeItem.type = 'promo_code'
  promoCodeItem.id = 'promo_code'
  promoCodeItem.title = promoCode?.code ?? { key: 'checkout:checkout_row_title_add_promo_code' }
  promoCodeItem.subtitle = promoCode?.title
  promoCodeItem.isCompleted = true
  promoCodeItem.buttonTitle = !!promoCode ? { key: 'common:common_title_remove' } : { key: 'common:common_title_add' }
  promoCodeItem.value = promoCode

  return {
    title: { key: 'checkout:checkout_section_header_title_payment' },
    items: [cardItem, promoCodeItem],
    id: 'payment'
  }
}

const createSections = (data: CheckoutData, customer?: CheckoutCustomer): CheckoutSection[] => {
  const selectedMethod = data.purchaseOrder?.shippingOptions.filter(o => o.id === data.purchaseOrder?.shippingOptionId)[0]?.distributionMethod ?? 'delivery'
  const shippingOptionSection = createShippingOptionsSection({
    shippingOptions: data.purchaseOrder?.shippingOptions ?? [] ,
    selectedShippingOptionId: data.purchaseOrder?.shippingOptionId
  })
  const shippingDetailsSection = createShippingDetailsSection({
    user: data.user ?? undefined,
    customer,
    deliveryLocation: data.purchaseOrder?.deliveryLocation,
    pickupLocation: data.purchaseOrder?.pickupLocation,
    selectedMethod
  })
  const paymentSection = createPaymentSection({
    card: data.defaultCard,
    promoCode: data.purchaseOrder?.paymentSummary?.appliedPromoCode
  })
  const cartItemsSection = createCartItemsSection(data.cart?.items ?? [])

  return [shippingOptionSection, shippingDetailsSection, paymentSection, cartItemsSection]
    .filter(s => s.items.length)
}

const createLoadingSection = (): CheckoutSection[] => {
  return [
    {
      title: { key: 'checkout:checkout_section_header_title_delivery_options' },
      items: (() => {
        const option1 = new CheckoutOptionItem()
        const option2 = new CheckoutOptionItem()

        option1.id = '1'
        option1.title = 'Option 1'
        option1.subtitle = 'Option 1'
        option1.type = 'shipping_option'

        option2.id = '2'
        option2.title = 'Option 2'
        option2.subtitle = 'Option 2'
        option2.type = 'shipping_option'

        return [option1, option2]
      })(),
      id: 'delivery_options_loading'
    },
    {
      title: { key: 'checkout:checkout_section_header_title_shipping' },
      items: (() => {
        const option1 = new CheckoutItem()
        const option2 = new CheckoutItem()

        option1.id = 'delivery_location_1'
        option1.title = 'Item 1'
        option1.subtitle = 'Item 1'
        option1.type = 'delivery_location'
        option1.buttonTitle = { key: 'common:common_title_edit' }

        option2.id = 'delivery_location_2'
        option2.title = 'Item 2'
        option2.subtitle = 'Item 2'
        option2.type = 'contact'
        option2.buttonTitle = { key: 'common:common_title_edit' }

        return [option1, option2]
      })(),
      id: 'shipping_details_loading'
    },
    {
      title: { key: 'checkout:checkout_section_header_title_payment' },
      items: (() => {
        const option1 = new CheckoutItem()
        const option2 = new CheckoutItem()

        option1.id = 'card_1'
        option1.title = 'Item 1'
        option1.subtitle = 'Item 1'
        option1.type = 'card'
        option1.buttonTitle = { key: 'common:common_title_edit' }

        option2.id = 'promo_code_2'
        option2.title = 'Item 2'
        option2.subtitle = 'Item 2'
        option2.type = 'promo_code'
        option2.buttonTitle = { key: 'common:common_title_edit' }

        return [option1, option2]
      })(),
      id: 'payment_loading'
    },
    {
      title: { key: 'checkout:checkout_section_header_title_your_items' },
      items: (() => {
        const option1 = new CheckoutItem()
        const option2 = new CheckoutItem()

        option1.id = 'cart_item_1'
        option1.title = 'Item 1'
        option1.subtitle = 'Item 1'
        option1.type = 'cart_item'
        option1.buttonTitle = { key: 'common:common_title_edit' }

        option2.id = 'cart_item_2'
        option2.title = 'Item 2'
        option2.subtitle = 'Item 2'
        option2.type = 'cart_item'
        option2.buttonTitle = { key: 'common:common_title_edit' }

        return [option1, option2]
      })(),
      id: 'cart_items_loading'
    }
  ]
}


export const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    isLoading: true,
    isPurchasing: true,
    isUpdating: true,
    showCartUpdateAlert: false,
    shippingOptions: [],
    sections: [],
    buttonTitle: { key: 'checkout:checkout_row_title_add_payment_method' }
  } as CheckoutState,
  reducers: {
    clearError(state) {
      state.error = undefined
      state.purchaseError = undefined
    },
    clearUpdateAlert(state) {
      state.showCartUpdateAlert = false
    },
    removePromoCode(state) {
      const purchaseOrder = new PurchaseOrderModel(state.purchaseOrder as PurchaseOrderModel)
      delete purchaseOrder.paymentSummary?.appliedPromoCode

      const updatedSections: CheckoutSection[] = state.sections.map(section => {
        if (section.id === 'payment') {
          const items = section.items.map(item => {
            if (item instanceof CheckoutItem && item.type === 'promo_code') {
              const castItem = item as CheckoutItem

              const newItem = new CheckoutItem()
              newItem.title = { key: 'checkout:checkout_row_title_add_promo_code' }
              newItem.id = castItem.id
              newItem.type = castItem.type
              newItem.isCompleted = true
              newItem.buttonTitle = { key: 'common:common_title_add' }

              return newItem
            } else {
              return item as CheckoutItem
            }
          })

          return { title: (section as CheckoutSection).title, items } as CheckoutSection
        } else {
          return section as CheckoutSection
        }
      })

      return {
        ...state,
        isUpdating: true,
        sections: updatedSections,
        purchaseOrder
      } as CheckoutState
    },
    reset(state) {
      return {
        ...state,
        isLoading: true,
        isUpdating: true,
        isPurchasing: false,
        defaultCard: undefined,
        selectedShippingOptionId: undefined,
        orderId: undefined,
        storeId: undefined,
        shippingOptions: [],
        purchaseOrder: undefined,
        cart: undefined,
        error: undefined,
        purchaseError: undefined,
        salesOrder: undefined,
        customer: undefined,
        buttonTitle: { key: 'checkout:checkout_row_title_add_payment_method' },
        sections: !state.sections.length ? createLoadingSection() : state.sections
      }
    },
    fetchCheckoutData(state, action: PayloadAction<{ storeId: string, reload?: boolean }>) {
      return {
        ...state,
        isLoading: !state.purchaseOrder || action.payload.reload === true,
        isUpdating: true,
        storeId: action.payload.storeId,
        sections: !state.sections.length ? createLoadingSection() : state.sections
      } as CheckoutState
    },
    createSalesOrder(state) {
      state.isPurchasing = true
    },
    optionItemSelected(state, action: PayloadAction<CheckoutOptionItem>) {
      if (action.payload.value instanceof ShippingOptionModel) {
        const purchaseOrder = new PurchaseOrderModel(state.purchaseOrder as PurchaseOrderModel)
        purchaseOrder.shippingOptionId = action.payload.value.id
        purchaseOrder.distributionMethod = purchaseOrder.shippingOptions.filter(o => o.id === action.payload.value?.id)[0]?.distributionMethod

        const updatedSections: CheckoutSection[] = state.sections.map(section => {
          if (section.id === 'delivery_options') {
            const items = section.items.map(item => {
              if (item instanceof CheckoutOptionItem) {
                const castItem = item as CheckoutOptionItem

                const newItem = new CheckoutOptionItem()
                newItem.title = castItem.title
                newItem.subtitle = castItem.subtitle
                newItem.id = castItem.id
                newItem.type = castItem.type
                newItem.value = castItem.value
                newItem.isSelected = castItem.id === action.payload.id
                return newItem
              } else {
                return item as CheckoutItem
              }
            })

            return { title: (section as CheckoutSection).title, items } as CheckoutSection
          } else {
            return section as CheckoutSection
          }
        })

        return {
          ...state,
          isUpdating: action.payload.value.id !== state.selectedShippingOptionId,
          selectedShippingOptionId: action.payload.value.id,
          sections: updatedSections,
          purchaseOrder
        } as CheckoutState
      }
    },
    setCustomer(state, action: PayloadAction<CheckoutCustomer>) {
      state.customer = action.payload
    },
    fetchCheckoutDataSucceeded(state, action: PayloadAction<CheckoutData>) {
      const purchaseOrder = action.payload.purchaseOrder
      const location = purchaseOrder?.deliveryLocation
      const pickupLocation = purchaseOrder?.pickupLocation
      const user = action.payload.user
      const card = action.payload.defaultCard
      const selectedMethod: DistributionMethod = action.payload.purchaseOrder?.distributionMethod === 'pickup' ? 'pickup' : 'delivery'

      const locationTypeIsApprox = location?.type === 'approximate'
      const locationTypeIsExplore = location?.type === 'explore'

      let buttonDestination: CheckoutDestination | undefined;
      let buttonTitle: string | LocalizationInfo | undefined;

      if (!location || locationTypeIsApprox || locationTypeIsExplore || !location?.type) {
        buttonTitle = { key: 'checkout:checkout_row_title_add_address' }
        buttonDestination = 'location_entry'
      } else if (selectedMethod === 'pickup' && !pickupLocation) {
        buttonTitle = { key: 'checkout:checkout_row_title_add_pickup_location' }
        buttonDestination = 'pickup_location_selector'
      } else if (!user?.firstName) {
        buttonTitle = { key: 'checkout:checkout_row_title_add_contact_name' }
        buttonDestination = 'contact_info_entry'
      } else if (!user?.phoneNumber) {
        buttonTitle = { key: 'checkout:checkout_row_title_add_contact_number' }
        buttonDestination = 'contact_info_entry'
      } else if (!card) {
        buttonTitle = { key: 'checkout:checkout_row_title_add_payment_method' }
        buttonDestination = 'payment_entry'
      } else {
        buttonTitle = { key: 'checkout:checkout_button_title_place_order' }
      }

      return {
        ...state,
        cart: action.payload.cart,
        purchaseOrder: action.payload.purchaseOrder,
        defaultCard: action.payload.defaultCard,
        buttonDestination,
        buttonTitle,
        user,
        showCartUpdateAlert: action.payload.didUpdateItems,
        selectedShippingOptionId: action.payload.purchaseOrder?.shippingOptionId,
        shippingOptions: action.payload.purchaseOrder?.shippingOptions ?? [],
        orderId: action.payload.purchaseOrder?.id,
        isLoading: false,
        isPurchasing: false,
        isUpdating: false,
        sections: createSections(action.payload, state.customer)
      } as CheckoutState
    },
    fetchCheckoutDataFailed(state, action: PayloadAction<Error>) {
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('CHECKOUT_DATA_FETCH_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'checkout:checkout_alert_message_error_getting_data' }
        error.error = action.payload
        state.error = error as any
      }
    },
    createSalesOrderFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false
      state.isPurchasing = false

      if (action.payload instanceof BaseError) {
        state.purchaseError = action.payload as any
      } else {
        const error = new GenericError('PURCHASE_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'checkout:checkout_alert_message_error_purchasing' }
        error.error = action.payload
        state.purchaseError = error as any
      }
    },
    createSalesOrderSucceeded(state, action: PayloadAction<SalesOrderModel>) {
      state.isLoading = false
      state.isPurchasing = false
      state.salesOrder = action.payload
    }
  }
})

export const checkoutReducer = checkoutSlice.reducer

export const {
  fetchCheckoutData,
  fetchCheckoutDataSucceeded,
  fetchCheckoutDataFailed,
  createSalesOrder,
  createSalesOrderFailed,
  createSalesOrderSucceeded,
  clearError,
  reset,
  removePromoCode,
  clearUpdateAlert,
  optionItemSelected,
  setCustomer
} = checkoutSlice.actions

export const selectPurchaseOrder = (state: RootState) => state.checkout.purchaseOrder
export const selectSalesOrder = (state: RootState) => state.checkout.salesOrder
export const selectPurchaseError = (state: RootState) => state.checkout.purchaseError
export const selectError = (state: RootState) => state.checkout.error
export const selectCustomerForAdmin = (state: RootState) => state.checkout.customer
export const selectIsLoading = (state: RootState) => state.checkout.isLoading
export const selectStoreId = (state: RootState) => state.checkout.storeId
export const selectShowCartUpdateAlert = (state: RootState) => state.checkout.showCartUpdateAlert
export const selectIsPurchasing = (state: RootState) => state.checkout.isPurchasing
export const selectIsUpdating = (state: RootState) => state.checkout.isUpdating
export const selectSections = (state: RootState) => state.checkout.sections
export const selectButtonTitle = (state: RootState) => state.checkout.buttonTitle
export const selectButtonDestination = (state: RootState) => state.checkout.buttonDestination