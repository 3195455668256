import {RootEpic} from "../../app/store";
import {debounceTime, filter, tap} from "rxjs/operators";
import {
  deleteLocation,
  deleteLocationFailed,
  deleteLocationSucceeded,
  fetchAndSaveLocation,
  fetchAndSaveLocationFailed,
  fetchAndSaveLocationSucceeded,
  fetchSavedLocations,
  fetchSavedLocationsFailed,
  fetchSavedLocationsSucceeded,
  fetchSearchResults,
  fetchSearchResultsFailed,
  fetchSearchResultsSucceeded,
  savePrimaryLocationSucceeded,
  updatePrimaryLocation,
  updatePrimaryLocationSucceeded
} from "./locationEntrySlice";
import {
  asyncScheduler,
  catchError,
  combineLatest,
  map,
  mergeMap,
  Observable,
  of,
  pipe,
  scheduled,
  startWith,
  throwError
} from "rxjs";
import {LocationService} from "../../services/locationService";
import {LocationModel} from "../../models/location";
import {clearPrimaryLocation, fetchLatLngSucceeded, setPrimaryLocation} from "../user/userSlice";
import {RealmServiceError} from "../../errors/realmServiceError";
import {fetchStoreFeed} from "../storeFeed/storeFeedSlice";
import {CallRouterMethodPushPayload} from "connected-next-router/actions";
import {LatLng} from "googlemaps";
import {logErrorRx} from "../../utils/logError";
import {EventLogger} from "../../utils/eventLogger";

export const fetchSavedLocationsEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchSavedLocations.match),
    mergeMap(() => {
      const cachedLocation = state$.value.user.primaryLocation ?? null
      const primaryLocation: Observable<LocationModel | null> = LocationService.fetchPrimaryDeliveryLocation()
        .pipe(
          map(location => {
            if (location) {
              return location
            } else {
              return cachedLocation
            }
          }),
          catchError(() => of(cachedLocation))
        )

      let currentLocation: Observable<LocationModel | null> = LocationService.fetchCurrentLocation()
        .pipe(
          mergeMap(l => {
            if (!l) {
              return LocationService.fetchApproxLocation()
            } else {
              return of(l)
            }
          }),
          catchError(() => of(null)),
        )

      if (cachedLocation?.type === 'current') {
        currentLocation = currentLocation.pipe(startWith(cachedLocation))
      }

      const savedLocations: Observable<LocationModel[]> = LocationService.fetchDeliveryLocations()
        .pipe(
          catchError(() => of([])),
          map(locations => {
            if (locations.length > 0) {
              return locations
            } else if (cachedLocation && cachedLocation?.type !== 'current' && cachedLocation?.type !== 'approximate') {
              return [cachedLocation]
            } else {
              return locations
            }
          })
        )

      return combineLatest(
        [
          primaryLocation,
          currentLocation,
          savedLocations
        ]
      ).pipe(
        map(([primaryLocation, currentLocation, savedLocations]) => {
          if (currentLocation) {
            const locations = currentLocation.locationId == primaryLocation?.locationId
              ? [currentLocation].concat(savedLocations)
              : savedLocations.concat(currentLocation)

            return { locations, primaryLocation }
          } else {
            return { locations: savedLocations, primaryLocation }
          }
        }),
        mergeMap(({ locations, primaryLocation }) => scheduled(
          [
            fetchSavedLocationsSucceeded({ locations, primaryLocation }),
          ],
          asyncScheduler
        )),
        catchError(error => logErrorRx(error)),
        catchError(error => of({
          type: fetchSavedLocationsFailed.type,
          payload: error,
          error: true
        }))
      )
    })
  )
}

export const fetchSearchResultsEpic: RootEpic = (action$, state$) => {
  const hasPermissionObservable = LocationService.fetchLocationPermissions()

  const locationObservable: Observable<LatLng> = hasPermissionObservable
    .pipe(mergeMap(hasPermission => {
      return hasPermission ? LocationService.fetchCurrentLatLng() : LocationService.fetchApproxLatLng()
    }))
    .pipe(catchError(() => LocationService.fetchApproxLatLng()))
    .pipe(catchError(() => of({ lat: 39.8283, lng: 98.5795 })))

  return action$.pipe(
    filter(fetchSearchResults.match),
    debounceTime(300),
    mergeMap(action => {
      if (action.payload.length < 2) {
        return of([])
          .pipe(map(fetchSearchResultsSucceeded))
      }

      const userLatLng = state$.value.user.userLatLng
      return (userLatLng ? of(userLatLng) : locationObservable)
        .pipe(
          mergeMap((latLng) => {
            return LocationService.fetchAutocompletePredictions(action.payload, latLng)
              .pipe(map(predictions => ({ predictions, latLng })))
          }),
          mergeMap(({predictions, latLng}) => scheduled(
            [
              fetchSearchResultsSucceeded(predictions),
              fetchLatLngSucceeded(latLng)
            ],
            asyncScheduler
          )),
          // map(fetchAutocompletePredictionsSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchSearchResultsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deleteLocationEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(deleteLocation.match),
    pipe(
      mergeMap(action => {
        return LocationService.deleteLocation(action.payload)
          .pipe(
            catchError(error => {
              if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
                return of(undefined)
              } else {
                return throwError(() => error)
              }
            }),
            mergeMap(() => {
              const cachedLocation = state$.value.user.primaryLocation ?? null

              let actions: {payload: any, type: string}[] = [
                deleteLocationSucceeded(action.payload)
              ]

              if (cachedLocation?.locationId === action.payload.locationId) {
                actions = actions.concat([clearPrimaryLocation(), fetchStoreFeed({ shouldAutoRoute: false })])
              }

              return scheduled(actions, asyncScheduler)
            }),
            catchError(error => logErrorRx(error)),
            catchError(error => of({
              type: deleteLocationFailed.type,
              payload: error,
              error: true
            }))
          )
      })
    )
  )
})

export const fetchAndSaveLocationEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchAndSaveLocation.match),
    mergeMap(action => {
      return LocationService.fetchLocationFromPlaceId(action.payload)
        .pipe(
          // tap({
          //   next: (location) => {
          //     location && EventLogger.updateGlobalPropertiesWithLocation(location)
          //   }
          // }),
          // mergeMap(location => {
          //   return LocationService.setPrimaryDeliveryLocation(location)
          //     .pipe(catchError(() => of(location)))
          // }),
          mergeMap(location => {
            let actions: ({payload: LocationModel | null | undefined, type: string} | CallRouterMethodPushPayload)[] = [
              // setPrimaryLocation(location),
              // fetchSavedLocations(),
              fetchAndSaveLocationSucceeded(location),
              // savePrimaryLocationSucceeded(),
              // fetchStoreFeed()
            ]
            return scheduled(actions, asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchAndSaveLocationFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const savePrimaryLocationEpic: RootEpic = (action$, $payload) => {
  return action$.pipe(
    filter(updatePrimaryLocation.match),
    pipe(
      mergeMap(action => {
        if (action.payload) {
          return LocationService.setPrimaryDeliveryLocation(action.payload)
            .pipe(catchError(() => of(action.payload)))
        } else {
          return of(null)
        }
      }),
      tap({
        next: (location) => {
          location && EventLogger.updateGlobalPropertiesWithLocation(location)
        }
      }),
      mergeMap(location => {
        let actions = location && [
          updatePrimaryLocationSucceeded(location),
          setPrimaryLocation(location),
          savePrimaryLocationSucceeded(),
          fetchStoreFeed({ shouldAutoRoute: false })
        ]
        return scheduled(actions ?? [], asyncScheduler)
      }),
    )
  )
}