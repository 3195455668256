import {RootEpic} from "../../app/store";
import {catchError, filter, map, mergeMap, of} from "rxjs";
import {fetchUser, fetchUserFailed, fetchUserSucceeded} from "./userSlice";
import {UserService} from "../../services/userService";
import {logErrorRx} from "../../utils/logError";
import {tap} from "rxjs/operators";
import {EventLogger} from "../../utils/eventLogger";

export const fetchUserEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchUser.match),
    mergeMap(() => {
      return UserService.fetchUser()
        .pipe(
          tap({
            next: user => {
              user && EventLogger.updateGlobalPropertyWithUser(user)
            }
          }),
          map(fetchUserSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchUserFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}