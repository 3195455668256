import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {Modal} from "../modals/Modal";
import {AffiliateOnboardView} from "./AffiliateOnboardView";

export const AffiliateOnboardModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();

  return <Modal
    className="md:max-w-lg"
    isOpen={isOpen('affiliate_onboard')}
    onClose={() => onClose()}
  >
    <AffiliateOnboardView onClose={onClose} />
  </Modal>
}