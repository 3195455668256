import {CommissionType} from "./commission";
import {CurrencyCode} from "./currencyCode";
import {VendorJson, VendorModel} from "./vendor";

export interface CommissionPayoutJson {
  amount: number,
  type: CommissionType,
  currency_code?: CurrencyCode,
  vendor?: VendorJson
  createdAt?: Date
  updatedAt?: Date
}

export class CommissionPayoutModel {
  amount: number = 0
  type: CommissionType = 'store_credit'
  currencyCode?: CurrencyCode
  vendor?: VendorModel
  createdAt?: Date
  updatedAt?: Date

  static fromJson(json?: CommissionPayoutJson): CommissionPayoutModel | undefined {
    if (!json) return

    const model = new CommissionPayoutModel()
    model.amount = json.amount
    model.type = json.type
    model.currencyCode = json.currency_code
    model.vendor = VendorModel.fromJson(json.vendor)
    model.createdAt = json.createdAt
    model.updatedAt = json.updatedAt

    return model
  }
}