import {OrderDetailItem} from "../../redux/orderDetail/orderDetailSlice";
import React from "react";
import {useTranslation} from "next-i18next";
import {SalesOrderFulfillmentModel} from "../../models/salesOrderFulfilment";
import {
  AmexColorIcon,
  BoxIcon,
  CouponIcon,
  DebitCardIcon,
  DeliveredIcon,
  DinersClubColorIcon,
  DiscoverColorIcon,
  FryingPanIcon,
  InTransitIcon,
  JcbColorIcon,
  MarkerIcon,
  MarkerTrackingIcon,
  MasterCardColorIcon,
  PhoneIcon,
  RefundedIcon,
  UnionPayColorIcon,
  VisaColorIcon,
  WarningIcon
} from "../icons";
import {CardBrand, CardModel} from "../../models/card";
import {Body, Heading3, LocalizedTextLabel, Paragraph} from "../text";
import {SalesOrderLineItemModel} from "../../models/salesOrderLineItem";

interface OrderDetailItemViewProps {
  item: OrderDetailItem
}

export const OrderDetailItemView: React.FC<OrderDetailItemViewProps> = ({ item }) => {
  const { t } = useTranslation()

  const Wrapper: React.FC = (props) => {
    return <>
      {
        (() => {
          if (item.type === 'shipment_status' && item.value instanceof SalesOrderFulfillmentModel) {
            const url = item.value.trackingNumbers[0]

            return <a target="_blank" rel="noreferrer" href={url}>
              <div
                className={`flex flex-row items-center space-x-3 lg:space-x-4 py-5`}
              >
                {props.children}
              </div>
            </a>
          } else if (item.type === 'tracking_number' && typeof item.value === 'string') {
            return <a target="_blank" rel="noreferrer" href={item.value}>
              <div
                className={`flex flex-row items-center space-x-3 lg:space-x-4 py-5`}
              >
                {props.children}
              </div>
            </a>
          } else if (item.type === 'cart_item') {
            return <div
              className={`flex flex-row items-center space-x-3 lg:space-x-4 py-1.5`}
            >
              {props.children}
            </div>
          } else {
            return <div
              className={`flex flex-row items-center space-x-3 lg:space-x-4 py-5`}
            >
              {props.children}
            </div>
          }
        })()
      }
    </>
  }

  return <div className={`${item.type === 'cart_item' ? '' : 'border-b last:border-0 border-fg-gray-surface'}`}>
    <Wrapper>
      <div className="flex-shrink-0">
        {
          (() => {
            if (item.type === 'delivery_location' || item.type === 'pickup_location') {
              return <MarkerIcon className={` h-8 w-8 mx-1`} />
            } else if (item.type === 'contact') {
              return <PhoneIcon className={`h-8 w-8 mx-1`} />
            } else if (item.type === 'card') {
              const cardBrand: CardBrand | undefined = item.value instanceof CardModel ? item.value.brand : undefined

              return <div>
                <VisaColorIcon className={`h-8 w-8 mx-1 ${cardBrand === 'visa' || cardBrand === 'Visa' ? '' : 'hidden'}`} />
                <MasterCardColorIcon className={`h-8 w-8 mx-1 ${cardBrand === 'mastercard' || cardBrand === 'MasterCard' ? '' : 'hidden'}`} />
                <AmexColorIcon className={`h-8 w-8 mx-1 ${cardBrand === 'amex' || cardBrand === 'American Express' ? '' : 'hidden'}`} />
                <DiscoverColorIcon className={`h-8 w-8 mx-1 ${cardBrand === 'discover' || cardBrand === 'Discover' ? '' : 'hidden'}`} />
                <DinersClubColorIcon className={`h-8 w-8 mx-1 ${cardBrand === 'diners' || cardBrand === 'Diners Club' ? '' : 'hidden'}`} />
                <JcbColorIcon className={`h-8 w-8 mx-1 ${cardBrand === 'jcb' || cardBrand === 'JCB' ? '' : 'hidden'}`} />
                <UnionPayColorIcon className={`h-8 w-8 mx-1 ${cardBrand === 'union' || cardBrand === 'Union Pay' || cardBrand === 'Union Pay' ? '' : 'hidden'}`} />
                <DebitCardIcon className={`h-8 w-8 mx-1 ${cardBrand === 'unknown' || !cardBrand ? '' : 'hidden'}`} />
              </div>
            } else if (item.type === 'promo_code') {
              return <CouponIcon className="text-fg-black h-8 w-8 mx-1" />
            } else if (item.type === 'cart_item' && item.value instanceof SalesOrderLineItemModel) {
              return <div className="bg-fg-gray-surface flex justify-center items-center rounded-full h-10 w-10">
                <Heading3>{item.value.quantity}x</Heading3>
              </div>
            } else if (item.type === 'shipment_status') {
              switch (item.icon) {
                case 'box':
                  return <BoxIcon className={` h-8 w-8 mx-1`} />
                case 'delivered':
                  return <DeliveredIcon className={` h-8 w-8 mx-1`} />
                case 'frying_pan':
                  return <FryingPanIcon className={` h-8 w-8 mx-1`} />
                case 'in_transit':
                  return <InTransitIcon className={` h-8 w-8 mx-1`} />
                case 'marker':
                  return <MarkerIcon className={` h-8 w-8 mx-1`} />
                case 'refunded':
                  return <RefundedIcon className={` h-8 w-8 mx-1`} />
                case 'warning':
                  return <WarningIcon className={` h-8 w-8 mx-1`} />
                default:
                  return <BoxIcon className={` h-8 w-8 mx-1`} />
              }
            } else if (item.type === 'tracking_number') {
              return <MarkerTrackingIcon className={` h-8 w-8 mx-1`} />
            }
          })()
        }
      </div>

      <div className="flex-grow flex flex-col space-y-1">
        <Body>
          <LocalizedTextLabel t={t} text={item.title}/>
        </Body>
        {
          (() => {
            if (item.value instanceof SalesOrderLineItemModel) {
              const optionDescription = item.value.optionValues
                .filter(o => o.value !== 'Default Title')
                .map(o => o.value)
                .join(', ')

              return optionDescription && <Paragraph className={`text-fg-gray-dark`}>
                {optionDescription}
              </Paragraph>
            }
          })()
        }
        {
          (() => {
            if (item.type !== 'cart_item') {
              return <Paragraph className={`${item.subtitle ? 'block' : 'hidden'} text-fg-gray-dark`}>
                <LocalizedTextLabel t={t} text={item.subtitle}/>
              </Paragraph>
            }
          })()
        }
      </div>
      {
        (() => {
          if (item.type === 'cart_item' && item.subtitle) {
            return <div className='flex-shrink-0 text-right'>
              <Body className={``}>
                <LocalizedTextLabel t={t} text={item.subtitle}/>
              </Body>
            </div>
          }
        })()
      }
    </Wrapper>
  </div>
}