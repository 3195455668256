import React, {Fragment, useEffect, useState} from "react";
import {Transition, Popover} from '@headlessui/react'
import {CloseIcon, SuccessColorIcon, WarningColorIcon} from "../icons";
import {Heading4, LocalizedTextLabel, Paragraph} from "../text";
import {NavBarNegativeInset} from "../navBars/NavBarMain";
import {LocalizationInfo} from "react-i18next";
import {useTranslation} from "next-i18next";

export type NotificationToastStatus = 'success' | 'failure'
export interface Notification {
  title?: string | LocalizationInfo
  message?: string | LocalizationInfo
  status?: NotificationToastStatus
}

export interface NotificationToastProps {
  title?: string | LocalizationInfo
  isVisible: boolean
  onClose?: () => void
  subtitle?: string | LocalizationInfo
  status?: NotificationToastStatus
}

export const NotificationToast: React.FC<NotificationToastProps> = (props) => {
  const { title, subtitle, status, isVisible, onClose } = props
  const [show, setShow] = useState(isVisible)
  const { t } = useTranslation()

  useEffect(() => {
    let timeout: number;
    if (show) {
      timeout = window.setTimeout(() => {
        setShow(false)

        window.clearTimeout(timeout)
      }, 8000)
    }

    return () => {
      window.clearTimeout(timeout)
    }
  }, [show])

  useEffect(() => {
    setShow(isVisible);
  }, [isVisible])

  return <div>
    <Popover>
      {({ open }) => {
        open = show

        return <>
          <Transition
            show={open}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              onClose && onClose()
            }}
          >
            <Popover.Panel className={`fixed flex flex-row sm:flex-col z-30 inset-0 px-4 py-6 pointer-events-none sm:p-6 items-end ${NavBarNegativeInset}`} static>
              <div className="max-w-sm w-full bg-white shadow-lg z-50 rounded-lg pointer-events-auto ring-1 ring-fg-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {
                        (() => {
                          switch (status) {
                            case "failure":
                              return <WarningColorIcon className="h-6 w-6" />
                            case "success":
                              return <SuccessColorIcon className="h-6 w-6" />
                          }
                        })()
                      }
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5 flex flex-col space-y-1">
                      <Heading4 className={`${title ? '' : 'hidden'}`}><LocalizedTextLabel text={title} t={t} /></Heading4>
                      <Paragraph className={`text-fg-gray-dark ${subtitle ? '' : 'hidden'}${title ? '' : 'hidden'}`}><LocalizedTextLabel text={subtitle} t={t} /></Paragraph>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-full px-3 py-3 -mr-3 -mt-3 inline-flex text-fg-black hover:bg-fg-gray-surface"
                        onClick={() => {
                          setShow(false)
                        }}
                      >
                        <CloseIcon className="h-3 w-3 text-fg-gray" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      }}
    </Popover>
  </div>
}

NotificationToast.defaultProps = {
  status: 'success'
}