import {PromoCodeJson, PromoCodeModel} from "./promoCode";

export interface AffiliateInternalCodesResponseJson {
  total_results?: number
  discount_codes?: PromoCodeJson[]
}

export class AffiliateInternalCodesResponseModel {
  totalResults: number = 0
  promoCodes: PromoCodeModel[] = []

  static fromJson(json?: AffiliateInternalCodesResponseJson): AffiliateInternalCodesResponseModel | undefined {
    if (!json) return

    const model = new AffiliateInternalCodesResponseModel()
    model.totalResults = json.total_results ?? 0
    model.promoCodes = json.discount_codes?.map(i => PromoCodeModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}