import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {useRouter} from "next/router";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Modal} from "../modals/Modal";
import {reset} from "../../redux/email/emailDetailSlice";
import {
  fetchEmails,
  reset as resetEmailList,
  selectCurrentPage,
  setCurrentPage
} from "../../redux/email/emailListSlice";
import {EmailDetailView} from "./EmailDetailView";

export const EmailDetailModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();
  const router = useRouter()
  const emailId = router.query.email_id as string | undefined
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector(selectCurrentPage)

  return <Modal
    className="md:max-w-screen-7/8"
    isOpen={isOpen('email_detail')}
    onClose={() => {
      dispatch(reset())
      onClose()

      dispatch(resetEmailList())
      if (pageNumber === 1) {
        dispatch(fetchEmails(pageNumber))
      } else {
        dispatch(setCurrentPage(1))
      }
    }}
  >
    <EmailDetailView onClose={onClose} emailId={emailId} />
  </Modal>
}