import {DietaryCategory} from "./dietaryCategory";
import {ImageJson, ImageModel} from "./image";
import {MealItemJson, MealItemModel} from "./mealItem";
import {MealType} from "./mealType";
import {RecipeJson, RecipeModel} from "./recipe";
import {FoodSubcategory} from "./foodSubcategory";
import {ObjectId} from "bson";
import {NutrientType} from "./nutrient";
import {EntityModel} from "./entityModel";
import {map, mergeMap, Observable, of, zip} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectSchema} from "mongodb-realm";

export type MealSourceType =
  'created' |
  'delivered' |
  'generated' |
  'search'

export interface MealJson {
  _id?: string
  calcium_total?: number
  calorie_total?: number
  carbohydrate_total?: number
  cholesterol_total?: number
  created_at?: Date
  description?: string
  dietary_categories?: DietaryCategory[]
  fat_total?: number
  fiber_total?: number
  image?: ImageJson
  image_file_name?: string
  image_url?: string
  iron_total?: number
  is_favorited?: boolean
  libraries?: string[]
  logged_at?: Date
  meal_items?: MealItemJson[]
  meal_name?: string
  meal_type?: MealType
  meal_types?: MealType[]
  monounsaturated_fat_total?: number
  number_of_servings?: number
  parent_meal_id?: string
  polyunsaturated_fat_total?: number
  potassium_total?: number
  protein_total?: number
  recipe?: RecipeJson
  saturated_fat_total?: number
  sodium_total?: number
  source_type?: MealSourceType
  subcategories?: FoodSubcategory[]
  sugar_total?: number
  tags?: string[]
  trans_fat_total?: number
  updated_at?: Date
  vitamin_a_total?: number
  vitamin_c_total?: number
}

export class MealModel implements EntityModel<MealModel> {
  public static schema: ObjectSchema = {
    name: 'MealEntity',
    properties: {
      _id: 'objectId',
      _partition: 'string',
      calciumTotal: 'double?',
      calorieTotal: 'double?',
      carbohydrateTotal: 'double?',
      cholesterolTotal: 'double?',
      createdAt: 'date?',
      dietaryCategories: 'string[]',
      fatTotal: 'double?',
      fiberTotal: 'double?',
      image: 'ImageEntity',
      ironTotal: 'double?',
      isFavorited: 'bool',
      libraries: 'string[]',
      loggedAt: 'date?',
      mealDescription: 'string?',
      mealId: 'string?',
      mealItems: 'MealItemEntity[]',
      mealName: 'string?',
      mealType: 'string?',
      mealTypes: 'string[]',
      monounsaturatedFatTotal: 'double?',
      numberOfServings: 'double',
      parentMealId: 'string?',
      polyunsaturatedFatTotal: 'double?',
      potassiumTotal: 'double?',
      proteinTotal: 'double?',
      recipe: 'RecipeEntity',
      saturatedFatTotal: 'double?',
      sodiumTotal: 'double?',
      sourceType: 'string?',
      subcategories: 'string[]',
      sugarTotal: 'double?',
      tags: 'string[]',
      transFatTotal: 'double?',
      updatedAt: 'date?',
      vitaminATotal: 'double?',
      vitaminCTotal: 'double?',
    },
    primaryKey: '_id',
  }

  public schema = MealModel.schema

  _id: ObjectId = new ObjectId()
  _partition: string = ''
  calciumTotal?: number
  calorieTotal?: number
  carbohydrateTotal?: number
  cholesterolTotal?: number
  createdAt?: Date
  dietaryCategories: DietaryCategory[] = []
  fatTotal?: number
  fiberTotal?: number
  image?: ImageModel
  ironTotal?: number
  isFavorited: boolean = false
  libraries: string[] = []
  loggedAt?: Date
  mealDescription?: string
  mealId?: string
  mealName?: string
  mealType?: string
  mealTypes: MealType[] = []
  monounsaturatedFatTotal?: number
  numberOfServings: number = 0
  parentMealId?: string
  polyunsaturatedFatTotal?: number
  potassiumTotal?: number
  proteinTotal?: number
  recipe?: RecipeModel
  saturatedFatTotal?: number
  sodiumTotal?: number
  sourceType?: string
  subcategories: FoodSubcategory[] = []
  sugarTotal?: number
  tags: string[] = []
  transFatTotal?: number
  updatedAt?: Date
  vitaminATotal?: number
  vitaminCTotal?: number

  private _mealItems: MealItemModel[] = []
  get mealItems(): MealItemModel[] {
    return this._mealItems;
  }

  set mealItems(value: MealItemModel[]) {
    this._mealItems = value;

    this.calciumTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('CALCIUM') ?? 0)
    }, 0)
    this.calorieTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('CALORIES') ?? 0)
    }, 0)
    this.carbohydrateTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('CARBOHYDRATE') ?? 0)
    }, 0)
    this.cholesterolTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('CHOLESTEROL') ?? 0)
    }, 0)
    this.fatTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('FAT') ?? 0)
    }, 0)
    this.fiberTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('FIBER') ?? 0)
    }, 0)
    this.ironTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('IRON') ?? 0)
    }, 0)
    this.monounsaturatedFatTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('MONOUNSATURATED_FAT') ?? 0)
    }, 0)
    this.polyunsaturatedFatTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('POLYUNSATURATED_FAT') ?? 0)
    }, 0)
    this.potassiumTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('POTASSIUM') ?? 0)
    }, 0)
    this.proteinTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('PROTEIN') ?? 0)
    }, 0)
    this.saturatedFatTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('SATURATED_FAT') ?? 0)
    }, 0)
    this.sodiumTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('SODIUM') ?? 0)
    }, 0)
    this.sugarTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('SUGAR') ?? 0)
    }, 0)
    this.transFatTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('TRANS_FAT') ?? 0)
    }, 0)
    this.vitaminATotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('VITAMIN_A') ?? 0)
    }, 0)
    this.vitaminCTotal = this._mealItems.reduce((sum, item) => {
      return sum + (item.getNutrients().get('VITAMIN_C') ?? 0)
    }, 0)
  }

  constructor(entity?: MealModel | null) {
    if (!entity) return

    this._id = entity._id
    this._partition = entity._partition
    this.calciumTotal = entity.calciumTotal
    this.calorieTotal = entity.calorieTotal
    this.carbohydrateTotal = entity.carbohydrateTotal
    this.cholesterolTotal = entity.cholesterolTotal
    this.createdAt = entity.createdAt
    this.dietaryCategories = entity.dietaryCategories
    this.fatTotal = entity.fatTotal
    this.fiberTotal = entity.fiberTotal
    this.image = new ImageModel(entity.image)
    this.ironTotal = entity.ironTotal
    this.isFavorited = entity.isFavorited
    this.libraries = entity.libraries
    this.loggedAt = entity.loggedAt
    this.mealItems = entity.mealItems.map(i => new MealItemModel(i))
    this.mealDescription = entity.mealDescription
    this.mealId = entity.mealId
    this.mealName = entity.mealName
    this.mealType = entity.mealType
    this.mealTypes = entity.mealTypes
    this.monounsaturatedFatTotal = entity.monounsaturatedFatTotal
    this.numberOfServings = entity.numberOfServings
    this.parentMealId = entity.parentMealId
    this.polyunsaturatedFatTotal = entity.polyunsaturatedFatTotal
    this.potassiumTotal = entity.potassiumTotal
    this.proteinTotal = entity.proteinTotal
    this.recipe = new RecipeModel(entity.recipe)
    this.saturatedFatTotal = entity.saturatedFatTotal
    this.sodiumTotal = entity.sodiumTotal
    this.sourceType = entity.sourceType
    this.subcategories = entity.subcategories
    this.sugarTotal = entity.sugarTotal
    this.tags = entity.tags
    this.transFatTotal = entity.transFatTotal
    this.updatedAt = entity.updatedAt
    this.vitaminATotal = entity.vitaminATotal
    this.vitaminCTotal = entity.vitaminCTotal

    return this
  }

  static fromJson(json?: MealJson): MealModel | undefined {
    if (!json) return

    const model = new MealModel()

    model.calciumTotal = json.calcium_total
    model.calorieTotal = json.calorie_total
    model.carbohydrateTotal = json.carbohydrate_total
    model.cholesterolTotal = json.cholesterol_total
    model.createdAt = json.created_at
    model.dietaryCategories = json.dietary_categories ?? []
    model.fatTotal = json.fat_total
    model.fiberTotal = json.fiber_total
    model.image = ImageModel.fromJson(json.image)
    model.ironTotal = json.iron_total
    model.isFavorited = json.is_favorited ?? false
    model.libraries = json.libraries ?? []
    model.loggedAt = json.logged_at
    model.mealDescription = json.description?.replace(/<[^>]+>/g, '')?.replace('&amp;', '&')
    model.mealId = json._id
    model.mealItems = json.meal_items
      ?.map((i) => MealItemModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.mealName = json.meal_name
    model.mealType = json.meal_type
    model.mealTypes = json.meal_types ?? []
    model.monounsaturatedFatTotal = json.monounsaturated_fat_total
    model.numberOfServings = json.number_of_servings ?? 0
    model.parentMealId = json.parent_meal_id
    model.polyunsaturatedFatTotal = json.polyunsaturated_fat_total
    model.potassiumTotal = json.potassium_total
    model.proteinTotal = json.protein_total
    model.recipe = RecipeModel.fromJson(json.recipe)
    model.saturatedFatTotal = json.saturated_fat_total
    model.sodiumTotal = json.sodium_total
    model.sourceType = json.source_type
    model.subcategories = json.subcategories ?? []
    model.sugarTotal = json.sugar_total
    model.tags = json.tags ?? []
    model.transFatTotal = json.trans_fat_total
    model.updatedAt = json.updated_at
    model.vitaminATotal = json.vitamin_a_total
    model.vitaminCTotal = json.vitamin_c_total

    return model
  }

  toEntityObservable(realmService: BaseRealmService): Observable<MealModel> {
    const imageObservable: Observable<ImageModel | undefined> = this.image?.toEntityObservable(realmService)
      ?? of<ImageModel | undefined>(undefined)
    const recipeObservable: Observable<RecipeModel | undefined> = this.recipe?.toEntityObservable(realmService)
      ?? of<RecipeModel | undefined>(undefined)
    const mealItemsObservable: Observable<MealItemModel[]> = this.mealItems.length === 0 ? of([]) : zip(this.mealItems.map(i => i.toEntityObservable(realmService)))

    return zip(mealItemsObservable, imageObservable, recipeObservable)
      .pipe(
        mergeMap(([mealItems, image, recipe]) => {
          this.mealItems = mealItems
          this.image = image
          this.recipe = recipe

          return realmService.query<MealModel>(MealModel.schema.name)
            .where({ mealId: this.mealId })
            .fetchOne()
            .pipe(
              map((existingEntity) => {
                if (existingEntity) {
                  this._id = existingEntity._id
                }

                return this
              })
            )
        })
      )
  }

  getNutrients(): Map<NutrientType, number> {
    const map = new Map<NutrientType, number>()

    this.calciumTotal && map.set("CALCIUM", this.calciumTotal)
    this.calorieTotal && map.set("CALORIES", this.calorieTotal)
    this.carbohydrateTotal && map.set("CARBOHYDRATE", this.carbohydrateTotal)
    this.cholesterolTotal && map.set("CHOLESTEROL", this.cholesterolTotal)
    this.fatTotal && map.set("FAT", this.fatTotal)
    this.fiberTotal && map.set("FIBER", this.fiberTotal)
    this.ironTotal && map.set("IRON", this.ironTotal)
    this.monounsaturatedFatTotal && map.set("MONOUNSATURATED_FAT", this.monounsaturatedFatTotal)
    this.polyunsaturatedFatTotal && map.set("POLYUNSATURATED_FAT", this.polyunsaturatedFatTotal)
    this.potassiumTotal && map.set("POTASSIUM", this.potassiumTotal)
    this.proteinTotal && map.set("PROTEIN", this.proteinTotal)
    this.saturatedFatTotal && map.set("SATURATED_FAT", this.saturatedFatTotal)
    this.sodiumTotal && map.set("SODIUM", this.sodiumTotal)
    this.sugarTotal && map.set("SUGAR", this.sugarTotal)
    this.transFatTotal && map.set("TRANS_FAT", this.transFatTotal)
    this.vitaminATotal && map.set("VITAMIN_A", this.vitaminATotal)
    this.vitaminCTotal && map.set("VITAMIN_C", this.vitaminCTotal)

    return map
  }
}