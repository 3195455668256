export interface CohortJson {
  id?: number
  name?: string
  description?: string
}

export class CohortModel {
  id?: number
  name?: string
  description?: string

  constructor(model?: CohortModel) {
    if (!model) return

    this.id = model.id
    this.name = model.name
    this.description = model.description

    return this
  }

  static fromJson(json?: CohortJson): CohortModel | undefined {
    if (!json) return

    const model = new CohortModel()

    model.id = json.id
    model.name = json.name
    model.description = json.description

    return model
  }
}