export type InAppSubscriptionStatus = 'active' | 'inactive' | 'canceled' | 'paused' | 'expired'
export type InAppSubscriptionPlatform = 'stripe' | 'ios' | 'mac_os' | 'android' | 'amazon' | 'unknown'

export interface InAppSubscriptionJson {
  status?: InAppSubscriptionStatus
  platform?: InAppSubscriptionPlatform
}

export class InAppSubscriptionModel {
  status?: InAppSubscriptionStatus
  platform?: InAppSubscriptionPlatform

  constructor(model?: InAppSubscriptionModel | null) {
    if (!model) return

    this.status = model.status
    this.platform = model.platform

    return this
  }

  static fromJson(json?: InAppSubscriptionJson): InAppSubscriptionModel | undefined {
    if (!json) return

    const model = new InAppSubscriptionModel()
    model.status = json.status
    model.platform = json.platform

    return model
  }
}