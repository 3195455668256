import {PromptModel} from "../../models/prompt";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {PromptsResponseModel} from "../../models/promtsResponse";
import {RootState} from "../../app/store";

interface PromptListState {
  readonly isLoading: boolean
  readonly prompts: PromptModel[]
  readonly totalPrompts: number
  readonly currentPage: number
  readonly error?: BaseError<any>
}

export const promptListSlice = createSlice({
  name: 'prompt_list',
  initialState: {
    isLoading: false,
    prompts: [],
    totalPrompts: 0,
    currentPage: 1,
  } as PromptListState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.prompts = []
      state.error = undefined
      state.currentPage = 1
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    fetchPrompts(state, action: PayloadAction<number>) {
      if (!state.prompts.length) {
        state.isLoading = true
      }
    },
    fetchPromptsSucceeded(state, action: PayloadAction<PromptsResponseModel>) {
      if (state.isLoading) {
        state.prompts = action.payload.prompts
      } else {
        state.prompts = state.prompts.concat(action.payload.prompts)
      }

      state.totalPrompts = action.payload.totalResults
      state.isLoading = false
    },
    fetchPromptsFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PROMPT_ERROR')
        error.localizedMessage = 'There was a problem getting prompts'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
  }
})

export const promptListReducer = promptListSlice.reducer

export const {
  reset,
  fetchPrompts,
  fetchPromptsFailed,
  fetchPromptsSucceeded,
  setCurrentPage
} = promptListSlice.actions

export const selectError = (state: RootState) => state.promptList.error
export const selectPrompts = (state: RootState) => state.promptList.prompts
export const selectIsLoading= (state: RootState) => state.promptList.isLoading
export const selectTotalPrompts = (state: RootState) => state.promptList.totalPrompts
export const selectCurrentPage = (state: RootState) => state.promptList.currentPage