import React from "react";
import {Modal} from "../modals/Modal";
import {CampaignDetailMessageSelectorView, CampaignMessageType} from "./CampaignDetailMessageSelectorView";
import {Container} from "../containers/Container";
import {CloseIcon} from "../icons";
import {HeroHeading2} from "../text";

export interface CampaignDetailMessageSelectorModalProps {
  isOpen: boolean
  type: 'email' | 'prompt' | 'push'
  onClose: (message?: CampaignMessageType) => any
}

export const CampaignDetailMessageSelectorModal: React.FC<CampaignDetailMessageSelectorModalProps> = ({ isOpen, type, onClose }) => {
  return <Modal
    className="md:max-w-screen-7/8"
    isOpen={isOpen}
    onClose={() =>{
      onClose()
    }}
  >
    <div
      className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen md:min-h-screen-7/8 sticky"
    >
      <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
        <div className="w-full no-scrollbar flex-1 flex flex-col">
          <div className="-ml-3 -mt-3 flex items-center justify-between">
            <button
              type="button"
              className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
              onClick={() => {
                onClose()
              }}
            >
              <CloseIcon className="h-4 h-4" />
            </button>
          </div>

          <div className={`mt-4 mb-6 lg:mb-8`}>
            <HeroHeading2>{ type === 'email' ? 'Select Email' : 'Select Prompt' }</HeroHeading2>
          </div>

          <CampaignDetailMessageSelectorView type={type} onMessageSelected={onClose} />
        </div>
      </Container>
    </div>
  </Modal>
}