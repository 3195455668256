import React, {MutableRefObject, useState} from "react";
import {CardNumberElement} from "@stripe/react-stripe-js";
import {CardBrand} from "../../models/card";
import {
  AmexColorIcon,
  DinersClubColorIcon,
  DiscoverColorIcon,
  JcbColorIcon,
  MasterCardColorIcon,
  UnionPayColorIcon,
  VisaColorIcon
} from "../icons";
import {useStripeFormatter} from "../../utils/useStripeFormatter";
import stripeJs from "@stripe/stripe-js";

export interface PaymentCardNumberInputViewProps {
  onChange: (event: stripeJs.StripeCardNumberElementChangeEvent) => any
}

export const PaymentCardNumberInputView: React.FC<PaymentCardNumberInputViewProps> = ({ onChange }) => {
  const [cardBrand, setCardBrand] = useState<CardBrand>('unknown')

  const { baseClass, baseStyle, onFocus, onError, onBlur } = useStripeFormatter('cardNumber')

  return <div className="relative">
    <CardNumberElement
      onFocus={() => {
        onFocus()
      }}
      onChange={e => {
        onError(e.error)
        setCardBrand(e.brand)
        onChange(e)
      }}
      onBlur={() => {
        onBlur()
      }}
      options={{
        classes: {
          base: baseClass
        },
        style: {
          base: baseStyle
        }
      }}
    />
    <div className="absolute flex flex-row space-x-1 right-3 lg:right-4 top-1/2 transform -translate-y-1/2">
      <VisaColorIcon className={`h-8 w-8 ${cardBrand === 'visa' || cardBrand === 'unknown' ? '' : 'hidden'}`} />
      <MasterCardColorIcon className={`h-8 w-8 ${cardBrand === 'mastercard' || cardBrand === 'unknown' ? '' : 'hidden'}`} />
      <AmexColorIcon className={`h-8 w-8 ${cardBrand === 'amex' || cardBrand === 'unknown' ? '' : 'hidden'}`} />
      <DiscoverColorIcon className={`h-8 w-8 ${cardBrand === 'discover' || cardBrand === 'unknown' ? '' : 'hidden'}`} />
      <DinersClubColorIcon className={`h-8 w-8 ${cardBrand === 'diners' ? '' : 'hidden'}`} />
      <JcbColorIcon className={`h-8 w-8 ${cardBrand === 'jcb' ? '' : 'hidden'}`} />
      <UnionPayColorIcon className={`h-8 w-8 ${cardBrand === 'union' ? '' : 'hidden'}`} />
    </div>
  </div>
}