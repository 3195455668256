import {MealType} from "./mealType";
import {DietaryCategory} from "./dietaryCategory";
import {ImageJson, ImageModel} from "./image";

export type ProductCategoryType = 'dietary' | 'meal'

export interface ProductCategoryJson {
  _id?: string
  name?: string
  meal_type?: MealType
  dietary_category?: DietaryCategory
  type?: ProductCategoryType
  image?: ImageJson
  parent_category_id?: string
  sort_number?: number
  store_id?: string
  vendor_id?: string
  vendor_name?: string
}

export class ProductCategoryModel {
  dietaryCategory?: DietaryCategory
  id?: string
  image?: ImageModel
  mealType?: MealType
  name?: string
  sortNumber?: number
  storeId?: string
  type?: ProductCategoryType
  vendorId?: string
  vendorName?: string

  constructor(model?: ProductCategoryModel | null) {
    if (!model) return

    this.dietaryCategory = model.dietaryCategory
    this.id = model.id
    this.image = new ImageModel(model.image)
    this.mealType = model.mealType
    this.name = model.name
    this.sortNumber = model.sortNumber
    this.storeId = model.storeId
    this.type = model.type
    this.vendorId = model.vendorId
    this.vendorName = model.vendorName
  }

  static fromJson(json?: ProductCategoryJson): ProductCategoryModel | undefined {
    if (!json) return

    return {
      dietaryCategory: json.dietary_category,
      id: json._id,
      image: ImageModel.fromJson(json.image),
      mealType: json.meal_type,
      name: json.name,
      sortNumber: json.sort_number,
      storeId: json.store_id,
      type: json.type,
      vendorId: json.vendor_id,
      vendorName: json.vendor_name
    }
  }
}