import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled} from "rxjs";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";
import {
  deleteEmail,
  deleteEmailFailed,
  deleteEmailSucceeded,
  fetchEmails,
  fetchEmailsFailed,
  fetchEmailsSucceeded,
  reset, setCurrentPage
} from "./emailListSlice";

export const fetchEmailsEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchEmails.match),
    mergeMap(action => {
      return MessageService.fetchEmails(action.payload)
        .pipe(
          map(fetchEmailsSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchEmailsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deleteEmailFromListEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(deleteEmail.match),
    mergeMap(action => {
      return MessageService.deleteEmail(action.payload)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.emailList.currentPage

            return scheduled([
              deleteEmailSucceeded(action.payload),
              reset(),
              pageNumber === 1 ? fetchEmails(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: deleteEmailFailed.type,
            payload: { error, id: action.payload },
            error: true
          }))
        )
    })
  )
}