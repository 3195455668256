import React from "react";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useModalRouting} from "../../utils/useModalRouting";
import {useRouter} from "next/router";
import {
  AppStoreIcon,
  FacebookFilledIcon,
  FitGenieLogoInvertedIcon, FitGenieStarIcon,
  GooglePlayIcon,
  InstagramIcon,
  TwitterIcon
} from "../icons";
import {Body, Heading3, Heading4, Paragraph} from "../text";
import Link from 'next/link'
import {selectUser} from "../../redux/user/userSlice";
import moment from "moment";

export const Footer: React.FC<any> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const { createRoute } = useModalRouting()
  const router = useRouter()

  const isAndroid = navigator.userAgent.indexOf( "Android" ) !== -1
  const isIphone = navigator.userAgent.indexOf( "iPhone" ) !== -1

  const user = useAppSelector(selectUser)
  const userInfo = user ? `----------------------------%0D%0AEmail: ${user.email}%0D%0AUser ID: ${user.userId}` : ''

  return <footer className="bg-fg-black">
    <div className="max-w-full mx-auto px-6 sm:px-8 lg:px-10 py-6 sm:py-8 lg:py-10">
      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-2 gap-6 justify-items-start">
        <div className="flex flex-col justify-items-start items-start col-span-1 space-y-8 mb-6 md:mb-0">
          <Link href="/store-feed" passHref>
            <a className="mb-6">
              <FitGenieLogoInvertedIcon className="h-7 w-auto" />
            </a>
          </Link>

          <a target="_blank" className={isAndroid ? 'hidden' : ''} rel="noreferrer" href={isAndroid || isIphone ? process.env.NEXT_PUBLIC_APP_DOWNLOAD_URL! : process.env.NEXT_PUBLIC_FITGENIE_APP_STORE_URL}>
            <AppStoreIcon className="h-8 w-auto text-white" />
          </a>

          <a target="_blank" className={isIphone ? 'hidden' : ''} rel="noreferrer" href={isAndroid || isIphone ? process.env.NEXT_PUBLIC_APP_DOWNLOAD_URL! : process.env.NEXT_PUBLIC_FITGENIE_PLAY_STORE_URL}>
            <GooglePlayIcon className="h-8 w-auto text-white" />
          </a>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12 col-span-2 xl:col-span-1">
          <div className="flex flex-col space-y-3">
            <Heading4 className="text-white">{t('common:footer_title_get_to_know_us')}</Heading4>
            <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_FITGENIE_BLOG_URL}><Paragraph className="text-fg-gray-light">{t('common:footer_title_blog')}</Paragraph></a>
            <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_FITGENIE_LINKEDIN_URL}><Paragraph className="text-fg-gray-light">{t('common:footer_title_linkedin')}</Paragraph></a>
          </div>

          <div className="flex flex-col space-y-3">
            <Heading4 className="text-white">{t('common:footer_title_let_us_help_you')}</Heading4>
            <Link href={createRoute({ name: 'contact_info_entry' })} shallow={true} passHref>
              <a>
                <Paragraph className="text-fg-gray-light">{t('common:footer_title_account_details')}</Paragraph>
              </a>
            </Link>
            <Link href={'/orders'} passHref>
              <a>
                <Paragraph className="text-fg-gray-light">{t('common:footer_title_order_history')}</Paragraph>
              </a>
            </Link>
            <a target="_blank" rel="noreferrer" href={`mailto:${process.env.NEXT_PUBLIC_FITGENIE_SUPPORT_EMAIL}?subject=[SUPPORT] FitGenie - Support&body=Hi FitGenie Team,%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A${userInfo}`}>
              <Paragraph className="text-fg-gray-light">{t('common:footer_title_help')}</Paragraph>
            </a>
          </div>

          <div className="flex flex-col space-y-3">
            <Heading4 className="text-white">{t('common:footer_title_doing_business')}</Heading4>
            <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_VENDOR_SIGNUP_URL}><Paragraph className="text-fg-gray-light">{t('common:footer_title_become_a_vendor')}</Paragraph></a>
            <a target="_blank" rel="noreferrer" href={`mailto:${process.env.NEXT_PUBLIC_FITGENIE_HELLO_EMAIL}?subject=[AFFILIATE PROGRAM] FitGenie - Affiliate&body=Hi FitGenie Team, I would like to become an affiliate!%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A${userInfo}`}><Paragraph className="text-fg-gray-light">{t('common:footer_title_become_an_affiliate')}</Paragraph></a>
          </div>
        </div>
      </div>

      <div className="w-full border-b-fg-footer-border border-b-2 my-12" />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
        <div className="flex flex-col md:flex-row md:items-center space-y-6 md:space-y-0 md:space-x-10">
          <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_FITGENIE_FACEBOOK_URL}>
            <FacebookFilledIcon className="text-white h-6 w-6" />
          </a>

          <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_FITGENIE_INSTAGRAM_URL}>
            <InstagramIcon className="text-white h-6 w-6" />
          </a>

          <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_FITGENIE_TWITTER_URL}>
            <TwitterIcon className="text-white h-6 w-6" />
          </a>
        </div>

        <div className="flex flex-col md:flex-row md:items-center md:justify-self-end space-y-6 md:space-y-0 md:space-x-10">
          <Paragraph className="text-white">{`Copyright © ${moment().format('YYYY')} by FitGenie, LLC`}</Paragraph>

          <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_TOS_URL}><Heading4 className="text-white">{t('common:footer_title_terms_of_use')}</Heading4></a>
          <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_TOS_URL}><Heading4 className="text-white">{t('common:footer_title_privacy_policy')}</Heading4></a>
        </div>
      </div>
    </div>
  </footer>
}