import {from, Observable} from "rxjs";
import {PromptsResponseModel} from "../models/promtsResponse";
import {api, handleApiError} from "./api";
import {PromptJson, PromptModel} from "../models/prompt";
import {CohortJson, CohortModel} from "../models/cohort";
import {EmailsResponseModel} from "../models/emailsResponse";
import {EmailJson, EmailModel} from "../models/email";
import {MessageCampaignsResponseModel} from "../models/messageCampaignsResponse";
import {MessageCampaignJson, MessageCampaignModel} from "../models/messageCampaign";
import {deepCopy} from "../utils/deepCopy";
import {PushNotificationJson, PushNotificationModel} from "../models/pushNotification";
import {PushNotificationsResponseModel} from "../models/pushNotificationsResponse";

export const MessageApi = {
  getCohorts(): Observable<CohortModel[]> {
    const request: Promise<CohortModel[]> = api.get(`/message/get-cohorts`, {
      headers: { is_authenticated: true }
    })
      .then(response => {
        return (response.data as CohortJson[]).map((i) => CohortModel.fromJson(i)!)
      })
      .catch(handleApiError)

    return from(request)
  },
  getCampaigns(pageNumber: number): Observable<MessageCampaignsResponseModel> {
    const request: Promise<MessageCampaignsResponseModel> = api.get(`/message/get-campaigns`, {
      headers: { is_authenticated: true },
      params: { page_number: pageNumber, max_results: 30 }
    })
      .then(response => MessageCampaignsResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getCampaign(id: string): Observable<MessageCampaignModel> {
    const request: Promise<MessageCampaignModel> = api.get(`/message/get-campaign/${id}`, {
      headers: { is_authenticated: true },
    })
      .then(response => MessageCampaignModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  createCampaign(campaign: MessageCampaignJson): Observable<MessageCampaignModel> {
    const json: any = deepCopy(campaign);
    if (json.nodes) {
      json.nodes = json.nodes.map((n: any) => {
        return {
          ...n,
          email: n.email && n.email._id,
          prompt: n.prompt && n.prompt._id,
          push: n.push && n.push._id
        }
      })
    }

    const request: Promise<MessageCampaignModel> = api.post(`/message/create-campaign`, { campaign: json },{
      headers: { is_authenticated: true },
    })
      .then(response => MessageCampaignModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  updateCampaign(campaign: MessageCampaignJson, id: string): Observable<MessageCampaignModel> {
    const json: any = deepCopy(campaign);
    if (json.nodes) {
      json.nodes = json.nodes.map((n: any) => {
        return {
          ...n,
          email: n.email && n.email._id,
          prompt: n.prompt && n.prompt._id,
          push: n.push && n.push._id
        }
      })
    }

    const request: Promise<MessageCampaignModel> = api.put(`/message/update-campaign`, { campaign: json, id },{
      headers: { is_authenticated: true },
    })
      .then(response => MessageCampaignModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  activateCampaign(id: string): Observable<void> {
    const request: Promise<void> = api.post(`/message/activate-campaign/${id}`, { },{
      headers: { is_authenticated: true },
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  deleteCampaign(id: string): Observable<void> {
    const request: Promise<void> = api.delete(`/message/delete-campaign/${id}`,{
      headers: { is_authenticated: true },
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  getEmails(pageNumber: number): Observable<EmailsResponseModel> {
    const request: Promise<EmailsResponseModel> = api.get(`/message/get-emails`, {
      headers: { is_authenticated: true },
      params: { page_number: pageNumber, max_results: 30 }
    })
      .then(response => EmailsResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getEmail(id: string): Observable<EmailModel> {
    const request: Promise<EmailModel> = api.get(`/message/get-email/${id}`, {
      headers: { is_authenticated: true },
    })
      .then(response => EmailModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  createEmail(email: EmailJson): Observable<EmailModel> {
    const request: Promise<EmailModel> = api.post(`/message/create-email`, { email },{
      headers: { is_authenticated: true },
    })
      .then(response => EmailModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  updateEmail(email: EmailJson, id: string): Observable<EmailModel> {
    const request: Promise<EmailModel> = api.put(`/message/update-email`, { email, id },{
      headers: { is_authenticated: true },
    })
      .then(response => EmailModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  sendEmail(to?: string[], testEmail?: EmailJson, emailId?: string, cohortId?: string): Observable<void> {
    const request: Promise<void> = api.post(`/message/send-email`, { to, test_email: testEmail, id: emailId, cohort_id: cohortId },{
      headers: { is_authenticated: true },
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  deleteEmail(id: string): Observable<void> {
    const request: Promise<void> = api.delete(`/message/delete-email/${id}`,{
      headers: { is_authenticated: true },
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  getPushNotifications(pageNumber: number): Observable<PushNotificationsResponseModel> {
    const request: Promise<PushNotificationsResponseModel> = api.get(`/message/get-push-notifications`, {
      headers: { is_authenticated: true },
      params: { page_number: pageNumber, max_results: 30 }
    })
      .then(response => PushNotificationsResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getPushNotification(id: string): Observable<PushNotificationModel> {
    const request: Promise<PushNotificationModel> = api.get(`/message/get-push-notification/${id}`, {
      headers: { is_authenticated: true },
    })
      .then(response => PushNotificationModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  createPushNotification(push: PushNotificationJson): Observable<PushNotificationModel> {
    const request: Promise<PushNotificationModel> = api.post(`/message/create-push-notification`, { push },{
      headers: { is_authenticated: true },
    })
      .then(response => PushNotificationModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  updatePushNotification(push: PushNotificationJson, id: string): Observable<PushNotificationModel> {
    const request: Promise<PushNotificationModel> = api.put(`/message/update-push-notification`, { push, id },{
      headers: { is_authenticated: true },
    })
      .then(response => PushNotificationModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  sendPushNotification(to?: string[], testPush?: PushNotificationJson, pushId?: string, cohortId?: string): Observable<void> {
    const request: Promise<void> = api.post(`/message/send-push-notification`, { to, test_push: testPush, id: pushId, cohort_id: cohortId },{
      headers: { is_authenticated: true },
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  deletePushNotification(id: string): Observable<void> {
    const request: Promise<void> = api.delete(`/message/delete-push-notification/${id}`,{
      headers: { is_authenticated: true },
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  getPrompts(pageNumber: number): Observable<PromptsResponseModel> {
    const request: Promise<PromptsResponseModel> = api.get(`/message/get-prompts`, {
      headers: { is_authenticated: true },
      params: { page_number: pageNumber, max_results: 30 }
    })
      .then(response => PromptsResponseModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getPrompt(id: string): Observable<PromptModel> {
    const request: Promise<PromptModel> = api.get(`/message/get-prompt/${id}`, {
      headers: { is_authenticated: true },
    })
      .then(response => PromptModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  createPrompt(prompt: PromptJson, cohortId?: string): Observable<PromptModel> {
    const request: Promise<PromptModel> = api.post(`/message/create-prompt`, { prompt, cohort_id: cohortId },{
      headers: { is_authenticated: true },
    })
      .then(response => PromptModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  updatePrompt(prompt: PromptJson, id: string, cohortId?: string): Observable<PromptModel> {
    const request: Promise<PromptModel> = api.put(`/message/update-prompt`, { prompt, id, cohort_id: cohortId },{
      headers: { is_authenticated: true },
    })
      .then(response => PromptModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  sendPrompt(emails: string[], prompt?: PromptJson, promptId?: string): Observable<void> {
    const request: Promise<void> = api.post(`/message/send-prompt`, { to: emails, test_prompt: prompt, id: promptId },{
      headers: { is_authenticated: true },
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  deletePrompt(id: string): Observable<void> {
    const request: Promise<void> = api.delete(`/message/delete-prompt/${id}`,{
      headers: { is_authenticated: true },
    })
      .then(() => {})
      .catch(handleApiError)

    return from(request)
  },
  setTestEmail(email?: string) {
    if (!email) {
      localStorage.removeItem('test_message_email')
    } else {
      localStorage.setItem('test_message_email', email)
    }
  },
  getTestEmail(): string | undefined | null {
    return localStorage.getItem('test_message_email')
  },
}