import {CardBrand} from "../../models/card";
import React from "react";
import {CardCvcElement} from "@stripe/react-stripe-js";
import {useStripeFormatter} from "../../utils/useStripeFormatter";
import {CvcAmexIcon, CvcIcon} from "../icons";

export interface PaymentCvcInputViewProps {
  cardBrand: CardBrand
}

export const PaymentCvcInputView: React.FC<PaymentCvcInputViewProps> = ({ cardBrand }) => {
  const { baseClass, baseStyle, onFocus, onError, onBlur } = useStripeFormatter('cardCvc')

  return <div className="relative">
    <CardCvcElement
      onFocus={() => {
        onFocus()
      }}
      onChange={e => {
        onError(e.error)
      }}
      onBlur={() => {
        onBlur()
      }}
      options={{
        classes: {
          base: baseClass
        },
        style: {
          base: baseStyle
        }
      }}
    />
    <div className="absolute flex flex-row space-x-1 text-fg-gray-dark right-3 lg:right-4 top-1/2 transform -translate-y-1/2">
      <CvcAmexIcon className={`h-8 w-8 ${cardBrand === 'amex' ? '' : 'hidden'}`} />
      <CvcIcon className={`h-8 w-8 ${cardBrand === 'amex' ? 'hidden' : ''}`} />
    </div>
  </div>
}
