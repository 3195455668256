import {BaseError} from "../../errors/baseError";
import {EmailModel} from "../../models/email";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {EmailsResponseModel} from "../../models/emailsResponse";
import {RootState} from "../../app/store";

interface EmailListState {
  readonly isLoading: boolean
  readonly isLoadingMore: boolean
  readonly emails: EmailModel[]
  readonly totalEmails: number
  readonly currentPage: number
  readonly isUpdatingEmailMap: { [key: string]: boolean }
  readonly error?: BaseError<any>
}

export const emailListSlice = createSlice({
  name: 'email_list',
  initialState: {
    isLoading: false,
    isLoadingMore: false,
    emails: [],
    totalEmails: 0,
    currentPage: 1,
    isUpdatingEmailMap: {},
  } as EmailListState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.isLoadingMore = false
      state.emails = []
      state.error = undefined
      state.isUpdatingEmailMap = {}
      state.currentPage = 1
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    fetchEmails(state, action: PayloadAction<number>) {
      state.isLoadingMore = true
      if (!state.emails.length) {
        state.isLoading = true
      }
    },
    deleteEmail(state, action: PayloadAction<string>) {
      state.isUpdatingEmailMap[action.payload] = true
    },
    deleteEmailSucceeded(state, action: PayloadAction<string>) {
      state.isUpdatingEmailMap[action.payload] = false
    },
    deleteEmailFailed(state, action: PayloadAction<{ error: Error, id: string }>) {
      state.isUpdatingEmailMap[action.payload.id] = false

      if (action.payload.error instanceof BaseError) {
        state.error = action.payload.error as any
      } else {
        const error = new GenericError('EMAIL_DELETE_ERROR')
        error.localizedMessage = 'There was a problem deleting this email'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload.error
        state.error = error as any
      }
    },
    fetchEmailsSucceeded(state, action: PayloadAction<EmailsResponseModel>) {
      if (state.isLoading) {
        state.emails = action.payload.emails
      } else {
        state.emails = state.emails.concat(action.payload.emails)
      }

      state.totalEmails = action.payload.totalResults
      state.isLoading = false
      state.isLoadingMore = false
    },
    fetchEmailsFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false
      state.isLoadingMore = false
      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('EMAIL_ERROR')
        error.localizedMessage = 'There was a problem getting emails'
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
  }
})

export const emailListReducer = emailListSlice.reducer

export const {
  reset,
  fetchEmails,
  fetchEmailsFailed,
  fetchEmailsSucceeded,
  setCurrentPage,
  deleteEmail,
  deleteEmailFailed,
  deleteEmailSucceeded
} = emailListSlice.actions

export const selectError = (state: RootState) => state.emailList.error
export const selectEmails = (state: RootState) => state.emailList.emails
export const selectIsLoading = (state: RootState) => state.emailList.isLoading
export const selectIsLoadingMore = (state: RootState) => state.emailList.isLoadingMore
export const selectTotalEmails = (state: RootState) => state.emailList.totalEmails
export const selectCurrentPage = (state: RootState) => state.emailList.currentPage
export const selectIsUpdating = (state: RootState, emailId?: string) => (emailId ? state.emailList.isUpdatingEmailMap[emailId] : false)
