import React from "react";
import {Modal} from "../modals/Modal";
import {PaymentSelectorView} from "./PaymentSelectorView";
import {useModalRouting} from "../../utils/useModalRouting";

export const PaymentSelectorModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();

  return <Modal
    className="md:max-w-2xl"
    onClose={() => onClose()}
    isOpen={isOpen('payment_selector')}>
    <PaymentSelectorView onClose={() => { onClose() }} />
  </Modal>
}