import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, mergeMap, of, scheduled} from "rxjs";
import {fetchNutritionTarget, fetchNutritionTargetFailed, fetchNutritionTargetSucceeded} from "./macroPlanSlice";
import {NutritionProtocolService} from "../../services/nutritionProtocolService";
import {logErrorRx} from "../../utils/logError";

export const fetchNutritionPlanEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchNutritionTarget.match),
    mergeMap(() => {
      return NutritionProtocolService.fetchNutritionTargetForDate(new Date())
        .pipe(
          mergeMap((target) => {
            return scheduled([fetchNutritionTargetSucceeded(target)], asyncScheduler)
          })
        )
    }),
    catchError(error => logErrorRx(error)),
    catchError(error => of({
      type: fetchNutritionTargetFailed.type,
      payload: error,
      error: true
    }))
  )
}