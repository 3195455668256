import React from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useRouter} from "next/router";
import {reset, selectProduct} from "../../redux/product/productSlice";
import {Modal} from "../modals/Modal";
import {ProductDetailView} from "./ProductDetailView";
import {useModalRouting} from "../../utils/useModalRouting";

export const ProductDetailModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { onClose, isOpen } = useModalRouting();

  const product = useAppSelector(selectProduct)

  const productId = router.query.product_id as string ?? product?.id

  return <Modal
    isOpen={isOpen('product_detail')}
    onClose={async () => {
      await onClose()
    }}
    onResignActive={() => {
      dispatch(reset())
    }}
    className="md:max-w-3xl lg:max-w-5xl"
  >
    <ProductDetailView
      productId={productId ?? ''}
      onClose={onClose}
    />
  </Modal>
}