import {BaseError} from "./baseError";
import {LocalizationInfo} from "react-i18next";

export type AuthValidationErrorCode = 'INVALID_EMAIL' | 'INVALID_PASSWORD' | 'INVALID_PASSWORD_LENGTH' | 'EMAIL_IS_EMPTY' | 'PASSWORD_IS_EMPTY'

export class AuthValidationError extends BaseError<AuthValidationErrorCode> {
  localizedMessage: LocalizationInfo | string
  localizedTitle: LocalizationInfo | string

  constructor(code: AuthValidationErrorCode) {
    super(code)

    switch (code) {
      case "INVALID_EMAIL":
        this.localizedMessage = { key: "auth:auth_error_message_invalid_email"}
        this.localizedTitle = { key: "auth:auth_error_title_invalid_email"}
        break
      case "INVALID_PASSWORD":
        this.localizedMessage = { key: "auth:auth_error_message_invalid_password"}
        this.localizedTitle = { key: "auth:auth_error_title_invalid_password"}
        break
      case "INVALID_PASSWORD_LENGTH":
        this.localizedMessage = { key: "auth:auth_error_message_invalid_password_length"}
        this.localizedTitle = { key: "auth:auth_error_title_invalid_password_length"}
        break
      case "EMAIL_IS_EMPTY":
        this.localizedMessage = { key: "auth:auth_error_message_email_is_empty"}
        this.localizedTitle = { key: "auth:auth_error_title_email_is_empty"}
        break
      case "PASSWORD_IS_EMPTY":
        this.localizedMessage = { key: "auth:auth_error_message_password_is_empty"}
        this.localizedTitle = { key: "auth:auth_error_title_password_is_empty"}
        break
    }
  }
}