import {NutrientType} from "../../models/nutrient";
import React from "react";
import {ResponsivePie} from '@nivo/pie'
import tailwindConfig from "../../tailwind.config";
import {Body, Heading1} from "../text";
import {useTranslation} from "next-i18next";
import Skeleton from "react-loading-skeleton";

export interface ProductDetailMacrosPieChartProps {
  nutrients: Map<NutrientType, number>
  isLoading: boolean
  className?: string
}

export const ProductDetailMacrosPieChart: React.FC<ProductDetailMacrosPieChartProps> = ({ nutrients, isLoading, className }) => {
  const { t } = useTranslation()
  const calories = nutrients.get("CALORIES") ?? 0

  const protein = nutrients.get("PROTEIN") ?? 0
  const proteinCals = protein * 4
  const proteinPercentage = calories ? proteinCals / calories : 0.001
  const carbohydrate = nutrients.get("CARBOHYDRATE") ?? 0
  const carbohydrateCals = carbohydrate * 4
  const carbohydratePercentage = calories ? carbohydrateCals / calories : 0.001

  const fat = nutrients.get("FAT") ?? 0
  const fatCals = fat * 9
  const fatPercentage = calories ? fatCals / calories : 0.001

  const data = [
    {
      label: t('nutrient_protein'),
      value: proteinPercentage
    },
    {
      label: t('nutrient_carbs'),
      value: carbohydratePercentage
    },
    {
      label: t('nutrient_fat'),
      value: fatPercentage
    }
  ]

  return(
    <div className={`relative ${className}`}>
      <ResponsivePie
        value={'value'}
        id={'label'}
        data={data}
        innerRadius={0.8}
        padAngle={3}
        colors={ isLoading ? [tailwindConfig.theme.colors['fg-gray-surface']] : [
          tailwindConfig.theme.colors['fg-purple-light'],
          tailwindConfig.theme.colors['fg-sky-blue'],
          tailwindConfig.theme.colors['fg-indigo']
        ]}
        borderColor="inherit:darker(0.2)"
        enableArcLinkLabels={false}
        enableArcLabels={false}
        animate={true}
        valueFormat=">-.0%"
        theme={{
          legends: {
            text: {
              fontSize: '1rem',
              fontWeight: 400,
              fill: tailwindConfig.theme.colors['fg-black'],
            },
          }
        }}
        tooltip={point => {
          return <div className="p-2 bg-white shadow-lg rounded-lg ring-1 ring-fg-gray-surface absolute bottom-0 left-3 items-center flex space-x-2">
            <div className="h-4 w-4" style={{ backgroundColor: point.datum.color }} />
            <Body className="whitespace-nowrap">{point.datum.data.label}: <span className="font-semibold">{Math.round(100 * point.datum.data.value)}%</span></Body>
          </div>
        }}
        legends={[]}
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col space-y-0 items-center justify-center">
          <Heading1>{isLoading ? <Skeleton className="!w-12" /> : calories}</Heading1>
          <Body className="text-fg-gray-dark">{isLoading ? <Skeleton className="!w-8" /> : t('common:nutrient_unit_kcal')}</Body>
        </div>
      </div>
    </div>
  )
}