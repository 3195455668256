import React from "react";
import {CartView} from "./CartView";
import {Modal} from "../modals/Modal";
import {useRouter} from "next/router";
import {Container} from "../containers/Container";
import {useModalRouting} from "../../utils/useModalRouting";

export const CartModal: React.FC<any> = () => {
  const router = useRouter();
  const { isOpen, onClose } = useModalRouting()

  return <Modal
    isOpen={isOpen('cart')}>
    <div className="max-h-screen w-full md:max-h-screen-7/8 lg:min-h-screen-3/4 sticky">
      <Container className="overflow-y-scroll max-h-screen">
        <CartView isMenu={false} storeId={router.query.cart_id as string} onClose={() => {
          onClose()
        }} />
      </Container>
    </div>
  </Modal>
}