import axios from "axios";
import {ApiError, ApiErrorJson} from "../errors/apiError";
import moment from "moment";
import {AuthService} from "../services/authService";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {firstValueFrom, filter} from "rxjs";
import {map} from "rxjs/operators";

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  responseType: "json",
});

// Use interceptor to inject the token to requests
api.interceptors.request.use(async (request) => {
  if (request.headers.is_authenticated) {
    const requestObservable = AuthService.getAccessToken()
      .pipe(
        filter(token => token != null),
        map(token => {
          if (token) {
            request.headers['Authorization'] = token
          }

          delete request.headers.is_authenticated
          return request;
        })
      )

    return firstValueFrom(requestObservable)
  } else {
    return request
  }
});

api.interceptors.response.use(originalResponse => {
  handleDates(originalResponse.data);
  return originalResponse;
});

const refreshAuthLogic = (failedRequest: any) => firstValueFrom(AuthService.authenticateSession()).then(response => {
  AuthService.setAccessToken(response)
  failedRequest.response.config.headers['Authorization'] = response?.accessToken;
  return Promise.resolve();
});

createAuthRefreshInterceptor(api, refreshAuthLogic);

export const refreshApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  responseType: "json",
});

refreshApi.interceptors.response.use(originalResponse => {
  handleDates(originalResponse.data);
  return originalResponse;
});


export const isIsoDateString = (value: any): boolean => {
  const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
  return value && typeof value === "string" && isoDateFormat.test(value);
}

const handleDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = moment(value).toDate();
    else if (typeof value === "object") handleDates(value);
  }
}

export const handleApiError = (error: any) => {
  const data = error?.response?.data as ApiErrorJson | null
  const apiError = ApiError.fromJson(data)

  if (apiError) {
    throw apiError
  } else {
    throw error
  }
}