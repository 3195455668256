import {LocationModel} from "../../models/location";
import {BaseError} from "../../errors/baseError";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {LocationSearchItem} from "./locationSearchSlice";
import {AutocompletePrediction} from "googlemaps";
import {PlacesError} from "../../errors/placesError";

export interface LocationEntryItem {
  readonly isLoading: boolean
  readonly isSaving: boolean
  readonly isDeleting: boolean
  readonly isSelected: boolean
  readonly location: LocationModel
}

export interface LocationEntryState {
  readonly locationEntryItems: LocationEntryItem[]
  readonly locationSearchItems: LocationSearchItem[]
  readonly query?: string
  readonly selectedLocation?: LocationModel | null
  readonly error?: BaseError<any> | null
  readonly searchError?: BaseError<any> | null
  readonly isSearching: boolean
  readonly isSaving: boolean
  readonly isLoading: boolean
  readonly shouldClose: boolean
}

export const locationEntrySlice = createSlice({
  name: "location_entry",
  initialState: {
    locationEntryItems: [],
    locationSearchItems: [],
    isLoading: false,
    isSaving: false,
    isSearching: false,
    shouldClose: false,
  } as LocationEntryState,
  reducers: {
    reset(state) {
      state.shouldClose = false
      state.query = ""
      state.locationSearchItems = []
      state.isSearching = false
      state.isSaving = false
      state.selectedLocation = undefined
    },
    savePrimaryLocationSucceeded(state) {
      state.shouldClose = true
      state.selectedLocation = undefined
    },
    fetchSearchResults(state, action: PayloadAction<string>) {
      state.query = action.payload
      state.isSearching = action.payload.length > 2
      state.error = null
      state.searchError = null
    },
    fetchSearchResultsSucceeded(state, action: PayloadAction<AutocompletePrediction[]>) {
      state.isSearching = false
      state.locationSearchItems = action.payload.map(prediction => {
        return {
          title: prediction.structured_formatting.main_text,
          subtitle: prediction.structured_formatting.secondary_text,
          isLoading: false,
          value: prediction,
          id: prediction.place_id,
          type: 'prediction'
        }
      })
    },
    fetchSearchResultsFailed(state, action: PayloadAction<Error>) {
      state.isSearching = false
      if (action.payload instanceof BaseError) {
        state.searchError = action.payload as any
      } else {
        const error = new GenericError('LOCATION_SEARCH_ERROR')
        error.localizedMessage = { key: 'location:location_message_error_searching_address' }
        error.localizedTitle = { key: 'common_error_title_something_went_wrong' }
        error.error = action.payload
        state.searchError = error as any
      }

      state.locationSearchItems = [
        {
          title: state.searchError?.localizedMessage ?? { key: 'location:location_message_error_searching_address' },
          isLoading: false,
          value: null,
          id: 'error',
          type: (state.error as PlacesError)?.code === 'ZERO_RESULTS' ? 'no_results' : 'error'
        }
      ]
    },
    updatePrimaryLocation(state, action: PayloadAction<LocationModel | null>) {
      state.isSaving = true
      // state.selectedLocation = action.payload
      state.locationEntryItems = state.locationEntryItems.map(item => {
        const isSelected = item.location.locationId === action.payload?.locationId ||
          item.location.type === 'current' && action.payload?.type === 'current' ||
          item.location.type === 'approximate' && action.payload?.type === 'approximate'

        return {
          location: item.location,
          isLoading: item.isLoading,
          isDeleting: false,
          isSaving: item.location.locationId === action.payload?.locationId,
          isSelected
        }
      })
    },
    fetchAndSaveLocation(state, action: PayloadAction<string>) {
      state.searchError = null
      state.locationSearchItems = state.locationSearchItems.map(item => {
        if ((item.value as AutocompletePrediction)?.place_id === action.payload) {
          item.isLoading = true
          return item
        } else {
          return item
        }
      })
    },
    fetchAndSaveLocationSucceeded(state, action: PayloadAction<LocationModel>) {
      // state.query = ""
      state.locationSearchItems = []
      state.selectedLocation = action.payload

      const containsItem = state.locationEntryItems.filter(i => i.location.locationId === action.payload.locationId)[0]
      if (!containsItem) {
        state.locationEntryItems.unshift({
          location: action.payload,
          isLoading: false,
          isDeleting: false,
          isSaving: false,
          isSelected: true
        })
      }
      state.locationEntryItems = state.locationEntryItems.map(item => {
        item.isSelected = item.location.locationId === action.payload.locationId
        return item
      })
    },
    fetchAndSaveLocationFailed(state, action: PayloadAction<Error>) {
      state.locationSearchItems = state.locationSearchItems.map(item => {
        item.isLoading = false
        return item
      })

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('LOCATION_FETCH_ERROR')
        error.localizedMessage = { key: 'location:location_message_error_getting_address' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }

      state.locationSearchItems = [
        {
          title: state.error?.localizedMessage ?? { key: 'location:location_message_error_getting_address' },
          isLoading: false,
          value: null,
          id: 'error',
          type: 'error'
        }
      ]
    },
    deleteLocation(state, action: PayloadAction<LocationModel>) {
      state.locationEntryItems = state.locationEntryItems.map(item => {
        if (item.location.locationId === action.payload.locationId) item.isDeleting = true
        return item
      })
    },
    deleteLocationSucceeded(state, action: PayloadAction<LocationModel>) {
      state.locationEntryItems = state.locationEntryItems.filter(i => i.location.locationId !== action.payload.locationId)
    },
    deleteLocationFailed(state, action: PayloadAction<Error>) {
      state.locationEntryItems = state.locationEntryItems.map(item => {
        item.isDeleting = false
        return item
      })

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('LOCATION_ENTRY_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'location:location_message_error_deleting_address' }
        error.error = action.payload
        state.error = error as any
      }
    },
    updatePrimaryLocationSucceeded(state, action: PayloadAction<LocationModel>) {
      state.isSaving = false
      state.locationEntryItems = state.locationEntryItems.map(item => {
        const isSelected = item.location.locationId === action.payload?.locationId ||
          item.location.type === 'current' && action.payload?.type === 'current' ||
          item.location.type === 'approximate' && action.payload?.type === 'approximate'

        return {
          location: item.location,
          isLoading: item.isLoading,
          isDeleting: false,
          isSaving: false,
          isSelected
        }
      })
    },
    fetchSavedLocations(state) {
      state.isLoading = true
      state.error = null

      if (state.locationEntryItems.length === 0) {
        state.locationEntryItems = [
          {
            isLoading: true,
            isSaving: false,
            isDeleting: false,
            isSelected: false,
            location: LocationModel.fromJson({ _id: "1" })!
          },
          {
            isLoading: true,
            isSaving: false,
            isDeleting: false,
            isSelected: false,
            location: LocationModel.fromJson({ _id: "2" })!
          }
        ]
      } else {
        state.locationEntryItems = state.locationEntryItems.map(item => {
          item.isLoading = true
          return item
        })
      }
    },
    fetchSavedLocationsSucceeded(state, action: PayloadAction<{ locations: LocationModel[], primaryLocation?: LocationModel | null }>) {
      state.isLoading = false
      state.error = null
      state.locationSearchItems = state.query ? state.locationSearchItems : []
      // state.selectedLocation = action.payload.primaryLocation
      const primaryLocation = action.payload.primaryLocation
      state.locationEntryItems = action.payload.locations.map(location => {
        const isSelected = location.locationId === primaryLocation?.locationId ||
          location.type === 'current' && primaryLocation?.type === 'current' ||
          location.type === 'approximate' && primaryLocation?.type === 'approximate'

        return {
          location,
          isLoading: false,
          isDeleting: false,
          isSaving: false,
          isSelected
        }
      }).sort((i1, i2) => {
        const gt = (i2.location.updatedAt?.getTime() ?? 0) > (i1.location.updatedAt?.getTime() ?? 0)
        const eq = (i2.location.updatedAt?.getTime() ?? 0) === (i1.location.updatedAt?.getTime() ?? 0)

        if (i1.isSelected && !i2.isSelected) {
          return -1
        } else if (i2.isSelected && !i1.isSelected) {
          return 1
        } else if (gt) {
          return 1
        } else if (eq) {
          return 0
        } else {
          return -1
        }
      })
    },
    fetchSavedLocationsFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('LOCATION_ENTRY_ERROR', action.payload)
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.localizedMessage = { key: 'location:location_message_error_getting_locations' }
        error.error = action.payload
        state.error = error as any
      }
    },
  },
})

export const locationEntryReducer = locationEntrySlice.reducer

export const {
  reset,
  savePrimaryLocationSucceeded,
  fetchSavedLocations,
  updatePrimaryLocation,
  deleteLocation,
  deleteLocationSucceeded,
  deleteLocationFailed,
  updatePrimaryLocationSucceeded,
  fetchAndSaveLocation,
  fetchAndSaveLocationFailed,
  fetchAndSaveLocationSucceeded,
  fetchSearchResults,
  fetchSearchResultsSucceeded,
  fetchSearchResultsFailed,
  fetchSavedLocationsSucceeded,
  fetchSavedLocationsFailed,
} = locationEntrySlice.actions

// Selectors
export const selectLocationEntryItems = (state: RootState) => state.locationEntry.locationEntryItems;
export const selectLocationSearchItems = (state: RootState) => state.locationEntry.locationSearchItems;
export const selectSelectedLocation = (state: RootState) => state.locationEntry.selectedLocation;
export const selectError = (state: RootState) => state.locationEntry.error;
export const selectIsLoading = (state: RootState) => state.locationEntry.isLoading;
export const selectIsSaving = (state: RootState) => state.locationEntry.isSaving;
export const selectIsSearching = (state: RootState) => state.locationEntry.isSearching;
export const selectQuery = (state: RootState) => state.locationEntry.query;
export const selectShouldClose = (state: RootState) => state.locationEntry.shouldClose;
