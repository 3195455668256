import {ObjectId} from "bson";
import {ObjectSchema} from "mongodb-realm";
import {BaseRealmService} from "../realm/realmService";
import {map, Observable} from "rxjs";
import {EntityModel} from "./entityModel";

export type PromptAvailabilityType = 'permanent' | 'one_shot' | 'temporary'
export type PromptDisplayType = 'standard' | 'large'
export type PromptScreen = 'log' | 'profile' | 'food_detail' | 'store_feed'

export interface PromptJson {
  _id?: string
  createdAt?: Date
  availabilityType?: PromptAvailabilityType
  backgroundColorDarkMode?: string
  backgroundColorLightMode?: string
  backgroundImageUrl?: string
  displayType?: PromptDisplayType
  expDate?: Date
  iconImageUrl?: string
  iconImageTintColorDarkMode?: string
  iconImageTintColorLightMode?: string
  isPremiumDisplayable?: boolean
  isUserDismissable?: boolean
  linkUrl?: string
  minAndroidVersion?: number
  minIosVersion?: string
  priority?: number
  showsBadge?: boolean
  subtitle?: string
  subtitleTextColorDarkMode?: string
  subtitleTextColorLightMode?: string
  title?: string
  titleTextColorDarkMode?: string
  titleTextColorLightMode?: string
  mixpanelCohortId?: string
  screens?: PromptScreen[]
  name?: string
  utm_campaign?: string
  utm_source?: string
  utm_medium?: string
}

export class PromptModel implements EntityModel<PromptModel> {
  public static schema: ObjectSchema = {
    name: 'PromptEntity',
    properties: {
      _id: 'objectId',
      _partition: 'string',
      availabilityType: 'string?',
      backgroundColorDarkMode: 'string?',
      backgroundColorLightMode: 'string?',
      backgroundImageUrl: 'string?',
      createdAt: 'date?',
      displayType: 'string?',
      expDate: 'date?',
      iconImageTintColorDarkMode: 'string?',
      iconImageTintColorLightMode: 'string?',
      iconImageUrl: 'string?',
      isDismissed: 'bool',
      isPremiumDisplayable: 'bool',
      isUserDismissable: 'bool',
      linkUrl: 'string?',
      minAndroidVersion: 'int',
      minIosVersion: 'string?',
      priority: 'int',
      promptId: 'string',
      screens: 'string[]',
      showsBadge: 'bool',
      subtitle: 'string?',
      subtitleTextColorDarkMode: 'string?',
      subtitleTextColorLightMode: 'string?',
      title: 'string?',
      titleTextColorDarkMode: 'string?',
      titleTextColorLightMode: 'string?',
    },
    primaryKey: '_id',
  }

  schema = PromptModel.schema

  _id: ObjectId = new ObjectId()
  _partition: string = ''
  promptId?: string
  createdAt?: Date
  availabilityType?: PromptAvailabilityType
  backgroundColorDarkMode?: string
  backgroundColorLightMode?: string
  backgroundImageUrl?: string
  displayType?: PromptDisplayType
  expDate?: Date
  iconImageUrl?: string
  iconImageTintColorDarkMode?: string
  iconImageTintColorLightMode?: string
  isPremiumDisplayable: boolean = false
  isUserDismissable: boolean = false
  isDismissed: boolean = false
  linkUrl?: string
  minAndroidVersion?: number
  minIosVersion?: string
  priority?: number
  showsBadge: boolean = false
  subtitle?: string
  subtitleTextColorDarkMode?: string
  subtitleTextColorLightMode?: string
  title?: string
  titleTextColorDarkMode?: string
  titleTextColorLightMode?: string
  screens: PromptScreen[] = []
  mixpanelCohortId?: string
  name?: string
  utmCampaign?: string
  utmSource?: string
  utmMedium?: string

  constructor(entity?: PromptModel | null) {
    if (!entity) return

    this._id = entity._id
    this._partition = entity._partition
    this.promptId = entity.promptId
    this.createdAt = entity.createdAt
    this.availabilityType = entity.availabilityType
    this.backgroundColorDarkMode = entity.backgroundColorDarkMode
    this.backgroundColorLightMode = entity.backgroundColorLightMode
    this.backgroundImageUrl = entity.backgroundImageUrl
    this.displayType = entity.displayType
    this.expDate = entity.expDate
    this.iconImageUrl = entity.iconImageUrl
    this.iconImageTintColorDarkMode = entity.iconImageTintColorDarkMode
    this.iconImageTintColorLightMode = entity.iconImageTintColorLightMode
    this.isPremiumDisplayable = entity.isPremiumDisplayable
    this.isUserDismissable = entity.isUserDismissable
    this.isDismissed = entity.isDismissed
    this.linkUrl = entity.linkUrl
    this.minAndroidVersion = entity.minAndroidVersion
    this.minIosVersion = entity.minIosVersion
    this.priority = entity.priority
    this.showsBadge = entity.showsBadge
    this.subtitle = entity.subtitle
    this.subtitleTextColorDarkMode = entity.subtitleTextColorDarkMode
    this.subtitleTextColorLightMode = entity.subtitleTextColorLightMode
    this.title = entity.title
    this.titleTextColorDarkMode = entity.titleTextColorDarkMode
    this.titleTextColorLightMode = entity.titleTextColorLightMode
    this.screens = entity.screens
    this.mixpanelCohortId = entity.mixpanelCohortId
    this.name = entity.name
    this.utmCampaign = entity.utmCampaign
    this.utmSource = entity.utmSource
    this.utmMedium = entity.utmMedium

    return this
  }

  static fromJson(json?: PromptJson): PromptModel | undefined {
    if (!json) return

    const model = new PromptModel()

    model.promptId = json._id
    model.createdAt = json.createdAt
    model.availabilityType = json.availabilityType
    model.backgroundColorDarkMode = json.backgroundColorDarkMode
    model.backgroundColorLightMode = json.backgroundColorLightMode
    model.backgroundImageUrl = json.backgroundImageUrl
    model.displayType = json.displayType
    model.expDate = json.expDate
    model.iconImageUrl = json.iconImageUrl
    model.iconImageTintColorDarkMode = json.iconImageTintColorDarkMode
    model.iconImageTintColorLightMode = json.iconImageTintColorLightMode
    model.isPremiumDisplayable = json.isPremiumDisplayable ?? false
    model.isUserDismissable = json.isUserDismissable ?? false
    model.linkUrl = json.linkUrl
    model.minAndroidVersion = json.minAndroidVersion
    model.minIosVersion = json.minIosVersion
    model.priority = json.priority
    model.showsBadge = json.showsBadge ?? false
    model.subtitle = json.subtitle
    model.subtitleTextColorDarkMode = json.subtitleTextColorDarkMode
    model.subtitleTextColorLightMode = json.subtitleTextColorLightMode
    model.title = json.title
    model.titleTextColorDarkMode = json.titleTextColorDarkMode
    model.titleTextColorLightMode = json.titleTextColorLightMode
    model.screens = json.screens ?? []
    model.mixpanelCohortId = json.mixpanelCohortId
    model.name = json.name
    model.utmCampaign = json.utm_campaign
    model.utmSource = json.utm_source
    model.utmMedium = json.utm_medium

    return model
  }

  toJson(): PromptJson {
    return {
      _id: this.promptId,
      createdAt: this.createdAt,
      availabilityType: this.availabilityType,
      backgroundColorDarkMode: this.backgroundColorDarkMode,
      backgroundColorLightMode: this.backgroundColorLightMode,
      backgroundImageUrl: this.backgroundImageUrl,
      displayType: this.displayType,
      expDate: this.expDate,
      iconImageUrl: this.iconImageUrl,
      iconImageTintColorDarkMode: this.iconImageTintColorDarkMode,
      iconImageTintColorLightMode: this.iconImageTintColorLightMode,
      isPremiumDisplayable: this.isPremiumDisplayable,
      isUserDismissable: this.isUserDismissable,
      linkUrl: this.linkUrl,
      minAndroidVersion: this.minAndroidVersion,
      minIosVersion: this.minIosVersion,
      priority: this.priority,
      showsBadge: this.showsBadge,
      subtitle: this.subtitle,
      subtitleTextColorDarkMode: this.subtitleTextColorDarkMode,
      subtitleTextColorLightMode: this.subtitleTextColorLightMode,
      title: this.title,
      titleTextColorDarkMode: this.titleTextColorDarkMode,
      titleTextColorLightMode: this.titleTextColorLightMode,
      screens: this.screens,
      name: this.name,
      utm_campaign: this.utmCampaign,
      utm_source: this.utmSource,
      utm_medium: this.utmMedium,
      mixpanelCohortId: this.mixpanelCohortId
    }
  }

  toEntityObservable(realmService: BaseRealmService): Observable<PromptModel> {
    return realmService.query<PromptModel>(PromptModel.schema.name)
      .where({ promptId: this.promptId })
      .fetchOne()
      .pipe(
        map(existingEntity => {
          if (existingEntity) {
            this._id = existingEntity._id
          }

          return this
        })
      )
  }
}
