import {BaseRealmService} from "../realm/realmService";
import {Observable, of} from "rxjs";
import {EntityModel} from "./entityModel";
import {ObjectSchema} from "mongodb-realm";

export interface AccountConfigJson {
  free_trial_exp_date?: Date
  is_beta_user?: boolean
  is_store_enabled?: boolean
  is_premium?: boolean
}

export class AccountConfigModel implements EntityModel<AccountConfigModel> {
  public static schema: ObjectSchema = {
    name: 'AccountConfigEntity',
    embedded: true,
    properties: {
      freeTrialExpDate: 'date?',
      isBetaUser: 'bool',
      isStoreEnabled: 'bool',
    },
  }

  schema = AccountConfigModel.schema

  freeTrialExpDate?: Date;
  isBetaUser: boolean = false;
  isStoreEnabled: boolean = true;

  constructor(entity?: AccountConfigModel | null) {
    if (!entity) return

    this.freeTrialExpDate = entity.freeTrialExpDate
    this.isBetaUser = entity.isBetaUser
    this.isStoreEnabled = entity.isStoreEnabled

    return this
  }

  static fromJson(json?: AccountConfigJson): AccountConfigModel | undefined {
    if (!json) return

    const model = new AccountConfigModel()

    model.freeTrialExpDate = json.free_trial_exp_date
    model.isBetaUser = json.is_beta_user ?? model.isBetaUser
    model.isStoreEnabled = json.is_store_enabled ?? model.isStoreEnabled

    return model
  }

  toEntityObservable(realmService: BaseRealmService): Observable<AccountConfigModel> {
    return of(this)
  }
}