import {from, Observable} from "rxjs";
import {ProductJson, ProductModel} from "../models/product";
import {api, handleApiError} from "./api";
import {StoreModel} from "../models/store";
import {LocationJson} from "../models/location";
import {StoreSectionModel} from "../models/storeSection";
import {StoreSelectorModel} from "../models/storeSelector";
import {VendorModel} from "../models/vendor";
import {CurrencyCode} from "../models/currencyCode";

export const StoreApi = {
  getAvailableMenuItemsForSkus(skuIds: string[]): Observable<ProductModel[]> {
    const request: Promise<ProductModel[]> = api.post(`/store/get-available-menu-items-for-skus`, skuIds,{
      headers: { is_authenticated: true }
    })
      .then(response => {
        const jsons: ProductModel[] = response.data.map((p: ProductJson) => ProductModel.fromJson(p)!)
        return jsons
      })
      .catch(handleApiError)

    return from(request)
  },
  getProduct(id: string): Observable<ProductModel> {
    const request: Promise<ProductModel> = api.get(`/store/get-menu-item/${id}`, {
      headers: { is_authenticated: true }
    })
      .then(response => ProductModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getStoreForLocation(payload: LocationJson): Observable<StoreModel> {
    const request: Promise<StoreModel> = api.post(`/v2/store/get-store-for-location`, payload, {
      headers: { is_authenticated: true }
    })
      .then(response => StoreModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getStore(id: string): Observable<StoreModel> {
    const request: Promise<StoreModel> = api.get(`/store/get-store/${id}`, {
      headers: { is_authenticated: true }
    })
      .then(response => StoreModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getStoreSection(id: string): Observable<StoreSectionModel> {
    const request: Promise<StoreSectionModel> = api.get(`/store/get-section/${id}`, {
      headers: { is_authenticated: true }
    })
      .then(response => StoreSectionModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getStoreSectionForCategory(categoryId: string): Observable<StoreSectionModel> {
    const request: Promise<StoreSectionModel> = api.get(`/store/get-section-for-category/${categoryId}`, {
      headers: { is_authenticated: true }
    })
      .then(response => StoreSectionModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getStoreForSelector(payload: LocationJson): Observable<StoreSelectorModel> {
    const request: Promise<StoreSelectorModel> = api.post(`/v2/store/get-store-selector`, payload, {
      headers: { is_authenticated: true }
    })
      .then(response => StoreSelectorModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  getVendor(id: string): Observable<VendorModel> {
    const request: Promise<VendorModel> = api.get(`/store/get-vendor/${id}`, {
      headers: { is_authenticated: true }
    })
      .then(response => VendorModel.fromJson(response.data)!)
      .catch(handleApiError)

    return from(request)
  },
  convertCurrency(fromCurrency: CurrencyCode, toCurrency: CurrencyCode, value: number): Observable<number> {
    const request: Promise<number> = api.post(`/currency/convert`, {
      from: fromCurrency, to: toCurrency, value
    }, {
      headers: { is_authenticated: true }
    })
      .then(response => response.data.result as number)
      .catch(handleApiError)

    return from(request)
  }
}