import {EntityModel} from "./entityModel";
import {ObjectSchema} from "mongodb-realm";
import {ObjectId} from "bson";
import {BaseRealmService} from "../realm/realmService";
import {map, Observable} from "rxjs";
import {NutrientType} from "./nutrient";
import Moment from "moment";
import {Frequency, RRule, RRuleSet, rrulestr, Weekday} from "rrule";
import {RruleUtils} from "../utils/RruleUtils";

export interface NutritionProtocolJson {
  avg_calories?: number
  avg_weight?: number
  calcium?: number
  calories?: number
  carbohydrate?: number
  cardio?: number
  cholesterol?: number
  created_at?: Date
  fat?: number
  fiber?: number
  _id?: string
  iron?: number
  potassium?: number
  protein?: number
  recommendation_feedback?: string
  sodium?: number
  sugar?: number
  updated_at?: Date
  vitamin_a?: number
  vitamin_c?: number
  weight_change_rate?: number
}

export class NutritionProtocolModel implements EntityModel<NutritionProtocolModel> {
  public static schema: ObjectSchema = {
    name: 'NutritionProtocolEntity',
    properties: {
      _id: 'objectId',
      _partition: 'string',
      avgCalories: 'double?',
      avgWeight: 'double?',
      calcium: 'double?',
      calories: 'double?',
      carbohydrate: 'double?',
      cardio: 'double?',
      cholesterol: 'double?',
      createdAt: 'date?',
      endDate: 'date?',
      fat: 'double?',
      fiber: 'double?',
      iron: 'double?',
      nutritionProtocolId: 'string?',
      potassium: 'double?',
      protein: 'double?',
      recommendationFeedback: 'string?',
      rrule: 'string?',
      sodium: 'double?',
      startDate: 'date?',
      sugar: 'double?',
      updatedAt: 'date?',
      vitaminA: 'double?',
      vitaminC: 'double?',
      weightChangeRate: 'double?',
    },
    primaryKey: '_id',
  }

  schema = NutritionProtocolModel.schema

  _id: ObjectId = new ObjectId();
  _partition: string = '';
  avgCalories?: number;
  avgWeight?: number;
  calcium?: number;
  calories?: number;
  carbohydrate?: number;
  cardio?: number;
  cholesterol?: number;
  createdAt?: Date;
  endDate?: Date;
  fat?: number;
  fiber?: number;
  iron?: number;
  nutritionProtocolId?: string;
  potassium?: number;
  protein?: number;
  recommendationFeedback?: string;
  rrule?: string;
  sodium?: number;
  startDate?: Date;
  sugar?: number;
  updatedAt?: Date;
  vitaminA?: number;
  vitaminC?: number;
  weightChangeRate?: number;

  constructor(entity?: NutritionProtocolModel) {
    if (!entity) return

    this._id = entity._id
    this._partition = entity._partition
    this.avgCalories = entity.avgCalories
    this.avgWeight = entity.avgWeight
    this.calcium = entity.calcium
    this.calories = entity.calories
    this.carbohydrate = entity.carbohydrate
    this.cardio = entity.cardio
    this.cholesterol = entity.cholesterol
    this.createdAt = entity.createdAt
    this.endDate = entity.endDate
    this.fat = entity.fat
    this.fiber = entity.fiber
    this.iron = entity.iron
    this.nutritionProtocolId = entity.nutritionProtocolId
    this.potassium = entity.potassium
    this.protein = entity.protein
    this.recommendationFeedback = entity.recommendationFeedback
    this.rrule = entity.rrule
    this.sodium = entity.sodium
    this.startDate = entity.startDate
    this.sugar = entity.sugar
    this.updatedAt = entity.updatedAt
    this.vitaminA = entity.vitaminA
    this.vitaminC = entity.vitaminC
    this.weightChangeRate = entity.weightChangeRate

    return this
  }

  toEntityObservable(realmService: BaseRealmService): Observable<NutritionProtocolModel> {
    return realmService.query<NutritionProtocolModel>(NutritionProtocolModel.schema.name)
      .where({ nutritionProtocolId: this.nutritionProtocolId })
      .fetchOne()
      .pipe(
        map(existingEntity => {
          if (existingEntity) {
            this._id = existingEntity._id
          }

          return this
        })
      )
  }

  static fromJson(json?: NutritionProtocolJson): NutritionProtocolModel | undefined {
    if (!json) return

    const model = new NutritionProtocolModel()

    model.avgCalories = json.avg_calories
    model.avgWeight = json.avg_weight
    model.calcium = json.calcium
    model.calories = json.calories
    model.carbohydrate = json.carbohydrate
    model.cardio = json.cardio
    model.cholesterol = json.cholesterol
    model.createdAt = json.created_at
    model.fat = json.fat
    model.fiber = json.fiber
    model.iron = json.iron
    model.nutritionProtocolId = json._id
    model.potassium = json.potassium
    model.protein = json.protein
    model.recommendationFeedback = json.recommendation_feedback
    model.sodium = json.sodium
    model.startDate = Moment(json.created_at).startOf('day').toDate()
    model.sugar = json.sugar
    model.updatedAt = json.updated_at
    model.vitaminA = json.vitamin_a
    model.vitaminC = json.vitamin_c
    model.weightChangeRate = json.weight_change_rate

    return model
  }

  getRrule(): RRuleSet {
    if (this.rrule) {
      const rrule = RruleUtils.createRruleFromString(this.rrule)
      const rruleSet = new RRuleSet()
      rruleSet.rrule(rrule)

      return rruleSet
    } else {
      const rrule = new RRule({
        freq: Frequency.DAILY,
        dtstart: null,
        until: null,
        interval: 1,
        wkst: Weekday.fromStr('MO')
      })

      const rruleSet = new RRuleSet()
      rruleSet.rrule(rrule)

      return rruleSet
    }
  }

  getNutrients(): Map<NutrientType, number> {
    const map = new Map<NutrientType, number>()

    this.calcium && map.set("CALCIUM", this.calcium)
    this.calories && map.set("CALORIES", this.calories)
    this.carbohydrate && map.set("CARBOHYDRATE", this.carbohydrate)
    this.cholesterol && map.set("CHOLESTEROL", this.cholesterol)
    this.fat && map.set("FAT", this.fat)
    this.fiber && map.set("FIBER", this.fiber)
    this.iron && map.set("IRON", this.iron)
    this.potassium && map.set("POTASSIUM", this.potassium)
    this.protein && map.set("PROTEIN", this.protein)
    this.sodium && map.set("SODIUM", this.sodium)
    this.sugar && map.set("SUGAR", this.sugar)
    this.vitaminA && map.set("VITAMIN_A", this.vitaminA)
    this.vitaminC && map.set("VITAMIN_C", this.vitaminC)

    return map
  }
}