import {ProductModel} from "../../models/product";
import React, {useEffect, useState} from "react";
import {CloseIcon, WarningColorIcon} from "../icons";
import {Image} from "../images/Image";
import {ImageUtils} from "../../utils/imageUtils";
import {Body, Heading1, HeroHeading2, LocalizedTextLabel} from "../text";
import {ProductDetailMacrosView} from "./ProductDetailMacrosView";
import {useRouter} from "next/router";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  fetchProduct,
  fetchProductSucceeded,
  optionItemSelected, reset, selectError,
  selectIsLoading,
  selectOptionSections,
  selectProduct,
  selectShouldClose
} from "../../redux/product/productSlice";
import {ProductDetailSkuOptionView} from "./ProductDetailSkuOptionView";
import {ProductDetailToolbarView} from "./ProductDetailToolbarView";
import {Container} from "../containers/Container";
import Skeleton from "react-loading-skeleton";
import {StoreEmptyStateView} from "../storeFeed/StoreEmptyStateView";
import {useTranslation} from "next-i18next";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export interface ProductDetailViewProps {
  productId: string
  product?: ProductModel
  onClose: () => void
}

export const ProductDetailView: React.FC<ProductDetailViewProps> = ({ product: preloadedProduct, productId, onClose }) => {
  const router = useRouter()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const product = useAppSelector(selectProduct)
  const optionSections = useAppSelector(selectOptionSections)
  const shouldClose = useAppSelector(selectShouldClose)
  const isLoading = useAppSelector(selectIsLoading)
  const error = useAppSelector(selectError)

  const [loading, setLoading] = useState(isLoading)

  const skuId = router.query.sku_id

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Menu Item Detail' }))
  }, [])

  useEffect(() => {
    setLoading(isLoading || router.isFallback)
  }, [isLoading, router.isFallback])

  useEffect(() => {
    if (shouldClose) {
      onClose()
    }
  }, [shouldClose, onClose])

  const fetchData = () => {
    if (preloadedProduct) {
      dispatch(fetchProductSucceeded(preloadedProduct))
    }

    if (skuId && typeof skuId === 'string') {
      productId && dispatch(fetchProduct({ productId, skuId, product: preloadedProduct }))
    } else {
      productId && dispatch(fetchProduct({ productId, product: preloadedProduct }))
    }
  }

  useEffect(() => {
    fetchData()

  }, [productId, skuId, dispatch, preloadedProduct])

  const imageUrl = ImageUtils.createUrlFromImage(product?.image)
  const nutrients = loading && !product ? new Map() : product?.getSelectedSku()?.deliveredMeal?.getNutrients()
  return (
    <div className="max-h-screen w-full md:max-h-screen-7/8 min-h-screen-3/4 sticky">
      <Container className="overflow-y-scroll max-h-screen max-w-full">
        <div className="w-full transform no-scrollbar">
          <div className="-ml-3 -mt-3 mb-2 lg:mb-6 flex items-center justify-between">
            <button
              type="button"
              className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
              onClick={() => onClose()}
            >
              <CloseIcon className="h-4 h-4"/>
            </button>
          </div>

          <div className={`${error ? '' : 'hidden'}`}>
            <StoreEmptyStateView
              onClick={() => {
                dispatch(reset())
                fetchData()
              }}
              buttonTitle={t('store:store_empty_state_button_try_again')}
              title={error?.localizedTitle}
              subtitle={error?.localizedMessage}
              icon={<WarningColorIcon />}
              isLoading={loading}
            />
          </div>

          <div className={`grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-8 ${error ? 'hidden' : ''}`}>
            <div className="col-span-1 w-full mx-auto">
              {imageUrl && !loading && <Image src={imageUrl} sizeClass={`mx-auto aspect-h-5 aspect-w-7 lg:aspect-h-1 lg:aspect-w-1`} className="rounded-lg" objectFit="cover" />}
              {loading && <Skeleton height="0px" className={`!rounded-lg !aspect-h-5 !aspect-w-7 lg:!aspect-h-1 lg:!aspect-w-1`}/>}
            </div>
            <div className=" col-span-1 lg:max-h-screen-3/4 lg:col-span-2 flex flex-col space-y-6 flex-1 overflow-hidden">
              <div className="flex-1 overflow-y-scroll">
                <div className="flex flex-col space-y-6">
                  <HeroHeading2>{loading ? <Skeleton className="!w-1/2" /> : product?.name}</HeroHeading2>
                  <Body className="text-fg-gray-dark">{loading ? <Skeleton count={3} className="!w-full" /> : product?.description}</Body>
                  {nutrients && <ProductDetailMacrosView nutrients={nutrients} isLoading={loading} />}
                  {optionSections.map(section => {
                    return <div key={section.id}>
                      <Heading1><LocalizedTextLabel t={t} text={section.title} /></Heading1>
                      {section.items.map((item, index) => {
                        return <ProductDetailSkuOptionView
                          key={item.sku.id}
                          item={item}
                          isLastItem={index === section.items.length - 1}
                          onClick={() => dispatch(optionItemSelected(item))} />
                      })}
                    </div>
                  })}

                  <div className={`pb-32`} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`bg-white shadow-2xl border-t border-fg-gray-surface-dark md:rounded-b-xl col-span-1 lg:col-span-3 fixed z-20 left-1/2 transform -translate-x-1/2 bottom-0 w-full px-4 sm:px-8 lg:px-10 py-4 lg:py-6 xl:py-8 ${error ? 'hidden' : ''}`}
          style={{ minWidth: '20rem' }}
        >
          <ProductDetailToolbarView productId={productId} />
        </div>
      </Container>
    </div>
  )
}