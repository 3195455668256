import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LocationModel} from "../../models/location";
import {UserModel} from "../../models/user";
import {RootState} from "../../app/store";
import {LatLng} from "googlemaps";
import {logoutSucceeded} from "../auth/authSlice";

// State
export interface UserState {
  primaryLocation?: LocationModel
  userLatLng?: LatLng
  user?: UserModel | null
}

// Slice
export const userSlice = createSlice({
  name: "user",
  initialState: {} as UserState,
  reducers: {
    reset(state, action: PayloadAction) {
      state.primaryLocation = undefined
      state.user = undefined
    },
    setPrimaryLocation(state, action: PayloadAction<LocationModel>) {
      state.primaryLocation = action.payload
      state.userLatLng = { lat: action.payload.latitude, lng: action.payload.longitude }
    },
    clearPrimaryLocation(state) {
      state.primaryLocation = undefined
    },
    fetchLatLngSucceeded(state, action: PayloadAction<LatLng>) {
      state.userLatLng = action.payload
    },
    fetchUser(state) {

    },
    fetchUserSucceeded(state, action: PayloadAction<UserModel | null>) {
      state.user = action.payload
    },
    fetchUserFailed(state, action: PayloadAction<Error>) {

    }
  }
})

export const {
  fetchLatLngSucceeded,
  clearPrimaryLocation,
  setPrimaryLocation,
  fetchUserSucceeded,
  fetchUser,
  fetchUserFailed,
  reset
} = userSlice.actions

// Reducer
export const userReducer = userSlice.reducer

// Selectors
export const selectPrimaryLocation = (state: RootState) => state.user.primaryLocation;
export const selectUser = (state: RootState) => state.user.user;
