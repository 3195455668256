import React from "react";
import {CardExpiryElement} from "@stripe/react-stripe-js";
import {useStripeFormatter} from "../../utils/useStripeFormatter";

export const PaymentExpDateInputView: React.FC<any> = () => {
  const { baseClass, baseStyle, onFocus, onError, onBlur } = useStripeFormatter('cardExpiry')

  return <div>
    <CardExpiryElement
      onFocus={() => {
        onFocus()
      }}
      onChange={e => {
        onError(e.error)
      }}
      onBlur={() => {
        onBlur()
      }}
      options={{
        classes: {
          base: baseClass
        },
        style: {
          base: baseStyle
        }
      }}
    />
  </div>
}
