import {EntityModel} from "./entityModel";
import {Observable, of} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectSchema} from "mongodb-realm";

export interface DirectionJson {
  direction_description?: string
  direction_number?: number
}

export class DirectionModel implements EntityModel<DirectionModel> {
  public static schema: ObjectSchema = {
    name: 'DirectionEntity',
    embedded: true,
    properties: {
      directionDescription: 'string?',
      directionNumber: 'int',
    },
  }

  public schema = DirectionModel.schema

  directionDescription?: string;
  directionNumber: number = 0

  constructor(entity?: DirectionModel | null) {
    if (!entity) return

    this.directionDescription = entity.directionDescription
    this.directionNumber = entity.directionNumber

    return this
  }

  static fromJson(json?: DirectionJson): DirectionModel | undefined {
    if (!json) return undefined

    const model = new DirectionModel()

    model.directionDescription = json.direction_description
    model.directionNumber = json.direction_number ?? 0

    return model
  }

  toJson(): DirectionJson {
    return {
      direction_number: this.directionNumber,
      direction_description: this.directionDescription
    }
  }

  toEntityObservable(realmService: BaseRealmService): Observable<DirectionModel> {
    return of(this)
  }
}