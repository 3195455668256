import {LocationSearchItem} from "../../redux/location/locationSearchSlice";
import React from "react";
import {LoadingSpinnerIcon, MarkerFilledIcon, NavigationFilledIcon, SearchIcon, WarningFilledIcon} from "../icons";
import {Body, HeroBody, LocalizedTextLabel, Paragraph} from "../text";
import {useTranslation} from "next-i18next";

export interface LocationEntrySearchResultViewProps {
  item: LocationSearchItem
  isLastItem: boolean
  onClick?: () => void
}

export const LocationEntrySearchResultView: React.FC<LocationEntrySearchResultViewProps> = ({ item, isLastItem, onClick }) => {
  const { t } = useTranslation()

  return <div className={`px-5`}>
    <div className={`py-4 flex items-center cursor-pointer ${!isLastItem ? 'border-b border-fg-gray-surface' : ''}`} onClick={() => onClick && onClick()}>
      <div>
        {
          (() => {
            if (item.isLoading) {
              return <LoadingSpinnerIcon className="h-6 h-6 mr-4 animate-spin text-fg-primary" />
            } else if (item.type === 'error') {
              return <WarningFilledIcon className="h-6 w-6 mr-4" />
            } else if (item.type === 'current_location') {
              return <NavigationFilledIcon className="text-fg-primary h-6 w-6 mr-4" />
            } else if (item.type === 'no_results') {
              return <SearchIcon className="h-6 w-6 mr-4" />
            } else {
              return <MarkerFilledIcon className="h-6 w-6 mr-4" />
            }
          })()
        }
      </div>
      <div className="flex-grow">
        <Body className={`${item.subtitle ? 'mb-1' : ''}`}>
          <LocalizedTextLabel t={t} text={item.title} />
        </Body>
        <Paragraph className={`${item.subtitle ? 'block' : 'hidden'} text-fg-gray-dark`}>
          <LocalizedTextLabel t={t} text={item.subtitle} />
        </Paragraph>
      </div>
    </div>
  </div>
}