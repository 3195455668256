import {LocationJson, LocationModel} from "./location";
import {StoreSelectorState} from "./storeSelectorState";
import {StoreSelectorOptionJson, StoreSelectorOptionModel} from "./storeSelectorOption";
import {ProductOptionModel} from "./productOption";

export interface StoreSelectorJson {
  available_locations?: LocationJson[]
  state?: StoreSelectorState
  stores?: StoreSelectorOptionJson[]
  subtitle?: string
  title?: string
}

export class StoreSelectorModel {
  availableLocations: LocationModel[] = []
  state?: StoreSelectorState
  stores: StoreSelectorOptionModel[] = []
  subtitle?: string
  title?: string

  static fromJson(json?: StoreSelectorJson): StoreSelectorModel | undefined {
    if (!json) return

    const model = new StoreSelectorModel()

    model.availableLocations = json.available_locations
      ?.map((i) => LocationModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.state = json.state
    model.stores = json.stores
      ?.map((i) => StoreSelectorOptionModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.subtitle = json.subtitle
    model.title = json.title

    return model
  }
}