import {BaseError} from "./baseError";
import {LocalizationInfo} from "react-i18next";

export type ContactInfoValidationErrorCode =
  'FIRST_NAME_IS_EMPTY' |
  'LAST_NAME_IS_EMPTY' |
  'PHONE_NUMBER_IS_EMPTY' |
  'INVALID_PHONE_NUMBER' |
  'INVALID_EMAIL' |
  'EMAIL_IS_EMPTY'

export class ContactInfoValidationError extends BaseError<ContactInfoValidationErrorCode> {
  localizedMessage: LocalizationInfo | string
  localizedTitle: LocalizationInfo | string

  constructor(code: ContactInfoValidationErrorCode) {
    super(code)

    switch (code) {
      case "FIRST_NAME_IS_EMPTY":
        this.localizedMessage = { key: "auth:auth_error_message_first_name_is_empty"}
        this.localizedTitle = { key: "auth:auth_error_title_first_name_is_empty"}
        break;
      case "LAST_NAME_IS_EMPTY":
        this.localizedMessage = { key: "auth:auth_error_message_last_name_is_empty"}
        this.localizedTitle = { key: "auth:auth_error_title_last_name_is_empty"}
        break;
      case "PHONE_NUMBER_IS_EMPTY":
        this.localizedMessage = { key: "auth:auth_error_message_phone_number_is_empty"}
        this.localizedTitle = { key: "auth:auth_error_title_phone_number_is_empty"}
        break;
      case "INVALID_PHONE_NUMBER":
        this.localizedMessage = { key: "auth:auth_error_message_invalid_phone_number"}
        this.localizedTitle = { key: "auth:auth_error_title_invalid_phone_number"}
        break;
      case "INVALID_EMAIL":
        this.localizedMessage = { key: "auth:auth_error_message_invalid_email"}
        this.localizedTitle = { key: "auth:auth_error_title_invalid_email"}
        break;
      case "EMAIL_IS_EMPTY":
        this.localizedMessage = { key: "auth:auth_error_message_email_is_empty"}
        this.localizedTitle = { key: "auth:auth_error_title_email_is_empty"}
        break;

    }
  }
}