import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductModel} from "../../models/product";
import {BaseError} from "../../errors/baseError";
import {GenericError} from "../../errors/genericError";
import {RootState} from "../../app/store";
import {ProductSkuModel} from "../../models/productSku";
import {WritableDraft} from "immer/dist/types/types-external";
import {LocalizationInfo} from "react-i18next";
import {ShoppingCartItemModel} from "../../models/shoppingCartItem";
import {MenuItemSizeType} from "../../models/menuItemSizeType";
import {ShoppingCartModel} from "../../models/shoppingCart";
import {fetchAndUpdateCartIfNeededSucceeded, fetchCartSucceeded} from "../cart/cartSlice";
import {fetchCheckoutDataSucceeded} from "../checkout/checkoutSlice";

export type ProductViewConfig = 'adding' | 'updating'

interface ProductState {
  readonly product?: ProductModel
  readonly cart?: ShoppingCartModel
  readonly lineItem?: ShoppingCartItemModel
  readonly optionSections: ProductOptionSection[]
  readonly isLoading: boolean
  readonly isUpdatingItem: boolean
  readonly shouldClose: boolean
  readonly error?: BaseError<any>
  readonly config: ProductViewConfig
}

export interface ProductOptionItem {
 readonly sku: ProductSkuModel
 readonly value: string
 readonly optionId: string
 readonly minPrice?: number
 readonly isSelected: boolean
 readonly type: 'size' | 'option'
}

interface ProductOptionSection {
  readonly id: string
  readonly title?: string | LocalizationInfo
  readonly items: ProductOptionItem[]
}

const createConfig = (
  product: ProductModel | WritableDraft<ProductModel>,
  cart?: ShoppingCartModel | WritableDraft<ShoppingCartModel>,
  lineItem?: ShoppingCartItemModel | WritableDraft<ShoppingCartItemModel>
): { config: ProductViewConfig, matchingSku?: ProductSkuModel } => {
  const items = cart?.items
    .filter(i => i.productId === product?.id)
    .sort((i1, i2) => (i2.updatedAt?.getTime() ?? 0) - (i1.updatedAt?.getTime() ?? 0)) ?? []

  const cartItem = lineItem ? items.filter(i => i.skuId === lineItem.skuId)[0] : items[0]
  const cartSku = product?.skus.filter(s => s.id === cartItem?.skuId)[0]

  let config: ProductViewConfig
  if (cartSku && cartItem) {
    cartSku.quantity = cartItem.quantity
    config = 'updating'

    return { config, matchingSku: cartSku as ProductSkuModel }
  } else {
    config = 'adding'
    return { config }
  }
}

const createOptionsSections = (product: ProductModel | WritableDraft<ProductModel>): ProductOptionSection[] => {
  const skus = product.skus
  if (skus.length <= 1) return []

  const options = product.options
  if (options.length > 0) {
    return options.map(option => {
      const values = option.values
      if (values.length <= 1) return;

      const items: ProductOptionItem[] = option.values.map(value => {
        const optionId = option.id
        if (!optionId) return;

        const otherSkus = option.values.map(otherValue => {
          const copiedProduct = new ProductModel(product as ProductModel)
          copiedProduct.selectedOptionValues.set(optionId, otherValue)

          return copiedProduct.getSelectedSku()
        })

        if (otherSkus.length <= 1) return;

        const minPrice = otherSkus.reduce((prev, curr) => {
          return (prev?.price ?? 0) < (curr?.price ?? 0) ? prev : curr;
        })?.price

        const copiedProduct = new ProductModel(product as ProductModel)
        copiedProduct.selectedOptionValues.set(optionId, value)

        const sku = copiedProduct.getSelectedSku()
        if (!sku) return;

        const priceDiff = (sku.price ?? 0) - (minPrice ?? 0)
        const isSelected = product.selectedOptionValues.get(optionId) === value

        const item: ProductOptionItem = {
          sku,
          value,
          optionId,
          minPrice,
          isSelected,
          type: 'option',
        }

        return item
      }).flatMap(i => i ? [i] : []) ?? []

      return { items, title: option.name, id: option.id ?? '' }
    }).flatMap(i => i ? [i] : []) ?? []
  } else {
    const copiedProduct = new ProductModel(product as ProductModel)
    const skus = copiedProduct.skus

    const minPrice = skus.reduce((prev, curr) => {
      return (prev?.price ?? 0) < (curr?.price ?? 0) ? prev : curr;
    })?.price

    const items: ProductOptionItem[] = skus.map(sku => {
      const isSelected = sku.size === product.getSelectedSku()?.size
      return {
        isSelected,
        sku,
        value: sku.size?.toUpperCase() ?? '',
        optionId: sku.size ?? '',
        minPrice,
        type: 'size'
      }
    })

    return [
      { items, title: { key: 'store:store_title_select_size' }, id: 'size' }
    ]
  }
}

export const productSlice = createSlice({
  name: "product",
  initialState: {
    isLoading: false,
    isUpdatingItem: false,
    shouldClose: false,
    optionSections: [],
    config: 'adding'
  } as ProductState,
  reducers: {
    reset(state) {
      state.isLoading = false
      state.shouldClose = false
      state.product = undefined
      state.lineItem = undefined
      state.error = undefined
      state.optionSections = []
    },
    fetchProduct(state, action: PayloadAction<{ product?: ProductModel, productId: string, skuId?: string }>) {
      state.isLoading = !state.product
    },
    fetchProductSucceeded(state, action: PayloadAction<ProductModel>) {
      const product = new ProductModel(action.payload)

      const { config, matchingSku } = createConfig(product, state.cart)
      if (matchingSku) {
        product.setSelectedSku(matchingSku)
      }

      const selectedSku = product.getSelectedSku()

      if (selectedSku) {
        selectedSku.quantity = selectedSku.quantity && selectedSku.quantity > 0 ? selectedSku.quantity : 1
        const index = product.skus.findIndex(s => s.id === selectedSku?.id)
        product.skus[index] = selectedSku
      }

      const sections = createOptionsSections(product)

      return {
        ...state,
        product,
        config,
        isLoading: false,
        lineItem: product.toLineItem(),
        optionSections: sections
      }
    },
    optionItemSelected(state, action: PayloadAction<ProductOptionItem>) {
      const item = action.payload
      const product = state.product
      const lineItem = state.lineItem

      const selectedSku = product?.getSelectedSku()

      if (item.type === 'option' && product && selectedSku) {
        const optionId = action.payload.optionId
        const value = action.payload.value
        product.selectedOptionValues.set(optionId, value)

        product.updateWithQuantity(lineItem?.quantity ?? 1)

        const newLineItem = product.toLineItem()
        const { config } = createConfig(product, state.cart, newLineItem)

        return {
          ...state,
          product,
          config,
          lineItem: newLineItem,
          optionSections: createOptionsSections(product) as WritableDraft<ProductOptionSection>[]
        }
      } else if (product && selectedSku) {
        product.selectedSize = action.payload.value as MenuItemSizeType
        product.updateWithQuantity(lineItem?.quantity ?? 1)

        const newLineItem = product.toLineItem()
        const { config } = createConfig(product, state.cart, newLineItem)

        return {
          ...state,
          product,
          config,
          lineItem: newLineItem,
          optionSections: createOptionsSections(product) as WritableDraft<ProductOptionSection>[]
        }
      } else {
        return { ...state }
      }
    },
    fetchProductFailed(state, action: PayloadAction<Error>) {
      state.isLoading = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PRODUCT_ERROR', action.payload)
        error.localizedMessage = { key: 'store:store_error_message_error_getting_product' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    setQuantity(state, action: PayloadAction<number>) {
      const lineItem = state.lineItem
      if (lineItem) {
        lineItem.quantity = action.payload
        state.lineItem = new ShoppingCartItemModel(lineItem)
      }
    },
    incrementItem(state) {
      const lineItem = state.lineItem
      if (lineItem) {
        lineItem.quantity += 1
        state.lineItem = new ShoppingCartItemModel(lineItem)
      }
    },
    decrementItem(state) {
      const lineItem = state.lineItem
      const config = state.config

      if (lineItem) {
        lineItem.quantity -= 1
        if (lineItem.quantity <= 1 && config === 'adding') {
          lineItem.quantity = 1
        } else if (lineItem.quantity <= 0 && config === 'updating') {
          lineItem.quantity = 0
        }
        state.lineItem = new ShoppingCartItemModel(lineItem)
      }
    },
    updateCartWithItem(state) {
      state.isUpdatingItem = true
    },
    updateItemInCartSucceeded(state) {
      state.isUpdatingItem = false
      state.shouldClose = true
    },
    updateItemInCartFailed(state, action: PayloadAction<Error>) {
      state.isUpdatingItem = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('PRODUCT_ERROR', action.payload)
        error.localizedMessage = { key: state.config === 'adding' ? 'store:store_error_message_error_adding_to_cart' : 'store:store_error_message_error_updating_item' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchCartSucceeded,(state, action) => {
      const cart = new ShoppingCartModel(action.payload)
      const product = state.product && new ProductModel(state.product as ProductModel)

      if (product) {
        const { config, matchingSku } = createConfig(product, state.cart)
        state.config = config
        if (matchingSku) product?.setSelectedSku(matchingSku)
      }

      state.cart = cart
    }).addCase(fetchCheckoutDataSucceeded,(state, action) => {
      const cart = new ShoppingCartModel(action.payload.cart)
      const product = state.product && new ProductModel(state.product as ProductModel)

      if (product) {
        const { config, matchingSku } = createConfig(product, state.cart)
        state.config = config
        if (matchingSku) product?.setSelectedSku(matchingSku)
      }

      state.cart = cart
    })
  }
})

export const productReducer = productSlice.reducer

export const {
  reset,
  fetchProduct,
  fetchProductSucceeded,
  fetchProductFailed,
  optionItemSelected,
  incrementItem,
  setQuantity,
  decrementItem,
  updateCartWithItem,
  updateItemInCartSucceeded,
  updateItemInCartFailed
} = productSlice.actions

export const selectProduct = (state: RootState) => state.product.product
export const selectLineItem = (state: RootState) => state.product.lineItem
export const selectIsLoading = (state: RootState) => state.product.isLoading
export const selectShouldClose = (state: RootState) => state.product.shouldClose
export const selectIsUpdatingItem = (state: RootState) => state.product.isUpdatingItem
export const selectError = (state: RootState) => state.product.error
export const selectConfig = (state: RootState) => state.product.config
export const selectQuantity = (state: RootState) => state.product.lineItem?.quantity ?? 1
export const selectTotalPrice = (state: RootState) => (state.product.lineItem?.quantity ?? 0) * (state.product.lineItem?.price ?? 0)
export const selectOptionSections = (state: RootState) => state.product.optionSections
