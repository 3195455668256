import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled} from "rxjs";
import {
  createPushNotification, createPushNotificationFailed,
  createPushNotificationSucceeded, deletePushNotification, deletePushNotificationFailed, deletePushNotificationSucceeded,
  fetchPushNotification,
  fetchPushNotificationFailed,
  fetchPushNotificationSucceeded, reset, updatePushNotification, updatePushNotificationFailed, updatePushNotificationSucceeded
} from "./pushNotificationDetailSlice";
import {
  fetchPushNotifications,
  reset as resetPushNotificationList,
  selectCurrentPage,
  setCurrentPage
} from "./pushNotificationListSlice";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";

export const fetchPushNotificationEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchPushNotification.match),
    mergeMap(action => {
      return MessageService.fetchPushNotification(action.payload)
        .pipe(
          map(fetchPushNotificationSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchPushNotificationFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const createPushNotificationEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(createPushNotification.match),
    mergeMap(action => {
      return MessageService.createPushNotification(action.payload.pushNotification)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.pushNotificationList.currentPage

            return scheduled([
              createPushNotificationSucceeded(),
              reset(),
              resetPushNotificationList(),
              pageNumber === 1 ? fetchPushNotifications(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: createPushNotificationFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const updatePushNotificationEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(updatePushNotification.match),
    mergeMap(action => {
      return MessageService.updatePushNotification(action.payload.pushNotification, action.payload.id)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.pushNotificationList.currentPage

            return scheduled([
              updatePushNotificationSucceeded(),
              reset(),
              resetPushNotificationList(),
              pageNumber === 1 ? fetchPushNotifications(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: updatePushNotificationFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deletePushNotificationEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(deletePushNotification.match),
    mergeMap(action => {
      return MessageService.deletePushNotification(action.payload)
        .pipe(
          mergeMap(() => {
            const pageNumber = state$.value.pushNotificationList.currentPage

            return scheduled([
              deletePushNotificationSucceeded(),
              reset(),
              resetPushNotificationList(),
              pageNumber === 1 ? fetchPushNotifications(pageNumber) : setCurrentPage(1)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: deletePushNotificationFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}