import {CurrencyCode} from "./currencyCode";

export type SubscriptionPriceInterval = 'year' | 'month' | 'week' | 'day'

export interface SubscriptionPriceJson {
  id: string
  error?: string
  currency: CurrencyCode
  interval?: SubscriptionPriceInterval
  interval_count?: number
  price: number
  discount_price?: number
  monthly_price: number
  discount_monthly_price?: number
  discount_percentage?: number
  coupon?: string
}

export class SubscriptionPriceModel {
  id: string = ''
  error?: string
  currency: CurrencyCode = 'USD'
  interval?: SubscriptionPriceInterval
  intervalCount?: number
  price: number = 0
  discountPrice?: number
  monthlyPrice: number = 0
  discountMonthlyPrice?: number
  discountPercentage?: number
  coupon?: string

  constructor(model?: SubscriptionPriceModel) {
    if (!model) return

    this.id = model.id
    this.error = model.error
    this.currency = model.currency
    this.interval = model.interval
    this.intervalCount  = model.intervalCount
    this.price = model.price
    this.discountPrice = model.discountPrice
    this.monthlyPrice = model.monthlyPrice
    this.discountMonthlyPrice = model.discountMonthlyPrice
    this.discountPercentage = model.discountPercentage
    this.coupon = model.coupon

    return this
  }

  static fromJson(json?: SubscriptionPriceJson): SubscriptionPriceModel | undefined {
    if (!json) return

    const model = new SubscriptionPriceModel()

    model.id = json.id
    model.error = json.error
    model.currency = json.currency
    model.interval = json.interval
    model.intervalCount = json.interval_count
    model.price = json.price
    model.discountPrice = json.discount_price
    model.monthlyPrice = json.monthly_price
    model.discountMonthlyPrice = json.discount_monthly_price
    model.discountPercentage = json.discount_percentage
    model.coupon = json.coupon

    return model
  }
}