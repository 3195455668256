import {CurrencyCode} from "./currencyCode";
import {PromoCodeJson, PromoCodeModel} from "./promoCode";

export interface PaymentSummaryJson {
  applied_promo_code?: PromoCodeJson
  currency_code?: CurrencyCode
  refund?: number
  discount?: number
  ship_dates?: Date[]
  shipping?: number
  subtotal?: number
  tax?: number
}

export class PaymentSummaryModel {
  appliedPromoCode?: PromoCodeModel
  currencyCode?: CurrencyCode
  discount?: number
  refund?: number
  shipDates: Date[] = []
  shipping?: number
  subtotal?: number
  tax?: number

  constructor(model?: PaymentSummaryModel) {
    if (!model) return

    this.appliedPromoCode = model.appliedPromoCode
    this.currencyCode = model.currencyCode
    this.discount = model.discount
    this.refund = model.refund
    this.shipDates = model.shipDates
    this.shipping = model.shipping
    this.subtotal = model.subtotal
    this.tax = model.tax
  }

  static fromJson(json?: PaymentSummaryJson): PaymentSummaryModel | undefined {
    if (!json) return

    const model = new PaymentSummaryModel()

    model.appliedPromoCode = PromoCodeModel.fromJson(json.applied_promo_code)
    model.currencyCode = json.currency_code
    model.discount = json.discount
    model.refund = json.refund
    model.shipDates = json.ship_dates ?? []
    model.shipping = json.shipping
    model.subtotal = json.subtotal
    model.tax = json.tax

    return model
  }

  getTotal(): number | undefined {
    if (this.subtotal != null && this.tax != null && this.shipping != null && this.discount != null) {
      return this.subtotal + this.tax + this.shipping - this.discount
    } else if (this.subtotal != null && this.tax != null && this.shipping != null) {
      return this.subtotal + this.tax + this.shipping
    }
  }
}