import React from "react";
import {useModalRouting} from "../../utils/useModalRouting";
import {useRouter} from "next/router";
import {Modal} from "../modals/Modal";
import {PromoCodeEntryView} from "./PromoCodeEntryView";

export const PromoCodeEntryModal: React.FC<any> = () => {
  const { isOpen, onClose } = useModalRouting()
  const router = useRouter();

  return <Modal
    className="md:max-w-2xl"
    onClose={() => onClose()}
    isOpen={isOpen('promo_code_entry')}
  >
    <PromoCodeEntryView onClose={onClose} storeId={router.query.store_id as string} />
  </Modal>
}