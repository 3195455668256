import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, map, mergeMap, of, scheduled} from "rxjs";
import {
  createPrompt,
  createPromptFailed,
  createPromptSucceeded,
  deletePrompt,
  deletePromptFailed,
  deletePromptSucceeded,
  fetchPrompt,
  fetchPromptFailed,
  fetchPromptSucceeded,
  reset,
  updatePrompt,
  updatePromptFailed,
  updatePromptSucceeded
} from "./promptDetailSlice";
import {MessageService} from "../../services/messageService";
import {logErrorRx} from "../../utils/logError";

export const fetchPromptEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchPrompt.match),
    mergeMap(action => {
      return MessageService.fetchPrompt(action.payload)
        .pipe(
          map(fetchPromptSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchPromptFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const createPromptEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(createPrompt.match),
    mergeMap(action => {
      return MessageService.createPrompt(action.payload.prompt, action.payload.cohortId)
        .pipe(
          mergeMap(() => {
            return scheduled([
              createPromptSucceeded(),
              reset()
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: createPromptFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const updatePromptEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(updatePrompt.match),
    mergeMap(action => {
      return MessageService.updatePrompt(action.payload.prompt, action.payload.id, action.payload.cohortId)
        .pipe(
          mergeMap(() => {
            const page = state$.value.promptList.currentPage

            return scheduled([
              updatePromptSucceeded(),
              reset()
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: updatePromptFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const deletePromptEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(deletePrompt.match),
    mergeMap(action => {
      return MessageService.deletePrompt(action.payload)
        .pipe(
          mergeMap(() => {
            const page = state$.value.promptList.currentPage

            return scheduled([
              deletePromptSucceeded(),
              reset()
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: deletePromptFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}