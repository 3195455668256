export interface PushNotificationStatsJson {
  errored?: number
  pending?: number
  delivered?: number
  processed?: number
  unsubscribe_drops?: number
  opens?: number
}

export interface PushNotificationJson {
  _id?: string
  created_at?: Date
  updated_at?: Date
  title?: string
  subtitle?: string
  message?: string
  name?: string
  android_channel_id?: string
  ios_thread_id?: string
  url?: string
  image_url?: string
  utm_campaign?: string
  utm_source?: string
  utm_medium?: string
  delivery_time_of_day?: string
  send_after?: Date
  stats?: PushNotificationStatsJson
}

export class PushNotificationStatsModel {
  errored: number = 0
  pending: number = 0
  delivered: number = 0
  processed: number = 0
  unsubscribeDrops: number = 0
  opens: number = 0

  constructor(model?: PushNotificationStatsModel) {
    if (!model) return

    this.errored = model.errored
    this.pending = model.pending
    this.delivered = model.delivered
    this.processed = model.processed
    this.unsubscribeDrops = model.unsubscribeDrops
    this.opens = model.opens

    return this
  }

  static fromJson(json?: PushNotificationStatsJson): PushNotificationStatsModel | undefined {
    if (!json) return

    const model = new PushNotificationStatsModel()

    model.errored = json.errored ?? model.errored
    model.pending = json.pending ?? model.pending
    model.delivered = json.delivered ?? model.delivered
    model.processed = json.processed ?? model.processed
    model.unsubscribeDrops = json.unsubscribe_drops ?? model.unsubscribeDrops
    model.opens = json.opens ?? model.opens

    return model
  }

  toJson(): PushNotificationStatsJson {
    return {
      errored: this.errored,
      pending: this.pending,
      delivered: this.delivered,
      processed: this.processed,
      unsubscribe_drops: this.unsubscribeDrops,
      opens: this.opens
    }
  }
}

export class PushNotificationModel {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  title?: string
  subtitle?: string
  message?: string
  name?: string
  androidChannelId?: string
  iosThreadId?: string
  url?: string
  imageUrl?: string
  deliveryTimeOfDay?: string
  sendAfter?: Date
  stats?: PushNotificationStatsModel
  utmCampaign?: string
  utmSource?: string
  utmMedium?: string

  constructor(model?: PushNotificationModel) {
    if (!model) return

    this.id = model.id
    this.createdAt = model.createdAt
    this.updatedAt = model.updatedAt
    this.title = model.title
    this.subtitle = model.subtitle
    this.message = model.message
    this.name = model.name
    this.androidChannelId = model.androidChannelId
    this.iosThreadId = model.iosThreadId
    this.url = model.url
    this.imageUrl = model.imageUrl
    this.deliveryTimeOfDay = model.deliveryTimeOfDay
    this.sendAfter = model.sendAfter
    this.utmCampaign = model.utmCampaign
    this.utmSource = model.utmSource
    this.utmMedium = model.utmMedium
    this.stats = model.stats && new PushNotificationStatsModel(model.stats)

    return this
  }

  static fromJson(json?: PushNotificationJson): PushNotificationModel | undefined {
    if (!json) return

    const model = new PushNotificationModel()

    model.id = json._id
    model.createdAt = json.created_at
    model.updatedAt = json.updated_at
    model.title = json.title
    model.subtitle = json.subtitle
    model.message = json.message
    model.name = json.name
    model.androidChannelId = json.android_channel_id
    model.iosThreadId = json.ios_thread_id
    model.url = json.url
    model.imageUrl = json.image_url
    model.deliveryTimeOfDay = json.delivery_time_of_day
    model.sendAfter = json.send_after
    model.utmCampaign = json.utm_campaign
    model.utmSource = json.utm_source
    model.utmMedium = json.utm_medium
    model.stats = PushNotificationStatsModel.fromJson(json.stats)

    return model
  }

  toJson(): PushNotificationJson {
    return {
      _id: this.id,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      title: this.title,
      subtitle: this.subtitle,
      message: this.message,
      name: this.name,
      android_channel_id: this.androidChannelId,
      ios_thread_id: this.iosThreadId,
      url: this.url,
      image_url: this.imageUrl,
      delivery_time_of_day: this.deliveryTimeOfDay,
      send_after: this.sendAfter,
      utm_campaign: this.utmCampaign,
      utm_source: this.utmSource,
      utm_medium: this.utmMedium,
      stats: this.stats?.toJson()
    }
  }
}