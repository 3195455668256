import {CommissionPayoutJson, CommissionPayoutModel} from "./commissionPayout";

export interface CommissionPayoutsResponseJson {
  total_results?: number
  payouts?: CommissionPayoutJson[]
}

export class CommissionPayoutsResponseModel {
  totalResults: number = 0
  payouts: CommissionPayoutModel[] = []

  static fromJson(json?: CommissionPayoutsResponseJson): CommissionPayoutsResponseModel | undefined {
    if (!json) return

    const model = new CommissionPayoutsResponseModel()
    model.totalResults = json.total_results ?? 0
    model.payouts = json.payouts?.map(i => CommissionPayoutModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}