import {PaymentSelectorItem} from "../../redux/payment/paymentSelectorSlice";
import React from "react";
import {useTranslation} from "next-i18next";
import Skeleton from "react-loading-skeleton";
import {
  AmexColorIcon,
  DebitCardFilledIcon,
  DinersClubColorIcon,
  DiscoverColorIcon,
  JcbColorIcon,
  LoadingSpinnerIcon,
  MasterCardColorIcon,
  RadioEmptyIcon,
  RadioFilledIcon,
  UnionPayColorIcon,
  VisaColorIcon
} from "../icons";
import {Body} from "../text";
import {Button} from "../buttons/Button";

export interface PaymentOptionViewProps {
  item: PaymentSelectorItem
  isLastItem: boolean
  onDeleteClick?: () => void
  onClick?: () => void
}

export const PaymentOptionView: React.FC<PaymentOptionViewProps> = ({ item, isLastItem, onDeleteClick, onClick  }) => {
  const { card, isSaving, isLoading, isDeleting, isSelected } = item
  const { t } = useTranslation()

  let title: string
  if (card.lastFourCardNumDigits) {
    title = `•••• ${card.lastFourCardNumDigits}`
  } else {
    title = '••••'
  }
  return (
    <div className={`flex items-center ${!isLastItem ? 'border-b border-fg-gray-surface' : ''}`}>
      <div className="flex space-x-4 py-4 flex-grow items-center cursor-pointer" onClick={() => onClick && onClick()}>
        <div>
          {
            (() => {
              if (isLoading) {
                return <Skeleton className="!rounded-full !h-7 !w-7" />
              } else if (isSaving) {
                return <LoadingSpinnerIcon className="h-6 w-6 animate-spin text-fg-primary" />
              } else if (isSelected) {
                return <RadioFilledIcon className="text-fg-primary h-6 w-6" />
              } else {
                return <RadioEmptyIcon className="text-fg-gray-light h-6 w-6" />
              }
            })()
          }
        </div>
        <div>
          {
            (() => {
              if (isLoading) {
                return <Skeleton className="!w-9 !h-6" />
              }

              switch (card.brand) {
                case "American Express":
                  return <AmexColorIcon className="h-9 w-9" />
                case "amex":
                  return <AmexColorIcon className="h-9 w-9" />
                case "Visa":
                  return <VisaColorIcon className="h-9 w-9" />
                case "visa":
                  return <VisaColorIcon className="h-9 w-9" />
                case "MasterCard":
                  return <MasterCardColorIcon className="h-9 w-9" />
                case "mastercard":
                  return <MasterCardColorIcon className="h-9 w-9" />
                case "Discover":
                  return <DiscoverColorIcon className="h-9 w-9" />
                case "discover":
                  return <DiscoverColorIcon className="h-9 w-9" />
                case "JCB":
                  return <JcbColorIcon className="h-9 w-9" />
                case "jcb":
                  return <JcbColorIcon className="h-9 w-9" />
                case "Union Pay":
                  return <UnionPayColorIcon className="h-9 w-9" />
                case "UnionPay":
                  return <UnionPayColorIcon className="h-9 w-9" />
                case "union":
                  return <UnionPayColorIcon className="h-9 w-9" />
                case "Diners Club":
                  return <DinersClubColorIcon className="h-9 w-9" />
                case "diners":
                  return <DinersClubColorIcon className="h-9 w-9" />
                default:
                  return <DebitCardFilledIcon className="h-9 w-9 text-fg-gray-dark" />
              }
            })()
          }
        </div>
        <div className="flex-grow">
          <Body>{isLoading ? <Skeleton className="!w-16"/>  : title}</Body>
        </div>
        <div>
          {isLoading ? <Skeleton className="!h-10 !w-20 !rounded-full" /> : <Button buttonStyle="secondary" isLoading={isDeleting} onClick={e => {
            e.stopPropagation()
            onDeleteClick && onDeleteClick()
          }}>
            <span className={`${isLoading ? 'text-transparent' : 'text-fg-red'}`}>{t('common:common_title_delete')}</span>
          </Button>}
        </div>
      </div>
    </div>
  )
}