import {ImageJson, ImageModel} from "./image";
import {CurrencyCode} from "./currencyCode";
import {LocationJson, LocationModel} from "./location";
import {DistributionScheduleType} from "./distributionScheduleType";
import {DistributionMethod} from "./distributionMethod";

export interface VendorJson {
  _id?: string
  cover_image?: ImageJson
  created_at?: Date
  updated_at?: Date
  currency_code?: CurrencyCode
  description?: string
  icon_image?: ImageJson
  location?: LocationJson
  name?: string
  support_email?: string
  account_email?: string
  phone_number?: string
  distribution_schedule_type?: DistributionScheduleType
  distribution_methods?: DistributionMethod[]
}

export class VendorModel {
  accountEmail?: string
  coverImage?: ImageModel
  createdAt?: Date
  currencyCode?: CurrencyCode
  description?: string
  distributionMethods: DistributionMethod[] = []
  distributionScheduleType?: DistributionScheduleType
  iconImage?: ImageModel
  id?: string
  location?: LocationModel
  name?: string
  phoneNumber?: string
  supportEmail?: string
  updatedAt?: Date

  constructor(model?: VendorModel) {
    if (!model) return

    this.accountEmail = model.accountEmail
    this.coverImage = new ImageModel(model.coverImage)
    this.createdAt = model.createdAt
    this.currencyCode = model.currencyCode
    this.description = model.description
    this.distributionMethods = model.distributionMethods
    this.distributionScheduleType = model.distributionScheduleType
    this.iconImage = new ImageModel(model.iconImage)
    this.id = model.id
    this.location = new LocationModel(model.location)
    this.name = model.name
    this.phoneNumber = model.phoneNumber
    this.supportEmail = model.supportEmail
    this.updatedAt = model.updatedAt
  }

  static fromJson(json?: VendorJson): VendorModel | undefined {
    if (!json) return

    return {
      accountEmail: json.account_email,
      coverImage: ImageModel.fromJson(json.cover_image),
      createdAt: json.created_at,
      currencyCode: json.currency_code,
      description: json.description,
      distributionMethods: json.distribution_methods ?? [],
      distributionScheduleType: json.distribution_schedule_type,
      iconImage: ImageModel.fromJson(json.icon_image),
      id: json._id,
      location: LocationModel.fromJson(json.location),
      name: json.name,
      phoneNumber: json.phone_number,
      supportEmail: json.support_email,
      updatedAt: json.updated_at
    }
  }
}