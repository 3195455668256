import React, {ReactElement, useEffect, useState} from "react";
import {LocalizedTextLabel, TextStyles} from "../text";
import {LocalizationInfo} from "react-i18next";
import {useTranslation} from "next-i18next";

export interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
  validate?: (text?: string | null) => string | LocalizationInfo | null | undefined | boolean
}

export interface TextAreaProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
  validate?: (text?: string | null) => string | LocalizationInfo | null | undefined | boolean
}

export const TextInputStyles = {
  Base: 'placeholder-fg-gray bg-fg-gray-surface-light border-0 border-transparent focus:ring-fg-black focus:ring-2 block w-full rounded-lg',
}

// eslint-disable-next-line react/display-name
export const HeroBodyInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [isFocused, setIsFocused] = useState(false)

  const leadingIcon = props.leadingIcon
  const leadingIconProps = leadingIcon && (leadingIcon as ReactElement)?.props
  const leadingIconClassName = `${isFocused ? 'text-current' : 'text-fg-gray-dark'} ${leadingIconProps?.className ? leadingIconProps.className : ''} h-5 w-5 lg:h-6 lg:w-6`

  const trailingIcon = props.trailingIcon
  const trailingIconProps = trailingIcon && (trailingIcon as ReactElement)?.props
  const trailingIconClassName = `${trailingIconProps?.className ? trailingIconProps.className : ''} h-5 w-5 lg:h-6 lg:w-6`

  const inputProps = { ...props }
  delete inputProps.validate
  delete inputProps.leadingIcon
  delete inputProps.trailingIcon

  return(
    <div className={`relative rounded-lg text-fg-black ${props.className}`}>
      <div className={`${leadingIcon ? '' : 'hidden'} absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none`}>
        {leadingIcon && React.cloneElement(leadingIcon as React.ReactElement, { className: leadingIconClassName })}
      </div>
      <input
        type="text"
        { ...inputProps }
        ref={ref}
        onFocus={e => {
          setIsFocused(true)
          props.onFocus && props.onFocus(e)
        }}
        onBlur={e => {
          setIsFocused(false)
          props.onBlur && props.onBlur(e)
        }}
        className={`rounded-lg ${TextInputStyles.Base} ${TextStyles.HeroBody} py-2.5 lg:py-3 ${leadingIcon && trailingIcon ? 'px-14' : leadingIcon ? 'pl-14 pr-3 lg:pr-4' : trailingIcon ? 'pr-14 pl-3 lg:pl-4' : 'px-3 lg:px-4'} ${props.className}`}
      />
      <div className={`${trailingIcon ? '' : 'hidden'} absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none`}>
        {trailingIcon && React.cloneElement(trailingIcon as React.ReactElement, { className: trailingIconClassName })}
      </div>
    </div>
  )
})

// eslint-disable-next-line react/display-name
export const BodyInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { t } = useTranslation()
  const { validate } = props

  const [isFocused, setIsFocused] = useState(false)
  const [text, setText] = useState<string | null | undefined>()
  const [validation, setValidation] = useState<string | LocalizationInfo | null | undefined | boolean>(validate ? validate(text) : true)
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    setValidation(validate ? validate(text) : true)
  }, [validate, text])

  useEffect(() => {
    if (validation === true) {
      setIsValid(true)
    } else if (validation === null || validation === undefined) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [validation])

  const leadingIcon = props.leadingIcon
  const leadingIconProps = leadingIcon && (leadingIcon as ReactElement)?.props
  const leadingIconClassName = `${isFocused ? 'text-current' : 'text-fg-gray-dark'} ${leadingIconProps?.className ? leadingIconProps.className : ''} h-5 w-5 lg:h-6 lg:w-6`

  const trailingIcon = props.trailingIcon
  const trailingIconProps = trailingIcon && (trailingIcon as ReactElement)?.props
  const trailingIconClassName = `${trailingIconProps?.className ? trailingIconProps.className : ''} h-5 w-5 lg:h-6 lg:w-6`


  const inputProps = { ...props }
  delete inputProps.validate
  delete inputProps.leadingIcon
  delete inputProps.trailingIcon

  return(
    <div className="flex flex-col space-y-2">
      <div className="relative rounded-lg text-fg-black">
        <div className={`${leadingIcon ? '' : 'hidden'} absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none`}>
          {leadingIcon && React.cloneElement(leadingIcon as React.ReactElement, { className: leadingIconClassName })}
        </div>
        <input
          type="text"
          { ...inputProps }
          ref={ref}
          onChange={e => {
            setText(e.target.value)
            props.validate && setValidation(props.validate(e.target.value))
            props.onChange && props.onChange(e)
          }}
          onFocus={e => {
            setIsFocused(true)
            props.onFocus && props.onFocus(e)
          }}
          onBlur={e => {
            setIsFocused(false)
            props.onBlur && props.onBlur(e)
          }}
          className={`rounded-lg ${TextInputStyles.Base} ${TextStyles.Body} ${isValid ? '' : 'text-fg-red ring-2 ring-fg-red focus:ring-fg-red focus:ring-2'} py-2.5 lg:py-3 ${leadingIcon && trailingIcon ? 'px-14' : leadingIcon ? 'pl-14 pr-3 lg:pr-4' : trailingIcon ? 'pr-14 pl-3 lg:pl-4' : 'px-3 lg:px-4'} ${props.className}`}
        />
        <div className={`${trailingIcon ? '' : 'hidden'} absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none`}>
          {trailingIcon && React.cloneElement(trailingIcon as React.ReactElement, { className: trailingIconClassName })}
        </div>
      </div>
      <p className={`${typeof validation === "string" || (validation as LocalizationInfo)?.key ? 'text-fg-red' : 'hidden'}`}>
        <LocalizedTextLabel t={t} text={validation as string | LocalizationInfo | null} />
      </p>
    </div>
  )
})

// eslint-disable-next-line react/display-name
export const BodyTextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { t } = useTranslation()
  const { validate } = props

  const [isFocused, setIsFocused] = useState(false)
  const [text, setText] = useState<string | null | undefined>()
  const [validation, setValidation] = useState<string | LocalizationInfo | null | undefined | boolean>(validate ? validate(text) : true)
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    setValidation(validate ? validate(text) : true)
  }, [validate, text])

  useEffect(() => {
    if (validation === true) {
      setIsValid(true)
    } else if (validation === null || validation === undefined) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [validation])

  const leadingIcon = props.leadingIcon
  const leadingIconProps = leadingIcon && (leadingIcon as ReactElement)?.props
  const leadingIconClassName = `${isFocused ? 'text-current' : 'text-fg-gray-dark'} ${leadingIconProps?.className ? leadingIconProps.className : ''} h-5 w-5 lg:h-6 lg:w-6`

  const trailingIcon = props.trailingIcon
  const trailingIconProps = trailingIcon && (trailingIcon as ReactElement)?.props
  const trailingIconClassName = `${trailingIconProps?.className ? trailingIconProps.className : ''} h-5 w-5 lg:h-6 lg:w-6`


  const textareaProps = { ...props }
  delete textareaProps.validate
  delete textareaProps.leadingIcon
  delete textareaProps.trailingIcon

  return(
    <div className="flex flex-col space-y-2">
      <div className="relative rounded-lg text-fg-black">
        <div className={`${leadingIcon ? '' : 'hidden'} absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none`}>
          {leadingIcon && React.cloneElement(leadingIcon as React.ReactElement, { className: leadingIconClassName })}
        </div>
        <textarea
          { ...textareaProps }
          ref={ref}
          onChange={e => {
            setText(e.target.value)
            props.validate && setValidation(props.validate(e.target.value))
            props.onChange && props.onChange(e)
          }}
          onFocus={e => {
            setIsFocused(true)
            props.onFocus && props.onFocus(e)
          }}
          onBlur={e => {
            setIsFocused(false)
            props.onBlur && props.onBlur(e)
          }}
          className={`rounded-lg ${TextInputStyles.Base} ${TextStyles.Body} ${isValid ? '' : 'text-fg-red ring-2 ring-fg-red focus:ring-fg-red focus:ring-2'} py-2.5 lg:py-3 ${leadingIcon && trailingIcon ? 'px-14' : leadingIcon ? 'pl-14 pr-3 lg:pr-4' : trailingIcon ? 'pr-14 pl-3 lg:pl-4' : 'px-3 lg:px-4'} ${props.className}`}
        />
        <div className={`${trailingIcon ? '' : 'hidden'} absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none`}>
          {trailingIcon && React.cloneElement(trailingIcon as React.ReactElement, { className: trailingIconClassName })}
        </div>
      </div>
      <p className={`${typeof validation === "string" || (validation as LocalizationInfo)?.key ? 'text-fg-red' : 'hidden'}`}>
        <LocalizedTextLabel t={t} text={validation as string | LocalizationInfo | null} />
      </p>
    </div>
  )
})