import {LocationJson, LocationModel} from "./location";
import {DistributionMethod} from "./distributionMethod";
import {PurchaseOrderItemModel, PurchaseOrderLineItemJson} from "./purchaseOrderLineItem";
import {ShippingOptionJson, ShippingOptionModel} from "./shippingOption";
import {PaymentSummaryJson, PaymentSummaryModel} from "./paymentSummary";
import {PickupLocationJson, PickupLocationModel} from "./pickupLocation";
import {VendorJson, VendorModel} from "./vendor";
import {CardModel} from "./card";

export interface PurchaseOrderJson {
  delivery_location?: LocationJson
  distribution_method?: DistributionMethod
  distribution_methods?: DistributionMethod[]
  _id?: string
  line_items?: PurchaseOrderLineItemJson[]
  shipping_options?: ShippingOptionJson[]
  default_card?: CardModel
  shopify_shipping_option_id?: string
  payment_summary?: PaymentSummaryJson
  pickup_location?: PickupLocationJson
  store_id?: string
  vendor?: VendorJson
}

export class PurchaseOrderModel {
  deliveryLocation?: LocationModel
  distributionMethod?: DistributionMethod
  distributionMethods: DistributionMethod[] = []
  id?: string
  defaultCard?: CardModel
  lineItems: PurchaseOrderItemModel[] = []
  shippingOptionId?: string
  shippingOptions: ShippingOptionModel[] = []
  paymentSummary?: PaymentSummaryModel
  pickupLocation?: PickupLocationModel
  storeId?: string
  vendor?: VendorModel

  constructor(model?: PurchaseOrderModel) {
    if (!model) return

    this.deliveryLocation = new LocationModel(model.deliveryLocation)
    this.distributionMethod = model.distributionMethod
    this.distributionMethods = model.distributionMethods
    this.id = model.id
    this.defaultCard = new CardModel(model.defaultCard)
    this.lineItems = model.lineItems.map(i => new PurchaseOrderItemModel(i))
    this.shippingOptionId = model.shippingOptionId
    this.shippingOptions = model.shippingOptions.map(i => new ShippingOptionModel(i))
    this.paymentSummary = new PaymentSummaryModel(model.paymentSummary)
    this.pickupLocation = new PickupLocationModel(model.pickupLocation)
    this.storeId = model.storeId
    this.vendor = new VendorModel(model.vendor)
  }

  static fromJson(json?: PurchaseOrderJson) {
    if (!json) return

    const model = new PurchaseOrderModel()

    model.deliveryLocation = LocationModel.fromJson(json.delivery_location)
    model.distributionMethod = json.distribution_method
    model.distributionMethods = json.distribution_methods ?? []
    model.id = json._id
    model.defaultCard = CardModel.fromJson(json.default_card)
    model.lineItems = json.line_items
      ?.map((i) => PurchaseOrderItemModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.shippingOptionId = json.shopify_shipping_option_id
    model.shippingOptions = json.shipping_options
      ?.map((i) => ShippingOptionModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    model.paymentSummary = PaymentSummaryModel.fromJson(json.payment_summary)
    model.pickupLocation = PickupLocationModel.fromJson(json.pickup_location)
    model.storeId = json.store_id
    model.vendor = VendorModel.fromJson(json.vendor)

    return model
  }
}