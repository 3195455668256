import React, {Fragment, useEffect, useState} from "react";
import { Dialog, Transition } from '@headlessui/react'

export interface ModalProps {
  isOpen: boolean
  onClose?: () => void
  onResignActive?: () => void
  className?: string
  children?: React.ReactNode
  initialFocus?: React.MutableRefObject<any>
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onResignActive, className, children, initialFocus }) => {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => {
      onResignActive && onResignActive()
    }}>
      <Dialog initialFocus={initialFocus} as="div" className="fixed z-30 inset-0" onClose={v => {
        onClose && onClose()
      }}>
        <div className="flex min-h-screen text-center md:block md:px-2 lg:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-black bg-opacity-50 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className={`flex transform transition w-full text-left md:inline-block md:my-8 md:align-middle ${className ? className : 'md:max-w-2xl lg:max-w-4xl'}`}>
              <div className="w-full relative flex items-start rounded-xl bg-white overflow-hidden shadow-2xl">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}