import {RootEpic, RootState} from "../../app/store";
import {filter, tap} from "rxjs/operators";
import {
  addItemToCart,
  decrementItemInCart,
  fetchAndUpdateCartIfNeeded,
  fetchAndUpdateCartIfNeededFailed,
  fetchAndUpdateCartIfNeededSucceeded,
  fetchCart,
  fetchCartFailed,
  fetchCartSucceeded,
  fetchRecentCart,
  incrementItemInCart, updateCartItemFailed, updateCartItemSucceeded,
  updateItemInCart
} from "./cartSlice";
import {asyncScheduler, catchError, map, mergeMap, Observable, of, scheduled} from "rxjs";
import {StoreService} from "../../services/storeService";
import {setAddedCartItem} from "../navigation/navigationSlice";
import {logErrorRx} from "../../utils/logError";
import {StateObservable} from "redux-observable";
import {ShoppingCartModel} from "../../models/shoppingCart";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export const fetchAndUpdateCart = (state$: StateObservable<RootState>, storeId: string): Observable<{ cart: ShoppingCartModel | null, didUpdate: boolean }> => {
  const localCart = state$.value.cart.cartMap[storeId]

  return StoreService.fetchShoppingCart(storeId, localCart)
    .pipe(
      mergeMap(cart => {
        const skuIds = cart?.items.map(i => i.skuId)
          .flatMap(i => i ? [i] : []) ?? []

        return StoreService.fetchAvailableProducts(skuIds)
      }),
      mergeMap(products => {
        return StoreService.updateShoppingCart(storeId, products, localCart)
          .pipe(
            tap({
              next: (cart) => {
                EventLogger.updateGlobalPropertiesWithCart(cart.cart)
              }
            })
          )
      })
    )
}

export const fetchAndUpdateCartIfNeededEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(fetchAndUpdateCartIfNeeded.match),
    mergeMap(action => {
      const localCart = state$.value.cart.cartMap[action.payload]

      return fetchAndUpdateCart(state$, action.payload).pipe(
          map(fetchAndUpdateCartIfNeededSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchAndUpdateCartIfNeededFailed.type,
            payload: { error, storeId: action.payload },
            error: true
          }))
        )
    })
  )
})

export const fetchCartEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(fetchCart.match),
    mergeMap(action => {
      const localCart = state$.value.cart.cartMap[action.payload]
      return StoreService.fetchShoppingCart(action.payload, localCart)
        .pipe(
          tap({
            next: (cart) => {
              EventLogger.updateGlobalPropertiesWithCart(cart)
            }
          }),
          map(fetchCartSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchCartFailed.type,
            payload: { error, storeId: action.payload },
            error: true
          }))
        )
    })
  )
})

export const fetchRecentCartEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(fetchRecentCart.match),
    mergeMap(action => {
      const localCart = Object.values(state$.value.cart.cartMap)
        .filter(c => c.items.length > 0)
        .sort((c1, c2) => (c2.updatedAt?.getTime() ?? 0) - (c1.updatedAt?.getTime() ?? 0))[0]

      return StoreService.fetchMostRecentShoppingCart(localCart)
        .pipe(
          tap({
            next: (cart) => {
              EventLogger.updateGlobalPropertiesWithCart(cart)
            }
          }),
          map(fetchCartSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchCartFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
})

export const addItemToCartEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(addItemToCart.match),
    mergeMap(action => {
      const { item, storeId, quantity, product } = action.payload
      const localCart = state$.value.cart.cartMap[storeId]

      item.quantity = quantity

      return StoreService.addItemToCart(item, storeId, localCart)
        .pipe(
          tap({
            next: () => {
              EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Product Added To Cart', quantity, product }))
            }
          }),
          mergeMap(cart => {
            return scheduled([
              fetchCartSucceeded(cart),
              setAddedCartItem(item),
              updateCartItemSucceeded(item)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: updateCartItemFailed.type,
            payload: { error, item },
            error: true
          }))
        )
    })
  )
})

export const incrementItemInCartEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(incrementItemInCart.match),
    mergeMap(action => {
      const { item, storeId } = action.payload
      const localCart = state$.value.cart.cartMap[storeId]
      item.quantity += 1

      return StoreService.updateItemInCart(item, storeId, localCart)
        .pipe(
          mergeMap(cart => {
            return scheduled([
              fetchCartSucceeded(cart),
              updateCartItemSucceeded(item)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: updateCartItemFailed.type,
            payload: { error, item },
            error: true
          }))
        )
    })
  )
})

export const decrementItemInCartEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(decrementItemInCart.match),
    mergeMap(action => {
      const { item, storeId } = action.payload
      const localCart = state$.value.cart.cartMap[storeId]
      item.quantity -= 1

      if (item.quantity <= 0 && item.skuId) {
        return StoreService.removeItemFromCart(item.skuId, storeId, localCart)
          .pipe(
            tap({
              next: () => {
                item.productId && EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Product Removed From Cart', productId: item.productId }))
              }
            }),
            mergeMap(cart => {
              return scheduled([
                fetchCartSucceeded(cart),
                updateCartItemSucceeded(item)
              ], asyncScheduler)
            }),
            catchError(error => logErrorRx(error)),
            catchError(error => of({
              type: updateCartItemFailed.type,
              payload: { error, item },
              error: true
            }))
          )
      } else {
        return StoreService.updateItemInCart(item, storeId, localCart)
          .pipe(
            mergeMap(cart => {
              return scheduled([
                fetchCartSucceeded(cart),
                updateCartItemSucceeded(item)
              ], asyncScheduler)
            }),
            catchError(error => logErrorRx(error)),
            catchError(error => of({
              type: updateCartItemFailed.type,
              payload: { error, item },
              error: true
            }))
          )
      }
    })
  )
})

export const updateItemInCartEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(updateItemInCart.match),
    mergeMap(action => {
      const { item, storeId, quantity } = action.payload
      const localCart = state$.value.cart.cartMap[storeId]
      item.quantity = quantity

      if (item.quantity <= 0 && item.skuId) {
        return StoreService.removeItemFromCart(item.skuId, storeId, localCart)
          .pipe(
            tap({
              next: () => {
                item.productId && EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Product Removed From Cart', productId: item.productId }))
              }
            }),
            mergeMap(cart => {
              return scheduled([
                fetchCartSucceeded(cart),
                updateCartItemSucceeded(item)
              ], asyncScheduler)
            }),
            catchError(error => logErrorRx(error)),
            catchError(error => of({
              type: updateCartItemFailed.type,
              payload: { error, item },
              error: true
            }))
          )
      } else {
        return StoreService.updateItemInCart(item, storeId, localCart)
          .pipe(
            mergeMap(cart => {
              return scheduled([
                fetchCartSucceeded(cart),
                updateCartItemSucceeded(item)
              ], asyncScheduler)
            }),
            catchError(error => logErrorRx(error)),
            catchError(error => of({
              type: updateCartItemFailed.type,
              payload: { error, item },
              error: true
            }))
          )
      }
    })
  )
})