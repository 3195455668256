import {realmService} from "../realm/realmService";
import {PickupLocationModel} from "../models/pickupLocation";
import {catchError, map, mergeMap, Observable, of, throwError} from "rxjs";
import {deepCopy} from "../utils/deepCopy";
import {ShoppingCartModel} from "../models/shoppingCart";
import {ShoppingCartItemModel} from "../models/shoppingCartItem";
import {v4 as uuid} from 'uuid';
import {ProductModel} from "../models/product";
import {StoreModel} from "../models/store";
import {LocationModel} from "../models/location";
import {StoreApi} from "../apis/storeApi";
import {VendorModel} from "../models/vendor";
import {StoreSectionModel} from "../models/storeSection";
import {DistributionApi} from "../apis/distributionApi";
import {StoreSelectorModel} from "../models/storeSelector";
import {OrderApi} from "../apis/orderApi";
import {RealmServiceError} from "../errors/realmServiceError";
import {DistributionMethod} from "../models/distributionMethod";
import {SalesOrderResponseModel} from "../models/salesOrderResponse";

export const StoreService = {
  observePrimaryPickupLocation(storeId: string): Observable<PickupLocationModel | null> {
    return realmService.query<PickupLocationModel>(PickupLocationModel.schema.name)
      .where({ storeId, isPrimary: true })
      .observeOne()
      .pipe(map(entity => entity && new PickupLocationModel(entity)))
  },
  fetchPrimaryPickupLocation(storeId: string): Observable<PickupLocationModel | null> {
    return realmService.query<PickupLocationModel>(PickupLocationModel.schema.name)
      .where({ storeId, isPrimary: true })
      .fetchOne()
      .pipe(map(entity => entity && new PickupLocationModel(entity)))
  },
  setPrimaryPickupLocation(location: PickupLocationModel, storeId: string): Observable<void> {
    const updateNonPrimaryLocations = realmService.query<PickupLocationModel>(PickupLocationModel.schema.name)
      .where({ storeId })
      .fetchAll()
      .pipe(map(entities => entities.map(e => new PickupLocationModel(e))))
      .pipe(
        mergeMap((locations) => {
          const nonPrimaryLocations = locations.map(l => {
            l.isPrimary = false
            return l
          })
          return realmService.createOrModifyEntities(nonPrimaryLocations)
        })
      )

    return updateNonPrimaryLocations.pipe(
      mergeMap(() => {
        const primaryLocation = new PickupLocationModel(location)
        primaryLocation.isPrimary = true
        return realmService.createOrModifyEntity(primaryLocation)
      }),
      map(() => {}),
    )
  },
  observeMostRecentShoppingCart(localCart?: ShoppingCartModel | null): Observable<ShoppingCartModel | null> {
    return realmService.query<ShoppingCartModel>(ShoppingCartModel.schema.name)
      .observeAll()
      .pipe(
        map(carts => carts.map(c => new ShoppingCartModel(c)).filter(c => c.items.length > 0)),
        map(carts => {
          return carts.sort((c1, c2) => (c2.updatedAt?.getTime() ?? 0) - (c1.updatedAt?.getTime() ?? 0))[0]
        }),
        catchError(error => {
          if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
            return of(localCart ?? null)
          } else {
            return throwError(() => error)
          }
        })
      )
  },
  observeShoppingCart(storeId: string): Observable<ShoppingCartModel | null> {
    return realmService.query<ShoppingCartModel>(ShoppingCartModel.schema.name)
      .where({ storeId })
      .observeOne()
      .pipe(map(cart => cart && new ShoppingCartModel(cart)))
  },
  fetchMostRecentShoppingCart(localCart?: ShoppingCartModel | null): Observable<ShoppingCartModel | null> {
    return realmService.query<ShoppingCartModel>(ShoppingCartModel.schema.name)
      .fetchAll()
      .pipe(
        map(carts => carts.map(c => new ShoppingCartModel(c)).filter(c => c.items.length > 0)),
        map(carts => {
          return carts.sort((c1, c2) => (c2.updatedAt?.getTime() ?? 0) - (c1.updatedAt?.getTime() ?? 0))[0]
        }),
        catchError(error => {
          if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
            return of(localCart ?? null)
          } else {
            return throwError(() => error)
          }
        })
      )
  },
  fetchShoppingCart(storeId: string, localCart?: ShoppingCartModel | null): Observable<ShoppingCartModel | null> {
    return realmService.query<ShoppingCartModel>(ShoppingCartModel.schema.name)
      .where({ storeId })
      .fetchOne()
      .pipe(
        map(cart => cart && new ShoppingCartModel(cart)),
        catchError(error => {
          if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
            return of(localCart ?? null)
          } else {
            return throwError(() => error)
          }
        })
      )
  },
  syncLocalShoppingCart(localCart?: ShoppingCartModel | null): Observable<ShoppingCartModel | null> {
    return realmService.createOrModifyEntity(localCart)
  },
  fetchCartItem(skuId: string, localCart?: ShoppingCartModel): Observable<ShoppingCartItemModel | null> {
    return realmService.query<ShoppingCartModel>(ShoppingCartModel.schema.name)
      .where({ 'items.skuId': skuId })
      .fetchOne()
      .pipe(map(entity => entity && new ShoppingCartModel(entity)))
      .pipe(catchError(error => {
        if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
          return of(localCart ?? null)
        } else {
          return throwError(() => error)
        }
      }))
      .pipe(map(cart => (cart && cart.items.filter(i => i.skuId === skuId)[0]) ?? null))
  },
  addItemToCart(item: ShoppingCartItemModel, storeId: string, localCart?: ShoppingCartModel | null): Observable<ShoppingCartModel | null> {
    return this.fetchShoppingCart(storeId, localCart).pipe(
      mergeMap(cart => {
        if (cart && cart.storeId) {
          let newItems = cart.items
          const matchingItem = newItems.filter(i => i.skuId == item.skuId)[0]

          if (matchingItem) {
            matchingItem.quantity = matchingItem.quantity + item.quantity

            newItems = newItems.map(i => i.skuId === item.skuId ? matchingItem : i)
          } else {
            newItems.push(item)
          }

          const totalPrice = newItems.reduce((sum, item) => sum + (item.price * item.quantity), 0)
          cart.totalPrice = totalPrice
          cart.updatedAt = new Date()
          cart.items = newItems

          return realmService.createOrModifyEntity(cart)
            .pipe(catchError(error => {
              if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
                return of(cart)
              } else {
                return throwError(() => error)
              }
            }))
        } else {
          const totalPrice = item.price * item.quantity
          const cart = new ShoppingCartModel()
          cart.createdAt = new Date()
          cart.currencyCode = item.currencyCode
          cart.shoppingCartId = uuid()
          cart.items = [item]
          cart.storeId = storeId
          cart.totalPrice = totalPrice
          cart.totalDiscount = 0
          cart.updatedAt = new Date()

          return realmService.createOrModifyEntity(cart)
            .pipe(catchError(error => {
              if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
                return of(cart)
              } else {
                return throwError(() => error)
              }
            }))
        }
      })
    )
  },
  addItemsToCart(items: ShoppingCartItemModel[], storeId: string, localCart?: ShoppingCartModel | null): Observable<ShoppingCartModel | null> {
    return this.fetchShoppingCart(storeId, localCart).pipe(
      mergeMap(cart => {
        if (cart && cart.storeId) {
          let newItems = cart.items

          items.forEach(item => {
            const matchingItem = newItems.filter(i => i.skuId == item.skuId)[0]

            if (matchingItem) {
              matchingItem.quantity = matchingItem.quantity + item.quantity

              newItems = newItems.map(i => i.skuId === item.skuId ? matchingItem : i)
            } else {
              newItems.push(item)
            }
          })

          const totalPrice = newItems.reduce((sum, item) => sum + (item.price * item.quantity), 0)
          cart.totalPrice = totalPrice
          cart.updatedAt = new Date()
          cart.items = newItems

          return realmService.createOrModifyEntity(cart)
            .pipe(catchError(error => {
              if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
                return of(cart)
              } else {
                return throwError(() => error)
              }
            }))
        } else {
          const totalPrice = items.reduce((sum, item) => sum + (item.price * item.quantity), 0)
          const cart = new ShoppingCartModel()
          cart.createdAt = new Date()
          cart.currencyCode = items[0].currencyCode
          cart.shoppingCartId = uuid()
          cart.items = items
          cart.storeId = storeId
          cart.totalPrice = totalPrice
          cart.totalDiscount = 0
          cart.updatedAt = new Date()

          return realmService.createOrModifyEntity(cart)
            .pipe(catchError(error => {
              if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
                return of(cart)
              } else {
                return throwError(() => error)
              }
            }))
        }
      })
    )
  },
  updateItemInCart(item: ShoppingCartItemModel, storeId: string, localCart?: ShoppingCartModel | null): Observable<ShoppingCartModel | null> {
    return this.fetchShoppingCart(storeId, localCart).pipe(
      mergeMap(cart => {
        if (!cart) return of(null)
        const lineItems = cart.items ?? []

        const i = lineItems.findIndex(i => i.skuId === item.skuId)

        if (i >= 0) {
          const lineItem = lineItems[i]

          lineItem.availabilityEndDate = item.availabilityEndDate
          lineItem.availabilityStartDate = item.availabilityStartDate

          lineItem.currencyCode = item.currencyCode
          lineItem.itemDescription = item.itemDescription
          lineItem.estimatedDistributionDate = item.estimatedDistributionDate

          lineItem.image = item.image
          lineItem.productId = item.productId
          lineItem.name = item.name
          lineItem.price = item.price
          lineItem.quantity = item.quantity
          lineItem.size = item.size
          lineItem.storeId = item.storeId
          lineItem.updatedAt = new Date()
          lineItem.vendorId = item.vendorId
        }

        const totalPrice = lineItems.reduce((sum, item) => sum + (item.price * item.quantity), 0)
        cart.totalPrice = totalPrice
        cart.updatedAt = new Date()
        cart.items = lineItems

        return realmService.createOrModifyEntity(cart)
          .pipe(catchError(error => {
            if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
              return of(cart)
            } else {
              return throwError(() => error)
            }
          }))
      })
    )
  },
  removeItemFromCart(skuId: string, storeId: string, localCart?: ShoppingCartModel | null): Observable<ShoppingCartModel | null> {
    return this.fetchShoppingCart(storeId, localCart).pipe(
      mergeMap(cart => {
        if (!cart) return of(null)
        let lineItems = cart.items

        const matchingItem = lineItems.filter(i => i.skuId == skuId)[0]
        if (matchingItem) {
          const totalPrice = cart.totalPrice - matchingItem.price * matchingItem.quantity
          cart.totalPrice = totalPrice

          lineItems = lineItems.filter(i => i.skuId !== matchingItem.skuId)
        }

        cart.items = lineItems
        cart.updatedAt = new Date()

        return realmService.createOrModifyEntity(cart)
          .pipe(catchError(error => {
            if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
              return of(cart)
            } else {
              return throwError(() => error)
            }
          }))
      })
    )
  },
  updateShoppingCart(storeId: string, products: ProductModel[], localCart?: ShoppingCartModel | null): Observable<{ cart: ShoppingCartModel | null, didUpdate: boolean }> {
    return this.fetchShoppingCart(storeId, localCart).pipe(
      mergeMap(cart => {
        if (!cart) return of({ cart: null, didUpdate: false })
        let lineItems = cart.items
        let didUpdate = false

        const newLineItems: ShoppingCartItemModel[] = []
        const removedItemIds: string[] = []

        lineItems.forEach(lineItem => {
          const newLineItem = deepCopy(lineItem)

          const product = products.filter(i => i.id == lineItem.productId)[0]
          const skus = product?.skus ?? []
          const containsLineItem = skus.filter(i => i.id == lineItem.skuId).length > 0

          if (containsLineItem) {
            product.selectedSize = lineItem.size
            const selectedOptionValues = new Map<String, String>()
            product.options.forEach(option => {
              const optionId = option.id
              const value = lineItem.optionValues.filter(i => i.value && option.values.includes(i.value))[0]?.value
              if (value && optionId) {
                selectedOptionValues.set(optionId, value)
              }
            })
            product.selectedOptionValues = selectedOptionValues

            newLineItem.availabilityEndDate = product.availabilityEndDate
            newLineItem.availabilityStartDate = product.availabilityStartDate
            newLineItem.currencyCode = product.getSelectedSku()?.currencyCode
            newLineItem.itemDescription = product.description
            newLineItem.estimatedDistributionDate = product.estimatedDistributionDate
            newLineItem.skuId = product.getSelectedSku()?.id
            newLineItem.image = product.image
            newLineItem.productId = product.id
            newLineItem.name = product.name
            newLineItem.price = product.getSelectedSku()?.price ?? 0
            newLineItem.size = product.getSelectedSku()?.size
            newLineItem.storeId = product.storeId
            newLineItem.updatedAt = new Date()
            newLineItem.vendorId = product.vendorId

            if (newLineItem.getIsAvailable()) {
              newLineItems.push(newLineItem)
            } else {
              if (lineItem.skuId) removedItemIds.push(lineItem.skuId)
              didUpdate = true
            }
          } else {
            if (lineItem.skuId) removedItemIds.push(lineItem.skuId)
            didUpdate = true
          }
        })

        const totalPrice = newLineItems.reduce((sum, item) => sum + (item.price * item.quantity), 0)
        cart.totalPrice = totalPrice
        cart.updatedAt = new Date()
        cart.items = newLineItems

        const newCart = this.fetchShoppingCart(storeId, localCart).pipe(map(cart => ({ cart, didUpdate })))

        return realmService.createOrModifyEntity(cart)
          .pipe(catchError(error => {
            if (error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
              return of(cart)
            } else {
              return throwError(() => error)
            }
          }))
          .pipe(mergeMap(() => newCart))
      })
    )
  },
  clearCart(storeId: string): Observable<ShoppingCartModel | null> {
    return this.fetchShoppingCart(storeId)
      .pipe(
        mergeMap(cart => {
          if (cart) {
            cart.items = []
            cart.totalPrice = 0
            cart.updatedAt = new Date()
          }

          return realmService.createOrModifyEntity(cart)
        })
      )
  },
  fetchStoreForLocation(location: LocationModel): Observable<StoreModel> {
    return StoreApi.getStoreForLocation(location.toJson())
  },

  fetchStore(id: string): Observable<StoreModel> {
    return StoreApi.getStore(id)
  },
  fetchVendor(vendorId: string): Observable<VendorModel> {
    return StoreApi.getVendor(vendorId)
  },
  fetchStoreSectionForCategory(categoryId: string): Observable<StoreSectionModel> {
    return StoreApi.getStoreSectionForCategory(categoryId)
  },
  fetchStoreSection(sectionId: string): Observable<StoreSectionModel> {
    return StoreApi.getStoreSection(sectionId)
  },
  fetchPickupLocations(storeId: string): Observable<PickupLocationModel[]> {
    return DistributionApi.getPickupLocations(storeId)
  },
  fetchStoreSelectorForLocation(location: LocationModel): Observable<StoreSelectorModel> {
    return StoreApi.getStoreForSelector(location.toJson())
  },
  fetchProduct(productId: string): Observable<ProductModel> {
    return StoreApi.getProduct(productId)
  },
  fetchAvailableProducts(skuIds: string[]): Observable<ProductModel[]> {
    return StoreApi.getAvailableMenuItemsForSkus(skuIds)
  },
  fetchOrderedProducts(query?: string): Observable<ProductModel[]> {
    return OrderApi.getOrderedProducts(query)
  },
  fetchOrders(pageNumber: number): Observable<SalesOrderResponseModel> {
    return OrderApi.getOrders(pageNumber)
  },
  getDistributionMethod(): DistributionMethod | undefined | null {
    return localStorage.getItem('distribution_method') === 'pickup' ? 'pickup' : 'delivery'
  },
  setDistributionMethod(method?: DistributionMethod) {
    if (!method) {
      localStorage.removeItem('distribution_method')
    } else {
      localStorage.setItem('distribution_method', method)
    }
  },
}