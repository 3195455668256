import {BaseError} from "../../errors/baseError";
import {
  ActivityLevel,
  BodyType,
  DietaryPreference,
  DietGoal,
  FitnessProfileModel,
  Gender,
  HeightUnit,
  WeightUnit
} from "../../models/fitnessProfile";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LocaleUtils} from "../../utils/localeUtils";
import {MacroSurveyValidationError} from "../../errors/macroSurveyValidationError";
import {GenericError} from "../../errors/genericError";
import {MacroSurveyUtils} from "../../utils/macroSurveyUtils";
import {UnitsUtils} from "../../utils/unitsUtils";
import {RootState} from "../../app/store";

export type MacroSurveyQuestion = 'diet_goal' | 'gender' | 'deadline' | 'body_type' | 'activity_level' | 'physical_details' | 'goal_weight' | 'dietary_preference'

export class MacroSurveyAnswers {
  gender?: Gender
  bodyType: BodyType = BodyType.average
  startingWeight?: string
  weightUnit: WeightUnit = WeightUnit.imperial
  heightUnit: HeightUnit = HeightUnit.imperial
  heightFeet?: string
  heightInches?: string
  heightCentimeters?: string
  goal?: DietGoal
  deadlineMonths?: number
  dietaryPreference?: DietaryPreference
  activityLevel?: ActivityLevel
  goalWeight?: string

  constructor(model?: MacroSurveyAnswers) {
    if (!model) return

    this.gender = model.gender
    this.bodyType = model.bodyType
    this.startingWeight = model.startingWeight
    this.weightUnit = model.weightUnit
    this.heightUnit = model.heightUnit
    this.heightFeet = model.heightFeet
    this.heightInches = model.heightInches
    this.heightCentimeters = model.heightCentimeters
    this.goal = model.goal
    this.deadlineMonths = model.deadlineMonths
    this.dietaryPreference = model.dietaryPreference
    this.activityLevel = model.activityLevel
    this.goalWeight = model.goalWeight

    return this
  }
}

export const getFitnessProfileFromAnswers = (answers: MacroSurveyAnswers): FitnessProfileModel => {
  const {
    gender,
    bodyType,
    weightUnit,
    heightUnit,
    heightCentimeters,
    heightFeet,
    heightInches,
    goal,
    dietaryPreference,
    activityLevel
  } = answers

  const startingWeight = UnitsUtils.convertWeight(
    answers.startingWeight ? parseFloat(answers.startingWeight) : undefined,
    weightUnit,
    WeightUnit.imperial
  )

  let height: number | undefined;
  if (heightUnit == HeightUnit.imperial) {
    height = MacroSurveyUtils.getHeightInInchesFromImperial(heightFeet, heightInches)
  } else {
    height = MacroSurveyUtils.getHeightInInchesFromMetric(heightCentimeters)
  }

  let deadlineMonths = goal === DietGoal.maintenance ? 5 : answers.deadlineMonths

  const { idealGoalWeight } = getIdealGoalWeights(answers)

  const goalWeight = goal === DietGoal.maintenance ? startingWeight : answers.goalWeight ? parseFloat(answers.goalWeight) : idealGoalWeight

  const bodyFatConstant = gender == Gender.female ? 0.07 : 0.0
  const bodyFat = (0.1 + (bodyType - 1) * 0.04) + bodyFatConstant

  const fitnessProfile = new FitnessProfileModel()

  fitnessProfile.activityLevel = activityLevel ?? fitnessProfile.activityLevel
  fitnessProfile.bodyType = bodyType
  fitnessProfile.bodyFat = bodyFat
  fitnessProfile.dietaryPreference = dietaryPreference ?? fitnessProfile.dietaryPreference
  fitnessProfile.dietLengthWeeks = deadlineMonths ? deadlineMonths * 4 : fitnessProfile.dietLengthWeeks
  fitnessProfile.gender = gender ?? fitnessProfile.gender
  fitnessProfile.goal = goal ?? fitnessProfile.goal
  fitnessProfile.goalWeight = goalWeight ?? fitnessProfile.goalWeight
  fitnessProfile.height = height ?? fitnessProfile.height
  fitnessProfile.heightUnits = heightUnit
  fitnessProfile.startingWeight = startingWeight ?? fitnessProfile.startingWeight
  fitnessProfile.weightUnits = weightUnit

  return fitnessProfile
}

const getIdealGoalWeights = (answers: MacroSurveyAnswers): { idealGoalWeights?: number[], idealGoalWeight?: number } => {
  const {
    gender,
    bodyType,
    weightUnit,
    heightUnit,
    heightCentimeters,
    heightFeet,
    heightInches,
    goal,
  } = answers

  const startingWeight = UnitsUtils.convertWeight(
    answers.startingWeight ? parseFloat(answers.startingWeight) : undefined,
    weightUnit,
    WeightUnit.imperial
  )

  let height: number | undefined;
  if (heightUnit == HeightUnit.imperial) {
    height = MacroSurveyUtils.getHeightInInchesFromImperial(heightFeet, heightInches)
  } else {
    height = MacroSurveyUtils.getHeightInInchesFromMetric(heightCentimeters)
  }

  let deadlineMonths = goal === DietGoal.maintenance ? 5 : answers.deadlineMonths

  let idealGoalWeight: number | undefined;
  if (
    startingWeight
    && deadlineMonths
    && goal
    && height
    && gender
  ) {
    const idealGoalWeights = MacroSurveyUtils.getGoalWeightsInPounds(
      startingWeight,
      deadlineMonths,
      bodyType,
      goal,
      height,
      gender
    )

    const idealWeight = MacroSurveyUtils.getIdealGoalWeightInPounds(
      height,
      startingWeight,
      deadlineMonths,
      bodyType,
      goal,
      gender
    )

    let initialIndex: number;
    if (goal === DietGoal.cut) {
      initialIndex = Math.floor(idealGoalWeights.length / 2)
    } else {
      let goalWeightIndex = idealGoalWeights.findIndex(value => value === Math.floor(idealWeight))
      goalWeightIndex = goalWeightIndex < 0 ? 0 : goalWeightIndex
      initialIndex = Math.floor(goalWeightIndex * 0.75)
    }

    idealGoalWeight = idealGoalWeights[initialIndex]

    return { idealGoalWeights, idealGoalWeight }
  } else {
    return {}
  }
}

export interface MacroSurveyPhysicalDetails {
  startingWeight?: string
  weightUnit: WeightUnit
  heightUnit: HeightUnit
  heightFeet?: string
  heightInches?: string
  heightCentimeters?: string
  goalWeight?: string
}

interface MacroSurveyState {
  error?: BaseError<any>
  validationError?: MacroSurveyValidationError
  surveyAnswers: MacroSurveyAnswers
  freeTrialLengthDays?: number
  isSubmitting: boolean
  locale?: string
  currentQuestion: MacroSurveyQuestion
  remainingQuestions: MacroSurveyQuestion[]
  allQuestions: MacroSurveyQuestion[]
  idealGoalWeight?: number
  idealGoalWeights: number[]
}

export const macroSurveySlice = createSlice({
  name: 'macro_survey',
  initialState: {
    isSubmitting: false,
    currentQuestion: 'diet_goal',
    surveyAnswers: {
      bodyType: BodyType.average,
      weightUnit: WeightUnit.imperial,
      heightUnit: HeightUnit.imperial
    },
    idealGoalWeights: [],
    remainingQuestions: ['diet_goal', 'deadline', 'gender', 'body_type', 'activity_level', 'physical_details', 'dietary_preference'],
    allQuestions: ['diet_goal', 'deadline', 'gender', 'body_type', 'activity_level', 'physical_details', 'dietary_preference']
  } as MacroSurveyState,
  reducers: {
    reset(state) {
      state.error = undefined
      state.validationError = undefined
      state.isSubmitting = false

      const answers = new MacroSurveyAnswers(state.surveyAnswers)
      const locale = LocaleUtils.getUserLocale()
      if (!state.locale && locale && locale.toLowerCase() === 'en-us') {
        state.locale = locale
        answers.heightUnit = HeightUnit.imperial
        answers.weightUnit = WeightUnit.imperial
      } else if (!state.locale && locale) {
        state.locale = locale
        answers.heightUnit = HeightUnit.metric
        answers.weightUnit = WeightUnit.metric
      }

      state.surveyAnswers = answers
    },
    setFreeTrialLengthDays(state, action: PayloadAction<number | undefined>) {
      state.freeTrialLengthDays = action.payload
    },
    setGender(state, action: PayloadAction<Gender>) {
      const updatedAnswers = new MacroSurveyAnswers(state.surveyAnswers)
      updatedAnswers.gender = action.payload
      state.surveyAnswers = updatedAnswers
    },
    setDietGoal(state, action: PayloadAction<DietGoal>) {
      const updatedAnswers = new MacroSurveyAnswers(state.surveyAnswers)
      updatedAnswers.goal = action.payload
      state.surveyAnswers = updatedAnswers
    },
    setDeadlineMonths(state, action: PayloadAction<number>) {
      const updatedAnswers = new MacroSurveyAnswers(state.surveyAnswers)
      updatedAnswers.deadlineMonths = action.payload
      state.surveyAnswers = updatedAnswers
    },
    setCurrentQuestion(state, action: PayloadAction<MacroSurveyQuestion>) {
      state.currentQuestion = action.payload

      const allQuestions = [...state.allQuestions]
      const index = allQuestions.indexOf(action.payload)
      state.remainingQuestions = allQuestions.splice(index)
    },
    validationFailed(state, action: PayloadAction<Error>) {
      if (action.payload instanceof MacroSurveyValidationError) {
        state.validationError = action.payload as any
      } else if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('MACRO_SURVEY_VALIDATION_ERROR')
        error.localizedMessage = { key: 'macro_survey:macro_survey_alert_message_error_submitting_survey' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    submitSurvey(state) {
      state.isSubmitting = true
    },
    submitSurveySucceeded(state) {
      state.isSubmitting = false

      const answers = new MacroSurveyAnswers()
      const locale = LocaleUtils.getUserLocale()
      if (!state.locale && locale && locale.toLowerCase() === 'en-us') {
        state.locale = locale
        answers.heightUnit = HeightUnit.imperial
        answers.weightUnit = WeightUnit.imperial
      } else if (!state.locale && locale) {
        state.locale = locale
        answers.heightUnit = HeightUnit.metric
        answers.weightUnit = WeightUnit.metric
      }

      state.surveyAnswers = answers
    },
    submitFailed(state, action: PayloadAction<Error>) {
      state.isSubmitting = false

      if (action.payload instanceof BaseError) {
        state.error = action.payload as any
      } else {
        const error = new GenericError('MACRO_SURVEY_ERROR')
        error.localizedMessage = { key: 'macro_survey:macro_survey_alert_message_error_submitting_survey' }
        error.localizedTitle = { key: 'common:common_error_title_something_went_wrong' }
        error.error = action.payload
        state.error = error as any
      }
    },
    setBodyType(state, action: PayloadAction<BodyType>) {
      const updatedAnswers = new MacroSurveyAnswers(state.surveyAnswers)
      updatedAnswers.bodyType = action.payload
      state.surveyAnswers = updatedAnswers
    },
    setActivityLevel(state, action: PayloadAction<ActivityLevel>) {
      const updatedAnswers = new MacroSurveyAnswers(state.surveyAnswers)
      updatedAnswers.activityLevel = action.payload
      state.surveyAnswers = updatedAnswers
    },
    setPhysicalDetails(state, action: PayloadAction<MacroSurveyPhysicalDetails>) {
      const {
        startingWeight,
        weightUnit,
        heightUnit,
        heightFeet,
        heightInches,
        heightCentimeters,
        goalWeight
      } = action.payload

      const updatedAnswers = new MacroSurveyAnswers(state.surveyAnswers)
      updatedAnswers.startingWeight = startingWeight
      updatedAnswers.weightUnit = weightUnit
      updatedAnswers.heightUnit = heightUnit
      updatedAnswers.heightFeet = heightFeet
      updatedAnswers.heightInches = heightInches
      updatedAnswers.heightCentimeters = heightCentimeters
      updatedAnswers.goalWeight = goalWeight

      const { idealGoalWeight, idealGoalWeights } = getIdealGoalWeights(updatedAnswers)

      state.idealGoalWeights = idealGoalWeights ?? state.idealGoalWeights
      state.idealGoalWeight = idealGoalWeight
      state.surveyAnswers = updatedAnswers
    },
    calculateIdealGoalWeights(state, action: PayloadAction<MacroSurveyPhysicalDetails>) {
      const {
        startingWeight,
        weightUnit,
        heightUnit,
        heightFeet,
        heightInches,
        heightCentimeters,
        goalWeight
      } = action.payload

      const updatedAnswers = new MacroSurveyAnswers(state.surveyAnswers)
      updatedAnswers.startingWeight = startingWeight
      updatedAnswers.weightUnit = weightUnit
      updatedAnswers.heightUnit = heightUnit
      updatedAnswers.heightFeet = heightFeet
      updatedAnswers.heightInches = heightInches
      updatedAnswers.heightCentimeters = heightCentimeters
      updatedAnswers.goalWeight = goalWeight

      const { idealGoalWeight, idealGoalWeights } = getIdealGoalWeights(updatedAnswers)

      state.idealGoalWeights = idealGoalWeights ?? state.idealGoalWeights
      state.idealGoalWeight = idealGoalWeight
    },
    setDietaryPreference(state, action: PayloadAction<DietaryPreference>) {
      const updatedAnswers = new MacroSurveyAnswers(state.surveyAnswers)
      updatedAnswers.dietaryPreference = action.payload
      state.surveyAnswers = updatedAnswers
    },
  }
})

export const macroSurveyReducer = macroSurveySlice.reducer

export const {
  setActivityLevel,
  setBodyType,
  setDietaryPreference,
  setDietGoal,
  setDeadlineMonths,
  setPhysicalDetails,
  submitFailed,
  submitSurvey,
  submitSurveySucceeded,
  calculateIdealGoalWeights,
  setGender,
  setCurrentQuestion,
  validationFailed,
  reset,
  setFreeTrialLengthDays
} = macroSurveySlice.actions

export const selectError = (state: RootState) => state.macroSurvey.error
export const selectValidationError = (state: RootState) => state.macroSurvey.validationError
export const selectSurveyAnswers = (state: RootState) => state.macroSurvey.surveyAnswers
export const selectIsSubmitting = (state: RootState) => state.macroSurvey.isSubmitting
export const selectCurrentQuestion = (state: RootState) => state.macroSurvey.currentQuestion
export const selectRemainingQuestions = (state: RootState) => state.macroSurvey.remainingQuestions
export const selectAllQuestions = (state: RootState) => state.macroSurvey.allQuestions
export const selectIdealGoalWeight = (state: RootState) => state.macroSurvey.idealGoalWeight
export const selectIdealGoalWeights = (state: RootState) => state.macroSurvey.idealGoalWeights