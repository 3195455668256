import {RootEpic} from "../../app/store";
import {
  fetchAvailablePromoCodes,
  fetchAvailablePromoCodesSucceeded, savePromoCode, savePromoCodeSucceeded,
  verifyPromoCode, verifyPromoCodeFailed,
  verifyPromoCodeSucceeded
} from "./promoCodeEntrySlice";
import {filter} from "rxjs/operators";
import {asyncScheduler, catchError, map, mergeMap, of, scheduled} from "rxjs";
import {PaymentService} from "../../services/paymentService";
import {logErrorRx} from "../../utils/logError";
import {fetchPaymentMethodsFailed} from "../payment/paymentSelectorSlice";
import {showNotification} from "../navigation/navigationSlice";

export const fetchAvailablePromoCodesEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchAvailablePromoCodes.match),
    mergeMap(action => {
      const storeId = action.payload
      return PaymentService.fetchAvailablePromoCodesForStore(storeId)
        .pipe(
          map(fetchAvailablePromoCodesSucceeded),
          catchError(error => logErrorRx(error)),
          catchError(() => scheduled([fetchAvailablePromoCodesSucceeded([])], asyncScheduler))
        )
    })
  )
}

export const verifyPromoCodeEpic: RootEpic = ((action$, state$) => {
  return action$.pipe(
    filter(verifyPromoCode.match),
    mergeMap(action => {
      return PaymentService.verifyPromoCode(action.payload.code, action.payload.store_id)
        .pipe(
          mergeMap(promoCode => {
            return scheduled([
              savePromoCode(promoCode)
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: verifyPromoCodeFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
})

export const savePromoCodeEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(savePromoCode.match),
    mergeMap(action => {
      PaymentService.setPromoCode(action.payload.id)
      return scheduled([
        savePromoCodeSucceeded(),
        showNotification({
          title: { key: 'checkout:checkout_alert_title_promo_code_success' },
          message: { key: 'checkout:checkout_alert_message_promo_code_success' }
        })
      ], asyncScheduler)
    })
  )
}