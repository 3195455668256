export type AffiliatePayoutMethodType = 'venmo' | 'paypal'
export type AffiliateStatus = 'active' | 'inactive'

export interface AffiliateInfoResponseJson {
  status?: AffiliateStatus
  payout_method?: AffiliatePayoutMethodType
  tier?: number
  code?: string
  app_share_url?: string
  shop_share_url?: string
}

export class AffiliateInfoResponseModel {
  status?: AffiliateStatus
  payoutMethod?: AffiliatePayoutMethodType
  tier: number = 0
  code?: string
  appShareUrl?: string
  shopShareUrl?: string

  static fromJson(json?: AffiliateInfoResponseJson): AffiliateInfoResponseModel | undefined {
    if (!json) return

    const model = new AffiliateInfoResponseModel()
    model.status = json.status
    model.code = json.code
    model.payoutMethod = json.payout_method
    model.tier = json.tier ?? 0
    model.appShareUrl = json.app_share_url
    model.shopShareUrl = json.shop_share_url

    return model
  }
}