import Bugsnag from "@bugsnag/js";
import {NotifiableError} from "@bugsnag/core/types/common";
import {getStore} from "../app/store";
import Event from "@bugsnag/core/types/event";
import {Observable, throwError} from "rxjs";
import {ApiError} from "../errors/apiError";
import {AuthValidationError} from "../errors/authValidationError";
import {PaymentValidationError} from "../errors/paymentValidationError";

export const logError = (error: NotifiableError, onError?: ((event: Event) => void)) => {
  if (error instanceof ApiError && error.code === 'UNAUTHORIZED') {
    return throwError(() => error)
  } else if (error instanceof AuthValidationError) {
    return throwError(() => error)
  } else if (error instanceof PaymentValidationError) {
    return throwError(() => error)
  }

  const user = getStore()?.getState().user.user

  Bugsnag.notify(error, (event) => {
    onError && onError(event)

    event.addMetadata('error', JSON.parse(JSON.stringify(error)))

    if (user) {
      const email = user.email
      const fullName = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName
      event.setUser(user.userId, email, fullName)
    }
  })

  if (process.env.NODE_ENV !== 'production') {
    console.error(error)
  }
}

export const logErrorRx = (error: NotifiableError, onError?: ((event: Event) => void)): Observable<never> => {
  logError(error, onError)
  return throwError(() => error)
}