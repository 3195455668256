import {MessageCampaignJson, MessageCampaignModel} from "./messageCampaign";

export interface MessageCampaignsResponseJson {
  total_results?: number
  campaigns?: MessageCampaignJson[]
}

export class MessageCampaignsResponseModel {
  totalResults: number = 0
  campaigns: MessageCampaignModel[] = []

  static fromJson(json?: MessageCampaignsResponseJson): MessageCampaignsResponseModel | undefined {
    if (!json) return

    const model = new MessageCampaignsResponseModel()
    model.totalResults = json.total_results ?? 0
    model.campaigns = json.campaigns?.map(i => MessageCampaignModel.fromJson(i))
      .flatMap(i => i ? [i] : []) ?? []
    return model
  }
}