import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  deletePaymentMethod,
  fetchPaymentMethods,
  reset,
  selectError,
  selectIsLoading,
  selectItems,
  setDefaultPaymentMethod
} from "../../redux/payment/paymentSelectorSlice";
import {CardModel} from "../../models/card";
import {Container} from "../containers/Container";
import {ArrowNextIcon, CloseIcon, PlusCircleIcon, WarningColorIcon} from "../icons";
import {StoreEmptyStateView} from "../storeFeed/StoreEmptyStateView";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Heading3, HeroHeading2} from "../text";
import {PaymentOptionView} from "./PaymentOptionView";
import Skeleton from "react-loading-skeleton";
import {useRouter} from "next/router";
import {useModalRouting} from "../../utils/useModalRouting";
import {ApiError} from "../../errors/apiError";
import {RealmServiceError} from "../../errors/realmServiceError";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export interface PaymentSelectorViewProps {
  onClose: () => void
}

export const PaymentSelectorView: React.FC<PaymentSelectorViewProps> = ({ onClose }) => {
  const { t } = useTranslation()

  const router = useRouter()
  const { createRoute } = useModalRouting()
  const dispatch = useAppDispatch()
  const items = useAppSelector(selectItems)
  const isLoading = useAppSelector(selectIsLoading)
  const error = useAppSelector(selectError)

  const emptyStateButtonTitle = error instanceof ApiError && error.code === 'UNAUTHORIZED' || error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN'
    ? t('auth:auth_title_sign_in')
    : t('store:store_empty_state_button_try_again')

  const [cardToDelete, setCardToDelete] = useState<CardModel | null>(null)

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Payment Method Selector' }))
  }, [])

  useEffect(() => {
    dispatch(reset())
    dispatch(fetchPaymentMethods())
  }, [dispatch])

  return <div className="max-h-screen md:max-h-screen-7/8 flex flex-col w-full min-h-screen-3/4 md:min-h-screen-2/3 sticky">
    <AlertDialog
      isOpen={!!cardToDelete}
      message={{ key: 'payment:payment_alert_message_confirm_delete_payment_method' }}
      title={{ key: 'payment:payment_alert_title_confirm_delete_payment_method' }}
      buttons={[
        {
          title: { key: 'common:common_title_delete' },
          onClick: () => {
            cardToDelete && dispatch(deletePaymentMethod(cardToDelete))
            setCardToDelete(null)
          },
          style: 'destructive'
        },
        {
          title: { key: 'common:common_title_cancel' },
          onClick: () => setCardToDelete(null),
          style: 'cancel'
        }
      ]}
    />
    <Container className={`overflow-y-scroll max-h-screen w-full flex-1 flex flex-col`}>
      <div className="w-full no-scrollbar flex-1 flex flex-col">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`mt-4 ${error ? 'hidden' : ''}`}>
          <HeroHeading2>{t('payment:payment_title_payment_options')}</HeroHeading2>
        </div>

        <div className={`${error ? '' : 'hidden'} flex-1 flex flex-col justify-center items-center`}>
          <StoreEmptyStateView
            onClick={() => {
              if (error instanceof ApiError && error.code === 'UNAUTHORIZED' || error instanceof RealmServiceError && error.code === 'NOT_LOGGED_IN') {
                router.push(
                  createRoute({ name: 'login', query: { config: 'sign_in' }, reloadOnClose: true }),
                  undefined,
                  { shallow: true }
                );
              } else {
                dispatch(reset())
                dispatch(fetchPaymentMethods())
              }
            }}
            buttonTitle={emptyStateButtonTitle}
            title={error?.localizedTitle}
            subtitle={error?.localizedMessage}
            icon={<WarningColorIcon />}
            isLoading={isLoading}
          />
        </div>

        <div className={`mt-4 ${error ? 'hidden' : ''}`}>
          <ul>
            {items.map((item, index) => {
              return <li key={item.card.id}>
                <PaymentOptionView
                  item={item}
                  isLastItem={false}
                  // isLastItem={index === items.length - 1}
                  onClick={() => {
                    if (!isLoading) dispatch(setDefaultPaymentMethod(item.card))
                  }}
                  onDeleteClick={() => {
                    if (!isLoading) setCardToDelete(item.card)
                  }}
                />
              </li>
            })}
          </ul>
        </div>

        <div className={`flex items-center cursor-pointer ${error ? 'hidden' : ''}`}
             onClick={() => {
               router.push(
                 createRoute({ name: 'payment_entry', returnTo: 'payment_selector' }),
                 undefined,
                 { shallow: true }
               );
             }}
        >
          <div className="flex space-x-4 py-6 flex-grow items-center" onClick={() => {}}>
            {isLoading ? <Skeleton className="!rounded-full !h-7 !w-7" /> : <PlusCircleIcon className="h-6 w-6 text-fg-black" />}

            <Heading3 className="flex-grow">{isLoading ? <Skeleton className="!w-40"/>  : t('payment:payment_title_add_credit_debit_card')}</Heading3>

            <ArrowNextIcon className={`h-3 w-3 ${isLoading ? 'hidden' : ''}`} />
          </div>
        </div>
      </div>
    </Container>
  </div>
}