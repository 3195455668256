import {EntityModel} from "./entityModel";
import {Observable, of} from "rxjs";
import {BaseRealmService} from "../realm/realmService";
import {ObjectSchema} from "mongodb-realm";

export type ImageModificationType = 'none' | 'background_removal' | string

export interface ImageJson {
  modifications?: ImageModificationType[]
  original_file_name?: string
  public_id?: string
  url?: string
}

export class ImageModel implements EntityModel<ImageModel> {
  public static schema: ObjectSchema = {
    name: 'ImageEntity',
    embedded: true,
    properties: {
      heightPx: 'int?',
      modifications: 'string[]',
      originalFileName: 'string?',
      publicId: 'string?',
      url: 'string?',
      widthPx: 'int?',
    },
  }

  schema = ImageModel.schema

  heightPx?: number;
  modifications: ImageModificationType[] = [];
  originalFileName?: string;
  publicId?: string;
  url?: string;
  widthPx?: number;

  constructor(entity?: ImageModel | null) {
    if (!entity) return

    this.heightPx = entity.heightPx
    this.modifications = entity.modifications ?? []
    this.originalFileName = entity.originalFileName
    this.publicId = entity.publicId
    this.url = entity.url
    this.widthPx = entity.widthPx

    return this
  }

  static fromJson(json?: ImageJson): ImageModel | undefined {
    if (!json) return undefined

    const model = new ImageModel()

    model.modifications = json.modifications ?? []
    model.originalFileName = json.original_file_name
    model.publicId = json.public_id
    model.url = json.url

    return model
  }

  toJson(): ImageJson {
    return {
      modifications: this.modifications,
      original_file_name: this.originalFileName,
      public_id: this.publicId,
      url: this.url
    }
  }

  toEntityObservable(realmService: BaseRealmService): Observable<ImageModel> {
    return of(this)
  }
}