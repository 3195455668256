import React, {useRef, useState} from "react";
import {Modal} from "../modals/Modal";
import {PaymentEntryView} from "./PaymentEntryView";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useModalRouting} from "../../utils/useModalRouting";

export const PaymentEntryModal: React.FC<any> = () => {
  const { onClose, isOpen } = useModalRouting();

  const [stripePromise] = useState(() => loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!))

  return <Modal
    className="md:max-w-2xl"
    onClose={async () => {
      await onClose()
    }}
    isOpen={isOpen('payment_entry')}>
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            src: 'url(\'/fonts/Gilroy-Medium.woff\') format(\'woff\')',
            family: 'Gilroy',
            style: 'normal',
            weight: '400'
          },
          {
            src: 'url(\'/fonts/Gilroy-SemiBold.woff\') format(\'woff\')',
            family: 'Gilroy',
            style: 'normal',
            weight: '600'
          },
          {
            src: 'url(\'/fonts/Gilroy-Bold.woff\') format(\'woff\')',
            family: 'Gilroy',
            style: 'normal',
            weight: '700'
          }
        ]
      }}
    >
      <PaymentEntryView onClose={() => { onClose() }} />
    </Elements>
  </Modal>
}