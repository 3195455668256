import {Heading1, LocalizedTextLabel, Paragraph} from "../text";
import {Button} from "../buttons/Button";
import React, {ReactElement} from "react";
import {LocalizationInfo} from "react-i18next";
import {useTranslation} from "next-i18next";
import {BaseError} from "../../errors/baseError";

export interface StoreFeedEmptyStateViewProps {
  title?: string | LocalizationInfo
  subtitle?: string | LocalizationInfo
  buttonTitle?: string | LocalizationInfo
  error?: BaseError<any>
  onClick?: () => void
  icon: React.ReactNode
  isLoading: boolean
  className?: string
}

export const StoreEmptyStateView: React.FC<StoreFeedEmptyStateViewProps> = ({ title, className, isLoading, buttonTitle, subtitle, onClick, icon, error }) => {
  const iconProps = icon && (icon as ReactElement)?.props
  const iconClassName = `${iconProps ? iconProps.className : ''} h-32 w-32 lg:h-36 lg:w-36`
  const { t } = useTranslation()

  return <div className={`flex flex-col h-full justify-center ${className ? className : ''}`}>
    <div className="flex flex-col space-y-6 lg:space-y-8 items-center">
      {icon && React.cloneElement(icon as React.ReactElement, { className: iconClassName })}
      <Heading1 className="text-center">
        <LocalizedTextLabel t={t} text={error?.localizedTitle ?? title}/>
      </Heading1>
      <Paragraph className="text-fg-gray-dark text-center">
        <LocalizedTextLabel t={t} text={error?.localizedMessage ?? subtitle} />
      </Paragraph>
      <Button
        size="lg"
        onClick={onClick}
        isLoading={isLoading}
      >
        <LocalizedTextLabel t={t} text={buttonTitle} />
      </Button>
    </div>
  </div>
}