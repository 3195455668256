import {NutritionTargetModel} from "../models/nutritionTarget";
import Moment from "moment";
import {NutritionProtocolModel} from "../models/nutritionProtocol";
import {RRule, RRuleSet} from "rrule";

export const NutritionProtocolUtils = {
  createTargetsFromProtocols(
    nutritionProtocols: NutritionProtocolModel[],
    date: Date
  ): NutritionTargetModel[] {
    return nutritionProtocols.map(nutritionProtocol => {
      const start = Moment(date).startOf('day').toDate()
      const end = Moment(date).endOf('day').toDate()

      const rrule = nutritionProtocol.getRrule()
      const frequency = nutritionProtocol.getRrule().options.freq
      const copiedProtocol = new NutritionProtocolModel(nutritionProtocol)

      if (copiedProtocol.getRrule().options.until && frequency) {
        const updatedRrule = new RRule({
          freq: frequency,
          dtstart: rrule.options.dtstart,
          until: nutritionProtocol.endDate
        })

        const rruleSet = new RRuleSet()
        rruleSet.rrule(updatedRrule)

        copiedProtocol.rrule = rruleSet.toString()
      }

      const dates = copiedProtocol.getRrule().between(start, end)

      return dates.map(date => {
        return new NutritionTargetModel({
          startDate: Moment(date).startOf('day').toDate(),
          endDate: Moment(date).endOf('day').toDate(),
          parentProtocol: copiedProtocol
        })
      })
    }).flat()
  }
}