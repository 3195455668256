import {AnyAction, combineReducers, configureStore, Dispatch, EnhancedStore} from '@reduxjs/toolkit';
import {combineEpics, createEpicMiddleware, Epic} from "redux-observable";
import {authReducer} from "../redux/auth/authSlice";
import {
  authenticateSessionEpic, loginWithAppleEpic,
  loginWithEmailEpic, loginWithFacebookEpic,
  logoutEpic,
  sendPasswordResetEpic,
  signUpEpic
} from "../redux/auth/authEpics";
import {locationSearchReducer} from "../redux/location/locationSearchSlice";

import {
  fetchAutocompletePredictionsEpic,
  fetchCurrentLocationEpic,
  fetchLocationFromPlaceIdEpic
} from "../redux/location/locationSearchEpics";
import {navigationReducer} from "../redux/navigation/navigationSlice";
import {userReducer} from "../redux/user/userSlice";
import {storeFeedReducer} from "../redux/storeFeed/storeFeedSlice";
import {fetchStoreFeedEpic} from "../redux/storeFeed/storeFeedEpics";
import {LocationModel} from "../models/location";
import {Context, createWrapper, HYDRATE, MakeStore} from "next-redux-wrapper";
import {createRouterMiddleware, initialRouterState, routerReducer} from 'connected-next-router'
import Router from "next/router";
import {NextPageContext} from "next";
import {AppContext} from "next/app";
import {locationEntryReducer} from "../redux/location/locationEntrySlice";
import {
  deleteLocationEpic,
  fetchAndSaveLocationEpic,
  fetchSavedLocationsEpic,
  fetchSearchResultsEpic,
  savePrimaryLocationEpic
} from "../redux/location/locationEntryEpics";
import {storeReducer} from "../redux/store/storeSlice";
import {cartReducer} from "../redux/cart/cartSlice";
import {ShoppingCartModel} from "../models/shoppingCart";
import {
  addItemToCartEpic,
  decrementItemInCartEpic, fetchAndUpdateCartIfNeededEpic,
  fetchCartEpic, fetchRecentCartEpic,
  incrementItemInCartEpic,
  updateItemInCartEpic
} from "../redux/cart/cartEpics";
import {fetchStoreEpic} from "../redux/store/storeEpics";
import {createTransform, persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {PersistConfig} from "redux-persist/es/types";
import {
  fetchStoreSectionForCategoryEpic,
  fetchStoreSectionForIdEpic
} from "../redux/storeSectionDetail/storeSectionDetailEpics";
import {storeSectionDetailReducer} from "../redux/storeSectionDetail/storeSectionDetailSlice";
import {productReducer} from "../redux/product/productSlice";
import {fetchProductEpic, updateCartWithItemEpic} from "../redux/product/productEpics";
import {paymentSelectorReducer} from "../redux/payment/paymentSelectorSlice";
import {
  deletePaymentMethodEpic,
  fetchPaymentMethodsEpic,
  setDefaultPaymentMethodEpic
} from "../redux/payment/paymentSelectorEpics";
import {paymentEntryReducer} from "../redux/payment/paymentEntrySlice";
import {addPaymentSourceEpic} from "../redux/payment/paymentEntryEpics";
import {contactInfoEntryReducer} from "../redux/user/contactInfoEntrySlice";
import {fetchUserEpic} from "../redux/user/userEpics";
import {setContactInfoEpic, setCustomerEpic} from "../redux/user/contactInfoEntryEpics";
import {checkoutReducer} from "../redux/checkout/checkoutSlice";
import {
  createSalesOrderEpic,
  fetchCheckoutDataEpic,
  optionItemSelectedEpic,
  removePromoCodeEpic,
  updateCheckoutDataEpic
} from "../redux/checkout/checkoutEpics";
import {promoCodeEntryReducer} from "../redux/promoCodeEntry/promoCodeEntrySlice";
import {
  fetchAvailablePromoCodesEpic,
  savePromoCodeEpic,
  verifyPromoCodeEpic
} from "../redux/promoCodeEntry/promoCodeEntryEpics";
import {orderListReducer} from "../redux/orderList/orderListSlice";
import {fetchOrdersEpic} from "../redux/orderList/orderListEpics";
import {fetchOrderEpic} from "../redux/orderDetail/orderDetailEpics";
import {orderDetailReducer} from "../redux/orderDetail/orderDetailSlice";
import {affiliateReducer} from "../redux/affiliate/affiliateSlice";
import {
  fetchAffiliateDataEpic,
  fetchAffiliateInfoEpic,
  fetchAffiliatePromoCodesEpic, onboardAffiliateEpic,
  setDateRangeEpic
} from "../redux/affiliate/affiliateEpics";
import {affiliateAdminReducer} from "../redux/affiliate/affiliateAdminSlice";
import {fetchAffiliatesEpic, updateAffiliateStatusEpic} from "../redux/affiliate/affiliateAdminEpics";
import {promptListReducer} from "../redux/prompt/promptListSlice";
import {fetchPromptsEpic} from "../redux/prompt/promptListEpics";
import {promptDetailReducer} from "../redux/prompt/promptDetailSlice";
import {
  createPromptEpic,
  deletePromptEpic,
  fetchPromptEpic,
  updatePromptEpic
} from "../redux/prompt/promptDetailEpic";
import {emailListReducer} from "../redux/email/emailListSlice";
import {deleteEmailFromListEpic, fetchEmailsEpic} from "../redux/email/emailListEpics";
import {cohortReducer} from "../redux/cohort/cohortSlice";
import {fetchCohortsEpic} from "../redux/cohort/cohortEpics";
import {emailDetailReducer} from "../redux/email/emailDetailSlice";
import {createEmailEpic, deleteEmailEpic, fetchEmailEpic, updateEmailEpic} from "../redux/email/emailDetailEpics";
import {campaignListReducer} from "../redux/campaign/campaignListSlice";
import {
  activateCampaignFromListEpic,
  deleteCampaignFromListEpic,
  fetchCampaignsEpic
} from "../redux/campaign/campaignListEpics";
import {campaignDetailReducer} from "../redux/campaign/campaignDetailSlice";
import {
  createCampaignEpic,
  deleteCampaignEpic,
  fetchCampaignEpic,
  updateCampaignEpic
} from "../redux/campaign/campaignDetailEpics";
import {pushNotificationListReducer} from "../redux/pushNotification/pushNotificationListSlice";
import {
  deletePushNotificationFromListEpic,
  fetchPushNotificationsEpic
} from "../redux/pushNotification/pushNotificationListEpics";
import {pushNotificationDetailReducer} from "../redux/pushNotification/pushNotificationDetailSlice";
import {
  createPushNotificationEpic, deletePushNotificationEpic,
  fetchPushNotificationEpic, updatePushNotificationEpic
} from "../redux/pushNotification/pushNotificationDetailEpics";
import {MacroSurveyAnswers, macroSurveyReducer} from "../redux/survey/macroSurveySlice";
import {
  loginWithAppleAndSubmitSurveyEpic,
  loginWithEmailAndSubmitSurveyEpic, loginWithFacebookAndSubmitSurveyEpic,
  setAnswerEpic,
  signUpWithEmailAndSubmitSurveyEpic, submitSurveyEpic
} from "../redux/survey/macroSurveyEpics";
import {macroPlanReducer} from "../redux/macroPlan/macroPlanSlice";
import {fetchNutritionPlanEpic} from "../redux/macroPlan/macroPlanEpics";
import {subscriptionReducer} from "../redux/upgrade/subscriptionSlice";
import {
  cancelSubscriptionEpic,
  createCheckoutSessionEpic,
  fetchSubscriptionsEpic,
  fetchUserSubscriptionEpic, setFreeTrialCollabExpDateEpic
} from "../redux/upgrade/subscriptionEpics";
import {onboardingReducer} from "../redux/onboarding/onboardingSlice";
import {fetchOnboardingDataEpics} from "../redux/onboarding/onboardingEpics";

const UserTransform = createTransform(
  (inboundState: any, key) => {
    return inboundState;
  },
  (outboundState: any, key) => {
    const state = { ...outboundState }
    if (outboundState.primaryLocation) {
      state.primaryLocation = new LocationModel(outboundState.primaryLocation)
    }
    return state;
  },
  { whitelist: ['user'] }
);

const MacroSurveyTransform = createTransform(
  (inboundState: any, key) => {
    return inboundState;
  },
  (outboundState: any, key) => {
    const state = { ...outboundState }
    if (outboundState.surveyAnswers) {
      state.surveyAnswers = new MacroSurveyAnswers(outboundState.surveyAnswers)
    }
    return state;
  },
  { whitelist: ['macroSurvey'] }
);

const CartTransform = createTransform(
  (inboundState: any, key) => {
    return inboundState;
  },
  (outboundState: any, key) => {
    const cartMap: { [storeId: string]: ShoppingCartModel } = {}
    for (let storeId in outboundState.cartMap) {
      const cart = outboundState.cartMap[storeId]
      if (cart) cartMap[storeId] = new ShoppingCartModel(cart)
    }

    return {
      isUpdatingMap: {},
      isLoadingMap: {},
      errorMap: {},
      productUpdatingMap: {},
      didUpdateCartMap: {},
      cartMap
    };
  },
  { whitelist: ['cart'] }
);

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  transforms: [UserTransform, CartTransform, MacroSurveyTransform],
  whitelist: ['cart', 'user', 'macroSurvey']
}

const combinedReducer = combineReducers({
  auth: authReducer,
  locationEntry: locationEntryReducer,
  locationSearch: locationSearchReducer,
  user: userReducer,
  navigation: navigationReducer,
  storeFeed: storeFeedReducer,
  store: storeReducer,
  storeSectionDetail: storeSectionDetailReducer,
  cart: cartReducer,
  product: productReducer,
  router: routerReducer,
  paymentSelector: paymentSelectorReducer,
  paymentEntry: paymentEntryReducer,
  promoCodeEntry: promoCodeEntryReducer,
  contactInfoEntry: contactInfoEntryReducer,
  checkout: checkoutReducer,
  orderList: orderListReducer,
  orderDetail: orderDetailReducer,
  affiliate: affiliateReducer,
  affiliateAdmin: affiliateAdminReducer,
  promptList: promptListReducer,
  promptDetail: promptDetailReducer,
  emailList: emailListReducer,
  emailDetail: emailDetailReducer,
  cohort: cohortReducer,
  campaignList: campaignListReducer,
  campaignDetail: campaignDetailReducer,
  pushNotificationList: pushNotificationListReducer,
  pushNotificationDetail: pushNotificationDetailReducer,
  macroSurvey: macroSurveyReducer,
  macroPlan: macroPlanReducer,
  subscription: subscriptionReducer,
  onboarding: onboardingReducer
});

const persistedReducer = persistReducer(persistConfig, combinedReducer)

const rootEpic = combineEpics(
  // auth
  signUpEpic,
  loginWithEmailEpic,
  loginWithFacebookEpic,
  authenticateSessionEpic,
  sendPasswordResetEpic,
  logoutEpic,
  loginWithAppleEpic,

  // user
  fetchUserEpic,

  // contactInfoEntry
  setContactInfoEpic,
  setCustomerEpic,

  // locationEntry
  fetchSavedLocationsEpic,
  savePrimaryLocationEpic,
  deleteLocationEpic,
  fetchSearchResultsEpic,
  fetchAndSaveLocationEpic,

  // locationSearch
  fetchLocationFromPlaceIdEpic,
  fetchAutocompletePredictionsEpic,
  fetchCurrentLocationEpic,

  // storeFeed
  fetchStoreFeedEpic,
  fetchStoreEpic,

  // storeSection
  fetchStoreSectionForCategoryEpic,
  fetchStoreSectionForIdEpic,

  // cart
  fetchCartEpic,
  fetchRecentCartEpic,
  addItemToCartEpic,
  incrementItemInCartEpic,
  decrementItemInCartEpic,
  updateItemInCartEpic,
  fetchAndUpdateCartIfNeededEpic,

  // product
  fetchProductEpic,
  updateCartWithItemEpic,

  // payment
  fetchPaymentMethodsEpic,
  setDefaultPaymentMethodEpic,
  deletePaymentMethodEpic,
  addPaymentSourceEpic,

  // checkout
  fetchCheckoutDataEpic,
  optionItemSelectedEpic,
  updateCheckoutDataEpic,
  createSalesOrderEpic,

  // promoCodeEntry
  fetchAvailablePromoCodesEpic,
  verifyPromoCodeEpic,
  savePromoCodeEpic,
  removePromoCodeEpic,

  // orderList
  fetchOrdersEpic,

  // orderDetail
  fetchOrderEpic,

  // affiliate
  fetchAffiliateInfoEpic,
  fetchAffiliateDataEpic,
  setDateRangeEpic,
  fetchAffiliatePromoCodesEpic,
  onboardAffiliateEpic,

  // affiliateAdmin
  fetchAffiliatesEpic,
  updateAffiliateStatusEpic,

  // promptList
  fetchPromptsEpic,

  // promptDetail
  fetchPromptEpic,
  createPromptEpic,
  updatePromptEpic,
  deletePromptEpic,

  // emailList
  fetchEmailsEpic,
  deleteEmailFromListEpic,

  // emailDetail
  fetchEmailEpic,
  createEmailEpic,
  updateEmailEpic,
  deleteEmailEpic,

  // cohort
  fetchCohortsEpic,

  // campaignList
  fetchCampaignsEpic,
  deleteCampaignFromListEpic,
  activateCampaignFromListEpic,

  // campaignDetail
  fetchCampaignEpic,
  createCampaignEpic,
  updateCampaignEpic,
  deleteCampaignEpic,

  // pushNotificationList
  fetchPushNotificationsEpic,
  deletePushNotificationFromListEpic,

  // pushNotificationDetail
  fetchPushNotificationEpic,
  createPushNotificationEpic,
  updatePushNotificationEpic,
  deletePushNotificationEpic,

  // macroSurvey
  setAnswerEpic,
  submitSurveyEpic,
  signUpWithEmailAndSubmitSurveyEpic,
  loginWithEmailAndSubmitSurveyEpic,
  loginWithFacebookAndSubmitSurveyEpic,
  loginWithAppleAndSubmitSurveyEpic,

  // macroPlan
  fetchNutritionPlanEpic,

  // subscription
  fetchSubscriptionsEpic,
  createCheckoutSessionEpic,
  cancelSubscriptionEpic,
  setFreeTrialCollabExpDateEpic,
  fetchUserSubscriptionEpic,

  // onboarding
  fetchOnboardingDataEpics,
);

const reducer: typeof combinedReducer = (state: any, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }
    if (typeof window !== 'undefined' && state?.router) {
      // preserve router value on client side navigation
      nextState.router = state.router
    }
    return nextState
  } else {
    return persistedReducer(state, action)
  }
}

let rootStore: EnhancedStore<RootState, any, any> | null = null
export const getStore = () => rootStore

export const makeStore: MakeStore<EnhancedStore> = (context: Context) => {
  const isServer = typeof window === 'undefined';

  const routerMiddleware = createRouterMiddleware()
  const asPath = (context as NextPageContext)?.asPath ?? (context as AppContext)?.ctx?.asPath ?? Router.router?.asPath
  let preloadedState
  if (asPath) {
    preloadedState = {
      router: initialRouterState(asPath)
    }
  }

  const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();

  if (!isServer) {
    const store = configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: false, serializableCheck: false})
        .concat(epicMiddleware)
        .concat(routerMiddleware),
      preloadedState,
      devTools: process.env.NODE_ENV !== 'production'
    })

    epicMiddleware.run(rootEpic);
    (store as any).__persistor = persistStore(store)

    rootStore = store

    return store
  } else {
    const store = configureStore({
      reducer,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: false, serializableCheck: false})
        .concat(epicMiddleware)
        .concat(routerMiddleware),
      preloadedState,
      devTools: process.env.NODE_ENV !== 'production'
    })

    epicMiddleware.run(rootEpic);

    rootStore = store

    return store
  }
};

export const wrapper = createWrapper<EnhancedStore>(makeStore, {
  debug: process.env.NODE_ENV !== 'production'
});

export type AppDispatch = Dispatch<AnyAction>;
export type RootState = ReturnType<typeof reducer>;
export type RootEpic = Epic<AnyAction, AnyAction, RootState>;