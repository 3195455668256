import {RootEpic} from "../../app/store";
import {filter, mergeMap} from "rxjs/operators";
import {asyncScheduler, catchError, throwError, from, Observable, of, scheduled} from "rxjs";
import {addPaymentSource, addPaymentSourceFailed, addPaymentSourceSucceeded} from "./paymentEntrySlice";
import {PaymentService} from "../../services/paymentService";
import {PaymentValidationError} from "../../errors/paymentValidationError";
import {logErrorRx} from "../../utils/logError";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export const addPaymentSourceEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(addPaymentSource.match),
    mergeMap(action => {
      const { stripe, element } = action.payload
      const user = state$.value.user.user
      const billingAddress = state$.value.paymentEntry.billingAddress

      const addressLine1 = billingAddress?.getFullStreetAddress() ?? undefined
      const addressLine2 = billingAddress?.subpremise
      const city = billingAddress?.locality
      const state = billingAddress?.administrativeArea
      const zip = billingAddress?.postalCode
      const countryCode = billingAddress?.countryCode

      const tokenObservable: Observable<string> = from(stripe.createToken(element, {
        name: user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : user?.firstName,
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_city: city,
        address_state: state,
        address_zip: zip,
        address_country: countryCode
      }))
        .pipe(
          mergeMap(result => {
            if (result.error && result.error.code && result.error.message) {
              const error = new PaymentValidationError(result.error.code)
              error.localizedMessage = result.error.message

              return throwError(() => error)
            } else if (!billingAddress) {
              return throwError(() => new PaymentValidationError("INVALID_ADDRESS"))
            } else if (result.token) {
              return of(result.token.id)
            } else {
              return throwError(() => result.error)
            }
          })
        )

      return tokenObservable
        .pipe(
          mergeMap(token => PaymentService.addPaymentSource(token)),
          mergeMap(() => {
            EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Payment Info Added' }))

            return scheduled([
              addPaymentSourceSucceeded()
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: addPaymentSourceFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}
