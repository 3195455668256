import {useRouter} from "next/router";
import {useCallback} from "react";
import stringify from "qs-stringify";
import {parse, ParsedUrlQuery} from "querystring";

const MODAL_NAME_PREFIX = '__FG_MODAL_NAME_'

export type ModalName = 'payment_entry'
  | 'order_detail'
  | 'contact_info_entry'
  | 'cart'
  | 'payment_selector'
  | 'product_detail'
  | 'promo_code_entry'
  | 'location_entry'
  | 'login'
  | 'affiliate_onboard'
  | 'prompt_detail'
  | 'email_detail'
  | 'campaign_detail'
  | 'push_notification_detail'

export const useModalRouting = () => {
  const router = useRouter()

  const returnUrl = useCallback((return_to?: string) => {
    const routerQuery: any = {}

    for (let queryKey in router.query) {
      if (!queryKey.includes(MODAL_NAME_PREFIX) && !queryKey.includes('return_to') && !queryKey.includes('reload') && !queryKey.includes('return_as')) {
        routerQuery[queryKey] = router.query[queryKey]
      }
    }

    const url = {
      pathname: return_to && return_to.includes('/') ? return_to : router.pathname,
      query: Object.assign({}, routerQuery, return_to && !return_to.includes('/') ? parse(return_to) : {})
    }

    return {
      url,
      reload: router.query.reload
    }
  }, [router.asPath])

  const onClose = () => {
    const timeout = window.setTimeout(async () => {
      const { url, reload } = returnUrl(router.query.return_to as string)
      await router.replace(
        url,
        router.query.return_as as string,
        { shallow: true }
      )

      if (reload) {
        await router.reload()
      }

      window.clearTimeout(timeout)
    }, 50)
  }

  interface CreateRouteProps {
    name: ModalName
    rootPath?: string,
    query?: { [key: string]: string | undefined | null },
    returnTo?: { name: ModalName, as?: string, query?: { [key: string]: string | undefined | null } } | string,
    reloadOnClose?: boolean
  }

  const createRoute = useCallback((params: CreateRouteProps) => {
    const routerQuery: ParsedUrlQuery = {}

    for (let queryKey in router.query) {
      if (!queryKey.includes(MODAL_NAME_PREFIX)) {
        routerQuery[queryKey] = router.query[queryKey]
      }
    }

    return (params.rootPath ?? router.pathname) + '?'
      + stringify(Object.assign(
        {},
        { [MODAL_NAME_PREFIX + params.name]: 'open' },
        routerQuery,
        params.query,
        typeof params.returnTo !== 'string' && params.returnTo
          ? { return_to: stringify({ [MODAL_NAME_PREFIX + params.returnTo.name]: 'open' }) }
          : params.returnTo ? params.returnTo.includes('/') ? { return_to: params.returnTo } : { return_to: stringify({ [MODAL_NAME_PREFIX + params.returnTo]: 'open' }) } : {},
        typeof params.returnTo !== 'string' && params.returnTo?.as
          ?
          { return_as: params.returnTo?.as  }
          :
          {},
        typeof params.returnTo !== 'string' && params.returnTo?.query
          ? params.returnTo.query
          : {},
        params.reloadOnClose ? { reload: true } : {},
        {}
      ))
  }, [router.asPath])

  const isOpen = useCallback((name?: ModalName) => {
    if (!name) {
      return !!Object.keys(router.query).filter(k => k.includes(MODAL_NAME_PREFIX))[0]
    } else {
      return !!router.query[MODAL_NAME_PREFIX + name]
    }
  }, [router.query])

  return { onClose, createRoute, isOpen }
}
