import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  onboardAffiliate,
  resetOnboardModal,
  selectIsOnboarding,
  selectOnboardError,
  selectOnboardValidationError,
  selectShouldCloseOnboardModal
} from "../../redux/affiliate/affiliateSlice";
import {LocalizationInfo} from "react-i18next";
import {AsYouType, CountryCode, parsePhoneNumber} from "libphonenumber-js";
import {selectPrimaryLocation, selectUser} from "../../redux/user/userSlice";
import {AffiliatePayoutMethodType} from "../../models/affiliateInfoResponse";
import {AlertDialog} from "../dialogs/AlertDialog";
import {Container} from "../containers/Container";
import {CloseIcon} from "../icons";
import {Heading4, HeroHeading2, TextStyles} from "../text";
import {BodyInput} from "../inputs";
import {PhoneNumberUtils} from "../../utils/phoneNumberUtils";
import {Button} from "../buttons/Button";

export interface AffiliateOnboardViewProps {
  onClose: () => void
}

export const AffiliateOnboardView: React.FC<AffiliateOnboardViewProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const shouldClose = useAppSelector(selectShouldCloseOnboardModal)
  const isOnboarding = useAppSelector(selectIsOnboarding)
  const error = useAppSelector(selectOnboardError)
  const validationError = useAppSelector(selectOnboardValidationError)

  const user = useAppSelector(selectUser)
  const primaryLocation = useAppSelector(selectPrimaryLocation)

  const payoutMethods: AffiliatePayoutMethodType[] = ['paypal', 'venmo']

  const [phoneNumberInputValue, setPhoneNumberInputValue] = useState(user?.phoneNumber ?? "");
  const [countryCode, setCountryCode] = useState<CountryCode>( primaryLocation?.address?.countryCode as CountryCode ?? 'US');
  const [payoutMethod, setPayoutMethod] = useState<AffiliatePayoutMethodType>('paypal');

  const [emailValidation, setEmailValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() => () => true)
  const [phoneValidation, setPhoneValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() =>() => true)
  const [codeValidation, setCodeValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() =>() => true)
  const [firstNameValidation, setFirstNameValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() =>() => true)
  const [lastNameValidation, setLastNameValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() =>() => true)

  useEffect(() => {
    let parsedCountryCode: CountryCode | "" | undefined
    let formattedPhoneNumber: string = ""
    const fallbackCountryCode = primaryLocation?.address?.countryCode as CountryCode ?? 'US'

    try {
      const parsedNumber = user?.phoneNumber ? parsePhoneNumber(user.phoneNumber, fallbackCountryCode) : undefined
      formattedPhoneNumber = parsedNumber ? parsedNumber.formatNational() : formattedPhoneNumber
      parsedCountryCode = user?.phoneNumber && parsePhoneNumber(user.phoneNumber, fallbackCountryCode).country
    } catch (e) {

    }

    setPhoneNumberInputValue(formattedPhoneNumber)
    setCountryCode(parsedCountryCode ? parsedCountryCode : fallbackCountryCode)
  }, [])

  useEffect(() => {
    dispatch(resetOnboardModal())
  }, [dispatch])

  useEffect(() => {
    if (shouldClose) {
      onClose()
      dispatch(resetOnboardModal())
    }
  }, [shouldClose, onClose, dispatch])

  useEffect(() => {
    setEmailValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "INVALID_EMAIL":
          return validationError?.localizedMessage
        case "EMAIL_IS_EMPTY":
          return validationError?.localizedMessage
        default:
          break;
      }
    })

    setPhoneValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "PHONE_IS_EMPTY":
          return validationError?.localizedMessage
        case "INVALID_PHONE_NUMBER":
          return validationError?.localizedMessage
        default:
          break
      }
    })

    setFirstNameValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "FIRST_NAME_IS_EMPTY":
          return validationError?.localizedMessage
        default:
          break
      }
    })

    setLastNameValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "LAST_NAME_IS_EMPTY":
          return validationError?.localizedMessage
        default:
          break
      }
    })

    setCodeValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "CODE_IS_EMPTY":
          return validationError?.localizedMessage
        case "INVALID_CODE_LENGTH":
          return validationError?.localizedMessage
        default:
          break
      }
    })
  }, [validationError])

  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const codeRef = useRef<HTMLInputElement>(null)
  const payoutEmailRef = useRef<HTMLInputElement>(null)
  const payoutPhoneRef = useRef<HTMLInputElement>(null)

  const handleSubmit = () => {
    const firstName = firstNameRef.current?.value
    const lastName = lastNameRef.current?.value
    const code = codeRef.current?.value
    let payoutPhoneNumber: string | undefined
    const payoutEmail = payoutEmailRef.current?.value

    if (payoutPhoneRef.current?.value && countryCode) {
      const parsedNumber = parsePhoneNumber(payoutPhoneRef.current.value, countryCode)
      payoutPhoneNumber =  parsedNumber.number.toString()
    }

    dispatch(onboardAffiliate(
      {
        payload: {
          first_name: firstName,
          last_name: lastName,
          payout_phone_number: payoutPhoneNumber,
          payout_email: payoutEmail,
          payout_method: payoutMethod,
          code
        },
        countryCode
      }
    ))
  }

  return <form
    className="max-h-screen flex flex-col w-full min-h-screen md:min-h-screen-2/3 sticky"
    onSubmit={e => {
      e.preventDefault()
      handleSubmit()
    }}
  >
    <AlertDialog
      isOpen={!!error}
      error={error}
      buttons={[
        {
          title: { key: 'common:common_title_ok' },
          onClick: () => {
            dispatch(resetOnboardModal())
          },
          style: 'default'
        }
      ]}
    />
    <Container className="overflow-y-scroll max-h-screen flex-1 w-full">
      <div className="w-full no-scrollbar">
        <div className="-ml-3 -mt-3 flex items-center justify-between">
          <button
            type="button"
            className="transition-all text-fg-black px-3 py-3 rounded-full flex items-center hover:bg-fg-gray-surface"
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon className="h-4 h-4" />
          </button>
        </div>

        <div className={`mt-4 mb-6 lg:mb-8`}>
          <HeroHeading2>{t('affiliate:affiliate_title_affiliate_sign_up')}</HeroHeading2>
        </div>

        <div className="flex flex-col space-y-5">
          <div>
            <Heading4 className="mb-2">{t('affiliate:affiliate_placeholder_promo_code')}</Heading4>
            <BodyInput
              ref={codeRef}
              placeholder={t('affiliate:affiliate_placeholder_promo_code')}
              validate={codeValidation}
            />
          </div>

          <div className="grid grid-cols-2 gap-3">
            <div>
              <Heading4 className="mb-2">{t('auth:auth_placeholder_first_name')}</Heading4>
              <BodyInput
                type="text"
                autoComplete="given-name"
                ref={firstNameRef}
                defaultValue={user?.firstName}
                validate={firstNameValidation}
                placeholder={ t('auth:auth_placeholder_first_name') }
              />
            </div>

            <div>
              <Heading4 className="mb-2">{t('auth:auth_placeholder_last_name')}</Heading4>
              <BodyInput
                type="text"
                autoComplete="family-name"
                ref={lastNameRef}
                defaultValue={user?.lastName}
                validate={lastNameValidation}
                placeholder={ t('auth:auth_placeholder_last_name') }
              />
            </div>
          </div>

          <div>
            <Heading4 className="mb-2">{t('affiliate:affiliate_placeholder_payout_method')}</Heading4>
            <select
              id="payout_method"
              name="payout_method"
              onClick={e => {
                e.stopPropagation()
              }}
              defaultValue={payoutMethod}
              onChange={e => {
                setPayoutMethod(e.target.value as AffiliatePayoutMethodType)
              }}
              className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
            >
              {
                payoutMethods
                  .map(i => {
                    return <option key={i} value={i}>{i === 'venmo' ? 'Venmo' : 'Paypal'}</option>
                  })
              }
            </select>
          </div>

          <div className={`${payoutMethod === 'venmo' ? 'hidden' : ''}`}>
            <Heading4 className="mb-2">{t('affiliate:affiliate_placeholder_paypal_email')}</Heading4>
            <BodyInput
              type="email"
              autoComplete="email"
              ref={payoutEmailRef}
              defaultValue={user?.email}
              placeholder={t('affiliate:affiliate_placeholder_paypal_email')}
              validate={emailValidation}
            />
          </div>

          <div className={`grid grid-cols-10 gap-3 ${payoutMethod === 'paypal' ? 'hidden' : ''}`}>
            <div className="col-span-5 lg:col-span-3">
              <Heading4 className="mb-2">{t('affiliate:affiliate_placeholder_country')}</Heading4>

              <div>
                <select
                  id="country_code"
                  name="country_code"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  defaultValue={countryCode}
                  onChange={e => {
                    setCountryCode(e.target.value as CountryCode)
                  }}
                  className={`bg-fg-gray-surface-light w-full h-full hover:bg-fg-gray-surface-dark border-0 cursor-pointer text-center focus:ring-0 rounded-lg py-2.5 lg:py-3 flex flex-row items-center ${TextStyles.Body}`}
                >
                  {
                    PhoneNumberUtils.countryCodes
                      .sort((c1, c2) => (c1.isd) - (c2.isd))
                      .map(i => {
                        return <option key={`${i.isd}_${i.code}`} value={i.code}>{`+${i.isd} (${i.code})`}</option>
                      })
                  }
                </select>
              </div>
            </div>

            <div className="col-span-5 lg:col-span-7">
              <Heading4 className="mb-2">{t('affiliate:affiliate_placeholder_venmo_phone_number')}</Heading4>
              <BodyInput
                type="tel"
                autoComplete="tel"
                ref={payoutPhoneRef}
                placeholder={t('auth:auth_placeholder_phone_number')}
                validate={phoneValidation}
                value={phoneNumberInputValue}
                onChange={useCallback(e => {
                  const formatter = new AsYouType(countryCode)
                  setPhoneNumberInputValue(formatter.input(e.target.value))
                }, [countryCode])}
              />
            </div>
          </div>

          <div>
            <Button
              size="lg"
              isFullWidth={true}
              isLoading={isOnboarding}
              onClick={() => {
                handleSubmit()
              }}
            >
              {t('affiliate:affiliate_title_sign_up')}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  </form>
}