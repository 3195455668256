import * as Realm from "realm-web";
import {Credentials, User} from "realm-web";
import {catchError, delay, from, mergeMap, Observable, of, single, startWith, take, throwError, zip} from "rxjs";
import {RealmServiceError} from "../errors/realmServiceError";
import {Decimal128, Double, Int32, ObjectId} from "bson";
import {
  ObjectSchema,
  ObjectSchemaProperty,
  PropertyType,
  RealmObject,
  RealmObjectDocument,
  Services
} from "mongodb-realm";
import {map} from "rxjs/operators";
import {EntityModel} from "../models/entityModel";
import moment from "moment";
import {isIsoDateString} from "../apis/api";

export const realmApp = new Realm.App({id: process.env.NEXT_PUBLIC_REALM_APP_ID!});

export interface BaseRealmService {
  getPartition(): string | null | undefined

  getDb(): Observable<Services.MongoDBDatabase>

  userIsLoggedIn(): boolean

  userIsAnonymous(): boolean

  saveEntities<T extends RealmObjectDocument>(entities: T[]): Observable<T[]>

  saveEntity<T extends RealmObjectDocument>(entity: T): Observable<T>

  query<T extends RealmObjectDocument>(collectionName: string): RealmQueryBuilder<T>

  login(token: string): Observable<User>

  loginWithEmail(email: string, password: string): Observable<User>

  loginAnonymously(): Observable<User>

  linkUserWithCredentials(user: User, credentials: Credentials): Observable<void>

  logout(): Observable<void>
}

class RealmService implements BaseRealmService {
  getPartition(): string | null | undefined {
    const user = realmApp.currentUser
    if (!user) return

    return `user=${user.id}`
  }

  userIsLoggedIn(): boolean {
    return realmApp.currentUser?.isLoggedIn !== true
  }

  userIsAnonymous(): boolean {
    return realmApp.currentUser?.identities
      .map(i => i.providerType === 'anon-user')
      .reduce((acc, i) => acc && i, true) === true
  }

  getDb(): Observable<Services.MongoDBDatabase> {
    return new Observable(subscriber => {
      const user = realmApp.currentUser

      if (!user) {
        subscriber.error(new RealmServiceError('NOT_LOGGED_IN'))
      } else {
        const mongodb = user.mongoClient("mongodb-atlas");
        const db = mongodb.db(process.env.NEXT_PUBLIC_REALM_DATABASE_NAME!)
        subscriber.next(db as Services.MongoDBDatabase)
      }
    })
  }

  saveEntities<T extends RealmObjectDocument>(entities: T[]): Observable<T[]> {
    return this.getDb()
      .pipe(
        mergeMap(() => entities.length === 0 ? of([]) : zip(entities.map(entity => this.saveEntity(entity))))
      )
  }

  saveEntity<T extends RealmObjectDocument>(entity: T): Observable<T> {
    return this.getDb()
      .pipe(
        mergeMap((db) => {
          let doc = this.createUpsertPayload(entity)
          let unset = this.createUnsetPayload(entity)

          const unsetOperation = () => {
            return db.collection<T>(entity.schema.name)
              .updateOne({ _id: entity._id }, { $unset: unset })
          }

          const copySchema = (from: RealmObjectDocument, to: RealmObjectDocument) => {
            Object.keys(to).forEach(key => {
              const fromValue = (from as any)[key]

              if (fromValue && fromValue.schema) {
                to.schema = from.schema
              } else if (typeof fromValue === 'object') {

              }
            })
          }

          const fallbackUnsetOperation = () => {
            return db.collection<T>(entity.schema.name)
              .findOne({ _id: entity._id })
              .then(e => {
                if (e) {
                  return this.createUnsetPayload(entity.constructor(e))
                } else {
                  return
                }
              })
              .then(u => {
                return u && db.collection<T>(entity.schema.name)
                  .updateOne({ _id: entity._id }, { $unset: u })
              })
          }

          const setOperation = () => {
            return db.collection<T>(entity.schema.name)
              .updateOne({ _id: entity._id }, { $set: doc }, { upsert: true })
              .then(result => {
                return db.collection<T>(entity.schema.name).findOne({ $or: [{ _id: entity._id }, { _id: result.upsertedId }] })
                  .then(doc => doc!)
              })
          }

          return from(setOperation())
            .pipe(
              catchError((e) => {
                if (e && e.errorCode === 'SchemaValidationFailedWrite') {
                  return from(unsetOperation())
                    .pipe(
                      catchError((e) => {
                        if (e && e.errorCode === 'SchemaValidationFailedWrite') {
                          return from(fallbackUnsetOperation())
                        } else {
                          return throwError(() => e)
                        }
                      }),
                      mergeMap(() => from(setOperation()))
                    )
                } else {
                  return throwError(() => e)
                }
              })
            )
        })
      )
  }

  createOrModifyEntity<T extends RealmObjectDocument>(entity?: EntityModel<T> | null): Observable<T | null> {
    if (!entity) {
      return new Observable(subscriber => {
        subscriber.next(null)
        // subscriber.complete()
      })
    } else {
      return entity.toEntityObservable(this)
        .pipe(
          mergeMap((e) => {
            return this.saveEntity<T>(e)
          })
        )
    }
  }

  createOrModifyEntities<T extends RealmObjectDocument>(entities: EntityModel<T>[]): Observable<T[]> {
    if (entities.length <= 0) {
      return new Observable(subscriber => {
        subscriber.next([])
        // subscriber.complete()
      })
    } else {
      const entityObservables = entities.map(e => e.toEntityObservable(this))
      return zip(entityObservables).pipe(
        mergeMap(entities => {
          return this.saveEntities<T>(entities)
        })
      )
    }
  }

  createUnsetPayload<T extends RealmObject>(entity: T, root: string = '') {
    let payload: any = {}

    for (const fieldName in entity) {
      const key = root + fieldName;
      const value = entity[fieldName] as unknown
      const type: PropertyType | ObjectSchemaProperty | ObjectSchema = entity.schema.properties[fieldName]
      if (!type) continue

      if (type === 'bool' || type === 'bool?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'bool[]') {

      } else if (type === 'int' || type === 'int?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'int[]') {

      } else if (type === 'float' || type === 'float?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'float[]') {

      } else if (type === 'double' || type === 'double?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'double[]') {

      } else if (type === 'decimal128' || type === 'decimal128?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'decimal128[]') {

      } else if (type === 'objectId' || type === 'objectId?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'objectId[]') {

      } else if (type === 'string' || type === 'string?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'string[]') {

      } else if (type === 'data' || type === 'data?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'date' || type === 'date?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'date[]') {

      } else if (type === 'list' || type === 'list?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (type === 'linkingObjects' || type === 'linkingObjects?') {
        type.includes('?') && value == null ? payload[key] = 1 : {}
      } else if (value instanceof Object && (value as RealmObjectDocument)?.schema) {
        const newRoot = root + fieldName + '.';
        payload = {
          ...payload,
          ...this.createUnsetPayload(value as RealmObjectDocument, newRoot)
        }
      } else if (Array.isArray(value)) {
        // payload[fieldName] = value.map(v => this.createUnsetPayload(v, payload))
      }
    }

    return payload
  }

  createUpsertPayload<T extends RealmObject>(entity: T) {
    const user = realmApp.currentUser
    if (!user) return

    const payload: any = {}

    for (const fieldName in entity) {
      const value = entity[fieldName] as unknown
      const type: PropertyType | ObjectSchemaProperty | ObjectSchema = entity.schema.properties[fieldName]
      if (!type) continue

      if (type === 'bool' || type === 'bool?') {
        value != null ? payload[fieldName] = value : value === null ? payload[fieldName] = false : {}
      } else if (type === 'bool[]') {
        payload[fieldName] = value
      } else if (type === 'int' || type === 'int?') {
        value != null ? payload[fieldName] = new Int32(value as number) : value === null ? payload[fieldName] = new Int32(0) : {}
      } else if (type === 'int[]') {
        payload[fieldName] = (value as number[]).map(v => new Int32(v))
      } else if (type === 'float' || type === 'float?') {
        value != null ? payload[fieldName] = new Decimal128((value as number).toString()) : value === null ? payload[fieldName] = new Decimal128('0') : {}
      } else if (type === 'float[]') {
        payload[fieldName] = (value as number[]).map(v => new Decimal128(v.toString()))
      } else if (type === 'double' || type === 'double?') {
        value != null ? payload[fieldName] = new Double(value as number) : value === null ? payload[fieldName] = new Double(0) : {}
      } else if (type === 'double[]') {
        payload[fieldName] = (value as number[]).map(v => new Double(v))
      } else if (type === 'decimal128' || type === 'decimal128?') {
        value != null ? payload[fieldName] = new Decimal128((value as number).toString()) : value === null ? payload[fieldName] = new Decimal128('0') : {}
      } else if (type === 'decimal128[]') {
        payload[fieldName] = (value as number[]).map(v => new Decimal128(v.toString()))
      } else if (type === 'objectId' || type === 'objectId?') {
        value != null ? payload[fieldName] = value : {}
      } else if (type === 'objectId[]') {
        payload[fieldName] = value
      } else if (type === 'string' || type === 'string?') {
        value != null ? payload[fieldName] = value : value === null ? payload[fieldName] = "" : {}
      } else if (type === 'string[]') {
        payload[fieldName] = value ? value : []
      } else if (type === 'data' || type === 'data?') {
        value != null ? payload[fieldName] = value : {}
      } else if (type === 'date' || type === 'date?') {
        value != null ? (payload[fieldName] = typeof value === 'string' && isIsoDateString(value) ? moment(value).toDate() : value) : value === null && (fieldName === 'createdAt' || fieldName === 'updatedAt') ? payload[fieldName] = new Date() : {}
      } else if (type === 'date[]') {
        payload[fieldName] = value ? value : []
      } else if (type === 'list' || type === 'list?') {
        payload[fieldName] = value
      } else if (type === 'linkingObjects' || type === 'linkingObjects?') {
        payload[fieldName] = value
      } else if (value instanceof Object && (value as RealmObjectDocument)?.schema) {
        payload[fieldName] = this.createUpsertPayload(value as RealmObjectDocument)
      } else if (Array.isArray(value)) {
        payload[fieldName] = value.map(v => this.createUpsertPayload(v))
      }
    }

    delete payload._id
    delete payload.schema

    payload._partition = `user=${user.id}`
    return payload
  }

  query<T extends RealmObjectDocument>(collectionName: string): RealmQueryBuilder<T> {
    return new RealmQueryBuilder<T>(this.getDb(), collectionName, this.getPartition() ?? new ObjectId().toHexString())
  }

  login(token: string): Observable<User> {
    return from(realmApp.logIn(Credentials.jwt(token)))
  }

  loginWithEmail(email: string, password: string): Observable<User> {
    return from(realmApp.logIn(Credentials.emailPassword(email, password)))
  }

  loginAnonymously(): Observable<User> {
    return from(realmApp.logIn(Credentials.anonymous()))
  }

  linkUserWithCredentials(user: User, credentials: Credentials): Observable<void> {
    return from(user.linkCredentials(credentials))
  }

  logout(): Observable<void> {
    return from(realmApp.currentUser?.logOut() ?? new Promise<void>(res => res()))
  }
}

type RealmQuery<T extends RealmObjectDocument> = {
  [key in keyof T | '$exists' | '$ne' | '$or' | '$and' | '$in' | string]?: RealmQuery<T> | RealmQuery<T>[] | string | string[] | number | number[] | ObjectId | boolean | undefined | null;
};


type RealmQueryOptions<T extends RealmObjectDocument> = {
  sort?: { [key in keyof T]?: -1 | 1 }
  projection?: { [key in keyof T]: -1 | 1 }
  limit?: number
}

class RealmQueryBuilder<T extends RealmObjectDocument> {
  collectionName: string
  queries: RealmQuery<T>[] = []
  db: Observable<Services.MongoDBDatabase>

  constructor(db: Observable<Services.MongoDBDatabase>, collectionName: string, _partition: string) {
    this.db = db
    this.collectionName = collectionName
    this.queries.push({ _partition })
  }

  getResolvedQuery(): RealmQuery<T> {
    return this.queries.length > 0 ? { $and: this.queries } : {}
  }

  where(query?: RealmQuery<T>): RealmQueryBuilder<T> {
    query && this.queries.push(query)
    return this
  }

  delete(): Observable<void> {
    return this.db.pipe(
      mergeMap(db => {
        return from(db.collection<T>(this.collectionName).deleteMany(this.getResolvedQuery()))
      }),
      map(() => {}),
      take(1),
      single()
    )
  }

  fetchOne(options?: RealmQueryOptions<T>): Observable<T | null> {
    return this.db.pipe(
      mergeMap(db => {
        return from(db.collection<T>(this.collectionName).findOne(this.getResolvedQuery(), options))
      }),
      take(1),
      single()
    )
  }

  fetchAll(options?: RealmQueryOptions<T>): Observable<T[]> {
    return this.db.pipe(
      mergeMap(db => {
        return from(db.collection<T>(this.collectionName).find(this.getResolvedQuery(), options))
      }),
      take(1),
      single()
    )
  }

  observeOne(options?: RealmQueryOptions<T>): Observable<T | null> {
    return this.db.pipe(
      mergeMap(db => this.fetchOne(options).pipe(map(value => [value, db] as [T | null, Services.MongoDBDatabase]))),
      mergeMap(([value, db]) => {
        const filter: any = {
        }

        this.queries.forEach(query => {
          for (let queryKey in query) {
            filter[`fullDocument.${queryKey}`] = (query as any)[queryKey]
          }
        })

        const collectionWatcher = db.collection<T>(this.collectionName).watch({
          filter,
        })

        return from(collectionWatcher).pipe(
          mergeMap(() => {
            return this.fetchOne()
          }),
          startWith(value)
        )
      })
    )
  }

  observeAll(options?: RealmQueryOptions<T>): Observable<T[]> {
    return this.db.pipe(
      mergeMap(db => this.fetchAll(options).pipe(map(value => [value, db] as [T[], Services.MongoDBDatabase]))),
      mergeMap(([value, db]) => {
        const filter: any = {
        }

        this.queries.forEach(query => {
          for (let queryKey in query) {
            filter[`fullDocument.${queryKey}`] = (query as any)[queryKey]
          }
        })

        const collectionWatcher = db.collection<T>(this.collectionName).watch({
          filter,
        })

        return from(collectionWatcher).pipe(
          mergeMap(() => {
            return this.fetchAll()
          }),
          startWith(value)
        )
      }),
    )
  }
}

export const realmService = new RealmService()