import React, {useEffect, useRef, useState} from "react";
import {
  loginWithApple, loginWithAppleAndSubmitSurvey,
  loginWithEmail, loginWithEmailAndSubmitSurvey,
  loginWithFacebook, loginWithFacebookAndSubmitSurvey, selectError,
  selectIsLoggingInWithApple,
  selectIsLoggingInWithEmail,
  selectIsLoggingInWithFacebook, selectIsResettingPassword,
  selectIsSigningUpWithEmail,
  sendPasswordReset,
  signUpWithEmail, signUpWithEmailAndSubmitSurvey
} from "../../redux/auth/authSlice";
import {Heading4, Paragraph, TextStyles} from "../text";
import {BodyInput} from "../inputs";
import {FacebookFilledIcon, IosFilledIcon, LockFilledIcon, MailFilledIcon, UserFilledIcon} from "../icons";
import {Button} from "../buttons/Button";
import {LoginViewConfig} from "./LoginView";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useTranslation} from "next-i18next";
import {BaseError} from "../../errors/baseError";
import {LocalizationInfo} from "react-i18next";
import {AuthValidationError} from "../../errors/authValidationError";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";

export interface LoginFormViewProps {
  config: LoginViewConfig
  onSelectedConfigChange: (config: LoginViewConfig) => void
}

export const LoginFormView: React.FC<LoginFormViewProps> = (props) => {
  const { t } = useTranslation()

  const nameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const [config, setConfig] = useState(props.config)
  const dispatch = useAppDispatch()

  const isLoggingInWithEmail = useAppSelector(selectIsLoggingInWithEmail)
  const isSigningUpWithEmail = useAppSelector(selectIsSigningUpWithEmail)
  const isLoggingInWithFacebook = useAppSelector(selectIsLoggingInWithFacebook)
  const isLoggingInWithApple = useAppSelector(selectIsLoggingInWithApple)
  const isResettingPassword = useAppSelector(selectIsResettingPassword)
  const error = useAppSelector(selectError);

  const [validationError, setValidationError] = useState<BaseError<any> | null>()
  const [emailValidation, setEmailValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() => () => true)
  const [passwordValidation, setPasswordValidation] = useState<(text?: string | null) => (string | LocalizationInfo | null | undefined | boolean)>(() =>() => true)

  useEffect(() => {
    setValidationError(error instanceof AuthValidationError ? error : null)
  },  [error])

  let emailButtonTitle: string;
  let facebookButtonTitle: string | null;
  let appleButtonTitle: string | null;
  switch (config) {
    case "sign_up":
      emailButtonTitle = t('auth:auth_button_sign_up_with_email')
      facebookButtonTitle = t('auth:auth_button_sign_up_with_facebook')
      appleButtonTitle = t('auth:auth_button_sign_up_with_apple')
      break;
    case "post_purchase":
      emailButtonTitle = t('auth:auth_button_sign_up_with_email')
      facebookButtonTitle = t('auth:auth_button_sign_up_with_facebook')
      appleButtonTitle = t('auth:auth_button_sign_up_with_apple')
      break;
    case "get_started":
      emailButtonTitle = t('auth:auth_button_continue_with_email')
      facebookButtonTitle = t('auth:auth_button_continue_with_facebook')
      appleButtonTitle = t('auth:auth_button_continue_with_apple')
      break;
    case "get_macro_plan":
      emailButtonTitle = t('auth:auth_button_sign_up_with_email')
      facebookButtonTitle = t('auth:auth_button_sign_up_with_facebook')
      appleButtonTitle = t('auth:auth_button_sign_up_with_apple')
      break;
    case "sign_in":
      emailButtonTitle = t('auth:auth_button_sign_in_with_email')
      facebookButtonTitle = t('auth:auth_button_sign_in_with_facebook')
      appleButtonTitle = t('auth:auth_button_sign_in_with_apple')
      break;
    case "forgot_password":
      emailButtonTitle = t('auth:auth_button_reset_password')
      facebookButtonTitle = null
      appleButtonTitle = null
      break;
  }

  useEffect(() => {
    switch (config) {
      case "sign_up":
        EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Sign Up' }))
        break;
      case "sign_in":
        EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Sign In' }))
        break;
      case "forgot_password":
        EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Forgot Password' }))
        break;
      case "post_purchase":
        EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Sign Up' }))
        break;
      case "get_started":
        EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Sign Up' }))
        break;
      case "get_macro_plan":
        EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Sign Up' }))
        break;
    }

    props.onSelectedConfigChange(config)
  }, [config])

  useEffect(() => {
    setEmailValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "INVALID_EMAIL":
          return validationError?.localizedMessage
        case "INVALID_PASSWORD":
          break;
        case "INVALID_PASSWORD_LENGTH":
          break;
        case "EMAIL_IS_EMPTY":
          return validationError?.localizedMessage
        case "PASSWORD_IS_EMPTY":
          break;
      }
    })

    setPasswordValidation(() => () => {
      const code = validationError?.code
      if (!code) return

      switch (code) {
        case "INVALID_EMAIL":
          break;
        case "INVALID_PASSWORD":
          return validationError?.localizedMessage
        case "INVALID_PASSWORD_LENGTH":
          return validationError?.localizedMessage
        case "EMAIL_IS_EMPTY":
          break;
        case "PASSWORD_IS_EMPTY":
          return validationError?.localizedMessage
      }
    })
  }, [validationError])

  return <form
    className="flex flex-col space-y-10 lg:space-y-16"
    onSubmit={e => {
      e.preventDefault()
      const email = emailRef.current?.value ?? ""
      const password = passwordRef.current?.value ?? ""
      const name = nameRef.current?.value

      switch (config) {
        case "sign_up":
          if (props.config === 'get_macro_plan') {
            dispatch(signUpWithEmailAndSubmitSurvey({ email, password, name }))
          } else {
            dispatch(signUpWithEmail({ email, password, name }))
          }
          break;
        case "post_purchase":
          dispatch(signUpWithEmail({ email, password, name }))
          break;
        case "get_started":
          dispatch(signUpWithEmail({ email, password, name }))
          break;
        case "get_macro_plan":
          dispatch(signUpWithEmailAndSubmitSurvey({ email, password, name }))
          break;
        case "sign_in":
          if (props.config === 'get_macro_plan') {
            dispatch(loginWithEmailAndSubmitSurvey({ email, password }))
          } else {
            dispatch(loginWithEmail({ email, password }))
          }
          break;
        case "forgot_password":
          dispatch(sendPasswordReset({ email }))
          break;
      }
    }}
  >
    <div className="flex flex-col space-y-5">
      {
        (() => {
          if (config === 'sign_up' || config === 'post_purchase' || config === 'get_macro_plan' || config === 'get_started') {
            return <div>
              <Heading4 className="mb-2">{t('auth:auth_placeholder_first_name')}</Heading4>
              <BodyInput
                type="text"
                autoComplete="given-name"
                ref={nameRef}
                leadingIcon={<UserFilledIcon />}
                placeholder={ t('auth:auth_placeholder_first_name') }
              />
            </div>
          }
          return null
        })()
      }

      <div>
        <Heading4 className="mb-2">{t('auth:auth_placeholder_email')}</Heading4>
        <BodyInput
          type="email"
          autoComplete="email"
          ref={emailRef}
          leadingIcon={<MailFilledIcon />}
          placeholder={t('auth:auth_placeholder_email')}
          validate={emailValidation}
        />
      </div>

      {
        (() => {
          if (config !== 'forgot_password') {
            return <div>
              <Heading4 className="mb-2">{t('auth:auth_placeholder_password')}</Heading4>
              <BodyInput
                type="password"
                autoComplete={`${config === 'sign_up' || config === 'post_purchase' || config === 'get_macro_plan' || config === 'get_started' ? 'new-password' : 'current-password'}`}
                ref={passwordRef}
                leadingIcon={<LockFilledIcon />}
                validate={passwordValidation}
                placeholder={ t('auth:auth_placeholder_password') }
              />
            </div>
          }
          return null
        })()
      }

      {
        (() => {
          if (config === 'sign_in') {
            return (
              <Paragraph className="text-center text-fg-gray">
                {t('auth:auth_label_forgot_password') + ' '}
                <span className={`${TextStyles.Heading4} text-fg-primary`} >
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      setConfig('forgot_password')
                    }}
                  >
                    {t('auth:auth_label_email_reset')}
                  </a>
                </span>
              </Paragraph>
            )
          } else if (config === 'sign_up' || config === 'post_purchase' || config === 'get_macro_plan' || config === 'get_started') {
            return (
              <Paragraph className="text-center text-fg-gray">
                {t('auth:auth_label_by_continuing') + ' '}
                <span className={`${TextStyles.Heading4} text-fg-primary`} >
                  <a target="_blank" rel="noreferrer" href={process.env.NEXT_PUBLIC_TOS_URL}>
                    {t('auth:auth_label_terms_of_service')}
                  </a>
                </span>
              </Paragraph>
            )
          }
          return null
        })()
      }
    </div>

    <div className="flex flex-col space-y-5">
      <Button
        isFullWidth={true}
        size="lg"
        type="submit"
        isLoading={config === "sign_up" || config === 'post_purchase' || config === 'get_started' || config === 'get_macro_plan' ? isSigningUpWithEmail : config === "forgot_password" ? isResettingPassword : isLoggingInWithEmail}
        buttonStyle="primary"
      >
        {emailButtonTitle}
      </Button>

      {
        (() => {
          if (config === 'sign_in' || config === 'sign_up' || config === 'post_purchase' || config === 'get_started' || config === 'get_macro_plan') {
            return (
              <div className="relative">

                <FacebookFilledIcon className="text-white absolute z-10 h-5 w-5 inset-y-0 left-0 top-1/2 -mt-2.5 ml-6 md:ml-8" />
                <Button
                  isFullWidth={true}
                  buttonStyle="primary"
                  size="lg"
                  onClick={() => {
                    if (props.config === 'get_macro_plan') {
                      dispatch(loginWithFacebookAndSubmitSurvey())
                    } else {
                      dispatch(loginWithFacebook())
                    }
                  }}
                  isLoading={isLoggingInWithFacebook}
                  className="bg-facebook-blue hover:bg-facebook-blue focus:ring-2 focus:ring-offset-2 focus:ring-facebook-blue"
                >
                  {facebookButtonTitle}
                </Button>
              </div>
            )
          }
          return null
        })()
      }

      {
        (() => {
          if (config === 'sign_in' || config === 'sign_up' || config === 'post_purchase' || config === 'get_started' || config === 'get_macro_plan') {
            return (
              <div className="relative">

                <IosFilledIcon className="text-white absolute z-10 h-5 w-5 inset-y-0 left-0 top-1/2 -mt-2.5 ml-6 md:ml-8" />
                <Button
                  isFullWidth={true}
                  buttonStyle="primary"
                  size="lg"
                  onClick={() => {
                    if (props.config === 'get_macro_plan') {
                      dispatch(loginWithAppleAndSubmitSurvey())
                    } else {
                      dispatch(loginWithApple())
                    }
                  }}
                  isLoading={isLoggingInWithApple}
                  className="bg-fg-black hover:bg-fg-black focus:ring-2 focus:ring-offset-2 focus:ring-fg-black"
                >
                  {appleButtonTitle}
                </Button>
              </div>
            )
          }
          return null
        })()
      }

      {
        (() => {
          if (config === 'sign_in') {
            return (
              <Paragraph className="text-center text-fg-gray">
                {t('auth:auth_label_dont_have_an_account') + ' '}
                <span className={`${TextStyles.Heading4} text-fg-primary`} >
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      if (props.config === 'post_purchase') {
                        setConfig('post_purchase')
                      } else if (props.config === 'get_started') {
                        setConfig('get_started')
                      } else if (props.config === 'get_macro_plan') {
                        setConfig('get_macro_plan')
                      } else {
                        setConfig('sign_up')
                      }
                    }}
                  >
                    {t('auth:auth_title_sign_up')}
                  </a>
                </span>
              </Paragraph>
            )
          } else if (config === 'sign_up' || config === 'post_purchase' || config === 'get_started' || config === 'get_macro_plan') {
            return (
              <Paragraph className="text-center text-fg-gray">
                {t('auth:auth_label_already_have_an_account') + ' '}
                <span className={`${TextStyles.Heading4} text-fg-primary`} >
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      setConfig('sign_in')
                    }}
                  >
                    {t('auth:auth_title_sign_in')}
                  </a>
                </span>
              </Paragraph>
            )
          }
          return null
        })()
      }
    </div>
  </form>
}