import {RootEpic} from "../../app/store";
import {asyncScheduler, catchError, filter, mergeMap, of, scheduled} from "rxjs";
import {
  cancelSubscription,
  cancelSubscriptionFailed,
  cancelSubscriptionSucceeded,
  createCheckoutSession,
  createCheckoutSessionFailed,
  createCheckoutSessionSucceeded,
  fetchSubscriptions,
  fetchSubscriptionsFailed,
  fetchSubscriptionsSucceeded,
  fetchUserSubscription,
  fetchUserSubscriptionFailed,
  fetchUserSubscriptionSucceeded,
  setFreeTrialCollabExpDate,
  setFreeTrialCollabExpDateFailed,
  setFreeTrialCollabExpDateSucceeded
} from "./subscriptionSlice";
import {SubscriptionService} from "../../services/subscriptionService";
import {logErrorRx} from "../../utils/logError";
import {UserService} from "../../services/userService";
import Moment from "moment";
import {showNotification} from "../navigation/navigationSlice";

export const fetchSubscriptionsEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchSubscriptions.match),
    mergeMap((action) => {
      return SubscriptionService.fetchSubscriptionPrices(action.payload)
        .pipe(
          mergeMap((subscriptions) => {
            return scheduled([fetchSubscriptionsSucceeded(subscriptions)], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchSubscriptionsFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const fetchUserSubscriptionEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(fetchUserSubscription.match),
    mergeMap((action) => {
      return SubscriptionService.fetchCurrentSubscription()
        .pipe(
          mergeMap((subscription) => {
            return scheduled([fetchUserSubscriptionSucceeded(subscription)], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: fetchUserSubscriptionFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const cancelSubscriptionEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(cancelSubscription.match),
    mergeMap(() => {
      return SubscriptionService.cancelSubscription()
        .pipe(
          mergeMap(() => {
            return scheduled([cancelSubscriptionSucceeded()], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: cancelSubscriptionFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const setFreeTrialCollabExpDateEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(setFreeTrialCollabExpDate.match),
    mergeMap(action => {
      const expDate = Moment().add(action.payload, 'days').toDate()
      return SubscriptionService.setFreeTrialExpDate(expDate)
        .pipe(
          mergeMap(() => {
            return scheduled([
              setFreeTrialCollabExpDateSucceeded(),
              showNotification({
                title: { key: 'subscription:subscription_alert_title_free_trial_activated' },
                message: { key: 'subscription:subscription_alert_message_trial_has_begun', options: { days: action.payload } }
              })
            ], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: setFreeTrialCollabExpDateFailed.type,
            payload: error,
            error: true
          }))
        )
    })
  )
}

export const createCheckoutSessionEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(createCheckoutSession.match),
    mergeMap(action => {
      const { id, promoCode } = action.payload
      return SubscriptionService.createCheckoutSession(id, promoCode)
        .pipe(
          mergeMap((response) => {
            return scheduled([createCheckoutSessionSucceeded({ id, response })], asyncScheduler)
          }),
          catchError(error => logErrorRx(error)),
          catchError(error => of({
            type: createCheckoutSessionFailed.type,
            payload: { error, id: action.payload.id },
            error: true
          }))
        )
    })
  )
}